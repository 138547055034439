import {useState, useRef, useEffect} from 'react'

import styled from 'styled-components'

import ReactCrop, {centerCrop, makeAspectCrop, Crop, PixelCrop} from 'react-image-crop'
import {canvasPreview} from './canvasPreview'
import {useDebounceEffect} from './useDebounceEffect'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

function Cropper({file, onCropChange}: {file: File; onCropChange: (blob: Blob | null) => void}) {
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(1 / 1)

  function onSelectFile(file: File) {
    setCrop(undefined) // Makes crop preview update between images.
    const reader = new FileReader()
    reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
    reader.readAsDataURL(file)
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const {width, height} = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)

        previewCanvasRef.current.toBlob((blob: Blob | null) => {
          onCropChange(blob)
        })
      }
    },
    100,
    [completedCrop, scale, rotate]
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else if (imgRef.current) {
      const {width, height} = imgRef.current
      setAspect(16 / 9)
      setCrop(centerAspectCrop(width, height, 16 / 9))
    }
  }

  function onCropComplete(crop: PixelCrop) {
    setCompletedCrop(crop)
  }

  useEffect(() => {
    onSelectFile(file)
  }, [file])

  useEffect(() => {
    // Get the previewCanvasRef image as a blob
    if (completedCrop && previewCanvasRef.current) {
    }
  }, [completedCrop, onCropChange])

  return (
    <Container>
      <div className='me-3'>
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => {
              onCropComplete(c)
            }}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt='Crop me'
              src={imgSrc}
              style={{transform: `scale(${scale}) rotate(${rotate}deg)`, maxHeight: 200}}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        <div className='text-muted text-center'>(original)</div>
      </div>
      <div className='ms-3'>
        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
        <div className='text-muted text-center'>(cropped)</div>
      </div>
    </Container>
  )
}

export default Cropper
