/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import styled from 'styled-components'
import { FuneralHome } from '../core/_models'
import { useObituaryDetail } from './ObituaryDetail'
import { replaceNewLinesWithParagraphs } from './ObituaryWriteSection'
import MultiDatePicker, { DateObject } from 'react-multi-date-picker'
import { getObituaryByShareableCode, getObituaryEmailContent } from '../core/_requests'
import { Link } from 'react-router-dom'
import usePageTitle from '../../../hooks/usePageTitle'
import CopyToClipboardButton from '../../../components/CopyToClipboardButton'

const MainQuillContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
`

const QuillContainer = styled.div`
  width: 100%;
  border: 0;

  .ql-container {
    border-radius: 10px;
    border: 0;
  }
  .ql-editor {
    font-size: 1.5rem;
  }

  p {
    margin-bottom: 20px;
  }

  p:last-child {
    margin-bottom: 0;
  }
`

const MultiDatePickerContainer = styled.div`
  .rmdp-container {
    width: 100%;
  }
`

interface ObituaryFinalizeAndSendProps { }

function ActionRow() {
  const { obituary, setObituary } = useObituaryDetail()

  // Will bring back the other buttons one day.

  return <>
    <div className='d-flex justify-content-center align-items-center'>
      <CopyToClipboardButton text={obituary.full_obituary_text || ''} />
    </div>
  </>

  return (
    <>
      <div className='d-flex justify-content-center align-items-center'>
        <a
          href={`${process.env.REACT_APP_API_URL}/obituaries/${obituary.unique_identifier}/download/pdf/`}
          className='btn btn-secondary me-2'
          target='_blank'
          rel='noreferrer'
        >
          Download PDF
        </a>
        <a
          href={`${process.env.REACT_APP_API_URL}/obituaries/${obituary.unique_identifier}/download/txt/`}
          className='btn btn-secondary me-2'
          target='_blank'
          rel='noreferrer'
        >
          Download as Text
        </a>
        {false && (
          <button type='button' className='btn btn-secondary' onClick={() => { }}>
            Send in Email
          </button>
        )}
        <a
          href={`${process.env.REACT_APP_API_URL}/obituaries/${obituary.unique_identifier}/download/docx/`}
          className='btn btn-secondary me-2'
          target='_blank'
          rel='noreferrer'
        >
          Download as .docx
        </a>
      </div>
    </>
  )
}

function FullObituary() {
  const { obituary, setObituary } = useObituaryDetail()

  return (
    <div className='pt-0 px-4 pb-4'>
      <div className="mt-5">
        <ActionRow />
      </div>
      <div className='separator separator-dashed my-8'></div>
      {obituary.active_image_uploads.length > 0 && (
        <div className='mb-5 d-flex align-items-center'>
          {obituary.active_image_uploads.map((image) => {
            return (
              <div key={`image-${image.unique_id}`} className='flex-column text-center me-4'>
                <div
                  className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'
                  style={{ backgroundColor: '#f3f6f9' }}
                >
                  <img
                    src={image.image_url}
                    alt={image.original_file_name}
                    className='img-fluid'
                    style={{ width: 'auto' }}
                  />
                </div>
                <div>
                  <a href={`${image.image_url}`} target='_blank' download rel='noreferrer'>
                    View Full Size
                  </a>
                  <a
                    href={`${process.env.REACT_APP_API_URL}/obituaries/${obituary.unique_identifier}/images/${image.unique_id}/download/`}
                    target='_blank'
                    rel='noreferrer'
                    className='ms-2'
                  >
                    Download
                  </a>
                </div>
              </div>
            )
          })}
        </div>
      )}
      <div
        style={{
          fontSize: '1.5rem',
          lineHeight: '2rem',
        }}
        dangerouslySetInnerHTML={{
          __html: replaceNewLinesWithParagraphs(obituary.full_obituary_text || ''),
        }}
      ></div>
      <div className='separator separator-dashed my-8'></div>
      <ActionRow />
    </div>
  )
}

function SendToNews() {
  const { obituary, setObituary } = useObituaryDetail()

  const [loadingEmailContent, setLoadingEmailContent] = useState(true)
  const [errorLoadingEmailContent, setErrorLoadingEmailContent] = useState<string | null>(null)

  const tomorrow = new DateObject().add(1, 'day')

  const [dateValues, setDateValues] = useState<DateObject | DateObject[]>([tomorrow])
  const hasADateValue = Array.isArray(dateValues) ? dateValues.length > 0 : !!dateValues

  const [emailBodyContent, setEmailBodyContent] = React.useState('')
  const [emailSignatureContent, setEmailSignatureContent] = React.useState('')

  const [gofundMeLinkValue, setGofundMeLinkValue] = useState('')

  const [currentFuneralHome, setCurrentFuneralHome] = useState<FuneralHome | null>(null)

  function getDatesToPrintString() {
    if (Array.isArray(dateValues) && dateValues.length > 0) {
      return dateValues.map((v) => v.format('MMMM D, YYYY')).join(', ')
    }
    if (dateValues instanceof DateObject) {
      return dateValues ? dateValues.format('MMMM D, YYYY') : '(select above)'
    }
    return '(select above)'
  }

  const onLoadEmailContent = useCallback(async () => {
    setLoadingEmailContent(true)
    try {
      const response = await getObituaryEmailContent(obituary.unique_identifier)
      setEmailBodyContent(response.body)
      setEmailSignatureContent(response.signature)
      setErrorLoadingEmailContent(null)
      setCurrentFuneralHome(response.funeralHome)
    } catch (error: any) {
      console.warn(error)
      setErrorLoadingEmailContent(error?.response?.data?.error || 'Unable to load email content')
    } finally {
      setLoadingEmailContent(false)
    }
  }, [obituary.unique_identifier])

  useEffect(() => {
    onLoadEmailContent()
  }, [onLoadEmailContent])

  if (loadingEmailContent) {
    return <div>Loading...</div>
  }

  if (errorLoadingEmailContent) {
    return (
      <div>
        <div className='alert alert-danger'>{errorLoadingEmailContent}</div>
        <button type='button' className='btn btn-secondary' onClick={onLoadEmailContent}>
          Retry
        </button>
      </div>
    )
  }

  return (
    <div className='px-4 px-md-0'>
      {!currentFuneralHome ? (
        <div className='mb-5 alert alert-warning'>
          <div className='alert-text font-weight-bold text-dark'>
            You have not yet been added to a funeral home. Please contact your funeral home to be
            added. Newspaper submissions will not be possible until you are added to a funeral home.
          </div>
        </div>
      ) : (
        <>
          {!currentFuneralHome.public_data?.phone_number && (
            <div className='mb-5 alert alert-warning'>
              <div className='alert-text font-weight-bold text-dark'>
                Your funeral home does not have a phone number set. Please contact your funeral home
                to set a phone number. Newspaper submissions will not be possible until you have a
                phone number set.
              </div>
            </div>
          )}
        </>
      )}
      {true && (
        <>
          <div className='mb-5 alert alert-warning'>
            <div className='alert-text font-weight-bold text-dark'>
              A newspaper has not yet been added to your account. An example newspaper submission is
              pictured below.
            </div>
          </div>
          <div className='separator separator-dashed my-8'></div>
        </>
      )}
      <div>
        <h4>
          To submit an obituary to Knoxville News Sentinel, an email will be sent to
          Obits@knoxnews.com
        </h4>
        <div className='mt-5'>
          <h5 className='mb-5'>Additional Information:</h5>
          <div className='row'>
            <div className='col-6'>
              <div className='form-group'>
                <label>Publication Date(s)</label>
                <MultiDatePickerContainer>
                  <MultiDatePicker
                    multiple
                    value={dateValues}
                    onChange={(dates: DateObject | DateObject[]) => setDateValues(dates)}
                    inputClass='form-control'
                    format={
                      !Array.isArray(dateValues) ||
                        (Array.isArray(dateValues) && dateValues.length < 4)
                        ? 'MMMM D, YYYY'
                        : 'M/D/YYYY'
                    }
                    placeholder='Select publication date(s)...'
                    required
                  />
                </MultiDatePickerContainer>
              </div>
            </div>
            <div className='col-6'>
              <div className='form-group'>
                <label htmlFor='name'>GoFundMe Link (Optional)</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='If applicable, enter the link to the GoFundMe page...'
                  value={gofundMeLinkValue}
                  onChange={(e) => setGofundMeLinkValue(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='separator separator-dashed my-8'></div>
      <h4 className='mb-5'>The email that will be sent:</h4>
      <div className='row mb-5'>
        <div className='col-6'>
          <div className='form-group'>
            <label className='mb-2'>Subject</label>
            <input
              type='text'
              className='form-control'
              defaultValue={`Obituary for ${obituary.for_full_name}`}
            />
          </div>
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-6'>
          <div className='form-group'>
            <label>Body</label>
          </div>
        </div>
      </div>
      <div>
        <MainQuillContainer>
          <QuillContainer>
            <ReactQuill
              style={{ fontSize: '1.5rem' }}
              theme='snow'
              value={emailBodyContent}
              onChange={(updatedText) => {
                setEmailBodyContent(updatedText)
              }}
              modules={{
                toolbar: false, // Snow includes toolbar by default
              }}
              readOnly={true}
              placeholder={`Build your email...`}
            />
          </QuillContainer>
          <QuillContainer>
            <ReactQuill
              style={{ fontSize: '1.5rem' }}
              theme='snow'
              value={`<div><strong>Date(s) to Print</strong></div><div><span>${getDatesToPrintString()}</span></div><div><strong>GoFundMe Link</strong></div><div><span>${gofundMeLinkValue && gofundMeLinkValue.trim() ? gofundMeLinkValue.trim() : '(none)'
                }</span></div>`}
              modules={{
                toolbar: false, // Snow includes toolbar by default
              }}
              readOnly
            />
          </QuillContainer>
          <QuillContainer>
            <ReactQuill
              style={{ fontSize: '1.5rem' }}
              theme='snow'
              value={emailSignatureContent}
              onChange={(updatedText) => {
                setEmailSignatureContent(updatedText)
              }}
              modules={{
                toolbar: false, // Snow includes toolbar by default
              }}
              readOnly={false}
              placeholder={`(Optional signature)`}
            />
          </QuillContainer>
        </MainQuillContainer>
      </div>
      <div className='separator separator-dashed my-8'></div>
      <div className='d-flex align-items-center justify-content-between'>
        {currentFuneralHome ? (
          <div>
            {!hasADateValue || !emailBodyContent ? (
              <div className='alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  {hasADateValue
                    ? 'Please enter an email body'
                    : 'Please select publication date(s)'}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div></div>
        )}
        <button
          type='button'
          className='btn btn-primary'
          disabled={
            !hasADateValue ||
            !emailBodyContent ||
            !currentFuneralHome ||
            !currentFuneralHome.public_data?.phone_number ||
            !!'disabledForNow'
          }
        >
          Send to News Sentinel
        </button>
      </div>
    </div>
  )
}

function ObituaryFinalizeAndSend({ }: ObituaryFinalizeAndSendProps) {
  const { obituary, setObituary, obituaryWebsocket } = useObituaryDetail()

  useEffect(() => {
    if (obituaryWebsocket) {
      obituaryWebsocket.sendPageView('finalize_obituary')
    }
  }, [obituaryWebsocket])

  usePageTitle({
    title: `${obituary.getSimpleName()}'s Obituary | Finalize and Send`,
  })

  const onReloadObituary = useCallback(async () => {
    if (!obituary) {
      return
    }
    try {
      // This is called in the `Write Obituary` tab
      // We use `getObituaryByShareableCode` cause it's new and better
      const { obituary: updatedObituary } = await getObituaryByShareableCode(obituary.unique_identifier)
      setObituary(updatedObituary)
    } catch (error) {
      console.warn(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obituary.unique_identifier, setObituary])

  useEffect(() => {
    onReloadObituary()
  }, [onReloadObituary])

  if (!obituary.hasWrittenBiography()) {
    return (
      <div className='alert alert-warning'>
        <div className='alert-text font-weight-bold text-dark'>
          Before finalizing and downloading you must{' '}
          <Link
            to={`/obituaries/${obituary.unique_identifier}/write`}
            className='ms-1 me-1 text-dark text-decoration-underline'
          >
            write the obituary here
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div>
      {false && (
        <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 ps-4'>
          <li className='nav-item'>
            <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
              View Full Obituary
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
              Send to Newspaper
            </a>
          </li>
        </ul>
      )}
      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
          <div className='row'>
            <div className='col-lg-12'>
              <FullObituary />
            </div>
          </div>
        </div>
        {false && <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
          <div className='row'>
            <div className='col-lg-12'>
              <SendToNews />
            </div>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default ObituaryFinalizeAndSend
