// We keep label to

const familyRelationshipOptions = [
  {
    value: 'husband',
    label: 'Husband',
    searchableLabel: 'husband partner',
  },
  {value: 'wife', label: 'Wife', searchableLabel: 'wife partner'},
  {value: 'spouse', label: 'Spouse', searchableLabel: 'spouse partner'},
  {value: 'partner', label: 'Partner', searchableLabel: 'partner spouse wife husband'},
  {value: 'friend', label: 'Friend', searchableLabel: 'friend best friend buddy pal'},
  {value: 'daughter', label: 'Daughter', searchableLabel: 'daughter child'},
  {value: 'son', label: 'Son', searchableLabel: 'son child'},
  {value: 'mother', label: 'Mother', searchableLabel: 'mother parent mom'},
  {value: 'father', label: 'Father', searchableLabel: 'father parent dad'},
  {value: 'brother', label: 'Brother', searchableLabel: 'brother sibling'},
  {value: 'sister', label: 'Sister', searchableLabel: 'sister sibling'},
  {value: 'grandson', label: 'Grandson', searchableLabel: 'grandson grandchild'},
  {value: 'granddaughter', label: 'Granddaughter', searchableLabel: 'granddaughter grandchild'},
  {value: 'grandmother', label: 'Grandmother', searchableLabel: 'grandmother grandparent grandma'},
  {value: 'grandfather', label: 'Grandfather', searchableLabel: 'grandfather grandparent grandpa'},
  {value: 'cousin', label: 'Cousin'},
  {value: 'aunt', label: 'Aunt'},
  {value: 'uncle', label: 'Uncle'},
  {value: 'niece', label: 'Niece'},
  {value: 'nephew', label: 'Nephew'},
  {value: 'family', label: 'Family'},
  {value: 'family_friend', label: 'Family friend'},
  {value: 'boyfriend', label: 'Boyfriend'},
  {value: 'girlfriend', label: 'Girlfriend'},
  {value: 'coworker', label: 'Coworker'},
  {value: 'grandparent', label: 'Grandparent'},
  {value: 'grandchild', label: 'Grandchild'},
  {value: 'sibling', label: 'Sibling'},
  {value: 'child', label: 'Child'},
  {value: 'parent', label: 'Parent'},
  {value: 'daughter-in-law', label: 'Daughter-in-law'},
  {value: 'son-in-law', label: 'Son-in-law'},
  {value: 'child-in-law', label: 'Child-in-law'},
  {value: 'mother-in-law', label: 'Mother-in-law'},
  {value: 'father-in-law', label: 'Father-in-law'},
  {value: 'parent-in-law', label: 'Parent-in-law'},
  {value: 'sister-in-law', label: 'Sister-in-law'},
  {value: 'brother-in-law', label: 'Brother-in-law'},
  {value: 'sibling-in-law', label: 'Sibling-in-law'},
  {value: 'ex-husband', label: 'Ex-husband'},
  {value: 'ex-wife', label: 'Ex-wife'},
  {value: 'ex-partner', label: 'Ex-partner'},
  {
    value: 'step-daughter',
    label: 'Step-daughter',
    searchableLabel: 'stepdaughter stepchild step-daughter step daughter',
  },
  {value: 'step-son', label: 'Step-son', searchableLabel: 'stepson stepchild step-son step son'},
  {value: 'step-child', label: 'Step-child', searchableLabel: 'stepchild step-child step child'},
  {
    value: 'step-mother',
    label: 'Step-mother',
    searchableLabel: 'stepmother step-parent step-mother step mother',
  },
  {
    value: 'step-father',
    label: 'Step-father',
    searchableLabel: 'stepfather step-parent step-father step father',
  },
  {
    value: 'step-parent',
    label: 'Step-parent',
    searchableLabel: 'stepparent step-parent step parent',
  },
  {value: 'funeral-director', label: 'Funeral director'},
  {value: 'nurse', label: 'Nurse'},
  {value: 'doctor', label: 'Doctor'},
  {value: 'medical-social-worker', label: 'Medical social worker'},
  {value: 'death-doula', label: 'Death doula'},
  {value: 'self', label: 'Self', searchableLabel: 'self me myself'},
  {value: 'other', label: 'Other'},
]

export default familyRelationshipOptions
