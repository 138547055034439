import {useState} from 'react'

function LanguagePicker({
  startCollapsed = false,
  hideEnglishDescriptions = false,
}: {
  startCollapsed?: boolean
  hideEnglishDescriptions?: boolean
}) {
  const [languagePickerVisible, setLanguagePickerVisible] = useState(!startCollapsed)

  const languageEmojiMapping: any = {
    en: '🇺🇸',
    es: '🇪🇸',
    fr: '🇫🇷',
    de: '🇩🇪',
    it: '🇮🇹',
    pt: '🇵🇹',
    nl: '🇳🇱',
    ru: '🇷🇺',
    ar: '🇸🇦',
    zh: '🇨🇳',
    ja: '🇯🇵',
  }

  const languageLabels: any = {
    en: 'English',
    es: hideEnglishDescriptions ? 'Español' : 'Español (Spanish)',
    fr: hideEnglishDescriptions ? 'Français' : 'Français (French)',
    de: hideEnglishDescriptions ? 'Deutsch' : 'Deutsch (German)',
    it: hideEnglishDescriptions ? 'Italiano' : 'Italiano (Italian)',
    pt: hideEnglishDescriptions ? 'Português' : 'Português (Portuguese)',
    nl: hideEnglishDescriptions ? 'Nederlands' : 'Nederlands (Dutch)',
    ru: hideEnglishDescriptions ? 'Русский' : 'Русский (Russian)',
    ar: hideEnglishDescriptions ? 'العربية' : 'العربية (Arabic)',
    zh: hideEnglishDescriptions ? '中文' : '中文 (Chinese)',
    ja: hideEnglishDescriptions ? '日本語' : '日本語 (Japanese)',
  }

  const [selectedLanguage, setSelectedLanguage] = useState('en')

  return (
    <>
      {!languagePickerVisible ? (
        <button onClick={() => setLanguagePickerVisible(true)} className='btn btn-sm btn-light'>
          {languageEmojiMapping[selectedLanguage]}
        </button>
      ) : (
        <select
          name='language'
          className='form-select form-select-sm'
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.target.value)}
        >
          {Object.keys(languageLabels).map((language) => {
            return (
              <option key={language} value={language}>
                {languageEmojiMapping[language]} {languageLabels[language]}
              </option>
            )
          })}
        </select>
      )}
    </>
  )
}

export default LanguagePicker
