import {useCallback, useEffect} from 'react'
import {getObituaryFormPartial} from '../modules/obituaries/core/_requests'
import {IAvailableFormType} from './useObituaryWebsocket'
import {
  Career,
  CeremonyDetails,
  Education,
  LifeEvents,
  MilitaryService,
  PersonalInformation,
  PersonalityAndInterests,
  Relatives,
} from '../modules/obituaries/core/_models'
import {ObituaryFinalQuestionMessage} from './useObituaryQuestionsWebsocket'

function useObituaryPartialUpdater(
  obituary_unique_id: string,
  formType: IAvailableFormType,
  callback: (
    data:
      | PersonalInformation
      | Relatives
      | CeremonyDetails
      | Education
      | Career
      | MilitaryService
      | LifeEvents
      | PersonalityAndInterests
      | ObituaryFinalQuestionMessage[]
  ) => void
) {
  const onLoadFormDelta = useCallback(async () => {
    // This is really important. It makes sure we re-render the form with the latest data
    // Otherwise we can get out of date between the connected websocket.
    // Will need a long-term data approach to this.
    try {
      const updatedData = await getObituaryFormPartial(obituary_unique_id, formType)
      if (updatedData) {
        callback(updatedData)
      }
    } catch (error) {
      console.warn(error)
    } finally {
    }
    // callback here leads to an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obituary_unique_id, formType])

  useEffect(() => {
    onLoadFormDelta()
  }, [onLoadFormDelta])
}

export default useObituaryPartialUpdater
