import { useCallback, useEffect, useRef, useState } from 'react'
import { MenuComponent } from '../../_metronic/assets/ts/components'
import { FuneralHome } from '../modules/obituaries/core/_models'
import { ApiError } from '../modules/auth'
import { adminUpdateFuneralHomeWidgetStatus } from './core/_requests'
import FuneralImageModalWrapper from './upload-media/FuneralImageModalWrapper'
import { useAuth } from '../modules/auth/core/Auth'
import { Link } from 'react-router-dom'

interface FuneralHomeRowOptionsProps {
  funeralHome: FuneralHome
  onUpdateFuneralHome: (funeralHome: FuneralHome) => void
}

const FuneralHomeRowOptions = ({
  funeralHome: defaultFuneralHome,
  onUpdateFuneralHome,
}: FuneralHomeRowOptionsProps) => {
  const { setCurrentPhoenixUser } = useAuth()

  const funeralHomeMenuRef = useRef<HTMLDivElement>(null)

  const [funeralHome, setFuneralHome] = useState<FuneralHome>(defaultFuneralHome)

  const [activeValue, setActiveValue] = useState<string | undefined>(
    funeralHome.widget_is_active ? 'active' : 'inactive'
  )
  const [isSaving, setIsSaving] = useState(false)
  const [saveError, setSaveError] = useState<string | undefined>(undefined)

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const onUpdate = useCallback(async () => {
    if (isSaving || !funeralHomeMenuRef.current) {
      return
    }
    const menuComponent = MenuComponent.getInstance(funeralHomeMenuRef.current)
    if (!menuComponent) {
      console.warn('Unable to get menu component')
      return
    }
    setIsSaving(true)
    try {
      const { funeralHome: updatedFuneralHome } =
        await adminUpdateFuneralHomeWidgetStatus(
          funeralHome.unique_identifier,
          activeValue === 'active' ? true : false
        )
      setFuneralHome(updatedFuneralHome)
      onUpdateFuneralHome(updatedFuneralHome)
      setSaveError(undefined)
      // Close the menu
      menuComponent.hide(funeralHomeMenuRef.current)
    } catch (error: any) {
      console.warn(error)
      const errorMessage =
        error instanceof ApiError ? error.message : 'Unable to update funeral home'
      setSaveError(errorMessage)
    } finally {
      setIsSaving(false)
    }
  }, [activeValue, funeralHome.unique_identifier, isSaving, onUpdateFuneralHome])

  const onSelectFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      setSelectedFile(file)
      e.target.value = ''
    }
  }, [])

  const onFinishedUploadingImage = useCallback(
    (funeralHome: FuneralHome) => {
      setFuneralHome(funeralHome)
      onUpdateFuneralHome(funeralHome)
      setSelectedFile(undefined)
    },
    [onUpdateFuneralHome]
  )

  return (
    <>
      <Link
        type='button'
        className='btn btn-light-primary me-3'
        to={`/admin/clients/${funeralHome.unique_identifier}/overview`}
      >
        Manage
      </Link>
      {/* begin::Filter Button */}
      <button
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div
        ref={funeralHomeMenuRef}
        id={`funeral_home_options_${funeralHome.unique_identifier}_menu`}
        className='menu menu-sub menu-sub-dropdown w-300px w-md-325px pb-10'
        data-kt-menu='true'
      >
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Actions</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        {false && 'logoDisabledForNow' && <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Logo:</label>
            <div>
              <input type='file' accept='image/*' onChange={onSelectFile} />
            </div>
          </div>
        </div>}

        {/* begin::Content */}
        <div className='mt-4 px-7' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Widget Status:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              onChange={(e) => setActiveValue(e.target.value)}
              value={activeValue}
            >
              <option value='inactive'>Inactive</option>
              <option value='active'>Active</option>
            </select>
          </div>
          {/* end::Input group */}
          {saveError && (
            <div>
              <div className='alert alert-danger'>{saveError}</div>
            </div>
          )}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isSaving}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
            >
              Cancel
            </button>
            <button
              disabled={isSaving}
              type='button'
              onClick={onUpdate}
              className='btn btn-primary fw-bold px-6'
            >
              {isSaving ? 'Updating...' : 'Update'}
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
      {selectedFile && (
        <FuneralImageModalWrapper
          funeralHome={funeralHome}
          setFuneralHome={onFinishedUploadingImage}
          file={selectedFile}
          onClose={() => {
            setSelectedFile(undefined)
          }}
        />
      )}
    </>
  )
}

export default FuneralHomeRowOptions
