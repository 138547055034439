import { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'

import usePageTitle from '../hooks/usePageTitle'
import { useShareableObituary } from './ShareableView'
import { replaceNewLinesWithParagraphs } from '../modules/obituaries/components/ObituaryWriteSection'
import { getObituaryByShareableCode } from '../modules/obituaries/core/_requests'
import CopyToClipboardButton from '../components/CopyToClipboardButton'

function FullObituary() {
  // This is only rendered below if the obituary has been written

  const { obituary } = useShareableObituary()

  return (
    <div className='pt-2'>
      {obituary.active_image_uploads.length > 0 && (
        <div className='mb-5 d-flex align-items-center'>
          {obituary.active_image_uploads.map((image) => {
            return (
              <div key={`image-${image.unique_id}`} className='flex-column text-center me-4'>
                <div
                  className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'
                  style={{ backgroundColor: '#f3f6f9' }}
                >
                  <img
                    src={image.image_url}
                    alt={image.original_file_name}
                    className='img-fluid'
                    style={{ width: 'auto' }}
                  />
                </div>
                <div>
                  <a href={`${image.image_url}`} target='_blank' download rel='noreferrer'>
                    View Full Size
                  </a>
                  <a
                    href={`${process.env.REACT_APP_API_URL}/obituaries/${obituary.unique_identifier}/images/${image.unique_id}/download/`}
                    target='_blank'
                    rel='noreferrer'
                    className='ms-2'
                  >
                    Download
                  </a>
                </div>
              </div>
            )
          })}
        </div>
      )}
      <div className='d-flex justify-content-center align-items-center'>
        <CopyToClipboardButton text={obituary.full_obituary_text || ''} tooltip='Copy Obituary' />
      </div>
      <div className='separator separator-dashed my-8'></div>
      <div
        style={{
          fontSize: '1.5rem',
          lineHeight: '2rem',
        }}
        dangerouslySetInnerHTML={{
          __html: replaceNewLinesWithParagraphs(obituary.full_obituary_text || ''),
        }}
      ></div>
      <div className='separator separator-dashed my-8'></div>
      <div className='d-flex justify-content-center align-items-center'>
        <CopyToClipboardButton text={obituary.full_obituary_text || ''} tooltip='Copy Obituary' />
        {false && 'disabledUntilDownloadingFixed' && <>
          <a
            href={`${process.env.REACT_APP_API_URL}/obituaries/${obituary.unique_identifier}/download/pdf/`}
            className='btn btn-secondary me-2'
            target='_blank'
            rel='noreferrer'
          >
            Download PDF
          </a>
          <a
            href={`${process.env.REACT_APP_API_URL}/obituaries/${obituary.unique_identifier}/download/txt/`}
            className='btn btn-secondary me-2'
            target='_blank'
            rel='noreferrer'
          >
            Download as Text
          </a>
        </>}
        {false && (
          <button type='button' className='btn btn-secondary' onClick={() => { }}>
            Send in Email
          </button>
        )}
      </div>
    </div>
  )
}

function FinalizeAndSend() {
  // This is the "Shareable Obituary"'s finalize and send page

  const { obituary, setObituary, obituaryWebsocket } = useShareableObituary()

  usePageTitle({
    title: `Finalize ${obituary.getSimpleName()}'s Obituary`,
  })

  useEffect(() => {
    if (obituaryWebsocket) {
      obituaryWebsocket.sendPageView('finalize_obituary')
    }
  }, [obituaryWebsocket])

  const onReloadObituary = useCallback(async () => {
    if (!obituary) {
      return
    }
    try {
      // This is called in the `Write Obituary` tab
      // We use `getObituaryByShareableCode` cause it's new and better
      const { obituary: updatedObituary } = await getObituaryByShareableCode(obituary.unique_identifier)
      setObituary(updatedObituary)
    } catch (error) {
      console.warn(error)
    }
  }, [obituary.unique_identifier, setObituary])

  useEffect(() => {
    onReloadObituary()
  }, [onReloadObituary])

  return (
    <div className='pt-2 pb-15 pt-md-5 pb-md-10'>
      {!obituary.full_obituary_text ? (
        <div className='alert alert-warning'>
          <div className='alert-text font-weight-bold text-dark'>
            Before finalizing and downloading you must{' '}
            <Link
              to={`/o/${obituary.shareable_code}/write`}
              className='ms-1 me-1 text-dark text-decoration-underline'
            >
              write the obituary here
            </Link>
          </div>
        </div>
      ) : (
        <>
          <FullObituary />
        </>
      )}
    </div>
  )
}

export default FinalizeAndSend
