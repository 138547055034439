import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import {
  KnownFamilyMember,
  SimplifiedPhoenixUser,
  SimplifiedPhoenixUserPhone,
} from '../../obituaries/core/_models'

export const SHOULD_USE_NEW_API = true

const API_URL = process.env.REACT_APP_API_URL

export const REGISTER_URL = `${API_URL}/register/`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password/`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(`${API_URL}/login/`, {
    email,
    password,
  })
}

export function loginWithMagicLink(email: string) {
  return axios
    .post<AuthModel>(`${process.env.REACT_APP_NEXT_API_URL}/obituaries/login/magic-link`, {
      email,
    })
    .then((res) => res.data)
    .then((response: any) => {
      return {
        success: response.data.success,
      }
    })
}

export function loginWithMagicLinkForObituary(email: string, obituaryUniqueId: string) {
  // This is the only route implemented right now...
  return axios
    .post<AuthModel>(`${process.env.REACT_APP_NEXT_API_URL}/obituaries/login/magic-link`, {
      email,
      obituaryUniqueId,
    })
    .then((res) => res.data)
    .then((response: any) => {
      return {
        success: response.data.success,
      }
    })
}

export function loginAndConfirmMagicLinkCode(
  emailAddress: string,
  code: string,
  additionalData?: any
) {
  return axios
    .post(`${process.env.REACT_APP_NEXT_API_URL}/obituaries/login/magic-link/confirm`, {
      emailAddress,
      code,
      ...(additionalData || {}),
    })
    .then((res) => res.data)
    .then((response: any) => {
      if (!response.api_token) {
        throw new Error('No api_token in response')
      }
      return {
        auth: response as AuthModel,
        myRelationship:
          response.metadata && response.metadata.my_relationship
            ? new KnownFamilyMember(response.metadata.my_relationship)
            : null,
      }
    })
}

export function loginWithPhone(phoneNumber: string, additionalData?: any) {
  return axios
    .post(`${process.env.REACT_APP_NEXT_API_URL}/obituaries/login/phone`, {
      phoneNumber,
      ...(additionalData || {}),
    })
    .then((res) => res.data)
    .then((response: any) => {
      if (!response.data.formatted_phone_number) {
        throw new Error('No formattedPhoneNumber in response')
      }
      return {
        success: response.data.success,
        formattedPhoneNumber: response.data.formatted_phone_number,
      }
    })
}

export function loginAndConfirmPhoneCode(phoneNumber: string, code: string, additionalData?: any) {
  return axios
    .post(`${process.env.REACT_APP_NEXT_API_URL}/obituaries/login/phone/confirm`, {
      phoneNumber,
      code,
      ...(additionalData || {}),
    })
    .then((res) => res.data)
    .then((response: any) => {
      if (!response.api_token) {
        throw new Error('No api_token in response')
      }
      return {
        auth: response as AuthModel,
        myRelationship:
          response.metadata && response.metadata.my_relationship
            ? new KnownFamilyMember(response.metadata.my_relationship)
            : null,
      }
    })
}

export function loginFacebook({
  fbAccessToken,
  dataAccessExpirationTime,
}: {
  fbAccessToken: string
  dataAccessExpirationTime: number
}) {
  return axios.post<AuthModel>(`${API_URL}/login_fb/`, {
    fbAccessToken,
    dataAccessExpirationTime,
  })
}

export function loginGoogle({googleAccessToken}: {googleAccessToken: string}) {
  return axios.post<AuthModel>(`${API_URL}/login_google/`, {
    googleAccessToken,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  // Moving away from this...
  return axios
    .post<UserModel>(`${process.env.REACT_APP_NEXT_API_URL}/obituaries/user-by-token`, {
      api_token: token,
    })
    .then((res) => res.data)
    .then((response: any) => {
      return new SimplifiedPhoenixUser(response.data)
    })
}

export function getUserPhoneByToken(token: string) {
  return axios
    .post<UserModel>(`${process.env.REACT_APP_NEXT_API_URL}/obituaries/user-by-token`, {
      api_token: token,
    })
    .then((res) => res.data)
    .then((response: any) => {
      return new SimplifiedPhoenixUserPhone(response.data)
    })
}

export function updateUserType(data: any) {
  return axios
    .post<UserModel>(
      `${process.env.REACT_APP_NEXT_API_URL}/obituaries/onboarding/update-user-type`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new UserModel(response.data)
    })
}

export function skipFuneralHomePlace() {
  return axios
    .post<UserModel>(`${API_URL}/onboarding/skip-funeral-home/`)
    .then((res) => res.data)
    .then((response: any) => {
      return new UserModel(response.data)
    })
}

export function resetUserType() {
  return axios
    .post<UserModel>(`${API_URL}/onboarding/reset-user-type/`)
    .then((res) => res.data)
    .then((response: any) => {
      return new UserModel(response.data)
    })
}
