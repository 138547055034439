import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { adminSubmitFRMOauthCode } from "./core/_requests"
import { ApiError } from "../modules/auth"

function ManageFRMOauth() {

    const navigate = useNavigate()

    const [loadingError, setLoadingError] = useState<string | null>(null)

    const tryToLoadCode = useCallback(async () => {
        const queryParameters = new URLSearchParams(window.location.search)
        const code = queryParameters.get('code')
        if (!code) {
            navigate('/admin/frm')
            return
        }
        try {
            const result = await adminSubmitFRMOauthCode(code)
            if (!result.frm_oauth_access_token) {
                throw new Error('Something went wrong (invalid response)')
            }
            navigate(`/admin/frm/manage-token/${result.frm_oauth_access_token.unique_identifier}`)
            return
        } catch (error: any) {
            console.warn('error', error)
            const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
            setLoadingError(errorMessage)
        }
    }, [navigate])

    useEffect(() => {
        tryToLoadCode()
    }, [tryToLoadCode])

    if (loadingError) {
        return <div className='alert alert-danger'>{loadingError || 'Something went wrong'}</div>
    }

    return (
        <div className='ms-4 spinner-border' role='status'>
            <span className='visually-hidden'>Loading...</span>
        </div>
    )
}

export default ManageFRMOauth