import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ApiError } from '../../../auth'
import {
  getObituaryFinalQuestionsSummary,
  generateObituaryFinalQuestionsSummary,
  updateIncludeSummaryInObituary,
} from '../../core/_requests'
import { ObituaryFinalQuestionSummary } from '../../inspiration_passage/types'
import { useObituaryDetail } from '../ObituaryDetail'
import { replaceNewLinesWithParagraphs } from '../ObituaryWriteSection'

const Container = styled.div``

const Content = styled.div``

function ObituaryFinalQuestionsSummary() {
  const { obituary } = useObituaryDetail()

  const [loadingSummary, setLoadingSummary] = useState(true)
  const [errorLoadingSummary, setErrorLoadingSummary] = useState<string | null>(null)
  const [resultingSummary, setResultingSummary] = useState<ObituaryFinalQuestionSummary | null>(
    null
  )

  const [summarizing, setSummarizing] = useState(false)
  const [errorSummarizing, setErrorSummarizing] = useState<string | null>(null)

  const onGenerateSummary = useCallback(async () => {
    setSummarizing(true)
    setErrorSummarizing(null)
    try {
      const { final_question_summary: updatedSummary } = await generateObituaryFinalQuestionsSummary(
        obituary.unique_identifier
      )
      setErrorSummarizing(null)
      setResultingSummary(updatedSummary)
    } catch (error: any) {
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setErrorSummarizing(errorMessage)
    } finally {
      setSummarizing(false)
    }
  }, [obituary.unique_identifier])

  const onLoadSummary = useCallback(async () => {
    setLoadingSummary(true)
    setErrorLoadingSummary(null)
    try {
      const { final_question_summary: updatedSummary } = await getObituaryFinalQuestionsSummary(
        obituary.unique_identifier
      )
      setErrorLoadingSummary(null)
      setResultingSummary(updatedSummary)
    } catch (error: any) {
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setErrorLoadingSummary(errorMessage)
    } finally {
      setLoadingSummary(false)
    }
  }, [obituary.unique_identifier])

  useEffect(() => {
    onLoadSummary()
  }, [onLoadSummary])

  return (
    <Container className='w-100 h-100 p-8'>
      <Content>
        {loadingSummary ? (
          <div className='position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {errorLoadingSummary ? (
              <div className='alert alert-danger' role='alert'>
                <div className='alert-text'>{errorLoadingSummary}</div>
              </div>
            ) : (
              <div>
                {!resultingSummary ? (
                  <>
                    <div className='text-center'>
                      {errorSummarizing ? (
                        <div className='alert alert-danger' role='alert'>
                          <div className='alert-text'>{errorSummarizing}</div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <h4>No summary has been created</h4>
                      <div className='text-center'>
                        <div className='separator separator-dashed my-7'></div>
                        <button
                          className='btn btn-primary'
                          onClick={onGenerateSummary}
                          disabled={summarizing}
                        >
                          {!summarizing ? 'Generate Summary' : 'Generating...'}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-start justify-content-center p-5 pt-10'>
                    <div className='text-center'>
                      <h4>Conversation Summary</h4>
                      <div className='text-center'>
                        <div className='separator separator-dashed my-7'></div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: replaceNewLinesWithParagraphs(resultingSummary.summary || ''),
                          }}
                        />
                        <div className='separator separator-dashed my-7'></div>
                        {/* Add a checkbox and label for if we should include this summary in the obituary */}
                        <div className='d-flex align-items-center justify-content-center'>
                          <div className='form-check form-switch'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='includeSummary'
                              checked={resultingSummary.include_in_obituary}
                              onChange={() => {
                                const newValue = !resultingSummary.include_in_obituary
                                setResultingSummary({
                                  ...resultingSummary,
                                  include_in_obituary: newValue,
                                })
                                // Firing and forgetting the API update:
                                updateIncludeSummaryInObituary(obituary.unique_identifier, {
                                  summaryUniqueId: resultingSummary.unique_id,
                                  newValue,
                                })
                              }}
                            />
                            <label className='form-check-label' htmlFor='includeSummary'>
                              Include in Obituary
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </Content>
    </Container>
  )
}

export default ObituaryFinalQuestionsSummary
