import { Route, Routes, Navigate } from 'react-router-dom'

import { MainUserLayout } from '../../_metronic/layout/MainUserLayout'
import AdminHome from '../admin/AdminHome'
import AdminDashboard from '../admin/AdminDashboard'
import AddFuneralHome from '../admin/AddFuneralHome'
import AdminHeader from '../admin/AdminHeader'
import ManageSlack from '../admin/ManageSlack'
import ClientDetailRoutes from '../admin/clients/ClientDetailRoutes'
import ManageFRM from '../admin/ManageFRM'
import ManageFRMOauth from '../admin/ManageFRMOauth'
import ManageFRMOauthToken from '../admin/ManageFRMOauthToken'

const PrivateAdminRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <MainUserLayout>
            <AdminHeader />
          </MainUserLayout>
        }
      >
        {/* /admin/home */}
        <Route path='home' element={<AdminHome />} />
        {/* /admin/dashboard */}
        <Route path='dashboard' element={<AdminDashboard />} />
        {/* /clients/:client_unique_id/* */}
        <Route path='clients/:client_unique_id/*' element={<ClientDetailRoutes />} />
        <Route path='funeral-homes/new' element={<AddFuneralHome />} />
        <Route path='frm/oauth/callback' element={<ManageFRMOauth />} />
        <Route path='frm/manage-token/:token_unique_id' element={<ManageFRMOauthToken />} />
        {/* /admin/frm */}
        <Route path='frm' element={<ManageFRM />} />
        <Route path='slack' element={<ManageSlack />} />
        <Route path='*' element={<Navigate to='/admin/home' replace />} />
      </Route>
    </Routes>
  )
}

export default PrivateAdminRoutes
