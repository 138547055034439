import styled from 'styled-components'

const ContentColumn = styled.div``

function replaceNewLinesWithBreaks(text: string) {
  return text.split('\n').map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    )
  })
}

function TitleAndDescriptionItem({title, description}: {title: string; description: string}) {
  return (
    <div className='row'>
      <ContentColumn className='col-12 pt-3'>
        <h1 className='text-center mb-8 mt-2 mt-md-0'>{title}</h1>
        <p className='text-center fs-2'>{replaceNewLinesWithBreaks(description)}</p>
      </ContentColumn>
    </div>
  )
}

export default TitleAndDescriptionItem
