class InspirationalPassageData {
  scripture_group: {title: string; value: string}
  scripture_item: {title: string; value: string}

  constructor(data: any) {
    this.scripture_group = data.scripture_group
    this.scripture_item = data.scripture_item
  }
}

export class InspirationalPassage {
  unique_identifier: string
  inserted_at: string
  updated_at: string

  text_content: string
  type_of_derivative_work: 'scripture' // We may end up abstracting derivative works so this could be useful

  data: InspirationalPassageData

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at

    this.text_content = data.text_content
    this.type_of_derivative_work = data.type_of_derivative_work

    this.data = new InspirationalPassageData(data.data)
  }
}

export class ObituaryFinalQuestionSummary {
  unique_id: string
  inserted_at: string
  updated_at: string

  summary: string
  include_in_obituary: boolean

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at

    this.summary = data.summary
    this.include_in_obituary = data.include_in_obituary
  }
}
