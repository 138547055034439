function GravemarkerPromotionalVideo() {
  return (
    <div>
      <iframe
        src='https://player.vimeo.com/video/804379523?h=6b4539abe4'
        width='640'
        height='360'
        frameBorder='0'
        allow='autoplay; fullscreen; picture-in-picture'
        allowFullScreen
        title='Custom Granite Memorials'
      ></iframe>
      <p>
        <a href='https://vimeo.com/804379523'>Custom Granite Memorials</a> from{' '}
        <a href='https://vimeo.com/user155269709'>Bereave</a> on{' '}
        <a href='https://vimeo.com'>Vimeo</a>.
      </p>
    </div>
  )
}

export default GravemarkerPromotionalVideo
