import React, { } from 'react'
import styled from 'styled-components'
import { Routes, Route, Outlet, Navigate, Link, useLocation } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'
import ReactPlayer from 'react-player'

import { useAuth } from '../../auth/core/Auth'
import PersonalizeWelcomeVideo from './PersonalizeWelcomeVideo'
import PersonalizeWelcomeVideoRecord from './PersonalizeWelcomeVideoRecord'
import PersonalizeWelcomeVideoCustomizeUpload from './PersonalizeWelcomeVideoCustomizeUpload'
import ExistingWelcomeVideos from './ExistingWelcomeVideos'

const CreateAvatarLayoutContainer = styled.div`
  max-width: 820px;
  margin: 0 auto;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  video {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 8px;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    overflow: hidden;
  }
`;

interface CloneVoiceContextParams {
}

const CloneVoiceContext = React.createContext<CloneVoiceContextParams>({
})

export const usePersonalizeContext = () => React.useContext(CloneVoiceContext)

type RouteItem = { path: string; title: string };

function PersonalizeProvider({ basePath }: { basePath: string }) {

  const location = useLocation()

  const routes = [
    {
      path: `${basePath}/intro`,
      title: 'Introduction',
    },
    {
      path: `${basePath}/personalize-welcome-video`,
      title: 'Create Welcome Video',
    },
    {
      path: `${basePath}/existing-welcome-videos`,
      title: 'Existing Welcome Videos',
    },
    false && {
      path: `${basePath}/instructions`,
      title: 'Instructions',
    },
    false && {
      path: `${basePath}/upload`,
      title: 'Upload',
    },
    false && {
      path: `${basePath}/consent`,
      title: 'Consent',
    },
    false && {
      path: `${basePath}/submit`,
      title: 'Submit',
    },
  ].filter(Boolean) as RouteItem[];

  return <CreateAvatarLayoutContainer className='card card-custom card-stretch shadow mb-5 p-8'>
    <div className='mb-8'>
      <div className='d-flex overflow-auto h-55px'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          {routes.map((item, key) => {
            return <li className='nav-item' key={key}>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname.startsWith(item.path) && 'active')
                }
                to={item.path}
              >
                {item.title}
              </Link>
            </li>
          })}
        </ul>
      </div>
    </div>
    <Outlet />
  </CreateAvatarLayoutContainer>
}

function CreateAvatarIntro({ nextUrl }: { nextUrl: string }) {
  return <div>
    <StyledReactPlayer
      url="https://uploads.obituaries.ai/dev/cli_zbx62H4OmmSMUp4AmSCXR/2023/10/05/jup_W3xnOtPRlBpS5Pv11HAvK/final_video_v3.mp4"
      loop={false}
      controls
      width='100%'
      style={{ minHeight: '440px' }}
    />
    <div className='mt-5 text-center'>
      <h4 className='my-10'>
        Welcome & Introduction
      </h4>
      <div>
        <Link
          className='btn btn-primary'
          to={nextUrl}
        >
          Get Started
        </Link>
      </div>
    </div>
  </div>
}


const RecommendationsContainer = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
`;

const EqualListHeight = styled.ul`
  display: flex;
  flex-direction: column;

  li {
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  li span.bold {
    font-weight: bold;
  }
`;

function CreateAvatarInstructions({ nextUrl }: { nextUrl: string }) {
  return <div>
    <RecommendationsContainer className='d-flex align-items-flex-start'>
      <div style={{ width: '50%' }} className='me-5'>
        <div className='mb-5 text-center'>
          <h4>👍 Recommendations</h4>
        </div>
        <EqualListHeight>
          <li>Record around <span className="bold">30 seconds</span> of footage</li>
          <li>Use your<span className="bold">highest quality webcam</span></li>
          <li>Ensure <span className="bold">good lighting</span> and a <span className="bold">quiet environment</span></li>
          <li><span className="bold">Look straight into the camera</span></li>
          <li><span className="bold">Pause</span> between each sentence with your <span className="bold">mouth closed</span></li>
          <li>Use <span className="bold">generic gestures</span> and keep hands below your chest</li>
        </EqualListHeight>
      </div>
      <div style={{ width: '50%' }} className='ms-5'>
        <div className='mb-5 text-center'>
          <h4>🚫 Things to Avoid</h4>
        </div>
        <EqualListHeight>
          <li>Stitches or cuts to your footage</li>
          <li>Talking without breaks or pauses</li>
          <li>Moving your position during the recording</li>
          <li>Loud background noises</li>
          <li>Shadows and overexposure on your face</li>
          <li>Diverting your gaze or looking around</li>
          <li>Hand gestures above the chest</li>
          <li>Use of directional or pointing gestures</li>
        </EqualListHeight>
      </div>
    </RecommendationsContainer>
    <div className='alert alert-info mt-4' role='alert'>
      💡 Tips: Speak freely on any topic in your preferred language. Just focus on being yourself and looking into the camera—leave the rest to us 😊.
    </div>
    <div className='mt-10 text-center'>
      <Link className='btn btn-primary' to={nextUrl}>Let's Begin</Link>
    </div>
  </div>
}

function CreateAvatarUpload() {
  return <div>
    CreateAvatarUpload
  </div>
}

function CreateAvatarConsent() {
  return <div>
    CreateAvatarConsent
  </div>
}

function CreateAvatarSubmit() {
  return <div>
    CreateAvatarSubmit
  </div>
}

function CreateAvatarProvider() {
  const params = useParams()
  const navigate = useNavigate()
  const { auth } = useAuth()

  const basePath = '/personalize'

  return (
    <CloneVoiceContext.Provider
      value={{}}
    >
      <Routes>
        <Route
          element={
            <>
              <PersonalizeProvider basePath={basePath} />
            </>
          }
        >
          <Route path='intro' element={<CreateAvatarIntro nextUrl={`${basePath}/personalize-welcome-video`} />} />
          <Route path='personalize-welcome-video' element={<PersonalizeWelcomeVideo basePath={`${basePath}/personalize-welcome-video`} />} />
          <Route path='personalize-welcome-video/upload' element={<PersonalizeWelcomeVideoRecord basePath={`${basePath}/personalize-welcome-video`} />} />
          <Route path='personalize-welcome-video/record' element={<PersonalizeWelcomeVideoRecord basePath={`${basePath}/personalize-welcome-video`} />} />
          <Route path='personalize-welcome-video/customize/:journey_personalization_unique_id' element={<PersonalizeWelcomeVideoCustomizeUpload basePath={`${basePath}/personalize-welcome-video`} />} />
          <Route path='existing-welcome-videos' element={<ExistingWelcomeVideos sendToBasePath={`${basePath}/personalize-welcome-video/customize`} />} />
          <Route path='instructions' element={<CreateAvatarInstructions nextUrl={`${basePath}/upload`} />} />
          <Route path='upload' element={<CreateAvatarUpload />} />
          <Route path='consent' element={<CreateAvatarConsent />} />
          <Route path='submit' element={<CreateAvatarSubmit />} />
          <Route path='*' element={<Navigate to={`${basePath}/intro`} replace />} />
        </Route>
      </Routes>
    </CloneVoiceContext.Provider >
  )
}

export default CreateAvatarProvider
