import { useCallback, useEffect, useState } from 'react'
import { AdminSlackApiData, AdminSlackResponse } from './core/_admin_slack_response'
import {
  adminGetSlackConfiguration,
  adminUpdateSlackConfiguration,
  adminTestSlackCategory,
} from './core/_requests'
import { ApiError } from '../modules/auth'

function SlackConversationSelector({
  api_data,
  enabled,
  to_channel_id,
  saving_category,
}: {
  api_data: AdminSlackApiData
  enabled?: boolean
  to_channel_id?: string
  saving_category: 'funeral_home' | 'obituary'
}) {
  const [isEnabled, setIsEnabled] = useState<boolean>(enabled || false)
  const [selectedConversationId, setSelectedConversationId] = useState<string>(to_channel_id || '')

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isTesting, setIsTesting] = useState<boolean>(false)

  const onFormSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setIsSaving(true)
      try {
        await adminUpdateSlackConfiguration({
          saving_category,
          post_about_funeral_home: saving_category === 'funeral_home' ? isEnabled : undefined,
          funeral_home_channel_id:
            saving_category === 'funeral_home' ? selectedConversationId : undefined,
          post_about_obituary: saving_category === 'obituary' ? isEnabled : undefined,
          obituary_channel_id: saving_category === 'obituary' ? selectedConversationId : undefined,
        })
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
      }
    },
    [isEnabled, selectedConversationId, saving_category]
  )

  const onTest = useCallback(async () => {
    setIsTesting(true)
    try {
      await adminTestSlackCategory({
        saving_category,
        post_about_funeral_home: saving_category === 'funeral_home' ? isEnabled : undefined,
        funeral_home_channel_id:
          saving_category === 'funeral_home' ? selectedConversationId : undefined,
        post_about_obituary: saving_category === 'obituary' ? isEnabled : undefined,
        obituary_channel_id: saving_category === 'obituary' ? selectedConversationId : undefined,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsTesting(false)
    }
  }, [isEnabled, selectedConversationId, saving_category])

  return (
    <form onSubmit={onFormSubmit}>
      {/* Label with checkbox: */}
      <div className='form-check form-switch d-flex align-items-center'>
        <input
          className='form-check-input'
          type='checkbox'
          id='flexSwitchCheckDefault'
          checked={isEnabled}
          onChange={(event) => {
            setIsEnabled(event.target.checked)
          }}
        />
        <label className='form-check-label fs-2 ms-3' htmlFor='flexSwitchCheckDefault'>
          Post to Slack?
        </label>
      </div>
      <div className='my-3'>
        <select
          className='form-select form-select-solid'
          aria-label='Default select example'
          value={selectedConversationId}
          onChange={(event) => {
            setSelectedConversationId(event.target.value)
          }}
        >
          <option value=''>Select a conversation</option>
          {api_data.conversations.map((conversation) => {
            // If it's a channel, that's the name.
            // otherwise, we lookup the user by id.
            let channelName = conversation.name
            if (!channelName) {
              // For now, we're only supporting DMs.
              if (conversation.is_im) {
                if (conversation.user) {
                  channelName = `DM with ${api_data.users_by_id[conversation.user].real_name}`
                } else {
                  channelName = 'DM with unknown user'
                }
              }
            }
            return (
              <option key={conversation.id} value={conversation.id}>
                {channelName}
              </option>
            )
          })}
        </select>
      </div>
      <>
        <div>
          <button className='btn btn-primary btn-sm' type='submit' disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save'}
          </button>
        </div>
      </>
      <div className='mt-4'>
        <button
          type='button'
          className='btn btn-secondary btn-sm'
          onClick={onTest}
          disabled={isTesting}
        >
          {isTesting ? 'Testing...' : 'Test'}
        </button>
      </div>
    </form>
  )
}

function ManageSlack() {
  const [slackConfiguration, setSlackConfiguration] = useState<AdminSlackResponse | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingError, setLoadingError] = useState<string | null>(null)

  const getSlackConfiguration = useCallback(async () => {
    try {
      const updatedSlackConfiguration = await adminGetSlackConfiguration()
      setSlackConfiguration(updatedSlackConfiguration)
    } catch (error: any) {
      console.warn('error', error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setLoadingError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getSlackConfiguration()
  }, [getSlackConfiguration])

  if (loading) {
    return (
      <div className='ms-4 spinner-border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    )
  }

  if (loadingError || !slackConfiguration) {
    return <div className='alert alert-danger'>{loadingError || 'Something went wrong'}</div>
  }

  return (
    <div>
      <h1>Obituary Posting</h1>
      <SlackConversationSelector
        api_data={slackConfiguration.api_data}
        enabled={slackConfiguration.slack_configuration.post_about_obituary}
        to_channel_id={slackConfiguration.slack_configuration.obituary_channel_id}
        saving_category='obituary'
      />
      <div className='separator separator-dashed my-6'></div>
      <h1>Funeral Home Posting</h1>
      <SlackConversationSelector
        api_data={slackConfiguration.api_data}
        enabled={slackConfiguration.slack_configuration.post_about_funeral_home}
        to_channel_id={slackConfiguration.slack_configuration.funeral_home_channel_id}
        saving_category='funeral_home'
      />
    </div>
  )
}

export default ManageSlack
