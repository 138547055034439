import { useState, useCallback, useEffect } from 'react'
import { IAvailableFormType } from '../../../obituaries/useObituaryWebsocket'
import { ApiError } from '../../auth'
import { ObituarySalesInsight } from '../core/_models'
import { generateInsightsForFormType, getObituaryInsightsByTopInterests } from '../core/_requests'
import { useObituaryDetail } from './ObituaryDetail'
import clsx from 'clsx'
import ProgressBarInSeconds from '../ProgressBarInSeconds'

function TopInterests() {
  const { obituary } = useObituaryDetail()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const [generatingTopInsights, setGeneratingTopInsights] = useState(false)
  const [errorGeneratingTopInsights, setErrorGeneratingTopInsights] = useState<string | null>(null)

  // I don't love the KnownFamilyMember naming but here we are

  const [insights, setInsights] = useState<ObituarySalesInsight[] | null>(null)
  const [canGenerateInsights, setCanGenerateInsights] = useState<boolean | null>(null)

  const onLoadInsights = useCallback(async () => {
    try {
      const { insights: updatedInsights, can_generate_insights: updatedCanGenerateInsights } =
        await getObituaryInsightsByTopInterests(obituary.unique_identifier)
      console.log(updatedInsights)
      setInsights(updatedInsights)
      setCanGenerateInsights(updatedCanGenerateInsights)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [obituary.unique_identifier])

  const onGenerateInsights = useCallback(async () => {
    setGeneratingTopInsights(true)
    setErrorGeneratingTopInsights(null)
    try {
      const { insights: updatedInsights, can_generate_insights: updatedCanGenerateInsights } =
        await generateInsightsForFormType(obituary.unique_identifier, 'top-interests')
      setInsights(updatedInsights)
      setCanGenerateInsights(updatedCanGenerateInsights)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setErrorGeneratingTopInsights(errorMessage)
    } finally {
      setGeneratingTopInsights(false)
    }
  }, [obituary.unique_identifier])

  useEffect(() => {
    onLoadInsights()
  }, [onLoadInsights])

  if (loading) {
    return (
      <div>
        <div className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='alert alert-danger' role='alert'>
        <div className='alert-text'>{error}</div>
      </div>
    )
  }

  return (
    <div>
      {/* Render a vertical list of sections */}
      <div className='list-group'>
        {insights && insights.length > 0 ? (
          <>
            {insights.map((insight, index) => {
              return (
                <li
                  key={`insight-${insight.unique_id}`}
                  className='list-group-item d-flex justify-content-between align-items-start border-0'
                >
                  <div className='ms-2 me-auto'>
                    <div className='fs-5 d-flex gap-5'>
                      <span className='user-select-none'>{index + 1}. </span>
                      <span>{`${insight.text}`}</span>
                    </div>
                  </div>
                </li>
              )
            })}
          </>
        ) : (
          <>
            {/* No insights for this section */}
            {!canGenerateInsights && (
              <li className='list-group-item d-flex justify-content-between align-items-start border-0'>
                <div className='ms-2 me-auto'>
                  <div className='fs-5'>(top interests are not available)</div>
                </div>
              </li>
            )}
          </>
        )}
        {canGenerateInsights && (
          <div className='mt-1 ps-6 mb-2'>
            {errorGeneratingTopInsights && (
              <div className='alert alert-danger' role='alert'>
                <div className='alert-text'>{errorGeneratingTopInsights}</div>
              </div>
            )}
            <button
              type='button'
              className={clsx(
                'btn btn-sm',
                insights?.length === 0 ? 'btn-primary' : 'btn-secondary'
              )} // TODO: make this a 'btn-outline-primary
              disabled={generatingTopInsights}
              onClick={onGenerateInsights}
            >
              {!generatingTopInsights
                ? insights?.length === 0
                  ? 'Generate Top Interests'
                  : 'Re-Generate Top Interests'
                : 'Generating...'}
            </button>
            {generatingTopInsights && (
              <div className='mt-4'>
                <ProgressBarInSeconds
                  message='Generating top interests...'
                  estimatedTimeInSeconds={3}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default TopInterests
