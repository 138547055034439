import { useState, useEffect } from "react";
import styled, { keyframes, css } from 'styled-components';

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const SubmissionErrorContainer = styled.div<{ animate: string }>`
    position: relative;
    z-index: 2;
    margin: 8px 8px 0 8px;
    animation: ${props => {
        switch (props.animate) {
            case 'down':
                return css`${slideDown} 0.5s ease-in-out forwards`;
            case 'up':
                return css`${slideUp} 0.5s ease-in-out forwards`;
            default:
                return 'none';
        }
    }};
`;

function NodeSubmissionError({ message, type }: { message?: string | null, type: 'error' | 'success' }) {
    const [animate, setAnimate] = useState<'none' | 'down' | 'up'>('none');
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (message) {
            setShowMessage(true);
            setAnimate('down');
            const timer1 = setTimeout(() => {
                setAnimate('up');
            }, 2000);
            const timer2 = setTimeout(() => {
                setAnimate('none');
                setShowMessage(false);
            }, 2500);
            return () => {
                clearTimeout(timer1);
                clearTimeout(timer2);
            };
        }
    }, [message]);

    if (!message || !showMessage) {
        return null;
    }

    return (
        <SubmissionErrorContainer animate={animate}>
            <div className={`alert alert-${type === 'error' ? 'danger' : 'success'}`} role='alert'>
                <strong>{type === 'error' ? 'Oh no!' : 'Great!'}</strong> {message}
            </div>
        </SubmissionErrorContainer>
    );
}


export default NodeSubmissionError;