import {useEffect} from 'react'
import {
  ObituaryFormActionsChanged,
  ObituaryWebsocket,
  handleIncomingWebsocketMessage,
} from './useObituaryWebsocket'

function useSimpleFormActionChanged(
  obituaryWebsocket: ObituaryWebsocket | null,
  callback: (message: ObituaryFormActionsChanged) => void
) {
  useEffect(() => {
    if (obituaryWebsocket) {
      if (obituaryWebsocket.websocket) {
        const handleMessage = (message: any) => {
          // First we parse the message. We use handleIncomingWebsocketMessage:
          const parsedMessage = handleIncomingWebsocketMessage(message)
          if (parsedMessage instanceof ObituaryFormActionsChanged) {
            callback(parsedMessage)
            return
          }
        }
        obituaryWebsocket.websocket.addEventListener('message', handleMessage)
        return () => {
          if (obituaryWebsocket.websocket) {
            obituaryWebsocket.websocket.removeEventListener('message', handleMessage)
          }
        }
      }
    }
  }, [callback, obituaryWebsocket])
}

export default useSimpleFormActionChanged
