import usePageTitle from '../hooks/usePageTitle'
import { ObituaryWriteDragAndDrop } from '../modules/obituaries/components/ObituaryWriteDragAndDropWrapper'
import { useShareableObituary } from './ShareableView'

/*
pathname.startsWith('/o matters below for dark-mode search in the future
*/
/* Dark mode was #1a1a1a, now it's #f6f8fa */
/* color was #ececf1 now it's #1a1a1a */

function WriteObituary() {
  const { obituary, setObituary, obituaryWebsocket } = useShareableObituary()

  usePageTitle({
    title: `Write ${obituary.getSimpleName()}'s Obituary`,
  })

  return (
    <div className='pt-5' style={{ paddingBottom: '6rem' }}>
      <ObituaryWriteDragAndDrop
        obituary={obituary}
        setObituary={setObituary}
        obituaryWebsocket={obituaryWebsocket}
        sectionBackgroundColor='#f6f8fa'
        linkToUpdateBio={`/o/${obituary.shareable_code}/details`}
        linkToRelativesSection={`/o/${obituary.shareable_code}/details/relatives`}
        linkToServiceDetails={`/o/${obituary.shareable_code}/details/ceremony`}
        isShareableObituary
      />
    </div>
  )
}

export default WriteObituary
