import React, { useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'

function LazilyLoadedReactPlayer({
    videoUrl: defaultVideoUrl,
    autoPlay = false,
    loop = false,
    muted = true,
    controls = false,
}: {
    videoUrl: string,
    autoPlay?: boolean,
    loop?: boolean,
    muted?: boolean,
    controls?: boolean,
}) {

    const videoPlayRef = useRef<ReactPlayer>(null)

    const [reloadAttempts, setReloadAttempts] = React.useState(0)

    const [videoUrl, setVideoUrl] = React.useState(defaultVideoUrl)

    const [isPlaying, setIsPlaying] = React.useState(false)

    // Maybe consider preload: 'metadata'. 'none' as a last resort.

    // We are having issues with React Flow having 4+ videos on the page at once.

    useEffect(() => {
        // If defaultVideoUrl changes, reset everything.
        setReloadAttempts(0)
        setVideoUrl(defaultVideoUrl)
        setIsPlaying(false)
    }, [defaultVideoUrl])

    return <>
        <ReactPlayer
            ref={videoPlayRef}
            autoPlay={autoPlay}
            loop={loop}
            muted={muted}
            url={videoUrl}
            controls={controls}
            width="100%"
            height="100%"
            onError={(error) => {
                console.warn('ReactPlayer onError', error)
                if (reloadAttempts > 5) {
                    console.warn('ReactPlayer onError reloadAttempts > 5, giving up')
                    return
                }
                // We don't seem to need this with programmatic playing now?
                const waitToAdjustUrlByMS = reloadAttempts === 0 ? 0 : 1000 * reloadAttempts
                setTimeout(() => {
                    setReloadAttempts(prevAttempts => prevAttempts + 1);

                    const urlObject = new URL(defaultVideoUrl);
                    const params = new URLSearchParams(urlObject.search);

                    params.set('reloadAttempts', reloadAttempts.toString());

                    urlObject.search = params.toString();

                    setVideoUrl(urlObject.toString());

                    videoPlayRef.current?.seekTo(0);
                    // setIsPlaying(true);
                }, waitToAdjustUrlByMS);
            }}
            config={{
                file: {
                    attributes: {
                        preload: 'auto'
                    }
                }
            }}
            playing={isPlaying}
        />
        {!isPlaying && false && 'disabledButWeMayWantAnOverlayOneDay' && <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 1, background: 'rgba(0, 0, 0, 0.8)' }} onClick={() => {
            videoPlayRef.current?.seekTo(0)
            setIsPlaying(true)
        }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center' }}>
                <div style={{ fontSize: '2rem' }}>Click to Play</div>
            </div>
        </div>}
    </>
}

export default LazilyLoadedReactPlayer