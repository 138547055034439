import React, {useEffect} from 'react'
import {useFormikContext} from 'formik'
import {IObituaryCreationForm} from './ObituaryBiographicalFormCreate'

export const localStorageKeys = {
  OBITUARY_INPUT_FORM: 'obituary-input-form',
}

export function notEmpty<TValue>(value: TValue): value is NonNullable<TValue> {
  return value !== null && value !== undefined && !!value // Pretty sure the last !! call is necessary (checks JS truthiness)
}

/*
checkTrues looks like: 
{
  nickname_is_preferred: {
    updateStateFunction: someFunction,
  }
}
*/

interface PersistFormProps {
  initialFormValues: IObituaryCreationForm
  checkTrues: {
    [key: string]: {
      updateStateFunction: (value: boolean) => void
    }
  }
  checkDates: {
    [key: string]: {
      updateStateFunction: (value: Date | null) => void
    }
  }
}

function PersistForm({initialFormValues, checkTrues, checkDates}: PersistFormProps) {
  //
  // This seems to be the recommended way to persist form state
  // https://github.com/jaredpalmer/formik/issues/271
  //

  const {values, submitForm, setValues} = useFormikContext()
  const stringifiedValues = JSON.stringify(values) // Important for nested objects

  useEffect(() => {
    let saveTimeout: number | null = null

    if (saveTimeout) {
      clearTimeout(saveTimeout)
    }

    saveTimeout = setTimeout(() => {
      //
      // Could probably check to see if we have changed anything
      // before doing this//
      //
      // We keep this checkout form separate from appState for now,
      // because it may be useful to handle it differently.
      //
      // We likely need to combine them.
      //
      const hasAFormValue = Object.values(values as any).some(notEmpty)
      if (hasAFormValue) {
        // Check if dev (we're using Next) and log if so
        if (process.env.NODE_ENV === 'development') {
          console.log('Saving form state to localStorage', values)
        }
        localStorage.setItem(localStorageKeys.OBITUARY_INPUT_FORM, JSON.stringify(values))
      } else {
        localStorage.removeItem(localStorageKeys.OBITUARY_INPUT_FORM)
      }
      /*
      Abandonment?
      if (copilot.appState.channel) {
        copilot.appState.channel.push("event", {
          event: "update_contact_information",
          data: hasAFormValue ? values : null,
        });
      }
      */
    }, 500) as any

    return () => {
      if (saveTimeout) {
        clearTimeout(saveTimeout)
      }
    }
  }, [stringifiedValues, submitForm, values])

  useEffect(() => {
    const tryToLoadFromLocalStorage = () => {
      try {
        const formValuesString = localStorage.getItem(localStorageKeys.OBITUARY_INPUT_FORM)
        if (formValuesString) {
          const formValues = JSON.parse(formValuesString)
          // checkTrues sets state values based on the loaded form.
          const checkTruesKeys = Object.keys(checkTrues)
          for (const key of checkTruesKeys) {
            if (formValues[key]) {
              checkTrues[key].updateStateFunction(!!formValues[key])
            }
          }
          // checkDates sets state values based on the loaded form.
          const checkDatesKeys = Object.keys(checkDates)
          for (const key of checkDatesKeys) {
            if (formValues[key]) {
              checkDates[key].updateStateFunction(new Date(formValues[key]))
            }
          }
          setValues({...initialFormValues, ...formValues})
        }
      } catch (e) {
        return
      }
    }
    tryToLoadFromLocalStorage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValues])

  return null
}

export default PersistForm
