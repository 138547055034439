/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'

import { getUserByToken, login, loginWithPhone, loginAndConfirmPhoneCode, loginWithMagicLink, loginAndConfirmMagicLinkCode, loginWithMagicLinkForObituary } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { ApiError } from '..'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { GOOGLE_OAUTH_CLIENT_ID } from '../../../App'
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import { useShareableObituary } from '../../../obituaries/ShareableView'
import TitleAndDescriptionItem from '../../../obituaries/TitleAndDescriptionItem'
import useEmailFromUrl from '../../../hooks/useEmailFromUrl'



const emailMagicCodeSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
})

const phoneNumberSchema = Yup.object().shape({
  phoneNumber: Yup.string().required('Phone number is required'),
})

const phoneConfirmSchema = Yup.object().shape({
  code: Yup.string().required('Code is required'),
})


const initialEmailMagicCodeValues = {
  email: '',
}

const initialPhoneNumberValues = {
  phoneNumber: '',
}

const initialPhoneConfirmValues = {
  code: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

function PhoneNumberLoginForm({
  setVerifyingPhone,
}: {
  setVerifyingPhone: (phoneNumber: string) => void
}) {
  const [loading, setLoading] = useState(false)
  const { saveAuth } = useAuth()

  const formik = useFormik({
    initialValues: initialPhoneNumberValues,
    validationSchema: phoneNumberSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { formattedPhoneNumber } = await loginWithPhone(values.phoneNumber)
        setVerifyingPhone(formattedPhoneNumber)
      } catch (error: any) {
        console.warn(error)
        const errorMessage = error instanceof ApiError ? error.message : 'Invalid login information'
        saveAuth(undefined)
        setStatus(errorMessage)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit}>
      {formik.status ? (
        <div className='mb-lg-5 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <>
          {false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>
                Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
                continue.
              </div>
            </div>
          )}
        </>
      )}

      <div className='mb-5'>
        {/* begin::Form group */}
        <div className='fv-row'>
          <label className='form-label fs-6 fw-bolder text-dark'>Phone Number</label>
          <input
            placeholder='Phone Number'
            {...formik.getFieldProps('phoneNumber')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber },
              {
                'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
              }
            )}
            name='phoneNumber'
            required
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.phoneNumber}</span>
            </div>
          )}
        </div>
      </div>

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Login with Phone Number</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {false && (
        <div className='text-center'>
          <div className='text-gray-400 fw-bold fs-4'>
            <Link to='/auth/registration' className='link-primary fw-bolder'>
              Create an Account
            </Link>
          </div>
        </div>
      )}
    </form>
  )
}

export function SocialLoginForm() {

  const { saveAuth, setCurrentPhoenixUser } = useAuth()

  const [error, setError] = useState<string | null>(null)
  const [continuingWithGoogle, setContinuingWithGoogle] = useState(false)

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      setContinuingWithGoogle(true)
      try {
        const { data: { data: auth } } = await axios.post(
          `${process.env.REACT_APP_NEXT_API_URL}/auth/login_google`, {
          googleAccessToken: codeResponse.code,
        });
        if (!auth.api_token) {
          throw new Error('Malformed response', auth)
        }
        saveAuth(auth)
        const user = await getUserByToken(auth.api_token)
        setCurrentPhoenixUser(user)
        // The app will redirect to authenticated routes so we don't
        // set setContinuingWithGoogle(false) here
      } catch (error: any) {
        console.warn(error)
        const errorMessage = error instanceof ApiError ? error.message : 'Invalid login information'
        setError(errorMessage)
        setContinuingWithGoogle(false)
      }
    },
    onError: errorResponse => console.log(errorResponse),
  });

  return <div className='mb-5'>
    {error && (
      <div className='mb-lg-5 alert alert-danger'>
        <div className='alert-text font-weight-bold'>{error}</div>
      </div>
    )}
    <button
      type='button'
      onClick={googleLogin}
      className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'
      disabled={continuingWithGoogle}
    >
      <img
        alt='Logo'
        src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
        className='h-20px me-3'
      />
      {!continuingWithGoogle ? 'Continue with Google' : 'Continuing with Google...'}
    </button>
    <div className='mb-5'>
      {/* end::Google link */}
      {false && 'socialLinks' && (
        <>
          {/* begin::Facebook link */}
          {false && (
            <>
              {/*<button
                  type='button'
                  onClick={() => {
                    onContinueWithFacebook({
                      onCancel: () => {},
                      onSuccess: ({authResponse, userResponse}) => {},
                      onError: (message?: string) => {
                        formik.setStatus(message || 'Something went wrong')
                      },
                    })
                  }}
                  className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
                    className='h-20px me-3'
                  />
                  Continue with Facebook
                </button>*/}
            </>
          )}
          {/* end::Facebook link */}
          {/* begin::Apple link */}
          {false && (
            <>
              {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
                  className='h-20px me-3'
                />
                Continue with Apple
            </a>*/}
            </>
          )}
          {/* end::Apple link */}
        </>
      )}
    </div>
  </div>
}

export function EmailLoginForm({
  confirmEmailCodeRoute, forObituaryUniqueId
}: {
  confirmEmailCodeRoute: string, forObituaryUniqueId?: string
}) {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const { saveAuth } = useAuth()

  const formik = useFormik({
    initialValues: initialEmailMagicCodeValues,
    validationSchema: emailMagicCodeSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        if (forObituaryUniqueId) {
          await loginWithMagicLinkForObituary(values.email, forObituaryUniqueId)
        } else {
          await loginWithMagicLink(values.email)
        }
        navigate(`${confirmEmailCodeRoute}?e=${values.email}`)
        return;
      } catch (error: any) {
        console.warn(error)
        const errorMessage = error instanceof ApiError ? error.message : 'Invalid login information'
        saveAuth(undefined)
        setStatus(errorMessage)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit}>
      {formik.status ? (
        <div className='mb-lg-5 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <>
          {false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>
                Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
                continue.
              </div>
            </div>
          )}
        </>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Enter your email address</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
          required
        />
        <div className='fv-plugins-message-container my-5'>
          <span role='alert' className='text-muted'>
            We will email you a code to login
          </span>
        </div>
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      {/* See version control for password functionality ... */}
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          className='btn btn-lg btn-secondary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Send me a code to login</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {false && (
        <div className='text-center'>
          <div className='text-gray-400 fw-bold fs-4'>
            <Link to='/auth/registration' className='link-primary fw-bolder'>
              Create an Account
            </Link>
          </div>
        </div>
      )}
    </form>
  )
}

function PhoneVerifyForm({
  verifyingPhone,
  onCancel,
}: {
  verifyingPhone: string
  onCancel: () => void
}) {
  const [loading, setLoading] = useState(false)
  const { saveAuth } = useAuth()

  const formik = useFormik({
    initialValues: initialPhoneConfirmValues,
    validationSchema: phoneConfirmSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { auth } = await loginAndConfirmPhoneCode(verifyingPhone, values.code)
        saveAuth(auth)
        // setCurrentPhoenixUser(user)
        throw new Error('need to fix phone auth')
      } catch (error: any) {
        console.warn(error)
        const errorMessage = error instanceof ApiError ? error.message : 'Unable to login'
        saveAuth(undefined)
        setStatus(errorMessage)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const loginAndSendCode = async (phone: string) => {
    try {
      await loginWithPhone(phone)
      // Set success
      formik.setStatus('Code sent')
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Unable to send code'
      saveAuth(undefined)
      formik.setStatus(errorMessage)
    }
  }

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit}>
      {formik.status ? (
        <>
          {formik.status !== 'Code sent' ? (
            <div className='mb-lg-5 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div className='mb-lg-5 alert alert-success'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
        </>
      ) : (
        <>
          {false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>
                Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
                continue.
              </div>
            </div>
          )}
        </>
      )}

      <div className='mb-5'></div>

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Phone Number</label>
        <div className='fs-1'>{verifyingPhone}</div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Verification Code</label>
            {/* end::Label */}
            {/* begin::Link */}
            {false && (
              <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{ marginLeft: '5px' }}
              >
                Forgot Password ?
              </Link>
            )}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('code')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.code && formik.errors.code,
            },
            {
              'is-valid': formik.touched.code && !formik.errors.code,
            }
          )}
          required
          autoFocus
          placeholder='Enter verification code'
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.code}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Confirm Code</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* begin:: Resend Code */}
      <div className='text-center'>
        <button
          type='button'
          className='btn btn-lg btn-light-primary w-100 mb-5'
          onClick={() => {
            loginAndSendCode(verifyingPhone)
          }}
        >
          Resend Code
        </button>
      </div>

      {/* begin::Cancel Button */}
      <div className='text-center'>
        <button
          type='button'
          className='btn btn-lg btn-link fs-6'
          onClick={() => {
            onCancel()
          }}
        >
          Cancel
        </button>
      </div>
      {/* end::Cancel Button */}

      {false && (
        <div className='text-center'>
          <div className='text-gray-400 fw-bold fs-4'>
            <Link to='/auth/registration' className='link-primary fw-bolder'>
              Create an Account
            </Link>
          </div>
        </div>
      )}
    </form>
  )
}


export function EmailVerifyForm({
  emailAddressBeingVerified,
  onCancel,
}: {
  emailAddressBeingVerified: string
  onCancel?: () => void
}) {

  // This will break in the generic auth case I think

  const {
    obituary,
  } = useShareableObituary()

  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentPhoenixUser } = useAuth()

  const formik = useFormik({
    initialValues: initialPhoneConfirmValues,
    validationSchema: phoneConfirmSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: { data: auth } } = await axios.post(
          `${process.env.REACT_APP_NEXT_API_URL}/obituaries/login/magic-link/confirm`, {
          emailAddress: emailAddressBeingVerified,
          code: values.code,
        });
        if (!auth.api_token) {
          throw new Error('Malformed response', auth)
        }
        saveAuth(auth)
        const user = await getUserByToken(auth.api_token)
        setCurrentPhoenixUser(user)
      } catch (error: any) {
        console.warn(error)
        const errorMessage = error instanceof ApiError ? error.message : 'Unable to login'
        saveAuth(undefined)
        setStatus(errorMessage)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const onResendCode = async (emailAddress: string) => {
    try {
      await loginWithMagicLinkForObituary(emailAddress, obituary.unique_identifier)
      // Set success
      formik.setStatus('Code sent')
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Unable to send code'
      saveAuth(undefined)
      formik.setStatus(errorMessage)
    }
  }

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit}>
      {formik.status ? (
        <>
          {formik.status !== 'Code sent' ? (
            <div className='mb-lg-5 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div className='mb-lg-5 alert alert-success'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
        </>
      ) : (
        <>
          {false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>
                Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
                continue.
              </div>
            </div>
          )}
        </>
      )}

      <div className='mb-5'></div>

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email Address</label>
        <div className='fs-1'>{emailAddressBeingVerified}</div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Verification Code</label>
            {/* end::Label */}
            {/* begin::Link */}
            {false && (
              <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{ marginLeft: '5px' }}
              >
                Forgot Password ?
              </Link>
            )}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('code')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.code && formik.errors.code,
            },
            {
              'is-valid': formik.touched.code && !formik.errors.code,
            }
          )}
          required
          autoFocus
          placeholder='Enter verification code'
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.code}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Confirm Code</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* begin:: Resend Code */}
      <div className='text-center'>
        <button
          type='button'
          className='btn btn-lg btn-light-primary w-100 mb-5'
          onClick={() => {
            onResendCode(emailAddressBeingVerified)
          }}
        >
          Resend Code
        </button>
      </div>

      {/* begin::Cancel Button */}
      {onCancel && <div className='text-center'>
        <button
          type='button'
          className='btn btn-lg btn-link fs-6'
          onClick={() => {
            onCancel()
          }}
        >
          Cancel
        </button>
      </div>}
      {/* end::Cancel Button */}

      {false && (
        <div className='text-center'>
          <div className='text-gray-400 fw-bold fs-4'>
            <Link to='/auth/registration' className='link-primary fw-bolder'>
              Create an Account
            </Link>
          </div>
        </div>
      )}
    </form>
  )
}

export function Login({ verifyingEmail = false }: { verifyingEmail?: boolean }) {
  const navigate = useNavigate()

  const { emailAddress } = useEmailFromUrl()

  const [verifyingPhone, setVerifyingPhone] = useState<string | null>(null)

  if (verifyingPhone) {
    // May use one day
    return (
      <>
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Confirm Phone</h1>
          <p className='text-gray-400 fw-bold fs-4'>Enter the code sent to your phone number:</p>
        </div>
        {/* begin::Heading */}
        <PhoneVerifyForm verifyingPhone={verifyingPhone} onCancel={() => setVerifyingPhone(null)} />
      </>
    )
  }

  if (verifyingEmail) {
    if (!emailAddress) {
      return <Navigate to={`/auth`} />
    }
    return (
      <>
        <div className='text-center mb-10'>
          <TitleAndDescriptionItem
            title={`Confirm Email`}
            description={`We have sent you an email with a link to confirm your email address. Please check your inbox and click the link to confirm your email address.`}
          />
          <div>
            <div className='mt-6'>
              <EmailVerifyForm emailAddressBeingVerified={emailAddress} onCancel={() => {
                navigate(`/dashboard`)
              }} />
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Log in or create an account</h1>
        <p className='text-gray-400 fw-bold fs-4'>
          Quickly get started by signing in using your existing accounts:
        </p>
      </div>
      {/* begin::Heading */}
      {false && <PhoneNumberLoginForm setVerifyingPhone={setVerifyingPhone} />}
      <SocialLoginForm />
      {/* begin::Separator */}
      <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div>
      {/* end::Separator */}
      <EmailLoginForm confirmEmailCodeRoute='/auth/login/code-confirm' />
    </>
  )
}
