import React, { useCallback, useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { JourneyPersonalization } from '../../../funeral_homes/dashboard/core/journey_classes'
import { getJourneyPersonalization } from '../../../funeral_homes/dashboard/core/_requests'
import { ApiError } from '../../auth'
import JourneyPersonalizationEditForm from './JourneyPersonalizationEditForm'
import JourneyPersonalizationPreviewModal from './JourneyPersonalizationPreviewModal'

interface CustomizeJourneyPersonalizationParams {
    basePath: string
    journeyPersonalization: JourneyPersonalization | null,
    previewIsActive: boolean
    setPreviewIsActive: (previewIsActive: boolean) => void
}

const CustomizeJourneyPersonalizationContext = React.createContext<CustomizeJourneyPersonalizationParams>({
    basePath: '',
    journeyPersonalization: null,
    previewIsActive: false,
    setPreviewIsActive: () => { }
})

export const useJourneyPersonalizationDetail = () => React.useContext(CustomizeJourneyPersonalizationContext)

function PersonalizeWelcomeVideoCustomizeUpload({ basePath }: { basePath: string }) {

    const { journey_personalization_unique_id: journeyPersonalizationUniqueId } = useParams()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const [journeyPersonalization, setJourneyPersonalization] = useState<JourneyPersonalization | null>(null)
    const [previewIsActive, setPreviewIsActive] = useState(false)

    const loadPersonalization = useCallback(async () => {
        if (!journeyPersonalizationUniqueId) {
            return
        }
        setLoading(true);
        setError(null)
        try {
            const updatedJourneyPersonalization = await getJourneyPersonalization(journeyPersonalizationUniqueId)
            setJourneyPersonalization(updatedJourneyPersonalization)
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof ApiError ? exception.message : 'Something went wrong'
            setError(`Unable to get personalized welcome video: ${errorMessage}`)
        } finally {
            setLoading(false)
        }
    }, [journeyPersonalizationUniqueId, setLoading, setError])

    useEffect(() => {
        loadPersonalization()
    }, [loadPersonalization])

    if (!journeyPersonalizationUniqueId) {
        return <Navigate to={`${basePath}`} />
    }

    if (loading) {
        return <div className='d-flex justify-content-center'>
            <div className='spinner-border text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
            </div>
        </div>
    }

    if (error) {
        return <div className='alert alert-danger'>
            {error}
        </div>
    }

    return <CustomizeJourneyPersonalizationContext.Provider value={{
        basePath,
        journeyPersonalization,
        previewIsActive,
        setPreviewIsActive
    }}>
        <JourneyPersonalizationEditForm />
        {previewIsActive && <JourneyPersonalizationPreviewModal onClose={() => setPreviewIsActive(false)} />}
    </CustomizeJourneyPersonalizationContext.Provider>
}

export default PersonalizeWelcomeVideoCustomizeUpload