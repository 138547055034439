import clsx from 'clsx'
import {useEffect, useState} from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1a1a1a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgb(255 255 255 / 9%) 0px 0px 4px 4px;
  z-index: 1000;
  /* Animate the translation: */
  transition: transform 0.3s;
  /* If the element is at it's default state, translate it 0 in the Y axis */
  transform: translateY(calc(100vh - 45px));
  /* If the element is expanded, translate it 100% in the Y axis */
  &.expanded {
    transform: translateY(0);
  }
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const ExpandedContent = styled.div`
  /* Opacity is 0 by default */
  opacity: 0;
  /* Animate the opacity: */
  transition: opacity 0.5s;
  /* If the element is expanded, make it visible */
  &.expanded {
    opacity: 1;
  }
`

function ConnectedUsers() {
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    // Maybe have an event listener?
    if (expanded) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [expanded])

  return (
    <Container className={expanded ? 'expanded' : ''}>
      <div className='container h-100'>
        <div className='row h-100'>
          <div className='col-12 col-md-6 offset-md-3'>
            <div className='row'>
              <div className='col-12'>
                <div
                  className='fs-5 text-center text-white p-4 cursor-pointer position-relative'
                  onClick={() => {
                    setExpanded(!expanded)
                  }}
                >
                  Chris, Christi, and 2 others connected
                  {expanded && (
                    <div className='position-absolute top-0 end-0 p-4'>
                      <i className='bi bi-x-circle-fill text-white fs-4'></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <ExpandedContent className={clsx('row h-100', expanded ? 'expanded' : '')}>
              <div className='col-12 h-100'>
                <div className='fs-5 text-center text-white'>
                  <span className='text-danger'>Chris</span> is typing... Foo Bar
                </div>
              </div>
            </ExpandedContent>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default ConnectedUsers
