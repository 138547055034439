import clsx from 'clsx'
import { useState, useCallback, useEffect } from 'react'
import { HelpCircle } from 'react-feather'
import styled from 'styled-components'

import { ApiError } from '../auth'
import { useObituaryDetail } from './components/ObituaryDetail'
import { GratitudeAlphabet } from './gratitude_alphabet/types'
import { TitleContainer } from './PersonalizedPoemComponent'
import ProgressBarInSeconds from './ProgressBarInSeconds'
import { generateGratitudeAlphabet, getGratitudeAlphabets } from '../../funeral_homes/dashboard/core/_requests'
import useObituaryChannel from '../../obituaries/next/useObituaryChannel'
import { replaceNewLinesWithParagraphs } from './components/ObituaryWriteSection'

// selectableOptions is an array of key, value

interface GratitudeAlphabetComponentProps {
  title: string
  helpText?: string
}

const LineBreakWrapper = styled.div`
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`

const formatMessage = (message: string) => {
  return message.split('\n').map((line, index) => {
    return <LineBreakWrapper key={index}>{line ? line : <p className='py-2'></p>}</LineBreakWrapper>
  })
}

function GratitudeAlphabetViewer({
  gratitude_alphabets,
}: {
  gratitude_alphabets: GratitudeAlphabet[]
}) {
  // Only show the first gratitude alphabet for now.
  const [gratitudeAlphabet, setGratitudeAlphabet] = useState<GratitudeAlphabet>(gratitude_alphabets[0])

  const [, setGratitudeAlphabetIsBeingWritten] = useState(false)
  const [textBeingWritten, setTextBeingWritten] = useState('')

  const {
    obituaryChannel
  } = useObituaryChannel()

  useEffect(() => {
    if (!obituaryChannel) {
      return;
    }
    obituaryChannel.on('started_writing_gratitude_alphabet', (msg) => {
      setGratitudeAlphabetIsBeingWritten(true)
      setTextBeingWritten('...')
    })
    obituaryChannel.on('graitude_alphabet_writing_update', (msg) => {
      if (msg.payload?.text_content) {
        setTextBeingWritten(replaceNewLinesWithParagraphs(msg.payload.text_content))
      }
    })
    // In this case our check is simpler (just the type)
    obituaryChannel.on('finished_writing', (msg) => {
      if (msg.payload?.derivative_work?.type_of_derivative_work === 'gratitude_alphabet') {
        setGratitudeAlphabet(new GratitudeAlphabet(msg.payload.derivative_work))
        console.warn('Need to update list...')
      }
    })
  }, [obituaryChannel])


  return (
    <div>
      <div className='text-justify fs-4'>
        {textBeingWritten ? <>
          <div dangerouslySetInnerHTML={{ __html: textBeingWritten }}></div>
        </> : <>
          {gratitudeAlphabet.text_content
            ? formatMessage(gratitudeAlphabet.text_content)
            : `...`}
        </>}
      </div>
      <div className='separator separator-dashed my-4'></div>
    </div>
  )
}

function GratitudeAlphabetComponent({
  title,
  helpText = undefined,
}: GratitudeAlphabetComponentProps) {
  const { obituary } = useObituaryDetail()

  const [viewingHelpText, setViewingHelpText] = useState(false)

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const [generatingGratitudeAlphabet, setGeneratingGratitudeAlphabet] = useState(false)
  const [errorGeneratingAlphabet, setErrorGeneratingAlphabet] = useState<string | null>(null)

  const [gratitudeAlphabets, setGratitudeAlphabets] = useState<GratitudeAlphabet[]>([])
  const [canGenerateAlphabet, setCanGenerateAlphabet] = useState<boolean | null>(null)

  const onLoadMemorialItem = useCallback(async () => {
    try {
      const upgradeGratitudeAlphabets = await getGratitudeAlphabets(obituary.unique_identifier)
      setGratitudeAlphabets(upgradeGratitudeAlphabets)
      setCanGenerateAlphabet(true)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [obituary.unique_identifier])

  const onGenerateGratitudeAlphabet = useCallback(async () => {
    setGeneratingGratitudeAlphabet(true)
    setErrorGeneratingAlphabet(null)
    try {
      const { gratitude_alphabet: newGratitudeAlphabet } = await generateGratitudeAlphabet(
        obituary.unique_identifier
      )
      setGratitudeAlphabets([newGratitudeAlphabet, ...gratitudeAlphabets])
      setCanGenerateAlphabet(true)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setErrorGeneratingAlphabet(errorMessage)
    } finally {
      setGeneratingGratitudeAlphabet(false)
    }
  }, [obituary.unique_identifier])

  useEffect(() => {
    onLoadMemorialItem()
  }, [onLoadMemorialItem])

  return (
    <div>
      <TitleContainer>
        <h2 className='m-0 p-0'>{title}</h2>
        {helpText ? (
          <button
            type='button'
            className='btn btn-sm btn-icon btn-icon-primary'
            onClick={() => setViewingHelpText(!viewingHelpText)}
          >
            <HelpCircle />
          </button>
        ) : (
          <div style={{ height: 38 }} />
        )}
      </TitleContainer>
      {viewingHelpText && (
        <div className='alert alert-info' role='alert'>
          <div className='alert-text'>{helpText}</div>
        </div>
      )}
      <div className='separator separator-dashed my-7'></div>
      {loading ? (
        <>
          <div>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>
              <div className='alert alert-danger' role='alert'>
                <div className='alert-text'>{error}</div>
              </div>
            </>
          ) : (
            <>
              {gratitudeAlphabets.length === 0 ? (
                <></>
              ) : (
                <>
                  <GratitudeAlphabetViewer gratitude_alphabets={gratitudeAlphabets} />
                </>
              )}
              {canGenerateAlphabet && (
                <div className='mt-4 text-center'>
                  {errorGeneratingAlphabet && (
                    <div className='alert alert-danger' role='alert'>
                      <div className='alert-text'>{errorGeneratingAlphabet}</div>
                    </div>
                  )}
                  <button
                    type='button'
                    className={clsx(
                      'btn btn-sm',
                      gratitudeAlphabets?.length === 0 ? 'btn-primary' : 'btn-secondary'
                    )} // TODO: make this a 'btn-outline-primary
                    disabled={generatingGratitudeAlphabet}
                    onClick={onGenerateGratitudeAlphabet}
                  >
                    {/* e.g. Re-Generate Sonnet */}
                    {!generatingGratitudeAlphabet
                      ? gratitudeAlphabets?.length === 0
                        ? `Generate Gratitude Alphabet`
                        : `Re-Generate Gratitude Alphabet`
                      : `Generating Gratitude Alphabet`}
                  </button>
                  {generatingGratitudeAlphabet && false && 'disabledInFavorOfStreaming' && (
                    <div className='mt-4'>
                      <ProgressBarInSeconds
                        message='Writing gratitude alphabet...'
                        estimatedTimeInSeconds={10}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default GratitudeAlphabetComponent
