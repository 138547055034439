import {useEffect, useRef} from 'react'
import {Obituary, ObituaryImageUpload} from '../core/_models'

import UploadImagesModal from './UploadImagesModal'

interface UploadMediaModalWrapperProps {
  onClose: (updatedObituary?: Obituary, newUploads?: ObituaryImageUpload[]) => void
}

const UploadImagesModalWrapper: React.FC<UploadMediaModalWrapperProps> = ({onClose}) => {
  useEffect(() => {
    function onClickedBackdrop(e: any) {
      if (e.target?.id === 'kt_modal_upload_media') {
        onClose()
      }
    }
    document.body.classList.add('modal-open')
    document.addEventListener('click', onClickedBackdrop)
    return () => {
      document.body.classList.remove('modal-open')
      document.removeEventListener('click', onClickedBackdrop)
    }
  }, [onClose])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_upload_media'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <UploadImagesModal onClose={onClose} />
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default UploadImagesModalWrapper
