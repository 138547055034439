import clsx from 'clsx'
import {useCallback, useState} from 'react'

export interface ToneSelectionOption {
  value: string
  label: string
  shortDescription: string
  description: string
  additional_chat_context: string
}

const toneSelections: ToneSelectionOption[] = [
  {
    value: 'conversational',
    label: 'Conversational',
    shortDescription: 'Friendly & Easygoing',
    description:
      'An obituary written in a friendly, easygoing tone that mimics the natural flow of conversation while honoring the deceased.',
    additional_chat_context:
      'Conversational obituaries use a casual and informal language, like talking to a friend.',
  },
  {
    value: 'celebratory',
    label: 'Celebratory',
    shortDescription: 'Joyful & Accomplished',
    description:
      "An obituary written in a joyful tone that focuses on the deceased's accomplishments and the positive aspects of their life.",
    additional_chat_context:
      "Celebratory obituaries emphasize the deceased's accomplishments and the joy they brought to others, creating a positive tone.",
  },
  {
    value: 'formal',
    label: 'Formal',
    shortDescription: 'Traditional & Respectful',
    description:
      'An obituary written in a traditional and respectful tone that adheres to established conventions while paying tribute to the deceased.',
    additional_chat_context:
      'Formal obituaries follow a structured format and use polite, respectful language.',
  },
  {
    value: 'reflective',
    label: 'Reflective',
    shortDescription: 'Contemplative & Thoughtful',
    description:
      "An obituary written in a contemplative tone that encourages readers to consider the deceased's life and legacy in a thoughtful manner.",
    additional_chat_context:
      "Reflective obituaries focus on deep emotions and encourage readers to ponder the deceased's life lessons.",
  },
  {
    value: 'humorous',
    label: 'Humorous',
    shortDescription: 'Light-hearted & Witty',
    description:
      'An obituary written in a witty and light-hearted tone, showcasing humor in the narrative while paying tribute to the deceased.',
    additional_chat_context:
      "Humorous obituaries incorporate jokes, funny anecdotes, and a lighthearted perspective on the deceased's life.",
  },
  {
    value: 'inspirational',
    label: 'Inspirational',
    shortDescription: 'Uplifting & Motivating',
    description:
      "An obituary written in an uplifting tone that emphasizes the positive aspects of the deceased's life, inspiring others to follow their example.",
    additional_chat_context:
      "Inspirational obituaries highlight the deceased's achievements and positive traits, encouraging others to emulate them.",
  },
  {
    value: 'poetic',
    label: 'Poetic',
    shortDescription: 'Artistic & Evocative',
    description:
      "An obituary written in an artistic and creative tone that uses evocative language and imagery to convey the deceased's story.",
    additional_chat_context:
      "Poetic obituaries use metaphor, symbolism, and vivid language to paint a picture of the deceased's life.",
  },
  {
    value: 'narrative',
    label: 'Narrative',
    shortDescription: 'Storytelling & Engaging',
    description:
      "An obituary written in a storytelling tone that weaves the deceased's life story into a cohesive, engaging narrative.",
    additional_chat_context:
      "Narrative obituaries tell the deceased's life story with engaging details, building a connection with the reader.",
  },
  {
    value: 'personal',
    label: 'Personal',
    shortDescription: 'Intimate & Emotional',
    description:
      "An obituary written in an intimate, emotional tone that highlights the personal aspects of the deceased's life and relationships.",
    additional_chat_context:
      "Personal obituaries delve into the deceased's close relationships, emotions, and the impact they had on others.",
  },
  {
    value: 'brief',
    label: 'Brief',
    shortDescription: 'Concise & Direct',
    description:
      "An obituary written in a concise, direct tone that summarizes the deceased's life in a few key details while paying tribute.",
    additional_chat_context:
      "Brief obituaries are 1 paragraph or at most 2 paragraphs, providing a quick summary of the deceased's life.",
  },
]

interface ToneSelectorProps {
  isShareableObituary: boolean
  biographyTone: ToneSelectionOption | null
  setBiographyTone: (tone: ToneSelectionOption | null) => void
  fullWidth?: boolean
}

function ToneSelector({
  isShareableObituary,
  biographyTone,
  setBiographyTone,
  fullWidth = false,
}: ToneSelectorProps) {
  // tone is a selected item from toneSelections

  const onToneChange = useCallback(
    (e: any) => {
      const value = e.target.value
      const tone = toneSelections.find((toneSelection) => toneSelection.value === value)
      setBiographyTone(tone || null)
    },
    [setBiographyTone]
  )

  return (
    <div className='mb-4'>
      <div className='row'>
        <div
          className={clsx(
            'col-12 position-relative',
            fullWidth ? '' : !isShareableObituary ? 'col-lg-4' : 'col-lg-8'
          )}
        >
          <label className='form-label'>Obituary tone:</label>
          <select
            className='form-select form-select-sm'
            value={biographyTone?.value || ''}
            onChange={onToneChange}
          >
            <option value=''>Warm, kind, and compassionate (Default)</option>
            {toneSelections.map((toneSelection) => (
              <option key={toneSelection.value} value={toneSelection.value}>
                {`${toneSelection.label} (${toneSelection.shortDescription})`}
              </option>
            ))}
          </select>
        </div>
      </div>
      {biographyTone && (
        <div className={clsx('row mt-4 ps-0 ps-lg-3')}>
          <div className='col-12'>
            <div className='p-0 m-0 fs-6'>{biographyTone.description}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ToneSelector
