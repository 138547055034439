import { useState, useCallback, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import styled from 'styled-components'

import { ApiError } from '../auth'
import { useObituaryDetail } from './components/ObituaryDetail'
import ProgressBarInSeconds from './ProgressBarInSeconds'
import { InspirationalPassage } from './inspiration_passage/types'
import { generateInspirationalPassages, getInspirationalPassages } from '../../funeral_homes/dashboard/core/_requests'
import useObituaryChannel from '../../obituaries/next/useObituaryChannel'
import { replaceNewLinesWithParagraphs } from './components/ObituaryWriteSection'

export interface SelectableOptionGroupItem {
  value: string
  label: string
}

export interface SelectableOptionGroup {
  is_primary: boolean
  value: string
  title: string
  options: SelectableOptionGroupItem[]
}

export interface SelectableOption {
  value: string
  label: string
  options?: SelectableOptionGroup[]
}

const VerticalTabsContainer = styled.ul`
  display: flex;
  flex-direction: column;

  border: 0;

  .nav-link {
    border-radius: var(--bs-nav-tabs-border-radius) !important;
  }

  .nav-link.active {
    border: 1px solid #d5d5d9;
  }
`

const VerticalTabItem = styled.li`
  list-style: none;
`

const VerticalTabLink = styled.a`
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  cursor: pointer;

  margin-left: 0 !important;

  &:hover {
    background-color: #f5f5f5;
  }

  &.active {
    background-color: #e9ecef;
    font-weight: bold;
    border-left: 3px solid #0d6efd;
  }
`

const LineBreakWrapper = styled.div`
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`

const formatMessage = (message: string) => {
  return message.split('\n').map((line, index) => {
    return <LineBreakWrapper key={index}>{line ? line : <p className='py-2'></p>}</LineBreakWrapper>
  })
}

function InspirationalPassageViewer({ inspirationalPassages, onUpdatedInspirationalPassage }: { inspirationalPassages: InspirationalPassage[], onUpdatedInspirationalPassage: (passage: InspirationalPassage) => void }) {
  // Only show the first poem for now.
  const inspirationalPassage = inspirationalPassages[0]

  const [, setInspirationalPassageBeingWritten] = useState(false)
  const [textBeingWritten, setTextBeingWritten] = useState('')

  const {
    obituaryChannel
  } = useObituaryChannel()

  useEffect(() => {
    if (!obituaryChannel) {
      return;
    }
    obituaryChannel.on('started_writing_scripture', (msg) => {
      setInspirationalPassageBeingWritten(true)
      setTextBeingWritten('...')
    })
    obituaryChannel.on('scripture_writing_update', (msg) => {
      if (msg.payload?.text_content) {
        setTextBeingWritten(replaceNewLinesWithParagraphs(msg.payload.text_content))
      }
    })
    obituaryChannel.on('finished_writing', (msg) => {
      if (msg.payload?.derivative_work?.unique_id === inspirationalPassage.unique_identifier) {
        setInspirationalPassageBeingWritten(false)
        onUpdatedInspirationalPassage(new InspirationalPassage(msg.payload.derivative_work))
      }
    })
  }, [inspirationalPassage.unique_identifier, obituaryChannel, onUpdatedInspirationalPassage])

  useEffect(() => {
    if (inspirationalPassage?.text_content) {
      setTextBeingWritten(replaceNewLinesWithParagraphs(inspirationalPassage.text_content))
    }
  }, [inspirationalPassage?.text_content])

  return (
    <div>
      <div className='separator separator-dashed my-4'></div>
      <div className='text-center fs-2'>
        {textBeingWritten ? <>
          <div dangerouslySetInnerHTML={{ __html: textBeingWritten }}></div>
        </> : <>
          {inspirationalPassage.text_content ? formatMessage(inspirationalPassage.text_content) : `...`}
        </>}
      </div>
      <div className='separator separator-dashed my-4'></div>
    </div>
  )
}

interface InspirationalPassagesProps { }

function InspirationalPassages({ }: InspirationalPassagesProps) {
  const { obituary } = useObituaryDetail()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const [generatingPassages, setGeneratingPassages] = useState(false)
  const [errorGeneratingPassages, setErrorGeneratingPassages] = useState<string | null>(null)

  const [selectedOptionGroup, setSelectedOptionGroup] = useState<SelectableOptionGroup | null>(null)
  const [selectedOptionGroupItem, setSelectedOptionGroupItem] =
    useState<SelectableOptionGroupItem | null>(null)

  const [availableScriptureOptions, setAvailableScriptureOptions] = useState<
    SelectableOptionGroup[]
  >([])
  const [viewingMoreOptions, setViewingMoreOptions] = useState(false)

  const [selectedOption, setSelectedOption] = useState<SelectableOption | null>(
    availableScriptureOptions.length > 0 ? { value: 'scripture', label: 'Scripture' } : null
  )

  const [activeScriptureOption, setActiveScriptureOption] = useState<SelectableOptionGroup | null>(
    availableScriptureOptions.length > 0 ? availableScriptureOptions[0] : null
  )

  const [inspirationalPassages, setInspirationalPassages] = useState<InspirationalPassage[]>([])

  const onLoadInspirationPassages = useCallback(async () => {
    // TODO:
    setLoading(false)
    try {
      const { passages: updatedPassages, scripture_options: updatedScriptureOptions } =
        await getInspirationalPassages(obituary.unique_identifier)
      setAvailableScriptureOptions(updatedScriptureOptions)
      setInspirationalPassages(updatedPassages)
      if (updatedScriptureOptions.length > 0) {
        setSelectedOption({ value: 'scripture', label: 'Scripture' })
        setActiveScriptureOption(updatedScriptureOptions[0])
        if (updatedScriptureOptions[0].options.length > 0) {
          setSelectedOptionGroup(updatedScriptureOptions[0])
          setSelectedOptionGroupItem(updatedScriptureOptions[0].options[0])
        }
      }
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [obituary.unique_identifier])

  const onGeneratePassages = useCallback(async () => {
    setGeneratingPassages(true)
    setErrorGeneratingPassages(null)
    try {
      const { inspirational_passage: updatedPassage } = await generateInspirationalPassages(
        obituary.unique_identifier,
        {
          selectedOption: selectedOption?.value,
          selectedOptionGroup: selectedOptionGroup?.value,
          selectedOptionGroupItem: selectedOptionGroupItem?.value,
        }
      )
      setInspirationalPassages((passages) => [...passages, updatedPassage])
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setErrorGeneratingPassages(errorMessage)
    } finally {
      setGeneratingPassages(false)
    }
  }, [
    obituary.unique_identifier,
    selectedOption?.value,
    selectedOptionGroup?.value,
    selectedOptionGroupItem?.value,
  ])

  useEffect(() => {
    onLoadInspirationPassages()
  }, [onLoadInspirationPassages])

  const onUpdatedInspirationalPassage = useCallback((passage: InspirationalPassage) => {
    setInspirationalPassages((passages) => [...passages.filter((p) => p.unique_identifier !== passage.unique_identifier), passage])
  }, [])

  const activeInspirationalPassages = useMemo(() => {
    return inspirationalPassages.filter(
      (x) =>
        x.type_of_derivative_work === 'scripture' &&
        x.data.scripture_group.value === activeScriptureOption?.value &&
        x.data.scripture_item.value === selectedOptionGroupItem?.value
    )
  }, [inspirationalPassages, activeScriptureOption?.value, selectedOptionGroupItem?.value])

  if (loading) {
    return (
      <div>
        <div className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='alert alert-danger' role='alert'>
        <div className='alert-text'>{error}</div>
      </div>
    )
  }


  return (
    <div className='row'>
      <div className='col-12'>
        <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 ps-4 fs-2'>
          <>
            {availableScriptureOptions
              .filter((x) => (!viewingMoreOptions ? x.is_primary : !x.is_primary))
              .map((option) => {
                // type_of_derivative_work exists but for now is always scripture
                const passageCount = inspirationalPassages.filter(
                  (x) => x.type_of_derivative_work === 'scripture' && x.data.scripture_group.value === option.value
                ).length
                return (
                  <li className='nav-item' key={option.value}>
                    <button
                      type='button'
                      className={clsx(
                        'nav-link d-flex align-items-center justify-content-between',
                        activeScriptureOption?.value === option.value && 'active'
                      )}
                      onClick={() => {
                        setActiveScriptureOption(option)
                        if (option.options.length > 0) {
                          setSelectedOptionGroup(option)
                          setSelectedOptionGroupItem(option.options[0])
                        }
                      }}
                    >
                      {`${option.title}`}
                      {passageCount > 0 && (
                        <span className='badge bg-primary ms-3'>{passageCount}</span>
                      )}
                    </button>
                  </li>
                )
              })}
            {/* Add a "More" tab if there are more than 3 options */}
            {availableScriptureOptions.length > 3 && (
              <li className='nav-item'>
                <button
                  type='button'
                  className='nav-link'
                  onClick={() => {
                    setViewingMoreOptions(!viewingMoreOptions)
                  }}
                >
                  {viewingMoreOptions ? 'Fewer' : 'More'}
                </button>
              </li>
            )}
          </>
        </ul>
      </div>
      <div className='col-12'>
        <div className='tab-content'>
          {availableScriptureOptions.map((option: SelectableOptionGroup) => {
            return (
              <div
                className={clsx(
                  'tab-pane',
                  activeScriptureOption?.value === option.value && 'active'
                )}
                id={option.value}
                key={option.value}
              >
                <div className='row'>
                  <div className='col-12'>
                    <div className='row'>
                      <div className='col-4'>
                        {/* Add another tab bar with all option.options */}
                        <VerticalTabsContainer className='nav nav-tabs fs-6'>
                          {option.options.map((optionItem) => {
                            const passageCount = inspirationalPassages.filter(
                              (x) =>
                                x.type_of_derivative_work === 'scripture' &&
                                x.data.scripture_group.value === option.value &&
                                x.data.scripture_item.value === optionItem.value
                            ).length
                            return (
                              <VerticalTabItem key={optionItem.value} className='nav-item'>
                                <VerticalTabLink
                                  className={clsx(
                                    'nav-link',
                                    selectedOptionGroupItem?.value === optionItem.value && 'active'
                                  )}
                                  onClick={() => {
                                    setSelectedOptionGroupItem(optionItem)
                                  }}
                                >
                                  {`${optionItem.label}`}
                                  {passageCount > 0 && (
                                    <span className='badge bg-primary ms-3'>{passageCount}</span>
                                  )}
                                </VerticalTabLink>
                              </VerticalTabItem>
                            )
                          })}
                        </VerticalTabsContainer>
                      </div>
                      <div className='col-8'>
                        <div className='tab-content'>
                          {!selectedOptionGroupItem ? (
                            <></>
                          ) : (
                            <>
                              {errorGeneratingPassages && (
                                <div>
                                  <div className='alert alert-danger' role='alert'>
                                    <div className='alert-text'>{errorGeneratingPassages}</div>
                                  </div>
                                </div>
                              )}
                              <div className='text-center'>
                                <button
                                  type='button'
                                  className={clsx(
                                    'btn btn-sm',
                                    true ? 'btn-primary' : 'btn-secondary'
                                  )} // TODO: make this a 'btn-outline-primary? Make the true not hardcoded
                                  disabled={generatingPassages}
                                  onClick={onGeneratePassages}
                                >
                                  {/* e.g. Re-Generate Sonnet */}
                                  {!generatingPassages
                                    ? true && 'hardcoded for now'
                                      ? `Generate ${selectedOptionGroupItem.label} Passages`
                                      : `Re-Generate ${selectedOptionGroupItem.label} Passages`
                                    : `Generating ${selectedOptionGroupItem.label} Passages`}
                                </button>
                                {activeInspirationalPassages.length > 0 && (
                                  <InspirationalPassageViewer
                                    inspirationalPassages={activeInspirationalPassages}
                                    onUpdatedInspirationalPassage={onUpdatedInspirationalPassage}
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default InspirationalPassages
