import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
    ChevronLeft
} from 'react-feather'
import Webcam from 'react-webcam';
import { useNavigate } from 'react-router-dom';
import PersonalizeRecordingActions, { PersonalizeRecordingActionsAsDisabledItems } from '../../PersonalizeRecordingActions';
import { createJourneyPersonalization } from '../../../../../funeral_homes/dashboard/core/_requests';
import { ApiError } from '../../../../auth';

const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
`;

const GoBackContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    cursor: pointer;
`;

const GoBackContainerWhileRecording = styled(GoBackContainer)`
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    color: white;
`;

const InstructionsContainer = styled.div`
    width: 100%;
    height: 200px;
    position: relative;
    background-color: rgb(227, 227, 227);
`;

const InstructionsContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    button {
        background-color: white !important;

        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: #f8f9fa !important;
        }
    }
`;

const ActionsContainer = styled.div`
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const WebcamContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background: black;
    height: 200px;
`;

const WebcamContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const PreviewContainer = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: rgba(35, 40, 51, 0.8);
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    font-weight: 600;

    .red-circle {
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 50%;
        margin-right: 8px;
    }
`;

function NodeRecordVideoPreviewItem({ nodeId, onGoBack, recordingIsInProgress, handleBeginRecording, activeUserMediaStream, setActiveUserMediaStream }: {
    nodeId: string;
    onGoBack: () => void;
    recordingIsInProgress: boolean;
    handleBeginRecording: (nodeId: string, skipCountdown?: boolean) => void;
    activeUserMediaStream: MediaStream | null;
    setActiveUserMediaStream: (stream: MediaStream | null) => void;
}) {

    const [videoAndAudioHaveBeenEnabled, setVideoAndAudioHaveBeenEnabled] = useState(false);

    const [videoIsActive, setVideoIsActive] = useState(false);
    const [audioIsActive, setAudioIsActive] = useState(false);

    const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState('');
    const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useState('');

    const [availableVideoDevices, setAvailableVideoDevices] = useState<MediaDeviceInfo[]>([]);
    const [availableAudioDevices, setAvailableAudioDevices] = useState<MediaDeviceInfo[]>([]);

    const fetchDevices = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        const audioDevices = devices.filter(device => device.kind === 'audioinput');
        setAvailableVideoDevices(videoDevices);
        setAvailableAudioDevices(audioDevices);
    };

    useEffect(() => {
        // Initial fetch
        fetchDevices();

        // Listen for changes
        navigator.mediaDevices.addEventListener('devicechange', fetchDevices);

        return () => {
            // Cleanup
            navigator.mediaDevices.removeEventListener('devicechange', fetchDevices);
        };
    }, []);

    return <Container>
        <InstructionsContainer>
            <InstructionsContent>
                <button
                    className='btn btn-outline btn-outline-primary' style={{ border: '1px solid #009ef7' }}
                    type='button'
                    onClick={() => {
                        setVideoAndAudioHaveBeenEnabled(true);
                    }}>
                    Turn on Camera & Mic
                </button>
                <div className='mt-4 text-center text-gray-600 px-8'>
                    Please allow the browser to access your <strong>camera</strong> (480P or higher resolution) and <strong>microphone</strong>
                </div>
            </InstructionsContent>
        </InstructionsContainer>
        {videoAndAudioHaveBeenEnabled && <WebcamContainer>
            <WebcamContent>
                <Webcam
                    audio={true}
                    muted={true}
                    screenshotFormat="image/jpeg"
                    height={'100%'}
                    width={'100%'}
                    videoConstraints={{
                        deviceId: selectedVideoDeviceId ? { exact: selectedVideoDeviceId } : undefined,
                        width: 1280,
                        height: 720,
                        facingMode: "environment",
                    }}
                    audioConstraints={{
                        deviceId: selectedAudioDeviceId ? { exact: selectedAudioDeviceId } : undefined,
                        noiseSuppression: true,
                        echoCancellation: true,
                    }}
                    mirrored={true}
                    onUserMedia={(stream) => {
                        setActiveUserMediaStream(stream);
                        // Todo: check video chunks and audio chunks
                        setVideoIsActive(true);
                        setAudioIsActive(true);
                    }}
                />
                {activeUserMediaStream && !recordingIsInProgress && <PreviewContainer>
                    <span className='red-circle'></span>
                    Preview
                </PreviewContainer>}
            </WebcamContent>
        </WebcamContainer>}
        <ActionsContainer>
            {activeUserMediaStream && <div className='px-4'>
                <div>
                    <div>
                        {availableVideoDevices.length > 0 ? <select className='me-2 form-select form-select-solid form-select-lg'
                            onChange={e => setSelectedVideoDeviceId(e.target.value)}>
                            {availableVideoDevices.map((device) => {
                                return <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
                            })}
                        </select> : <select className='me-2 form-select form-select-solid form-select-lg'>
                            <option value=''>
                                (Default Video Device)
                            </option>
                        </select>}
                    </div>
                    <div className='mt-2'>
                        {availableAudioDevices.length > 0 ? <select className='me-2 form-select form-select-solid form-select-lg'
                            onChange={e => setSelectedAudioDeviceId(e.target.value)}>
                            {availableAudioDevices.map((device) => {
                                return <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
                            })}
                        </select> : <select className='me-2 form-select form-select-solid form-select-lg'>
                            <option value=''>
                                (Default Audio Device)
                            </option>
                        </select>}
                    </div>
                </div>
            </div>}
            <div className='mt-5 text-center'>
                {!recordingIsInProgress && <div>
                    {videoIsActive && audioIsActive ? <button
                        className='btn btn-primary'
                        type='button'
                        onClick={() => {
                            handleBeginRecording(nodeId);
                        }}
                    >
                        Begin Recording
                    </button> : <button
                        className='btn btn-primary'
                        type='button'
                        disabled
                    >
                        {videoAndAudioHaveBeenEnabled ? <>
                            {activeUserMediaStream ? <>Begin Recording</> : <>Loading Devices...</>}
                        </> : <>
                            Begin Recording
                        </>
                        }
                    </button>}
                </div>}
            </div>
        </ActionsContainer>
        <GoBackContainer
            onClick={onGoBack}
            as={videoAndAudioHaveBeenEnabled ? GoBackContainerWhileRecording : undefined}
        >
            <ChevronLeft size={24} />
        </GoBackContainer>
    </Container>
}

export default NodeRecordVideoPreviewItem;