import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

function CopyToClipboardButton({ text, tooltip = '', }: { text: string, tooltip?: string }) {
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        if (!copied) {
            return
        }

        setTimeout(() => {
            setCopied(false)
        }, 1500)
    }, [copied])

    return <OverlayTrigger
        key='copy-to-clipboard'
        placement='top'
        overlay={tooltip ? <Tooltip id='tooltip-copy-to-clipboard'>{tooltip}</Tooltip> : <></>}
    >
        <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
            <button type='button' className='btn btn-secondary'>
                {!copied ? 'Copy to Clipboard' : 'Copied!'}
            </button>
        </CopyToClipboard>
    </OverlayTrigger>
}

export default CopyToClipboardButton