import { useState, useCallback, useEffect } from "react"

import { getAllJourneyPersonalizations, getJourneyPersonalization } from "../../../funeral_homes/dashboard/core/_requests"
import { JourneyPersonalization } from "../../../funeral_homes/dashboard/core/journey_classes"
import { ApiError } from "../../auth"
import { Link } from "react-router-dom"

function ExistingWelcomeVideos({ sendToBasePath }: { sendToBasePath: string }) {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const [journeyPersonalizations, setJourneyPersonalizations] = useState<JourneyPersonalization[]>([])

    const loadAllExistingWelcomeVideos = useCallback(async () => {
        setLoading(true);
        setError(null)
        try {
            const updatedResults = await getAllJourneyPersonalizations()
            setJourneyPersonalizations(updatedResults)
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof ApiError ? exception.message : 'Something went wrong'
            setError(`Unable to get welcome videos: ${errorMessage}`)
        } finally {
            setLoading(false)
        }
    }, [setLoading, setError])

    useEffect(() => {
        loadAllExistingWelcomeVideos()
    }, [loadAllExistingWelcomeVideos])

    if (loading) {
        return (
            <div>
                <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            </div>
        )
    }

    if (error) {
        return <div className='alert alert-danger' role='alert'>
            {error}
        </div>
    }

    if (journeyPersonalizations.length === 0) {
        return <div>
            <Link to={`/personalize/personalize-welcome-video`} className='btn btn-primary'>
                Create New Welcome Video
            </Link>
        </div>
    }

    return <div>
        {journeyPersonalizations.map((journeyPersonalization, index) => {
            return <div key={`jp-${journeyPersonalization.unique_identifier}`}>
                <div>
                    <h2>Welcome Video:</h2>
                    <div className='mt-4'>
                        <video height={200} controls src={journeyPersonalization.journey_upload?.uploaded_file_url} />
                    </div>
                    <div className='mt-2'>
                        <Link
                            to={`${sendToBasePath}/${journeyPersonalization.unique_identifier}`}
                            className='btn btn-primary'
                        >
                            Edit
                        </Link>
                    </div>
                </div>
            </div>
        })}
    </div>
}

export default ExistingWelcomeVideos