import styled from 'styled-components'
import { IAvailableFormType } from '../../../obituaries/useObituaryWebsocket'
import { useState, useCallback, useEffect } from 'react'
import { ApiError } from '../../auth'
import { ObituarySalesInsight } from '../core/_models'
import { generateInsightsForFormType, getObituaryInsightsBySection } from '../core/_requests'
import { useObituaryDetail } from './ObituaryDetail'
import clsx from 'clsx'
import ProgressBarInSeconds from '../ProgressBarInSeconds'

const ListGroupContainer = styled.div`
  .list-group-item {
    border: none;
  }
`

const availableSections = [
  {
    name: 'Customization Insights',
    formType: 'customization' as IAvailableFormType, // not technically correct
  },
  false && {
    name: 'Services & Condolences',
    formType: 'services' as IAvailableFormType,
  },
  false && {
    name: 'Education',
    formType: 'education' as IAvailableFormType,
  },
  false && {
    name: 'Career',
    formType: 'career' as IAvailableFormType,
  },
  false && {
    name: 'Military Service',
    formType: 'military' as IAvailableFormType,
  },
  false && {
    name: 'Affiliations',
    formType: 'affiliations' as IAvailableFormType,
  },
  false && {
    name: 'Life Events',
    formType: 'life-events' as IAvailableFormType,
  },
  false && {
    name: 'Personality and Interests',
    formType: 'personality' as IAvailableFormType,
  },
  false && {
    name: 'Final Questions',
    formType: 'final-questions' as IAvailableFormType,
  },
].filter((x) => x)

function SectionItem({
  section,
  sectionInsights: defaultSectionInsights,
}: {
  // section is one of the above availableSections
  section: typeof availableSections[number]
  sectionInsights:
  | {
    can_generate_insights: boolean
    insights: ObituarySalesInsight[]
  }
  | undefined
}) {
  const { obituary } = useObituaryDetail()

  const [sectionInsights, setSectionInsights] = useState<
    | {
      can_generate_insights: boolean
      insights: ObituarySalesInsight[]
    }
    | undefined
  >(defaultSectionInsights)

  const sectionInsightItems = sectionInsights?.insights ?? []

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const onGenerateInsights = useCallback(async () => {
    if (typeof section === 'boolean') {
      return
    }
    setLoading(true)
    setError(null)
    try {
      const updatedInsights = await generateInsightsForFormType(
        obituary.unique_identifier,
        section.formType
      )
      setSectionInsights(updatedInsights)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [obituary.unique_identifier, typeof section !== 'boolean' ? section.formType : null])

  return (
    <div className='list-group-item'>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='fs-4'>{typeof section === 'boolean' ? '' : section.name}</div>
        {false && <div className='badge badge-primary badge-pill'>14</div>}
      </div>
      <ListGroupContainer className='list-group'>
        {sectionInsightItems.length > 0 ? (
          <>
            {sectionInsightItems.map((insight, index) => {
              return (
                <li
                  key={`insight-${insight.unique_id}`}
                  className='list-group-item d-flex justify-content-between align-items-start'
                >
                  <div className='ms-2 me-auto'>
                    <div className='fs-5 d-flex gap-5'>
                      <span className='user-select-none'>{index + 1}. </span>
                      <span>{`${insight.text}`}</span>
                    </div>
                  </div>
                </li>
              )
            })}
          </>
        ) : (
          <>
            {/* No insights for this section */}
            <li className='list-group-item d-flex justify-content-between align-items-start'>
              <div className='ms-2 me-auto'>
                <div className='fs-5'>No insights for this section</div>
              </div>
            </li>
          </>
        )}
        {sectionInsights?.can_generate_insights && (
          <div className='mt-1 ps-6 mb-2'>
            {error && (
              <div className='alert alert-danger' role='alert'>
                <div className='alert-text'>{error}</div>
              </div>
            )}
            <button
              type='button'
              className={clsx(
                'btn btn-sm',
                sectionInsights.insights.length === 0 ? 'btn-primary' : 'btn-secondary'
              )} // TODO: make this a 'btn-outline-primary
              disabled={loading}
              onClick={onGenerateInsights}
            >
              {!loading
                ? sectionInsightItems.length > 0
                  ? 'Re-Generate Insights'
                  : 'Generate Insights'
                : 'Generating...'}
            </button>
            {loading && (
              <div className='mt-4'>
                <ProgressBarInSeconds message='Generating insights...' />
              </div>
            )}
          </div>
        )}
      </ListGroupContainer>
    </div>
  )
}

function SectionInsights() {
  const { obituary } = useObituaryDetail()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  // I don't love the KnownFamilyMember naming but here we are

  const [insights, setInsights] = useState<{
    customization: {
      can_generate_insights: boolean
      insights: ObituarySalesInsight[]
    }
  } | null>(null)

  const onLoadInsights = useCallback(async () => {
    try {
      const updatedInsights = await getObituaryInsightsBySection(obituary.unique_identifier)
      console.log(updatedInsights)
      setInsights(updatedInsights)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [obituary.unique_identifier])

  useEffect(() => {
    onLoadInsights()
  }, [onLoadInsights])

  if (loading) {
    return (
      <div>
        <div className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='alert alert-danger' role='alert'>
        <div className='alert-text'>{error}</div>
      </div>
    )
  }

  return (
    <div>
      {/* Render a vertical list of sections */}
      <div className='list-group'>
        {availableSections.map((section) => {
          if (typeof section === 'boolean') {
            return null
          }
          return (
            <SectionItem
              key={`section-${section.formType}`}
              section={section}
              sectionInsights={insights?.customization}
            />
          )
        })}
      </div>
    </div>
  )
}

export default SectionInsights
