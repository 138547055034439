import {FC} from 'react'
import styled from 'styled-components'

const ContainerButton = styled.button`
  width: 100%;
  height: 162px;

  h1 {
    font-size: 1.75rem;
  }

  @media (max-width: 1300px) {
    height: 100px !important;

    h1 {
      font-size: 1.5rem !important;
    }
  }
`

type NewObituaryCardProps = {
  selected?: boolean
  title: string
  onClick: () => void
}

const SelectUserTypeCard: FC<NewObituaryCardProps> = ({selected, title, onClick}) => {
  return (
    <ContainerButton
      type='button'
      className={
        !selected
          ? 'card border border-2 border-gray-300 border-hover p-5'
          : 'card border border-2 border-primary p-5'
      }
      onClick={onClick}
    >
      {/* Add a centered plus sign with text */}
      <div className='d-flex justify-content-center align-items-center h-100 w-100'>
        <div className='d-flex flex-column justify-content-center align-items-center w-100'>
          <h1 className='fw-bolder text-dark mt-5 text-center'>{title}</h1>
        </div>
      </div>
    </ContainerButton>
  )
}

export default SelectUserTypeCard
