import {DateTime} from 'luxon'
import {OnboardingFlags, SimpleUserModel, UserProfile} from '../../auth/core/_models'
import {IAvailableFormType} from '../../../obituaries/useObituaryWebsocket'

export type IObituarySections =
  | 'biography_personal'
  | 'family_members'
  | 'detail_services'
  | 'visitation_information'
  | 'burial_information'
  | 'special_remarks'
export class QuestionResponse {
  unique_id: string
  updated_at: string
  inserted_at: string

  response: string

  audio_response_url?: string | null
  audio_response_duration?: number | null
  audio_response_transcript?: string | null
  audio_response_transcription_status?:
    | 'In-Progress'
    | 'Transcribing'
    | 'Completed'
    | 'Failed'
    | null

  audio_response_summary?: string
  audio_response_summary_in_words?: number

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.updated_at = data.updated_at
    this.inserted_at = data.inserted_at
    this.response = data.response
    this.audio_response_url = data.audio_response_url
    this.audio_response_duration = data.audio_response_duration
    this.audio_response_transcript = data.audio_response_transcript
    this.audio_response_transcription_status = data.audio_response_transcription_status
    this.audio_response_summary = data.audio_response_summary
    this.audio_response_summary_in_words = data.audio_response_summary_in_words
  }

  isTranscriptionInProgress() {
    return (
      this.audio_response_transcription_status === 'In-Progress' ||
      this.audio_response_transcription_status === 'Transcribing'
    )
  }
}

export class BaseQuestion {
  unique_id: string
  question: string
  latest_response: QuestionResponse | null = null

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.question = data.question
    this.latest_response = data.latest_response ? new QuestionResponse(data.latest_response) : null
  }
}
export class StartingQuestion extends BaseQuestion {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(data: any) {
    super(data)
  }
}

export class FollowupQuestion extends BaseQuestion {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(data: any) {
    super(data)
  }
}

class InputtedDetails {
  for_person_full_name: string = ''
  for_person_first_name: string = ''
  for_person_middle_name: string = ''
  for_person_last_name: string = ''
  for_person_nickname: string = ''
  nickname_is_preferred: boolean = false
  for_person_born_in_city_and_state: string = ''
  for_person_recent_city_and_state: string = ''
  birth_date_string: string = ''
  death_date_string: string = ''
  death_not_yet_passed: boolean = false
  birth_date_display_year_only: boolean = false
  gender_value: string = ''

  funeral_director_name: string = ''
  funeral_director_phone: string = ''
  funeral_director_email: string = ''

  // birth_date and death_date are derived from birth_date_string and death_date_string (or null)
  // We use luxon to parse the date strings into Date objects

  get birth_date(): Date | null {
    if (this.birth_date_string) {
      return DateTime.fromFormat(this.birth_date_string, 'yyyy-MM-dd').toJSDate()
    }
    return null
  }

  get death_date(): Date | null {
    if (this.death_date_string) {
      return DateTime.fromFormat(this.death_date_string, 'yyyy-MM-dd').toJSDate()
    }
    return null
  }

  constructor(data: any) {
    this.for_person_full_name = data.for_person_full_name
    this.for_person_first_name = data.for_person_first_name
    this.for_person_middle_name = data.for_person_middle_name
    this.for_person_last_name = data.for_person_last_name
    this.for_person_nickname = data.for_person_nickname
    this.nickname_is_preferred = data.nickname_is_preferred
    this.for_person_born_in_city_and_state = data.for_person_born_in_city_and_state
    this.for_person_recent_city_and_state = data.for_person_recent_city_and_state
    this.birth_date_string = data.birth_date_string
    this.death_date_string = data.death_date_string
    this.death_not_yet_passed = data.death_not_yet_passed
    this.birth_date_display_year_only = data.birth_date_display_year_only
    this.gender_value = data.gender_value
    this.funeral_director_name = data.funeral_director_name
    this.funeral_director_phone = data.funeral_director_phone
    this.funeral_director_email = data.funeral_director_email
  }
}

export class FamilyAudioResponse {
  unique_id: string
  updated_at: string
  inserted_at: string

  audio_response_url?: string
  audio_response_duration?: number | null
  audio_response_transcript?: string | null
  audio_response_transcription_status?:
    | 'In-Progress'
    | 'Transcribing'
    | 'Completed'
    | 'Failed'
    | null

  audio_response_family_summary_text?: string

  created_relationships: FamilyRelationship[] = []

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.updated_at = data.updated_at
    this.inserted_at = data.inserted_at
    this.audio_response_url = data.audio_response_url
    this.audio_response_duration = data.audio_response_duration
    this.audio_response_transcript = data.audio_response_transcript
    this.audio_response_transcription_status = data.audio_response_transcription_status
    this.audio_response_family_summary_text = data.audio_response_family_summary_text
    this.created_relationships = data.created_relationships.map(
      (r: any) => new FamilyRelationship(r)
    )
  }
}

export class SectionRevision {
  unique_identifier: string
  updated_at: string
  inserted_at: string
  text_content: string

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.updated_at = data.updated_at
    this.inserted_at = data.inserted_at
    this.text_content = data.text_content
  }
}

export class SectionSummary {
  unique_id: string
  inserted_at: string
  updated_at: string

  tied_to_revision: SectionRevision
  summary_text: string
  summary_in_words: number

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.tied_to_revision = new SectionRevision(data.tied_to_revision)
    this.summary_text = data.summary_text
    this.summary_in_words = data.summary_in_words
  }
}

export class Section {
  unique_id: string
  updated_at: string
  inserted_at: string

  title: string
  key: IObituarySections

  ordering: number

  latest_revision?: SectionRevision | null
  can_be_generated_with_ai: boolean
  can_be_summarized_with_ai_in_words: boolean

  active_summaries: SectionSummary[] = []

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.updated_at = data.updated_at
    this.inserted_at = data.inserted_at
    this.title = data.title
    this.key = data.key
    this.ordering = data.ordering
    this.latest_revision = data.latest_revision ? new SectionRevision(data.latest_revision) : null
    this.can_be_generated_with_ai = data.can_be_generated_with_ai
    this.can_be_summarized_with_ai_in_words = data.can_be_summarized_with_ai_in_words
    this.active_summaries = data.active_summaries.map((s: any) => new SectionSummary(s))
  }

  getButtonActionText(): string {
    // Search for ['biography_personal', 'family_members', 'detail_services'].includes
    // to find the primary/light button styling and classes.
    if (this.key === 'biography_personal') {
      return 'Generate Obituary'
    }
    if (this.key === 'family_members') {
      return 'Generate Family Members'
    }
    if (this.key === 'detail_services') {
      return 'Generate Services & Condolences'
    }
    if (this.key === 'visitation_information') {
      return 'Generate Visitation Information'
    }
    if (this.key === 'burial_information') {
      return 'Generate Burial Information'
    }
    if (this.key === 'special_remarks') {
      return 'Generate Special Remarks'
    }
    return 'Generate'
  }
}

export class PersonalInformation {
  primaryAvatarUrl?: string
  lovedOnesFullName?: string
  maidenNameIfApplicable?: string
  nickname?: string
  nicknameIsPreferred?: boolean
  gender?: string
  genderOther?: string
  birthDate?: string
  placeOfBirth?: string
  dateOfDeath?: string
  locationOfDeath?: string
  cityOfLastResidence?: string
  passingInformation?: string

  hasGeneratedHumanName?: boolean
  humanName?: string
  humanNameTitle?: string
  humanNameFirst?: string
  humanNameMiddle?: string
  humanNameLast?: string
  humanNameSuffix?: string
  humanNameNickname?: string

  constructor(data: any) {
    this.primaryAvatarUrl = data.primaryAvatarUrl
    this.lovedOnesFullName = data.lovedOnesFullName
    this.maidenNameIfApplicable = data.maidenNameIfApplicable
    this.nickname = data.nickname
    this.nicknameIsPreferred = data.nicknameIsPreferred
    this.gender = data.gender
    this.genderOther = data.genderOther
    this.birthDate = data.birthDate
    this.placeOfBirth = data.placeOfBirth
    this.dateOfDeath = data.dateOfDeath
    this.locationOfDeath = data.locationOfDeath
    this.cityOfLastResidence = data.cityOfLastResidence
    this.passingInformation = data.passingInformation
    this.hasGeneratedHumanName = data.hasGeneratedHumanName
    this.humanName = data.humanName
    this.humanNameTitle = data.humanNameTitle
    this.humanNameFirst = data.humanNameFirst
    this.humanNameMiddle = data.humanNameMiddle
    this.humanNameLast = data.humanNameLast
    this.humanNameSuffix = data.humanNameSuffix
    this.humanNameNickname = data.humanNameNickname
  }
}

export class Relatives {
  survivors?: string
  passedAway?: string

  constructor(data: any) {
    this.survivors = data.survivors
    this.passedAway = data.passedAway
  }
}

export class VenueAndDateTimeItem {
  itemUniqueId: string

  venue?: string | null // Long string

  date?: string | null
  time?: string | null

  constructor(data: any) {
    this.itemUniqueId = data.unique_id
    this.venue = data.venue
    this.date = data.date
    this.time = data.time
  }
}
export class CeremonyDetails {
  venueDateAndTimeAndSpecialGuidelines?: string
  expressCondolences?: string
  venueAndDateTimeItems?: VenueAndDateTimeItem[] = []

  constructor(data: any) {
    this.venueDateAndTimeAndSpecialGuidelines = data.venueDateAndTimeAndSpecialGuidelines
    this.expressCondolences = data.expressCondolences
    // check data.active_venue_and_date_time_items:
    this.venueAndDateTimeItems = data.venueAndDateTimeItems
      ? data.venueAndDateTimeItems.map((v: any) => new VenueAndDateTimeItem(v))
      : []
  }
}

export class ReceivedQuestion {
  unique_id: string
  inserted_at: string
  updated_at: string
  order: number
  generated_based_on_attribute: string

  question: string
  answer: string | null

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.order = data.order
    this.generated_based_on_attribute = data.generated_based_on_attribute
    this.question = data.question
    this.answer = data.answer
  }
}

export class Education {
  schoolsAttended?: string
  notedAchievements?: string
  receivedQuestions?: ReceivedQuestion[] = []

  constructor(data: any) {
    this.schoolsAttended = data.schoolsAttended
    this.notedAchievements = data.notedAchievements
    this.receivedQuestions =
      data.receivedQuestions && data.receivedQuestions.length > 0
        ? data.receivedQuestions.map((q: any) => new ReceivedQuestion(q))
        : undefined
  }
}

export class Career {
  careerProfession?: string
  notableAchievements?: string
  receivedQuestions?: ReceivedQuestion[] = []

  constructor(data: any) {
    this.careerProfession = data.careerProfession
    this.notableAchievements = data.notableAchievements
    this.receivedQuestions =
      data.receivedQuestions && data.receivedQuestions.length > 0
        ? data.receivedQuestions.map((q: any) => new ReceivedQuestion(q))
        : undefined
  }
}

export class MilitaryService {
  militaryService?: string
  notableAchievements?: string
  receivedQuestions?: ReceivedQuestion[] = []

  constructor(data: any) {
    this.militaryService = data.militaryService
    this.notableAchievements = data.notableAchievements
    this.receivedQuestions =
      data.receivedQuestions && data.receivedQuestions.length > 0
        ? data.receivedQuestions.map((q: any) => new ReceivedQuestion(q))
        : undefined
  }
}

export class Affiliations {
  listedAffiliations?: string
  receivedQuestions?: ReceivedQuestion[] = []

  constructor(data: any) {
    this.listedAffiliations = data.listedAffiliations
    this.receivedQuestions =
      data.receivedQuestions && data.receivedQuestions.length > 0
        ? data.receivedQuestions.map((q: any) => new ReceivedQuestion(q))
        : undefined
  }
}

export class LifeEvents {
  weddingsOrBirthsOrSignificantEvents?: string
  receivedQuestions?: ReceivedQuestion[] = []

  constructor(data: any) {
    this.weddingsOrBirthsOrSignificantEvents = data.weddingsOrBirthsOrSignificantEvents
    this.receivedQuestions =
      data.receivedQuestions && data.receivedQuestions.length > 0
        ? data.receivedQuestions.map((q: any) => new ReceivedQuestion(q))
        : undefined
  }
}

export class PersonalityAndInterests {
  descriptionOfLovedOne?: string
  favoriteMemories?: string
  proudestAccomplishments?: string
  hobbiesAndFavoriteThings?: string
  receivedQuestions?: ReceivedQuestion[] = []

  constructor(data: any) {
    this.descriptionOfLovedOne = data.descriptionOfLovedOne
    this.favoriteMemories = data.favoriteMemories
    this.proudestAccomplishments = data.proudestAccomplishments
    this.hobbiesAndFavoriteThings = data.hobbiesAndFavoriteThings
    this.receivedQuestions =
      data.receivedQuestions && data.receivedQuestions.length > 0
        ? data.receivedQuestions.map((q: any) => new ReceivedQuestion(q))
        : undefined
  }
}

export class TributeHeadline {
  unique_id: string
  inserted_at: string
  updated_at: string
  text: string

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.text = data.text
  }
}

// For now ObituaryQuestion is very simply just a question and response
// tied to an individual obituary and an obituary's specific question
// e.g (Share any important details about their career, profession, or places of work, including roles/titles and years employed, if relevant.)

export class ObituaryQuestion {
  unique_id: string
  inserted_at: string
  updated_at: string

  tied_to_question_id: string // e.g. career.careerProfession

  question: string
  response: string

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.tied_to_question_id = data.tied_to_question_id
    this.question = data.question
    this.response = data.response
  }
}

export class TemporaryMessage {
  // temporary because they may both disappear and be refactored any time
  message: string
  type: 'success' | 'error'

  constructor(data: any, type: 'success' | 'error') {
    this.message = data.message
    this.type = type
  }
}

export class ReceivableEmailAddress {
  unique_id: string
  inserted_at: string
  updated_at: string

  created_by_user: SimpleUserModel

  email_prefix: string
  email_prefix_without_dots: string
  email_prefix_without_dots_and_plus: string

  email: string
  email_without_dots: string
  email_without_dots_and_plus: string

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.created_by_user = new SimpleUserModel(data.created_by_user)
    this.email_prefix = data.email_prefix
    this.email_prefix_without_dots = data.email_prefix_without_dots
    this.email_prefix_without_dots_and_plus = data.email_prefix_without_dots_and_plus
    this.email = data.email
    this.email_without_dots = data.email_without_dots
    this.email_without_dots_and_plus = data.email_without_dots_and_plus
  }
}

class ObituaryData {
  obituary_type: string
  obituary_type_string: string
  preneed_type: string

  constructor(data: any) {
    this.obituary_type = data.obituary_type
    this.obituary_type_string = data.obituary_type_string
    this.preneed_type = data.preneed_type
  }
}

export class Obituary {
  is_loading: boolean = false

  unique_identifier: string
  inserted_at: string
  updated_at: string

  data?: ObituaryData

  for_full_name: string
  for_full_name_initials: string

  preferred_pronoun: string

  starting_questions: StartingQuestion[]
  active_followup_questions: FollowupQuestion[]

  status_string: string

  current_step_string:
    | 'Finishing'
    | 'Answering Questions'
    | 'Answering More Questions'
    | 'Family'
    | 'Waiting'

  dates_lived_string: string
  dates_lived_short_string: string

  progress_percentage: number

  // We are moving to BiographicalInformation, which contains everything
  // above really. Should help organize things a bit.

  inputted_details?: InputtedDetails | null

  active_family_audio_responses: FamilyAudioResponse[]
  active_relationships: FamilyRelationship[]
  active_sections: Section[]
  active_image_uploads: ObituaryImageUpload[]

  active_tribute_headlines: TributeHeadline[]

  total_age_string?: string

  full_obituary_text?: string
  public_link: string
  shareable_code: string

  data_personal_information: PersonalInformation | null = null
  personal_information_count: number = 0

  data_relatives: Relatives | null = null
  relatives_count: number = 0

  data_ceremony_details: CeremonyDetails | null = null
  ceremony_details_count: number = 0

  data_education: Education | null = null
  education_count: number = 0

  data_career: Career | null = null
  career_count: number = 0

  data_military_service: MilitaryService | null = null
  military_service_count: number = 0

  data_affiliations: Affiliations | null = null
  affiliations_count: number = 0

  data_life_events: LifeEvents | null = null
  life_events_count: number = 0

  data_personality_and_interests: PersonalityAndInterests | null = null
  personality_and_interests_count: number = 0

  funeral_home: FuneralHome | null = null

  active_receivable_email_addresses?: ReceivableEmailAddress[]

  // Not yet implemented in the backend:
  avatar_url?: string
  tribute_headline?: string

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.data = data.data ? new ObituaryData(data.data) : undefined
    this.for_full_name = data.for_full_name
    this.for_full_name_initials = data.for_full_name_initials
    this.preferred_pronoun = data.preferred_pronoun
    this.starting_questions = data.starting_questions
      ? data.starting_questions.map(
          (startingQuestion: any) => new StartingQuestion(startingQuestion)
        )
      : []
    this.active_followup_questions = data.active_followup_questions
      ? data.active_followup_questions.map(
          (followupQuestion: any) => new FollowupQuestion(followupQuestion)
        )
      : []
    this.status_string = data.status_string
    this.current_step_string = data.current_step_string
    this.dates_lived_string = data.dates_lived_string
    this.dates_lived_short_string = data.dates_lived_short_string
    this.progress_percentage = data.progress_percentage
    this.inputted_details = data.inputted_details
      ? new InputtedDetails(data.inputted_details)
      : null
    this.active_family_audio_responses = data.active_family_audio_responses
      ? data.active_family_audio_responses.map(
          (familyAudioResponse: any) => new FamilyAudioResponse(familyAudioResponse)
        )
      : []
    this.active_relationships = data.active_relationships
      ? data.active_relationships.map((r: any) => new FamilyRelationship(r))
      : []

    this.active_sections = data.active_sections
      ? data.active_sections.map((s: any) => new Section(s))
      : []

    this.active_image_uploads = data.active_image_uploads
      ? data.active_image_uploads.map((i: any) => new ObituaryImageUpload(i))
      : []
    this.active_tribute_headlines = data.active_tribute_headlines
      ? data.active_tribute_headlines.map((t: any) => new TributeHeadline(t))
      : []
    this.total_age_string = data.total_age_string
    this.full_obituary_text = data.full_obituary_text
    this.public_link = data.public_link
    this.shareable_code = data.shareable_code
    this.data_personal_information = data.data_personal_information
      ? new PersonalInformation(data.data_personal_information)
      : null
    this.personal_information_count = data.personal_information_count || 0
    this.data_relatives = data.data_relatives ? new Relatives(data.data_relatives) : null
    this.relatives_count = data.relatives_count || 0
    this.data_ceremony_details = data.ceremony_details
      ? new CeremonyDetails(data.ceremony_details)
      : null
    this.ceremony_details_count = data.ceremony_details_count || 0
    this.data_education = data.data_education ? new Education(data.data_education) : null
    this.education_count = data.education_count || 0
    this.data_career = data.data_career ? new Career(data.data_career) : null
    this.career_count = data.career_count || 0
    this.data_military_service = data.data_military_service
      ? new MilitaryService(data.data_military_service)
      : null
    this.military_service_count = data.military_service_count || 0
    this.data_affiliations = data.data_affiliations
      ? new Affiliations(data.data_affiliations)
      : null
    this.affiliations_count = data.affiliations_count || 0
    this.data_life_events = data.data_life_events ? new LifeEvents(data.data_life_events) : null
    this.life_events_count = data.life_events_count || 0
    this.data_personality_and_interests = data.data_personality_and_interests
      ? new PersonalityAndInterests(data.data_personality_and_interests)
      : null
    this.personality_and_interests_count = data.personality_and_interests_count || 0
    this.funeral_home = data.funeral_home ? new FuneralHome(data.funeral_home) : null
    this.active_receivable_email_addresses = data.active_receivable_email_addresses
      ? data.active_receivable_email_addresses.map(
          (emailAddress: any) => new ReceivableEmailAddress(emailAddress)
        )
      : []
    // TODO:
    this.dates_lived_string = data.dates_lived_string
    this.dates_lived_short_string = data.dates_lived_short_string
    this.total_age_string = data.total_age_string
  }

  getObituaryTypeString(): string {
    // This used to be in the back-end. To keep from having to maintain
    // that in Elixir (for now), we do the mapping here.
    // Likely will change.
    const obituaryType = this.data?.obituary_type
    if (obituaryType) {
      const mapping: any = {
        pre_need: 'Pre-Need',
        at_need: 'At-Need',
        other: 'Other',
        from_widget: 'Widget',
      }
      if (obituaryType in mapping) {
        return `${mapping[obituaryType]}`
      }
      return `Un-mapped Type: ${obituaryType}`
    }
    return 'Unknown'
  }

  getSimpleName() {
    // This order is important.
    // "Human name" is parsing we do on a full name to guess at the name's structure.
    // We do this to make the input simpler.
    // We default to nickname if preferred, human name if parsed,
    // nickname if available, human nick name if available, and finally
    // just the original name.
    if (
      this.data_personal_information?.nicknameIsPreferred &&
      this.data_personal_information?.nickname
    ) {
      return this.data_personal_information.nickname
    }
    if (this.data_personal_information?.humanNameFirst) {
      return this.data_personal_information.humanNameFirst
    }
    if (this.data_personal_information?.nickname) {
      return this.data_personal_information.nickname
    }
    if (this.data_personal_information?.humanNameNickname) {
      return this.data_personal_information.humanNameNickname
    }
    if (this.data_personal_information?.lovedOnesFullName) {
      return this.data_personal_information.lovedOnesFullName
    }
    return this.for_full_name
  }

  getSimpleFirstName() {
    const simpleName = this.getSimpleName()
    if (simpleName) {
      return simpleName.split(' ')[0].trim()
    }
    return ''
  }

  isPreNeedObituary() {
    return this.data?.obituary_type === 'pre_need'
  }

  hasWrittenBiography() {
    return (
      this.active_sections.filter(
        (x) => x.key === 'biography_personal' && x.latest_revision?.text_content
      ).length > 0
    )
  }

  getTributeText(): string {
    if (this.active_tribute_headlines.length === 0) {
      return ''
    }
    return this.active_tribute_headlines[0].text
  }
}

export class FamilyRelationship {
  unique_id: string
  inserted_at: string
  updated_at: string

  name: string
  relationship_group: string
  relationship: string
  relationship_other_details: string
  deceased_status: 'Living' | 'Deceased' | 'Unknown'

  is_authorizer: boolean

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.name = data.name
    this.relationship_group = data.relationship_group
    this.relationship = data.relationship
    this.relationship_other_details = data.relationship_other_details
    this.deceased_status = data.deceased_status
    this.is_authorizer = data.is_authorizer
  }
}

export class ObituaryImageUpload {
  unique_id: string
  inserted_at: string
  updated_at: string

  original_file_name: string
  file_type: string
  file_size: number

  image_url: string

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.original_file_name = data.original_file_name
    this.file_type = data.file_type
    this.file_size = data.file_size
    this.image_url = data.image_url
  }
}

class FuneralHomeBillingDetails {
  unique_id: string
  inserted_at: string
  updated_at: string
  stripe_customer_id: string
  stripe_subscription_id: string
  address_line1: string
  address_line2: string
  city: string
  state: string
  zip_code: string

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.stripe_customer_id = data.stripe_customer_id
    this.stripe_subscription_id = data.stripe_subscription_id
    this.address_line1 = data.address_line1
    this.address_line2 = data.address_line2
    this.city = data.city
    this.state = data.state
    this.zip_code = data.zip_code
  }
}

export class ClientPrivateData {
  send_lead_notifications_to_email_addresses?: string

  constructor(data: any) {
    this.send_lead_notifications_to_email_addresses =
      data.send_lead_notifications_to_email_addresses
  }
}

export class AnonClientPublicData {
  // This is the new Phoenix data we are moving toward. It's going
  // to be a sub-object on the client so that we can explicitly move things here.

  widget_primary_color?: string
  widget_primary_text_color?: string
  widget_icon_location?: null | 'bottom_right' | 'bottom_left'
  widget_show_tribute_directly?: boolean

  inviting_text_above_widget?: string

  widget_is_larger?: boolean
  widget_call_to_action_text?: string

  formatted_address: string
  address_line1: string
  address_line2: string
  city: string
  state: string
  zip_code: string
  website: string
  phone_number: string
  email: string

  interactive_employee_is_enabled?: boolean
  video_widget_preview_video_url?: string
  video_widget_full_video_url?: string

  private_labeling_text?: string
  private_labeling_url?: string
  preview_interactive_employee_at_url?: string
  interactive_employee_lead_collection_background_color?: string
  interactive_employee_lead_collection_text_color?: string

  constructor(data: any) {
    this.widget_primary_color = data.widget_primary_color
    this.widget_primary_text_color = data.widget_primary_text_color
    this.widget_icon_location = data.widget_icon_location
    this.widget_show_tribute_directly = data.widget_show_tribute_directly
    this.inviting_text_above_widget = data.inviting_text_above_widget
    this.widget_is_larger = data.widget_is_larger
    this.widget_call_to_action_text = data.widget_call_to_action_text

    this.formatted_address = data.formatted_address
    this.address_line1 = data.address_line1
    this.address_line2 = data.address_line2
    this.city = data.city
    this.state = data.state
    this.zip_code = data.zip_code
    this.website = data.website
    this.phone_number = data.phone_number
    this.email = data.email

    this.interactive_employee_is_enabled = data.interactive_employee_is_enabled
    this.video_widget_preview_video_url = data.video_widget_preview_video_url
    this.video_widget_full_video_url = data.video_widget_full_video_url

    this.private_labeling_text = data.private_labeling_text
    this.private_labeling_url = data.private_labeling_url
    this.preview_interactive_employee_at_url = data.preview_interactive_employee_at_url
    this.interactive_employee_lead_collection_background_color =
      data.interactive_employee_lead_collection_background_color
    this.interactive_employee_lead_collection_text_color =
      data.interactive_employee_lead_collection_text_color
  }
}

class FuneralHomePaymentSettingData {
  charge_consumers_per_obituary: boolean
  amount_to_charge_consumers_in_cents?: number
  has_linked_bank_account?: boolean

  constructor(data: any) {
    this.charge_consumers_per_obituary = !!data.charge_consumers_per_obituary
    this.amount_to_charge_consumers_in_cents = data.amount_to_charge_consumers_in_cents
    this.has_linked_bank_account = data.has_linked_bank_account
  }
}

class SimplifiedStripeConnectAccount {
  id: string
  capabilities: {card_payments: string; transfers: string}
  charges_enabled?: boolean
  payouts_enabled?: boolean

  constructor(data: any) {
    this.id = data.id
    this.capabilities = data.capabilities
    this.charges_enabled = data.charges_enabled
    this.payouts_enabled = data.payouts_enabled
  }
}

class FuneralHomePaymentSettingPrivateData {
  stripe_connect_account?: SimplifiedStripeConnectAccount

  constructor(data: any) {
    this.stripe_connect_account = data.stripe_connect_account
      ? new SimplifiedStripeConnectAccount(data.stripe_connect_account)
      : undefined
  }
}

export class FuneralHomePaymentSettingForAdmin {
  unique_identifier: string
  inserted_at: string
  updated_at: string
  public_data: FuneralHomePaymentSettingData
  private_data: FuneralHomePaymentSettingPrivateData

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.public_data = new FuneralHomePaymentSettingData(data.public_data)
    this.private_data = new FuneralHomePaymentSettingPrivateData(data.private_data)
  }
}

export class FuneralHomePaymentSetting {
  unique_identifier: string
  inserted_at: string
  updated_at: string
  public_data: FuneralHomePaymentSettingData

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.public_data = new FuneralHomePaymentSettingData(data.public_data)
  }
}

export class FuneralHome {
  unique_identifier: string
  inserted_at: string
  updated_at: string
  name: string
  name_initials: string

  short_name?: string
  latest_logo_url: string
  billing_details?: FuneralHomeBillingDetails
  is_active: boolean

  widget_is_active: boolean

  active_memberships?: FuneralHomeMembershipWithUser[]

  data?: ClientPrivateData
  public_data?: AnonClientPublicData

  payment_settings?: FuneralHomePaymentSetting | null

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.name = data.name
    this.name_initials = data.name_initials
    this.short_name = data.short_name
    this.latest_logo_url = data.latest_logo_url
    this.billing_details = data.billing_details
      ? new FuneralHomeBillingDetails(data.billing_details)
      : undefined
    this.is_active = data.is_active
    this.widget_is_active = data.widget_is_active
    // todo:
    //this.active_memberships = data.active_memberships
    this.data = data.data ? new ClientPrivateData(data.data) : undefined
    this.public_data = data.public_data ? new AnonClientPublicData(data.public_data) : undefined
    this.payment_settings = data.payment_settings
      ? new FuneralHomePaymentSetting(data.payment_settings)
      : null
  }

  singleLineMembershipString(): string {
    if (!this.active_memberships || this.active_memberships?.length === 0) {
      return 'No members'
    }
    return (this.active_memberships || [])
      .map((m) => {
        return `${m.user.name || 'No name'} (${m.user.email})`
      })
      .join(', ')
  }

  shouldChargeConsumersPerObituary() {
    return !this.payment_settings || this.payment_settings.public_data.charge_consumers_per_obituary
  }
}

class FuneralHomePhoenixMembershipData {
  membership: {role: 'admin' | 'user'}

  constructor(data: any) {
    this.membership = data.membership
  }
}

export class FuneralHomePhoenixMembership {
  unique_identifier: string
  inserted_at: string
  updated_at: string

  date_confirmed_by_user?: string

  user: SimplifiedPhoenixUser

  data: FuneralHomePhoenixMembershipData

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.date_confirmed_by_user = data.date_confirmed_by_user
    this.user = new SimplifiedPhoenixUser(data.user)
    this.data = new FuneralHomePhoenixMembershipData(data.data)
  }
}

export class FuneralHomeMembership {
  unique_id: string
  inserted_at: string
  updated_at: string
  funeral_home: FuneralHome
  is_active: boolean

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.funeral_home = new FuneralHome(data.funeral_home)
    this.is_active = data.is_active
  }
}

export class FuneralHomeMembershipWithUser {
  unique_id: string
  inserted_at: string
  updated_at: string
  is_active: boolean
  user: SimplifiedPhoenixUser

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.is_active = data.is_active
    this.user = new SimplifiedPhoenixUser(data.user)
  }
}

export class FuneralHomeWithMemberships extends FuneralHome {
  active_memberships: FuneralHomeMembershipWithUser[]

  constructor(data: any) {
    super(data)
    this.active_memberships = data.active_memberships
      ? data.active_memberships.map((m: any) => new FuneralHomeMembershipWithUser(m))
      : []
  }

  singleLineMembershipString(): string {
    if (this.active_memberships.length === 0) {
      return 'No members'
    }
    return this.active_memberships
      .map((m) => {
        return `${m.user.name || 'No name'} (${m.user.email})`
      })
      .join(', ')
  }
}

export class KnownFamilyMember {
  unique_id: string
  inserted_at: string
  updated_at: string

  date_confirmed_by_user?: string

  name: string // May be at-odds with the user-profile name, but it's here to at least have something.
  phone_number: string
  email_address: string

  relationship_group: string
  relationship: string
  relationship_other_details?: string

  is_authorizer: boolean
  is_self: boolean

  tied_to_user?: SimpleUserModel

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.date_confirmed_by_user = data.date_confirmed_by_user
    this.name = data.name
    this.phone_number = data.phone_number
    this.email_address = data.email_address
    this.relationship_group = data.relationship_group
    this.relationship = data.relationship
    this.relationship_other_details = data.relationship_other_details
    this.is_authorizer = data.is_authorizer
    this.is_self = data.is_self
    this.tied_to_user = data.tied_to_user ? new SimpleUserModel(data.tied_to_user) : undefined
  }
}

export class ObituarySalesInsight {
  unique_id: string
  inserted_at: string
  updated_at: string

  text: string

  can_generate_insights?: boolean

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.text = data.text
  }
}

export class ObituaryEmailAttachment {
  unique_id: string
  inserted_at: string
  updated_at: string

  file: string
  file_size: number
  file_type: string
  original_file_name: string
  sha256_hash: string

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.file = data.file
    this.file_size = data.file_size
    this.file_type = data.file_type
    this.original_file_name = data.original_file_name
    this.sha256_hash = data.sha256_hash
  }
}

export class ObituaryEmail {
  unique_id: string
  inserted_at: string
  updated_at: string

  bcc_emails: string
  body: string
  cc_emails: string
  date: string
  formatted_from_email_address: string
  from_email_address: string
  html_body: string
  message_id: string
  subject: string
  to_emails: string

  attachments: ObituaryEmailAttachment[]

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.bcc_emails = data.bcc_emails
    this.body = data.body
    this.cc_emails = data.cc_emails
    this.date = data.date
    this.formatted_from_email_address = data.formatted_from_email_address
    this.from_email_address = data.from_email_address
    this.html_body = data.html_body
    this.message_id = data.message_id
    this.subject = data.subject
    this.to_emails = data.to_emails
    this.attachments = data.attachments
      ? data.attachments.map((a: any) => new ObituaryEmailAttachment(a))
      : []
  }
}

export class ObituaryDebug {
  // For now, basically converts a GPT3.5 array of questions into the relevant items.

  system_prompt: string

  // Messages for now are an array of "role" and "content"
  messages: {role: string; content: string}[]

  // "updated_messages" is a dict of index: value, where index is the index of the message in the messages array.
  // We do it this way to make it easier to send to the API

  updated_messages: {[key: number]: string} = {}

  constructor(data: any) {
    // We expect data to have "questions". If not, raise an error.
    if (!data.questions) {
      console.warn('No questions found in debug data.', data)
      throw new Error('No questions found in debug data.')
    }
    // If the array is empty, we don't have any debug info.
    if (data.questions.length === 0) {
      this.system_prompt = ''
      this.messages = []
      return
    }
    // Otherwise, we have some debug info.
    this.system_prompt = data.questions[0].content
    this.messages = data.questions.slice(1).map((q: any) => {
      return {role: q.role, content: q.content}
    })
  }

  updatedMessage(index: number, value: string) {
    this.updated_messages[index] = value
  }
}

export class SimplifiedPhoenixUser {
  // id instead of unique_identifier just because of the Pow backend application
  id: number

  email: string
  first_name: string
  last_name: string
  name: string

  data?: any

  is_admin?: boolean
  has_funeral_home_access?: boolean

  current_client?: FuneralHome

  onboarding_flags?: OnboardingFlags

  avatar_url?: string
  name_initials?: string

  current_frm_company_id?: string

  constructor(responseData: any) {
    this.id = responseData.id
    this.email = responseData.email
    this.first_name = responseData.first_name
    this.last_name = responseData.last_name
    this.name = responseData.name
    this.data = responseData.data // Nested arbitrary data, maps to the data column
    this.is_admin = responseData.is_admin
    this.has_funeral_home_access = responseData.has_funeral_home_access
    this.current_client = responseData.current_client
      ? new FuneralHome(responseData.current_client)
      : undefined
    this.onboarding_flags = responseData.onboarding_flags
      ? new OnboardingFlags(responseData.onboarding_flags)
      : undefined
    this.avatar_url = responseData.avatar_url
    this.name_initials = responseData.name_initials
    this.current_frm_company_id = responseData.current_frm_company_id
  }
}

export class SimplifiedPhoenixUserPhone {
  // Really simplified, for now...
  unique_identifier: string
  phone_number: string
  name: string

  is_admin?: boolean
  user_profile?: UserProfile // Not implementing this, yet anyway

  email?: string // Not convinced this belongs here instead of creating a link between phones and users

  current_funeral_home_unique_id?: string // This needs to go away

  has_active_subscription?: boolean // TODO

  avatar_url?: string // TODO

  is_superuser?: boolean // TODO

  onboarding_flags?: OnboardingFlags // TODO

  email_is_unknown?: boolean // TODO, also remove?

  active_funeral_home_memberships?: FuneralHomeMembership[]

  constructor(data: any) {
    this.unique_identifier = data.unique_id
    this.phone_number = data.phone_number
    this.name = data.name
    this.is_admin = data.is_admin
  }

  hasSelectedUserType() {
    return false
  }
  hasFuneralHomeMembership() {
    return false
  }
  isFuneralHomeUser() {
    return false
  }

  isWaitingForApproval() {
    // Everyone is waiting for approval, for now.
    return true
  }
}
