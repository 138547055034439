import {useCallback, useEffect, useState} from 'react'
import {Channel, Socket, Presence} from 'phoenix'
import {v4 as uuidv4} from 'uuid'

import Timer from './timer'

export interface SessionPresence {
  whoIsTyping?: string
}

interface AgentMessage {
  action: 'typing' | 'text'
  type: 'message' | 'action'
  payload: any
}

interface AgentMessageTypingPayload {
  is_typing: boolean
}

interface UserMessage {
  action: 'text'
  type: 'message'
  payload: any
}

export interface SessionMessage {
  identifier: string // Front-end only right now....
  from_user?: string // Or from_agent
  from_agent?: string
  message: string
  is_generating?: boolean
}

export interface ReceivedAnalysis {
  data: {
    type_of_analysis: 'by_session_category' | 'by_sentiment_analysis' | 'by_session_intent'
    final_result: any
  }
  inserted_at: string
  onboarding_chat_session_id: number
  onboarding_chat_session_message_id: number
  unique_identifier: string
  updated_at: string
}

function renderOnlineUsers(presences: any) {
  // Implement your rendering logic to show online users
  // TODO
}

const useNextObituaryWebsocket = ({
  apiAccessToken,
  obituaryUniqueId,
}: {
  apiAccessToken: string
  obituaryUniqueId: string
}) => {
  const [socket, setSocket] = useState<Socket | null>(null)
  const [lobbyChannel, setLobbyChannel] = useState<Channel | null>(null)
  const [obituaryChannel, setObituaryChannel] = useState<Channel | null>(null)

  const [connectedToLobby, setConnectedToLobby] = useState(false)

  const [sessionPresence, setSessionPresence] = useState<SessionPresence>({})

  const [sessionMessages, setSessionMessages] = useState<SessionMessage[]>([])
  const [sessionAnalyses, setSessionAnalyses] = useState<ReceivedAnalysis[]>([])

  const [finishedAskingQuestions, setFinishedAskingQuestions] = useState(false)

  const onLogOut = useCallback(() => {
    if (obituaryChannel) {
      obituaryChannel.leave().receive('ok', () => {
        console.log('Left session channel')
      })
    }
    if (lobbyChannel) {
      lobbyChannel.leave().receive('ok', () => {
        console.log('Left lobby channel')
      })
    }
    setSessionMessages([])
    setSessionPresence({})
    window.location.reload()
  }, [lobbyChannel, obituaryChannel])

  const onNewSession = useCallback(() => {
    if (obituaryChannel) {
      obituaryChannel.leave().receive('ok', () => {
        console.log('Left session channel')
      })
    }
    setSessionMessages([])
    setSessionPresence({})
  }, [obituaryChannel])

  useEffect(() => {
    if (!obituaryUniqueId) {
      return
    }

    if (!apiAccessToken) {
      return
    }

    const socket = new Socket(`${process.env.REACT_APP_NEXT_WS_URL}`, {
      params: {token: apiAccessToken},
      logger: (kind, msg, data) => {
        // console.log(`${kind}: ${msg}`, data);
      },
      reconnectAfterMs: (tries) => {
        return [1000, 5000, 10000][tries - 1] || 10000
      },
      heartbeatIntervalMs: 5000,
    })

    const sessionLobbyChannel = socket.channel(`obituaries:lobby`, {})
    setLobbyChannel(sessionLobbyChannel)

    const reconnectTimer = new Timer(
      () => {
        setConnectedToLobby(false)
      },
      function (tries: any) {
        return [1000, 5000, 10000][tries - 1] || 10000
      }
    )

    reconnectTimer.scheduleTimeout() // fires after 1000
    reconnectTimer.scheduleTimeout() // fires after 5000
    reconnectTimer.reset()
    reconnectTimer.scheduleTimeout() // fires after 1000

    socket.onOpen(() => {
      reconnectTimer.reset()
    })

    socket.onError(() => {
      setConnectedToLobby(false)
      reconnectTimer.reset()
      reconnectTimer.scheduleTimeout()
    })

    socket.onClose(() => {
      setConnectedToLobby(false)
      reconnectTimer.reset()
      reconnectTimer.scheduleTimeout()
    })

    socket.connect()

    setSocket(socket)

    function joinSpecificSession() {
      const updatedObituaryChannel = socket.channel(`obituaries:${obituaryUniqueId}`, {})
      updatedObituaryChannel
        .join()
        .receive('ok', (sessionJoinResponse) => {
          if (sessionJoinResponse.latest_analyses) {
            setSessionAnalyses(sessionJoinResponse.latest_analyses)
          }
          setObituaryChannel(updatedObituaryChannel)
        })
        .receive('error', (sessionJoinError) => {
          console.warn('Unable to join', sessionJoinError)
        })
      updatedObituaryChannel.on('new_analysis', (msg) => {
        console.log('new_analysis: ', msg)
        const castedMessage = msg.payload as ReceivedAnalysis
        setSessionAnalyses((prevSessionAnalyses) => [...prevSessionAnalyses, castedMessage])
      })
      updatedObituaryChannel.on('new_msg_from_agent_started', (msg) => {
        if (msg.type_of_message === 'function_finish_asking_questions') {
          setFinishedAskingQuestions(true)
        }
        // check type_of_message
        setSessionMessages((prevSessionMessages) => [
          ...prevSessionMessages,
          {
            identifier: msg.session_message_identifier,
            from_agent: msg.payload.from_agent,
            message: '',
            is_generating: true,
          } as SessionMessage,
        ])
      })
      updatedObituaryChannel.on('new_msg_from_agent_is_in_flight', (msg) => {
        // msg has session_message_identifier also
        // Msg has payload: { from_agent: "Emily", message: "delta of message" }
        // we will add this message text to the matching message's text.
        setSessionMessages((prevSessionMessages) => {
          return prevSessionMessages.map((prevSessionMessage) => {
            if (
              prevSessionMessage.identifier === msg.session_message_identifier &&
              msg.payload.message
            ) {
              return {
                ...prevSessionMessage,
                message: prevSessionMessage.message + (msg.payload.message || ''),
              }
            } else {
              return prevSessionMessage
            }
          })
        })
      })
      updatedObituaryChannel.on('new_msg_from_agent', (msg) => {
        const agentMessage = msg as AgentMessage
        if (agentMessage.type === 'action') {
          if (agentMessage.action === 'typing') {
            const payload = agentMessage.payload as AgentMessageTypingPayload
            setSessionPresence({
              whoIsTyping: payload.is_typing ? 'Emily is typing...' : '',
            })
          } else {
            console.warn('Unsupported `msg.action` of ', agentMessage.action, agentMessage)
          }
        } else if (agentMessage.type === 'message') {
          const message = agentMessage.payload as SessionMessage
          if (!message.identifier) {
            message.identifier = uuidv4()
          }
          setSessionMessages((prevSessionMessages) => [...prevSessionMessages, message])
        } else {
          console.warn('Unsupported `msg.type` of ', agentMessage.type, agentMessage)
        }
      })
      updatedObituaryChannel.on('new_msg_from_user', (msg) => {
        const userMessage = msg as UserMessage
        if (userMessage.type === 'message') {
          const message = userMessage.payload as SessionMessage
          if (!message.identifier) {
            message.identifier = uuidv4()
          }
          setSessionMessages((prevSessionMessages) => [...prevSessionMessages, message])
        } else {
          console.warn('Unsupported `msg.type` of ', userMessage.type, userMessage)
        }
      })
      setConnectedToLobby(true)
    }

    let presences: any = {}
    sessionLobbyChannel
      .join()
      .receive('ok', (joinResponse) => {
        joinSpecificSession()
      })
      .receive('error', (resp) => {
        console.warn('Unable to join lobby channel', resp)
      })
    sessionLobbyChannel.on('presence_state', (state) => {
      presences = Presence.syncState(presences, state)
      renderOnlineUsers(presences)
    })
    sessionLobbyChannel.on('presence_diff', (diff) => {
      presences = Presence.syncDiff(presences, diff)
      renderOnlineUsers(presences)
    })

    // Cleanup on unmount
    return () => {
      socket.disconnect()
      reconnectTimer.reset()
    }
  }, [apiAccessToken, obituaryUniqueId])

  return {
    obituaryChannel,
  }
}

export default useNextObituaryWebsocket
