import React, { useEffect, useState } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'

import usePageTitle from '../../../hooks/usePageTitle'
import { getJourneyFromAdmin } from '../../../funeral_homes/dashboard/core/_requests'
import { useAuth } from '../../auth/core/Auth'
import { Journey } from '../../../funeral_homes/dashboard/core/journey_classes'
import JourneyHeader from './journey-tabs/JourneyHeader'
import JourneyDetail from './journey-tabs/JourneyDetail'
import JourneyQuestions from './journey-tabs/JourneyQuestions'
import JourneyTributeDisplay from './journey-tabs/JourneyTributeDisplay'
import JourneyPoemDisplay from './journey-tabs/JourneyPoemDisplay'
import JourneyQuotesAndScriptureDisplay from './journey-tabs/JourneyQuotesAndScriptureDisplay'
import JourneyFamilySectionDisplay from './journey-tabs/JourneyFamilySectionDisplay'
import JourneyProvider from './journey-tabs/_JourneyProvider'

function JourneyDetailView() {

  const params = useParams()

  return (
    <JourneyProvider>
      <Routes>
        <Route
          element={
            <>
              <JourneyHeader />
              <div className='row g-5 g-xxl-8'>
                <div className='col-xl-12'>
                  <div
                    className={`card`}
                    style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                  >
                    {/* begin::Body */}
                    <div className='card-body pb-6 pt-0 ps-0 pe-0 pt-md-4 pb-md-10 ps-md-10 pe-md-10'>
                      <Outlet />
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        >
          <Route
            path='*'
            element={
              <div>
                <Routes>
                  <Route path="" element={<JourneyDetail />} />
                  <Route path="/questions" element={<JourneyQuestions />} />
                  <Route path="/family-section" element={<JourneyFamilySectionDisplay />} />
                  <Route path="/written-tribute" element={<JourneyTributeDisplay />} />
                  <Route path="/poetry" element={<JourneyPoemDisplay />} />
                  <Route path="/quotes-and-scripture" element={<JourneyQuotesAndScriptureDisplay />} />
                  <Route path="*" element={<Navigate to={params.unique_id ? `/journeys/${params.unique_id}` : '/obituaries'} />} />
                </Routes>
              </div>
            }
          />
        </Route>
      </Routes>
    </JourneyProvider>
  )
}

export default JourneyDetailView
