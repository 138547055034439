import {Channel} from 'phoenix'

import {useObituaryDetail} from '../../modules/obituaries/components/ObituaryDetail'
import {useShareableObituary} from '../ShareableView'

function useObituaryChannel(): {obituaryChannel: Channel | null} {
  // One day we will have only a single context and provider for the app and we'll
  // be able to update this hook to use said new context. For now though
  // we used to have a user front-end and admin front-end (still do, but used to too),
  // and we need to just return a single channel for both.

  const {obituaryChannel: shareableObituaryChannel} = useShareableObituary()
  const {obituaryChannel: detailObituaryChannel} = useObituaryDetail()

  // There will be only 1 here. We should likely consolidate the contexts above,
  // but in the interest of time we're going to do this for now.

  const obituaryChannel = shareableObituaryChannel || detailObituaryChannel || null

  return {
    obituaryChannel,
  }
}

export default useObituaryChannel
