import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const LargeModalDialog = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 98vw;
    max-height: 98vh;
    height: auto;
`;

const CleanAndFullHeightIframe = styled.iframe`
    border: none;
    width: 100%;
    height: 100%;
`;

function JourneyPersonalizationPreviewModal({ onClose }: { onClose: () => void }) {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const body = document.querySelector('body');
        if (!body) {
            return;
        }
        body.classList.add('modal-open');
        return () => {
            body.classList.remove('modal-open');
        }
    }, [])

    return <>
        <div className='modal fade show' style={{ display: 'block' }}>
            <LargeModalDialog className='modal-dialog modal-dialog-centered modal-xl'>
                <div className='modal-content h-100'>
                    <div className='modal-header'>
                        <h5 className='modal-title'>Preview</h5>
                        <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={onClose}></button>
                    </div>
                    <div className='modal-body'>
                        {loading && <div className='d-flex justify-content-center'>
                            <div className='spinner-border text-primary' role='status'>
                                <span className='visually-hidden'>Loading...</span>
                            </div>
                        </div>}
                        <CleanAndFullHeightIframe
                            src="http://solemnoaks.com/"
                            onLoad={() => {
                                setLoading(false);
                            }} />
                    </div>
                    <div className='modal-footer'>
                        <button type='button' className='btn btn-secondary' data-bs-dismiss='modal' onClick={onClose}>Close</button>
                    </div>
                </div>
            </LargeModalDialog>
        </div>
        <div className='modal-backdrop fade show'></div>
    </>
}

export default JourneyPersonalizationPreviewModal;