import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { ApiError } from '../../../auth'
import { saveObituaryComponent } from '../../core/_requests'
import { educationSchema } from '../../form_schema'
import { Education, KnownFamilyMember, Obituary } from '../../core/_models'
import FormTitle from './FormTitle'
import { IAvailableFormType, ObituaryWebsocket } from '../../../../obituaries/useObituaryWebsocket'
import useComplicatedFormBehavior from '../../../../obituaries/useComplicatedFormBehavior'
import FormItemsRenderer, { IItemProps } from './FormItemsRenderer'
import useObituaryPartialUpdater from '../../../../obituaries/useObituaryPartialUpdater'
import useObituaryFormUpdater from '../../../../obituaries/next/useObituaryFormUpdater'
import useObituaryChannel from '../../../../obituaries/next/useObituaryChannel'

const _educationToFormikValues = (education: Education): Education => {
  return {
    schoolsAttended: education.schoolsAttended || '',
    notedAchievements: education.notedAchievements || '',
  }
}

function EducationForm({
  obituary,
  setObituary,
  onSuccessfulSave,
  goBackUrl,
  obituaryWebsocket,
  userRelationshipToObituary,
}: {
  obituary: Obituary
  setObituary: (obituary: Obituary) => void
  onSuccessfulSave: (updatedObituary: Obituary) => void
  goBackUrl: string
  obituaryWebsocket: ObituaryWebsocket | null
  userRelationshipToObituary?: KnownFamilyMember | null
}) {

  const {
    obituaryChannel
  } = useObituaryChannel();

  const title = 'Education'

  const formik = useFormik<Education>({
    initialValues: _educationToFormikValues(obituary.data_education || ({} as Education)),
    validationSchema: educationSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true)
      setStatus(undefined)
      try {
        const updatedObituary = await saveObituaryComponent(obituary.unique_identifier, 'education', values)
        setObituary(updatedObituary)
        onSuccessfulSave(updatedObituary)
      } catch (error: any) {
        console.warn(error)
        const errorMessage = error instanceof ApiError ? error.message : 'Unable to save education'
        setStatus(errorMessage)
      }
    },
  })

  const formError = formik.status

  const items: IItemProps[] = [
    {
      label:
        'Share details about any schools you want mentioned, including years attended and major or course of study.',
      name: 'schoolsAttended',
      type: 'text',
      value: formik.values.schoolsAttended,
      autoFocus: false,
      required: false,
      error: formik.errors.schoolsAttended,
      touched: formik.touched.schoolsAttended,
      isTextArea: true,
      placeholder:
        'e.g. UCLA, 1976-1980, Business / Riverdale High School / culinary class in Paris...',
      additionalQuestionId: 'education.schoolsAttended',
    } as IItemProps,
    {
      label: !userRelationshipToObituary?.is_self
        ? 'Would you like to share any notable facts, achievements, or stories about their time at these schools?'
        : 'Would you like to share any notable facts, achievements, or stories about your time at these schools?',
      name: 'notedAchievements',
      type: 'text',
      value: formik.values.notedAchievements,
      autoFocus: false,
      required: false,
      error: formik.errors.notedAchievements,
      touched: formik.touched.notedAchievements,
      isTextArea: true,
      placeholder: 'e.g. Alpha Phi Alpha / The Princeton Tigers / cum laude...',
      additionalQuestionId: 'education.notedAchievements',
    } as IItemProps,
  ]

  const formType: IAvailableFormType = 'education'
  const { editingFieldsByName } = useComplicatedFormBehavior(formType, obituaryWebsocket, formik)

  useObituaryPartialUpdater(obituary.unique_identifier, 'education', (updatedData: any) => {
    formik.setValues(_educationToFormikValues(updatedData))
    obituary.data_education = updatedData // Should we call setObituary here? Hm
  })

  useObituaryFormUpdater({
    obituaryChannel,
    onChange: (event) => {
      if (event.formType === 'education') {
        formik.setFieldValue(event.formName, event.formValue)
      }
    }
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormTitle title={title} />
        {formError && (
          <div className='alert alert-danger'>
            <div className='alert-text'>{formError}</div>
          </div>
        )}
        <FormItemsRenderer
          formType={formType}
          formik={formik}
          obituaryWebsocket={obituaryWebsocket}
          setObituary={setObituary}
          items={items}
          editingFieldsByName={editingFieldsByName}
          receivedQuestions={obituary.data_education?.receivedQuestions || undefined}
          obituaryChannel={obituaryChannel}
        />
        <div className='row mt-10'>
          <div className='col-12 d-flex justify-content-between align-items-center'>
            <Link to={goBackUrl} className='btn btn-secondary me-3'>
              Back
            </Link>
            <button type='submit' className='btn btn-primary'>
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EducationForm
