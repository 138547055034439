import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { SimpleUserModel } from '../../../auth/core/_models'

// IUsersWhoAreEditingFieldsType is a dictionary of {field_name: {unique_id: user}}

// TODO: Should this have 3 nested records? Not sure,
// Check this against IUsersWhoAreOnPages

export type IUsersWhoAreEditingFieldsType = Record<
  string,
  Record<string, Record<string, SimpleUserModel>>
>

// We also may have a user: field relationship also but keeping it to 1 for now for simplicity

// FieldBeingEditedItem is a sub-record of the editingFieldsByNameType

const EditingContainer = styled.div`
  padding: 0 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 0 0 1px #ccc;
  /* Background uses the "info" variable from bootstrap: */
  background-color: #0dcaf0;
  color: #fff;
`

function FieldBeingEditedItem({
  editingFieldByName,
}: {
  editingFieldByName: IUsersWhoAreEditingFieldsType[keyof IUsersWhoAreEditingFieldsType]
}) {
  // current user is already filtered
  const [editingUsers, setEditingUsers] = useState<SimpleUserModel[]>([])

  useEffect(() => {
    // editingFieldByName.users is a dictionary of {unique_id: user}
    // We need to convert this here to just an array of users
    const users = Object.values(editingFieldByName.users)
    setEditingUsers(users)
  }, [editingFieldByName])

  const editingUserNames = editingUsers.map((user) => user.user_profile?.getSimpleName()).filter(Boolean)

  let editingString = ''

  if (editingUserNames.length === 1) {
    editingString = `${editingUserNames[0]} is editing`
  } else if (editingUserNames.length === 2) {
    editingString = `${editingUserNames[0]} and ${editingUserNames[1]} are editing`
  } else if (editingUserNames.length > 2) {
    // Just show the number of people editing
    editingString = `${editingUserNames.length} people are editing`
  }

  return (
    <div className='flex items-center pe-3'>
      {editingUsers.map((user) => {
        return <EditingContainer key={`${user.unique_id}`}>{editingString}</EditingContainer>
      })}
    </div>
  )
}

export default FieldBeingEditedItem
