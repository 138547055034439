import { Link, Navigate } from 'react-router-dom'
import styled from 'styled-components'


// Container has 2 columns, where both options fill entirely

export const StyledSelectOptionContainer = styled.div`
    height: 240px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

// A large rounded square with centered content
// On hover the background is a pleasing light grey
export const StyledSelectOption = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #EFEFEF;
    overflow: hidden;

    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: rgba(35, 40, 51, 0.06);
    }
`;

// PrimaryStyledSelectOption has a pleasing primary blue color

export const PrimaryStyledSelectOption = styled(StyledSelectOption)`
    border: 1px solid #009ef7;
    position: relative;

    :after {
        content: 'Recommended';
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        background-color: #009ef7;
        color: white;
        padding: 4px 8px;
        border-radius: 0 0 8px 0;
    }
`;

const SelectTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: black;
`;

const SelectSubtitle = styled.div`
    font-size: 14px;
    text-align: center;
    padding: 4px 8px;
    border-radius: 4px;
`;

const SelectSubtitlePrimary = styled(SelectSubtitle)`
    background-color: #e8fff3;
    color: #50cd89;
`;

const SelectSubtitleSecondary = styled(SelectSubtitle)`
    background-color: #fff8dd;
    color: #f5b800;
`;

// The example is all centered in the bottom:

const ViewSamplesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
`;

const ViewSamplesTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
`;

const ViewSamplesThumbnailContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const ViewSamplesExampleThumbnail = styled.img`
    width: 168px;
    height: 95px;
    border-radius: 8px;
    margin: 0 8px;
    cursor: pointer;
    border: 1px solid #EFEFEF;

    &:hover {
        border: 1px solid #009ef7;
    }
`;

function PersonalizeWelcomeVideo({ basePath }: { basePath: string }) {

    // Temporary redirect to record video:

    return <Navigate to={`${basePath}/record`} replace={true} />

    return <div>
        <h2 className='mb-8'>
            Upload or record a welcome video
        </h2>
        <div role='alert' className='alert alert-info mt-4 fs-4'>
            In this section, introduce your funeral home, highlight your services, and extend a warm greeting to website visitors. Keep the video under 2 minutes for optimal engagement.
        </div>
        <StyledSelectOptionContainer className='mt-8'>
            <StyledSelectOption to={`${basePath}/record`} className='me-2'>
                <SelectTitle>
                    Upload Video
                </SelectTitle>
                <SelectSubtitleSecondary>
                    {false ? 'Best quality' : 'Coming soon'}
                </SelectSubtitleSecondary>
            </StyledSelectOption>
            <PrimaryStyledSelectOption to={`${basePath}/record`} className='ms-2'>
                <SelectTitle>
                    Record Video
                </SelectTitle>
                <SelectSubtitlePrimary>
                    Quickest option
                </SelectSubtitlePrimary>
            </PrimaryStyledSelectOption>
        </StyledSelectOptionContainer>
        <ViewSamplesContainer>
            <ViewSamplesTitle>
                View sample welcome videos
            </ViewSamplesTitle>
            <ViewSamplesThumbnailContainer>
                <ViewSamplesExampleThumbnail src='https://via.placeholder.com/168x95' />
                <ViewSamplesExampleThumbnail src='https://via.placeholder.com/168x95' />
            </ViewSamplesThumbnailContainer>
        </ViewSamplesContainer>
    </div>
}

export default PersonalizeWelcomeVideo;