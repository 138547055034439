/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect } from 'react'
import { useObituaryDetail } from './components/ObituaryDetail'
import usePageTitle from '../../hooks/usePageTitle'
import { Link } from 'react-router-dom'
import PersonalizedPoemComponent from './PersonalizedPoemComponent'
import GratitudeAlphabetComponent from './GratitudeAlphabetComponent'
import { getObituaryByShareableCode } from './core/_requests'

interface MemorialFolderProps { }

function MemorialFolder({ }: MemorialFolderProps) {
  const { obituary, setObituary, obituaryWebsocket } = useObituaryDetail()

  useEffect(() => {
    if (obituaryWebsocket) {
      obituaryWebsocket.sendPageView('memorial-folder')
    }
  }, [obituaryWebsocket])

  usePageTitle({
    title: `${obituary.getSimpleName()}'s Obituary | Memorial Folder`,
  })

  const onReloadObituary = useCallback(async () => {
    if (!obituary) {
      return
    }
    try {
      // This is called in the `Write Obituary` tab
      // We use `getObituaryByShareableCode` cause it's new and better
      const { obituary: updatedObituary } = await getObituaryByShareableCode(obituary.unique_identifier)
      setObituary(updatedObituary)
    } catch (error) {
      console.warn(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obituary.unique_identifier, setObituary])

  useEffect(() => {
    onReloadObituary()
  }, [onReloadObituary])


  if (!obituary.hasWrittenBiography()) {
    return (
      <div className='alert alert-warning'>
        <div className='alert-text font-weight-bold text-dark'>
          Before working on the memorial folder you must
          <Link
            to={`/obituaries/${obituary.unique_identifier}/write`}
            className='ms-1 me-1 text-dark text-decoration-underline'
          >
            write the obituary here
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div>
      {false && 'tabsAreDisabled' && (
        <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 ps-4'>
          <li className='nav-item'>
            <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
              Contributors to this Obituary
            </a>
          </li>
          {false && 'disabledForNow' && (
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                Send to Newspaper
              </a>
            </li>
          )}
        </ul>
      )}
      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
          <div className='row mt-2'>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <PersonalizedPoemComponent title='Personalized Poem' />
                </div>
                <div className='col-12 col-md-6'>
                  <GratitudeAlphabetComponent
                    title='Gratitude Alphabet'
                    helpText='A gratitude alphabet is a list of words that start with each letter of the alphabet. It is a way to express gratitude for the things in life that you are thankful for.'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
          <div className='row'>
            <div className='col-lg-12'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MemorialFolder
