export class GratitudeAlphabet {
  unique_identifier: string
  inserted_at: string
  updated_at: string

  text_content: string
  type_of_derivative_work: 'gratitude_alphabet' // We may end up abstracting derivative works so this could be useful

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at

    this.text_content = data.text_content
    this.type_of_derivative_work = data.type_of_derivative_work
  }
}
