import React, { useCallback, useEffect } from "react"

import { getDashboardJourneyQuestions } from "../../../../funeral_homes/dashboard/core/_requests"
import { JourneyAnswer } from "../../../../funeral_homes/dashboard/core/journey_classes"
import { ApiError } from "../../../auth"
import { useJourneyDetail } from "./_JourneyProvider"

function JourneyQuestions() {
    const {
        journey
    } = useJourneyDetail();

    const [loading, setLoading] = React.useState(true)
    const [loadingError, setLoadingError] = React.useState<string | null>(null)

    const [questionAnswers, setQuestionAnswers] = React.useState<JourneyAnswer[]>([])

    const loadDashboardData = useCallback(async () => {
        if (!journey?.unique_identifier) {
            return;
        }
        setLoading(true)
        try {
            const updatedData = await getDashboardJourneyQuestions(journey.unique_identifier)
            setQuestionAnswers(updatedData.journeyAnswers)
            setLoadingError(null)
        } catch (error: any) {
            const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
            setLoadingError(errorMessage)
        } finally {
            setLoading(false)
        }
    }, [journey?.unique_identifier])

    useEffect(() => {
        loadDashboardData()
    }, [loadDashboardData])

    if (loading) {
        return null;
    }

    if (loadingError) {
        return <div>
            {loadingError}
        </div>
    }

    return <div>
        {questionAnswers.length === 0 ? <div>No questions found</div> : <div>
            {questionAnswers.map((questionAnswer, index) => {
                return <div key={index} className="mb-3">
                    <div>
                        {questionAnswer.data.question_payload.question_text}
                    </div>
                    {questionAnswer.data.question_payload.selected_items && questionAnswer.data.question_payload.selected_items?.length > 0 && <div>
                        Selected: {questionAnswer.data.question_payload.selected_items?.join(', ')}
                    </div>}
                    {questionAnswer.data.question_payload.inputted_text && <div>
                        Answered: {questionAnswer.data.question_payload.inputted_text}
                    </div>}
                </div>
            })}
        </div>}
    </div>
}

export default JourneyQuestions;