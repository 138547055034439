import {useEffect, useState} from 'react'
import {
  ObituarySectionWriteUpdate,
  ObituaryWebsocket,
  handleIncomingWebsocketMessage,
} from './useObituaryWebsocket'
import {SimpleUserModel} from '../modules/auth/core/_models'
import {Obituary} from '../modules/obituaries/core/_models'

// export type ISectionsBeingGenerated = ...

// ISectionsBeingGenerated is basically a dict of {section_id: {status: string, user: SimpleUserModel}}

export type ISectionsBeingGenerated = Record<
  string,
  {status: string; message: string; user: SimpleUserModel}
>

const defaultSectionsBeingGenerated: ISectionsBeingGenerated = {}

function useSimpleSectionWriteUpdate(
  obituaryWebsocket: ObituaryWebsocket | null,
  obituaryUpdatedCallback: (obituary: Obituary) => void
) {
  const [sectionsBeingGenerated, setSectionsBeingGenerated] = useState<ISectionsBeingGenerated>({
    ...defaultSectionsBeingGenerated,
  })

  useEffect(() => {
    let workingSectionsBeingGenerated = {
      ...defaultSectionsBeingGenerated,
    }
    if (obituaryWebsocket) {
      if (obituaryWebsocket.websocket) {
        const handleMessage = (message: any) => {
          // First we parse the message. We use handleIncomingWebsocketMessage:
          const parsedMessage = handleIncomingWebsocketMessage(message)
          if (parsedMessage instanceof ObituarySectionWriteUpdate) {
            // We check for the obituary update message first:
            if (parsedMessage.obituary) {
              obituaryUpdatedCallback(parsedMessage.obituary)
            }
            // Then we update the sectionsBeingGenerated:
            const sectionUniqueId = parsedMessage.section_unique_id
            const user = parsedMessage.user
            const status = parsedMessage.status
            const message = parsedMessage.message
            // Update the workingSectionsBeingGenerated:
            workingSectionsBeingGenerated = {
              ...workingSectionsBeingGenerated,
              [sectionUniqueId]: {
                status,
                message,
                user,
              },
            }
            // Update the state:
            setSectionsBeingGenerated(workingSectionsBeingGenerated)
            return
          }
        }
        obituaryWebsocket.websocket.addEventListener('message', handleMessage)
        return () => {
          if (obituaryWebsocket.websocket) {
            obituaryWebsocket.websocket.removeEventListener('message', handleMessage)
          }
        }
      }
    }
  }, [obituaryUpdatedCallback, obituaryWebsocket])

  return {sectionsBeingGenerated}
}

export default useSimpleSectionWriteUpdate
