import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { ApiError } from '../../../auth'
import { saveObituaryComponent } from '../../core/_requests'
import { lifeEventsSchema } from '../../form_schema'
import { KnownFamilyMember, LifeEvents, Obituary } from '../../core/_models'
import FormTitle from './FormTitle'
import { IAvailableFormType, ObituaryWebsocket } from '../../../../obituaries/useObituaryWebsocket'
import useComplicatedFormBehavior from '../../../../obituaries/useComplicatedFormBehavior'
import FormItemsRenderer, { IItemProps } from './FormItemsRenderer'
import useObituaryPartialUpdater from '../../../../obituaries/useObituaryPartialUpdater'
import useObituaryFormUpdater from '../../../../obituaries/next/useObituaryFormUpdater'
import useObituaryChannel from '../../../../obituaries/next/useObituaryChannel'

const _lifeEventsToFormikValues = (lifeEvents: LifeEvents): LifeEvents => {
  return {
    weddingsOrBirthsOrSignificantEvents: lifeEvents.weddingsOrBirthsOrSignificantEvents || '',
  }
}

function LifeEventsForm({
  obituary,
  setObituary,
  onSuccessfulSave,
  goBackUrl,
  obituaryWebsocket,
}: {
  obituary: Obituary
  setObituary: (obituary: Obituary) => void
  onSuccessfulSave: (updatedObituary: Obituary) => void
  goBackUrl: string
  obituaryWebsocket: ObituaryWebsocket | null
  userRelationshipToObituary?: KnownFamilyMember | null
}) {
  const {
    obituaryChannel
  } = useObituaryChannel();

  const title = 'Life Events'

  const formik = useFormik<LifeEvents>({
    initialValues: _lifeEventsToFormikValues(obituary.data_life_events || ({} as LifeEvents)),
    validationSchema: lifeEventsSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true)
      setStatus(undefined)
      try {
        const updatedObituary = await saveObituaryComponent(
          obituary.unique_identifier,
          'life-events',
          values
        )
        setObituary(updatedObituary)
        onSuccessfulSave(updatedObituary)
      } catch (error: any) {
        console.warn(error)
        const errorMessage =
          error instanceof ApiError ? error.message : 'Unable to save life events'
        setStatus(errorMessage)
      }
    },
  })

  const formError = formik.status

  const items: IItemProps[] = [
    {
      label:
        'Share details about weddings, births of children, or any other significant events or memories you would like to include.',
      name: 'weddingsOrBirthsOrSignificantEvents',
      type: 'text',
      value: formik.values.weddingsOrBirthsOrSignificantEvents,
      autoFocus: false,
      required: false,
      error: formik.errors.weddingsOrBirthsOrSignificantEvents,
      touched: formik.touched.weddingsOrBirthsOrSignificantEvents,
      isTextArea: true,
      placeholder:
        'e.g. community center volunteering / married the love of their life / traveled by train all over Europe...',
      additionalQuestionId: 'life-events.weddingsOrBirthsOrSignificantEvents',
    } as IItemProps,
  ]

  const formType: IAvailableFormType = 'life-events'
  const { editingFieldsByName } = useComplicatedFormBehavior(formType, obituaryWebsocket, formik)

  useObituaryPartialUpdater(obituary.unique_identifier, 'life-events', (updatedData: any) => {
    formik.setValues(_lifeEventsToFormikValues(updatedData))
    obituary.data_life_events = updatedData // Should we call setObituary here? Hm
  })

  useObituaryFormUpdater({
    obituaryChannel,
    onChange: (event) => {
      if (event.formType === 'life-events') {
        formik.setFieldValue(event.formName, event.formValue)
      }
    }
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormTitle title={title} />
        {formError && (
          <div className='alert alert-danger'>
            <div className='alert-text'>{formError}</div>
          </div>
        )}
        <FormItemsRenderer
          formType={formType}
          formik={formik}
          obituaryWebsocket={obituaryWebsocket}
          setObituary={setObituary}
          items={items}
          editingFieldsByName={editingFieldsByName}
          receivedQuestions={obituary.data_life_events?.receivedQuestions || undefined}
          obituaryChannel={obituaryChannel}
        />
        <div className='row mt-10'>
          <div className='col-12 d-flex justify-content-between align-items-center'>
            <Link to={goBackUrl} className='btn btn-secondary me-3'>
              Back
            </Link>
            <button type='submit' className='btn btn-primary'>
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default LifeEventsForm
