import {useState, useEffect} from 'react'

import {IUsersWhoAreEditingFieldsType} from '../modules/obituaries/components/forms/FieldBeingEditedItem'
import {
  handleIncomingWebsocketMessage,
  ObituaryUserLeft,
  ObituaryWebsocket,
  ObituaryAdditionalQuestionFocused,
  ObituaryAdditionalQuestionBlurred,
  ObituaryAdditionalQuestionChanged,
} from './useObituaryWebsocket'
import {useAuth} from '../modules/auth/core/Auth'
import {ObituaryEmail} from '../modules/obituaries/core/_models'

function useAdditionalQuestionsUpdates(
  obituaryWebsocket: ObituaryWebsocket | null,
  onUpdateCallback: (item?: ObituaryAdditionalQuestionChanged) => void
) {
  const {currentPhoenixUserPhone} = useAuth()

  /*
  editingFieldsByName looks like:
    {
      'questionUniqueId': {
        'users': {
          'unique_id1': {
            ...
          } as SimpleUserModel,
          'unique_id2': {
            ...
          } as SimpleUserModel,
        }
      },
    }
  */

  const [editingFieldsByName, setEditingFieldsByName] = useState<IUsersWhoAreEditingFieldsType>({})

  useEffect(() => {
    let workingEditingFieldsByName = {} as IUsersWhoAreEditingFieldsType
    if (obituaryWebsocket) {
      if (obituaryWebsocket.websocket) {
        const handleMessage = (message: any) => {
          // First we parse the message. We use handleIncomingWebsocketMessage:
          const parsedMessage = handleIncomingWebsocketMessage(message)
          if (!parsedMessage || !currentPhoenixUserPhone) {
            // Warnings are logged up-stream of this function, so we can just
            // return here.
            return
          }
          if (parsedMessage instanceof ObituaryEmail) {
            // May want to update the tab notification?
            return
          }
          if (parsedMessage.user.unique_id === currentPhoenixUserPhone.unique_identifier) {
            // All messages right now are based on other users' actions, so we
            // can just return for now
            return
          }
          if (parsedMessage instanceof ObituaryUserLeft) {
            // See if parsedMessage.user.unique_id is in workingEditingFieldsByName
            // If so, remove it
            for (const [name, value] of Object.entries(workingEditingFieldsByName)) {
              if (parsedMessage.user.unique_id in value.users) {
                delete value.users[parsedMessage.user.unique_id]
              }
              if (Object.keys(value.users).length === 0) {
                delete workingEditingFieldsByName[name]
              }
            }
            // Update state
            setEditingFieldsByName(workingEditingFieldsByName)
            onUpdateCallback()
            return
          } else if (parsedMessage instanceof ObituaryAdditionalQuestionFocused) {
            const {questionUniqueId, user} = parsedMessage
            // Update workingEditingFieldsByName
            if (questionUniqueId in workingEditingFieldsByName) {
              // If name is already in workingEditingFieldsByName, add user to
              // users
              workingEditingFieldsByName[questionUniqueId].users[user.unique_id] = user
            } else {
              // If name is not in workingEditingFieldsByName, add it
              workingEditingFieldsByName[questionUniqueId] = {
                users: {
                  [user.unique_id]: user,
                },
              }
            }
            // Update state
            setEditingFieldsByName(workingEditingFieldsByName)
            onUpdateCallback()
            // See if parsedMessage.name is in formik.values
            return
          } else if (parsedMessage instanceof ObituaryAdditionalQuestionBlurred) {
            const {questionUniqueId, user} = parsedMessage
            // Update workingEditingFieldsByName
            if (questionUniqueId in workingEditingFieldsByName) {
              // If name is already in workingEditingFieldsByName, remove user
              // from users
              delete workingEditingFieldsByName[questionUniqueId].users[user.unique_id]
            }
            // If there are no users left, delete the name
            if (
              workingEditingFieldsByName[questionUniqueId] &&
              workingEditingFieldsByName[questionUniqueId].users &&
              Object.keys(workingEditingFieldsByName[questionUniqueId].users).length === 0
            ) {
              delete workingEditingFieldsByName[questionUniqueId]
            }
            // Update state
            setEditingFieldsByName(workingEditingFieldsByName)
            onUpdateCallback()
            // See if parsedMessage.name is in formik.values
            return
          } else if (parsedMessage instanceof ObituaryAdditionalQuestionChanged) {
            // (functionally identical to ObituaryFormActionsCheckboxChanged)
            // only we don't use formik.setFieldValue right now
            onUpdateCallback(parsedMessage)
            return
          }
        }
        obituaryWebsocket.websocket.addEventListener('message', handleMessage)
        return () => {
          if (obituaryWebsocket.websocket) {
            obituaryWebsocket.websocket.removeEventListener('message', handleMessage)
          }
        }
      }
    }
  }, [currentPhoenixUserPhone, obituaryWebsocket, onUpdateCallback])

  return {
    editingFieldsByName,
  }
}

export default useAdditionalQuestionsUpdates
