/* eslint-disable jsx-a11y/anchor-is-valid */

import { useRef, useState } from 'react'
import clsx from 'clsx'
import { useMediaQuery } from 'react-responsive'

import { Link, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Menu as MenuIcon } from 'react-feather'

import { useObituaryDetail } from './ObituaryDetail'

import PersonalInformationForm from './forms/PersonalInformationForm'
import RelativesForm from './forms/RelativesForm'
import CeremonyDetailsForm from './forms/CeremonyDetailsForm'
import EducationForm from './forms/EducationForm'
import CareerForm from './forms/CareerForm'
import MilitaryServiceForm from './forms/MilitaryServiceForm'
import LifeEventsForm from './forms/LifeEventsForm'
import PersonalityAndInterestsForm from './forms/PersonalityAndInterestsForm'

import { Obituary } from '../core/_models'
import { SelectableHeader } from '../../../obituaries/ObituariesDetailItem'
import useSimpleFormPageView, { IUsersWhoAreOnPages } from '../../../obituaries/useSimpleFormPageView'
import { IAvailableFormType } from '../../../obituaries/useObituaryWebsocket'
import { SimpleUserModel, UserModel } from '../../auth/core/_models'
import AffiliationsForm from './forms/AffiliationsForm'
import FinalQuestionsForm from './forms/FinalQuestionsForm'
import usePageTitle from '../../../hooks/usePageTitle'

function buildTraditionalObituaryNavigation(obituary: Obituary) {
  return [
    {
      name: 'Personal Information',
      path: `/old-obituaries/${obituary.unique_identifier}`,
      count: obituary.personal_information_count,
      formType: 'personal_information' as IAvailableFormType,
    },
    {
      name: 'Relatives',
      path: `/old-obituaries/${obituary.unique_identifier}/relatives`,
      count: obituary.relatives_count,
      formType: 'relatives' as IAvailableFormType,
    },
    {
      name: 'Education',
      path: `/old-obituaries/${obituary.unique_identifier}/education`,
      count: obituary.education_count,
      formType: 'education' as IAvailableFormType,
    },
    {
      name: 'Career',
      path: `/old-obituaries/${obituary.unique_identifier}/career`,
      count: obituary.career_count,
      formType: 'career' as IAvailableFormType,
    },
    {
      name: 'Military Service',
      path: `/old-obituaries/${obituary.unique_identifier}/military`,
      count: obituary.military_service_count,
      formType: 'military' as IAvailableFormType,
    },
    {
      name: 'Affiliations',
      path: `/old-obituaries/${obituary.unique_identifier}/affiliations`,
      count: obituary.affiliations_count,
      formType: 'affiliations' as IAvailableFormType,
    },
    {
      name: 'Life Events',
      path: `/old-obituaries/${obituary.unique_identifier}/life-events`,
      count: obituary.life_events_count,
      formType: 'life-events' as IAvailableFormType,
    },
    {
      name: 'Personality and Interests',
      path: `/old-obituaries/${obituary.unique_identifier}/personality`,
      count: obituary.personality_and_interests_count,
      formType: 'personality' as IAvailableFormType,
    },
    /*
    false
      ? {
          name: 'Final Questions',
          path: `/old-obituaries/${obituary.unique_identifier}/final-questions`,
          count: 0, // TODO
          formType: 'final-questions' as IAvailableFormType,
        }
      : null,
      // Disabling Services & Condolences for now
    {
      name: 'Services & Condolences',
      path: `/old-obituaries/${obituary.unique_identifier}/ceremony`,
      count: obituary.ceremony_details_count,
      formType: 'services' as IAvailableFormType,
    },
      */
  ]
}

function buildWhoIsHere(users: SimpleUserModel[]) {
  try {
    const userNames = users.map((user: SimpleUserModel) => {
      return user.user_profile?.getSimpleName()
    }).filter(Boolean)
    if (userNames.length === 1) {
      return `${userNames[0]} is here`
    }
    if (userNames.length === 2) {
      return `${userNames[0]} and ${userNames[1]} are here`
    }
    if (userNames.length > 2) {
      return `${userNames[0]}, ${userNames[1]} and ${userNames.length - 2} others are here`
    }
  } catch (e) {
    console.warn(e)
    return ''
  }
}

function VerticalNav({
  onSelect,
  usersOnPage,
}: {
  onSelect: () => void
  usersOnPage: IUsersWhoAreOnPages
}) {
  const location = useLocation()
  const { obituary } = useObituaryDetail()

  return (
    <div>
      <ul className='nav nav-stretch nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap flex-column'>
        {buildTraditionalObituaryNavigation(obituary).map((item) => {
          const { name, path, count, formType } = item
          const isActive = location.pathname === path
          return (
            <li
              key={name}
              className='nav-item'
              style={
                isActive
                  ? {
                    boxShadow: 'rgb(0 0 0 / 9%) 0px 0px 4px 4px',
                    borderRadius: '4px',
                  }
                  : {}
              }
              onClick={() => {
                onSelect()
              }}
            >
              <Link
                to={path}
                className='py-3 nav-link w-100 h-100 flex-column justify-content-center align-items-start'
                style={
                  isActive
                    ? {
                      fontWeight: '500',
                    }
                    : {
                      fontWeight: '100',
                    }
                }
              >
                {name}
                {count && count > 0 ? (
                  <span
                    style={{
                      lineHeight: '10px',
                      marginTop: 10,
                      marginBottom: 10,
                      paddingTop: 0,
                      paddingLeft: 10,
                    }}
                    className='text-muted'
                  >
                    {`${count} completed ${count === 1 ? 'item' : 'items'}`}
                  </span>
                ) : (
                  <></>
                )}
                {usersOnPage[formType] && Object.keys(usersOnPage[formType]).length > 0 ? (
                  <div>
                    <span
                      style={{
                        lineHeight: '10px',
                        marginTop: 10,
                        marginBottom: 10,
                        paddingTop: 0,
                        paddingLeft: 10,
                      }}
                      className='text-muted'
                    >
                      {buildWhoIsHere(Object.values(usersOnPage[formType]))}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

function ObituaryInputForm() {
  const navigate = useNavigate()
  const topRowRef = useRef<HTMLDivElement>(null)

  const isBigScreen = useMediaQuery({ query: '(min-width: 700px)' })

  const [viewingCategories, setViewingCategories] = useState(false)

  const { obituary, setObituary, obituaryWebsocket } = useObituaryDetail()

  const { usersOnPage } = useSimpleFormPageView(obituaryWebsocket)

  usePageTitle({
    title: `${obituary.getSimpleName()}'s Obituary`,
  })

  function scrollToTopOfPage() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  function scrollToTopOfRow() {
    window.scrollTo({ top: 150, behavior: 'smooth' })
  }

  return (
    <div ref={topRowRef} className='row'>
      <div className='col-12'>
        <div className='row mt-4'>
          {/* Vertical nav ... show on mobile */}
          <div className='col-12 d-md-none'>
            {!viewingCategories ? (
              <SelectableHeader
                className='w-100 cursor-pointer px-8 py-4'
                onClick={() => setViewingCategories(true)}
              >
                <MenuIcon size={18} />
                <h1 className='ms-4'>Categories</h1>
              </SelectableHeader>
            ) : (
              <SelectableHeader className='w-100  px-8 py-4'>
                <h1>Choose a category</h1>
              </SelectableHeader>
            )}
          </div>
          {/* Vertical nav ... hide on mobile but only if !viewingCategories */}
          <div
            className={clsx(
              'col-4 pe-8',
              !viewingCategories && !isBigScreen ? 'd-none' : 'd-md-block',
              !isBigScreen ? 'col-12 ps-12 ps-md-4 ' : ''
            )}
          >
            <VerticalNav
              onSelect={() => {
                setViewingCategories(false)
                scrollToTopOfRow()
              }}
              usersOnPage={usersOnPage}
            />
          </div>
          {/* Hide if viewingCategories */}
          <div
            className={clsx(
              'col-12 col-md-8 px-8 pt-0',
              viewingCategories && !isBigScreen ? 'd-none' : ''
            )}
          >
            <Routes>
              <Route
                index
                element={
                  <PersonalInformationForm
                    obituary={obituary}
                    setObituary={setObituary}
                    obituaryWebsocket={obituaryWebsocket}
                    onSuccessfulSave={() => {
                      navigate(`/old-obituaries/${obituary.unique_identifier}/relatives`)
                      scrollToTopOfRow()
                    }}
                  />
                }
              />
              <Route
                path='relatives'
                element={
                  <RelativesForm
                    obituary={obituary}
                    setObituary={setObituary}
                    obituaryWebsocket={obituaryWebsocket}
                    onSuccessfulSave={() => {
                      navigate(`/old-obituaries/${obituary.unique_identifier}/education`)
                      scrollToTopOfRow()
                    }}
                    goBackUrl={`/old-obituaries/${obituary.unique_identifier}`}
                  />
                }
              />
              <Route
                path='education'
                element={
                  <EducationForm
                    obituary={obituary}
                    setObituary={setObituary}
                    obituaryWebsocket={obituaryWebsocket}
                    onSuccessfulSave={() => {
                      navigate(`/old-obituaries/${obituary.unique_identifier}/career`)
                      scrollToTopOfRow()
                    }}
                    goBackUrl={`/old-obituaries/${obituary.unique_identifier}/relatives`}
                  />
                }
              />
              <Route
                path='career'
                element={
                  <CareerForm
                    obituary={obituary}
                    setObituary={setObituary}
                    obituaryWebsocket={obituaryWebsocket}
                    onSuccessfulSave={() => {
                      navigate(`/old-obituaries/${obituary.unique_identifier}/military`)
                      scrollToTopOfRow()
                    }}
                    goBackUrl={`/old-obituaries/${obituary.unique_identifier}/education`}
                  />
                }
              />
              <Route
                path='military'
                element={
                  <MilitaryServiceForm
                    obituary={obituary}
                    setObituary={setObituary}
                    obituaryWebsocket={obituaryWebsocket}
                    onSuccessfulSave={() => {
                      navigate(`/old-obituaries/${obituary.unique_identifier}/affiliations`)
                      scrollToTopOfRow()
                    }}
                    goBackUrl={`/old-obituaries/${obituary.unique_identifier}/career`}
                  />
                }
              />
              <Route
                path='affiliations'
                element={
                  <AffiliationsForm
                    obituary={obituary}
                    setObituary={setObituary}
                    obituaryWebsocket={obituaryWebsocket}
                    onSuccessfulSave={() => {
                      navigate(`/old-obituaries/${obituary.unique_identifier}/life-events`)
                      scrollToTopOfRow()
                    }}
                    goBackUrl={`/old-obituaries/${obituary.unique_identifier}/military`}
                  />
                }
              />
              <Route
                path='life-events'
                element={
                  <LifeEventsForm
                    obituary={obituary}
                    setObituary={setObituary}
                    obituaryWebsocket={obituaryWebsocket}
                    onSuccessfulSave={() => {
                      navigate(`/old-obituaries/${obituary.unique_identifier}/personality`)
                      scrollToTopOfRow()
                    }}
                    goBackUrl={`/old-obituaries/${obituary.unique_identifier}/affiliations`}
                  />
                }
              />
              <Route
                path='personality'
                element={
                  <PersonalityAndInterestsForm
                    obituary={obituary}
                    setObituary={setObituary}
                    obituaryWebsocket={obituaryWebsocket}
                    onSuccessfulSave={() => {
                      // Services are disabled for now. We go directly to the write
                      // navigate(`/old-obituaries/${obituary.unique_identifier}/ceremony`)
                      navigate(`/old-obituaries/${obituary.unique_identifier}/write`)
                      scrollToTopOfRow()
                    }}
                    goBackUrl={`/old-obituaries/${obituary.unique_identifier}/life-events`}
                  />
                }
              />
              <Route
                path='ceremony'
                element={
                  <CeremonyDetailsForm
                    obituary={obituary}
                    setObituary={setObituary}
                    obituaryWebsocket={obituaryWebsocket}
                    onSuccessfulSave={() => {
                      navigate(`/old-obituaries/${obituary.unique_identifier}/write`)
                      scrollToTopOfRow()
                    }}
                    goBackUrl={`/old-obituaries/${obituary.unique_identifier}/personality`}
                  />
                }
              />
              <Route
                path='final-questions'
                element={
                  <FinalQuestionsForm
                    obituary={obituary}
                    setObituary={setObituary}
                    obituaryWebsocket={obituaryWebsocket}
                    onSuccessfulSave={() => {
                      navigate(`/old-obituaries/${obituary.unique_identifier}/write`)
                      // Scroll to top of page
                      scrollToTopOfPage()
                    }}
                    goBackUrl={`/old-obituaries/${obituary.unique_identifier}/personality`}
                  />
                }
              />
              {/* Catch all */}
              <Route
                path='*'
                element={<Navigate to={`/old-obituaries/${obituary.unique_identifier}`} replace />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ObituaryInputForm
