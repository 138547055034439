import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MainUserLayout } from '../../_metronic/layout/MainUserLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import FuneralHomeDashboardWrapper from '../funeral_homes/dashboard/FuneralHomeDashboardWrapper'
import ObituariesListWrapper from '../funeral_homes/obituaries/ObituariesListWrapper'
import JourneysListWrapper from '../funeral_homes/obituaries/JourneysListWrapper'
import { PageTitle } from '../../_metronic/layout/core'
import ObituaryDetail from '../modules/obituaries/components/ObituaryDetail'
import JourneysPage from '../modules/obituaries/JourneysPage'
import PersonalizeProvider from '../modules/obituaries/components/PersonalizeProvider'
import PaymentsProvider from '../modules/obituaries/components/payments/PaymentsProvider'
import InteractiveEmployeeProvider from '../modules/obituaries/components/interactive-employee/InteractiveEmployeeProvider'

const PrivateFuneralHomeRoutes = () => {
  const ObituariesPage = lazy(() => import('../modules/obituaries/ObituariesPage'))

  return (
    <Routes>
      <Route element={<MainUserLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<FuneralHomeDashboardWrapper />} />
        <Route path='journeys' element={<JourneysListWrapper />} />
        <Route path='old-obituaries' element={<ObituariesListWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='create-obituary/*'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={[]}>Create Obituary</PageTitle>
              <ObituaryDetail />
            </SuspensedView>
          }
        />
        {/* Lazy Modules */}
        <Route
          path='old-obituaries/*'
          element={
            <SuspensedView>
              <ObituariesPage />
            </SuspensedView>
          }
        />
        <Route
          path='journeys/*'
          element={
            <SuspensedView>
              <JourneysPage />
            </SuspensedView>
          }
        />
        {/* typically /personalize/intro */}
        <Route
          path='interactive-employee/*'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={[]}>Interactive Employee</PageTitle>
              <InteractiveEmployeeProvider />
            </SuspensedView>
          }
        />
        {/* typically /personalize/intro */}
        <Route
          path='personalize/*'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={[]}>Personalize</PageTitle>
              <PersonalizeProvider />
            </SuspensedView>
          }
        />
        {/* typically /payments/settings */}
        <Route
          path='payments/*'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={[]}>Payments</PageTitle>
              <PaymentsProvider />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/dashboard' replace />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export default PrivateFuneralHomeRoutes
