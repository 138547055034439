import { useCallback } from "react";
import { useAuth } from "../modules/auth/core/Auth"

function ComingSoon() {
    const {
        logout
    } = useAuth();

    const onLogout = useCallback(() => {
        logout()
        window.location.href = '/auth'
    }, [logout])

    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        flexDirection: 'column'
    }}>
        <div style={{ fontSize: '2rem' }}>
            This page is coming soon
        </div>
        <div className='mt-3'>
            <button
                className='btn btn-primary'
                onClick={onLogout}
            >
                Logout
            </button>
        </div>
    </div>
}

export default ComingSoon