import {useState, useEffect} from 'react'

const ProgressBarInSeconds = ({
  estimatedTimeInSeconds = 10,
  message = undefined,
}: {
  estimatedTimeInSeconds?: number
  message?: string
}) => {
  let color = 'bg-primary'

  const [value, setValue] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((prevValue) => prevValue + 100 / estimatedTimeInSeconds)
    }, 1000)
    return () => clearInterval(interval)
  }, [estimatedTimeInSeconds])

  // Make sure value doesn't go over 100:

  const progressValue = Math.round(Math.min(value, 100))

  return (
    <>
      {message && (
        <div className='alert alert-info'>
          <div className='alert-text'>
            <i className='fas fa-info-circle me-2'></i>
            {value < 100 ? message : 'Finishing up...'}
          </div>
        </div>
      )}
      <div className='d-flex flex-column w-100 me-2'>
        <div className='d-flex flex-stack mb-2'>
          <span className='text-muted me-2 fs-7 fw-semibold'>{progressValue}%</span>
        </div>
        <div className='progress h-6px w-100'>
          <div
            className={`progress-bar ${color}`}
            role='progressbar'
            style={{width: `${progressValue}%`}}
          ></div>
        </div>
      </div>
    </>
  )
}

export default ProgressBarInSeconds
