import { useCallback, useState } from "react"
import { getStripeConnectSetupLink, getStripeConnectUpdateLink } from "../../../../funeral_homes/dashboard/core/_requests"
import { ApiError } from "../../../auth"
import { useAuth } from "../../../auth/core/Auth"
import { FuneralHomePaymentSettingForAdmin } from "../../core/_models"

function PaymentsStripeConnectActions({ paymentSettings }: { paymentSettings: FuneralHomePaymentSettingForAdmin }) {
    const { currentPhoenixUser } = useAuth()
    const client = currentPhoenixUser?.current_client

    const [loadingFullPaymentSettings, setLoadingFullPaymentSettings] = useState(false)
    const [errorGeneratingStripeConnectLink, setErrorGeneratingStripeConnectLink] = useState<string | null>(null)

    const getStripeConnectLink = useCallback(async () => {
        if (!client) {
            return
        }
        setLoadingFullPaymentSettings(true)
        setErrorGeneratingStripeConnectLink(null)
        try {
            if (paymentSettings?.private_data?.stripe_connect_account?.payouts_enabled) {
                const stripeConnectRedirectLink = await getStripeConnectUpdateLink(client.unique_identifier)
                window.location.href = stripeConnectRedirectLink
            } else {
                const stripeConnectRedirectLink = await getStripeConnectSetupLink(client.unique_identifier)
                window.location.href = stripeConnectRedirectLink
            }
            // We don't set loading to false because we re-direct to Stripe
        } catch (error: any) {
            console.warn(error)
            const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
            setErrorGeneratingStripeConnectLink(errorMessage)
            setLoadingFullPaymentSettings(false)
        }
    }, [client, paymentSettings?.private_data?.stripe_connect_account?.payouts_enabled])

    if (
        !paymentSettings.private_data.stripe_connect_account?.id ||
        !paymentSettings.private_data.stripe_connect_account.payouts_enabled
    ) {
        return <div>
            <button
                className="btn btn-primary"
                type="button"
                disabled={loadingFullPaymentSettings}
                onClick={getStripeConnectLink}
            >
                {!loadingFullPaymentSettings ? 'Connect with Stripe' : 'Loading...'}
            </button>
            <div className='mt-3'>
                <div className='help-text'>
                    <p>
                        <strong>Connect with Stripe</strong>
                    </p>
                    <p>
                        Connect your Stripe account to start accepting payments. You will be redirected to Stripe to complete the process.
                    </p>
                    <p>
                        Note ... when choosing "Industry", a good selection is "Other business services".
                    </p>
                </div>
            </div>
            {errorGeneratingStripeConnectLink && <div className='mt-3'>
                <div className='alert alert-danger' role='alert'>
                    {errorGeneratingStripeConnectLink}
                </div>
            </div>}
        </div>
    }

    return <div>
        <p>
            <strong>Stripe Account</strong>
        </p>

        <p>
            <strong>Account ID:</strong> {paymentSettings.private_data.stripe_connect_account.id}
        </p>

        <p>
            <button type="button" className="btn btn-secondary btn-sm" disabled={loadingFullPaymentSettings} onClick={getStripeConnectLink}>
                {!loadingFullPaymentSettings ? 'Manage Stripe Account' : 'Loading...'}
            </button>
        </p>

        {errorGeneratingStripeConnectLink && <div className='mt-3'>
            <div className='alert alert-danger' role='alert'>
                {errorGeneratingStripeConnectLink}
            </div>
        </div>}
    </div>
}

export default PaymentsStripeConnectActions