import React, { useCallback, useEffect, useState } from "react"
import { Routes, Route, Navigate, useParams, Outlet } from "react-router-dom"
import { BlockPicker } from 'react-color';

import { FuneralHome } from "../../modules/obituaries/core/_models"
import { getDetailedClient, updateClientDetails } from "../../funeral_homes/dashboard/core/_requests"
import { ApiError } from "../../modules/auth"
import ClientHeader from "./ClientHeader"
import ClientCodeBlock from "../../components/ClientCodeBlock"
import ClientTeamList from "./ClientTeamList"
import AddTeamMemberModal from "./add-team-member-modal/AddTeamMemberModal"

interface ClientDetailParams {
    client: FuneralHome,
    addingTeamMember: boolean,
    setAddingTeamMember: (addingTeamMember: boolean) => void,
}

const ClientDetailContext = React.createContext<ClientDetailParams>({
    client: {} as FuneralHome,
    addingTeamMember: false,
    setAddingTeamMember: () => { },
})

const showLineBreaksForText = (text: string) => {
    return text.split('\n').map((item, index) => {
        return <span key={`widget-call-to-action-text-${index}`}>{item}<br /></span>
    })
}

export const useClientDetail = () => React.useContext(ClientDetailContext)

function ClientLayout() {
    const { client, addingTeamMember } = useClientDetail()

    return (
        <>
            <div>
                <ClientHeader client={client} basePath={`/admin/clients/${client.unique_identifier}`} />
                <div className="card">
                    <div className="card-body px-9 py-4">
                        <Outlet />
                    </div>
                </div>
            </div>
            {addingTeamMember && <AddTeamMemberModal />}
        </>
    )
}

function ClientColorPicker({ name, defaultColor, helpText = undefined }: { name: string, defaultColor: string, helpText?: string }) {
    const [currentColor, setCurrentColor] = useState(defaultColor)

    return <div>
        <div className="mb-3">
            <input
                name={name}
                type='text'
                className='form-control'
                placeholder='Enter color'
                value={currentColor}
                onChange={(event) => setCurrentColor(event.target.value)}
            />
        </div>
        <BlockPicker
            color={currentColor || defaultColor}
            onChangeComplete={(color) => setCurrentColor(color.hex)}
        />
        {helpText && <div className='form-text'>{helpText}</div>}
    </div>
}

export interface ClientRowItem {
    label: string,
    value: React.ReactNode,
    helpText?: string,
    isColor?: boolean
    editingComponent?: React.ReactNode,
}

export function ClientRowItemRenderer({ item, isEditing }: { item: ClientRowItem, isEditing: boolean }) {
    const { label, value, helpText, isColor } = item

    const { client } = useClientDetail()

    const useBackgroundColor = isColor && typeof value === 'string' ? value : undefined

    return <div className='row mb-7'>
        <label className='col-lg-4 fw-bold text-muted'>{label}</label>
        <div className='col-lg-8 fv-row'>
            {isEditing && item.editingComponent ? <>
                {item.editingComponent}
            </> : <>
                {!isColor ? <>
                    <span className='fw-bold fs-6'>{value}</span>
                </> : <>
                    <span style={{ backgroundColor: useBackgroundColor, border: '1px solid black' }} className='badge'>
                        <span className='fw-bold fs-6' style={{ color: client.public_data?.widget_primary_text_color || 'black' }}>{value}</span>
                    </span>
                </>}
                {helpText && <div className='form-text'>{helpText}</div>}
            </>}
        </div>
    </div>
}

function ClientOverview() {

    const { client } = useClientDetail()

    const [isEditing, setIsEditing] = useState(false)

    const [saving, setSaving] = useState(false)
    const [saveError, setSaveError] = useState<string | null>(null)

    const clientOptionItems: ClientRowItem[] = [
        {
            label: 'Client Name',
            value: client.name,
            editingComponent: <input required type='text' className='form-control' placeholder='Enter client name' name="name" defaultValue={client.name} />
        },
        {
            label: 'Client ID',
            value: client.unique_identifier,
        },
        {
            label: 'Client Address',
            value: client.public_data?.formatted_address || '(none)'
        },
    ]

    const widgetOptionItems: ClientRowItem[] = [
        {
            label: 'Widget Active?',
            value: client.widget_is_active ? 'Yes' : 'No',
            editingComponent: <div>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' id='widget_is_active' name='widget_is_active' defaultChecked={client.widget_is_active} />
                    <label className='form-check-label' htmlFor='widget_is_active'>
                        <span className='fw-bold text-muted'>Widget Active?</span>
                    </label>
                </div>
            </div>
        },
        {
            label: 'Widget Primary Color',
            value: client.public_data?.widget_primary_color ? client.public_data?.widget_primary_color : '#0170b2',
            isColor: true,
            editingComponent: <ClientColorPicker
                name="widget_primary_color"
                defaultColor={client.public_data?.widget_primary_color || '#0170b2'}
                helpText="This color will also be used for the background of the widget call to action button."
            />,
            helpText: 'This color will also be used for the background of the widget call to action button.'
        },
        {
            label: 'Widget Primary Text Color',
            value: client.public_data?.widget_primary_text_color ? client.public_data?.widget_primary_text_color : "#ffffff",
            isColor: true,
            editingComponent: <ClientColorPicker
                name="widget_primary_text_color"
                defaultColor={client.public_data?.widget_primary_text_color || '#ffffff'}
            />
        },
        {
            label: 'Widget Icon Location',
            value: client.public_data?.widget_icon_location ? client.public_data?.widget_icon_location === 'bottom_left' ? 'Bottom Left' : 'Bottom Right' : '(default, bottom right)',
            editingComponent: <div>
                <div className='form-check form-check-custom form-check-solid'>
                    <input className='form-check-input' type='radio' id='widget_icon_location_bottom_left' name='widget_icon_location' value='bottom_left' defaultChecked={client.public_data?.widget_icon_location === 'bottom_left'} />
                    <label className='form-check-label' htmlFor='widget_icon_location_bottom_left'>
                        <span className='fw-bold text-muted'>Bottom Left</span>
                    </label>
                </div>
                <div className='form-check form-check-custom form-check-solid mt-2'>
                    <input className='form-check-input' type='radio' id='widget_icon_location_bottom_right' name='widget_icon_location' value='bottom_right' defaultChecked={client.public_data?.widget_icon_location === 'bottom_right'} />
                    <label className='form-check-label' htmlFor='widget_icon_location_bottom_right'>
                        <span className='fw-bold text-muted'>Bottom Right</span>
                    </label>
                </div>
            </div>
        },
        {
            label: 'Widget Show Tribute Directly?',
            value: client.public_data?.widget_show_tribute_directly ? 'Yes' : 'No',
        },
        {
            label: 'Widget Is Larger?',
            value: client.public_data?.widget_is_larger ? 'Yes' : 'No',
            editingComponent: <div>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' id='widget_is_larger' name='widget_is_larger' defaultChecked={client.public_data?.widget_is_larger} />
                    <label className='form-check-label' htmlFor='widget_is_larger'>
                        <span className='fw-bold text-muted'>Widget Is Larger?</span>
                    </label>
                </div>
            </div>
        },
        {
            label: 'Widget Call To Action Text',
            value: client.public_data?.widget_call_to_action_text ? showLineBreaksForText(client.public_data?.widget_call_to_action_text) : '(default, "Write Obit")',
            editingComponent: <textarea className='form-control h-100px' placeholder='Enter widget call to action text' name="widget_call_to_action_text" defaultValue={client.public_data?.widget_call_to_action_text} />
        },
        {
            label: 'Inviting Text Above Widget',
            value: client.public_data?.inviting_text_above_widget || '(default, no text)',
            editingComponent: <input type='text' className='form-control' placeholder='Enter inviting text above widget' name="inviting_text_above_widget" defaultValue={client.public_data?.inviting_text_above_widget} />
        },
        {
            label: 'Enable Interactive Employee?',
            value: client.public_data?.interactive_employee_is_enabled ? 'Yes' : 'No',
            editingComponent: <div>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' id='interactive_employee_is_enabled' name='interactive_employee_is_enabled' defaultChecked={client.public_data?.interactive_employee_is_enabled} />
                    <label className='form-check-label' htmlFor='interactive_employee_is_enabled'>
                        <span className='fw-bold text-muted'>Enable Interactive Employee?</span>
                    </label>
                </div>
            </div>
        },
        {
            label: '(Temp) Video Widget Full Video URL',
            value: client.public_data?.video_widget_full_video_url || '(temporary ... full URL to the video widget video)',
            editingComponent: <input type='text' className='form-control' placeholder='Enter full URL to the video to use on page load (video widget must be enabled)' name="video_widget_full_video_url" defaultValue={client.public_data?.video_widget_full_video_url} />
        },
        {
            label: '(Temp) Video Widget Full Video URL (Preview)',
            value: client.public_data?.video_widget_preview_video_url || '(temporary ... full URL to the video widget video preview (allows for quicker load times))',
            editingComponent: <input type='text' className='form-control' placeholder='Enter full URL to the video to use on page load (video widget must be enabled)' name="video_widget_preview_video_url" defaultValue={client.public_data?.video_widget_preview_video_url} />
        },
        {
            label: 'Send Lead Notifications To Email Addresses',
            value: client.data?.send_lead_notifications_to_email_addresses || '(none)',
            editingComponent: <input type='text' className='form-control' placeholder='Enter email addresses to send lead notifications to' name="send_lead_notifications_to_email_addresses" defaultValue={client.data?.send_lead_notifications_to_email_addresses} />
        },
        {
            label: 'Private Labeling - Text / Label',
            value: client.public_data?.private_labeling_text || '(none)',
            editingComponent: <input type='text' className='form-control' placeholder='Enter private labeling text' name="private_labeling_text" defaultValue={client.public_data?.private_labeling_text} />
        },
        {
            label: 'Private Labeling - URL to link to',
            value: client.public_data?.private_labeling_url || '(none)',
            editingComponent: <input type='text' className='form-control' placeholder='Enter private labeling URL' name="private_labeling_url" defaultValue={client.public_data?.private_labeling_url} />
        },
        {
            label: 'Preview Interactive Employee at URL',
            value: client.public_data?.preview_interactive_employee_at_url || '(none, defaults to https://www.solemnoaks.com)',
            editingComponent: <input type='text' className='form-control' placeholder='Enter URL to preview interactive employee' name="preview_interactive_employee_at_url" defaultValue={client.public_data?.preview_interactive_employee_at_url} />
        },
        // ##0531b4 was NOFCS
        {
            label: 'Interactive Employee Lead Collection Background Color',
            value: client.public_data?.interactive_employee_lead_collection_background_color ? client.public_data?.interactive_employee_lead_collection_background_color : '#000000',
            isColor: true,
            editingComponent: <ClientColorPicker
                name="interactive_employee_lead_collection_background_color"
                defaultColor={client.public_data?.interactive_employee_lead_collection_background_color || '#000000'}
            />
        },
        {
            label: 'Interactive Employee Lead Collection Text Color',
            value: client.public_data?.interactive_employee_lead_collection_text_color ? client.public_data?.interactive_employee_lead_collection_text_color : '#ffffff',
            isColor: true,
            editingComponent: <ClientColorPicker
                name="interactive_employee_lead_collection_text_color"
                defaultColor={client.public_data?.interactive_employee_lead_collection_text_color || '#ffffff'}
            />
        },
    ]

    const onFormSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setSaveError(null)
        setSaving(true)
        // Doing this manually to avoid formik for now.
        const formValues = new FormData(event.currentTarget)
        const serializedFormValues: any = {}
        formValues.forEach((value, key) => {
            serializedFormValues[key] = value
        })
        const finalValues = {
            name: serializedFormValues.name,
            widget_is_active: serializedFormValues.widget_is_active === 'on',
            widget_primary_color: (serializedFormValues.widget_primary_color || '').trim(),
            widget_primary_text_color: (serializedFormValues.widget_primary_text_color || '').trim(),
            widget_icon_location: serializedFormValues.widget_icon_location,
            widget_is_larger: serializedFormValues.widget_is_larger === 'on',
            widget_call_to_action_text: (serializedFormValues.widget_call_to_action_text || '').trim(),
            inviting_text_above_widget: (serializedFormValues.inviting_text_above_widget || '').trim(),
            interactive_employee_is_enabled: serializedFormValues.interactive_employee_is_enabled === 'on',
            video_widget_full_video_url: (serializedFormValues.video_widget_full_video_url || '').trim(),
            video_widget_preview_video_url: (serializedFormValues.video_widget_preview_video_url || '').trim(),
            send_lead_notifications_to_email_addresses: (serializedFormValues.send_lead_notifications_to_email_addresses || '').trim(),
            private_labeling_text: (serializedFormValues.private_labeling_text || '').trim(),
            private_labeling_url: (serializedFormValues.private_labeling_url || '').trim(),
            preview_interactive_employee_at_url: (serializedFormValues.preview_interactive_employee_at_url || '').trim(),
            interactive_employee_lead_collection_background_color: (serializedFormValues.interactive_employee_lead_collection_background_color || '').trim(),
            interactive_employee_lead_collection_text_color: (serializedFormValues.interactive_employee_lead_collection_text_color || '').trim(),
        }
        try {
            await updateClientDetails(client.unique_identifier, finalValues)
            // For now, just reload. We will update the context one day...
            window.location.reload()
        } catch (error: any) {
            console.warn(error)
            const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
            setSaveError(errorMessage)
            setSaving(false)
        }
    }, [client.unique_identifier])

    return <form onSubmit={onFormSubmit}>
        {clientOptionItems.map((item, index) => {
            return <ClientRowItemRenderer key={`widget-option-${index}`} item={item} isEditing={isEditing} />
        })}
        <div className='separator separator-dashed my-7'></div>
        {widgetOptionItems.map((item, index) => {
            return <ClientRowItemRenderer key={`widget-option-${index}`} item={item} isEditing={isEditing} />
        })}
        <div className='separator separator-dashed my-7'></div>
        <div className='d-flex justify-content-start'>
            {!isEditing ? <>
                <button type='button' className='btn btn-secondary' onClick={() => {
                    setIsEditing(true)
                    setSaveError(null)
                }}>
                    Edit Client
                </button>
            </> : <>
                <button type='button' className='btn btn-secondary' onClick={() => {
                    setIsEditing(false)
                    setSaveError(null)
                }} disabled={saving}>
                    Cancel
                </button>
                <button type='submit' className='btn btn-primary ms-2' disabled={saving}>
                    {!saving ? 'Save' : 'Saving...'}
                </button>
            </>}
        </div>
        {/* Need a little bit of bottom padding unless there is an error */}
        {saveError ? <div className='alert alert-danger mt-5' role='alert'>
            <div className='alert-text'>{saveError}</div>
        </div> : <div className="mb-3" />}
    </form>
}

function WebsiteWidgetCode() {
    const { client } = useClientDetail()

    return <div>
        <ClientCodeBlock client={client} />
    </div>
}

function ClientDetailRoutes() {
    const { client_unique_id: clientUniqueId } = useParams();
    const [addingTeamMember, setAddingTeamMember] = useState(false)

    const [client, setClient] = useState<FuneralHome | null>(null)

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)

    const loadDetailedClient = useCallback(async () => {
        if (!clientUniqueId) {
            return;
        }
        try {
            const { client: funeralHome } = await getDetailedClient(clientUniqueId)
            setClient(funeralHome)
            setError(null)
        } catch (error: any) {
            console.warn(error)
            const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
            setError(errorMessage)
        } finally {
            setLoading(false)
        }
    }, [clientUniqueId])

    useEffect(() => {
        loadDetailedClient()
    }, [loadDetailedClient])


    if (!clientUniqueId) {
        return <Navigate to='/admin/home' replace />
    }

    if (loading) {
        return (
            <div>
                <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            </div>
        )
    }

    if (error || !client) {
        return (
            <div className='alert alert-danger' role='alert'>
                <div className='alert-text'>{error || 'Something went wrong (invalid client)'}</div>
            </div>
        )
    }

    return (
        <ClientDetailContext.Provider
            value={{
                client,
                addingTeamMember,
                setAddingTeamMember,
            }}
        >
            <Routes>
                <Route
                    element={
                        <ClientLayout />
                    }
                >
                    {/* /admin/home */}
                    <Route path='overview' element={<ClientOverview />} />
                    <Route path='team' element={<ClientTeamList />} />
                    <Route path='website-widget-code' element={<WebsiteWidgetCode />} />
                    <Route path='*' element={<Navigate to={`/admin/clients/${clientUniqueId}/overview`} replace />} />
                </Route>
            </Routes>
        </ClientDetailContext.Provider>
    )
}

export default ClientDetailRoutes