import { useFormik } from 'formik'
import * as Yup from 'yup'

import { ApiError } from '../modules/auth'
import { useAuth } from '../modules/auth/core/Auth'
import FormTitle from '../modules/obituaries/components/forms/FormTitle'
import { confirmOwnRelationshipToObituary } from '../modules/obituaries/core/_requests'
import { useShareableObituary } from './ShareableView'
import FormItemsRenderer, {
  IItemProps,
} from '../modules/obituaries/components/forms/FormItemsRenderer'

interface IDescribeRelationshipToObituary {
  fullName: string
  phoneNumber: string
  emailAddress?: string
}

const describeRelationshipSchema = Yup.object().shape({
  fullName: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required'),
  emailAddress: Yup.string().required('Required'), // Required for pre-need
})

function UserOwnObituaryRelationshipForm() {
  const { currentPhoenixUserPhone } = useAuth()
  const { obituary, setObituary, userRelationshipToObituary, setUserRelationshipToObituary } =
    useShareableObituary()

  const formik = useFormik<IDescribeRelationshipToObituary>({
    initialValues: {
      fullName: userRelationshipToObituary?.name || currentPhoenixUserPhone?.user_profile?.name || '',
      phoneNumber: currentPhoenixUserPhone?.phone_number || '', // Should never be empty
      emailAddress: currentPhoenixUserPhone?.email && !currentPhoenixUserPhone.email_is_unknown ? currentPhoenixUserPhone.email : '',
    },
    validationSchema: describeRelationshipSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true)
      setStatus(undefined)
      try {
        const { obituary: updatedObituary, my_relationship: updatedRelationship } =
          await confirmOwnRelationshipToObituary(obituary.unique_identifier, {
            full_name: values.fullName,
            phone_number: values.phoneNumber,
            email_address: values.emailAddress,
          })
        setObituary(updatedObituary)
        setUserRelationshipToObituary(updatedRelationship)
      } catch (error: any) {
        console.warn(error)
        const errorMessage =
          error instanceof ApiError ? error.message : 'Unable to save your relationship.'
        setStatus(errorMessage)
      }
    },
  })

  const items = [
    {
      label: 'Your full name *',
      name: 'fullName',
      type: 'text',
      value: formik.values.fullName,
      autoFocus: false,
      required: true,
      error: formik.errors.fullName,
      touched: formik.touched.fullName,
      isTextArea: false,
      isCheckbox: false,
      checked: undefined,
      isDatePicker: false,
      placeholder: 'Enter your full name...',
      autoComplete: 'name',
    } as IItemProps,
    {
      label: 'Your phone number *',
      name: 'phoneNumber',
      type: 'text',
      value: formik.values.phoneNumber,
      autoFocus: false,
      required: false,
      error: formik.errors.phoneNumber,
      touched: formik.touched.phoneNumber,
      isTextArea: false,
      isCheckbox: false,
      checked: undefined,
      isDatePicker: false,
      placeholder: undefined,
      readOnly: true, // Until we have the "re-confirm" phone number we disable the input for now.
    } as IItemProps,
    {
      label: 'Your email address *',
      name: 'emailAddress',
      type: 'email',
      value: formik.values.emailAddress,
      autoFocus: false,
      required: true,
      error: formik.errors.emailAddress,
      touched: formik.touched.emailAddress,
      isTextArea: false,
      isCheckbox: false,
      checked: undefined,
      isDatePicker: false,
      placeholder: 'Enter your email address...',
      autoComplete: 'email',
    } as IItemProps,
  ]

  const formError = formik.status

  return (
    <div className='mt-4 mt-md-0'>
      <form onSubmit={formik.handleSubmit}>
        <FormTitle title={`Confirm your contact information:`} hideContinue />
        {formError && (
          <div className='alert alert-danger'>
            <div className='alert-text'>{formError}</div>
          </div>
        )}
        <FormItemsRenderer items={items} formik={formik} obituaryWebsocket={null} obituaryChannel={null} />
        <div className='row mt-10'>
          <div className='col-12 d-flex justify-content-end'>
            <button type='submit' className='btn btn-primary'>
              Continue
            </button>
          </div>
        </div>
        {formError && (
          <div className='alert alert-danger mt-4'>
            <div className='alert-text'>{formError}</div>
          </div>
        )}
      </form>
    </div>
  )
}

export default UserOwnObituaryRelationshipForm
