import { useCallback, useEffect, useMemo, useState } from 'react'

import { AdminFRMLocationsResponse, AdminFRMResponse, AllClientsAndConnectionsItems, FRMLocation } from './core/_admin_slack_response'

import {
  adminGetFRMConfiguration, getListOfLocationsFromTheFRM
} from './core/_requests'

import { ApiError } from '../modules/auth'
import { KTSVG, useDebounce } from '../../_metronic/helpers'
import { Link } from 'react-router-dom'
import FuneralHomeRowOptions from './FuneralHomeRowOptions'
import FRMClientSwitcher from './FRMClientSwitcher'
import TieToFuneralHomeModalWrapper from './tie-to-funeral-home-modal/TieToFuneralHomeModalWrapper'

function FRMLocationRow({
  location: defaultLocation,
  setTieToFrmLocationItemModalActive,
  clientsAndConnections
}: {
  location: FRMLocation,
  setTieToFrmLocationItemModalActive: (location: FRMLocation) => void
  clientsAndConnections: AllClientsAndConnectionsItems
}) {

  const [location, setLocation] = useState<FRMLocation>(defaultLocation)

  const tiedToExistingItem = useMemo(() => {
    if (clientsAndConnections[location._id]) {
      return clientsAndConnections[location._id]
    }
    return null
  }, [clientsAndConnections, location._id])

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <div
              className='text-dark fw-bold fs-6'
            >{location.name}</div>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              {location.address} - {location._id}
            </span>
          </div>
        </div>
      </td>
      <td>
        {tiedToExistingItem ? <>
          {tiedToExistingItem.client.name}
          <button className='btn btn-sm btn-light-primary ms-2' onClick={() => {
            setTieToFrmLocationItemModalActive(location)
          }}>
            Update
          </button>
        </> : <>
          <button className='btn btn-sm btn-light-primary' onClick={() => {
            setTieToFrmLocationItemModalActive(location)
          }}>
            Set up
          </button>
        </>}
      </td>
      <td>
        <div className='d-flex justify-content-end flex-shrink-0'>
          -
        </div>
      </td>
    </tr>
  )
}

function ManageFRMListOfFuneralHomes({ frmConfiguration, frmAuthenticationURL }: {
  frmConfiguration: AdminFRMResponse
  frmAuthenticationURL: string
}) {

  const [haveLoadedOnce, setHaveLoadedOnce] = useState<boolean>(false)

  const [frmLocationResponse, setFrmLocationResponse] = useState<AdminFRMLocationsResponse | null>(null)

  const [searchText, setSearchText] = useState<string>('')
  const [searching, setSearching] = useState<boolean>(true)
  const [searchError, setSearchError] = useState<string | null>(null)

  const [warningMessage, setWarningMessage] = useState<string | null>(null)

  const debouncedSearchText = useDebounce(searchText, 500)

  const [tieToFrmLocationItemModalActive, setTieToFrmLocationItemModalActive] = useState<FRMLocation | null>(null)

  const loadResults = useCallback(async () => {
    setSearching(true)
    try {
      const updatedResponse = await getListOfLocationsFromTheFRM(debouncedSearchText || '')
      setFrmLocationResponse(updatedResponse)
      setSearchError(null)
      if (updatedResponse.warning_message) {
        setWarningMessage(updatedResponse.warning_message)
      }
    } catch (error: any) {
      console.warn('error', error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setSearchError(errorMessage)
    } finally {
      setSearching(false)
      setHaveLoadedOnce(true)
    }
  }, [debouncedSearchText])

  useEffect(() => {
    loadResults()
  }, [loadResults])

  if (haveLoadedOnce && searchError) {
    return (
      <div className='alert alert-danger'>{searchError}</div>
    )
  }

  if (!frmLocationResponse) {
    return (
      <div className='ms-4 spinner-border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    )
  }

  return (
    <div>
      {warningMessage &&
        <div className='card-header border-0 pt-5'>
          <div className='alert alert-warning' role='alert'>
            <div className='alert-text'>{warningMessage}</div>
          </div>
        </div>
      }
      <div className={`card card-custom card-stretch shadow mb-5`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            {/* begin::Search Bar */}
            <div className='d-flex align-items-center position-relative my-1'>
              {<input
                type='text'
                className='form-control form-control-solid w-300px'
                placeholder='Search for a funeral home...'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />}
              {searching ? (
                <>
                  <div className='ms-4 spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </>
              ) : (
                <>
                  {searchError ? (
                    <>
                      <div className='ms-4'>
                        <div className='alert alert-danger' role='alert'>
                          <div className='alert-text'>{searchError}</div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            {/* end::Search Bar */}
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to authenticate with the FRM'
          >
            <FRMClientSwitcher />
            <a href={frmAuthenticationURL} className='btn btn-sm btn-light-primary'>
              <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Re-Authenticate with the FRM
            </a>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  {false && (
                    <th className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          data-kt-check='true'
                          data-kt-check-target='.widget-9-check'
                        />
                      </div>
                    </th>
                  )}
                  {false && <th></th>}
                  <th className='min-w-150px'>FRM Location Name</th>
                  <th className='min-w-150px'>Tied to Obit.ai Client</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {frmLocationResponse.locations.length === 0 ? (
                  <>
                    <tr>
                      <td colSpan={5}>
                        <div className='text-center pt-5'>No locations found</div>
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    {frmLocationResponse.locations.map((location) => {
                      return (
                        <FRMLocationRow
                          key={`location-${location._id}`}
                          location={location}
                          setTieToFrmLocationItemModalActive={setTieToFrmLocationItemModalActive}
                          clientsAndConnections={frmLocationResponse.clients_and_connections || {}}
                        />
                      )
                    })}
                  </>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      {tieToFrmLocationItemModalActive && <TieToFuneralHomeModalWrapper
        frmLocation={tieToFrmLocationItemModalActive}
        clientsAndConnections={frmLocationResponse.clients_and_connections || {}}
        accessToken={frmLocationResponse.access_token}
        setFrmLocation={(frmLocation) => {
          // TODO
        }}
        onClose={() => {
          setTieToFrmLocationItemModalActive(null)
        }} />}
    </div>
  )
}

function ManageFRM() {
  const [frmConfiguration, setFrmConfiguration] = useState<AdminFRMResponse | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingError, setLoadingError] = useState<string | null>(null)

  const getFrmConfiguration = useCallback(async () => {
    try {
      const updatedConfiguration = await adminGetFRMConfiguration()
      setFrmConfiguration(updatedConfiguration)
    } catch (error: any) {
      console.warn('error', error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setLoadingError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [])

  const frmAuthenticationURL = useMemo(() => {
    const highlevelScopeString = `locations.write locations.readonly oauth.write oauth.readonly contacts.readonly contacts.write businesses.readonly businesses.write opportunities.write opportunities.readonly`
    return `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${window.location.origin}/admin/frm/oauth/callback&client_id=654915e3d54fac78c6db30e7-lon4lefb&scope=${highlevelScopeString}`
  }, [])

  useEffect(() => {
    getFrmConfiguration()
  }, [getFrmConfiguration])

  if (loading) {
    return (
      <div className='ms-4 spinner-border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    )
  }

  if (loadingError || !frmConfiguration) {
    return <div className='alert alert-danger'>{loadingError || 'Something went wrong'}</div>
  }

  if (!frmConfiguration.have_authenticated_with_frm) {
    return (
      <div>
        <div className={`card card-custom card-stretch shadow mb-5`}>
          <div className='card-body py-8'>
            <a href={frmAuthenticationURL} className='btn btn-sm btn-light-primary'>
              <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Authenticate with the FRM
            </a>
          </div>
        </div>
      </div>
    )
  }

  return <>
    <ManageFRMListOfFuneralHomes
      frmConfiguration={frmConfiguration}
      frmAuthenticationURL={frmAuthenticationURL}
    />
  </>
}

export default ManageFRM
