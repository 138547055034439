export class ApiError {
  message: string
  response: any

  constructor(message: string, response: any) {
    this.message = message
    this.response = response
  }
}

export const LOCAL_STORAGE_SELECTED_FUNERAL_HOME = 'selected_funeral_home'
