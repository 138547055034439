import { useState, useCallback } from 'react'
import styled from 'styled-components'

import { ApiError } from '../modules/auth'
import { loginWithPhone, loginAndConfirmPhoneCode, getUserPhoneByToken } from '../modules/auth/core/_requests'
import { useShareableObituary } from './ShareableView'
import TitleAndDescriptionItem from './TitleAndDescriptionItem'
import { useAuth } from '../modules/auth/core/Auth'


/*
pathname.startsWith('/o matters below for dark-mode search in the future
*/
/* Dark mode was #1a1a1a, now it's #f6f8fa */

export const NextButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: #f6f8fa;
  z-index: 100;
`

export const NextButtonContent = styled.div`
  border-top: 1px solid #fff;
`

export const SubmitErrorContainer = styled.div`
  position: absolute;
  top: -35px;
  left: 0;
  right: 0;
`

function SubmitButton({
  title,
  titleLoading,
  loading = false,
  error = null,
}: {
  title: string
  titleLoading: string
  loading?: boolean
  error?: string | null
}) {
  return (
    <>
      <NextButtonContainer>
        <NextButtonContent className='container h-100'>
          <div className='row h-100'>
            <div className='col-12 col-md-6 offset-md-3 d-flex justify-content-center align-items-center position-relative'>
              {error && (
                <SubmitErrorContainer>
                  <div className='alert alert-danger' role='alert'>
                    <div className='alert-text'>{error}</div>
                  </div>
                </SubmitErrorContainer>
              )}
              <button className='btn btn-lg btn-secondary w-100' type='submit' disabled={loading}>
                {loading ? titleLoading : title}
              </button>
            </div>
          </div>
        </NextButtonContent>
      </NextButtonContainer>
    </>
  )
}
function ShareableLoginContent() {
  const { saveAuth, setCurrentPhoenixUserPhone } = useAuth()

  const { obituary, setUserRelationshipToObituary } = useShareableObituary()

  const [signingUp, setSigningUp] = useState(false)
  const [signingUpConfirming, setSigningUpConfirming] = useState(false)

  const [error, setError] = useState<string | null>(null)

  const [phone, setPhone] = useState('')

  const [confirmingPhoneNumber, setConfirmingPhoneNumber] = useState<string | null>(null)

  const [code, setCode] = useState('')

  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  const onSendDataToApi = useCallback(
    async (showSuccess = false) => {
      if (signingUp) {
        return
      }
      setError(null)
      setSuccessMessage(null)
      setSigningUp(true)
      try {
        const { formattedPhoneNumber: updatedConfirmingPhoneNumber } = await loginWithPhone(phone, {
          obituaryUniqueId: obituary.unique_identifier,
        })
        setConfirmingPhoneNumber(updatedConfirmingPhoneNumber)
        if (showSuccess) {
          setSuccessMessage('Code was sent successfully')
        }
      } catch (error: any) {
        console.warn('error', error)
        const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
        setError(errorMessage)
      } finally {
        setSigningUp(false)
      }
    },
    [obituary.unique_identifier, phone, signingUp]
  )

  const onInitialSignup = useCallback(
    async (e: any) => {
      e.preventDefault()
      onSendDataToApi()
    },
    [onSendDataToApi]
  )

  const onSignupConfirm = useCallback(
    async (e: any) => {
      e.preventDefault()
      if (signingUpConfirming) {
        return
      }
      if (!confirmingPhoneNumber) {
        setError('Phone number is required')
        return
      }
      setError(null)
      setSigningUpConfirming(true)
      try {
        const { auth, myRelationship } = await loginAndConfirmPhoneCode(confirmingPhoneNumber, code, {
          obituaryUniqueId: obituary.unique_identifier,
        })
        saveAuth(auth)
        const userPhone = await getUserPhoneByToken(auth.api_token)
        setCurrentPhoenixUserPhone(userPhone)
        if (myRelationship) {
          setUserRelationshipToObituary(myRelationship)
        }
      } catch (error: any) {
        console.warn('error', error)
        const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
        setError(errorMessage)
        setTimeout(() => {
          setError(null)
        }, 2000)
      } finally {
        setSigningUpConfirming(false)
      }
    },
    [code, confirmingPhoneNumber, obituary.unique_identifier, saveAuth, setCurrentPhoenixUserPhone, setUserRelationshipToObituary, signingUpConfirming]
  )

  if (confirmingPhoneNumber) {
    return (
      <form onSubmit={onSignupConfirm}>
        <TitleAndDescriptionItem
          title={`Confirm your phone number`}
          description={`Enter the code we just texted to ${confirmingPhoneNumber}`}
        />
        <div>
          <div className='mt-6'>
            <div>
              {successMessage && (
                <div className='alert alert-success' role='alert'>
                  <div className='alert-text'>{successMessage}</div>
                </div>
              )}
            </div>
            <div>
              <label htmlFor='name' className='form-label'>
                Enter code
              </label>
              <input
                type='text'
                className='form-control'
                id='code'
                placeholder='Enter the code we just texted to you...'
                required
                value={code}
                onChange={(e) => setCode(e.target.value)}
                autoComplete='off'
                autoFocus
              />
            </div>
          </div>
          <div className='row h-100 mt-6'>
            <div className='col-12'>
              <button
                className='btn btn-lg btn-secondary w-100'
                type='submit'
                disabled={signingUpConfirming}
              >
                {signingUpConfirming ? 'Confirming...' : 'Confirm'}
              </button>
              <div className='separator separator-dashed my-6' />
              {/* begin:: Resend Code */}
              <div className='text-center'>
                <button
                  type='button'
                  className='btn btn-lg btn-link w-100'
                  onClick={() => {
                    onSendDataToApi(true)
                  }}
                >
                  Resend Code
                </button>
              </div>
              {/* begin::Cancel Button */}
              <div className='text-center'>
                <button
                  type='button'
                  className='btn btn-lg btn-link fs-6'
                  onClick={() => {
                    setConfirmingPhoneNumber(null)
                  }}
                >
                  Cancel
                </button>
              </div>
              {/* end::Cancel Button */}
              {error && (
                <div className='mt-3'>
                  <div className='alert alert-danger' role='alert'>
                    <div className='alert-text'>{error}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    )
  }

  return (
    <form onSubmit={onInitialSignup}>
      <TitleAndDescriptionItem
        title={`Access ${obituary.getSimpleName()}'s Obituary`}
        description={`To honor the life and legacy of ${obituary.getSimpleFirstName()}, please enter your phone number below.`}
      />
      <div>
        <div className='mt-6'>
          <div>
            <label htmlFor='phone' className='form-label'>
              Phone
            </label>
            <input
              type='tel'
              className='form-control'
              id='phone'
              placeholder='Enter your phone number...'
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <div className='form-text text-center'>
              We will text you a code to verify your phone number.
            </div>
          </div>
        </div>
      </div>
      <SubmitButton
        title='Continue'
        titleLoading='Continuing...'
        loading={signingUp}
        error={error}
      />
    </form>
  )
}

function ShareableLoginForPhone() {
  return <div className='mt-12'>
    <ShareableLoginContent />
  </div>
}

export default ShareableLoginForPhone
