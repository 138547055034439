function BootstrapIndicator() {
    if (process.env.NODE_ENV === 'production') return null;

    return (
        <div className="fixed-bottom p-3" style={{ left: '1rem', zIndex: 1050, width: '5rem', height: '5rem' }}>
            <div className="d-block d-sm-none">xs</div>
            <div className="d-none d-sm-block d-md-none">sm</div>
            <div className="d-none d-md-block d-lg-none">md</div>
            <div className="d-none d-lg-block d-xl-none">lg</div>
            <div className="d-none d-xl-block d-xxl-none">xl</div>
            <div className="d-none d-xxl-block">xxl</div>
        </div>
    );
}

export default BootstrapIndicator;