import { useState } from 'react'
import { Field, Formik, Form } from 'formik'

import { ApiError } from '../../../modules/auth'
import { useClientDetail } from '../ClientDetailRoutes'
import { addTeamMemberToClient } from '../../../funeral_homes/dashboard/core/_requests'

function AddTeamMemberModalForm() {
  const initialFormValues = {
    name: '',
    phone: '',
    email: '',
  }

  const {
    client
  } = useClientDetail()

  const [submissionError, setSubmissionError] = useState<string | null>(null)

  const onFormSubmit = async (values: any, actions: any) => {
    setSubmissionError(null)
    actions.setSubmitting(true)
    try {
      await addTeamMemberToClient(client.unique_identifier, {
        ...values,
      })
      // Simply reload for now
      window.location.reload()
    } catch (error: any) {
      console.warn(error)
      actions.setSubmitting(false)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setSubmissionError(errorMessage)
    }
  }

  return (
    <Formik initialValues={initialFormValues} onSubmit={onFormSubmit}>
      {({ values: formValues, isSubmitting, setFieldValue, getFieldProps, touched, errors }) => (
        <Form>
          <div>
            <div className='position-relative'>
              <div className='row mb-5'>
                <div className='col-lg-4'>
                  <label className='form-label'>Name (First and Last Name) *</label>
                  <Field name={`name`}>
                    {({ field }: { field: any }) => (
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter team member name'
                        required={true}
                        {...field}
                        autoComplete='off'
                      />
                    )}
                  </Field>
                </div>
                <div className='col-lg-4 mt-4 mt-lg-0'>
                  <label className='form-label'>Phone *</label>
                  <Field name={`phone`}>
                    {({ field }: { field: any }) => (
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter team member phone number'
                        {...field}
                        required={true}
                        autoComplete='off'
                      />
                    )}
                  </Field>
                </div>
                <div className='col-lg-4 mt-4 mt-lg-0'>
                  <label className='form-label'>Email</label>
                  <Field name={`email`}>
                    {({ field }: { field: any }) => (
                      <input
                        type='email'
                        className='form-control'
                        placeholder='Enter team member email'
                        {...field}
                        autoComplete='off'
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
          </div>
          <div className='separator separator-dashed my-6'></div>
          <div className='d-flex justify-content-start'>
            <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
              {isSubmitting ? 'Adding...' : 'Add Team Member'}
            </button>
          </div>
          {submissionError && (
            <div className='mt-5 alert alert-danger'>
              <div className='alert-text font-weight-bold text-center'>{submissionError}</div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default AddTeamMemberModalForm
