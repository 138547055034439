import { useEffect, useRef } from 'react'

import FuneralImageModal from './FuneralImageModal'
import { FuneralHome } from '../../modules/obituaries/core/_models'

interface FuneralImageModalWrapperProps {
  funeralHome: FuneralHome
  setFuneralHome: (funeralHome: FuneralHome) => void
  file: File
  onClose: () => void
}

const FuneralImageModalWrapper: React.FC<FuneralImageModalWrapperProps> = ({
  funeralHome,
  setFuneralHome,
  file,
  onClose,
}) => {
  useEffect(() => {
    function onClickedBackdrop(e: any) {
      if (e.target?.id === 'modal_upload_funeral_home_image') {
        onClose()
      }
    }
    document.body.classList.add('modal-open')
    document.addEventListener('click', onClickedBackdrop)
    return () => {
      document.body.classList.remove('modal-open')
      document.removeEventListener('click', onClickedBackdrop)
    }
  }, [onClose])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='modal_upload_funeral_home_image'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <FuneralImageModal
          funeralHome={funeralHome}
          setFuneralHome={setFuneralHome}
          file={file}
          onClose={onClose}
        />
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default FuneralImageModalWrapper
