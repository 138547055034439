import axios from 'axios'
import {
  BaseQuestion,
  FamilyRelationship,
  FollowupQuestion,
  FuneralHome,
  Obituary,
  Section,
  KnownFamilyMember,
  VenueAndDateTimeItem,
  PersonalInformation,
  Career,
  Education,
  LifeEvents,
  Relatives,
  MilitaryService,
  PersonalityAndInterests,
  CeremonyDetails,
  ObituarySalesInsight,
  Affiliations,
  ObituaryEmail,
  ObituaryDebug,
  SimplifiedPhoenixUser,
  SimplifiedPhoenixUserPhone,
} from './_models'
import {IAvailableFormType} from '../../../obituaries/useObituaryWebsocket'
import {ObituaryFinalQuestionMessage} from '../../../obituaries/useObituaryQuestionsWebsocket'
import {PersonalizedPoem} from '../poetry/types'
import {GratitudeAlphabet} from '../gratitude_alphabet/types'
import {InspirationalPassage, ObituaryFinalQuestionSummary} from '../inspiration_passage/types'
import {SelectableOptionGroup} from '../InspirationalPassages'
import {SelectableMerchandiseOptionGroup} from '../MerchandiseDetail'

export function createObituaryOld(data: any) {
  return axios
    .post<Obituary>(`${process.env.REACT_APP_API_URL}/new-obituary/`, data)
    .then((res) => res.data)
    .then((response: any) => {
      return new Obituary(response.data)
    })
}

export function updateObituary(uniqueId: string, data: any) {
  return axios
    .post<Obituary>(`${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/update/`, data)
    .then((res) => res.data)
    .then((response: any) => {
      return new Obituary(response.data)
    })
}

export function getObituaries() {
  return axios
    .get<Obituary[]>(`${process.env.REACT_APP_API_URL}/obituaries/`)
    .then((res) => res.data)
    .then((response: any) => {
      return response.data.results.map((obituary: any) => new Obituary(obituary))
    })
}

export function getObituaryByShareableCode(shareableCode: string, token?: string) {
  return axios
    .get<Obituary>(
      `${process.env.REACT_APP_NEXT_API_URL}/obituaries/by-shareable-code/${shareableCode}/`,
      {
        params: token
          ? {
              t: token,
            }
          : {},
      }
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
        myRelationship: response.data.my_relationship
          ? new KnownFamilyMember(response.data.my_relationship)
          : null,
        user: response.data.user ? new SimplifiedPhoenixUser(response.data.user) : null,
        user_phone: response.data.user_phone
          ? new SimplifiedPhoenixUserPhone(response.data.user_phone)
          : null,
        api_token: response.data.api_token ? response.data.api_token : null,
      }
    })
}

export function getObituariesAssociatedWithUser() {
  return axios
    .get<Obituary[]>(`${process.env.REACT_APP_API_URL}/my-obituaries/`)
    .then((res) => res.data)
    .then((response: any) => {
      return response.data.obituaries.map((obituary: any) => new Obituary(obituary))
    })
}

export interface ISaveObituaryResponse {
  obituary: Obituary
  updated_question: BaseQuestion
  new_question?: FollowupQuestion
}

export function removeObituaryResponse(
  uniqueId: string,
  questionUniqueId: string
): Promise<BaseQuestion> {
  // Returning a BaseQuestion for updated_question feels ... wrong.
  // Right now it works.
  return axios
    .post<BaseQuestion>(
      `${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/responses/${questionUniqueId}/remove/`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new BaseQuestion(response.data)
    })
}

export function saveObituaryResponse(
  uniqueId: string,
  questionUniqueId: string,
  data: any
): Promise<ISaveObituaryResponse> {
  // Returning a BaseQuestion for updated_question feels ... wrong.
  // Right now it works.
  return axios
    .post<ISaveObituaryResponse>(
      `${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/responses/${questionUniqueId}/`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
        updated_question: new BaseQuestion(response.data.updated_question),
        new_question: response.data.new_question
          ? new FollowupQuestion(response.data.new_question)
          : undefined,
      }
    })
}

export function saveObituaryTranscriptResponse(
  uniqueId: string,
  questionUniqueId: string,
  data: any
): Promise<ISaveObituaryResponse> {
  // Returning a BaseQuestion for updated_question feels ... wrong.
  // Right now it works.
  return axios
    .post<ISaveObituaryResponse>(
      `${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/transcript-responses/${questionUniqueId}/`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
        updated_question: new BaseQuestion(response.data.updated_question),
      }
    })
}

export function uploadRecordingForObituaryResponse(
  uniqueId: string,
  questionId: string,
  audioResponse: Blob
): Promise<ISaveObituaryResponse> {
  const formData = new FormData()
  formData.append('audio_response', audioResponse)
  return axios
    .post<ISaveObituaryResponse>(
      `${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/responses/${questionId}/upload-recording/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
        updated_question: new BaseQuestion(response.data.updated_question),
        new_question: response.data.new_question
          ? new FollowupQuestion(response.data.new_question)
          : undefined,
      }
    })
}

export function _deprecated_generateNewObituaryQuestion(uniqueId: string) {
  return axios
    .post<Obituary>(`${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/generate-question/`)
    .then((res) => res.data)
    .then((response: any) => {
      return new Obituary(response.data)
    })
}

export function submitNewObituaryQuestion(uniqueId: string, data: any) {
  return axios
    .post<Obituary>(
      `${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/submit-question/`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new Obituary(response.data)
    })
}

export function resetObituaryQuestions(uniqueId: string) {
  return axios
    .post<Obituary>(`${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/reset-questions/`)
    .then((res) => res.data)
    .then((response: any) => {
      return new Obituary(response.data)
    })
}

export function archiveObituary(uniqueId: string) {
  return axios
    .post<Obituary>(`${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/archive/`)
    .then((res) => res.data)
    .then((response: any) => {
      return true
    })
}

export function getObituaryDownloadLink(uniqueId: string) {
  // Returns a link to download
  return axios
    .get(`${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/download-link/`)
    .then((res) => res.data)
    .then((response: any) => {
      return response.data.link
    })
}

export function skipObituaryQuestion(uniqueId: string, questionId: string) {
  return axios
    .post<Obituary>(
      `${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/skip-question/${questionId}/`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new Obituary(response.data)
    })
}

export function uploadRecordingForObituaryFamilyTree(
  uniqueId: string,
  audioResponse: Blob
): Promise<Obituary> {
  const formData = new FormData()
  formData.append('audio_response', audioResponse)
  return axios
    .post(`${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/upload-family-tree/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data)
    .then((response: any) => {
      return new Obituary(response.data)
    })
}

export function retryFamilyAudioTranscription(
  uniqueId: string,
  familyAudioResponseUniqueId: string
) {
  return axios
    .post<Obituary>(
      `${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/retry-family-audio-response/${familyAudioResponseUniqueId}/`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new Obituary(response.data)
    })
}

export function updateObituaryRelationship(
  uniqueId: string,
  relationshipUniqueId: string,
  data: any
) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/relationships/${relationshipUniqueId}/`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        relationship: new FamilyRelationship(response.data.relationship),
        obituary: new Obituary(response.data.obituary),
      }
    })
}

export function createObituaryRelationship(uniqueId: string, data: any) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/relationships/`, data)
    .then((res) => res.data)
    .then((response: any) => {
      return {
        relationship: new FamilyRelationship(response.data.relationship),
        obituary: new Obituary(response.data.obituary),
      }
    })
}

export function removeObituaryRelationship(uniqueId: string, relationshipUniqueId: string) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/relationships/${relationshipUniqueId}/remove/`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
      }
    })
}

export function updateObituarySectionOrder(uniqueId: string, sectionUniqueIds: string[]) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/sections/update_order/`, {
      section_unique_ids: sectionUniqueIds,
    })
    .then((res) => res.data)
    .then((response: any) => {
      return new Obituary(response.data)
    })
}

export function updateObituarySection(uniqueId: string, sectionUniqueId: string, data: any) {
  return axios
    .post(
      `${process.env.REACT_APP_NEXT_API_URL}/protected/obituaries/${uniqueId}/sections/${sectionUniqueId}/update`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
      }
    })
}

export function writeObituarySection(uniqueId: string, sectionUniqueId: string) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/sections/${sectionUniqueId}/write/`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        section: new Section(response.data.updated_section),
        obituary: new Obituary(response.data.obituary),
      }
    })
}

export function writeObituarySectionForBiography(
  uniqueId: string,
  sectionUniqueId: string,
  data: any
) {
  return axios
    .post(
      `${process.env.REACT_APP_NEXT_API_URL}/protected/obituaries/${uniqueId}/write-biography`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      // May want to do something with the response one day?
      return true
    })
}

export function writeObituaryFamilyMembers(uniqueId: string, sectionUniqueId: string) {
  return axios
    .post(
      `${process.env.REACT_APP_NEXT_API_URL}/protected/obituaries/${uniqueId}/write-family-members`
    )
    .then((res) => res.data)
    .then((response: any) => {
      // May want to do something with the response one day?
      return true
    })
}

export function writeServicesAndCondolences(uniqueId: string, sectionUniqueId: string) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/sections/${sectionUniqueId}/write_services_and_condolences/`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        section: new Section(response.data.updated_section),
        obituary: new Obituary(response.data.obituary),
      }
    })
}
export function summarizeObituarySection(
  authToken: string,
  uniqueId: string,
  sectionRevisionUniqueId: string,
  inWords: number,
  onTokenReceived: (token: string) => void
) {
  const url = `${process.env.REACT_APP_NEXT_API_URL}/protected/obituaries/${uniqueId}/summarize-biography`

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({
      section_revision_unique_id: sectionRevisionUniqueId,
      in_words: inWords,
    }),
  })
    .then((response) => response.body)
    .then((rb: any) => {
      const reader = rb.getReader()
      let decoder = new TextDecoder('utf-8')
      let data = ''

      return reader.read().then(function processText({done, value}: any) {
        if (done) return

        data += decoder.decode(value, {stream: true})

        let lines = data.split('\n')
        lines.slice(0, -1).forEach((line) => onTokenReceived(line))
        data = lines[lines.length - 1]

        return reader.read().then(processText)
      })
    })
    .catch((error) => {
      console.error('An error occurred:', error)
    })
}

export function saveObituaryImages(uniqueId: string, imageUniqueIds: string[]) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/save-images/`, {
      image_unique_ids: imageUniqueIds,
    })
    .then((res) => res.data)
    .then((response: any) => {
      return new Obituary(response.data)
    })
}

export function removeObituaryImage(uniqueId: string, imageUniqueId: string) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/images/${imageUniqueId}/remove/`)
    .then((res) => res.data)
    .then((response: any) => {
      return new Obituary(response.data)
    })
}

export function getObituaryEmailContent(uniqueId: string) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/obituaries/${uniqueId}/newspaper-email-content/`)
    .then((res) => res.data)
    .then((response: any) => {
      return {
        subject: `${response.data.subject}`,
        body: `${response.data.body}`,
        signature: `${response.data.signature}`,
        funeralHome: response.data.funeral_home
          ? new FuneralHome(response.data.funeral_home)
          : null,
      }
    })
}

export function saveObituaryComponent(
  obituaryUniqueId: string,
  componentName: string,
  values: any
) {
  return axios
    .post(
      `${process.env.REACT_APP_NEXT_API_URL}/protected/obituaries/${obituaryUniqueId}/save-component`,
      {
        componentName,
        values,
      }
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new Obituary(response.data.obituary)
    })
}

export function generateObituaryTribute(obituaryUniqueId: string) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/generate-tribute/`, {})
    .then((res) => res.data)
    .then((response: any) => {
      return new Obituary(response.data.obituary)
    })
}

export function savePersonalRelationshipToObituary(obituaryUniqueId: string, data: any) {
  return axios
    .post(
      `${process.env.REACT_APP_NEXT_API_URL}/protected/obituaries/${obituaryUniqueId}/save-personal-relationship`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
        my_relationship: new KnownFamilyMember(response.data.my_relationship),
      }
    })
}

export function confirmRepresentativeToObituary(obituaryUniqueId: string, data: any) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/confirm-representative/`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
        my_relationship: new KnownFamilyMember(response.data.my_relationship),
      }
    })
}

export function confirmOwnRelationshipToObituary(obituaryUniqueId: string, data: any) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/confirm-own-relationship/`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
        my_relationship: new KnownFamilyMember(response.data.my_relationship),
      }
    })
}

export function generateNewObituaryQuestion(obituaryUniqueId: string, data: any) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/generate-new-question/`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
      }
    })
}

export function createNewSpecificServiceItem(obituaryUniqueId: string, data: any) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/create-new-specific-service-item/`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
        venue_and_date_time_item: new VenueAndDateTimeItem(response.data.venue_and_date_time_item),
      }
    })
}

export function removeServiceItemById(obituaryUniqueId: string, serviceItemId: string) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/remove-service-item/${serviceItemId}/`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
      }
    })
}

export function getObituaryContributors(obituaryUniqueId: string) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/contributors/`)
    .then((res) => res.data)
    .then((response: any) => {
      return {
        contributors: response.data.contributors.map((contributor: any) => {
          return new KnownFamilyMember(contributor)
        }),
      }
    })
}

// reinviteContributor :

export function reinviteContributor(obituaryUniqueId: string, contributorUniqueId: string) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/reinvite-contributor/${contributorUniqueId}/`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        success: response.data.success,
      }
    })
}

export function getObituaryReceivableEmailSuggestion(obituaryUniqueId: string) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/receivable-email-suggestion/`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        suggested_email_address: response.data.suggested_email_address,
      }
    })
}

export function saveReceiveableEmail(obituaryUniqueId: string, data: any) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/save-receivable-email/`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
      }
    })
}

export function getObituaryFormPartial(obituaryUniqueId: string, formType: IAvailableFormType) {
  return axios
    .get(
      `${process.env.REACT_APP_NEXT_API_URL}/protected/obituaries/${obituaryUniqueId}/form-partial?form_type=${formType}`
    )
    .then((res) => res.data)
    .then((response: any) => {
      if (formType === 'personal_information') {
        return new PersonalInformation(response.data)
      }
      if (formType === 'relatives') {
        return new Relatives(response.data)
      }
      if (formType === 'services') {
        return new CeremonyDetails(response.data)
      }
      if (formType === 'education') {
        return new Education(response.data)
      }
      if (formType === 'career') {
        return new Career(response.data)
      }
      if (formType === 'military') {
        return new MilitaryService(response.data)
      }
      if (formType === 'affiliations') {
        return new Affiliations(response.data)
      }
      if (formType === 'life-events') {
        return new LifeEvents(response.data)
      }
      if (formType === 'personality') {
        return new PersonalityAndInterests(response.data)
      }
      if (formType === 'final-questions') {
        return response.data.results
          ? response.data.results.map((question: any) => {
              return new ObituaryFinalQuestionMessage(question)
            })
          : []
      }
      console.warn(`formType ${formType} not found in getObituaryFormPartial`)
      return null
    })
}

export function getObituaryInsightsBySection(obituaryUniqueId: string) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/insights/by_section/`)
    .then((res) => res.data)
    .then((response: any) => {
      return {
        customization: response.data.customization,
      }
    })
}

export function getObituaryInsightsByTopInterests(obituaryUniqueId: string) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/insights/by_top_interests/`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return response.data as {
        can_generate_insights: boolean
        insights: ObituarySalesInsight[]
      }
    })
}

export function generateInsightsForFormType(
  obituaryUniqueId: string,
  formType: IAvailableFormType
) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/generate-insights-for-form-type/`,
      {
        form_type: formType,
      }
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        can_generate_insights: response.data.can_generate_insights,
        insights: response.data.insights.map((insight: any) => {
          return new ObituarySalesInsight(insight)
        }),
      }
    })
}

// Get all emails sent to this obituary

export function getObituaryEmails(obituaryUniqueId: string) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/emails/`)
    .then((res) => res.data)
    .then((response: any) => {
      return response.data.results.map((x: any) => new ObituaryEmail(x))
    })
}

export function getObituaryFinalQuestionsSummary(obituaryUniqueId: string) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/final-questions-summary/`)
    .then((res) => res.data)
    .then((response: any) => {
      return {
        final_question_summary: response.data.final_question_summary
          ? new ObituaryFinalQuestionSummary(response.data.final_question_summary)
          : null,
      }
    })
}

export function generateObituaryFinalQuestionsSummary(obituaryUniqueId: string) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/generate-final-questions-summary/`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        final_question_summary: new ObituaryFinalQuestionSummary(
          response.data.final_question_summary
        ),
      }
    })
}

export function updateIncludeSummaryInObituary(obituaryUniqueId: string, data: any) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/update-include-summary-in-obituary/`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        final_question_summary: new ObituaryFinalQuestionSummary(
          response.data.final_question_summary
        ),
      }
    })
}

export function getObituaryDebugInfo(
  obituaryUniqueId: string,
  apiNamespace: 'biography' | 'condolences',
  biographyTone: string
) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/debug/?namespace=${apiNamespace}&tone=${biographyTone}`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new ObituaryDebug(response.data.debug_info)
    })
}

export function makeObituaryDebugRequest(obituaryUniqueId: string, data: any) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/debug/request/`, data)
    .then((res) => res.data)
    .then((response: any) => {
      return response.data.result as string
    })
}

export function getAvailableMerchandise(obituaryUniqueId: string) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/available-merchandise/`)
    .then((res) => res.data)
    .then((response: any) => {
      return {
        merchandise_options: response.data
          .merchandise_options as SelectableMerchandiseOptionGroup[],
      }
    })
}

export function addSimpleFamilyMemberToObituary(obituaryUniqueId: string, data: any) {
  return axios
    .post<Obituary>(
      `${process.env.REACT_APP_API_URL}/obituaries/${obituaryUniqueId}/add-simple-family-member/`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
      }
    })
}
