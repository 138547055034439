import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import { useLocation } from 'react-router-dom'
import { ThemeModeProvider } from '../partials'
import { MenuComponent } from '../assets/ts/components'
import BootstrapIndicator from './BootstrapIndicator'

interface MainUserLayoutProps {
  children?: React.ReactNode
}

const MainUserLayout = ({ children = undefined }: MainUserLayoutProps) => {
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          <div
            className='wrapper d-flex flex-column flex-row-fluid'
            id='kt_wrapper'
            style={{ paddingLeft: 0, paddingTop: 65 }}
          >
            <HeaderWrapper left={0} forUser />
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>
                  {children && children}
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </ThemeModeProvider>
      <BootstrapIndicator />
    </PageDataProvider>
  )
}

export { MainUserLayout }
