import { useCallback, useState } from 'react'
import { FormikContextType, useFormik } from 'formik'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import { DateTime } from 'luxon'
import { TimePicker } from 'react-samay'
import styled from 'styled-components'

import { getDateAsAstring } from '../ObituaryBiographicalFormCreate'
import { ApiError } from '../../../auth'
import {
  createNewSpecificServiceItem,
  removeServiceItemById,
  saveObituaryComponent,
} from '../../core/_requests'
import { ceremonyDetailsSchema } from '../../form_schema'
import {
  CeremonyDetails,
  KnownFamilyMember,
  Obituary,
  VenueAndDateTimeItem,
} from '../../core/_models'
import FormTitle from './FormTitle'
import {
  IAvailableFormType,
  ObituaryNewServiceItem,
  ObituaryRemoveServiceItem,
  ObituaryServiceItemBlurred,
  ObituaryServiceItemChanged,
  ObituaryServiceItemFocused,
  ObituaryWebsocket,
} from '../../../../obituaries/useObituaryWebsocket'
import FormItemsRenderer, { IItemProps } from './FormItemsRenderer'
import useComplicatedFormBehavior from '../../../../obituaries/useComplicatedFormBehavior'
import useObituaryPartialUpdater from '../../../../obituaries/useObituaryPartialUpdater'
import { useAuth } from '../../../auth/core/Auth'
import useObituaryFormUpdater from '../../../../obituaries/next/useObituaryFormUpdater'
import useObituaryChannel from '../../../../obituaries/next/useObituaryChannel'

const ClearTimeContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 1px;
  bottom: 1px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0.25rem 0.25rem 0;
  cursor: pointer;
  color: var(--kt-light-inverse);
  background: var(--kt-light);
  border-left: 1px solid #ebedf2;
  border-right: 1px solid #ebedf2;
`

export const getTimeAsString = (inputDate: Date) => {
  // Returns something like 16:00:00

  const date = DateTime.fromJSDate(inputDate)

  const result = date.toLocaleString(DateTime.TIME_24_WITH_SECONDS)

  return result
}

const _ceremonyDetailsToFormikValues = (ceremonyDetails: CeremonyDetails) => {
  return {
    venueDateAndTimeAndSpecialGuidelines:
      ceremonyDetails.venueDateAndTimeAndSpecialGuidelines || '',
    expressCondolences: ceremonyDetails.expressCondolences || '',
    venueAndDateTimeItems: ceremonyDetails.venueAndDateTimeItems
      ? ceremonyDetails.venueAndDateTimeItems
      : [],
  }
}

function SpecificServiceDetails({
  obituary,
  setObituary,
  formik,
  formType,
  obituaryWebsocket,
}: {
  obituary: Obituary
  setObituary: (obituary: Obituary) => void
  formik: FormikContextType<CeremonyDetails>
  formType: IAvailableFormType
  obituaryWebsocket: ObituaryWebsocket | null
}) {
  const [addingANewVenue, setAddingANewVenue] = useState(false)
  const [saveError, setSaveError] = useState<string | undefined>(undefined)

  const [removingItem, setRemovingItem] = useState(false)
  const [removingItemError, setRemovingItemError] = useState<string | undefined>(undefined)

  const onAddSpecificServiceVenuesAndTimeClick = useCallback(async () => {
    setAddingANewVenue(true)
    setSaveError(undefined)
    try {
      const { obituary: updatedObituary, venue_and_date_time_item: newVenueAndDateTimeItem } =
        await createNewSpecificServiceItem(obituary.unique_identifier, {})
      setObituary(updatedObituary)
      // Add an empty item to the array
      formik.setFieldValue('venueAndDateTimeItems', [
        ...(formik.values.venueAndDateTimeItems || []),
        newVenueAndDateTimeItem,
      ])
      setSaveError(undefined)
    } catch (error: any) {
      console.warn(error)
      const errorMessage =
        error instanceof ApiError ? error.message : 'Unable to add new venue and date time item'
      setSaveError(errorMessage)
    } finally {
      setAddingANewVenue(false)
    }
  }, [formik, obituary.unique_identifier, setObituary])

  const onRemoveSpecificServiceVenueAndTimeClick = useCallback(
    async (venueAndDateTimeItem: VenueAndDateTimeItem) => {
      setRemovingItem(true)
      setRemovingItemError(undefined)
      try {
        const { obituary: updatedObituary } = await removeServiceItemById(
          obituary.unique_identifier,
          venueAndDateTimeItem.itemUniqueId
        )
        formik.setFieldValue('venueAndDateTimeItems', [
          ...(formik.values.venueAndDateTimeItems || []).filter(
            (item) => item.itemUniqueId !== venueAndDateTimeItem.itemUniqueId
          ),
        ])
        setObituary(updatedObituary)
        setRemovingItemError(undefined)
      } catch (error: any) {
        console.warn(error)
        const errorMessage =
          error instanceof ApiError ? error.message : 'Unable to remove venue and date time item'
        setRemovingItemError(errorMessage)
      } finally {
        setRemovingItem(false)
      }
    },
    [formik, obituary.unique_identifier, setObituary]
  )

  // We have this here because we need to update the obituary when we add or remove a venue and date time item
  // I'm not sure if we should use useComplicatedFormBehavior here or not but it works for now.

  useComplicatedFormBehavior(formType, obituaryWebsocket, formik, (parsedMessage) => {
    if (parsedMessage instanceof ObituaryNewServiceItem) {
      formik.setFieldValue('venueAndDateTimeItems', [
        ...(formik.values.venueAndDateTimeItems || []),
        parsedMessage.venueAndDateTimeItem,
      ])
    } else if (parsedMessage instanceof ObituaryRemoveServiceItem) {
      formik.setFieldValue('venueAndDateTimeItems', [
        ...(formik.values.venueAndDateTimeItems || []).filter(
          (item) => item.itemUniqueId !== parsedMessage.venueAndDateTimeItemUniqueId
        ),
      ])
    } else if (parsedMessage instanceof ObituaryServiceItemFocused) {
      formik.setFieldValue('venueAndDateTimeItems', [
        ...(formik.values.venueAndDateTimeItems || []).map((item) => {
          if (item.itemUniqueId === parsedMessage.serviceItemUniqueId) {
            return {
              ...item,
              focusedName: parsedMessage.name,
            }
          } else {
            return {
              ...item,
              focusedName: undefined,
            }
          }
        }),
      ])
    } else if (parsedMessage instanceof ObituaryServiceItemBlurred) {
      formik.setFieldValue('venueAndDateTimeItems', [
        ...(formik.values.venueAndDateTimeItems || []).map((item) => {
          if (item.itemUniqueId === parsedMessage.serviceItemUniqueId) {
            return {
              ...item,
              focusedName: undefined,
            }
          } else {
            return {
              ...item,
              focusedName: undefined,
            }
          }
        }),
      ])
    } else if (parsedMessage instanceof ObituaryServiceItemChanged) {
      formik.setFieldValue('venueAndDateTimeItems', [
        ...(formik.values.venueAndDateTimeItems || []).map((item) => {
          // parsedMessage.name is either venue, date, or time.
          if (item.itemUniqueId === parsedMessage.uniqueId) {
            return {
              ...item,
              [parsedMessage.name]: parsedMessage.value,
            }
          } else {
            return {
              ...item,
              focusedName: undefined,
            }
          }
        }),
      ])
    }
  })

  return (
    <div className='mb-8'>
      {(saveError || removingItemError) && (
        <div>
          <div className='alert alert-danger'>
            <div className='alert-text'>{saveError || removingItemError}</div>
          </div>
        </div>
      )}
      <div>
        {(formik.values.venueAndDateTimeItems || []).map((item, index) => {
          return (
            <div className='row mb-3 position-relative' key={`item-${item.itemUniqueId}`}>
              <div className='col-12'>
                {index > 0 && (
                  <div>
                    <div className='separator separator-dashed my-7'></div>
                  </div>
                )}
                <div className='row'>
                  <div className='col-12'>
                    <label className='form-label'>
                      Enter the venue and details (e.g memorial service, burial, funeral, etc.)
                    </label>
                    <textarea
                      className='form-control'
                      placeholder='Enter the venue / details...'
                      required={false}
                      name={`venueAndDateTimeItems[${index}].venue`}
                      value={item.venue || ''}
                      autoComplete='off'
                      rows={3}
                      disabled={removingItem}
                      onChange={(e) => {
                        formik.setFieldValue(
                          `venueAndDateTimeItems[${index}].venue`,
                          e.target.value
                        )
                        // TODO: change a local state variable.
                        if (obituaryWebsocket && item.itemUniqueId) {
                          obituaryWebsocket?.formActions.onServiceItemChanged({
                            uniqueId: item.itemUniqueId,
                            name: 'venue', // We will use unique_id to find the group
                            value: e.target.value,
                          })
                        }
                      }}
                      onFocus={() => {
                        if (obituaryWebsocket && item.itemUniqueId) {
                          obituaryWebsocket?.formActions.onServiceItemFocused({
                            uniqueId: item.itemUniqueId,
                            name: 'venue', // We will use unique_id to find the group
                          })
                        }
                      }}
                      onBlur={() => {
                        if (obituaryWebsocket && item.itemUniqueId) {
                          obituaryWebsocket?.formActions.onServiceItemBlurred({
                            uniqueId: item.itemUniqueId,
                            name: 'venue', // We will use unique_id to find the group
                          })
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 mt-3'>
                  <label className='form-label'>Select the date and time:</label>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-12 col-md-5'>
                  <DatePicker
                    className='form-control'
                    selected={item.date ? DateTime.fromISO(item.date).toJSDate() : null}
                    onChange={(date) => {
                      const updatedFieldValue = date ? getDateAsAstring(date) : ''
                      formik.setFieldValue(
                        `venueAndDateTimeItems[${index}].date`,
                        updatedFieldValue
                      )
                      // TODO: change a local state variable.
                      if (obituaryWebsocket && item.itemUniqueId) {
                        obituaryWebsocket?.formActions.onServiceItemChanged({
                          uniqueId: item.itemUniqueId,
                          name: 'date', // We will use unique_id to find the group
                          value: updatedFieldValue,
                        })
                      }
                    }}
                    onFocus={() => {
                      if (obituaryWebsocket && item.itemUniqueId) {
                        obituaryWebsocket?.formActions.onServiceItemFocused({
                          uniqueId: item.itemUniqueId,
                          name: 'date', // We will use unique_id to find the group
                        })
                      }
                    }}
                    onBlur={() => {
                      if (obituaryWebsocket && item.itemUniqueId) {
                        obituaryWebsocket?.formActions.onServiceItemBlurred({
                          uniqueId: item.itemUniqueId,
                          name: 'date', // We will use unique_id to find the group
                        })
                      }
                    }}
                    dateFormat={[
                      'MMMM do, yyyy', // May 1, 2021
                      'MMMM do yyyy', // May 1 2021
                      'MMMM d, yyyy', // May 1, 2021
                      'P', // 2021-05-01
                      'PP', // 05/01/2021
                      'PPP', // May 1, 2021
                      'MMM dd yyyy', // May 01 2021
                      'MMMM dd yyyy', // May 01 2021
                      'MMMM d yyyy', // May 1 2021
                      // Also allow 01-01-2021 and 01/01/2021 and 01.01.2021 and 01 01 2021 and 01-01-2021
                      // and 01/01/21 and 01/01/21 and 01.01.21 and 01 01 21 and 01-01-21
                      'MM-dd-yyyy',
                      'MM/dd/yyyy',
                      'MM.dd.yyyy',
                      'MM dd yyyy',
                      'MM-dd-yy',
                      'MM/dd/yy',
                      'MM.dd.yy',
                      'MM dd yy',
                    ]}
                    showYearDropdown
                    showMonthDropdown
                    placeholderText='Select date...'
                    required={false}
                    name={`venueAndDateTimeItems[${index}].date`}
                    autoComplete='off'
                    dropdownMode='select'
                    disabled={removingItem}
                  />
                </div>
                <div className='col-9 col-md-5 mt-3 mt-md-0 position-relative'>
                  <TimePicker
                    value={
                      item.time ? DateTime.fromFormat(item.time, 'HH:mm:ss').toJSDate() : undefined
                    }
                    onChange={(time) => {
                      const updatedValue = time ? getTimeAsString(time) : ''
                      formik.setFieldValue(`venueAndDateTimeItems[${index}].time`, updatedValue)
                      // TODO: change a local state variable.
                      if (obituaryWebsocket && item.itemUniqueId) {
                        obituaryWebsocket?.formActions.onServiceItemChanged({
                          uniqueId: item.itemUniqueId,
                          name: 'time', // We will use unique_id to find the group
                          value: updatedValue,
                        })
                      }
                    }}
                    use12Hours
                    showSecond={false}
                    format='h:mm a'
                    minuteStep={15}
                    placeholder='Select time...'
                    inputClassName='form-control'
                    className='w-100'
                    disabled={removingItem}
                    onFocus={() => {
                      if (obituaryWebsocket && item.itemUniqueId) {
                        obituaryWebsocket?.formActions.onServiceItemFocused({
                          uniqueId: item.itemUniqueId,
                          name: 'time', // We will use unique_id to find the group
                        })
                      }
                    }}
                    onBlur={() => {
                      if (obituaryWebsocket && item.itemUniqueId) {
                        obituaryWebsocket?.formActions.onServiceItemBlurred({
                          uniqueId: item.itemUniqueId,
                          name: 'time', // We will use unique_id to find the group
                        })
                      }
                    }}
                  />
                  {item.time && (
                    <ClearTimeContainer
                      onClick={() => {
                        formik.setFieldValue(`venueAndDateTimeItems[${index}].time`, '')
                      }}
                    >
                      <i className='bi bi-x-circle'></i>
                    </ClearTimeContainer>
                  )}
                </div>
                <div className='col-3 col-md-2 mt-3 mt-md-0'>
                  <button
                    type='button'
                    className='btn btn-light btn-sm w-100 h-100'
                    onClick={() => {
                      onRemoveSpecificServiceVenueAndTimeClick(item)
                    }}
                    disabled={removingItem}
                  >
                    <i className='bi bi-trash'></i>
                  </button>
                </div>
              </div>

              {true && <div></div>}
            </div>
          )
        })}
      </div>
      <div>
        <button
          className='btn btn-sm btn-secondary'
          onClick={() => onAddSpecificServiceVenuesAndTimeClick()}
          type='button'
          disabled={addingANewVenue}
        >
          {addingANewVenue ? (
            <>{'Adding venue and time...'}</>
          ) : (
            <>
              {formik.values.venueAndDateTimeItems && formik.values.venueAndDateTimeItems.length > 0
                ? 'Add another venue and time'
                : 'Add specific service venues and times'}
            </>
          )}
        </button>
      </div>
    </div>
  )
}

function CeremonyDetailsForm({
  obituary,
  setObituary,
  onSuccessfulSave,
  goBackUrl,
  obituaryWebsocket,
  userRelationshipToObituary,
}: {
  obituary: Obituary
  setObituary: (obituary: Obituary) => void
  onSuccessfulSave: (updatedObituary: Obituary) => void
  goBackUrl: string
  obituaryWebsocket: ObituaryWebsocket | null
  userRelationshipToObituary?: KnownFamilyMember | null
}) {

  const {
    obituaryChannel
  } = useObituaryChannel()

  const { currentPhoenixUserPhone } = useAuth()

  const formik = useFormik<CeremonyDetails>({
    initialValues: _ceremonyDetailsToFormikValues(
      obituary.data_ceremony_details || ({} as CeremonyDetails)
    ),
    validationSchema: ceremonyDetailsSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true)
      setStatus(undefined)
      try {
        const updatedObituary = await saveObituaryComponent(
          obituary.unique_identifier,
          'services',
          values
        )
        setObituary(updatedObituary)
        onSuccessfulSave(updatedObituary)
      } catch (error: any) {
        console.warn(error)
        const errorMessage =
          error instanceof ApiError ? error.message : 'Unable to save ceremony details'
        setStatus(errorMessage)
      }
    },
  })

  const formError = formik.status
  const formType: IAvailableFormType = 'services'

  const items: IItemProps[] = [
    userRelationshipToObituary?.is_self ||
      (obituary.isPreNeedObituary() && !currentPhoenixUserPhone?.isFuneralHomeUser())
      ? ({
        name: 'isPreNeed',
        isSkipped: true,
      } as IItemProps)
      : ({
        label: 'Share any more relevant details about the ceremony venue or special guidelines.',
        name: 'venueDateAndTimeAndSpecialGuidelines',
        type: 'text',
        value: formik.values.venueDateAndTimeAndSpecialGuidelines,
        autoFocus: false,
        required: false,
        error: formik.errors.venueDateAndTimeAndSpecialGuidelines,
        touched: formik.touched.venueDateAndTimeAndSpecialGuidelines,
        isTextArea: true,
        placeholder:
          'e.g funeral at Union Church, Columbia, MD / family and friends welcome / MM/DD/YYYY, 00:00...',
        additionalComponent: () => {
          return (
            <SpecificServiceDetails
              obituary={obituary}
              setObituary={setObituary}
              formik={formik}
              formType={formType}
              obituaryWebsocket={obituaryWebsocket}
            />
          )
        },
        additionalComponentIsAbove: true,
      } as IItemProps),
    {
      label: 'How can people express their condolences?',
      name: 'expressCondolences',
      type: 'text',
      value: formik.values.expressCondolences,
      autoFocus: false,
      required: false,
      error: formik.errors.expressCondolences,
      touched: formik.touched.expressCondolences,
      isTextArea: true,
      placeholder: 'e.g. charitable donations / flowers / memorial contributions...',
    } as IItemProps,
  ]

  const { editingFieldsByName } = useComplicatedFormBehavior(formType, obituaryWebsocket, formik)

  useObituaryPartialUpdater(obituary.unique_identifier, 'services', (updatedData: any) => {
    formik.setValues(_ceremonyDetailsToFormikValues(updatedData))
    obituary.data_ceremony_details = updatedData // Should we call setObituary here? Hm
  })

  useObituaryFormUpdater({
    obituaryChannel,
    onChange: (event) => {
      if (event.formType === 'career') {
        formik.setFieldValue(event.formName, event.formValue)
      }
    }
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormTitle title='Services & Condolences' />
        {formError && (
          <div className='alert alert-danger'>
            <div className='alert-text'>{formError}</div>
          </div>
        )}
        <FormItemsRenderer
          formType={formType}
          formik={formik}
          obituaryWebsocket={obituaryWebsocket}
          items={items}
          editingFieldsByName={editingFieldsByName}
          obituaryChannel={obituaryChannel}
        />
        <div className='row mt-10'>
          <div className='col-12 d-flex justify-content-between align-items-center'>
            <Link to={goBackUrl} className='btn btn-secondary me-3'>
              Back
            </Link>
            <button type='submit' className='btn btn-primary'>
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CeremonyDetailsForm
