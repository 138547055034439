import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import JourneyDetailView from './components/JourneyDetailView'

const profileBreadCrumbs: Array<PageLink> = []

const JourneysPage = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path=':unique_id/*'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Edit Journey</PageTitle>
            <JourneyDetailView />
          </>
        }
      />
      <Route index element={<Navigate to='/obituaries' replace />} />
    </Route>
  </Routes>
)

export default JourneysPage
