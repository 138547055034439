import { useState, useCallback, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { HelpCircle } from 'react-feather'
import styled from 'styled-components'

import { ApiError } from '../auth'
import { useObituaryDetail } from './components/ObituaryDetail'
import ProgressBarInSeconds from './ProgressBarInSeconds'
import { availablePoemTypes, PersonalizedPoem, PoemType } from './poetry/types'
import { getPersonalizedPoems, generatePersonalizedPoem } from '../../funeral_homes/dashboard/core/_requests'
import useObituaryChannel from '../../obituaries/next/useObituaryChannel'
import { replaceNewLinesWithParagraphs } from './components/ObituaryWriteSection'

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`

const LineBreakWrapper = styled.div`
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`

const formatMessage = (message: string) => {
  return message.split('\n').map((line, index) => {
    return <LineBreakWrapper key={index}>{line ? line : <p className='py-2'></p>}</LineBreakWrapper>
  })
}

function PoemViewer({ poems, onPoemUpdated }: { poems: PersonalizedPoem[], onPoemUpdated: (poem: PersonalizedPoem) => void }) {

  const poem = poems[0]

  const [, setPoemIsBeingWritten] = useState(false)
  const [textBeingWritten, setTextBeingWritten] = useState('')

  const {
    obituaryChannel
  } = useObituaryChannel()

  useEffect(() => {
    if (!obituaryChannel) {
      return;
    }
    obituaryChannel.on('started_writing_poem', (msg) => {
      setPoemIsBeingWritten(true)
      setTextBeingWritten('...')
    })
    obituaryChannel.on('poem_writing_update', (msg) => {
      if (msg.payload?.text_content) {
        setTextBeingWritten(replaceNewLinesWithParagraphs(msg.payload.text_content))
      }
    })
    obituaryChannel.on('finished_writing', (msg) => {
      if (msg.payload?.derivative_work?.type_of_derivative_work === 'poem' && msg.payload?.derivative_work?.data?.poem_type && msg.payload?.derivative_work?.data?.poem_type === poem.data?.poem_type) {
        const updatedPoem = new PersonalizedPoem(msg.payload.derivative_work)
        onPoemUpdated(updatedPoem)
      }
    })
  }, [obituaryChannel, onPoemUpdated, poem.data?.poem_type, poem.unique_identifier])

  useEffect(() => {
    if (poem?.text_content) {
      setTextBeingWritten(replaceNewLinesWithParagraphs(poem.text_content))
    }
  }, [poem?.text_content])

  return (
    <div>
      <div className='separator separator-dashed my-4'></div>
      <div className='text-center fs-4'>
        {textBeingWritten ? <>
          <div dangerouslySetInnerHTML={{ __html: textBeingWritten }}></div>
        </> : <>
          {poem.text_content ? formatMessage(poem.text_content) : `...`}
        </>}
      </div>
      <div className='separator separator-dashed my-4'></div>
    </div>
  )
}

interface PersonalizedPoemComponentProps {
  title: string
  helpText?: string
  primaryActionText?: string
  secondaryActionText?: string
  loadingActionText?: string
}

function PersonalizedPoemComponent({ title, helpText = undefined }: PersonalizedPoemComponentProps) {
  const { obituary } = useObituaryDetail()

  const [viewingHelpText, setViewingHelpText] = useState(false)

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const [generatingPoem, setGeneratingPoem] = useState(false)
  const [errorGeneratingPoem, setErrorGeneratingTopInsights] = useState<string | null>(null)

  const [allPersonalizedPoems, setPersonalizedPoems] = useState<PersonalizedPoem[]>([])
  const [canGeneratePoem, setCanGeneratePoem] = useState<boolean | null>(null)

  const [selectedPoemType, setSelectedPoemType] = useState<PoemType | null>(availablePoemTypes[0])

  const personalizedPoems = useMemo(() => {
    return selectedPoemType
      ? allPersonalizedPoems.filter((poem) => poem.data.poem_type === selectedPoemType.value)
      : []
  }, [allPersonalizedPoems, selectedPoemType])

  const onLoadMemorialItem = useCallback(async () => {
    try {
      const updatedPoem = await getPersonalizedPoems(obituary.unique_identifier)
      setPersonalizedPoems(updatedPoem)
      setCanGeneratePoem(true)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [obituary.unique_identifier])

  const onGeneratePoem = useCallback(async () => {
    setGeneratingPoem(true)
    setErrorGeneratingTopInsights(null)
    try {
      const { personalized_poem: newPoem } = await generatePersonalizedPoem(obituary.unique_identifier, {
        poem_type: selectedPoemType?.value,
      })
      setPersonalizedPoems([newPoem, ...allPersonalizedPoems])
      setCanGeneratePoem(true)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setErrorGeneratingTopInsights(errorMessage)
    } finally {
      setGeneratingPoem(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obituary.unique_identifier, selectedPoemType])

  const onPoemUpdated = useCallback((poem: PersonalizedPoem) => {
    setPersonalizedPoems([poem, ...allPersonalizedPoems.filter((p) => p.unique_identifier !== poem.unique_identifier)])
  }, [allPersonalizedPoems])

  useEffect(() => {
    onLoadMemorialItem()
  }, [onLoadMemorialItem])

  return (
    <div>
      <TitleContainer>
        <h2 className='m-0 p-0'>{title}</h2>
        {helpText ? (
          <button
            type='button'
            className='btn btn-sm btn-icon btn-icon-primary'
            onClick={() => setViewingHelpText(!viewingHelpText)}
          >
            <HelpCircle />
          </button>
        ) : (
          <div style={{ height: 38 }} />
        )}
      </TitleContainer>
      {viewingHelpText && (
        <div className='alert alert-info' role='alert'>
          <div className='alert-text'>{helpText}</div>
        </div>
      )}
      <div className='separator separator-dashed my-7'></div>
      <select
        className='form-select form-select-solid'
        onChange={(e) =>
          setSelectedPoemType(
            availablePoemTypes.find((poemType) => poemType.value === e.target.value) || null
          )
        }
      >
        {availablePoemTypes.map((poemType) => {
          return (
            <option key={poemType.value} value={poemType.value}>
              {poemType.label}
            </option>
          )
        })}
      </select>
      {selectedPoemType && (
        <div className='fs-5 d-flex gap-5 p-2 text-center'>
          <div>{selectedPoemType.description}</div>
        </div>
      )}
      {loading ? (
        <>
          <div>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>
              <div className='alert alert-danger' role='alert'>
                <div className='alert-text'>{error}</div>
              </div>
            </>
          ) : (
            <>
              {personalizedPoems.length === 0 ? (
                <></>
              ) : (
                <>
                  {/* This is right above the "Generate Acrostic" button ... */}
                  <PoemViewer onPoemUpdated={onPoemUpdated} poems={personalizedPoems} />
                </>
              )}
              {canGeneratePoem && (
                <div className='mt-4 text-center'>
                  {errorGeneratingPoem && (
                    <div className='alert alert-danger' role='alert'>
                      <div className='alert-text'>{errorGeneratingPoem}</div>
                    </div>
                  )}
                  <button
                    type='button'
                    className={clsx(
                      'btn btn-sm',
                      personalizedPoems?.length === 0 ? 'btn-primary' : 'btn-secondary'
                    )} // TODO: make this a 'btn-outline-primary
                    disabled={generatingPoem}
                    onClick={onGeneratePoem}
                  >
                    {/* e.g. Re-Generate Sonnet */}
                    {!generatingPoem
                      ? personalizedPoems?.length === 0
                        ? `Generate ${selectedPoemType?.label || 'Poem'}`
                        : `Re-Generate ${selectedPoemType?.label || 'Poem'}`
                      : `Generating ${selectedPoemType?.label || 'Poem'}...`}
                  </button>
                  {generatingPoem && false && 'disabledBecauseWeStreamNow' && (
                    <div className='mt-4'>
                      <ProgressBarInSeconds message='Writing poem...' estimatedTimeInSeconds={5} />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default PersonalizedPoemComponent
