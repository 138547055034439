import {Transition} from '@headlessui/react'
import {InformationCircleIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {NoticeAlertType} from '../types'

import styled from 'styled-components'

const TransitionalContainer = styled.div`
  transition: transform 0.5s ease-out;
  transform: translateX(-200%);

  &.enter-active {
    transform: translateX(0);
  }

  &.leave-active {
    transform: translateX(-200%);
  }
`

const showForSeconds = 5

export default function NoticeAlert({title, link = '', id, removeAlert}: NoticeAlertType) {
  const [show, setShow] = useState(true)

  const [currentClassName, setCurrentClassName] = useState('')

  useEffect(() => {
    // Set the enter-active class 50ms after the component mounts
    setTimeout(() => {
      setCurrentClassName('enter-active')
    }, 50)
    setTimeout(() => {
      // Add the hide class 0.5 seconds before removing
      setCurrentClassName('leave-active')
    }, (showForSeconds - 0.5) * 1000)
  }, [])

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false)
        setTimeout(() => {
          removeAlert(id)
        }, 500)
      }, showForSeconds * 1000)
    }
  }, [id, removeAlert, show])

  return (
    <TransitionalContainer className={currentClassName}>
      <Transition
        show={show}
        enter='transition-transform duration-500 ease-out'
        enterFrom={'transform translate-x-[-100%]'}
        enterTo={'transform translate-x-0'}
        leave='transition-transform duration-500 ease-in'
        leaveFrom={'transform translate-x-0'}
        leaveTo={'transform translate-x-[-100%]'}
      >
        <div
          onClick={() => {
            setShow(false)
            removeAlert(id)
          }}
          className='mt-6'
        >
          <div className='mb-0 alert alert-info shadow'>
            <div className='alert-text font-weight-bold text-dark'>
              <i className='fas fa-info-circle me-2'></i>
              {title}
            </div>
          </div>
        </div>
      </Transition>
    </TransitionalContainer>
  )
}
