import { useCallback, useEffect, useState } from "react";
import { ReactFlowInstance } from "reactflow";
import styled from "styled-components";
import {
    X as CloseIcon,
} from 'react-feather'

import { generateJourneyFlowPreview, publishJourneyFlow } from "../../../../../funeral_homes/dashboard/core/_requests";
import { ApiError } from "../../../../auth";
import NodeSubmissionError from "./NodeSubmissionError";
import { JourneyValidationError } from "../../../../../funeral_homes/dashboard/core/journey_classes";
import { useAuth } from "../../../../auth/core/Auth";

const Container = styled.div`
    position: absolute;
    right: 0;
    top: 0;
`;

const Content = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    text-align: right;
`;

const ValidationErrorsContainer = styled.div`
    position: relative;
    margin-top: 10px;
    width: 250px;
    min-height: 100px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    background-color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const CloseIconContainer = styled.div`
    position: absolute;
    left: -10px;
    top: -10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ccc;
    border-radius: 50%;
    cursor: pointer;
`;

const JumpToErrorWrapper = styled.div`
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 10px;
`;

function InteractiveEmployeeActions({ reactFlowInstance, setHighlightedNodeId }: { reactFlowInstance: ReactFlowInstance, setHighlightedNodeId: (nodeId: string | null) => void }) {

    const {
        currentPhoenixUser
    } = useAuth();

    const [submitting, setSubmitting] = useState(false);
    const [submissionErrorMessage, setSubmissionErrorMessage] = useState<string | null>(null);
    const [submissionSuccessMessage, setSubmissionSuccessMessage] = useState<string | null>(null);

    const [currentValidationErrors, setCurrentValidationErrors] = useState<JourneyValidationError[]>([]);

    useEffect(() => {
        return;
        /*
        const currentFlowObject = reactFlowInstance.toObject();
        //
        // The data is stored in the DB, so right now we just send the nodeIds and edgeIds to the backend.
        // This exposes a bit of security risk but as we iterate on the front-end we want to 
        // keep things moving quickly.
        //
        // We may keep it all in the back-end one day.
        //
        const initialNodeId = 'node-welcome-video'; // Also will be in the back-end, but useful to have here.
        const nodeIds = currentFlowObject.nodes.map((node) => node.id);
        const edgesWithInformation = currentFlowObject.edges.map((edge) => {
            return {
                id: edge.id,
                source: edge.source,
                target: edge.target
            }
        });
        console.log('initialNodeId', initialNodeId);
        console.log('nodeIds', nodeIds);
        console.log('edgeIds', edgesWithInformation);
        */
    }, [reactFlowInstance])

    const onGeneratePreview = useCallback(async () => {
        setSubmitting(true);
        setSubmissionErrorMessage(null);
        setCurrentValidationErrors([]);
        try {
            const result = await generateJourneyFlowPreview();
            if (result.validation_errors && result.validation_errors.length > 0) {
                setCurrentValidationErrors(result.validation_errors);
            } else if (!result.journey_flow?.unique_identifier) {
                setSubmissionErrorMessage("Bad response")
            } else {
                const usePreviewUrl = currentPhoenixUser?.current_client?.public_data?.preview_interactive_employee_at_url;
                const finalUrl = usePreviewUrl ? usePreviewUrl : process.env.NODE_ENV === 'production' ? 'https://www.solemnoaks.com' : 'http://localhost:3000'
                window.open(
                    `${finalUrl}/?obit_ai_jf_uid=${result.journey_flow.unique_identifier}`, '_blank'
                );
            }
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof ApiError ? exception.message : 'Something went wrong'
            setSubmissionErrorMessage(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }, []);

    const onPublishVersion = useCallback(async () => {
        setSubmitting(true);
        setSubmissionErrorMessage(null);
        setCurrentValidationErrors([]);
        try {
            const result = await publishJourneyFlow();
            if (result.validation_errors && result.validation_errors.length > 0) {
                setCurrentValidationErrors(result.validation_errors);
            } else if (!result.journey_flow?.unique_identifier) {
                setSubmissionErrorMessage("Bad response")
            } else {
                setSubmissionSuccessMessage("Successfully published");
            }
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof ApiError ? exception.message : 'Something went wrong'
            setSubmissionErrorMessage(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }, []);

    const handleJumpToNodeError = useCallback((error: JourneyValidationError) => {
        const node = reactFlowInstance.getNodes().find((node) => node.id === error.node_id);
        if (node && node.position) {
            setHighlightedNodeId(node.id);
            // reactFlowInstance.fitView();
            // reactFlowInstance.zoomTo(1);
            reactFlowInstance.setCenter(
                node.position.x,
                node.position.y,
                {
                    zoom: 1
                }
            );
        }
    }, [reactFlowInstance, setHighlightedNodeId]);

    return <Container>
        <Content className="p-4">
            <div className="btn-group">
                <button
                    className="btn btn-warning"
                    type="button"
                    onClick={onGeneratePreview}
                    disabled={submitting}
                >
                    Preview
                </button>
                <button
                    className="btn btn-success"
                    type="button"
                    onClick={onPublishVersion}
                    disabled={submitting}
                >
                    Publish
                </button>
            </div>
            {currentValidationErrors.length > 0 && <ValidationErrorsContainer>
                {currentValidationErrors.map((error, index) => {
                    return <JumpToErrorWrapper
                        key={index}
                        onClick={() => {
                            handleJumpToNodeError(error);
                        }}>
                        {error.message}
                    </JumpToErrorWrapper>
                })}
                <CloseIconContainer
                    onClick={() => {
                        setCurrentValidationErrors([]);
                        setHighlightedNodeId(null)
                    }}>
                    <CloseIcon
                        size={14}
                    />
                </CloseIconContainer>
            </ValidationErrorsContainer>}
        </Content>
        <NodeSubmissionError message={submissionErrorMessage || submissionSuccessMessage} type={submissionErrorMessage ? 'error' : 'success'} />
    </Container>
}

export default InteractiveEmployeeActions;