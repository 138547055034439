import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'

import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getUserByToken } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import { ApiError } from '..'
import { SimplifiedPhoenixUser, SimplifiedPhoenixUserPhone } from '../../obituaries/core/_models'

interface AuthResponse {
  accessToken: string
  data_access_expiration_time: number
  expiresIn: number
  signedRequest: string
  userID: string
  grantedScopes?: string | undefined
  reauthorize_required_in?: number | undefined
}

// We have both users and user phones for now, which are similar but temporary.
// We started the front-end with phone only and now we have phone/email.

// This may change over time

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentPhoenixUser: SimplifiedPhoenixUser | undefined
  currentPhoenixUserPhone: SimplifiedPhoenixUserPhone | undefined
  setCurrentPhoenixUser: Dispatch<SetStateAction<SimplifiedPhoenixUser | undefined>>
  setCurrentPhoenixUserPhone: Dispatch<SetStateAction<SimplifiedPhoenixUserPhone | undefined>>
  logout: () => void
  onContinueWithFacebook: ({
    onCancel,
    onSuccess,
    onError,
  }: {
    onCancel: () => void
    onSuccess: ({
      authResponse,
      userResponse,
    }: {
      authResponse: AuthResponse
      userResponse: any
    }) => void
    onError: (message?: string) => void
  }) => void
  onGoogleOauthSuccess: (
    res: any,
    {
      onError,
    }: {
      onError: (message?: string) => void
    }
  ) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentPhoenixUser: undefined,
  currentPhoenixUserPhone: undefined,
  setCurrentPhoenixUser: () => { },
  setCurrentPhoenixUserPhone: () => { },
  logout: () => { },
  onContinueWithFacebook: () => { },
  onGoogleOauthSuccess: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())

  const [currentPhoenixUser, setCurrentPhoenixUser] = useState<SimplifiedPhoenixUser | undefined>()
  const [currentPhoenixUserPhone, setCurrentPhoenixUserPhone] = useState<SimplifiedPhoenixUserPhone | undefined>()

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentPhoenixUser(undefined)
    setCurrentPhoenixUserPhone(undefined)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentPhoenixUser,
        currentPhoenixUserPhone,
        setCurrentPhoenixUser,
        setCurrentPhoenixUserPhone,
        logout,
        onContinueWithFacebook: () => { },
        onGoogleOauthSuccess: () => { },
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentPhoenixUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const [tokenVerificationError, setTokenVerificationError] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const user = await getUserByToken(apiToken)
          if (user) {
            // This is going to break things with other parts of the obituary front-end,
            // sorry.
            setCurrentPhoenixUser(user)
          }
        }
        setTokenVerificationError(undefined)
      } catch (error: any) {
        console.warn(error)
        const errorMessage = error instanceof ApiError ? error.message : 'Unable to verify user'
        setTokenVerificationError(errorMessage)
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  if (tokenVerificationError) {
    return (
      <div className='d-flex flex-column flex-root'>
        <div className='d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30'>
          <div className='d-flex flex-column flex-row-fluid text-center'>
            <h1 className='error-title font-weight-boldest text-dark-75 mt-15 mt-sm-30 mb-15'>
              Oops...
            </h1>
            <p className='display-4 font-weight-bold text-danger'>{tokenVerificationError}</p>
            {/* Button to retry */}
            <div>
              <button
                type='button'
                className='btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3'
                onClick={() => window.location.reload()}
              >
                Retry
              </button>
            </div>
            {/* Logout button */}
            <div>
              <button
                type='button'
                className='btn btn-secondary font-weight-bolder font-size-h6 px-8 py-4 my-3'
                onClick={() => {
                  logout()
                  window.location.reload()
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
