import React from 'react';

export function PauseButtonIcon() {
    return (
        <div style={{ top: 0, right: 0, position: 'absolute', left: 0, bottom: 0 }}>
            <div style={{ height: '100%', width: '100%', margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-pause">
                    <rect x="6" y="4" width="4" height="16"></rect>
                    <rect x="14" y="4" width="4" height="16"></rect>
                </svg>
            </div>
        </div>
    );
}

export default PauseButtonIcon;
