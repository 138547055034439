import update from 'immutability-helper'
import { FC, useEffect } from 'react'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Obituary, Section } from '../core/_models'
import { getObituaryByShareableCode, updateObituarySectionOrder } from '../core/_requests'
import { useObituaryDetail } from './ObituaryDetail'

import ObituaryWriteSection from './ObituaryWriteSection'
import { ObituaryWebsocket } from '../../../obituaries/useObituaryWebsocket'
import useSimpleFormPageView, { IUsersWhoAreOnPages } from '../../../obituaries/useSimpleFormPageView'
import useSimpleSectionWriteUpdate from '../../../obituaries/useSimpleSectionWriteUpdate'
import usePageTitle from '../../../hooks/usePageTitle'

const style = {
  width: '100%',
}

export interface DraggableSection {
  id: number
  text: string
  render: () => JSX.Element
}

export interface ContainerState {
  sections: DraggableSection[]
}

interface ObituaryWriteDragAndDropProps {
  obituary: Obituary
  setObituary: (obituary: Obituary) => void
  obituaryWebsocket: ObituaryWebsocket | null
  sectionBackgroundColor: string
  linkToUpdateBio: string
  linkToRelativesSection: string
  linkToServiceDetails: string
  isShareableObituary?: boolean
}

function UsersOWritePage({ usersOnPage }: { usersOnPage: IUsersWhoAreOnPages }) {
  // This feels distracting right now so I'm going to hide it
  return <></>

  /*
  const usersOnWriteObituaryPage = usersOnPage.write_obituary || {} // This is a dict of {user_id: SimpleUserModel}
  const usersOnWriteObituaryPageArray = Object.values(usersOnWriteObituaryPage)

  // If usersOnWriteObituaryPageArray is empty show nothing

  if (usersOnWriteObituaryPageArray.length === 0) {
    return <></>
  }

  const userNames = usersOnWriteObituaryPageArray.map((x: SimpleUserModel) => {
    return x.user_profile?.getSimpleName()
  }).filter(Boolean)

  let userNamesString = ''

  if (userNames.length === 1) {
    userNamesString = `${userNames[0]} is viewing this page`
  } else if (userNames.length === 2) {
    userNamesString = `${userNames[0]} and ${userNames[1]} are viewing this page`
  } else {
    const lastUser = userNames.pop()
    userNamesString = `${userNames.join(', ')}, and ${lastUser} are viewing this page`
  }

  return (
    <div className='mb-5 alert alert-info'>
      <div className='alert-text font-weight-bold text-dark'>
        <i className='fas fa-info-circle me-2'></i>
        {userNamesString}
      </div>
    </div>
  )
  */
}

export const ObituaryWriteDragAndDrop: FC<ObituaryWriteDragAndDropProps> = ({
  obituary,
  setObituary,
  obituaryWebsocket,
  sectionBackgroundColor,
  linkToUpdateBio,
  linkToRelativesSection,
  linkToServiceDetails,
  isShareableObituary = false,
}) => {
  const [sectionsLastGenerated, setSectionsLastGenerated] = useState<number>(0) // Hack to get the sections to re-render
  const [draggableSections, setDraggableSections] = useState<Section[]>(obituary.active_sections)

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setDraggableSections((prevCards: Section[]) => {
        const updatedSections = update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as Section],
          ],
        })
        // We use a Promise so that it's async (no UI needs to wait for this)
        // Probably should use a websocket instead
        updateObituarySectionOrder(
          obituary.unique_identifier,
          updatedSections.map((x) => x.unique_id)
        ).then((updatedObituary) => {
          setObituary(updatedObituary)
        })
        return updatedSections
      })
    },
    [obituary.unique_identifier, setObituary]
  )

  const { usersOnPage } = useSimpleFormPageView(obituaryWebsocket)

  const { sectionsBeingGenerated } = useSimpleSectionWriteUpdate(
    obituaryWebsocket,
    (updatedObituary: Obituary) => {
      setObituary(updatedObituary)
      setSectionsLastGenerated(new Date().getTime())
      setDraggableSections(updatedObituary.active_sections)
    }
  )

  const renderSection = useCallback(
    (section: Section, index: number) => {
      return (
        <ObituaryWriteSection
          key={`section-${section.unique_id}-${sectionsLastGenerated}`}
          obituary={obituary}
          setObituary={setObituary}
          index={index}
          section={section}
          moveCard={moveCard}
          sectionBackgroundColor={sectionBackgroundColor}
          linkToUpdateBio={linkToUpdateBio}
          linkToRelatives={linkToRelativesSection}
          linkToServiceDetails={linkToServiceDetails}
          sectionsBeingGenerated={sectionsBeingGenerated}
          isShareableObituary={isShareableObituary}
        />
      )
      // Using a copy and pasted example so I assume we should not have exhaustive deps here
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      obituary,
      draggableSections,
      sectionsBeingGenerated,
      sectionsLastGenerated,
      isShareableObituary,
    ]
  )

  useEffect(() => {
    if (obituaryWebsocket) {
      obituaryWebsocket.sendPageView('write_obituary')
    }
  }, [obituaryWebsocket])

  usePageTitle({
    title: `${obituary.getSimpleName()}'s Obituary | Write Obituary`,
  })

  const onReloadObituary = useCallback(async () => {
    if (!obituary) {
      return
    }
    try {
      // This is called in the `Write Obituary` tab
      // We use `getObituaryByShareableCode` cause it's new and better
      const { obituary: updatedObituary } = await getObituaryByShareableCode(obituary.unique_identifier)
      setObituary(updatedObituary)
    } catch (error) {
      console.warn(error)
    }
  }, [obituary.unique_identifier, setObituary])

  useEffect(() => {
    onReloadObituary()
  }, [onReloadObituary])

  return (
    <>
      <UsersOWritePage usersOnPage={usersOnPage} />
      {!obituary.data_relatives?.passedAway && !obituary.data_relatives?.survivors && (
        <div className='mb-5 alert alert-warning'>
          <div className='alert-text font-weight-bold text-dark'>
            No family relationships have been added to this obituary. Please
            <Link
              className='ms-1 me-1 text-dark text-decoration-underline'
              to={linkToRelativesSection}
            >
              add family relationships
            </Link>
            in order to generate the best possible obituary.
          </div>
        </div>
      )}
      <div style={style}>
        {draggableSections.map((draggableItem, i) => renderSection(draggableItem, i))}
      </div>
    </>
  )
}

function ObituaryWriteDragAndDropWrapper() {
  const { obituary, setObituary, obituaryWebsocket } = useObituaryDetail()

  return (
    <ObituaryWriteDragAndDrop
      obituary={obituary}
      setObituary={setObituary}
      obituaryWebsocket={obituaryWebsocket}
      sectionBackgroundColor='white'
      linkToUpdateBio={`/obituaries/${obituary.unique_identifier}`}
      linkToRelativesSection={`/obituaries/${obituary.unique_identifier}/relatives`}
      linkToServiceDetails={`/obituaries/${obituary.unique_identifier}/ceremony`}
    />
  )
}

export default ObituaryWriteDragAndDropWrapper
