import React, { useEffect, useRef, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import useObituaryQuestionsWebsocket, {
  ObituaryFinalQuestionMessage,
  ObituaryFinalQuestionMessageStatusUpdate,
} from '../../../../obituaries/useObituaryQuestionsWebsocket'
import { useObituaryDetail } from '../ObituaryDetail'
import { Obituary } from '../../core/_models'
import { useAuth } from '../../../auth/core/Auth'
import useObituaryPartialUpdater from '../../../../obituaries/useObituaryPartialUpdater'

const darkTheme = {
  background: 'rgba(52, 53, 65)',
  textColor: 'white',
  messageBackgroundColor: '#546e7a',
  inputBackground: 'rgba(64, 65, 79)',
  inputBorderColor: 'rgba(0, 0, 0, 0.2)',
  focusBorderColor: '#546e7a',
  chatMessageFromSystemBackgroundColor: 'rgba(52,53,65)',
  chatMessageFromSystemTextColor: 'white',
  chatMessageFromUserBackgroundColor: '#546e7a',
  chatMessageFromUserTextColor: 'white',
}

const lightTheme = {
  background: 'white',
  textColor: '#333',
  messageBackgroundColor: '#cce4f6',
  inputBackground: '#f1f1f1',
  inputBorderColor: 'rgba(0, 0, 0, 0.1)',
  focusBorderColor: '#8dbdf0',
  chatMessageFromSystemBackgroundColor: '#cce4f6',
  chatMessageFromSystemTextColor: 'black',
  chatMessageFromUserBackgroundColor: '#8dbdf0',
  chatMessageFromUserTextColor: 'white',
}

const ChatMessageText = styled.div`
  margin: 0;
  padding: 8px 16px;
  border-radius: 16px;
`

const ChatMessageFromSystemText = styled(ChatMessageText)`
  background-color: ${({ theme }) => theme.chatMessageFromSystemBackgroundColor};
  color: ${({ theme }) => theme.chatMessageFromSystemTextColor};
  font-weight: 100;
`

const ChatMessageFromUserText = styled(ChatMessageText)`
  background-color: ${({ theme }) => theme.chatMessageFromUserBackgroundColor};
  color: ${({ theme }) => theme.chatMessageFromUserTextColor};
`

const StyledChatContainer = styled.div`
  color: ${({ theme }) => theme.textColor};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: 18px;
  background-color: ${({ theme }) => theme.background};
`

const StyledChatMessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  position: relative;
`

const ChatMessage = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  flex-direction: column;
`

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
`

const StyledChatMessageFromSystem = styled(ChatMessage)``

const StyledChatMessageFromUser = styled(ChatMessage)``

const StyledChatInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.background};
  border-top: 1px solid ${({ theme }) => theme.inputBorderColor};
`

const ChatInputForm = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
`

const ChatInput = styled.input`
  flex: 1;
  padding: 8px 16px;
  border-radius: 24px;
  outline: none;
  background: ${({ theme }) => theme.inputBackground};
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  color: ${({ theme }) => theme.textColor};

  &:focus {
    border-color: ${({ theme }) => theme.focusBorderColor};
  }
`

const ObituaryFinalQuestionsChat = ({
  obituary,
  theme = 'dark',
}: {
  obituary: Obituary
  theme?: 'dark' | 'light'
}) => {
  const { currentPhoenixUserPhone } = useAuth()

  const messageContainerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const selectedTheme = theme === 'dark' ? darkTheme : lightTheme
  const [messages, setMessages] = useState<ObituaryFinalQuestionMessage[]>([])
  const [inputValue, setInputValue] = useState('')

  const [loadingInitialQuestions, setLoadingInitialQuestions] = useState(true)
  const [isTyping, setIsTyping] = useState(false)

  const { onSendNewResponse } = useObituaryQuestionsWebsocket(
    obituary,
    (message: ObituaryFinalQuestionMessage) => {
      setMessages((messages) => [...messages, message])
      setTimeout(() => {
        if (messageContainerRef.current) {
          messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight
        }
      }, 10) // TODO: find a better way to do this
    },
    (updatedMessage: ObituaryFinalQuestionMessageStatusUpdate) => {
      // updatedMessage.status is either created or finished:
      if (updatedMessage.status === 'finished') {
        setMessages((messages) => {
          return messages.map((message) => {
            if (message.unique_id === updatedMessage.message.unique_id) {
              return updatedMessage.message
            }
            return message
          })
        })
      } else if (updatedMessage.status === 'created') {
        setMessages((messages) => [...messages, updatedMessage.message])
      }
      setTimeout(() => {
        if (messageContainerRef.current) {
          messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight
        }
      }, 10) // TODO: find a better way to do this
    }
  )

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value)
  }

  const handleSendMessage = (e: any) => {
    e.preventDefault()
    if (inputValue) {
      onSendNewResponse({
        message: inputValue,
      })
      setInputValue('')
    }
  }

  useObituaryPartialUpdater(obituary.unique_identifier, 'final-questions', (updatedData: any) => {
    setMessages(updatedData)
    setLoadingInitialQuestions(updatedData.is_system_generating)
    setTimeout(() => {
      if (messageContainerRef.current) {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight
      }
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 10) // TODO: find a better way to do this
  })

  return (
    <ThemeProvider theme={selectedTheme}>
      <StyledChatContainer>
        <StyledChatMessagesContainer ref={messageContainerRef}>
          {messages.map((message, index) => {
            if (message.created_by_user?.unique_id !== currentPhoenixUserPhone?.unique_identifier) {
              return (
                <ChatMessageFromSystem
                  key={index}
                  message={message}
                  obituary={obituary}
                  isFirstMessage={index === 0}
                />
              )
            } else {
              return <ChatMessageFromUser key={index} message={message} />
            }
          })}
          {loadingInitialQuestions && (
            <div className='position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center'>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          )}
        </StyledChatMessagesContainer>
        <StyledChatInputContainer>
          <ChatInputForm onSubmit={handleSendMessage}>
            <ChatInput
              ref={inputRef}
              autoFocus
              value={inputValue}
              onChange={handleInputChange}
              placeholder={!loadingInitialQuestions ? 'Type your reply here...' : ''}
              disabled={loadingInitialQuestions}
            />
          </ChatInputForm>
        </StyledChatInputContainer>
      </StyledChatContainer>
    </ThemeProvider>
  )
}

const LineBreakWrapper = styled.div`
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`

const formatMessage = (message: string) => {
  return message
    .split('\n')
    .map((line, index) => <LineBreakWrapper key={index}>{line}</LineBreakWrapper>)
}

const UserRow = ({ name }: { name: string }) => {
  return (
    <div className='d-flex align-items-center mb-2'>
      {false && <Avatar src='/path/to/system_avatar.png' alt='System Avatar' />}
      <div>{name}</div>
    </div>
  )
}

const ChatMessageFromSystem = ({
  message,
  obituary,
  isFirstMessage,
}: {
  message: ObituaryFinalQuestionMessage
  obituary: Obituary
  isFirstMessage?: boolean
}) => {
  return (
    <StyledChatMessageFromSystem>
      <UserRow
        name={
          message.created_by_user?.user_profile?.getSimpleName() ||
          obituary.funeral_home?.name ||
          'System'
        }
      />
      <ChatMessageFromSystemText>
        {message.message
          ? formatMessage(message.message)
          : !isFirstMessage
            ? '...'
            : 'Preparing question...'}
      </ChatMessageFromSystemText>
    </StyledChatMessageFromSystem>
  )
}

const ChatMessageFromUser = ({ message }: { message: ObituaryFinalQuestionMessage }) => {
  return (
    <StyledChatMessageFromUser>
      <UserRow name='You' />
      <ChatMessageFromUserText>
        {message.message ? formatMessage(message.message) : '...'}
      </ChatMessageFromUserText>
    </StyledChatMessageFromUser>
  )
}

export default ObituaryFinalQuestionsChat
