import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { ApiError } from '../../../auth'
import { saveObituaryComponent } from '../../core/_requests'
import { affiliationsSchema } from '../../form_schema'
import { Affiliations, KnownFamilyMember, Obituary } from '../../core/_models'
import FormTitle from './FormTitle'
import { IAvailableFormType, ObituaryWebsocket } from '../../../../obituaries/useObituaryWebsocket'
import useComplicatedFormBehavior from '../../../../obituaries/useComplicatedFormBehavior'
import FormItemsRenderer, { IItemProps } from './FormItemsRenderer'
import useObituaryPartialUpdater from '../../../../obituaries/useObituaryPartialUpdater'
import { useAuth } from '../../../auth/core/Auth'
import useObituaryFormUpdater from '../../../../obituaries/next/useObituaryFormUpdater'
import useObituaryChannel from '../../../../obituaries/next/useObituaryChannel'

const _affiliationsToFormikValues = (affiliations: Affiliations): Affiliations => {
  return {
    listedAffiliations: affiliations.listedAffiliations || '',
  }
}

function AffiliationsForm({
  obituary,
  setObituary,
  onSuccessfulSave,
  goBackUrl,
  obituaryWebsocket,
  userRelationshipToObituary,
}: {
  obituary: Obituary
  setObituary: (obituary: Obituary) => void
  onSuccessfulSave: (updatedObituary: Obituary) => void
  goBackUrl: string
  obituaryWebsocket: ObituaryWebsocket | null
  userRelationshipToObituary?: KnownFamilyMember | null
}) {
  const { currentPhoenixUserPhone } = useAuth()

  const {
    obituaryChannel
  } = useObituaryChannel();

  const title = 'Affiliations'

  const formik = useFormik<Affiliations>({
    initialValues: _affiliationsToFormikValues(obituary.data_affiliations || ({} as Affiliations)),
    validationSchema: affiliationsSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true)
      setStatus(undefined)
      try {
        const updatedObituary = await saveObituaryComponent(
          obituary.unique_identifier,
          'affiliations',
          values
        )
        setObituary(updatedObituary)
        onSuccessfulSave(updatedObituary)
      } catch (error: any) {
        console.warn(error)
        const errorMessage =
          error instanceof ApiError ? error.message : 'Unable to save affiliations.'
        setStatus(errorMessage)
      }
    },
  })

  const formError = formik.status

  const items: IItemProps[] = [
    {
      label: !userRelationshipToObituary?.is_self
        ? currentPhoenixUserPhone?.isFuneralHomeUser()
          ? `List any affiliations that have been meaningful to ${obituary.getSimpleName() || 'your client'
          }, including religious, professional, social, or cultural groups.`
          : `List any affiliations that have been meaningful to ${obituary.getSimpleName() || 'your loved one'
          }, including religious, professional, social, or cultural groups.`
        : 'List any affiliations that have been meaningful to you, including religious, professional, social, or cultural groups.',
      name: 'listedAffiliations',
      type: 'text',
      value: formik.values.listedAffiliations,
      autoFocus: false,
      required: false,
      error: formik.errors.listedAffiliations,
      touched: formik.touched.listedAffiliations,
      isTextArea: true,
      placeholder:
        'e.g. Local Church, Rotary Club, Professional Association, Community Organization, Sports Club...',
      additionalQuestionId: 'affiliations.listedAffiliations',
    } as IItemProps,
  ]

  const formType: IAvailableFormType = 'affiliations'
  const { editingFieldsByName } = useComplicatedFormBehavior(formType, obituaryWebsocket, formik)

  useObituaryPartialUpdater(obituary.unique_identifier, 'affiliations', (updatedData: any) => {
    formik.setValues(_affiliationsToFormikValues(updatedData))
    obituary.data_affiliations = updatedData // Should we call setObituary here? Hm
  })

  useObituaryFormUpdater({
    obituaryChannel,
    onChange: (event) => {
      if (event.formType === 'affiliations') {
        formik.setFieldValue(event.formName, event.formValue)
      }
    }
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormTitle title={title} />
        {formError && (
          <div className='alert alert-danger'>
            <div className='alert-text'>{formError}</div>
          </div>
        )}
        <FormItemsRenderer
          formType={formType}
          formik={formik}
          obituaryWebsocket={obituaryWebsocket}
          setObituary={setObituary}
          items={items}
          editingFieldsByName={editingFieldsByName}
          receivedQuestions={obituary.data_affiliations?.receivedQuestions || undefined}
          obituaryChannel={obituaryChannel}
        />
        <div className='row mt-10'>
          <div className='col-12 d-flex justify-content-between align-items-center'>
            <Link to={goBackUrl} className='btn btn-secondary me-3'>
              Back
            </Link>
            <button type='submit' className='btn btn-primary'>
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AffiliationsForm
