import { useFormik } from 'formik'
import * as Yup from 'yup'
import { DateTime } from 'luxon'
import DatePicker from 'react-datepicker'
import Select, { createFilter } from 'react-select'

import { useShareableObituary } from './ShareableView'
import { ApiError } from '../modules/auth'
import FormTitle from '../modules/obituaries/components/forms/FormTitle'
import clsx from 'clsx'
import { getDateAsAstring } from '../modules/obituaries/components/ObituaryBiographicalFormCreate'
import familyRelationshipOptions from '../modules/obituaries/core/familyRelationshipOptions'
import { savePersonalRelationshipToObituary } from '../modules/obituaries/core/_requests'
import { useAuth } from '../modules/auth/core/Auth'
import UserFuneralHomeRelationshipForm from './UserFuneralHomeRelationshipForm'
import UserOwnObituaryRelationshipForm from './UserOwnObituaryRelationshipForm'

interface IDescribeRelationshipToObituary {
  fullName: string
  relationship: string
  relationshipOtherDetails?: string
  phoneNumber: string
  emailAddress?: string
}

const describeRelationshipSchema = Yup.object().shape({
  fullName: Yup.string().required('Required'),
  relationship: Yup.string().required('Relationship is required').nullable(),
  relationshipOtherDetails: Yup.string(),
  phoneNumber: Yup.string().required('Required'),
  emailAddress: Yup.string(),
})

function UserRelationshipForm() {
  const { currentPhoenixUserPhone, currentPhoenixUser } = useAuth()
  const {
    obituary,
    setObituary,
    userRelationshipToObituary,
    setUserRelationshipToObituary,
  } = useShareableObituary()


  const formik = useFormik<IDescribeRelationshipToObituary>({
    initialValues: {
      fullName: userRelationshipToObituary?.name || currentPhoenixUserPhone?.user_profile?.name || '' || currentPhoenixUser?.name || '' || currentPhoenixUserPhone?.name || '',
      relationship: userRelationshipToObituary?.relationship || '',
      relationshipOtherDetails: userRelationshipToObituary?.relationship_other_details || '',
      phoneNumber: currentPhoenixUserPhone?.phone_number || currentPhoenixUserPhone?.phone_number || currentPhoenixUser?.data?.inputted_phone_number || '', // Should never be empty
      emailAddress: currentPhoenixUser?.email ? currentPhoenixUser?.email : currentPhoenixUserPhone?.email && !currentPhoenixUserPhone.email_is_unknown ? currentPhoenixUserPhone.email : '',
    },
    validationSchema: describeRelationshipSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true)
      setStatus(undefined)
      try {
        const { obituary: updatedObituary, my_relationship: updatedRelationship } =
          await savePersonalRelationshipToObituary(obituary.unique_identifier, {
            full_name: values.fullName,
            relationship: values.relationship,
            relationship_other_details: values.relationshipOtherDetails,
            phone_number: values.phoneNumber,
            email_address: values.emailAddress,
          })
        setObituary(updatedObituary)
        setUserRelationshipToObituary(updatedRelationship)
      } catch (error: any) {
        console.warn(error)
        const errorMessage =
          error instanceof ApiError ? error.message : 'Unable to save your relationship.'
        setStatus(errorMessage)
      }
    },
  })

  const items = [
    {
      label: 'Your full name *',
      name: 'fullName',
      type: 'text',
      value: formik.values.fullName,
      autoFocus: false,
      required: true,
      error: formik.errors.fullName,
      touched: formik.touched.fullName,
      isTextArea: false,
      isCheckbox: false,
      checked: undefined,
      isDatePicker: false,
      placeholder: 'Enter your full name...',
    },
    // Relationship:
    {
      label: `Enter your relationship to ${obituary.getSimpleName()} *`,
      name: 'relationship',
      type: 'custom',
      component: () => {
        const currentValue = formik.values.relationship
        return (
          <div>
            {/* We used to have className of `react-select-dark-container` and `react-select-dark` for classnamePrefix */}
            {/* // pathname.startsWith('/o for future searchability... */}
            <Select
              name={`relationship`}
              placeholder='Select or search for a relationship type...'
              options={familyRelationshipOptions}
              onChange={(value: any) => {
                if (!value) {
                  formik.setFieldValue(`relationship`, null)
                  return
                }
                formik.setFieldValue(`relationship`, value.value)
              }}
              required
              value={
                currentValue
                  ? familyRelationshipOptions.find((option) => option.value === currentValue)
                  : null
              }
              isClearable
              formatOptionLabel={(option: any) => {
                return option.label
              }}
              filterOption={createFilter({
                ignoreAccents: true,
                ignoreCase: true,
                matchFrom: 'any',
                stringify: (option: any) => {
                  return option.data?.searchableLabel || option.label
                },
              })}
            />
            {formik.errors.relationship && formik.touched.relationship && (
              <div className='alert alert-danger mt-4 mb-0'>
                <div className='alert-text'>{formik.errors.relationship}</div>
              </div>
            )}
          </div>
        )
      },
    },
    formik.values.relationship === 'other' && {
      label: `Describe your relationship to ${obituary.getSimpleName()} *`,
      name: 'relationshipOtherDetails',
      type: 'text',
      value: formik.values.relationshipOtherDetails,
      autoFocus: false,
      required: formik.values.relationship === 'other',
      error: formik.errors.relationshipOtherDetails,
      touched: formik.touched.relationshipOtherDetails,
      isTextArea: false,
      isCheckbox: false,
      checked: undefined,
      isDatePicker: false,
      placeholder: 'Describe your relationship (e.g. friend, colleague, etc.)...',
    },
    {
      header: 'Confirm your contact information:',
      name: 'header',
    },
    // Phone number (required, was used to login):
    // Email (optional):
    {
      label: 'Your phone number *',
      name: 'phoneNumber',
      type: 'text',
      value: formik.values.phoneNumber,
      autoFocus: false,
      required: false,
      error: formik.errors.phoneNumber,
      touched: formik.touched.phoneNumber,
      isTextArea: false,
      isCheckbox: false,
      checked: undefined,
      isDatePicker: false,
      placeholder: undefined,
      readOnly: false, // Until we have the "re-confirm" phone number we disable the input for now.
    },
    {
      label: 'Your email address',
      name: 'emailAddress',
      type: 'email',
      value: formik.values.emailAddress,
      autoFocus: false,
      required: false,
      error: formik.errors.emailAddress,
      touched: formik.touched.emailAddress,
      isTextArea: false,
      isCheckbox: false,
      checked: undefined,
      isDatePicker: false,
      placeholder: 'Enter your email address (optional)...',
    },
  ]

  const formError = formik.status

  if (userRelationshipToObituary?.is_self) {
    // This has the highest priority
    return <UserOwnObituaryRelationshipForm />
  }

  if (
    currentPhoenixUserPhone?.is_admin ||
    (obituary.funeral_home &&
      currentPhoenixUserPhone?.isFuneralHomeUser() &&
      (currentPhoenixUserPhone.active_funeral_home_memberships || [])
        .map((x) => x.funeral_home.unique_identifier)
        .includes(obituary.funeral_home.unique_identifier))
  ) {
    // We do this here because <UserRelationshipForm /> is called in multiple places
    return <UserFuneralHomeRelationshipForm />
  }

  return (
    <div className='mt-4 mt-md-0'>
      <form onSubmit={formik.handleSubmit}>
        <FormTitle
          title={
            userRelationshipToObituary?.relationship
              ? `Confirm your relationship with ${obituary.getSimpleName()}:`
              : `Describe your relationship to ${obituary.getSimpleName()}:`
          }
          hideContinue
        />
        {formError && (
          <div className='alert alert-danger'>
            <div className='alert-text'>{formError}</div>
          </div>
        )}
        {items.map((item) => {
          if (!item) {
            // Should never happen
            return null
          }
          return (
            <div className={clsx('row', item.isCheckbox ? 'mt-4' : 'mt-8')} key={item.name}>
              <div className='col-12'>
                {item.header ? (
                  <>
                    <div className='fs-4'>{item.header}</div>
                  </>
                ) : (
                  <>
                    {item.component ? (
                      <>
                        <label className='form-label'>{item.label}</label>
                        {item.component()}
                      </>
                    ) : (
                      <>
                        {item.isCheckbox ? (
                          <>
                            <div>
                              <input
                                id={item.name}
                                type='checkbox'
                                className='form-check-input'
                                name={item.name}
                                checked={item.checked}
                                onChange={(e) => {
                                  formik.setFieldValue(item.name, e.target.checked)
                                }}
                                onFocus={() => { }}
                                onBlur={() => { }}
                              />
                              <label className='form-check-label ms-2' htmlFor={item.name}>
                                {item.label}
                              </label>
                            </div>
                          </>
                        ) : (
                          <>
                            <label className='form-label'>{item.label}</label>
                            {item.isDatePicker ? (
                              <>
                                <div>
                                  {/* https://github.com/Hacker0x01/react-datepicker/issues/3921 */}
                                  {/* May not need all of these formats, but this seems to make it more useable on user input */}
                                  <DatePicker
                                    className='form-control'
                                    selected={
                                      item.value ? DateTime.fromISO(item.value).toJSDate() : null
                                    }
                                    onChange={(date) => {
                                      formik.setFieldValue(
                                        item.name,
                                        date ? getDateAsAstring(date) : ''
                                      )
                                    }}
                                    onFocus={() => { }}
                                    onBlur={() => { }}
                                    dateFormat={[
                                      'MMMM do, yyyy', // May 1, 2021
                                      'MMMM do yyyy', // May 1 2021
                                      'MMMM d, yyyy', // May 1, 2021
                                      'P', // 2021-05-01
                                      'PP', // 05/01/2021
                                      'PPP', // May 1, 2021
                                      'MMM dd yyyy', // May 01 2021
                                      'MMMM dd yyyy', // May 01 2021
                                      'MMMM d yyyy', // May 1 2021
                                      // Also allow 01-01-2021 and 01/01/2021 and 01.01.2021 and 01 01 2021 and 01-01-2021
                                      // and 01/01/21 and 01/01/21 and 01.01.21 and 01 01 21 and 01-01-21
                                      'MM-dd-yyyy',
                                      'MM/dd/yyyy',
                                      'MM.dd.yyyy',
                                      'MM dd yyyy',
                                      'MM-dd-yy',
                                      'MM/dd/yy',
                                      'MM.dd.yy',
                                      'MM dd yy',
                                    ]}
                                    showYearDropdown
                                    showMonthDropdown
                                    dropdownMode='select'
                                    maxDate={new Date()}
                                    placeholderText={item.placeholder || ''}
                                    required={item.required}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                {!item.isTextArea ? (
                                  <input
                                    type='text'
                                    className='form-control'
                                    name={item.name}
                                    onChange={(e) => {
                                      formik.setFieldValue(item.name, e.target.value)
                                    }}
                                    value={item.value}
                                    autoFocus={item.autoFocus}
                                    required={item.required}
                                    onFocus={() => { }}
                                    onBlur={() => { }}
                                    placeholder={item.placeholder || ''}
                                    readOnly={item.readOnly}
                                  />
                                ) : (
                                  <textarea
                                    className='form-control'
                                    name={item.name}
                                    onChange={(e) => {
                                      formik.setFieldValue(item.name, e.target.value)
                                    }}
                                    onFocus={() => { }}
                                    onBlur={() => { }}
                                    value={item.value}
                                    autoFocus={item.autoFocus}
                                    required={item.required}
                                    placeholder={item.placeholder || ''}
                                    rows={5}
                                    readOnly={item.readOnly}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                {item.error && item.touched && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{item.error}</div>
                  </div>
                )}
              </div>
            </div>
          )
        })}
        <div className='row mt-10'>
          <div className='col-12 d-flex justify-content-end'>
            <button type='submit' className='btn btn-primary'>
              Continue
            </button>
          </div>
        </div>
        {formError && (
          <div className='alert alert-danger mt-4'>
            <div className='alert-text'>{formError}</div>
          </div>
        )}
      </form>
    </div>
  )
}

export default UserRelationshipForm
