import { Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import clsx from 'clsx'
import { useJourneyDetail } from "./_JourneyProvider"

const IconUploadMedia = styled.div`
  overflow: hidden;

  .hover-upload-media {
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-weight: bold;
    font-size: 1.25rem;
    cursor: pointer;
    height: 100%;
  }

  &:hover {
    .hover-upload-media {
      opacity: 1;
    }
  }
`

const PreNeedContainer = styled.div`
  /* yellow background */
  background: #f2f1706e;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: var(--bs-card-border-radius) var(--bs-card-border-radius) 0 0;
  font-size: 12px;
  height: 16px;
`

function JourneyHeader() {
  const location = useLocation()

  const { journey } = useJourneyDetail();

  const shouldShowCondensedHeader = useMediaQuery({ query: '(max-width: 700px)' })

  if (!journey) {
    return null;
  }

  return (
    <>
      <div
        className='card mb-0'
        style={{
          borderBottomWidth: 1,
          borderBottomColor: '#ebedf2',
          borderBottomStyle: 'solid',
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        {journey.isPreNeedObituary() && <PreNeedContainer>{`- Pre-Need -`}</PreNeedContainer>}
        <div
          className={clsx(
            'card-body pb-0',
            shouldShowCondensedHeader ? 'px-4' : 'px-10',
            journey.isPreNeedObituary() ? 'pt-6' : 'pt-4'
          )}
          style={{
            zIndex: 2,
          }}
        >
          <div className={clsx('row py-8', shouldShowCondensedHeader ? 'mb-3' : '')}>
            <div className={clsx(shouldShowCondensedHeader ? 'col-12' : 'col-8')}>
              <div className='row h-100'>
                <div className='col-9 d-flex flex-column align-items-start justify-content-center'>
                  <div className='text-gray-800 fs-2 fw-bolder'>
                    {journey.for_full_name ? `${journey.for_full_name}` : <span>&nbsp;</span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <div
                className={clsx(
                  'd-flex overflow-auto',
                  !shouldShowCondensedHeader ? 'h-55px' : 'h-40px'
                )}
              >
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  {!journey ? (
                    <>
                    </>
                  ) : (
                    <>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            ([
                              `/journeys/${journey.unique_identifier}`,
                              `/journeys/${journey.unique_identifier}/sub-detail-routes-here`,
                            ].indexOf(location.pathname) > -1
                              ? 'active'
                              : '')
                          }
                          to={`/journeys/${journey.unique_identifier}`}
                        >
                          Details
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname === `/journeys/${journey.unique_identifier}/questions` &&
                              'active')
                          }
                          to={`/journeys/${journey.unique_identifier}/questions`}
                        >
                          {!shouldShowCondensedHeader ? 'Questions Answered' : 'Questions'}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname === `/journeys/${journey.unique_identifier}/written-tribute` &&
                              'active')
                          }
                          to={`/journeys/${journey.unique_identifier}/written-tribute`}
                        >
                          {!shouldShowCondensedHeader ? 'Written Tribute' : 'Tribute'}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname === `/journeys/${journey.unique_identifier}/family-section` &&
                              'active')
                          }
                          to={`/journeys/${journey.unique_identifier}/family-section`}
                        >
                          {!shouldShowCondensedHeader ? 'Family Section' : 'Family'}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname === `/journeys/${journey.unique_identifier}/poetry` &&
                              'active')
                          }
                          to={`/journeys/${journey.unique_identifier}/poetry`}
                        >
                          Poetry
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname === `/journeys/${journey.unique_identifier}/quotes-and-scripture` &&
                              'active')
                          }
                          to={`/journeys/${journey.unique_identifier}/quotes-and-scripture`}
                        >
                          Quotes and Scripture
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default JourneyHeader
