import { useCallback, useMemo, useRef, useState } from 'react';
import ReactFlow, { MiniMap, Controls, Background, addEdge, applyEdgeChanges, applyNodeChanges, Node, NodeChange, Edge, EdgeChange, Connection, Viewport, useOnViewportChange, MarkerType, ReactFlowInstance } from 'reactflow';
import styled from 'styled-components';
import Webcam from 'react-webcam';

import 'reactflow/dist/style.css';

import usePageTitle from '../../../../hooks/usePageTitle';
import PersonalizeRecordingActions, { PersonalizeRecordingActionsAsDisabledItems } from '../PersonalizeRecordingActions';
import { ApiError } from '../../../auth';
import { createJourneyPersonalization } from '../../../../funeral_homes/dashboard/core/_requests';

import NodeVideoWithQuestions from './nodes/NodeVideoWithQuestions';
import NodePreNeedLeadCollectionForm from './nodes/NodePreNeedLeadCollectionForm';
import NodeLeadCollectionForm from './nodes/NodeLeadCollectionForm';
import NodeCustomAIObituaryWriter from './nodes/NodeCustomAIObituaryWriter';
import NodeArbitraryDataForm from './nodes/NodeArbitraryDataForm';
import InteractiveEmployeeActions from './components/InteractiveEmployeeActions';
import NodeBackdrop from './nodes/NodeBackdrop';

const ReactFlowContainer = styled.div<{ $hasHighlightedNode: boolean }>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .react-flow__attribution {
        display: none;
    }

    .react-flow__edges {
        z-index: ${({ $hasHighlightedNode }) => !$hasHighlightedNode ? '2000 !important' : '0 !important'};
    }

    .hidden-drag-handle {
        /* quickest way to hide the drag handle and allow future dragging... */
        display: none;
    }
`;

const RecordingInProgressContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    font-weight: 600;
    border-radius: 8px;
    overflow: hidden;
`;

const RecordingFixedContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999999999;
    background: rgba(0, 0, 0, 0.9);
`;

const WebcamContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 200px;
    left: 0;

    video {
        width: 100%;
        height: 100%;
    }
`;

export class NodeCTA {
    label: string;
    handleId: string;
    backgroundColor: string;
    textColor: string;
    borderColor: string;

    constructor(label: string, handleId: string, { isPrimary = false }: { isPrimary?: boolean } = {}) {
        this.label = label;
        this.handleId = handleId;
        if (isPrimary) {
            this.backgroundColor = '#4A90E2';
            this.textColor = 'white';
            this.borderColor = '#4A90E2';
        } else {
            this.backgroundColor = 'white';
            this.textColor = '#333';
            this.borderColor = '#333';
        }
    }
}

export interface NodeCopy {
    title: string;
    body: string;
    bodyOnceUploaded?: string;
}

export interface NodeFormElement {
    type: string;
    name: string;
    placeholder: string;
}

const nodeTypes = {
    NodeVideoWithQuestions: NodeVideoWithQuestions,
    NodePreNeedLeadCollectionForm: NodePreNeedLeadCollectionForm,
    NodeLeadCollectionForm: NodeLeadCollectionForm,
    NodeCustomAIObituaryWriter: NodeCustomAIObituaryWriter,
    NodeArbitraryDataForm: NodeArbitraryDataForm,
    NodeBackdrop: NodeBackdrop
};

function ViewportChangeLogger() {
    useOnViewportChange({
        onStart: useCallback((viewport: Viewport) => console.log('start', viewport), []),
        onChange: useCallback((viewport: Viewport) => console.log('change', viewport), []),
        onEnd: useCallback((viewport: Viewport) => console.log('end', viewport), []),
    });
    return null;
}

export default function InteractiveEmployeeManage() {

    usePageTitle({ title: 'Interactive Employee - Manage', includeDefaultTitle: true });

    const [planAheadIsTopCTA, setPlanAheadIsTopCTA] = useState(false);

    const planAheadNode = useMemo(() => {
        return {
            id: 'node-plan-ahead',
            type: 'NodeVideoWithQuestions',
            targetPosition: 'top',
            position: planAheadIsTopCTA ? {
                "x": 500,
                "y": 0,
            } : {
                "x": 500,
                "y": 210,
            },
            data: {
                targetNodeId: 'node-welcome-video',
                copy: {
                    title: 'Add a Pre-Planning Guide Video',
                    body: 'Enhance engagement by uploading a video that highlights the advantages of pre-planning.',
                    bodyOnceUploaded: 'Your pre-planning video has been uploaded. Review or replace it to ensure it aligns with your brand message.',
                } as NodeCopy,
                ctas: [
                    { label: "I'm Ready to Pre-Plan", handleId: 'a', backgroundColor: '#4A90E2', textColor: 'white', borderColor: '#4A90E2' },
                    { label: "I Have More Questions", handleId: 'b', backgroundColor: 'white', textColor: '#333', borderColor: '#333' },
                ] as NodeCTA[],
            },
            dragHandle: '.hidden-drag-handle'
        }
    }, [planAheadIsTopCTA]);

    const immediateAssistanceNode = useMemo(() => {
        return {
            id: 'node-immediate-assistance-form',
            type: 'NodeArbitraryDataForm',
            targetPosition: 'top',
            position: planAheadIsTopCTA ? {
                "x": 500,
                "y": 470
            } : {
                "x": 500,
                "y": 0,
            },
            data: {
                targetNodeId: 'node-welcome-video',
                copy: {
                    title: 'Immediate Assistance Setup',
                    body: "Configure your at-need phone number:"
                } as NodeCopy,
                formElements: [
                    {
                        type: 'text',
                        name: 'phone_number',
                        placeholder: 'Enter phone number to call...',
                    }
                ] as NodeFormElement[],
            },
            dragHandle: '.hidden-drag-handle'
        }
    }, [planAheadIsTopCTA]);

    const initialNodes = useMemo(() => {

        const planAheadCTA = new NodeCTA("Plan Ahead", "a", {
            isPrimary: planAheadIsTopCTA
        });

        const immediateAssistanceCTA = new NodeCTA("Immediate Help", "b", {
            isPrimary: !planAheadIsTopCTA
        });

        return [
            {
                id: 'node-welcome-video', type: 'NodeVideoWithQuestions', position: { x: 0, y: 0 },
                data: {
                    copy: {
                        title: 'Customize Your Welcome Message',
                        body: 'Upload a welcoming video that resonates with your brand and serves as an introduction to your services.',
                        bodyOnceUploaded: 'Your welcome video has been uploaded. Review or replace it to ensure it aligns with your brand message.',
                    } as NodeCopy,
                    ctas: [
                        planAheadIsTopCTA ? planAheadCTA : immediateAssistanceCTA,
                        !planAheadIsTopCTA ? planAheadCTA : immediateAssistanceCTA,
                        { label: 'Something Else', handleId: 'c', backgroundColor: 'white', textColor: '#333', borderColor: '#333' },
                    ] as NodeCTA[],
                },
                dragHandle: '.hidden-drag-handle'
            },
            planAheadIsTopCTA ? planAheadNode : immediateAssistanceNode,
            !planAheadIsTopCTA ? planAheadNode : immediateAssistanceNode,
            {
                id: 'node-additional-services-video',
                type: 'NodeVideoWithQuestions',
                targetPosition: 'top',
                position: {
                    "x": 500,
                    "y": 690,
                },
                data: {
                    targetNodeId: 'node-welcome-video',
                    copy: {
                        title: 'Additional Services',
                        body: "Address any additional inquiries or services here. Customize the content to guide visitors through other offerings or general questions they might have.",
                        bodyOnceUploaded: 'Your additional services video has been uploaded. Review or replace it to ensure it aligns with your brand message.',
                    } as NodeCopy,
                    ctas: [
                        { label: "Recent Obituaries", handleId: 'a', backgroundColor: '#4A90E2', textColor: 'white', borderColor: '#4A90E2' },
                        { label: "Sympathy Store", handleId: 'b', backgroundColor: 'white', textColor: '#333', borderColor: '#333' },
                        { label: "Send Flowers", handleId: 'c', backgroundColor: 'white', textColor: '#333', borderColor: '#333' },
                        { label: "More Options", handleId: 'd', backgroundColor: 'white', textColor: '#333', borderColor: '#333' },
                    ] as NodeCTA[],
                },
                dragHandle: '.hidden-drag-handle'
            },
            {
                id: 'node-pre-planning-redirect',
                type: 'NodeArbitraryDataForm',
                targetPosition: 'top',
                position: {
                    "x": 1014,
                    "y": 108
                },
                data: {
                    targetNodeId: 'node-plan-ahead',
                    copy: {
                        title: 'Online Pre-Planning Redirect',
                        body: "Redirect visitors directly to your online pre-planning or arrangements portal. Simply input the URL and customize the redirect message to seamlessly guide them."
                    } as NodeCopy,
                    formElements: [
                        {
                            type: 'text',
                            name: 'redirect_uri',
                            placeholder: 'Enter URL...',
                        }
                    ] as NodeFormElement[],
                },
                dragHandle: '.hidden-drag-handle'
            },
            {
                id: 'node-pre-need-lead-collection-form',
                type: 'NodePreNeedLeadCollectionForm',
                targetPosition: 'top',
                position: {
                    "x": 1014,
                    "y": 344
                },
                data: {
                    targetNodeId: 'node-plan-ahead',
                    copy: {
                        title: 'Pre-Need Lead Collection Form',
                        body: "Collect details from visitors who want to know more. Name, phone number, and email address will be collected.",
                    } as NodeCopy,
                },
                dragHandle: '.hidden-drag-handle'
            },
            {
                id: 'node-redirect-to-obituaries',
                type: 'NodeArbitraryDataForm',
                targetPosition: 'top',
                position: {
                    "x": 970,
                    "y": 630,
                },
                data: {
                    targetNodeId: 'node-additional-services-video',
                    copy: {
                        title: 'Redirect to Obituaries Page',
                        body: "Drive more traffic to your obituaries and tributes listing."
                    } as NodeCopy,
                    formElements: [
                        {
                            type: 'text',
                            name: 'redirect_uri',
                            placeholder: 'Enter URL...',
                        }
                    ] as NodeFormElement[],
                },
                dragHandle: '.hidden-drag-handle'
            },
            {
                id: 'node-redirect-to-sympathy-store',
                type: 'NodeArbitraryDataForm',
                targetPosition: 'top',
                position: {
                    "x": 970,
                    "y": 850,
                },
                data: {
                    targetNodeId: 'node-additional-services-video',
                    copy: {
                        title: 'Urns, Jewelry, and Keepsakes Redirect',
                        body: "Guide visitors directly to your sympathy store."
                    } as NodeCopy,
                    formElements: [
                        {
                            type: 'text',
                            name: 'redirect_uri',
                            placeholder: 'Enter URL...',
                        }
                    ] as NodeFormElement[],
                },
                dragHandle: '.hidden-drag-handle'
            },
            {
                id: 'node-redirect-to-flowers-store',
                type: 'NodeArbitraryDataForm',
                targetPosition: 'top',
                position: {
                    "x": 970,
                    "y": 1070,
                },
                data: {
                    targetNodeId: 'node-additional-services-video',
                    copy: {
                        title: 'Send Flowers Redirect',
                        body: "Guide visitors directly to your flowers store."
                    } as NodeCopy,
                    formElements: [
                        {
                            type: 'text',
                            name: 'redirect_uri',
                            placeholder: 'Enter URL...',
                        }
                    ] as NodeFormElement[],
                },
                dragHandle: '.hidden-drag-handle'
            },
            {
                id: 'node-additional-offerings-video',
                type: 'NodeVideoWithQuestions',
                targetPosition: 'top',
                position: {
                    "x": 970,
                    "y": 1300,
                },
                data: {
                    targetNodeId: 'node-additional-services-video',
                    copy: {
                        title: 'Showcase Additional Offerings',
                        body: "Upload a video to highlight unique services or messages. Set yourself apart and maximize client engagement.",
                        bodyOnceUploaded: 'Your additional offerings video has been uploaded. Review or replace it to ensure it aligns with your brand message.',
                    } as NodeCopy,
                    ctas: [
                        { label: "Write Obituary", handleId: 'a', backgroundColor: '#4A90E2', textColor: 'white', borderColor: '#4A90E2' },
                        { label: "Resources", handleId: 'b', backgroundColor: 'white', textColor: '#333', borderColor: '#333' },
                        { label: "Contact Us", handleId: 'c', backgroundColor: 'white', textColor: '#333', borderColor: '#333' },
                    ] as NodeCTA[],
                },
                dragHandle: '.hidden-drag-handle'
            },
            {
                id: 'node-open-ai-obituary-writer',
                type: 'NodeCustomAIObituaryWriter',
                targetPosition: 'top',
                position: {
                    "x": 1536,
                    "y": 876
                },
                data: {
                    targetNodeId: 'node-additional-services-video',
                    copy: {
                        title: 'AI Obituary Writer',
                        body: "Direct clients to an intuitive AI-driven obituary writer. Simplify the process, ensuring a meaningful tribute every time."
                    } as NodeCopy,
                },
                dragHandle: '.hidden-drag-handle'
            },
            {
                id: 'node-redirect-to-resources',
                type: 'NodeArbitraryDataForm',
                targetPosition: 'top',
                position: {
                    "x": 1536,
                    "y": 1138
                },
                data: {
                    targetNodeId: 'node-plan-ahead',
                    copy: {
                        title: 'Guidance & Inspiration',
                        body: "Point clients to a curated selection of resources and tips, enhancing their experience and ensuring they have all the information at their fingertips.",
                    } as NodeCopy,
                    formElements: [
                        {
                            type: 'text',
                            name: 'redirect_uri',
                            placeholder: 'Enter URL...',
                        }
                    ] as NodeFormElement[],
                },
                dragHandle: '.hidden-drag-handle'
            },
            {
                id: 'node-lead-collection-form',
                type: 'NodeLeadCollectionForm',
                targetPosition: 'top',
                position: {
                    "x": 1536,
                    "y": 1400
                },
                data: {
                    targetNodeId: 'node-plan-ahead',
                    copy: {
                        title: 'Lead Collection Form',
                        body: "Collect details from visitors who want to know more. Name, phone number, and email address will be collected.",
                    } as NodeCopy,
                },
                dragHandle: '.hidden-drag-handle'
            },
        ]
    }, [immediateAssistanceNode, planAheadIsTopCTA, planAheadNode]);

    const initialEdges = [
        {
            id: 'Welcome -> Plan Ahead',
            label: '',
            source: 'node-welcome-video', target: 'node-plan-ahead', sourceHandle: 'a',
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
                color: '#b1b1b7',
            },
            style: {
                strokeWidth: 2,
                stroke: '#b1b1b7',
            },
        },
        {
            id: 'Welcome -> Immediate Assistance',
            label: '',
            source: 'node-welcome-video', target: 'node-immediate-assistance-form', sourceHandle: 'b',
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
                color: '#b1b1b7',
            },
            style: {
                strokeWidth: 2,
                stroke: '#b1b1b7',
            },
        },
        {
            id: 'Welcome -> Something Else',
            label: '',
            source: 'node-welcome-video', target: 'node-additional-services-video', sourceHandle: 'c',
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
                color: '#b1b1b7',
            },
            style: {
                strokeWidth: 2,
                stroke: '#b1b1b7',
            },
        },
        {
            id: 'Plan Ahead -> Pre-Planning Redirect',
            label: '',
            source: 'node-plan-ahead', target: 'node-pre-planning-redirect', sourceHandle: 'a',
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
                color: '#b1b1b7',
            },
            style: {
                strokeWidth: 2,
                stroke: '#b1b1b7',
            },
        },
        {
            id: 'Plan Ahead -> Lead Collection Form',
            label: '',
            source: 'node-plan-ahead', target: 'node-pre-need-lead-collection-form', sourceHandle: 'b',
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
                color: '#b1b1b7',
            },
            style: {
                strokeWidth: 2,
                stroke: '#b1b1b7',
            },
        },
        {
            id: 'Additional Services -> Navigate to Obituaries List',
            label: '',
            source: 'node-additional-services-video', target: 'node-redirect-to-obituaries', sourceHandle: 'a',
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
                color: '#b1b1b7',
            },
            style: {
                strokeWidth: 2,
                stroke: '#b1b1b7',
            },
        },
        {
            id: 'Additional Services -> Navigate to Sympathy Store',
            label: '',
            source: 'node-additional-services-video', target: 'node-redirect-to-sympathy-store', sourceHandle: 'b',
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
                color: '#b1b1b7',
            },
            style: {
                strokeWidth: 2,
                stroke: '#b1b1b7',
            },
        },
        {
            id: 'Additional Services -> Navigate to Flowers Store',
            label: '',
            source: 'node-additional-services-video', target: 'node-redirect-to-flowers-store', sourceHandle: 'c',
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
                color: '#b1b1b7',
            },
            style: {
                strokeWidth: 2,
                stroke: '#b1b1b7',
            },
        },
        {
            id: 'Additional Services -> Something Else',
            label: '',
            source: 'node-additional-services-video', target: 'node-additional-offerings-video', sourceHandle: 'd',
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
                color: '#b1b1b7',
            },
            style: {
                strokeWidth: 2,
                stroke: '#b1b1b7',
            },
        },
        {
            id: 'Additional Offerings -> Write an Obituary',
            label: '',
            source: 'node-additional-offerings-video', target: 'node-open-ai-obituary-writer', sourceHandle: 'a',
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
                color: '#b1b1b7',
            },
            style: {
                strokeWidth: 2,
                stroke: '#b1b1b7',
            },
        },
        {
            id: 'Additional Offerings -> Ideas and Resources',
            label: '',
            source: 'node-additional-offerings-video',
            target: 'node-redirect-to-resources', sourceHandle: 'b',
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
                color: '#b1b1b7',
            },
            style: {
                strokeWidth: 2,
                stroke: '#b1b1b7',
            },
        },
        {
            id: 'Additional Offerings -> Lead Collection Form',
            label: '',
            source: 'node-additional-offerings-video', target: 'node-lead-collection-form', sourceHandle: 'c',
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
                color: '#b1b1b7',
            },
            style: {
                strokeWidth: 2,
                stroke: '#b1b1b7',
            },
        },
    ];

    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);

    const [nodes, setNodes] = useState<Node[]>(initialNodes as Node[]);
    const [edges, setEdges] = useState<Edge[]>(initialEdges as Edge[]);

    const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const beginRecordingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const [activeUserMediaStream, setActiveUserMediaStream] = useState<MediaStream | null>(null);

    const [recordingCountdown, setRecordingCountdown] = useState(5);
    const [recordingIsInProgress, setRecordingIsInProgress] = useState(false);

    const [actualRecordingHasBegun, setActualRecordingHasBegun] = useState(false);

    const [activeTemporaryVideoUrl, setActiveTemporaryVideoUrl] = useState('');
    const [activeVideoBlob, setActiveVideoBlob] = useState<Blob | null>(null);

    const [uploadingIsInProgress, setUploadingIsInProgress] = useState(false);

    const [errorUploading, setErrorUploading] = useState<string | null>(null);

    const [shouldSkipCountdown, setShouldSkipCountdown] = useState(false);

    const [activelyRecordingNodeId, setActivelyRecordingNodeId] = useState<string | null>(null);

    // For validation errors right now, but maybe more one day

    const [highlightedNodeId, setHighlightedNodeId] = useState<string | null>(null);

    const onNodesChange = useCallback(
        (changes: NodeChange[]) => {
            setNodes((nds) => applyNodeChanges(changes, nds));
        },
        [setNodes]
    );

    const onEdgesChange = useCallback(
        (changes: EdgeChange[]) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );

    const onConnect = useCallback(
        (connection: Connection | Edge) => setEdges((eds) => addEdge(connection, eds)),
        [setEdges]
    );

    const handleBeginRecording = useCallback((nodeId: string, skipCountdown = false) => {

        setActivelyRecordingNodeId(nodeId);
        setRecordingIsInProgress(true);
        if (!skipCountdown) {
            setRecordingCountdown(5);
        } else {
            setRecordingCountdown(-1);
        }
        setActiveTemporaryVideoUrl('');
        setActiveVideoBlob(null);

        if (!skipCountdown) {

            if (countdownIntervalRef.current) {
                clearInterval(countdownIntervalRef.current);
            }

            if (beginRecordingTimeoutRef.current) {
                clearTimeout(beginRecordingTimeoutRef.current);
            }

            countdownIntervalRef.current = setInterval(() => {
                setRecordingCountdown(prev => prev - 1);
            }, 1000);

            beginRecordingTimeoutRef.current = setTimeout(() => {
                if (countdownIntervalRef.current) {
                    clearInterval(countdownIntervalRef.current);
                }
                setActualRecordingHasBegun(true);
            }, 5000);
        } else {
            setTimeout(() => {
                setActualRecordingHasBegun(true);
            }, 100);
        }

        return () => {
            if (countdownIntervalRef.current) {
                clearInterval(countdownIntervalRef.current);
            }
            if (beginRecordingTimeoutRef.current) {
                clearTimeout(beginRecordingTimeoutRef.current);
            }
        }

    }, []);

    const onClickedUpload = useCallback(async () => {
        if (!activeVideoBlob) {
            setErrorUploading('No video to upload')
            return;
        }
        if (!activelyRecordingNodeId) {
            setErrorUploading('No actively recording node id')
            return;
        }
        setUploadingIsInProgress(true);
        setErrorUploading(null)
        try {
            const result = await createJourneyPersonalization(activelyRecordingNodeId, activeVideoBlob)
            // Need to set nodes and apply changes and update the node data with the new journey personalization id.
            setNodes((nds) => {
                return nds.map(nd => {
                    if (nd.id === activelyRecordingNodeId) {
                        return {
                            ...nd,
                            data: {
                                ...nd.data,
                                journeyPersonalization: result,
                            }
                        }
                    }
                    return nd;
                })
            })
            // Need to reset the recording state
            setRecordingIsInProgress(false);
            setActualRecordingHasBegun(false);
            setActiveTemporaryVideoUrl('');
            setActiveVideoBlob(null);
            if (countdownIntervalRef.current) {
                clearInterval(countdownIntervalRef.current);
            }
            if (beginRecordingTimeoutRef.current) {
                clearTimeout(beginRecordingTimeoutRef.current);
            }
            setUploadingIsInProgress(false);
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof ApiError ? exception.message : 'Something went wrong'
            setErrorUploading(`Upload error: ${errorMessage}`)
            setUploadingIsInProgress(false)
        }
    }, [activeVideoBlob, activelyRecordingNodeId]);

    return (
        <>
            <ReactFlowContainer $hasHighlightedNode={!!highlightedNodeId}>
                <ReactFlow
                    nodes={nodes.map(node => {
                        return {
                            ...node,
                            selected: highlightedNodeId === node.id,
                            data: {
                                ...node.data,
                                handleBeginRecording,
                                recordingIsInProgress,
                                activeUserMediaStream,
                                setActiveUserMediaStream,
                                highlightedNodeId
                            },
                        } as Node;
                    }).concat(!highlightedNodeId ? [] : [{
                        id: 'node-backdrop',
                        type: 'NodeBackdrop', position: { x: -3000, y: -3000 },
                        data: {
                            setHighlightedNodeId
                        },
                        dragHandle: '.hidden-drag-handle'
                    }])}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    onNodeDragStop={(evt, node) => {
                        console.log('drag stop', node.position);
                    }}
                    fitView={false}
                    defaultViewport={{
                        "x": 160,
                        "y": 80,
                        "zoom": 1,
                    }}
                    onInit={setReactFlowInstance}
                    nodesDraggable={!!(true && 'maybeDisabledOneDayButWeAreUsingThe .hidden-drag-handle')}
                    nodesConnectable={false}
                    zoomOnScroll
                    zoomOnDoubleClick
                    zoomOnPinch
                    panOnDrag
                >
                    {false && <MiniMap />}
                    {!highlightedNodeId && <Controls
                        showInteractive={false}
                    />}
                    <Background />
                    {false && <ViewportChangeLogger />}
                </ReactFlow>
                {reactFlowInstance && <InteractiveEmployeeActions reactFlowInstance={reactFlowInstance} setHighlightedNodeId={setHighlightedNodeId} />}
            </ReactFlowContainer>
            {recordingIsInProgress && recordingCountdown > 0 && <RecordingFixedContainer>
                <RecordingInProgressContainer>
                    <div className='text-center'>
                        <div>Recording in...</div>
                        <div className='fw-bold'>{recordingCountdown}</div>
                    </div>
                </RecordingInProgressContainer>
                <PersonalizeRecordingActionsAsDisabledItems onCanceled={() => {
                    setRecordingIsInProgress(false);
                    setActualRecordingHasBegun(false);
                    setActiveTemporaryVideoUrl('');
                    setActiveVideoBlob(null);
                    if (countdownIntervalRef.current) {
                        clearInterval(countdownIntervalRef.current);
                    }
                    if (beginRecordingTimeoutRef.current) {
                        clearTimeout(beginRecordingTimeoutRef.current);
                    }
                }} />
            </RecordingFixedContainer>}
            {actualRecordingHasBegun && activeUserMediaStream && <RecordingFixedContainer>
                <WebcamContainer>
                    {!activeTemporaryVideoUrl ?
                        <Webcam mirrored /> :
                        <video src={activeTemporaryVideoUrl} autoPlay loop={false} muted={false} controls style={{ width: '100%', height: '100%' }} />
                    }
                </WebcamContainer>
                <PersonalizeRecordingActions
                    userMediaStream={activeUserMediaStream}
                    onCanceled={() => {
                        setRecordingIsInProgress(false);
                        setActualRecordingHasBegun(false);
                        setActiveTemporaryVideoUrl('');
                        setActiveVideoBlob(null);
                    }}
                    onReRecord={() => {
                        setActiveTemporaryVideoUrl('');
                        setActiveVideoBlob(null);
                        // This resets the whole component so that we can do another count-down
                        // and also clear any existing video chunks
                        setActualRecordingHasBegun(false);
                        if (activelyRecordingNodeId) {
                            handleBeginRecording(activelyRecordingNodeId, shouldSkipCountdown);
                        } else {
                            // Should never happen...
                            alert('No actively recording node id')
                        }
                    }}
                    onReceivedVideoUrl={({
                        url: videoUrl,
                        blob: videoBlob,
                    }) => {
                        setActiveTemporaryVideoUrl(videoUrl);
                        setActiveVideoBlob(videoBlob);
                    }}
                    uploading={uploadingIsInProgress}
                    onClickedUpload={onClickedUpload}
                    errorUploading={errorUploading}
                    shouldSkipCountdown={shouldSkipCountdown}
                    setShouldSkipCountdown={setShouldSkipCountdown}
                />
            </RecordingFixedContainer>}
        </>
    );
}
