import {Delta} from 'quill'

// not technically a hook but here so it's easier to find in the future.

// This function is used to handle pasting into the quill editor.
// It also seems to be used for handling the initial value of the editor.

function handlePaste(node: Node, delta: Delta): Delta {
  if (!delta.ops) {
    return delta
  }
  delta.ops = delta.ops.map((op) => {
    if (op.insert && typeof op.insert === 'string') {
      // Removing all HTML tags and keeping only plain text with line breaks
      return {insert: op.insert.replace(/<.*?>/g, '').replace(/&nbsp;/g, ' ')}
    }
    return op
  })
  return delta
}

export default handlePaste
