import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import Webcam from 'react-webcam';
import styled from 'styled-components'
import axios from 'axios';

import PersonalizeRecordingActions, { PersonalizeRecordingActionsAsDisabledItems } from './PersonalizeRecordingActions';
import { ApiError } from '../../auth';
import { createJourneyPersonalization } from '../../../funeral_homes/dashboard/core/_requests';
import { useAuth } from '../../auth/core/Auth';

// Container has 2 columns, where both options fill entirely

export const StyledSelectOptionContainer = styled.div`
    height: 240px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

// A large rounded square with centered content
// On hover the background is a pleasing light grey
export const StyledSelectOption = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #EFEFEF;
    overflow: hidden;

    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: rgba(35, 40, 51, 0.06);
    }
`;

// PrimaryStyledSelectOption has a pleasing primary blue color

export const PrimaryStyledSelectOption = styled(StyledSelectOption)`
    border: 1px solid #009ef7;
    position: relative;

    :after {
        content: 'Recommended';
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        background-color: #009ef7;
        color: white;
        padding: 4px 8px;
        border-radius: 0 0 8px 0;
    }
`;

const WebcamRecordingContainer = styled.div<{ $recordingInProgress: boolean }>`
    position: ${props => props.$recordingInProgress ? 'fixed' : 'relative'};
    top: ${props => props.$recordingInProgress ? '0' : 'auto'};
    right: ${props => props.$recordingInProgress ? '0' : 'auto'};
    bottom: ${props => props.$recordingInProgress ? '0' : 'auto'};
    left: ${props => props.$recordingInProgress ? '0' : 'auto'};
    background-color: ${props => props.$recordingInProgress ? 'rgba(0, 0, 0, 0.8)' : 'transparent'};
    z-index: ${props => props.$recordingInProgress ? '99999999' : 'auto'};
`;

const WebcamOuterContainer = styled.div<{ $recordingInProgress: boolean }>`
    background-color: #f8f9fa;
    position: relative;
    border-radius: 8px;

    width: ${props => props.$recordingInProgress ? '80vw' : '480px'};
    height: ${props => props.$recordingInProgress ? '80vh' : '270px'};
`;

const WebcamContainer = styled.div<{ $recordingInProgress: boolean }>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    border-radius: 8px;

    /* overflow must be visible while recording because of the actions below the webcam */
    overflow: ${props => !props.$recordingInProgress ? 'hidden' : 'visible'};
`;

const WebcamContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const PreviewContainer = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: rgba(35, 40, 51, 0.8);
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    font-weight: 600;

    .red-circle {
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 50%;
        margin-right: 8px;
    }
`;

const InstructionsContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    button {
        background-color: white !important;

        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: #f8f9fa !important;
        }
    }
`;

const RecordingInProgressContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    font-weight: 600;
    border-radius: 8px;
    overflow: hidden;
`;

function PersonalizeWelcomeVideoRecord({ basePath }: { basePath: string }) {

    const webcamRef = useRef<Webcam>(null);

    const navigate = useNavigate();

    const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const beginRecordingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const [videoAndAudioHaveBeenEnabled, setVideoAndAudioHaveBeenEnabled] = useState(false);
    const [activeUserMediaStream, setActiveUserMediaStream] = useState<MediaStream | null>(null);

    const [videoIsActive, setVideoIsActive] = useState(false);
    const [audioIsActive, setAudioIsActive] = useState(false);

    const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState('');
    const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useState('');

    const [availableVideoDevices, setAvailableVideoDevices] = useState<MediaDeviceInfo[]>([]);
    const [availableAudioDevices, setAvailableAudioDevices] = useState<MediaDeviceInfo[]>([]);

    const [recordingCountdown, setRecordingCountdown] = useState(5);
    const [recordingIsInProgress, setRecordingIsInProgress] = useState(false);

    const [actualRecordingHasBegun, setActualRecordingHasBegun] = useState(false);

    const [activeTemporaryVideoUrl, setActiveTemporaryVideoUrl] = useState('');
    const [activeVideoBlob, setActiveVideoBlob] = useState<Blob | null>(null);

    const [uploadingIsInProgress, setUploadingIsInProgress] = useState(false);

    const [errorUploading, setErrorUploading] = useState<string | null>(null);

    const [shouldSkipCountdown, setShouldSkipCountdown] = useState(false);

    const fetchDevices = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        const audioDevices = devices.filter(device => device.kind === 'audioinput');
        setAvailableVideoDevices(videoDevices);
        setAvailableAudioDevices(audioDevices);
    };

    useEffect(() => {
        // Initial fetch
        fetchDevices();

        // Listen for changes
        navigator.mediaDevices.addEventListener('devicechange', fetchDevices);

        return () => {
            // Cleanup
            navigator.mediaDevices.removeEventListener('devicechange', fetchDevices);
        };
    }, []);

    const handleStartPreview = useCallback(() => {
        setVideoAndAudioHaveBeenEnabled(true);
    }, []);

    const handleBeginRecording = useCallback((skipCountdown = false) => {

        setRecordingIsInProgress(true);
        if (!skipCountdown) {
            setRecordingCountdown(5);
        } else {
            setRecordingCountdown(-1);
        }
        setActiveTemporaryVideoUrl('');
        setActiveVideoBlob(null);

        if (!skipCountdown) {

            if (countdownIntervalRef.current) {
                clearInterval(countdownIntervalRef.current);
            }

            if (beginRecordingTimeoutRef.current) {
                clearTimeout(beginRecordingTimeoutRef.current);
            }

            countdownIntervalRef.current = setInterval(() => {
                setRecordingCountdown(prev => prev - 1);
            }, 1000);

            beginRecordingTimeoutRef.current = setTimeout(() => {
                if (countdownIntervalRef.current) {
                    clearInterval(countdownIntervalRef.current);
                }
                setActualRecordingHasBegun(true);
            }, 5000);
        } else {
            setTimeout(() => {
                setActualRecordingHasBegun(true);
            }, 100);
        }

        return () => {
            if (countdownIntervalRef.current) {
                clearInterval(countdownIntervalRef.current);
            }
            if (beginRecordingTimeoutRef.current) {
                clearTimeout(beginRecordingTimeoutRef.current);
            }
        }

    }, []);

    const onClickedUpload = useCallback(async () => {
        if (!activeVideoBlob) {
            setErrorUploading('No video to upload')
            return;
        }
        setUploadingIsInProgress(true);
        setErrorUploading(null)
        try {
            const result = await createJourneyPersonalization('welcome-video', activeVideoBlob)
            navigate(`${basePath}/customize/${result.unique_identifier}`)
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof ApiError ? exception.message : 'Something went wrong'
            setErrorUploading(`Upload error: ${errorMessage}`)
            setUploadingIsInProgress(false)
        }
    }, [activeVideoBlob, basePath, navigate]);

    return <div>
        <h2 className='mb-8'>
            Record Welcome Video
        </h2>
        {!videoAndAudioHaveBeenEnabled ? <div role='alert' className='alert alert-info mt-4 fs-4'>
            Click 'Turn on Camera & Mic' to activate your camera and see a preview. You'll be able to record your welcome message after you click 'Begin Recording.'
        </div> : <div role='alert' className='alert alert-info mt-4 fs-4'>
            After you click 'Begin Recording,' a 5-second countdown will start. Aim for a welcome message that's concise yet engaging, lasting 1-2 minutes. Feel free to record multiple takes to capture your best message.
        </div>}
        <WebcamRecordingContainer $recordingInProgress={recordingIsInProgress}>
            <WebcamOuterContainer className='mt-8 mx-auto' $recordingInProgress={recordingIsInProgress}>
                <InstructionsContent>
                    <button
                        className='btn btn-outline btn-outline-primary' style={{ border: '1px solid #009ef7' }}
                        type='button'
                        onClick={() => {
                            handleStartPreview();
                        }}>
                        Turn on Camera & Mic
                    </button>
                    <div className='mt-8 text-center text-gray-600 px-16 mx-8'>
                        Please allow the browser to access your <strong>camera</strong> (480P or higher resolution) and <strong>microphone</strong>
                    </div>
                </InstructionsContent>
                {videoAndAudioHaveBeenEnabled && <WebcamContainer $recordingInProgress={recordingIsInProgress}>
                    <WebcamContent>
                        <Webcam
                            ref={webcamRef}
                            audio={true}
                            muted={true}
                            screenshotFormat="image/jpeg"
                            height={'100%'}
                            width={'100%'}
                            videoConstraints={{
                                deviceId: selectedVideoDeviceId ? { exact: selectedVideoDeviceId } : undefined,
                                width: 1280,
                                height: 720,
                                facingMode: "environment",
                            }}
                            audioConstraints={{
                                deviceId: selectedAudioDeviceId ? { exact: selectedAudioDeviceId } : undefined,
                                noiseSuppression: true,
                                echoCancellation: true,
                            }}
                            mirrored={true}
                            onUserMedia={(stream) => {
                                setActiveUserMediaStream(stream);
                                // Todo: check video chunks and audio chunks
                                setVideoIsActive(true);
                                setAudioIsActive(true);
                            }}
                        />
                        {activeUserMediaStream && !recordingIsInProgress && <PreviewContainer>
                            <span className='red-circle'></span>
                            Preview
                        </PreviewContainer>}
                    </WebcamContent>
                </WebcamContainer>}
                {recordingIsInProgress && recordingCountdown > 0 && <>
                    <RecordingInProgressContainer>
                        <div className='text-center'>
                            <div>Recording in...</div>
                            <div className='fw-bold'>{recordingCountdown}</div>
                        </div>
                    </RecordingInProgressContainer>
                    <PersonalizeRecordingActionsAsDisabledItems onCanceled={() => {
                        setRecordingIsInProgress(false);
                        setActualRecordingHasBegun(false);
                        setActiveTemporaryVideoUrl('');
                        setActiveVideoBlob(null);
                        if (countdownIntervalRef.current) {
                            clearInterval(countdownIntervalRef.current);
                        }
                        if (beginRecordingTimeoutRef.current) {
                            clearTimeout(beginRecordingTimeoutRef.current);
                        }
                    }} />
                </>}
                {actualRecordingHasBegun && activeUserMediaStream && <PersonalizeRecordingActions
                    userMediaStream={activeUserMediaStream}
                    onCanceled={() => {
                        setRecordingIsInProgress(false);
                        setActualRecordingHasBegun(false);
                        setActiveTemporaryVideoUrl('');
                        setActiveVideoBlob(null);
                    }}
                    onReRecord={() => {
                        setActiveTemporaryVideoUrl('');
                        setActiveVideoBlob(null);
                        // This resets the whole component so that we can do another count-down
                        // and also clear any existing video chunks
                        setActualRecordingHasBegun(false);
                        handleBeginRecording(shouldSkipCountdown);
                    }}
                    onReceivedVideoUrl={({
                        url: videoUrl,
                        blob: videoBlob,
                    }) => {
                        setActiveTemporaryVideoUrl(videoUrl);
                        setActiveVideoBlob(videoBlob);
                    }}
                    uploading={uploadingIsInProgress}
                    onClickedUpload={onClickedUpload}
                    errorUploading={errorUploading}
                    shouldSkipCountdown={shouldSkipCountdown}
                    setShouldSkipCountdown={setShouldSkipCountdown}
                />}
                {activeTemporaryVideoUrl && <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 1 }}>
                    <video style={{
                        width: '100%',
                        height: '100%',
                    }} src={activeTemporaryVideoUrl} controls autoPlay={true} loop={false} />
                </div>}
            </WebcamOuterContainer>
        </WebcamRecordingContainer>
        {activeUserMediaStream && <div className='mt-8'>
            <div className='d-flex align-items-center'>
                <select className='me-2 form-select form-select-solid form-select-lg'
                    onChange={e => setSelectedVideoDeviceId(e.target.value)}>
                    {availableVideoDevices.map((device) => {
                        return <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
                    })}
                </select>
                <select className='ms-2 form-select form-select-solid form-select-lg'
                    onChange={e => setSelectedAudioDeviceId(e.target.value)}>
                    {availableAudioDevices.map((device) => {
                        return <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
                    })}
                </select>
            </div>
        </div>}
        <div className='mt-5 text-center'>
            {!recordingIsInProgress && <div>
                {videoIsActive && audioIsActive ? <button
                    className='btn btn-primary'
                    type='button'
                    onClick={() => {
                        handleBeginRecording();
                    }}
                >
                    Begin Recording
                </button> : <button
                    className='btn btn-primary'
                    type='button'
                    disabled
                >
                    {videoAndAudioHaveBeenEnabled ? <>
                        {activeUserMediaStream ? <>Begin Recording</> : <>Loading Devices...</>}
                    </> : <>
                        Begin Recording
                    </>
                    }
                </button>}
            </div>}
        </div>
    </div>
}

export default PersonalizeWelcomeVideoRecord;