import {ReactNode} from 'react'
import {AlertProvider} from './alertContext'
import PopUpProvider from './popUpContext'

export default function ContextWrapper({children}: {children: ReactNode}) {
  //element to wrap all contexts
  return (
    <>
      <AlertProvider>
        <PopUpProvider>{children}</PopUpProvider>
      </AlertProvider>
    </>
  )
}
