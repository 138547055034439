export interface PoemType {
  value: string
  label: string
  description: string
}

export class PersonalizedPoemData {
  poem_type: keyof typeof availablePoemTypes

  constructor(data: any) {
    this.poem_type = data.poem_type
  }
}

export class PersonalizedPoem {
  unique_identifier: string
  inserted_at: string
  updated_at: string

  text_content: string
  type_of_derivative_work: 'poem' // We may end up abstracting derivative works so this could be useful

  data: PersonalizedPoemData

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at

    this.text_content = data.text_content
    this.type_of_derivative_work = data.type_of_derivative_work

    this.data = new PersonalizedPoemData(data.data || {}) // `data.data` is required and should never be null...
  }
}

export const availablePoemTypes: Array<PoemType> = [
  {
    value: 'acrostic',
    label: 'Acrostic',
    description:
      'A poem where the first letter of each line spells out a word or message, usually read vertically.',
  },
  {
    value: 'free_verse',
    label: 'Free Verse',
    description:
      'A poem without a set rhyme scheme or meter, allowing the poet to have more freedom in their expression.',
  },
  {
    value: 'sonnet',
    label: 'Sonnet',
    description:
      'A 14-line poem with a specific rhyme scheme, often about love or deep emotions. Typically written in iambic pentameter.',
  },
  {
    value: 'haiku',
    label: 'Haiku',
    description:
      'A traditional Japanese poem consisting of three lines, with a 5-7-5 syllable pattern, often focused on nature and the changing seasons.',
  },
  {
    value: 'limerick',
    label: 'Limerick',
    description:
      'A humorous, five-line poem with an AABBA rhyme scheme. The first, second, and fifth lines are longer, while the third and fourth lines are shorter.',
  },
  {
    value: 'cinquain',
    label: 'Cinquain',
    description:
      'A five-line poem with a specific syllable count per line: 2-4-6-8-2. Cinquains do not typically rhyme.',
  },
  {
    value: 'diamante',
    label: 'Diamante',
    description:
      'A seven-line poem shaped like a diamond. It starts with one subject and ends with its opposite, using specific parts of speech and a syllable pattern.',
  },
  {
    value: 'shape_poem',
    label: 'Shape Poem',
    description:
      "A poem that is arranged in a specific shape or pattern on the page, often reflecting the poem's theme.",
  },
  {
    value: 'quatrain',
    label: 'Quatrain',
    description:
      'A four-line stanza or poem, with various possible rhyme schemes, such as AABB, ABAB, or ABBA.',
  },
  {
    value: 'couplet',
    label: 'Couplet',
    description:
      'Two lines of verse that usually rhyme and have the same meter, often expressing a complete thought or idea.',
  },
]
