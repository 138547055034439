import React from 'react'
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { DateTime } from 'luxon'

import { useObituaryDetail } from './components/ObituaryDetail'
import usePageTitle from '../../hooks/usePageTitle'
import {
  getObituaryEmails,
  getObituaryReceivableEmailSuggestion,
  saveReceiveableEmail,
} from './core/_requests'
import { ApiError } from '../auth'
import { ObituaryEmail } from './core/_models'
import useSimpleEmailReceived from '../../obituaries/useSimpleEmailReceived'

interface ClaimEmailFormProps { }

function ClaimEmailForm({ }: ClaimEmailFormProps) {
  const { obituary, setObituary } = useObituaryDetail()

  const [loadingSuggestion, setLoadingSuggestion] = useState(false)
  const [errorLoadingSuggestion, setErrorLoadingSuggestion] = useState<string | null>(null)

  const [savingEmail, setSavingEmail] = useState(false)
  const [errorSavingEmail, setErrorSavingEmail] = useState<string | null>(null)

  const [emailAddress, setEmailAddress] = useState('')

  const emailAddressWithoutPeriods = emailAddress.replace(/\./g, '')

  // Email is invalid is if the value is not empty and has anything that's not a letter, number, dot, or plus

  const emailIsValid = useCallback(() => {
    if (emailAddress === '') {
      return true
    }
    return /^[a-zA-Z0-9.+]+$/.test(emailAddress)
  }, [emailAddress])

  const onFormSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setSavingEmail(true)
      setErrorSavingEmail(null)
      try {
        const { obituary: updatedObituary } = await saveReceiveableEmail(obituary.unique_identifier, {
          email_address: emailAddress,
        })
        setObituary(updatedObituary)
      } catch (error: any) {
        console.warn(error)
        const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
        setErrorSavingEmail(errorMessage)
      } finally {
        setSavingEmail(false)
      }
    },
    [emailAddress, obituary.unique_identifier, setObituary]
  )

  const loadSuggestedEmail = useCallback(async () => {
    setLoadingSuggestion(true)
    try {
      const { suggested_email_address: suggestedEmailAddress } =
        await getObituaryReceivableEmailSuggestion(obituary.unique_identifier)
      setEmailAddress(suggestedEmailAddress)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setErrorLoadingSuggestion(errorMessage)
    } finally {
      setLoadingSuggestion(false)
    }
  }, [obituary.unique_identifier])

  useEffect(() => {
    loadSuggestedEmail()
  }, [loadSuggestedEmail])

  return (
    <>
      {errorLoadingSuggestion && (
        <div className='alert alert-danger'>
          <div className='alert-text'>{errorLoadingSuggestion}</div>
        </div>
      )}
      {errorSavingEmail && (
        <div className='alert alert-danger'>
          <div className='alert-text'>{errorSavingEmail}</div>
        </div>
      )}
      <form onSubmit={onFormSubmit}>
        <div className='form-group row mt-8'>
          <div className='col-12 col-lg-6'>
            <div className='row'>
              <div className='col-12'>
                <label htmlFor='email_address'>Preferred Email Address:</label>
                <div className='mt-4 d-flex'>
                  <input
                    type='text'
                    className='form-control'
                    id='email_address'
                    name='email_address'
                    value={emailAddress}
                    onChange={(event) => setEmailAddress(event.target.value)}
                    autoComplete='off'
                    placeholder='Enter email address...'
                    required
                    maxLength={30}
                  />
                  <span className='ml-2 align-self-center'>@tributes.obituaries.ai</span>
                </div>
              </div>
            </div>
            {!emailIsValid() ? (
              <>
                {/* Just light red error text: */}
                <div className='row mt-4'>
                  <div className='col-12'>
                    <small className='form-text text-danger'>
                      Email address can only contain letters, numbers, dots, and plus signs.
                    </small>
                  </div>
                </div>
              </>
            ) : (
              <>
                {emailAddressWithoutPeriods && emailAddressWithoutPeriods !== emailAddress && (
                  <div className='row mt-4'>
                    <div className='col-12'>
                      {/* Muted text how emails will also be available without periods: */}
                      <small className='form-text text-muted'>
                        Email receiving will also be available at{' '}
                        <strong>{`${emailAddressWithoutPeriods}@tributes.obituaries.ai`}</strong>
                      </small>
                    </div>
                  </div>
                )}
              </>
            )}
            {/* Help text: */}
            <div className='row mt-4'>
              <div className='col-12'>
                <small className='form-text text-muted'>
                  This unique email address is where family and friends can send heartfelt messages
                  and memories of the deceased. While it's recommended to use the name of the
                  departed, you're welcome to choose any meaningful identifier.
                </small>
              </div>
            </div>
            <div className='separator separator-dashed my-10'></div>
            {/* Buttons: */}
            <div className='row mt-4'>
              <div className='col-12'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={savingEmail || loadingSuggestion}
                >
                  {savingEmail ? 'Saving...' : 'Save Preferred Email Address'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

function EmailItem({ email }: { email: ObituaryEmail }) {
  return (
    <div className='col-12 col-lg-6 mb-6'>
      <div className='card card-custom gutter-b card-stretch shadow-sm'>
        <div className='card-body position-relative'>
          <div className='d-flex align-items-center'>
            <div className='d-flex flex-column flex-grow-1'>
              <div className='d-flex align-items-center justify-content-start flex-wrap'>
                <div className='w-75px'>From:</div>
                <a
                  href={`mailto:${email.from_email_address}`}
                  className='text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder'
                >
                  {email.from_email_address}
                </a>
              </div>
              {email.cc_emails && (
                <div className='d-flex align-items-center justify-content-start flex-wrap'>
                  <div className='w-75px'>CC:</div>
                  <div className='text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder'>
                    {email.cc_emails}
                  </div>
                </div>
              )}
              {email.bcc_emails && (
                <div className='d-flex align-items-center justify-content-start flex-wrap'>
                  <div className='w-75px'>BCC:</div>
                  <div className='text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder'>
                    {email.bcc_emails}
                  </div>
                </div>
              )}
              <div className='d-flex align-items-center justify-content-start flex-wrap'>
                <div className='w-75px'>Subject:</div>
                <span className='text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder'>
                  {email.subject}
                </span>
              </div>
              <div className='position-absolute top-0 end-0 p-3'>
                <span
                  className='text-muted font-weight-bold'
                  title={new Date(email.inserted_at).toLocaleString()}
                >
                  {DateTime.fromISO(email.inserted_at).toRelative()}
                </span>
              </div>
            </div>
          </div>
          <div className='separator separator-dashed my-5'></div>
          <div>{email.body || '(no message)'}</div>
        </div>
        {email.attachments.length > 0 && (
          <div className='card-footer'>
            <div className='d-flex align-items-center'>
              <div className='d-flex flex-column flex-grow-1'>
                <div className='d-flex align-items-start justify-content-start flex-wrap flex-column flex-sm-column'>
                  <div className='font-weight-bold mr-2'>Attachments:</div>
                  <div className='mt-3'>
                    <div className='d-flex align-items-center justify-content-start flex-wrap'>
                      {email.attachments.map((attachment) => (
                        <div key={attachment.unique_id} className='me-2'>
                          <a href={attachment.file} target='_blank' rel='noreferrer'>
                            {attachment.original_file_name}
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function ReceivedEmails() {
  const { obituary, obituaryWebsocket } = useObituaryDetail()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const [allEmails, setEmails] = useState<ObituaryEmail[]>([])

  const onLoadEmails = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const updatedItems = await getObituaryEmails(obituary.unique_identifier)
      setEmails(updatedItems)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [obituary.unique_identifier])

  useEffect(() => {
    onLoadEmails()
  }, [onLoadEmails])

  useSimpleEmailReceived(obituaryWebsocket, (newEmail: ObituaryEmail) => {
    setEmails((prevEmails) => [newEmail, ...prevEmails])
  })

  return (
    <>
      <h3>Received Emails:</h3>
      <div className='separator separator-dashed my-3'></div>
      {loading ? (
        <div>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {error ? (
            <>
              <div className='alert alert-danger'>
                <div className='alert-text'>{error}</div>
              </div>
              <div>
                <button type='button' className='btn btn-primary' onClick={onLoadEmails}>
                  Retry
                </button>
              </div>
            </>
          ) : (
            <>
              {allEmails.length === 0 ? (
                <>{<p>(no emails have been received yet)</p>}</>
              ) : (
                <>
                  <div className='row'>
                    {allEmails.map((email) => (
                      <EmailItem key={`email-${email.unique_id}`} email={email} />
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

function AvailableEmails() {
  const { obituary, obituaryWebsocket } = useObituaryDetail()

  const [viewingQrCode, setViewingQrCode] = useState(false)

  if (
    !obituary.active_receivable_email_addresses ||
    obituary.active_receivable_email_addresses.length === 0
  ) {
    // This component should not be used if there are no active receivable email addresses
    console.warn('No active receivable email addresses')
    return null
  }

  const allValidEmailAddresses = [
    ...obituary.active_receivable_email_addresses.map((emailAddress) => [
      emailAddress.email,
      emailAddress.email_without_dots,
      emailAddress.email_without_dots_and_plus,
    ]),
  ]

  const flattenedArray: string[] = allValidEmailAddresses.flat()
  const distinctFlattenedArray = [...new Set(flattenedArray)]

  const emailsAfterFirstItem = distinctFlattenedArray.slice(1)

  return (
    <div className='row  mt-4'>
      <h4 className='col-12'>Active Email Addresses:</h4>
      <div>
        <div>
          <a target='_blank' href={`mailto:${flattenedArray[0]}`} rel='noreferrer'>
            {flattenedArray[0]}
          </a>
          {emailsAfterFirstItem.length > 0 && (
            <>
              <span className='ms-1'>(also available at:</span>
              {emailsAfterFirstItem.map((emailAddress, index) => (
                <span key={emailAddress} className='ms-1'>
                  <a target='_blank' href={`mailto:${emailAddress}`} rel='noreferrer'>
                    {emailAddress}
                  </a>
                  {index === emailsAfterFirstItem.length - 1 ? ')' : ','}
                </span>
              ))}
            </>
          )}
        </div>
        <div
          className='py-3 cursor-pointer'
          onClick={() => {
            setViewingQrCode(!viewingQrCode)
          }}
        >
          <div>
            {viewingQrCode ? (
              <div className='mb-4'>
                <div className='mb-2 fs-1'>Scan this QR code for the email address:</div>
                <QRCode value={`mailto:${flattenedArray[0]}`} size={120} />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div>
            <small className='form-text text-muted'>
              {!viewingQrCode ? '(show QR code)' : '(hide QR code)'}
            </small>
          </div>
        </div>
      </div>
      <div className='separator my-3'></div>
      <div className='col-12'>
        <div className='row mt-4'>
          <div className='col-12'>
            <ReceivedEmails />
          </div>
          {false && 'attachmentsDisabledForNow' && (
            <div className='col-12 col-lg-6'>
              <h3>Received Attachments:</h3>
              <div className='separator separator-dashed my-3'></div>
              <p>(no attachments have been received yet)</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

interface EmailsProps { }

function Emails({ }: EmailsProps) {
  const { obituary, obituaryWebsocket } = useObituaryDetail()

  useEffect(() => {
    if (obituaryWebsocket) {
      obituaryWebsocket.sendPageView('emails')
    }
  }, [obituaryWebsocket])

  usePageTitle({
    title: `${obituary.getSimpleName()}'s Obituary | Emails`,
  })

  return (
    <div>
      {!obituary.active_receivable_email_addresses ||
        obituary.active_receivable_email_addresses.length === 0 ? (
        <>
          <div className='alert alert-info'>
            <div className='alert-text'>
              An email address must be generated below before receiving emails.
            </div>
          </div>
          <ClaimEmailForm />
        </>
      ) : (
        <AvailableEmails />
      )}
    </div>
  )
}

export default Emails
