import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 8000px;
    height: 8000px;
    background: rgba(0,0,0,0.85);
    z-index: 3000;
    cursor: pointer;
`;

function NodeBackdrop({ data }: { id: string, data: any }) {
    return <Container onClick={() => {
        if (!data?.setHighlightedNodeId) {
            alert('No setHighlightedNodeId function found on data');
            return;
        }
        data?.setHighlightedNodeId(null);
    }} />
}

export default NodeBackdrop;