import { Link } from 'react-router-dom'
import { NextButtonContainer, NextButtonContent } from './ShareableLoginForPhone'
import { useShareableObituary } from './ShareableView'
import TitleAndDescriptionItem from './TitleAndDescriptionItem'
import usePageTitle from '../hooks/usePageTitle'

function NavigateButton({ title, to }: { title: string; to: string }) {
  return (
    <NextButtonContainer>
      <NextButtonContent className='container h-100'>
        <div className='row h-100'>
          <div className='col-12 col-md-6 offset-md-3 d-flex justify-content-center align-items-center'>
            <Link className='btn btn-lg btn-secondary w-100' to={to}>
              {title}
            </Link>
          </div>
        </div>
      </NextButtonContent>
    </NextButtonContainer>
  )
}
function Welcome() {
  const { obituary } = useShareableObituary()

  usePageTitle({
    title: obituary.funeral_home ? `Welcome from ${obituary.funeral_home.name}` : 'Welcome',
  })

  // I'm not sure if changing the verbiage to "your" for pre-need is the right idea.
  // Going with it for now though.

  // Maybe we should only do this if a code is texted to the user and we can confirm
  // that the user is the one who was texted the code?

  // Right now until we have "code" or some sort of referral token to know who is accessing this obit
  // We only show "your obituary" if it's likely the person accessing it.

  // We aren't using a code yet at this time in case people share links to obituaries.

  return (
    <div>
      <TitleAndDescriptionItem
        title={
          obituary.isPreNeedObituary() && obituary.data?.preneed_type === 'self'
            ? `Thank you for trusting ${obituary?.funeral_home?.name} with your obituary.`
            : `Thank you for trusting ${obituary?.funeral_home?.name} with ${obituary.getSimpleFirstName()}'s obituary.`
        }
        description={`We understand that this is a difficult time for you and your family. Our goal is to make the obituary completion process as easy and stress-free as possible.\n\nWe're here to guide you through each step and ensure that the obituary accurately reflects the life and legacy of your loved one.\n\nPlease don't hesitate to reach out to us if you have any questions or concerns. We're here to support you every step of the way.`}
      />
      <NavigateButton title='Start' to={`/o/${obituary.shareable_code}/login`} />
    </div>
  )
}

export default Welcome
