import * as Yup from 'yup'

// Personal information

export const personalInformationSchema = Yup.object().shape({
  lovedOnesFullName: Yup.string().required('Required'),
  // everything else is optional:
  maidenNameIfApplicable: Yup.string(),
  nickname: Yup.string(),
  nicknameIsPreferred: Yup.boolean(),
  gender: Yup.string(),
  genderOther: Yup.string(),
  birthDate: Yup.string().nullable(), // YYYY-MM-DD
  placeOfBirth: Yup.string(),
  dateOfDeath: Yup.string().nullable(), // YYYY-MM-DD
  locationOfDeath: Yup.string(),
  cityOfLastResidence: Yup.string(),
  passingInformation: Yup.string(),
})

// Relatives

export const relativesSchema = Yup.object().shape({
  survivors: Yup.string(),
  passedAway: Yup.string(),
})

// Ceremony details

// Ceremony details have an array of "VenueAndDateTimeItem" objects

export const venueAndDateTimeItemSchema = Yup.object().shape({
  itemUniqueId: Yup.string(), // if editing an existing item, this is the id of the item
  venue: Yup.string().nullable(),
  date: Yup.string().nullable(),
  time: Yup.string().nullable(),
})

export const ceremonyDetailsSchema = Yup.object().shape({
  venueDateAndTimeAndSpecialGuidelines: Yup.string(),
  expressCondolences: Yup.string(),
  venueAndDateTimeItems: Yup.array().of(venueAndDateTimeItemSchema),
})

// Education

export const educationSchema = Yup.object().shape({
  schoolsAttended: Yup.string(),
  notedAchievements: Yup.string(),
})

// Career

export const careerSchema = Yup.object().shape({
  careerProfession: Yup.string(),
  notableAchievements: Yup.string(),
})

// Military service

export const militaryServiceSchema = Yup.object().shape({
  militaryService: Yup.string(),
  notableAchievements: Yup.string(),
})

// Affiliations events

export const affiliationsSchema = Yup.object().shape({
  listedAffiliations: Yup.string(),
})

// Life events

export const lifeEventsSchema = Yup.object().shape({
  weddingsOrBirthsOrSignificantEvents: Yup.string(),
})

// Personality and interests

export const personalityAndInterestsSchema = Yup.object().shape({
  descriptionOfLovedOne: Yup.string(),
  favoriteMemories: Yup.string(),
  proudestAccomplishments: Yup.string(),
  hobbiesAndFavoriteThings: Yup.string(),
})
