/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import ReconnectingWebSocket from 'reconnecting-websocket'

import { startRecording, pauseRecording, stopRecording } from './voice_recorder'

import { BaseQuestion, Obituary } from '../core/_models'

import {
  removeObituaryResponse,
  saveObituaryResponse,
  uploadRecordingForObituaryResponse,
  skipObituaryQuestion,
  saveObituaryTranscriptResponse,
} from '../core/_requests'
import { useObituaryDetail } from './ObituaryDetail'
import { useAuth } from '../../auth/core/Auth'

export const QuestionContainer = styled.div`
  .edit-tools {
    display: none;
  }
  &:hover {
    .edit-tools {
      display: block;
    }
  }
}`

interface QuestionRecorderProps {
  autoStart?: boolean
  obituary: Obituary
  question: BaseQuestion
  onSuccess: (updatedQuestion: BaseQuestion) => void
  onCancel: () => void
  onStartedUploading: () => void
}

function QuestionRecorder({
  autoStart,
  obituary,
  question,
  onSuccess,
  onCancel: finalOnCancel,
  onStartedUploading,
}: QuestionRecorderProps) {
  const inputUpdateTranscriptionRef = React.useRef<HTMLTextAreaElement>(null)

  const canvasRef = React.createRef<HTMLCanvasElement>()
  const canvasWidth = 400
  const canvasHeight = 100

  const backgroundColor = 'rgb(200, 200, 200)'
  const foregroundColor = 'rgb(0, 0, 0)'

  const [isPaused, setIsPaused] = useState(false)

  const [audioUrlToDownload, setAudioUrlToDownload] = useState<string | undefined>(undefined)
  const [resultingAudioBlob, setResultingAudioBlob] = useState<Blob | null>(null)

  const [uploadingRecording, setUploadingRecording] = useState(false)

  const [isRecording, setIsRecording] = useState(!!autoStart)
  const [confirmingRemoval, setConfirmingRemoval] = useState(false)

  const [recordingDurationSeconds, setRecordingDurationSeconds] = useState(0)

  const [savingResponse, setSavingResponse] = useState(false)
  const [submissionError, setSubmissionError] = useState('')

  const onaudioprocess = useCallback((e: AudioProcessingEvent, analyser: AnalyserNode) => {
    // Visualize with a waveform
    // see audio-react-recorder for an example
    // I tried to use the library but the waveform never showed up.
    // May have been because of the way I was referencing the canvas context.
  }, [])

  const onStopRecording = () => {
    setIsRecording(false)
    stopRecording({
      onReceivedAudioLink: (result: any) => {
        setResultingAudioBlob(result.resultingBlob)
        setAudioUrlToDownload(result.audioURL)
      },
    })
  }

  const onStopRecordingAndCancel = () => {
    stopRecording()
    finalOnCancel()
  }

  const onStartRecording = useCallback(() => {
    setIsRecording(true)
    setRecordingDurationSeconds(0)
    canvasRef.current?.getContext('2d')?.clearRect(0, 0, canvasWidth, canvasHeight)
    // I think the canvas context only needs to be requested here once.
    // Kinda weird that we're passing it around this way but I think it's ok
    startRecording({
      onaudioprocess,
    })
  }, [canvasRef, onaudioprocess])

  const finishRemovingRecording = useCallback(async () => {
    try {
      const removalResult = await removeObituaryResponse(obituary.unique_identifier, question.unique_id)
      onSuccess(removalResult)
    } catch (e) {
      console.error(e)
    }
  }, [obituary.unique_identifier, onSuccess, question.unique_id])

  const onUploadRecording = useCallback(async () => {
    if (!resultingAudioBlob) {
      return
    }
    setUploadingRecording(true)
    onStartedUploading()
    try {
      const result = await uploadRecordingForObituaryResponse(
        obituary.unique_identifier,
        question.unique_id,
        resultingAudioBlob
      )
      onSuccess(result.updated_question)
    } catch (e) {
      console.error(e)
      setUploadingRecording(false)
    }
  }, [obituary.unique_identifier, onStartedUploading, onSuccess, question.unique_id, resultingAudioBlob])

  const onSaveResponse = useCallback(async () => {
    if (!inputUpdateTranscriptionRef.current) {
      return
    }
    setSavingResponse(true)
    try {
      const obituaryResponseResult = await saveObituaryTranscriptResponse(
        obituary.unique_identifier,
        question.unique_id,
        {
          updated_summary: inputUpdateTranscriptionRef.current.value,
        }
      )
      onSuccess(obituaryResponseResult.updated_question)
    } catch (e) {
      console.error(e)
      setSubmissionError('There was an error updating your answer')
    } finally {
      setSavingResponse(false)
    }
  }, [obituary.unique_identifier, onSuccess, question.unique_id])

  const onFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      onSaveResponse()
    },
    [onSaveResponse]
  )

  useEffect(() => {
    if (autoStart) {
      onStartRecording()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoStart])

  useEffect(() => {
    // Keep track of the duration of the recording (in seconds)
    // and update the UI accordingly
    let recordedSeconds = 0
    let interval: NodeJS.Timeout | undefined
    if (isRecording) {
      interval = setInterval(() => {
        if (!isPaused) {
          // updateDuration(duration + 1)
          recordedSeconds += 1
          setRecordingDurationSeconds(recordedSeconds)
        }
      }, 1000)
    } else {
      if (interval) {
        clearInterval(interval)
      }
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isRecording, isPaused])

  return (
    <div className='mt-5'>
      {false && 'waveFormVisualizerIsNotQuiteWorking' && (
        <canvas
          ref={canvasRef}
          width={canvasWidth}
          height={canvasHeight}
          className='audio-react-recorder__canvas'
        ></canvas>
      )}
      {audioUrlToDownload ? (
        <div>
          <audio src={audioUrlToDownload} controls />
          <div className='mt-5'>
            <button
              type='button'
              onClick={() => {
                onStopRecordingAndCancel()
              }}
              className='btn btn-secondary me-2'
              disabled={uploadingRecording}
            >
              Cancel
            </button>
            <button
              type='button'
              onClick={() => {
                onStartRecording()
                setAudioUrlToDownload(undefined)
                setResultingAudioBlob(null)
              }}
              className='btn btn-danger me-2'
              disabled={uploadingRecording}
            >
              Re-Record
            </button>
            <button
              type='button'
              onClick={() => {
                onUploadRecording()
              }}
              className='btn btn-primary'
              disabled={uploadingRecording}
            >
              {uploadingRecording ? 'Uploading...' : 'Upload'}
            </button>
          </div>
        </div>
      ) : (
        <>
          {isRecording ? (
            <>
              <div>
                <button
                  type='button'
                  onClick={() => {
                    onStopRecordingAndCancel()
                  }}
                  className='btn btn-secondary me-2'
                >
                  Cancel
                </button>
                {false && 'pausingSeemsBuggy' && (
                  <button
                    type='button'
                    onClick={() => {
                      if (isPaused) {
                        onStartRecording()
                      } else {
                        pauseRecording()
                      }
                      setIsPaused(!isPaused)
                    }}
                    className='btn btn-secondary me-2'
                  >
                    {isPaused ? 'Resume' : 'Pause'}
                  </button>
                )}
                <button
                  type='button'
                  onClick={() => {
                    onStopRecording()
                  }}
                  className='btn btn-danger'
                >
                  Stop Recording
                </button>
              </div>
              <div className='mt-2'>
                {`Recording for ${recordingDurationSeconds} second${recordingDurationSeconds === 1 ? '' : 's'
                  }`}
              </div>
            </>
          ) : (
            <>
              {confirmingRemoval ? (
                <>
                  <div className='mb-3'>Are you sure you want to remove this recording?</div>
                  <div>
                    <button
                      type='button'
                      onClick={() => {
                        finishRemovingRecording()
                      }}
                      className='btn btn-danger'
                    >
                      Yes, remove
                    </button>
                    <button
                      type='button'
                      onClick={() => {
                        setConfirmingRemoval(false)
                      }}
                      className='btn btn-secondary ms-2'
                    >
                      No, cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {question.latest_response && (
                    <>
                      {question.latest_response.isTranscriptionInProgress() ? (
                        <>
                          <div>
                            <small className='text-gray-700' style={{ fontSize: 14 }}>
                              Transcription in progress. This may take a few minutes.
                            </small>
                          </div>
                        </>
                      ) : (
                        <form onSubmit={onFormSubmit}>
                          <div>
                            <textarea
                              style={{ fontSize: '1.25rem' }}
                              ref={inputUpdateTranscriptionRef}
                              className='form-control h-250px border-0 resize-none'
                              placeholder='Update audio transcription...'
                              defaultValue={question.latest_response?.audio_response_summary || ''}
                              onChange={(e) => {
                                //
                              }}
                              required
                            />
                          </div>
                          <div className='d-flex flex-wrap mt-5 align-items-center justify-content-between position-relative'>
                            <div>
                              {submissionError && (
                                <div
                                  className='alert alert-danger d-flex align-items-center mb-2'
                                  role='alert'
                                >
                                  <div>{submissionError}</div>
                                </div>
                              )}
                            </div>
                            <button
                              type='submit'
                              className='btn btn-sm btn-primary me-2 mb-2'
                              disabled={savingResponse}
                            >
                              {savingResponse ? `Updating...` : `Update Answer`}
                            </button>
                          </div>
                        </form>
                      )}
                    </>
                  )}
                  <div className='separator separator-dashed my-5'></div>
                  <div>
                    <h5 className='mb-5'>Recording Options:</h5>
                  </div>
                  <div>
                    <button
                      type='button'
                      onClick={() => {
                        finalOnCancel()
                      }}
                      className='btn btn-secondary me-2'
                    >
                      Cancel
                    </button>
                    <button
                      type='button'
                      onClick={() => {
                        setConfirmingRemoval(true)
                      }}
                      className='btn btn-danger me-2'
                    >
                      Remove Recording
                    </button>
                    <button
                      type='button'
                      onClick={() => {
                        onStartRecording()
                      }}
                      className='btn btn-primary'
                    >
                      Restart Recording
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

interface EditToolsProps {
  obituary: Obituary
  question: BaseQuestion
  onSkippedQuestion: (baseQuestion: BaseQuestion) => void
}

function QuestionEditTools({ obituary, question, onSkippedQuestion }: EditToolsProps) {
  const [confirmingSkip, setConfirmingSkip] = useState(false)

  const finishSkippingQuestion = useCallback(async () => {
    try {
      await skipObituaryQuestion(obituary.unique_identifier, question.unique_id)
      onSkippedQuestion(question)
    } catch (e) {
      console.error(e)
    }
  }, [obituary.unique_identifier, onSkippedQuestion, question])

  return (
    <div
      className='edit-tools position-absolute bottom-0 end-0 p-2 rounded'
      style={{ backgroundColor: 'white' }}
    >
      {confirmingSkip ? (
        <div>
          <div>Are you sure you want to skip?</div>
          <div>
            <button
              className='btn btn-danger btn-sm me-1'
              onClick={() => {
                finishSkippingQuestion()
              }}
            >
              Yes
            </button>
            <button
              className='btn btn-secondary btn-sm ms-1'
              onClick={() => {
                setConfirmingSkip(false)
              }}
            >
              No
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className='edit-tools__item'>
            <button
              className='btn btn-sm'
              onClick={() => {
                setConfirmingSkip(true)
              }}
            >
              Skip
            </button>
          </div>
        </>
      )}
    </div>
  )
}

type ObituaryStartingQuestionCardProps = {
  question: BaseQuestion
  setLoadingNewQuestion: (loading: boolean) => void
  onAddedQuestion: (question: BaseQuestion) => void
  onSkippedQuestion: (question: BaseQuestion) => void
  recordingAnswer: boolean
  setRecordingAnswer: (recordingAnswer: boolean) => void
}

const ObituaryQuestionCard: React.FC<ObituaryStartingQuestionCardProps> = ({
  question: defaultQuestion,
  setLoadingNewQuestion,
  onAddedQuestion,
  onSkippedQuestion: finalOnSkippedQuestion,
  recordingAnswer,
  setRecordingAnswer,
}) => {
  const { auth } = useAuth()
  const authToken = auth ? auth.api_token : null

  const { obituary, setObituary } = useObituaryDetail()

  const inputResponseRef = React.useRef<HTMLTextAreaElement>(null)

  const [question, setQuestion] = useState<BaseQuestion>(defaultQuestion)
  const [editing, setEditing] = useState<boolean>(false)

  const [recording, setRecording] = useState<'start_automatically' | 'wait_for_button' | false>(
    false
  )

  const [savingResponse, setSavingResponse] = useState(false)
  const [submissionError, setSubmissionError] = useState('')

  const [confirmingDelete, setConfirmingDelete] = useState(false)

  const [showingTranscription, setShowingTranscription] = useState(false)

  const isFollowupQuestion = defaultQuestion.unique_id.startsWith('obfq') // Maybe a better way to do this

  const onSkippedQuestion = useCallback(() => {
    setRecording(false)
    setRecordingAnswer(true)
    finalOnSkippedQuestion(defaultQuestion)
  }, [defaultQuestion, finalOnSkippedQuestion, setRecordingAnswer])

  const onDeleteResponse = useCallback(async () => {
    try {
      const updatedQuestion = await removeObituaryResponse(obituary.unique_identifier, question.unique_id)
      setQuestion(updatedQuestion)
      setConfirmingDelete(false)
      setEditing(false)
    } catch (error) {
      console.error(error)
    }
  }, [obituary.unique_identifier, question.unique_id])

  const onSaveResponse = useCallback(async () => {
    if (!inputResponseRef.current) {
      return
    }
    setSavingResponse(true)
    setSubmissionError('')
    setLoadingNewQuestion(true)
    try {
      const obituaryResponseResult = await saveObituaryResponse(
        obituary.unique_identifier,
        question.unique_id,
        {
          response: inputResponseRef.current.value,
        }
      )
      setQuestion(obituaryResponseResult.updated_question)
      if (obituaryResponseResult.new_question) {
        onAddedQuestion(obituaryResponseResult.new_question)
      }
      setSubmissionError('')
      setEditing(false)
    } catch (e) {
      console.error(e)
      setSubmissionError('There was an error saving your response.')
    } finally {
      setSavingResponse(false)
      setLoadingNewQuestion(false)
      setConfirmingDelete(false)
    }
  }, [obituary.unique_identifier, onAddedQuestion, question.unique_id, setLoadingNewQuestion])

  const onFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      onSaveResponse()
    },
    [onSaveResponse]
  )

  useEffect(() => {
    if (!authToken) {
      return
    }
    // Sets up the websocket connection for this card
    const webSocket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_API_WS_URL}/obituaries/question-response/${defaultQuestion.unique_id}/?token=${authToken}`
    )
    webSocket.onmessage = function (e) {
      const data = JSON.parse(e.data)
      if (data.type === 'transcription_update') {
        const updatedQuestion = data.data.question
        setQuestion(new BaseQuestion(updatedQuestion)) // Should we use a constructor? Should we not use the BaseQuestion class?
        setObituary(new Obituary(data.data.obituary))
      }
    }
    return () => {
      webSocket.close()
    }
  }, [authToken, defaultQuestion.unique_id, setObituary])

  return (
    <QuestionContainer className='border border-gray-300 rounded-3 p-4 position-relative'>
      <div style={{ fontSize: '1.5rem' }} className='text-gray-800 fw-bold'>
        {question.question}
      </div>
      {recording ? (
        <>
          <QuestionRecorder
            autoStart={recording === 'start_automatically'}
            obituary={obituary}
            question={question}
            onSuccess={(updatedQuestion: BaseQuestion) => {
              setQuestion(updatedQuestion)
              setRecording(false)
              setRecordingAnswer(false)
            }}
            onCancel={() => {
              setRecording(false)
              setRecordingAnswer(false)
            }}
            onStartedUploading={() => {
              // This allows other answers to be recorded while uploading.
              // May end up breaking things if the upload fails, need to test that.
              setRecordingAnswer(false)
            }}
          />
        </>
      ) : (
        <>
          {editing ? (
            <form onSubmit={onFormSubmit}>
              <div>
                <textarea
                  style={{ fontSize: '1.25rem' }}
                  ref={inputResponseRef}
                  className='form-control h-250px border-0 resize-none'
                  placeholder='Enter your answer here...'
                  defaultValue={question.latest_response?.response || ''}
                  onChange={(e) => {
                    //
                  }}
                  autoFocus={!question.latest_response?.response}
                />
                <div className='d-flex flex-wrap mt-5 align-items-center justify-content-between position-relative'>
                  {confirmingDelete ? (
                    <>
                      <div className='d-flex align-items-center'>
                        Are you sure you want to delete this response?
                      </div>
                      <div className='d-flex align-items-center'>
                        <button
                          type='button'
                          className='btn btn-sm btn-secondary me-2'
                          onClick={() => {
                            setConfirmingDelete(false)
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type='button'
                          className='btn btn-sm btn-danger'
                          onClick={() => {
                            onDeleteResponse()
                          }}
                        >
                          Yes, Delete
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <button
                          type='button'
                          className='btn btn-sm btn-secondary me-2 mb-2'
                          onClick={() => {
                            setEditing(false)
                          }}
                          disabled={savingResponse}
                        >
                          Cancel
                        </button>
                        {question.latest_response && (
                          <button
                            type='button'
                            className='btn btn-sm btn-danger me-2 mb-2'
                            onClick={() => {
                              setConfirmingDelete(true)
                            }}
                            disabled={savingResponse}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                      {submissionError && (
                        <div
                          className='alert alert-danger d-flex align-items-center mb-2'
                          role='alert'
                        >
                          <div>{submissionError}</div>
                        </div>
                      )}
                      <button
                        type='submit'
                        className='btn btn-sm btn-primary me-2 mb-2'
                        disabled={savingResponse}
                      >
                        {savingResponse ? `Saving...` : `Save Answer`}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </form>
          ) : (
            <>
              {question.latest_response ? (
                <>
                  <div style={{ fontSize: '1.5rem' }} className='text-gray-700 mt-2'>
                    {question.latest_response.audio_response_url ? (
                      <div>
                        <div className='d-flex align-items-center justify-content-center'>
                          <div className='me-2'>
                            <audio src={question.latest_response.audio_response_url} controls />
                          </div>
                        </div>
                        {showingTranscription ? (
                          <>
                            <div className='d-flex align-items-center justify-content-center'>
                              {question.latest_response.audio_response_transcript}
                            </div>
                            <div>
                              <button
                                type='button'
                                className='btn btn-sm btn-secondary mt-2'
                                onClick={() => {
                                  setShowingTranscription(false)
                                }}
                              >
                                Hide Transcription
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            {question.latest_response.audio_response_transcription_status ===
                              'Completed' ? (
                              <div>
                                {question.latest_response.audio_response_summary && (
                                  <div>{question.latest_response.audio_response_summary}</div>
                                )}
                                <div
                                  style={{ fontSize: 12 }}
                                  className='text-gray-700 my-2 cursor-pointer'
                                  onClick={() => {
                                    setShowingTranscription(!showingTranscription)
                                  }}
                                >
                                  Show Full Transcript
                                </div>
                              </div>
                            ) : (
                              <div>
                                {question.latest_response.isTranscriptionInProgress() ? (
                                  <>
                                    <small className='text-gray-700' style={{ fontSize: 14 }}>
                                      Transcription in progress. This may take a few minutes.
                                    </small>
                                  </>
                                ) : (
                                  <>{/* Nothing for transcription failure for now. */}</>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div>{question.latest_response.response}</div>
                    )}
                  </div>
                  {!showingTranscription && (
                    <div className='d-flex flex-wrap mt-2 align-items-center justify-content-center'>
                      <button
                        type='button'
                        className='btn btn-sm btn-secondary me-2 mb-2'
                        onClick={() => {
                          if (question.latest_response?.audio_response_url) {
                            setRecording('wait_for_button')
                            setRecordingAnswer(true)
                          } else {
                            setEditing(true)
                          }
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className='d-flex flex-wrap mt-2 align-items-center justify-content-center'>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary me-2 mb-2'
                      onClick={() => {
                        setRecording('start_automatically')
                        setRecordingAnswer(true)
                      }}
                      disabled={recordingAnswer}
                    >
                      Record Answer
                    </button>
                    <button
                      type='button'
                      className='btn btn-sm btn-secondary me-2 mb-2'
                      onClick={() => {
                        setEditing(true)
                      }}
                    >
                      Write Answer
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
      {isFollowupQuestion && (
        <QuestionEditTools
          obituary={obituary}
          question={question}
          onSkippedQuestion={onSkippedQuestion}
        />
      )}
    </QuestionContainer>
  )
}

export default ObituaryQuestionCard
