import { useJourneyDetail } from "./_JourneyProvider"
import { replaceNewLinesWithParagraphs } from "../ObituaryWriteSection";

function JourneyTributeDisplay() {
    const {
        journey
    } = useJourneyDetail()

    if (!journey) {
        return null;
    }

    return <div dangerouslySetInnerHTML={{ __html: replaceNewLinesWithParagraphs(journey.data.latest_tribute?.response_string || 'No tribute yet') }} />
}

export default JourneyTributeDisplay