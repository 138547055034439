import { useState, useCallback } from 'react'
import { useFormik } from 'formik'
import styled from 'styled-components'

import { ApiError } from '../../../auth'
import { saveObituaryComponent } from '../../core/_requests'
import { personalInformationSchema } from '../../form_schema'
import {
  KnownFamilyMember,
  Obituary,
  ObituaryImageUpload,
  PersonalInformation,
} from '../../core/_models'
import FormTitle from './FormTitle'
import UploadImagesModalWrapper from '../../upload-media/UploadImagesModalWrapper'
import { ObituaryWebsocket, IAvailableFormType } from '../../../../obituaries/useObituaryWebsocket'
import useComplicatedFormBehavior from '../../../../obituaries/useComplicatedFormBehavior'
import FormItemsRenderer, { IItemProps } from './FormItemsRenderer'
import { useAuth } from '../../../auth/core/Auth'
import useObituaryPartialUpdater from '../../../../obituaries/useObituaryPartialUpdater'
import useObituaryFormUpdater from '../../../../obituaries/next/useObituaryFormUpdater'
import useObituaryChannel from '../../../../obituaries/next/useObituaryChannel'

const IconUploadMedia = styled.div`
  overflow: hidden;

  .hover-upload-media {
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-weight: bold;
    cursor: pointer;

    position: absolute;
    inset: 0px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 1rem;
  }

  &:hover {
    .hover-upload-media {
      opacity: 1;
    }
  }
`

const _personalInformationToFormikValues = (updatedData: PersonalInformation) => {
  return {
    lovedOnesFullName: updatedData.lovedOnesFullName || '',
    maidenNameIfApplicable: updatedData.maidenNameIfApplicable || '',
    nickname: updatedData.nickname || '',
    nicknameIsPreferred: updatedData.nicknameIsPreferred || false,
    birthDate: updatedData.birthDate || '',
    placeOfBirth: updatedData.placeOfBirth || '',
    dateOfDeath: updatedData.dateOfDeath || '',
    locationOfDeath: updatedData.locationOfDeath || '',
    cityOfLastResidence: updatedData.cityOfLastResidence || '',
    passingInformation: updatedData.passingInformation || '',
    gender: updatedData.gender || '',
    genderOther: updatedData.genderOther || '',
  }
}

function PersonalInformationForm({
  obituary,
  setObituary,
  onSuccessfulSave,
  obituaryWebsocket,
  hideImageUploader = false,
  userRelationshipToObituary,
}: {
  obituary: Obituary
  setObituary: (obituary: Obituary) => void
  onSuccessfulSave: (updatedObituary: Obituary) => void
  obituaryWebsocket: ObituaryWebsocket | null
  hideImageUploader?: boolean
  userRelationshipToObituary?: KnownFamilyMember | null
}) {
  const { currentPhoenixUserPhone } = useAuth()

  const {
    obituaryChannel
  } = useObituaryChannel();

  const [mediaUploadModalOpen, setMediaUploadModalOpen] = useState(false)

  const formik = useFormik<PersonalInformation>({
    initialValues: _personalInformationToFormikValues(
      obituary.data_personal_information || ({} as PersonalInformation)
    ),
    validationSchema: personalInformationSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true)
      setStatus(undefined)
      try {
        const updatedObituary = await saveObituaryComponent(
          obituary.unique_identifier,
          'personal_information',
          values
        )
        setObituary(updatedObituary)
        onSuccessfulSave(updatedObituary)
      } catch (error: any) {
        console.warn(error)
        const errorMessage =
          error instanceof ApiError ? error.message : 'Unable to save personal information'
        setStatus(errorMessage)
      }
    },
  })

  const onFinishedUploadingImages = useCallback(
    (updatedObituary?: Obituary, newUploads?: ObituaryImageUpload[]) => {
      setMediaUploadModalOpen(false)
    },
    []
  )

  const formError = formik.status

  const items: IItemProps[] = [
    {
      label: userRelationshipToObituary?.is_self
        ? 'Your full name *'
        : obituary.isPreNeedObituary() && currentPhoenixUserPhone?.isFuneralHomeUser()
          ? `Client's full name *`
          : "Your loved one's full name *",
      name: 'lovedOnesFullName',
      type: 'text',
      value: formik.values.lovedOnesFullName,
      autoFocus: false,
      required: true,
      error: formik.errors.lovedOnesFullName,
      touched: formik.touched.lovedOnesFullName,
      isTextArea: false,
      autoComplete: 'off',
    } as IItemProps,
    {
      label: userRelationshipToObituary?.is_self
        ? 'Maiden name (if applicable)'
        : obituary.isPreNeedObituary() && currentPhoenixUserPhone?.isFuneralHomeUser()
          ? `Maiden name (if applicable)`
          : 'Maiden name (if applicable)',
      name: 'maidenNameIfApplicable',
      type: 'text',
      value: formik.values.maidenNameIfApplicable,
      autoFocus: false,
      required: false,
      error: formik.errors.maidenNameIfApplicable,
      touched: formik.touched.maidenNameIfApplicable,
      isTextArea: false,
      autoComplete: 'off',
    } as IItemProps,
    {
      label: 'Nickname (optional)',
      name: 'nickname',
      type: 'text',
      value: formik.values.nickname,
      autoFocus: false,
      required: false,
      error: formik.errors.nickname,
      touched: formik.touched.nickname,
      isTextArea: false,
      autoComplete: 'off',
    } as IItemProps,
    {
      label: !userRelationshipToObituary?.is_self
        ? 'Treat their nickname as their first name'
        : 'Treat my nickname as my first name',
      name: 'nicknameIsPreferred',
      type: 'checkbox',
      value: '',
      autoFocus: false,
      required: false,
      error: formik.errors.nicknameIsPreferred,
      touched: formik.touched.nicknameIsPreferred,
      isTextArea: false,
      isCheckbox: true,
      checked: formik.values.nicknameIsPreferred,
      itemIsHidden: formik.values.nickname ? false : true,
    } as IItemProps,
    {
      label: 'Gender',
      name: 'gender',
      type: 'select',
      value: formik.values.gender,
      autoFocus: false,
      required: false,
      error: formik.errors.gender,
      touched: formik.touched.gender,
      isTextArea: false,
      isSelect: true,
      selectOptions: [
        {
          value: '',
          label: '(Select a gender)',
        },
        {
          value: 'female',
          label: 'Female',
        },
        {
          value: 'male',
          label: 'Male',
        },
        {
          value: 'something-else',
          label: 'Something else',
        },
      ],
    } as IItemProps,
    formik.values.gender === 'something-else'
      ? {
        label: 'Enter a gender',
        name: 'genderOther',
        type: 'text',
        value: formik.values.genderOther,
        autoFocus: false,
        required: false,
        error: formik.errors.genderOther,
        touched: formik.touched.genderOther,
        isTextArea: false,
        autoComplete: 'off',
        isCheckbox: false,
        checked: false,
      }
      : ({
        name: 'genderOther',
        itemIsHidden: true,
      } as IItemProps),
    {
      label: 'Birth date',
      name: 'birthDate',
      type: 'text',
      value: formik.values.birthDate,
      autoFocus: false,
      required: false,
      error: formik.errors.birthDate,
      touched: formik.touched.birthDate,
      isTextArea: false,
      isDatePicker: true,
    } as IItemProps,
    {
      label: 'Place of birth',
      name: 'placeOfBirth',
      type: 'text',
      value: formik.values.placeOfBirth,
      autoFocus: false,
      required: false,
      error: formik.errors.placeOfBirth,
      touched: formik.touched.placeOfBirth,
      isTextArea: false,
      autoComplete: 'off',
    } as IItemProps,
    userRelationshipToObituary?.is_self
      ? ({ isSkipped: true, name: 'passing-date-placeholder' } as IItemProps)
      : ({
        label: 'Date of death',
        name: 'dateOfDeath',
        type: 'text',
        value: formik.values.dateOfDeath,
        autoFocus: false,
        required: false,
        error: formik.errors.dateOfDeath,
        touched: formik.touched.dateOfDeath,
        isTextArea: false,
        isDatePicker: true,
      } as IItemProps),
    userRelationshipToObituary?.is_self
      ? ({ isSkipped: true, name: 'passing-location-placeholder' } as IItemProps)
      : ({
        label: 'Location of death',
        name: 'locationOfDeath',
        type: 'text',
        value: formik.values.locationOfDeath,
        autoFocus: false,
        required: false,
        error: formik.errors.locationOfDeath,
        touched: formik.touched.locationOfDeath,
        isTextArea: false,
        autoComplete: 'off',
      } as IItemProps),
    {
      label: 'City of last residence',
      name: 'cityOfLastResidence',
      type: 'text',
      value: formik.values.cityOfLastResidence,
      autoFocus: false,
      required: false,
      error: formik.errors.cityOfLastResidence,
      touched: formik.touched.cityOfLastResidence,
      isTextArea: false,
      autoComplete: 'off',
    } as IItemProps,
    userRelationshipToObituary?.is_self
      ? ({ isSkipped: true, name: 'passing-information-placeholder' } as IItemProps)
      : ({
        label: currentPhoenixUserPhone?.isFuneralHomeUser()
          ? `Give any information you wish to share about ${obituary.getSimpleName() || 'your client'
          }'s passing`
          : `Give any information you wish to share about ${obituary.getSimpleName() || 'your loved one'
          }'s passing`,
        name: 'passingInformation',
        type: 'text',
        value: formik.values.passingInformation,
        autoFocus: false,
        required: false,
        error: formik.errors.passingInformation,
        touched: formik.touched.passingInformation,
        isTextArea: true,
        placeholder: 'e.g. at home surrounded by loved ones / after a long battle with cancer...',
      } as IItemProps),
  ]

  const formType: IAvailableFormType = 'personal_information'
  const { editingFieldsByName } = useComplicatedFormBehavior(formType, obituaryWebsocket, formik)

  useObituaryPartialUpdater(obituary.unique_identifier, 'personal_information', (updatedData: any) => {
    formik.setValues(_personalInformationToFormikValues(updatedData))
    obituary.data_personal_information = updatedData // Should we call setObituary here? Hm
  })

  useObituaryFormUpdater({
    obituaryChannel,
    onChange: (event) => {
      if (event.formType === 'personal_information') {
        formik.setFieldValue(event.formName, event.formValue)
      }
    }
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormTitle title='Personal Information' />
        {formError && (
          <div className='alert alert-danger'>
            <div className='alert-text'>{formError}</div>
          </div>
        )}
        {/* Begin Picture Upload Row */}
        {hideImageUploader || 'broken in the shareable obit right now' ? (
          <>
            {obituary.data_personal_information?.primaryAvatarUrl ||
              obituary.active_image_uploads.length > 0 ? (
              <div className='row mt-8'>
                <div className='col-12'>
                  <div className='col-12 col-md-6'>
                    <div className='row'>
                      <label className='form-label'>The current picture of your loved one</label>
                      <IconUploadMedia className='symbol symbol-50px symbol-lg-100px symbol-fixed position-relative'>
                        <img
                          src={
                            obituary.data_personal_information?.primaryAvatarUrl ||
                            obituary.active_image_uploads[0].image_url
                          }
                          alt='Obituary'
                          style={{ width: 'auto' }}
                        />
                      </IconUploadMedia>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <div className='row mt-8'>
              <div className='col-12'>
                <div className='col-12 col-md-6'>
                  {obituary.data_personal_information?.primaryAvatarUrl ||
                    obituary.active_image_uploads.length > 0 ? (
                    <div className='row'>
                      <label className='form-label'>The current picture of your loved one</label>
                      <IconUploadMedia className='symbol symbol-50px symbol-lg-100px symbol-fixed position-relative'>
                        <img
                          src={
                            obituary.data_personal_information?.primaryAvatarUrl ||
                            obituary.active_image_uploads[0].image_url
                          }
                          alt='Obituary'
                          style={{ width: 'auto' }}
                        />
                        <div
                          className='hover-upload-media'
                          style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
                          onClick={() => setMediaUploadModalOpen(true)}
                        >
                          <div className='d-flex flex-column align-items-center justify-content-center h-100 text-center'>
                            <div className=''>
                              <div>Upload Images</div>
                            </div>
                          </div>
                        </div>
                      </IconUploadMedia>
                    </div>
                  ) : (
                    <div className='row'>
                      <label className='form-label'>Upload a picture of your loved one</label>
                      <button
                        className='btn btn-sm btn-secondary'
                        type='button'
                        onClick={() => {
                          setMediaUploadModalOpen(true)
                        }}
                      >
                        Choose Picture
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {/* End Picture Upload Row */}
        <FormItemsRenderer
          formType={formType}
          formik={formik}
          obituaryWebsocket={obituaryWebsocket}
          setObituary={setObituary}
          items={items}
          editingFieldsByName={editingFieldsByName}
          obituaryChannel={obituaryChannel}
        />
        <div className='row mt-10'>
          <div className='col-12 d-flex justify-content-end'>
            <button type='submit' className='btn btn-primary'>
              Continue
            </button>
          </div>
        </div>
        {formError && (
          <div className='alert alert-danger mt-4'>
            <div className='alert-text'>{formError}</div>
          </div>
        )}
      </form>
      {mediaUploadModalOpen && <UploadImagesModalWrapper onClose={onFinishedUploadingImages} />}
    </div>
  )
}

export default PersonalInformationForm
