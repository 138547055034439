import { useState, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import styled from 'styled-components'

import { ApiError } from '../auth'
import { useObituaryDetail } from './components/ObituaryDetail'
import {
  getAvailableMerchandise,
} from './core/_requests'
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import GravemarkerDesigner from './GravemarkerDesigner'
import GravemarkerPromotionalVideo from './GravemarkerPromotionalVideo'

export interface SelectableMerchandiseOptionGroupItem {
  to: string
  value: string
  label: string
}

export interface SelectableMerchandiseOptionGroup {
  is_primary: boolean
  value: string
  title: string
  options: SelectableMerchandiseOptionGroupItem[]
}

const VerticalTabsContainer = styled.ul`
  display: flex;
  flex-direction: column;

  border: 0;

  .nav-link {
    border-radius: var(--bs-nav-tabs-border-radius) !important;
  }

  .nav-link.active {
    border: 1px solid #d5d5d9;
  }
`

const VerticalTabItem = styled.li`
  list-style: none;
`

const VerticalTabLink = styled(Link)`
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  cursor: pointer;

  margin-left: 0 !important;

  &:hover {
    background-color: #f5f5f5;
  }

  &.active {
    background-color: #e9ecef;
    font-weight: bold;
    border-left: 3px solid #0d6efd;
  }
`

const LineBreakWrapper = styled.div`
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`

const formatPassage = (message: string) => {
  return message.split('\n').map((line, index) => {
    return <LineBreakWrapper key={index}>{line ? line : <p className='py-2'></p>}</LineBreakWrapper>
  })
}

interface MerchandiseDetailProps { }

function MerchandiseDetail({ }: MerchandiseDetailProps) {
  const { obituary } = useObituaryDetail()

  const availableMerchandise = [
    {
      to: `/obituaries/${obituary.unique_identifier}/merchandise/gravemarker`,
      value: 'gravemarker',
      title: 'Gravemarker',
      options: [
        {
          to: `/obituaries/${obituary.unique_identifier}/merchandise/gravemarker/text-marker`,
          value: 'text_marker',
          label: 'Text Marker',
        },
        {
          to: `/obituaries/${obituary.unique_identifier}/merchandise/gravemarker/photo-marker`,
          value: 'photo_marker',
          label: 'Photo Marker',
        },
        {
          to: `/obituaries/${obituary.unique_identifier}/merchandise/gravemarker/military-marker`,
          value: 'military_marker',
          label: 'Military Marker',
        },
        {
          to: `/obituaries/${obituary.unique_identifier}/merchandise/gravemarker/promotional-video`,
          value: 'promotional_video',
          label: 'Promotional Video',
        },
      ],
    },
  ]

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const { pathname: pathName } = useLocation()

  const [generatingPassages, setGeneratingPassages] = useState(false)
  const [errorGeneratingPassages, setErrorGeneratingPassages] = useState<string | null>(null)

  const [selectedOptionGroup, setSelectedMerchandiseOptionGroup] =
    useState<SelectableMerchandiseOptionGroup | null>(null)
  const [selectedOptionGroupItem, setSelectedMerchandiseOptionGroupItem] =
    useState<SelectableMerchandiseOptionGroupItem | null>(null)

  const [availableMerchandiseOptions, setAvailableMerchandiseOptions] = useState<
    SelectableMerchandiseOptionGroup[]
  >([])
  const [viewingMoreOptions, setViewingMoreOptions] = useState(false)

  const [activeScriptureOption, setActiveMerchandiseOption] =
    useState<SelectableMerchandiseOptionGroup | null>(
      availableMerchandiseOptions.length > 0 ? availableMerchandiseOptions[0] : null
    )

  const [generatedMerchandise, setGeneratedMerchandise] = useState<any[]>([]) // TODO: type

  const onLoadInspirationPassages = useCallback(async () => {
    // TODO:
    setLoading(false)
    try {
      const { merchandise_options: updatedMerchandiseOptions } = await getAvailableMerchandise(
        obituary.unique_identifier
      )
      setAvailableMerchandiseOptions(updatedMerchandiseOptions)
      if (updatedMerchandiseOptions.length > 0) {
        const firstOption = updatedMerchandiseOptions[0]
        setActiveMerchandiseOption(firstOption)
        if (firstOption.options.length > 0) {
          setSelectedMerchandiseOptionGroup(firstOption)
          setSelectedMerchandiseOptionGroupItem(firstOption.options[0])
        }
      }
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [obituary.unique_identifier])

  const onGeneratePassages = useCallback(async () => {
    /*
    setGeneratingPassages(true)
    setErrorGeneratingPassages(null)
    try {
      const {inspirational_passage: updatedPassage} = await generateInspirationalPassages(
        obituary.unique_identifier,
        {
          selectedOption: selectedOption?.value,
          selectedOptionGroup: selectedOptionGroup?.value,
          selectedOptionGroupItem: selectedOptionGroupItem?.value,
        }
      )
      setInspirationalPassages((passages) => [...passages, updatedPassage])
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setErrorGeneratingPassages(errorMessage)
    } finally {
      setGeneratingPassages(false)
    }
    */
  }, [])

  useEffect(() => {
    onLoadInspirationPassages()
  }, [onLoadInspirationPassages])

  if (loading) {
    return (
      <div>
        <div className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='alert alert-danger' role='alert'>
        <div className='alert-text'>{error}</div>
      </div>
    )
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 ps-4 fs-2'>
          <>
            {availableMerchandise.map((option) => {
              // passage_type exists but for now is always scripture
              const passageCount = generatedMerchandise.filter(
                (x) => x.passage_type === 'scripture' && x.passage_sub_type === option.value
              ).length
              return (
                <li className='nav-item' key={option.value}>
                  <Link
                    to={option.to}
                    className={clsx(
                      'nav-link d-flex align-items-center justify-content-between',
                      pathName.startsWith(option.to) && 'active'
                    )}
                  >
                    {`${option.title}`}
                    {passageCount > 0 && (
                      <span className='badge bg-primary ms-3'>{passageCount}</span>
                    )}
                  </Link>
                </li>
              )
            })}
          </>
        </ul>
      </div>
      <div className='col-12'>
        <Routes>
          <Route
            path='/gravemarker/*'
            element={
              <div>
                <div className='row'>
                  <div className='col-4'>
                    {/* Add another tab bar with all option.options */}
                    <VerticalTabsContainer className='nav nav-tabs fs-6'>
                      {availableMerchandise[0].options.map((optionItem) => {
                        const passageCount = 0 // TODO
                        return (
                          <VerticalTabItem key={optionItem.value} className='nav-item'>
                            <VerticalTabLink
                              className={clsx('nav-link', pathName === optionItem.to && 'active')}
                              to={optionItem.to}
                            >
                              {`${optionItem.label}`}
                              {passageCount > 0 && (
                                <span className='badge bg-primary ms-3'>{passageCount}</span>
                              )}
                            </VerticalTabLink>
                          </VerticalTabItem>
                        )
                      })}
                    </VerticalTabsContainer>
                  </div>
                  <div className='col-8'>
                    <Routes>
                      <Route path='/text-marker' element={<GravemarkerDesigner type='text' />} />
                      <Route path='/photo-marker' element={<GravemarkerDesigner type='photo' />} />
                      <Route
                        path='/military-marker'
                        element={<GravemarkerDesigner type='military' />}
                      />
                      <Route path='/promotional-video' element={<GravemarkerPromotionalVideo />} />
                      {/* Catch all */}
                      <Route
                        path='*'
                        element={
                          <Navigate
                            to={`/obituaries/${obituary.unique_identifier}/merchandise/gravemarker/text-marker`}
                            replace
                          />
                        }
                      />
                    </Routes>
                  </div>
                </div>
              </div>
            }
          />
          {/* Catch all */}
          <Route
            path='*'
            element={
              <Navigate
                to={`/obituaries/${obituary.unique_identifier}/merchandise/gravemarker/text-marker`}
                replace
              />
            }
          />
        </Routes>
      </div>
    </div>
  )
}

export default MerchandiseDetail
