import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { KnownFamilyMember, Obituary } from '../../core/_models'
import FormTitle from './FormTitle'
import { ObituaryWebsocket } from '../../../../obituaries/useObituaryWebsocket'
import { useAuth } from '../../../auth/core/Auth'
import ObituaryFinalQuestionsChat from './ObituaryFinalQuestionsChat'
import ObituaryFinalQuestionsSummary from './ObituaryFinalQuestionsSummary'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`

const AppContainer = styled(Container)`
  height: calc(100vh - 385px);
`

const ShareableContainer = styled(Container)`
  background-color: rgba(68, 70, 84);
`

function FinalQuestionsForm({
  obituary,
  setObituary,
  onSuccessfulSave,
  goBackUrl,
  obituaryWebsocket,
  userRelationshipToObituary,
  viewingShareableObituary = false,
}: {
  obituary: Obituary
  setObituary: (obituary: Obituary) => void
  onSuccessfulSave: (updatedObituary: Obituary) => void
  goBackUrl: string
  obituaryWebsocket: ObituaryWebsocket | null
  userRelationshipToObituary?: KnownFamilyMember | null
  viewingShareableObituary?: boolean
}) {
  const { currentPhoenixUserPhone } = useAuth()

  const [viewingSummary, setViewingSummary] = useState(false)

  const title = 'Final Questions'

  useEffect(() => {
    if (obituaryWebsocket) {
      obituaryWebsocket.sendPageView('final-questions')
    }
  }, [obituaryWebsocket])

  return (
    <div className='h-100' style={{ paddingBottom: 50 }}>
      <FormTitle
        title={title}
        continueGoesTo={
          viewingShareableObituary
            ? `/o/${obituary.shareable_code}/write`
            : `/obituaries/${obituary.unique_identifier}/write`
        }
        additionalButtons={
          !viewingShareableObituary ? (
            <>
              <button
                type='button'
                className='btn btn-sm btn-light-primary me-2'
                onClick={() => {
                  setViewingSummary(!viewingSummary)
                }}
              >
                {!viewingSummary ? `View Summary` : `Hide Summary`}
              </button>
            </>
          ) : undefined
        }
      />
      <Container as={viewingShareableObituary ? ShareableContainer : AppContainer}>
        {!viewingSummary ? (
          <ObituaryFinalQuestionsChat
            obituary={obituary}
            theme={viewingShareableObituary ? 'dark' : 'light'}
          />
        ) : (
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <ObituaryFinalQuestionsSummary />
          </div>
        )}
      </Container>
    </div>
  )
}

export default FinalQuestionsForm
