/*
{
  "slack_configuration": {
    "inserted_at": "2023-03-31T13:54:02.330927Z",
    "unique_id": "slc_yX1DwjEdzQoeHb",
    "obituary_channel_id": null,
    "post_about_obituary": false,
    "funeral_home_channel_id": null,
    "post_about_funeral_home": false
  },
  "api_data": {
    "conversations": [
      {
        "id": "C051889TKNW",
        "name": "general",
        "is_channel": true,
        "is_group": false,
        "is_im": false,
        "is_mpim": false,
        "is_private": false,
        "created": 1680267724,
        "is_archived": false,
        "is_general": true,
        "unlinked": 0,
        "name_normalized": "general",
        "is_shared": false,
        "is_org_shared": false,
        "is_pending_ext_shared": false,
        "pending_shared": [],
        "context_team_id": "T051XUCU3A4",
        "updated": 1680267724524,
        "parent_conversation": null,
        "creator": "U051AMY2360",
        "is_ext_shared": false,
        "shared_team_ids": [
          "T051XUCU3A4"
        ],
        "pending_connected_team_ids": [],
        "is_member": false,
        "topic": {
          "value": "",
          "creator": "",
          "last_set": 0
        },
        "purpose": {
          "value": "This is the one channel that will always include everyone. It’s a great spot for announcements and team-wide conversations.",
          "creator": "U051AMY2360",
          "last_set": 1680267724
        },
        "previous_names": [],
        "num_members": 1
      },
      ...
    ]
  }
}
*/

export class AdminSlackConfiguration {
  inserted_at: string
  unique_id: string
  obituary_channel_id: string
  post_about_obituary: boolean
  funeral_home_channel_id: string
  post_about_funeral_home: boolean

  constructor(data: any) {
    this.inserted_at = data.inserted_at
    this.unique_id = data.unique_id
    this.obituary_channel_id = data.obituary_channel_id
    this.post_about_obituary = data.post_about_obituary
    this.funeral_home_channel_id = data.funeral_home_channel_id
    this.post_about_funeral_home = data.post_about_funeral_home
  }
}

export class AdminSlackConversation {
  id: string
  name: string
  is_channel: boolean
  is_group: boolean
  is_im: boolean
  is_mpim: boolean
  is_private: boolean
  created: number
  is_archived: boolean
  is_general: boolean
  unlinked: number
  name_normalized: string
  is_shared: boolean
  is_org_shared: boolean
  is_pending_ext_shared: boolean
  pending_shared: any[]
  context_team_id: string
  updated: number
  parent_conversation: any
  creator: string
  is_ext_shared: boolean
  shared_team_ids: string[]
  pending_connected_team_ids: any[]
  is_member: boolean
  topic: {
    value: string
    creator: string
    last_set: number
  }
  purpose: {
    value: string
    creator: string
    last_set: number
  }
  previous_names: any[]
  num_members: number
  user?: string

  constructor(data: any) {
    this.id = data.id
    this.name = data.name
    this.is_channel = data.is_channel
    this.is_group = data.is_group
    this.is_im = data.is_im
    this.is_mpim = data.is_mpim
    this.is_private = data.is_private
    this.created = data.created
    this.is_archived = data.is_archived
    this.is_general = data.is_general
    this.unlinked = data.unlinked
    this.name_normalized = data.name_normalized
    this.is_shared = data.is_shared
    this.is_org_shared = data.is_org_shared
    this.is_pending_ext_shared = data.is_pending_ext_shared
    this.pending_shared = data.pending_shared
    this.context_team_id = data.context_team_id
    this.updated = data.updated
    this.parent_conversation = data.parent_conversation
    this.creator = data.creator
    this.is_ext_shared = data.is_ext_shared
    this.shared_team_ids = data.shared_team_ids
    this.pending_connected_team_ids = data.pending_connected_team_ids
    this.is_member = data.is_member
    this.topic = data.topic
    this.purpose = data.purpose
    this.previous_names = data.previous_names
    this.num_members = data.num_members
    this.user = data.user
  }
}

/*
users:
[
  {
    "id": "USLACKBOT",
    "team_id": "T051XUCU3A4",
    "name": "slackbot",
    "deleted": false,
    "color": "757575",
    "real_name": "Slackbot",
    "tz": "America/Los_Angeles",
    "tz_label": "Pacific Daylight Time",
    "tz_offset": -25200,
    "profile": {
      "title": "",
      "phone": "",
      "skype": "",
      "real_name": "Slackbot",
      "real_name_normalized": "Slackbot",
      "display_name": "Slackbot",
      "display_name_normalized": "Slackbot",
      "fields": {},
      "status_text": "",
      "status_emoji": "",
      "status_emoji_display_info": [],
      "status_expiration": 0,
      "avatar_hash": "sv41d8cd98f0",
      "always_active": true,
      "first_name": "slackbot",
      "last_name": "",
      "image_24": "https://a.slack-edge.com/80588/img/slackbot_24.png",
      "image_32": "https://a.slack-edge.com/80588/img/slackbot_32.png",
      "image_48": "https://a.slack-edge.com/80588/img/slackbot_48.png",
      "image_72": "https://a.slack-edge.com/80588/img/slackbot_72.png",
      "image_192": "https://a.slack-edge.com/80588/marketing/img/avatars/slackbot/avatar-slackbot.png",
      "image_512": "https://a.slack-edge.com/80588/img/slackbot_512.png",
      "status_text_canonical": "",
      "team": "T051XUCU3A4"
    },
    "is_admin": false,
    "is_owner": false,
    "is_primary_owner": false,
    "is_restricted": false,
    "is_ultra_restricted": false,
    "is_bot": false,
    "is_app_user": false,
    "updated": 0,
    "is_email_confirmed": false,
    "who_can_share_contact_card": "EVERYONE"
  },
  {
    "id": "U0515AEL2N9",
    "team_id": "T051XUCU3A4",
    "name": "obituariesai",
    "deleted": false,
    "color": "4bbe2e",
    "real_name": "obituaries.ai",
    "tz": "America/Los_Angeles",
    "tz_label": "Pacific Daylight Time",
    "tz_offset": -25200,
    "profile": {
      "title": "",
      "phone": "",
      "skype": "",
      "real_name": "obituaries.ai",
      "real_name_normalized": "obituaries.ai",
      "display_name": "",
      "display_name_normalized": "",
      "fields": null,
      "status_text": "",
      "status_emoji": "",
      "status_emoji_display_info": [],
      "status_expiration": 0,
      "avatar_hash": "g7fc09a416c2",
      "api_app_id": "A050TNL2N3H",
      "always_active": true,
      "bot_id": "B051XV7NDEU",
      "first_name": "obituaries.ai",
      "last_name": "",
      "image_24": "https://secure.gravatar.com/avatar/7fc09a416c2c87379344bb9d1a778646.jpg?s=24&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0008-24.png",
      "image_32": "https://secure.gravatar.com/avatar/7fc09a416c2c87379344bb9d1a778646.jpg?s=32&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0008-32.png",
      "image_48": "https://secure.gravatar.com/avatar/7fc09a416c2c87379344bb9d1a778646.jpg?s=48&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0008-48.png",
      "image_72": "https://secure.gravatar.com/avatar/7fc09a416c2c87379344bb9d1a778646.jpg?s=72&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0008-72.png",
      "image_192": "https://secure.gravatar.com/avatar/7fc09a416c2c87379344bb9d1a778646.jpg?s=192&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0008-192.png",
      "image_512": "https://secure.gravatar.com/avatar/7fc09a416c2c87379344bb9d1a778646.jpg?s=512&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0008-512.png",
      "status_text_canonical": "",
      "team": "T051XUCU3A4"
    },
    "is_admin": false,
    "is_owner": false,
    "is_primary_owner": false,
    "is_restricted": false,
    "is_ultra_restricted": false,
    "is_bot": true,
    "is_app_user": false,
    "updated": 1680268048,
    "is_email_confirmed": false,
    "who_can_share_contact_card": "EVERYONE"
  },
  {
    "id": "U051AMY2360",
    "team_id": "T051XUCU3A4",
    "name": "chris",
    "deleted": false,
    "color": "9f69e7",
    "real_name": "Chris Cohoat",
    "tz": "America/Indiana/Indianapolis",
    "tz_label": "Eastern Daylight Time",
    "tz_offset": -14400,
    "profile": {
      "title": "",
      "phone": "",
      "skype": "",
      "real_name": "Chris Cohoat",
      "real_name_normalized": "Chris Cohoat",
      "display_name": "",
      "display_name_normalized": "",
      "fields": null,
      "status_text": "",
      "status_emoji": "",
      "status_emoji_display_info": [],
      "status_expiration": 0,
      "avatar_hash": "668f2d7338fe",
      "image_original": "https://avatars.slack-edge.com/2023-03-31/5039330149205_668f2d7338fe030f9868_original.jpg",
      "is_custom_image": true,
      "first_name": "Chris",
      "last_name": "Cohoat",
      "image_24": "https://avatars.slack-edge.com/2023-03-31/5039330149205_668f2d7338fe030f9868_24.jpg",
      "image_32": "https://avatars.slack-edge.com/2023-03-31/5039330149205_668f2d7338fe030f9868_32.jpg",
      "image_48": "https://avatars.slack-edge.com/2023-03-31/5039330149205_668f2d7338fe030f9868_48.jpg",
      "image_72": "https://avatars.slack-edge.com/2023-03-31/5039330149205_668f2d7338fe030f9868_72.jpg",
      "image_192": "https://avatars.slack-edge.com/2023-03-31/5039330149205_668f2d7338fe030f9868_192.jpg",
      "image_512": "https://avatars.slack-edge.com/2023-03-31/5039330149205_668f2d7338fe030f9868_512.jpg",
      "image_1024": "https://avatars.slack-edge.com/2023-03-31/5039330149205_668f2d7338fe030f9868_1024.jpg",
      "status_text_canonical": "",
      "team": "T051XUCU3A4"
    },
    "is_admin": true,
    "is_owner": true,
    "is_primary_owner": true,
    "is_restricted": false,
    "is_ultra_restricted": false,
    "is_bot": false,
    "is_app_user": false,
    "updated": 1680267769,
    "is_email_confirmed": true,
    "who_can_share_contact_card": "EVERYONE"
  }
]
*/

export class AdminSlackUser {
  id: string
  name: string
  real_name: string
  profile: {
    image_24: string
    image_32: string
    image_48: string
    image_72: string
    image_192: string
    image_512: string
  }

  constructor(data: any) {
    this.id = data.id
    this.name = data.name
    this.real_name = data.real_name
    this.profile = data.profile
  }
}

export class AdminSlackApiData {
  conversations: AdminSlackConversation[]
  users: AdminSlackUser[]
  users_by_id: {[key: string]: AdminSlackUser}

  constructor(data: any) {
    this.conversations = data.conversations.map(
      (conversation: any) => new AdminSlackConversation(conversation)
    )
    this.users = data.users.map((user: any) => new AdminSlackUser(user))
    this.users_by_id = this.users.reduce((acc: any, user: any) => {
      acc[user.id] = user
      return acc
    }, {})
  }
}

export class AdminSlackResponse {
  slack_configuration: AdminSlackConfiguration
  api_data: AdminSlackApiData

  constructor(data: any) {
    this.slack_configuration = new AdminSlackConfiguration(data.slack_configuration)
    this.api_data = new AdminSlackApiData(data.api_data)
  }
}

export class FRMAccessToken {
  company_id: string
  company_name: string
  inserted_at: string
  public_data: any
  unique_identifier: string
  updated_at: string

  constructor(data: any) {
    this.company_id = data.company_id
    this.company_name = data.company_name
    this.inserted_at = data.inserted_at
    this.public_data = data.public_data
    this.unique_identifier = data.unique_identifier
    this.updated_at = data.updated_at
  }
}

export class AdminFRMResponse {
  have_authenticated_with_frm: boolean
  frm_oauth_access_token?: FRMAccessToken

  constructor(data: any) {
    this.have_authenticated_with_frm = data.have_authenticated_with_frm
    this.frm_oauth_access_token = data.frm_oauth_access_token
      ? new FRMAccessToken(data.frm_oauth_access_token)
      : undefined
  }
}

export class FRMLocation {
  _id: string
  address: string
  isInstalled: boolean
  name: string

  constructor(data: any) {
    this._id = data._id
    this.address = data.address
    this.isInstalled = data.isInstalled
    this.name = data.name
  }
}

export interface ClientsAndConnectionsItem {
  client: {
    unique_identifier: string
    name: string
  }
  connection: {
    company_id: string
    location_id: string
    at_need_pipeline_id?: string
    pre_need_pipeline_id?: string
    everything_else_pipeline_id?: string
  }
}

export type AllClientsAndConnectionsItems = Record<string, ClientsAndConnectionsItem>

export class AdminFRMLocationsResponse {
  access_token: FRMAccessToken
  locations_count: number
  locations: FRMLocation[]

  warning_message?: string | null

  clients_and_connections?: AllClientsAndConnectionsItems

  constructor(data: any) {
    this.access_token = new FRMAccessToken(data.access_token)
    this.locations_count = data.locations_count
    this.locations = data.locations
      ? data.locations.map((location: any) => new FRMLocation(location))
      : []
    this.warning_message = data.warning_message
    this.clients_and_connections = data.clients_and_connections || {}
  }
}
