import { Handle, Position } from 'reactflow';
import styled from 'styled-components';

import { NodeCopy, NodeFormElement } from '../InteractiveEmployeeManage';
import { NodeContainer, CopyTitle, CopyBody } from './NodeVideoWithQuestions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ApiError } from '../../../../auth';
import { getActiveJourneyPersonalizationForNode, saveFormValuesForNode } from '../../../../../funeral_homes/dashboard/core/_requests';
import NodeSubmissionError from '../components/NodeSubmissionError';
import { JourneyPersonalization } from '../../../../../funeral_homes/dashboard/core/journey_classes';

export const FormContainer = styled.form`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 16px;

    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

// { [key: string]: { formElement: NodeFormElement, value: string } } 

interface FormElementsAsObject {
    [key: string]: {
        formElement: NodeFormElement,
        value: string
    }
}

function NodeArbitraryDataForm({ id: nodeId, data }: { id: string, data: any }) {

    const copy = data.copy as NodeCopy;
    const formElements = data.formElements as NodeFormElement[];

    const targetNodeId = data?.targetNodeId;

    const [submitting, setSubmitting] = useState(false);
    const [submissionError, setSubmissionError] = useState<string | null>(null);
    const [submissionSuccess, setSubmissionSuccess] = useState<string | null>(null);

    const [loadingCurrentFormValues, setLoadingCurrentFormValues] = useState(true);
    const [loadingError, setLoadingError] = useState<string | null>(null);

    const [useFormElementsAsObject, setUseFormElementsAsObject] = useState<FormElementsAsObject>({})

    const useFormElementsAsObjectAsArray = useMemo(() => {
        return Object.entries(useFormElementsAsObject).map((item) => {
            const [name, { formElement, value }] = item
            return {
                name,
                formElement,
                value
            }
        })
    }, [useFormElementsAsObject])

    const onFormSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setSubmitting(true);
        setSubmissionError(null);
        setSubmissionSuccess(null);
        // Doing this manually to avoid formik for now.
        const formValues = new FormData(event.currentTarget)
        const serializedFormValues: any = {}
        formValues.forEach((value, key) => {
            serializedFormValues[key] = value
        })
        try {
            const result = await saveFormValuesForNode(nodeId, {
                form_values: serializedFormValues
            });
            // todo: use result?
            setSubmissionSuccess('Successfully updated!')
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof ApiError ? exception.message : 'Something went wrong'
            setSubmissionError(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }, [nodeId]);

    useEffect(() => {
        if (!formElements || formElements.length === 0) {
            return;
        }
        // Convert formElements to an array of {key: value} objects
        const formElementsAsObject: FormElementsAsObject = {}
        formElements.forEach((formElement) => {
            const { name } = formElement
            formElementsAsObject[name] = {
                formElement,
                value: ''
            }
        })
        // We will set the value (if it exists) after a successful load.
        async function loadForNodeId() {
            try {
                const result = await getActiveJourneyPersonalizationForNode(nodeId)
                if (result?.data.form_values) {
                    Object.entries(result.data.form_values).forEach((item) => {
                        const [name, value] = item
                        if (formElementsAsObject[name] && typeof value === 'string') {
                            formElementsAsObject[name].value = value.toString()
                        }
                    })
                }
                setUseFormElementsAsObject(formElementsAsObject)
            } catch (error: any) {
                console.warn('error', error)
                const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
                setLoadingError(errorMessage)
            } finally {
                setLoadingCurrentFormValues(false)
            }
        }
        loadForNodeId()
    }, [formElements, nodeId]);

    if (!formElements || formElements.length === 0) {
        return (
            <NodeContainer $highlighted={data?.highlightedNodeId === nodeId}>
                {targetNodeId && <Handle type="target" position={Position.Left} id={targetNodeId} />}
                <div className='d-flex flex-column align-items-center justify-content-center position-absolute w-100 h-100 p-5'>
                    <CopyTitle className='mb-5'>
                        {copy.title}
                    </CopyTitle>
                    <div className='alert alert-danger' role='alert'>
                        Missing form elements
                    </div>
                </div>
            </NodeContainer>
        );
    }

    return (
        <NodeContainer $highlighted={data?.highlightedNodeId === nodeId}>
            {targetNodeId && <Handle type="target" position={Position.Left} id={targetNodeId} />}
            <FormContainer className='mt-5' onSubmit={onFormSubmit}>
                <CopyTitle>
                    {copy.title}
                </CopyTitle>
                <CopyBody>
                    {copy.body}
                </CopyBody>
                {loadingCurrentFormValues ? <></> : <>
                    {useFormElementsAsObjectAsArray.length > 1 ? <>
                        <div>
                            (Not yet implemented)
                        </div>
                    </> : <div className='w-100 mt-3 mb-5 input-group'>
                        {useFormElementsAsObjectAsArray.map((item) => {
                            return <input
                                key={`input-${item.name}`}
                                type={item.formElement.type}
                                className='form-control'
                                placeholder={item.formElement.placeholder}
                                name={item.name}
                                defaultValue={item.value || ''}
                                required={false && 'notRequiredUntilWeHaveAWayToClearNodes'}
                            />
                        })}
                        <div className='input-group-append'>
                            <button className='btn btn-light' type='submit' disabled={submitting}>
                                {submitting ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    </div>}
                </>}
                {loadingError && <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                        <div className='text-center'>
                            {loadingError}
                        </div>
                    </div>
                </div>}
            </FormContainer>
            <NodeSubmissionError message={submissionSuccess || submissionError || ''} type={submissionSuccess ? 'success' : 'error'} />
        </NodeContainer >
    );
}

export default NodeArbitraryDataForm;