/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl, useDebounce } from '../../_metronic/helpers'
import { ApiError } from '../modules/auth'
import { DateTime } from 'luxon'
import FuneralHomeRowOptions from './FuneralHomeRowOptions'
import { FuneralHome } from '../modules/obituaries/core/_models'
import { getAllAvailableFuneralHomes, getClientTeamMembers } from '../funeral_homes/dashboard/core/_requests'

type Props = {}

const ProgressBarItem = ({ value }: { value: number }) => {
  let color = 'bg-primary'
  if (value < 50) {
    color = 'bg-danger'
  } else if (value > 50 && value < 75) {
    color = 'bg-warning'
  } else if (value > 75) {
    color = 'bg-success'
  }
  return (
    <div className='d-flex flex-column w-100 me-2'>
      <div className='d-flex flex-stack mb-2'>
        <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
      </div>
      <div className='progress h-6px w-100'>
        <div
          className={`progress-bar ${color}`}
          role='progressbar'
          style={{ width: `${value}%` }}
        ></div>
      </div>
    </div>
  )
}

function BadButQuicklyWorkingClientMembershipLineItem({ client }: { client: FuneralHome }) {

  // This is bad because there's a database query for every single row item...
  // Doing this to avoid adding additional back-end endpoints and code right now.

  const [totalCount, setTotalCount] = React.useState<number | null>(null)

  const loadMemberships = useCallback(async () => {
    const updatedTeamMembers = await getClientTeamMembers(client.unique_identifier, '')
    setTotalCount(updatedTeamMembers.length)
  }, [client.unique_identifier])

  useEffect(() => {
    loadMemberships()
  }, [loadMemberships])

  if (totalCount === null) {
    return <span className='text-muted fw-semibold text-muted d-block fs-7'>
      {/* No loading state for now */}
    </span>
  }

  return <Link to={`/admin/clients/${client.unique_identifier}/team`} className='text-dark fw-bold d-block fs-6'>
    {totalCount === 0 ? `No team members` : `${totalCount} team member${totalCount === 1 ? '' : 's'}`}
  </Link>
}

function FuneralHomeRow({
  funeralHome: defaultFuneralHome,
}: {
  funeralHome: FuneralHome
}) {
  const [funeralHome, setFuneralHome] = React.useState<FuneralHome>(defaultFuneralHome)

  return (
    <tr>
      {false && (
        <td>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input className='form-check-input widget-9-check' type='checkbox' value='1' />
          </div>
        </td>
      )}
      {false && <td>
        {funeralHome.latest_logo_url ? (
          <div className='symbol symbol-45px me-5'>
            <img src={funeralHome.latest_logo_url} alt='' />
          </div>
        ) : (
          <div className='symbol symbol-45px me-5'>
            <span className='symbol-label bg-light-primary text-primary fw-bold'>
              {funeralHome.name_initials}
            </span>
          </div>
        )}
      </td>}
      <td>
        <div className='d-flex align-items-center'>
          {false && (
            <div className='symbol symbol-45px me-5'>
              <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
            </div>
          )}
          <div className='d-flex justify-content-start flex-column'>
            {/* Use "text-hover-primary" when clickable */}
            <Link
              className='text-dark fw-bold fs-6 text-hover-primary'
              to={`/admin/clients/${funeralHome.unique_identifier}/overview`}
            >{funeralHome.name}</Link>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              {funeralHome.public_data?.formatted_address}
            </span>
          </div>
        </div >
      </td >
      <td className='text-left'>
        {funeralHome.widget_is_active ? (
          <>
            <span className='badge badge-light-success'>Visible</span>
          </>
        ) : (
          <>
            <span className='badge badge-light-warning'>Inactive</span>
          </>
        )}
      </td>
      <td>
        <BadButQuicklyWorkingClientMembershipLineItem client={funeralHome} />
        <span className='text-muted fw-semibold text-muted d-block fs-7'>
          {`Created ${DateTime.fromJSDate(new Date(funeralHome.inserted_at)).toFormat(
            'LLL dd, yyyy'
          )}`}
        </span>
      </td>
      {
        false && (
          <td className='text-end'>
            <ProgressBarItem value={75} />
          </td>
        )
      }
      <td>
        <div className='d-flex justify-content-end flex-shrink-0'>
          {false && (
            <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
            </a>
          )}
          <FuneralHomeRowOptions funeralHome={funeralHome} onUpdateFuneralHome={(updatedFuneralHome) => {
            console.log('here', updatedFuneralHome)
            setFuneralHome(updatedFuneralHome)
          }} />
          {false && (
            <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </a>
          )}
          {false && (
            <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </a>
          )}
        </div>
      </td>
    </tr >
  )
}

const AdminHome: React.FC<Props> = ({ }) => {
  const [loading, setLoading] = React.useState(true)
  const [loadingError, setLoadingError] = React.useState<string | null>(null)
  const [allFuneralHomes, setAllFuneralHomes] = React.useState<FuneralHome[]>([])

  const [searchText, setSearchText] = React.useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  const loadResults = useCallback(async () => {
    setLoading(true)
    try {
      const updatedData = await getAllAvailableFuneralHomes(debouncedSearchText)
      setAllFuneralHomes(updatedData)
      setLoadingError(null)
    } catch (error: any) {
      console.warn('error', error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setLoadingError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [debouncedSearchText])

  useEffect(() => {
    loadResults()
  }, [loadResults])

  return (
    <div>
      <div className={`card card-custom card-stretch shadow mb-5`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            {/* begin::Search Bar */}
            <div className='d-flex align-items-center position-relative my-1'>
              <input
                type='text'
                className='form-control form-control-solid w-300px'
                placeholder='Search for a funeral home...'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {loading ? (
                <>
                  <div className='ms-4 spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </>
              ) : (
                <>
                  {loadingError ? (
                    <>
                      <div className='ms-4'>
                        <div className='alert alert-danger' role='alert'>
                          <div className='alert-text'>{loadingError}</div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            {/* end::Search Bar */}
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <Link to='/admin/funeral-homes/new' className='btn btn-sm btn-light-primary'>
              <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              New Funeral Home
            </Link>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  {false && (
                    <th className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          data-kt-check='true'
                          data-kt-check-target='.widget-9-check'
                        />
                      </div>
                    </th>
                  )}
                  {false && <th></th>}
                  <th className='min-w-150px'>Funeral Home Name</th>
                  <th className='min-w-50px text-left'>Widget Visible?</th>
                  <th className='min-w-140px'>Details</th>
                  {false && <th className='min-w-120px'>Progress</th>}
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {allFuneralHomes?.length === 0 ? (
                  <>
                    <tr>
                      <td colSpan={5}>
                        <div className='text-center pt-5'>No funeral homes found</div>
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    {allFuneralHomes.map((funeralHome) => {
                      return (
                        <FuneralHomeRow
                          key={`funeral-home-${funeralHome.unique_identifier}`}
                          funeralHome={funeralHome}
                        />
                      )
                    })}
                  </>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </div>
  )
}

export default AdminHome
