import { useCallback, useEffect, useRef, useState } from 'react'

import TieToFuneralHomeModal from './TieToFuneralHomeModal'
import { AllClientsAndConnectionsItems, FRMAccessToken, FRMLocation } from '../core/_admin_slack_response'
import { KTSVG } from '../../../_metronic/helpers'
import SelectFuneralHomeWrapper from '../../pages/obituaries/SelectFuneralHomeWrapper'
import FuneralHomeSwitcher from '../../../_metronic/layout/components/FuneralHomeSwitcher'
import { adminTieObituariesAiClientToFRMCompanyAndLocation, adminGetFRMPipelines, FRMPipelineItem } from '../core/_requests'
import { ApiError } from '../../modules/auth'

interface FuneralImageModalWrapperProps {
  frmLocation: FRMLocation
  accessToken: FRMAccessToken
  setFrmLocation: (frmLocation: FRMLocation) => void
  onClose: () => void
  clientsAndConnections: AllClientsAndConnectionsItems
}

const PipelineSelector: React.FC<{
  availablePipelines: FRMPipelineItem[],
  selectedId: string | null,
  setSelectedId: (id: string | null) => void,
}> = ({
  availablePipelines,
  selectedId,
  setSelectedId,
}) => {
    return <div>
      <select className='form-select form-select-solid'
        onChange={(e) => {
          setSelectedId(e.target.value)
        }}
        value={selectedId || ''}
      >
        <option value=''>Select pipeline</option>
        {availablePipelines.map((pipeline) => {
          return <option
            key={pipeline.id}
            value={pipeline.id}
          >
            {pipeline.name}
          </option>
        })}
      </select>
    </div>
  }

const TieToFuneralHomeModalWrapper: React.FC<FuneralImageModalWrapperProps> = ({
  frmLocation,
  accessToken,
  setFrmLocation,
  onClose,
  clientsAndConnections
}) => {

  const [saveError, setSaveError] = useState('')

  const [loadingPipelines, setLoadingPipelines] = useState(true)
  const [pipelineLoadingError, setPipelineLoadingError] = useState('')
  const [availablePipelines, setAvailablePipelines] = useState<FRMPipelineItem[]>([])

  const matchingClient = clientsAndConnections[frmLocation._id]?.client || null
  const matchingConnection = clientsAndConnections[frmLocation._id]?.connection || null

  const [selectedAtNeedPipelineId, setSelectedAtNeedPipelineId] = useState<string | null>(matchingConnection?.at_need_pipeline_id || null)
  const [selectedPreNeedPipelineId, setSelectedPreNeedPipelineId] = useState<string | null>(matchingConnection?.pre_need_pipeline_id || null)
  const [selectedEverythingElsePipelineId, setSelectedEverythingElsePipelineId] = useState<string | null>(
    matchingConnection?.everything_else_pipeline_id || null
  )

  const [saving, setSaving] = useState(false)

  const [selectedFuneralHomeUniqueId, setSelectedFuneralHomeUniqueId] = useState<string | null>(
    matchingClient ? matchingClient.unique_identifier : null
  )

  const canSave = true

  useEffect(() => {
    function onClickedBackdrop(e: any) {
      if (e.target?.id === 'modal_upload_funeral_home_image') {
        onClose()
      }
    }
    document.body.classList.add('modal-open')
    document.addEventListener('click', onClickedBackdrop)
    return () => {
      document.body.classList.remove('modal-open')
      document.removeEventListener('click', onClickedBackdrop)
    }
  }, [onClose])

  const onSave = useCallback(async () => {
    console.log(accessToken)
    setSaveError('')
    setSaving(true)
    try {
      await adminTieObituariesAiClientToFRMCompanyAndLocation({
        company_id: accessToken.company_id,
        location_id: frmLocation._id,
        funeral_home_unique_id: selectedFuneralHomeUniqueId,
        at_need_pipeline_id: selectedAtNeedPipelineId,
        pre_need_pipeline_id: selectedPreNeedPipelineId,
        everything_else_pipeline_id: selectedEverythingElsePipelineId,
      })
      // Reload the page :X :
      window.location.reload()
    } catch (error: any) {
      console.warn('error', error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setSaveError(errorMessage)
    } finally {
      setSaving(false)
    }
  }, [accessToken, frmLocation._id, selectedAtNeedPipelineId, selectedEverythingElsePipelineId, selectedFuneralHomeUniqueId, selectedPreNeedPipelineId])

  const loadAvailablePipelines = useCallback(async () => {
    if (!accessToken.company_id || !frmLocation._id) {
      return
    }
    setLoadingPipelines(true)
    setPipelineLoadingError('')
    try {
      const pipelines = await adminGetFRMPipelines(accessToken.company_id, frmLocation._id)
      setAvailablePipelines(pipelines)
    } catch (error: any) {
      console.warn('error', error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setPipelineLoadingError(errorMessage)
    } finally {
      setLoadingPipelines(false)
    }
  }, [accessToken.company_id, frmLocation._id])

  useEffect(() => {
    loadAvailablePipelines()
  }, [loadAvailablePipelines])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='modal_upload_funeral_home_image'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2>{`Tie ${frmLocation.name} to Obit.ai Client`}</h2>
              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => onClose()}>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body py-lg-5 px-lg-5' style={{ maxHeight: '70vh', overflowY: 'auto' }}>
              <div>
                Select funeral home:
              </div>
              <div>
                <FuneralHomeSwitcher
                  boringSelectInput
                  onChanged={(selectedFuneralHomeUniqueId) => {
                    setSelectedFuneralHomeUniqueId(selectedFuneralHomeUniqueId)
                  }}
                  hideCreation
                  startAsEmptyValue
                  initiallySelectedValue={matchingClient ? {
                    value: matchingClient.unique_identifier,
                    label: `${matchingClient.name}`,
                  } : null}
                />
              </div>
              {loadingPipelines ? <></> : <>
                {pipelineLoadingError ? <>
                  <div className='mt-2'>
                    Pipelines:
                  </div>
                  <div className='alert alert-danger mt-3'>
                    <div className='alert-text font-weight-bold text-center'>{pipelineLoadingError}</div>
                  </div>
                </> : <>
                  {availablePipelines.length === 0 ? <>
                    <div className='mt-2'>
                      Pipelines:
                    </div>
                    <div className='alert alert-info mt-3'>
                      <div className='alert-text font-weight-bold text-center'>No pipelines available</div>
                    </div>
                  </> : <>
                    <div className='mt-2'>
                      At-Need Pipeline:
                    </div>
                    <div>
                      <PipelineSelector
                        availablePipelines={availablePipelines}
                        selectedId={selectedAtNeedPipelineId}
                        setSelectedId={setSelectedAtNeedPipelineId}
                      />
                    </div>
                    <div className='mt-2'>
                      Pre-Need Pipeline:
                    </div>
                    <div>
                      <PipelineSelector
                        availablePipelines={availablePipelines}
                        selectedId={selectedPreNeedPipelineId}
                        setSelectedId={setSelectedPreNeedPipelineId}
                      />
                    </div>
                    <div className='mt-2'>
                      Everything Else Pipeline:
                    </div>
                    <div>
                      <PipelineSelector
                        availablePipelines={availablePipelines}
                        selectedId={selectedEverythingElsePipelineId}
                        setSelectedId={setSelectedEverythingElsePipelineId}
                      />
                    </div>
                  </>}
                </>}
              </>}
            </div>
            <div className='modal-footer'>
              <div className='d-flex flex-column flex-grow-1 px-5'>
                <div className='row'>
                  <div className='col-xl-12'>
                    {saveError && (
                      <div className='mb-0 alert alert-danger'>
                        <div className='alert-text font-weight-bold text-center'>{saveError}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack'>
                <div>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary me-3'
                    disabled={!canSave}
                    onClick={onSave}
                  >
                    {saving ? (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : (
                      <>
                        <span className='indicator-label'>Save Connection</span>
                        <span className='indicator-label'>
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default TieToFuneralHomeModalWrapper
