import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { ApiError } from '../../../auth'
import { saveObituaryComponent } from '../../core/_requests'
import { militaryServiceSchema } from '../../form_schema'
import { KnownFamilyMember, MilitaryService, Obituary } from '../../core/_models'
import FormTitle from './FormTitle'
import { IAvailableFormType, ObituaryWebsocket } from '../../../../obituaries/useObituaryWebsocket'
import useComplicatedFormBehavior from '../../../../obituaries/useComplicatedFormBehavior'
import FormItemsRenderer, { IItemProps } from './FormItemsRenderer'
import useObituaryPartialUpdater from '../../../../obituaries/useObituaryPartialUpdater'
import useObituaryFormUpdater from '../../../../obituaries/next/useObituaryFormUpdater'
import useObituaryChannel from '../../../../obituaries/next/useObituaryChannel'

const _militaryToFormikValues = (military: MilitaryService): MilitaryService => {
  return {
    militaryService: military.militaryService || '',
    notableAchievements: military.notableAchievements || '',
  }
}

function MilitaryServiceForm({
  obituary,
  setObituary,
  onSuccessfulSave,
  goBackUrl,
  obituaryWebsocket,
  userRelationshipToObituary,
}: {
  obituary: Obituary
  setObituary: (obituary: Obituary) => void
  onSuccessfulSave: (updatedObituary: Obituary) => void
  goBackUrl: string
  obituaryWebsocket: ObituaryWebsocket | null
  userRelationshipToObituary?: KnownFamilyMember | null
}) {

  const {
    obituaryChannel
  } = useObituaryChannel();
  const title = 'Military Service'

  const formik = useFormik<MilitaryService>({
    initialValues: _militaryToFormikValues(obituary.data_military_service || ({} as MilitaryService)),
    validationSchema: militaryServiceSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true)
      setStatus(undefined)
      try {
        const updatedObituary = await saveObituaryComponent(
          obituary.unique_identifier,
          'military',
          values
        )
        setObituary(updatedObituary)
        onSuccessfulSave(updatedObituary)
      } catch (error: any) {
        console.warn(error)
        const errorMessage =
          error instanceof ApiError ? error.message : 'Unable to save military service'
        setStatus(errorMessage)
      }
    },
  })

  const formError = formik.status

  const items: IItemProps[] = [
    {
      label: !userRelationshipToObituary?.is_self
        ? 'Share any details you want mentioned about their service, including years served, locations, service branch, ranks, and honors and awards.'
        : 'Share any details you want mentioned about your service, including years served, locations, service branch, ranks, and honors and awards.',
      name: 'militaryService',
      type: 'text',
      value: formik.values.militaryService,
      autoFocus: false,
      required: false,
      error: formik.errors.militaryService,
      touched: formik.touched.militaryService,
      isTextArea: true,
      placeholder: 'e.g. US Army 1955-1960 / Field Artillery / Bronze Star Medal...',
      additionalQuestionId: 'military.militaryService',
    } as IItemProps,
    {
      label: !userRelationshipToObituary?.is_self
        ? 'Would you like to share any notable facts, achievements, or stories from their time in the military?'
        : 'Would you like to share any notable facts, achievements, or stories from your time in the military?',
      name: 'notableAchievements',
      type: 'text',
      value: formik.values.notableAchievements,
      autoFocus: false,
      required: false,
      error: formik.errors.notableAchievements,
      touched: formik.touched.notableAchievements,
      isTextArea: true,
      placeholder:
        'e.g. provided medical assistance to others / helped their unit secure a strategic position / was known as the best shot of their unit...',
      additionalQuestionId: 'military.notableAchievements',
    } as IItemProps,
  ]

  const formType: IAvailableFormType = 'military'
  const { editingFieldsByName } = useComplicatedFormBehavior(formType, obituaryWebsocket, formik)

  useObituaryPartialUpdater(obituary.unique_identifier, 'military', (updatedData: any) => {
    formik.setValues(_militaryToFormikValues(updatedData))
    obituary.data_military_service = updatedData // Should we call setObituary here? Hm
  })

  useObituaryFormUpdater({
    obituaryChannel,
    onChange: (event) => {
      if (event.formType === 'military') {
        formik.setFieldValue(event.formName, event.formValue)
      }
    }
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormTitle title={title} />
        {formError && (
          <div className='alert alert-danger'>
            <div className='alert-text'>{formError}</div>
          </div>
        )}
        <FormItemsRenderer
          formType={formType}
          formik={formik}
          obituaryWebsocket={obituaryWebsocket}
          setObituary={setObituary}
          items={items}
          editingFieldsByName={editingFieldsByName}
          receivedQuestions={obituary.data_military_service?.receivedQuestions || undefined}
          obituaryChannel={obituaryChannel}
        />
        <div className='row mt-10'>
          <div className='col-12 d-flex justify-content-between align-items-center'>
            <Link to={goBackUrl} className='btn btn-secondary me-3'>
              Back
            </Link>
            <button type='submit' className='btn btn-primary'>
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default MilitaryServiceForm
