/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useState } from 'react'

import { KTSVG } from '../../../_metronic/helpers'
import { ApiError } from '../../modules/auth'
import { FuneralHome } from '../../modules/obituaries/core/_models'
import Cropper from './Cropper'

import { adminUploadLogoForFuneralHome } from '../core/_requests'

interface UploadImagesModalProps {
  funeralHome: FuneralHome
  setFuneralHome: (funeralHome: FuneralHome) => void
  file: File
  onClose: () => void
}

const FuneralImageModal: FC<UploadImagesModalProps> = ({
  funeralHome,
  setFuneralHome,
  file,
  onClose,
}) => {
  const [savingLogo, setSavingLogo] = useState(false)

  const [uploadError, setUploadError] = useState('')

  const [croppedBlob, setCroppedBlob] = useState<Blob | null>(null)

  const canSaveLogo = !!croppedBlob

  const onSave = useCallback(async () => {
    if (!croppedBlob) {
      return
    }
    setSavingLogo(true)
    setUploadError('')
    try {
      const { funeralHome: updatedFuneralHome } = await adminUploadLogoForFuneralHome(
        funeralHome.unique_identifier,
        file.name,
        croppedBlob
      )
      setFuneralHome(updatedFuneralHome)
    } catch (error: any) {
      console.warn('error', error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setUploadError(errorMessage)
    } finally {
      setSavingLogo(false)
    }
  }, [croppedBlob, file.name, funeralHome.unique_identifier, setFuneralHome])

  const onCropChange = useCallback((blob: Blob | null) => {
    setCroppedBlob(blob)
  }, [])

  return (
    <div className='modal-dialog modal-dialog-centered mw-900px'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>{`Crop and save logo for ${funeralHome.name}`}</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => onClose()}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body py-lg-5 px-lg-5' style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          <Cropper file={file} onCropChange={onCropChange} />
        </div>

        <div className='modal-footer'>
          <div className='d-flex flex-column flex-grow-1 px-5'>
            <div className='row'>
              <div className='col-xl-12'>
                {uploadError && (
                  <div className='mb-0 alert alert-danger'>
                    <div className='alert-text font-weight-bold text-center'>{uploadError}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='d-flex flex-stack'>
            <div>
              <button
                type='button'
                className='btn btn-lg btn-primary me-3'
                disabled={!canSaveLogo}
                onClick={onSave}
              >
                {savingLogo ? (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <>
                    <span className='indicator-label'>Save Logo for Funeral Home</span>
                    <span className='indicator-label'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FuneralImageModal
