import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { ApiError } from '../../../auth'
import { saveObituaryComponent } from '../../core/_requests'
import { relativesSchema } from '../../form_schema'
import { KnownFamilyMember, Obituary, Relatives } from '../../core/_models'
import FormTitle from './FormTitle'
import { IAvailableFormType, ObituaryWebsocket } from '../../../../obituaries/useObituaryWebsocket'
import FormItemsRenderer, { IItemProps } from './FormItemsRenderer'
import useComplicatedFormBehavior from '../../../../obituaries/useComplicatedFormBehavior'
import { useAuth } from '../../../auth/core/Auth'
import useObituaryPartialUpdater from '../../../../obituaries/useObituaryPartialUpdater'
import useObituaryFormUpdater from '../../../../obituaries/next/useObituaryFormUpdater'
import useObituaryChannel from '../../../../obituaries/next/useObituaryChannel'

function RelativesForm({
  obituary,
  setObituary,
  onSuccessfulSave,
  goBackUrl,
  obituaryWebsocket,
  userRelationshipToObituary,
}: {
  obituary: Obituary
  setObituary: (obituary: Obituary) => void
  onSuccessfulSave: (updatedObituary: Obituary) => void
  goBackUrl: string
  obituaryWebsocket: ObituaryWebsocket | null
  userRelationshipToObituary?: KnownFamilyMember | null
}) {

  const {
    obituaryChannel
  } = useObituaryChannel();

  const { currentPhoenixUserPhone } = useAuth()

  const formik = useFormik<Relatives>({
    initialValues: {
      survivors: obituary.data_relatives?.survivors || '',
      passedAway: obituary.data_relatives?.passedAway || '',
    },
    validationSchema: relativesSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true)
      setStatus(undefined)
      try {
        const updatedObituary = await saveObituaryComponent(obituary.unique_identifier, 'relatives', values)
        setObituary(updatedObituary)
        onSuccessfulSave(updatedObituary)
      } catch (error: any) {
        console.warn(error)
        const errorMessage = error instanceof ApiError ? error.message : 'Unable to save relatives'
        setStatus(errorMessage)
      }
    },
  })

  const formError = formik.status

  const items: IItemProps[] = [
    {
      label: !userRelationshipToObituary?.is_self
        ? currentPhoenixUserPhone?.isFuneralHomeUser()
          ? `Share names and relationships of ${obituary.getSimpleName() || 'your client'
          }'s survivors.`
          : `Share names and relationships of ${obituary.getSimpleName() || 'your loved one'
          }'s survivors. Don't forget to include yourself.`
        : 'Share names and relationships of your survivors.',
      name: 'survivors',
      type: 'text',
      value: formik.values.survivors,
      autoFocus: false,
      required: false,
      error: formik.errors.survivors,
      touched: formik.touched.survivors,
      isTextArea: true,
      placeholder:
        'Son Joe Doe (wife Jane)\nDaughter Jill Smith (husband Joe)\nSon James "Jim" Doe\n5 grandchildren\n1 great-grandchild',
    } as IItemProps,
    {
      label: !userRelationshipToObituary?.is_self
        ? currentPhoenixUserPhone?.isFuneralHomeUser()
          ? `Share any relatives of ${obituary.getSimpleName() || 'your client'
          } you want mentioned who have passed away.`
          : `Share any relatives of ${obituary.getSimpleName() || 'your loved one'
          } you want mentioned who have passed away.`
        : 'Share any relatives you want mentioned who have passed away.',
      name: 'passedAway',
      type: 'text',
      value: formik.values.passedAway,
      autoFocus: false,
      required: false,
      error: formik.errors.passedAway,
      touched: formik.touched.passedAway,
      isTextArea: true,
      placeholder: 'Wife Diana Doe\nParents Beatrice and Richard Doe',
    } as IItemProps,
  ]

  const formType: IAvailableFormType = 'relatives'
  const { editingFieldsByName } = useComplicatedFormBehavior(formType, obituaryWebsocket, formik)

  useObituaryPartialUpdater(obituary.unique_identifier, 'relatives', (updatedData: any) => {
    formik.setValues(updatedData)
    obituary.data_relatives = updatedData // Should we call setObituary here? Hm
  })

  useObituaryFormUpdater({
    obituaryChannel,
    onChange: (event) => {
      if (event.formType === 'relatives') {
        formik.setFieldValue(event.formName, event.formValue)
      }
    }
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormTitle title='Relatives' />
        {formError && (
          <div className='alert alert-danger'>
            <div className='alert-text'>{formError}</div>
          </div>
        )}
        <FormItemsRenderer
          formType={formType}
          formik={formik}
          obituaryWebsocket={obituaryWebsocket}
          items={items}
          editingFieldsByName={editingFieldsByName}
          textAreaRows={10}
          obituaryChannel={obituaryChannel}
        />
        <div className='row mt-10'>
          <div className='col-12 d-flex justify-content-between align-items-center'>
            <Link to={goBackUrl} className='btn btn-secondary me-3'>
              Back
            </Link>
            <button type='submit' className='btn btn-primary'>
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default RelativesForm
