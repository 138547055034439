import { useState, useCallback, useEffect } from "react"
import { KTSVG } from "../../_metronic/helpers"
import { CodeBlock } from "../../_metronic/partials"
import { FuneralHome } from "../modules/obituaries/core/_models"
import { getClientWidgetCode } from "../funeral_homes/dashboard/core/_requests"

interface ClientCodeBlockProps {
    client: FuneralHome
}

function ClientCodeBlock({ client }: ClientCodeBlockProps) {
    const [code, setCode] = useState<string | null>(null)

    const [error, setError] = useState<string | null>(null)

    const loadGettingStartedInformation = useCallback(async () => {
        if (!client) {
            setError(null)
            setCode(null)
            return
        }
        try {
            const currentWidgetScriptDetails = await getClientWidgetCode(client.unique_identifier)
            if (!currentWidgetScriptDetails || !currentWidgetScriptDetails.widget_script) {
                setError('Invalid response')
            } else {
                const finalCode = currentWidgetScriptDetails.widget_script;
                setCode(finalCode)
                setError(null)
            }
        } catch (error: any) {
            const errorMessage = error?.response?.data?.error || 'Unable to retrieve code'
            setError(errorMessage)
        }
    }, [client])

    useEffect(() => {
        loadGettingStartedInformation()
    }, [loadGettingStartedInformation])

    return (
        <>
            {error && (
                <>
                    <div className='alert alert-danger d-flex align-items-center p-5 mb-10'>
                        <span className='svg-icon svg-icon-2hx svg-icon-danger me-3'>
                            <KTSVG
                                path='/media/icons/duotune/general/gen044.svg'
                                className='svg-icon svg-icon-2hx svg-icon-dark me-4'
                            />
                        </span>
                        <div className='d-flex flex-column'>
                            <h5 className='mb-1'>Error</h5>
                            <span>{error}</span>
                        </div>
                    </div>
                </>
            )}
            {code && client && (
                <div>
                    <h4>{`Paste the following block of code anywhere on  ${client.name}'s website:`}</h4>
                    <CodeBlock language='markup' code={code} />
                </div>
            )}
        </>
    )
}

export default ClientCodeBlock
