/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

import SessionsByDayWidget from './SessionsByDayWidget'
import WidgetConversionsByDayWidget from './WidgetConversionsByDayWidget'

import DashboardWrapper from '../../pages/dashboard/DashboardWrapper'
import usePageTitle from '../../hooks/usePageTitle'

const FuneralHomeDashboard: FC = () => {
  return (
    <>
      <div className='row'>
        <div className='col-lg-6'>
          <SessionsByDayWidget />
        </div>
        <div className='col-lg-6'>
          <WidgetConversionsByDayWidget />
        </div>
      </div>
      {false && <DashboardWrapper />}
    </>
  )
}

const FuneralHomeDashboardWrapper = () => {
  usePageTitle({
    title: 'Dashboard',
    includeDefaultTitle: true,
  })

  return <>
    <FuneralHomeDashboard />
  </>
}

export default FuneralHomeDashboardWrapper
