/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import styled from 'styled-components'
import { useObituaryDetail } from './ObituaryDetail'
import ObituaryContributors from './ObituaryContributors'
import SectionInsights from './SectionInsights'
import TopInterests from './TopInterests'
import usePageTitle from '../../../hooks/usePageTitle'
import { Link } from 'react-router-dom'

interface ObituaryInsightsProps { }

function ObituaryInsights({ }: ObituaryInsightsProps) {
  const { obituary, obituaryWebsocket } = useObituaryDetail()

  useEffect(() => {
    if (obituaryWebsocket) {
      obituaryWebsocket.sendPageView('insights')
    }
  }, [obituaryWebsocket])

  usePageTitle({
    title: `${obituary.getSimpleName()}'s Obituary | Insights`,
  })

  return (
    <div>
      {false && 'tabsAreDisabled' && (
        <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 ps-4'>
          <li className='nav-item'>
            <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
              Contributors to this Obituary
            </a>
          </li>
          {false && 'disabledForNow' && (
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                Send to Newspaper
              </a>
            </li>
          )}
        </ul>
      )}
      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
          <div className='row mt-2'>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='col-6 col-md-3'>
                  <h2>Contributors</h2>
                  <div className='separator separator-dashed my-7'></div>
                  <ObituaryContributors />
                </div>
                {!obituary.hasWrittenBiography() ? (
                  <>
                    <div className='col-12 col-md-6'>
                      <div className='alert alert-warning'>
                        <div className='alert-text font-weight-bold text-dark'>
                          Before viewing top interests and insights, you need to{' '}
                          <Link
                            to={`/obituaries/${obituary.unique_identifier}/write`}
                            className='ms-1 me-1 text-dark text-decoration-underline'
                          >
                            write the obituary here
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='col-6 col-md-3'>
                      <h2>Top Interests</h2>
                      <div className='separator separator-dashed my-7'></div>
                      <TopInterests />
                    </div>
                    <div className='col-12 col-md-6'>
                      <SectionInsights />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
          <div className='row'>
            <div className='col-lg-12'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ObituaryInsights
