import {useEffect} from 'react'
import {Channel} from 'phoenix'

import {IAvailableFormType} from '../useObituaryWebsocket'

interface ObituaryFormChangedEvent {
  formType: IAvailableFormType
  formName: string
  formValue: string
}

function useObituaryFormUpdater({
  obituaryChannel,
  onChange,
}: {
  obituaryChannel: Channel | null
  onChange: (data: ObituaryFormChangedEvent) => void
}) {
  useEffect(() => {
    if (!obituaryChannel) {
      return
    }
    obituaryChannel.on('obituary_form_name_changed', (data: any) => {
      if (data?.payload) {
        const formType = data.payload.form
        const formName = data.payload.name
        const formValue = data.payload.value
        onChange({
          formType: formType,
          formName: formName,
          formValue: formValue,
        } as ObituaryFormChangedEvent)
      }
    })
  }, [obituaryChannel, onChange])
}

export default useObituaryFormUpdater
