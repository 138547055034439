import { useJourneyDetail } from "./_JourneyProvider"
import { replaceNewLinesWithParagraphs } from "../ObituaryWriteSection";

function JourneyFamilySectionDisplay() {
    const {
        journey
    } = useJourneyDetail()

    if (!journey) {
        return null;
    }

    return <div dangerouslySetInnerHTML={{ __html: replaceNewLinesWithParagraphs(journey.data.latest_family_section?.response_string || 'No family section yet') }} />
}

export default JourneyFamilySectionDisplay