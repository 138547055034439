export class JourneyOnboardingSnapshot {
  current_user_relationship_to_other_person: string | null
  current_users_email: string
  current_users_phone: string
  current_users_full_name: string
  other_person_has_passed: boolean | null
  other_persons_full_name: string

  constructor(data: any) {
    this.current_user_relationship_to_other_person = data.current_user_relationship_to_other_person
    this.current_users_email = data.current_users_email
    this.current_users_phone = data.current_users_phone
    this.current_users_full_name = data.current_users_full_name
    this.other_person_has_passed = data.other_person_has_passed
    this.other_persons_full_name = data.other_persons_full_name
  }
}

export class JourneyQuestionGeneratedJson {
  question?: string
  suggested_response_1?: string
  suggested_response_2?: string
  suggested_response_3?: string

  constructor(data: any) {
    this.question = data.question
    this.suggested_response_1 = data.suggested_response_1
    this.suggested_response_2 = data.suggested_response_2
    this.suggested_response_3 = data.suggested_response_3
  }
}

export class JourneyTribute {
  unique_identifier?: string
  response_string: string

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier || undefined
    this.response_string = data.response_string || ''
  }
}

export class JourneyPoem {
  unique_identifier: string
  response_string: string
  poem_type_value: string

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.response_string = data.response_string || ''
    this.poem_type_value = data.poem_type_value
  }
}

export class JourneyFamilySection {
  unique_identifier: string
  response_string: string

  inputted_living_relatives: string
  inputted_deceased_relatives: string

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.response_string = data.response_string || ''
    this.inputted_living_relatives = data.inputted_living_relatives || ''
    this.inputted_deceased_relatives = data.inputted_deceased_relatives || ''
  }
}

export class JourneyTextAndQuote {
  unique_identifier: string
  response_string: string

  text_and_quote_group_value: string
  text_and_quote_item_value: string

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.response_string = data.response_string || ''
    this.text_and_quote_group_value = data.text_and_quote_group_value
    this.text_and_quote_item_value = data.text_and_quote_item_value
  }
}

export class JourneyQuestion {
  unique_identifier?: string
  generated_json?: JourneyQuestionGeneratedJson

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier || undefined
    this.generated_json = data.generated_json
      ? new JourneyQuestionGeneratedJson(data.generated_json)
      : undefined
  }
}

export class JourneyData {
  current_stage?: undefined | 'restarted' | 'answer_questions'
  is_for_self?: boolean
  has_been_submitted?: boolean
  latest_onboarding_snapshot?: JourneyOnboardingSnapshot

  latest_question?: JourneyQuestion
  latest_tribute?: JourneyTribute
  latest_family_section?: JourneyFamilySection
  latest_poem?: JourneyPoem
  latest_text_and_quote?: JourneyTextAndQuote

  constructor(data: any) {
    this.current_stage = data.current_stage
    this.is_for_self = data.is_for_self
    this.has_been_submitted = data.has_been_submitted
    this.latest_onboarding_snapshot = data.latest_onboarding_snapshot
      ? new JourneyOnboardingSnapshot(data.latest_onboarding_snapshot)
      : undefined
    this.latest_question = data.latest_question
      ? new JourneyQuestion(data.latest_question)
      : undefined
    this.latest_tribute = data.latest_tribute ? new JourneyTribute(data.latest_tribute) : undefined
    this.latest_family_section = data.latest_family_section
      ? new JourneyFamilySection(data.latest_family_section)
      : undefined
    this.latest_poem = data.latest_poem ? new JourneyPoem(data.latest_poem) : undefined
    this.latest_text_and_quote = data.latest_text_and_quote
      ? new JourneyTextAndQuote(data.latest_text_and_quote)
      : undefined
  }
}

class JourneyAnswerData {
  question_payload: {
    question_text: string
    selected_items?: string[]
    inputted_text: string
  }

  constructor(data: any) {
    this.question_payload = data.question_payload
  }
}

export class JourneyAnswer {
  inserted_at: string
  updated_at: string
  unique_identifier: string
  data: JourneyAnswerData

  constructor(data: any) {
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.unique_identifier = data.unique_identifier
    this.data = new JourneyAnswerData(data.data)
  }
}

export type ITypeOfTributeTab = 'full-tribute' | 'family' | 'poetry' | 'text-and-quotes'

export const journeyDownloadableOptions: {label: string; value: ITypeOfTributeTab}[] = [
  {
    label: 'Full Tribute',
    value: 'full-tribute',
  },
  {
    label: 'Family',
    value: 'family',
  },
  {
    label: 'Poetry',
    value: 'poetry',
  },
  {
    label: 'Quotes & Scripture',
    value: 'text-and-quotes',
  },
]

export class Journey {
  inserted_at: string
  updated_at: string
  unique_identifier: string
  public_link: string
  download_link: string
  share_link: string
  shareable_code: string
  data: JourneyData
  progress_value: number
  progress_percentage: number

  full_journey_progress_percentage_value: number

  for_full_name?: string
  for_full_name_initials?: string

  tribute_headline?: string

  constructor(data: any) {
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.unique_identifier = data.unique_identifier
    this.public_link = data.public_link
    this.download_link = data.download_link
    this.share_link = data.share_link
    this.shareable_code = data.shareable_code
    this.data = new JourneyData(data.data)
    this.progress_value = data.progress_value
    this.progress_percentage = data.progress_percentage || 0
    this.full_journey_progress_percentage_value = data.full_journey_progress_percentage_value || 0
    this.for_full_name = data.for_full_name || undefined
    this.for_full_name_initials = data.for_full_name_initials || undefined
    this.tribute_headline = data.tribute_headline || undefined
  }

  getWelcomingTitleCopy(): string {
    if (this.data.is_for_self) {
      return 'Continue Your Tribute'
    }
    if (this.data.latest_onboarding_snapshot?.other_persons_full_name) {
      return `Continue ${this.data.latest_onboarding_snapshot?.other_persons_full_name}'s Tribute`
    }
    return 'Continue Tribute'
  }

  getWelcomingSubtitleCopy(): string {
    return 'Pick up where you left off.'
  }

  getWelcomingPath(): string {
    if (this.data.current_stage === 'answer_questions') {
      return `/journeys/${this.unique_identifier}`
    }
    return this.data.is_for_self ? '/start-tribute/self' : '/start-tribute/someone-else'
  }

  getDownloadLinkForSelection(selectedOptions: string[]) {
    // Our multi-select just uses an array of strings which match
    // against the values in journeyDownloadableOptions (but the labels)
    if (
      selectedOptions.length === 0 ||
      selectedOptions.length === journeyDownloadableOptions.length
    ) {
      return this.download_link
    }
    // We need to convert the select labels to query params which are the values
    // in journeyDownloadableOptions
    const specificDownloadOptions = selectedOptions
      .map((option) => {
        const matchingOption = journeyDownloadableOptions.find(
          (journeyDownloadableOption) => journeyDownloadableOption.label === option
        )
        if (!matchingOption) {
          return null
        }
        return matchingOption.value
      })
      .filter(Boolean)
    if (specificDownloadOptions.length === 0) {
      return this.download_link
    }
    return `${this.download_link}?onlyItems=${specificDownloadOptions.join(',')}`
  }

  getObituaryTypeString(): string {
    if (this.data.is_for_self) {
      return 'Pre-Need: Self'
    }
    if (this.data.latest_onboarding_snapshot?.other_person_has_passed) {
      return 'At-Need'
    }
    return 'Pre-Need: Someone Else'
  }

  isPreNeedObituary() {
    if (this.data.is_for_self) {
      return true
    }
    if (this.data.latest_onboarding_snapshot?.other_person_has_passed) {
      return false
    }
    return true
  }
}

class JourneyUploadData {}

export class JourneyUpload {
  unique_identifier: string
  inserted_at: string
  updated_at: string
  uploaded_file_url: string
  data: JourneyUploadData

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.uploaded_file_url = data.uploaded_file_url
    this.data = new JourneyUploadData()
  }
}

class JourneyPersonalizationData {
  video_crop?: {
    start_time_ms: number
    end_time_ms: number
  }

  background_choice: string
  background_image_url: string
  background_image_color: string
  selected_external_image_url: string

  form_values?: any // Temporary
  button_labels?: any // Temporary

  constructor(data: any) {
    this.video_crop = data.video_crop || undefined
    this.background_choice = data.background_choice || ''
    this.background_image_url = data.background_image_url || ''
    this.background_image_color = data.background_image_color || ''
    this.selected_external_image_url = data.selected_external_image_url || ''
    this.form_values = data.form_values || undefined
    this.button_labels = data.button_labels || undefined
  }
}

export class JourneyPersonalization {
  unique_identifier: string
  inserted_at: string
  updated_at: string
  recorded_for_node_id: string
  is_active_for_node_id: boolean
  data: JourneyPersonalizationData
  journey_upload?: JourneyUpload

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.recorded_for_node_id = data.recorded_for_node_id
    this.is_active_for_node_id = data.is_active_for_node_id
    this.data = new JourneyPersonalizationData(data.data)
    this.journey_upload = data.journey_upload ? new JourneyUpload(data.journey_upload) : undefined
  }
}

class JourneyFlowPublicData {
  type_of_flow:
    | 'simple'
    | 'simple-plan-ahead'
    | 'simple-additional-services'
    | 'plan-ahead-and-additional-services'

  nodes?: {
    'node-welcome-video'?: JourneyPersonalization | undefined
    'node-immediate-assistance-form'?: JourneyPersonalization | undefined
    'node-plan-ahead'?: {
      personalization?: JourneyPersonalization | undefined
      nodes?:
        | {
            'node-pre-planning-redirect': JourneyPersonalization | undefined
          }
        | undefined
    }
    'node-additional-services-video'?: {
      personalization?: JourneyPersonalization | undefined
      nodes?:
        | {
            'node-redirect-to-obituaries'?: JourneyPersonalization | undefined
            'node-redirect-to-sympathy-store'?: JourneyPersonalization | undefined
            'node-redirect-to-flowers-store'?: JourneyPersonalization | undefined
            'node-additional-offerings-video'?: {
              personalization?: JourneyPersonalization | undefined
              nodes?:
                | {
                    ai_obituary_writer?: {
                      enabled: boolean
                    }
                    'node-redirect-to-resources': JourneyPersonalization | null | undefined
                  }
                | undefined
            }
          }
        | undefined
    }
  }

  constructor(data: any) {
    // May move to separate classes for each type of flow at some point
    this.type_of_flow = data.type_of_flow
    this.nodes = data.nodes
  }
}

export class JourneyFlow {
  unique_identifier: string
  inserted_at: string
  updated_at: string
  public_data: JourneyFlowPublicData

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.public_data = new JourneyFlowPublicData(data.public_data)
  }
}

export class JourneyValidationError {
  message: string
  node_id: string

  constructor(data: any) {
    this.message = data.message
    this.node_id = data.node_id
  }
}

export class JourneyFlowPreviewResponse {
  validation_errors?: JourneyValidationError[]
  journey_flow?: JourneyFlow

  constructor(data: any) {
    this.validation_errors = data.validation_errors
      ? data.validation_errors.map(
          (validationError: any) => new JourneyValidationError(validationError)
        )
      : undefined
    this.journey_flow = data.journey_flow ? new JourneyFlow(data.journey_flow) : undefined
  }
}
