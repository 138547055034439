import {useEffect, useState} from 'react'
import {
  IAvailableFormType,
  ObituaryPageViewMessage,
  ObituaryUserLeft,
  ObituaryWebsocket,
  handleIncomingWebsocketMessage,
} from './useObituaryWebsocket'
import {useAuth} from '../modules/auth/core/Auth'
import {SimpleUserModel} from '../modules/auth/core/_models'

export type IUsersWhoAreOnPages = Record<IAvailableFormType, Record<string, SimpleUserModel>>

const defaultUsersOnPage = {
  personal_information: {},
  relatives: {},
  services: {},
  education: {},
  career: {},
  military: {},
  affiliations: {},
  'life-events': {},
  personality: {},
} as IUsersWhoAreOnPages

function useSimpleFormPageView(obituaryWebsocket: ObituaryWebsocket | null) {
  const {currentPhoenixUserPhone} = useAuth()

  const [usersOnPage, setUsersOnPage] = useState<IUsersWhoAreOnPages>({
    ...defaultUsersOnPage,
  })

  useEffect(() => {
    let workingUsersOnPage = {...defaultUsersOnPage}
    if (obituaryWebsocket) {
      if (obituaryWebsocket.websocket) {
        const handleMessage = (message: any) => {
          // First we parse the message. We use handleIncomingWebsocketMessage:
          const parsedMessage = handleIncomingWebsocketMessage(message)
          if (parsedMessage instanceof ObituaryPageViewMessage) {
            if (parsedMessage.user.unique_id === currentPhoenixUserPhone?.unique_identifier) {
              // Return because we don't care about our own page views
              return
            }
            // If the user is on a page, we add them to the usersOnPage state:
            if (parsedMessage.page_name) {
              // First we clear workingUsersOnPage of this user (they can only be on one page at a time, for now)
              Object.keys(workingUsersOnPage).forEach((formNameString) => {
                const formName = formNameString as IAvailableFormType
                const workingValue = workingUsersOnPage[formName]
                if (workingValue[parsedMessage.user.unique_id]) {
                  delete workingValue[parsedMessage.user.unique_id]
                }
              })
              workingUsersOnPage = {
                ...workingUsersOnPage,
                [parsedMessage.page_name]: {
                  ...workingUsersOnPage[parsedMessage.page_name],
                  [parsedMessage.user.unique_id]: parsedMessage.user,
                },
              }
              setUsersOnPage(workingUsersOnPage)
            }
            return
          } else if (parsedMessage instanceof ObituaryUserLeft) {
            // If the user left, we remove them from the usersOnPage state:
            Object.keys(workingUsersOnPage).forEach((formNameString) => {
              const formName = formNameString as IAvailableFormType
              const workingValue = workingUsersOnPage[formName]
              if (workingValue[parsedMessage.user.unique_id]) {
                delete workingValue[parsedMessage.user.unique_id]
              }
            })
            setUsersOnPage(workingUsersOnPage)
            return
          }
        }
        obituaryWebsocket.websocket.addEventListener('message', handleMessage)
        return () => {
          if (obituaryWebsocket.websocket) {
            obituaryWebsocket.websocket.removeEventListener('message', handleMessage)
          }
        }
      }
    }
  }, [currentPhoenixUserPhone?.unique_identifier, obituaryWebsocket])

  return {
    usersOnPage,
  }
}

export default useSimpleFormPageView
