import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';
import { Video, Circle, Upload } from 'react-feather'

import { NodeCTA, NodeCopy } from '../InteractiveEmployeeManage';
import NodeRecordVideoPreviewItem from '../components/NodeRecordVideoPreviewItem';
import { JourneyPersonalization } from '../../../../../funeral_homes/dashboard/core/journey_classes';
import { getActiveJourneyPersonalizationForNode, updateButtonLabelForNode } from '../../../../../funeral_homes/dashboard/core/_requests';
import { ApiError } from '../../../../auth';
import NodeUploadVideoPreviewItem from '../components/NodeUploadVideoPreviewItem';
import NodeManageVideoItem from '../components/NodeManageVideoItem';
import LazilyLoadedReactPlayer from '../components/LazilyLoadedReactPlayer';
import NodeSubmissionError from '../components/NodeSubmissionError';


// We aren't changing the border now that we have a NodeBackdrop, but it's here
// in case we want to change it back.

export const NodeContainer = styled.div<{ $highlighted: boolean }>`
    width: 365px;
    min-height: 200px;
    background-color: #fff;
    border: ${({ $highlighted }) => (!$highlighted || true) ? '1px solid #1a192b' : '3px solid #1a192b'};
    border-radius: 3px;
    cursor: auto;
    transition: all 0.2s ease-in-out;
    position: relative;

    &:hover {
    }
`;

const VideoContent = styled.div`
    width: 100%;
    height: 200px;
    position: relative;
    background-color: rgb(227, 227, 227);
    overflow: hidden;

    .no-video-icon {
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
        user-select: none;
        pointer-events: none;        
    }

    .video-actions {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    &:hover {
        .no-video-icon {
            opacity: 0;
        }

        .video-actions {
            opacity: 1;
        }
    }
`;

const VideoAction = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        scale: 1.02;
    }
`;

const VideoCopyContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 10px 0 10px;
`;

export const CopyTitle = styled.div`
    font-size: 18px;
    font-weight: 500;
`;

export const CopyBody = styled.div`
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
`;

export const QuestionsContent = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    flex-direction: column;
`;

const CallToActionItemContainer = styled.div<{ $backgroundColor: string, $textColor: string, $borderColor: string }>`
    width: 100%;
    height: 44px;
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    color: ${({ $textColor }) => $textColor};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 6px;
    padding: 10px;
    position: relative;
    border: 1px solid ${({ $borderColor }) => $borderColor};
    box-shadow: 0 1px 2px rgba(0,0,0,.2);
    border-radius: 3px;
    position: relative;
`;

const CallToActionItemInputForm = styled.form`
    display: flex;
    justify-content: space-between;
    width: 100%;

    input {
        height: 38px;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
    }
`;

const CallToActionItemLabel = styled.div`
    font-size: 14px;
    font-weight: 500;
`;

export const CallToActionItem = ({
    nodeId,
    defaultCTALabel,
    label: defaultLabel, handleId, backgroundColor, textColor, borderColor,
}: {
    nodeId: string,
    defaultCTALabel: string,
    label: string, handleId: string, backgroundColor: string, textColor: string, borderColor: string
}) => {

    const inputRef = useRef<HTMLInputElement>(null);

    const [editing, setEditing] = useState(false);

    const [label, setLabel] = useState(defaultLabel);

    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const onSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (submitting) {
            return;
        }
        const updatedValue = inputRef.current?.value || '';
        setSubmitting(true);
        setErrorMessage(null);
        try {
            await updateButtonLabelForNode(nodeId, {
                handleId,
                label: updatedValue,
            });
            setSuccessMessage('Button text updated')
            setLabel(updatedValue ? updatedValue : defaultCTALabel);
            setEditing(false);
        } catch (error: any) {
            console.warn('error', error)
            const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
            setErrorMessage(errorMessage)
        } finally {
            setSubmitting(false)
        }
    }, [defaultCTALabel, handleId, nodeId, submitting]);

    if (editing) {
        return <CallToActionItemContainer
            $backgroundColor={"white"} $textColor={"#333"} $borderColor={"#333"}
            onClick={() => { }}
        >
            <CallToActionItemInputForm className='d-flex' onSubmit={onSubmit}>
                <input
                    ref={inputRef}
                    type='text' className='form-control' placeholder={`(defaults to "${defaultCTALabel}")`}
                    defaultValue={label !== defaultCTALabel ? label : ''}
                />
                <button
                    type='button'
                    className='btn btn-secondary btn-sm ms-1' onClick={() => {
                        setEditing(false)
                        setSuccessMessage(null)
                    }}>
                    ❌
                </button>
                <button
                    type='submit'
                    className='btn btn-primary btn-sm ms-1'
                >
                    ✅
                </button>
            </CallToActionItemInputForm>
            <Handle type="source" position={Position.Right} id={handleId} />
            <div style={{ position: 'absolute', left: 0, right: 0, top: 0, zIndex: 1 }}>
                <NodeSubmissionError message={errorMessage} type="error" />
            </div>
        </CallToActionItemContainer>
    }

    return (
        <CallToActionItemContainer
            $backgroundColor={backgroundColor} $textColor={textColor} $borderColor={borderColor}
            onClick={() => setEditing(true)}
        >
            <CallToActionItemLabel>{label}</CallToActionItemLabel>
            <Handle type="source" position={Position.Right} id={handleId} />
            <div style={{ position: 'absolute', left: 0, right: 0, top: 0, zIndex: 1 }}>
                <NodeSubmissionError message={successMessage} type="success" />
            </div>
        </CallToActionItemContainer>
    );
};

function VideoRecordAndUploadButtons({ setRecordVideoPreviewIsActive, setUploadVideoPreviewIsActive }: { setRecordVideoPreviewIsActive: (value: boolean) => void, setUploadVideoPreviewIsActive: (value: boolean) => void }) {
    return (
        <div
            className='video-actions'
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}
        >
            <VideoAction onClick={() => setRecordVideoPreviewIsActive(true)}>
                <Circle />
                <div className='text-center mt-2'>
                    Record a Video
                </div>
            </VideoAction>
            <VideoAction onClick={() => setUploadVideoPreviewIsActive(true)}>
                <Upload />
                <div className='text-center mt-2'>
                    Upload a Video
                </div>
            </VideoAction>
        </div>
    );
}

function NodeVideoWithQuestions({ id: nodeId, data, }: { id: string, data: any }) {

    const copy = data.copy as NodeCopy;

    const targetNodeId = data?.targetNodeId;

    const [recordVideoPreviewIsActive, setRecordVideoPreviewIsActive] = useState(false);
    const [uploadVideoPreviewIsActive, setUploadVideoPreviewIsActive] = useState(false);

    const [managingVideoView, setManagingVideoView] = useState<boolean>(false);

    const [loadingNodePersonalization, setLoadingNodePersonalization] = useState(true);
    const [journeyPersonalizationForNode, setJourneyPersonalizationForNode] = useState<JourneyPersonalization | null>(null);
    const [loadingError, setLoadingError] = useState<string | null>(null);

    // It's important to set these after we retrieve the node data,
    // just in case they depend on the node data.

    const [ctas, setCtas] = useState<NodeCTA[]>([]);

    useEffect(() => {
        async function loadForNodeId() {
            try {
                const result = await getActiveJourneyPersonalizationForNode(nodeId)
                setJourneyPersonalizationForNode(result);
                setCtas(data?.ctas as NodeCTA[]);
            } catch (error: any) {
                console.warn('error', error)
                const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
                setLoadingError(errorMessage)
            } finally {
                setLoadingNodePersonalization(false)
            }
        }
        loadForNodeId()
    }, [data?.ctas, nodeId]);

    useEffect(() => {
        if (data.journeyPersonalization) {
            // Update the node's personalization:
            setJourneyPersonalizationForNode(data.journeyPersonalization);
            setRecordVideoPreviewIsActive(false);
            setUploadVideoPreviewIsActive(false);
        }
    }, [data.journeyPersonalization]);

    return (
        <NodeContainer $highlighted={data?.highlightedNodeId === nodeId}>
            {targetNodeId && <Handle type="target" position={Position.Left} id={targetNodeId} />}
            <VideoContent>
                {loadingNodePersonalization ? <></> : <>
                    {journeyPersonalizationForNode && journeyPersonalizationForNode.journey_upload?.uploaded_file_url ? <>
                        <LazilyLoadedReactPlayer
                            autoPlay={false}
                            loop={false}
                            muted={false}
                            controls
                            videoUrl={journeyPersonalizationForNode.journey_upload.uploaded_file_url}
                        />
                    </> : <>
                        <VideoRecordAndUploadButtons
                            setRecordVideoPreviewIsActive={setRecordVideoPreviewIsActive}
                            setUploadVideoPreviewIsActive={setUploadVideoPreviewIsActive}
                        />
                        <div className='no-video-icon' style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                                <Video size={50} color="#b1b1b6" />
                            </div>
                        </div>
                    </>}
                </>}
                {loadingError && <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                        <div className='text-center'>
                            {loadingError}
                        </div>
                    </div>
                </div>}
            </VideoContent>
            <div
                onClick={() => {
                    setManagingVideoView(true)
                }}
                className='text-center' style={{ cursor: 'pointer', padding: '10px', fontSize: '14px', fontWeight: 500, color: '#b1b1b6' }}
            >
                Manage Video
            </div>
            {!journeyPersonalizationForNode ? <VideoCopyContent className='mt-4'>
                <CopyTitle>
                    {copy.title}
                </CopyTitle>
                <CopyBody>
                    {copy.body}
                </CopyBody>
            </VideoCopyContent> : <VideoCopyContent>
                <CopyTitle>
                    {copy.title}
                </CopyTitle>
                <CopyBody>
                    {copy.bodyOnceUploaded}
                </CopyBody>
            </VideoCopyContent>}
            {ctas && <QuestionsContent>
                {ctas.map((cta: NodeCTA) => {
                    const buttonLabel = journeyPersonalizationForNode?.data?.button_labels?.[cta.handleId] || cta.label;
                    return (
                        <CallToActionItem
                            key={`${nodeId}-${cta.handleId}`}
                            nodeId={nodeId}
                            defaultCTALabel={cta.label}
                            label={buttonLabel}
                            handleId={cta.handleId}
                            backgroundColor={cta.backgroundColor}
                            textColor={cta.textColor}
                            borderColor={cta.borderColor}
                        />
                    );
                })}
            </QuestionsContent>}
            {recordVideoPreviewIsActive && <NodeRecordVideoPreviewItem
                nodeId={nodeId}
                onGoBack={() => {
                    if (journeyPersonalizationForNode?.journey_upload?.uploaded_file_url) {
                        setManagingVideoView(true)
                    } else {
                        setRecordVideoPreviewIsActive(false)
                    }
                }}
                handleBeginRecording={data.handleBeginRecording}
                recordingIsInProgress={data.recordingIsInProgress}
                activeUserMediaStream={data.activeUserMediaStream}
                setActiveUserMediaStream={data.setActiveUserMediaStream}
            />}
            {uploadVideoPreviewIsActive && <NodeUploadVideoPreviewItem
                nodeId={nodeId}
                onGoBack={() => {
                    if (journeyPersonalizationForNode?.journey_upload?.uploaded_file_url) {
                        setManagingVideoView(true)
                    } else {
                        setUploadVideoPreviewIsActive(false)
                    }
                }}
                onSuccessfulUpload={(uploadedJourneyPersonalization: JourneyPersonalization) => {
                    setJourneyPersonalizationForNode(uploadedJourneyPersonalization)
                    setUploadVideoPreviewIsActive(false)
                }}
            />}
            {managingVideoView && <NodeManageVideoItem
                nodeId={nodeId}
                journeyPersonalization={journeyPersonalizationForNode}
                setJourneyPersonalizationForNode={setJourneyPersonalizationForNode}
                onGoBack={() => {
                    setManagingVideoView(false)
                    setRecordVideoPreviewIsActive(false)
                    setUploadVideoPreviewIsActive(false)
                }}
                onRecordVideo={() => {
                    setRecordVideoPreviewIsActive(true)
                    setManagingVideoView(false)
                }}
                onUploadVideo={() => {
                    setUploadVideoPreviewIsActive(true)
                    setManagingVideoView(false)
                }}
            />}
        </NodeContainer>
    );
}

export default NodeVideoWithQuestions;