import { useCallback, useEffect, useState } from 'react'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ApiError } from '../../../app/modules/auth'
import { useAuth } from '../../../app/modules/auth/core/Auth'
import { adminUpdateCurrentUserClient, getAllAvailableFuneralHomes } from '../../../app/funeral_homes/dashboard/core/_requests'

const StyledSelectContainer = styled.div`
  padding-right: 5px;
  width: 500px;

  @media (max-width: 576px) { // or your custom breakpoint for xs
    width: 100%;
  }
`

function FuneralHomeSwitcher({
  hideCreation = false,
  onChanged = undefined,
  menuPlacement = 'auto',
  boringSelectInput = false,
  startAsEmptyValue = false,
  initiallySelectedValue = null
}: {
  hideCreation?: boolean,
  onChanged?: (value: string) => void,
  menuPlacement?: 'auto' | 'bottom' | 'top',
  boringSelectInput?: boolean,
  startAsEmptyValue?: boolean,
  initiallySelectedValue?: { value: string; label: string } | null
}) {
  const navigate = useNavigate()

  const { currentPhoenixUser } = useAuth()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const [selectedValue, setSelectedValue] = useState<{ value: string; label: string }>(initiallySelectedValue || {
    label: '(Select a funeral home)',
    value: '',
  })

  const [selectableFuneralHomes, setSelectableFuneralHomes] = useState<
    { value: string; label: string }[]
  >([])

  const onChange = async (value: string) => {
    if (value === 'new') {
      navigate('/admin/funeral-homes/new')
      // reset the selected value to the current funeral home:
      setSelectedValue({
        value: selectableFuneralHomes[0].value,
        label: selectableFuneralHomes[0].label,
      })
    } else if (onChanged) {
      onChanged(value)
    } else {
      setLoading(true)
      try {
        await adminUpdateCurrentUserClient(
          value
        )
        // reload the page, but only after a successful update:
        window.location.reload()
      } catch (exception) {
        console.error(exception)
        const errorMessage = exception instanceof ApiError ? exception.message : 'Something went wrong'
        setError(errorMessage)
        setLoading(false)
      }
    }
  }

  const loadMemberships = useCallback(async () => {
    try {
      const funeralHomes = await getAllAvailableFuneralHomes()
      let currentFuneralHome = funeralHomes.find(
        (funeralHome) => funeralHome.unique_identifier === currentPhoenixUser?.current_client?.unique_identifier
      )
      if (!currentFuneralHome) {
        currentFuneralHome = funeralHomes[0]
      }
      if (initiallySelectedValue) {
        setSelectedValue(initiallySelectedValue)
        if (onChanged) {
          onChanged(initiallySelectedValue.value)
        }
      } else if (!startAsEmptyValue) {
        setSelectedValue({
          value: currentFuneralHome.unique_identifier,
          label: `${currentFuneralHome.name}`,
        })
        if (onChanged) {
          onChanged(currentFuneralHome.unique_identifier)
        }
      }
      const updatedFuneralHomes = funeralHomes.map((funeralHome) => ({
        value: funeralHome.unique_identifier,
        label: `${funeralHome.name}`,
      }))
      if (!hideCreation && (currentPhoenixUser?.is_admin)) {
        updatedFuneralHomes.push({
          value: 'new',
          label: `(Create a new funeral home)`,
        })
      }
      if (startAsEmptyValue) {
        // Add an empty value to the top of the list:
        updatedFuneralHomes.unshift({
          value: '',
          label: '(Select a funeral home)',
        })
      }
      setSelectableFuneralHomes(updatedFuneralHomes)
      setError(null)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPhoenixUser])

  useEffect(() => {
    loadMemberships()
  }, [loadMemberships])

  if (loading) {
    return <></>
  }

  if (error) {
    return (
      <div className='me-5'>
        <div className='alert alert-danger' role='alert'>
          {error}
        </div>
      </div>
    )
  }

  if (boringSelectInput) {
    return (
      <select
        className='form-select form-select-solid form-select-sm'
        aria-label='Select a funeral home'
        value={selectedValue.value}
        onChange={(e) => {
          setSelectedValue({
            value: e.target.value,
            label: e.target.value,
          })
          onChange(e.target.value)
        }}
      >
        {selectableFuneralHomes.map((funeralHome) => (
          <option key={funeralHome.value} value={funeralHome.value}>
            {funeralHome.label}
          </option>
        ))}
      </select>
    )
  }

  return (
    <StyledSelectContainer className='menu-item'>
      <Select
        required
        options={selectableFuneralHomes}
        value={selectedValue}
        onChange={(item: { value: string; label: any }) => {
          if (!item) {
            return
          }
          setSelectedValue({
            value: item.value,
            label: item.label,
          })
          onChange(item.value)
        }}
        menuPlacement={menuPlacement}
        styles={{
          control: (baseStyles: any) => ({
            ...baseStyles,
          }),
          // selected option:
          singleValue: (baseStyles: any) => ({
            ...baseStyles,
            fontWeight: 'normal',
            color: 'var(--kt-gray-600)',
          }),
          // selectable options:
          option: (baseStyles: any) => ({
            ...baseStyles,
            fontWeight: 'normal',
          }),
        }}
      />
    </StyledSelectContainer>
  )
}

export default FuneralHomeSwitcher
