import axios from 'axios'
import {AdminPendingFuneralHomesResponse} from './_models'
import {FuneralHomeWithMemberships} from '../../modules/obituaries/core/_models'
import {UserModel} from '../../modules/auth/core/_models'
import {
  AdminSlackResponse,
  AdminFRMResponse,
  AdminFRMLocationsResponse,
  FRMAccessToken,
} from './_admin_slack_response'

const API_URL = process.env.REACT_APP_NEXT_API_URL

export function adminGetFuneralHomes(searchText: string) {
  return axios
    .get(`${API_URL}/admin/funeral-homes/?q=${searchText}`)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return new AdminPendingFuneralHomesResponse(responseData.data)
    })
}

export function adminUpdateFuneralHomeWidgetStatus(unique_id: string, widgetIsActive: boolean) {
  return axios
    .post(`${API_URL}/admin/funeral-homes/${unique_id}/update-widget-status`, {
      widgetIsActive,
    })
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return {
        funeralHome: new FuneralHomeWithMemberships(responseData.data.client),
      }
    })
}

export function adminUploadLogoForFuneralHome(unique_id: string, filename: string, blob: Blob) {
  const formData = new FormData()
  formData.append('logo', blob, filename)

  return axios
    .post(`${API_URL}/admin/funeral-homes/${unique_id}/upload-logo/`, formData)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return {
        funeralHome: new FuneralHomeWithMemberships(responseData.data.funeral_home),
        user: new UserModel(responseData.data.user),
      }
    })
}

export function adminGetSlackConfiguration() {
  return axios
    .get(`${API_URL}/admin/slack-configuration/`)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return new AdminSlackResponse(responseData.data)
    })
}

export function adminUpdateSlackConfiguration(data: any) {
  return axios
    .post(`${API_URL}/admin/slack-configuration/update/`, data)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return new AdminSlackResponse(responseData.data)
    })
}

export function adminTestSlackCategory(data: any) {
  return axios
    .post(`${API_URL}/admin/slack-configuration/test-category/`, data)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return responseData.data
    })
}

export function adminGetFRMConfiguration() {
  return axios
    .get(`${API_URL}/admin/frm-configuration`)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return new AdminFRMResponse(responseData.data)
    })
}

export function adminUpdateFRMConfiguration(data: any) {
  return axios
    .post(`${API_URL}/admin/frm-configuration/update`, data)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return new AdminFRMResponse(responseData.data)
    })
}

export function adminSubmitFRMOauthCode(code: string) {
  return axios
    .post(`${API_URL}/admin/frm-configuration/submit-oauth-code`, {code})
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return new AdminFRMResponse(responseData.data)
    })
}

export function getListOfLocationsFromTheFRM(queryText: string) {
  return axios
    .get(`${API_URL}/admin/frm-configuration/list-of-locations?q=${queryText}`)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      // responseData.data.clients_and_connections is a dict of {string client id: ClientsAndConnectionsItem}
      return new AdminFRMLocationsResponse(responseData.data)
    })
}

export function adminGetFRMOauthCodeByUniqueID(unique_id: string) {
  return axios
    .get(`${API_URL}/admin/frm-configuration/get-oauth-code/${unique_id}`)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return new FRMAccessToken(responseData.data.frm_oauth_access_token)
    })
}

export function saveCompanyNameForTokenUniqueId(unique_id: string, company_name: string) {
  return axios
    .post(`${API_URL}/admin/frm-configuration/save-oauth-code-company-name/${unique_id}`, {
      company_name,
    })
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return responseData.data
    })
}

export function adminUpdateCurrentUserFRMCompanyID(updatedFRMCompanyId: string) {
  return axios
    .post(`${API_URL}/admin/frm-configuration/update-current-user-frm-company-id`, {
      frm_company_id: updatedFRMCompanyId,
    })
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return responseData.data
    })
}

export interface CompanyIdAndName {
  company_id: string
  company_name: string
}

export function adminGetAvailableFRMCompanies() {
  return axios
    .get(`${API_URL}/admin/frm-configuration/get-available-frm-companies`)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return {
        company_ids_and_names: responseData.data.company_ids_and_names as CompanyIdAndName[],
      }
    })
}

export function adminTieObituariesAiClientToFRMCompanyAndLocation(data: any) {
  return axios
    .post(
      `${API_URL}/admin/frm-configuration/tie-obituaries-ai-client-to-frm-company-and-location`,
      data
    )
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return responseData.data
    })
}

export interface FRMPipelineItem {
  id: string
  name: string
}

export function adminGetFRMPipelines(companyId: string, locationId: string) {
  return axios
    .get(
      `${API_URL}/admin/frm-configuration/get-pipelines?company_id=${companyId}&location_id=${locationId}`
    )
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return responseData.data.pipelines ? (responseData.data.pipelines as FRMPipelineItem[]) : []
    })
}
