import {useEffect, useState} from 'react'
import {
  ObituaryMoreQuestionsUpdate,
  ObituaryWebsocket,
  handleIncomingWebsocketMessage,
} from './useObituaryWebsocket'
import {SimpleUserModel} from '../modules/auth/core/_models'
import {Obituary} from '../modules/obituaries/core/_models'
import {useAuth} from '../modules/auth/core/Auth'

// export type IQuestionsBeingGenerated = ...

// IQuestionsBeingGenerated is basically a dict of {based_on_attribute: {status: string, message: string, user: SimpleUserModel}}
// We can only ask questions once per attribute ... status can be "started", "failed", "success". Message can be anything

export type IQuestionsBeingGenerated = Record<
  string,
  {status: string; message: string; user: SimpleUserModel}
>

const defaultQuestionsBeingGenerated: IQuestionsBeingGenerated = {}

function useMoreQuestions(
  obituaryWebsocket: ObituaryWebsocket | null,
  obituaryUpdatedCallback: (obituary: Obituary) => void
) {
  const {currentPhoenixUserPhone} = useAuth()

  const [questionsBeingGenerated, setQuestionsBeingGenerated] = useState<IQuestionsBeingGenerated>({
    ...defaultQuestionsBeingGenerated,
  })

  useEffect(() => {
    let workingQuestionsBeingGenerated = {
      ...defaultQuestionsBeingGenerated,
    }
    if (obituaryWebsocket) {
      if (obituaryWebsocket.websocket) {
        const handleMessage = (message: any) => {
          // First we parse the message. We use handleIncomingWebsocketMessage:
          const parsedMessage = handleIncomingWebsocketMessage(message)
          if (!parsedMessage || !currentPhoenixUserPhone) {
            // Warnings are logged up-stream of this function, so we can just
            // return here.
            return
          }
          if (parsedMessage instanceof ObituaryMoreQuestionsUpdate) {
            // Then we update the questionsBeingGenerated:
            const tiedToQuestionId = parsedMessage.tied_to_question_id
            const user = parsedMessage.user
            const status = parsedMessage.status
            // Update the workingSectionsBeingGenerated:
            const message = parsedMessage.message
            if (status === 'started' || status === 'failed') {
              workingQuestionsBeingGenerated = {
                ...workingQuestionsBeingGenerated,
                [tiedToQuestionId]: {
                  status,
                  message,
                  user,
                },
              }
            } else if (status === 'success') {
              if (parsedMessage.obituary) {
                // If the status is success, we need to update the obituary:
                obituaryUpdatedCallback(parsedMessage.obituary)
              } else {
                console.warn('No obituary in parsedMessage')
              }
              // Then we remove the basedOnAttribute from workingQuestionsBeingGenerated:
              delete workingQuestionsBeingGenerated[tiedToQuestionId]
            } else {
              console.warn('Unknown status in ObituaryMoreQuestionsUpdate')
            }
            // Update the state:
            setQuestionsBeingGenerated(workingQuestionsBeingGenerated)
            return
          }
        }
        obituaryWebsocket.websocket.addEventListener('message', handleMessage)
        return () => {
          if (obituaryWebsocket.websocket) {
            obituaryWebsocket.websocket.removeEventListener('message', handleMessage)
          }
        }
      }
    }
  }, [currentPhoenixUserPhone, obituaryWebsocket])

  return {questionsBeingGenerated}
}

export default useMoreQuestions
