import { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { adminGetFRMOauthCodeByUniqueID, saveCompanyNameForTokenUniqueId } from "./core/_requests"
import { ApiError } from "../modules/auth"
import { FRMAccessToken } from "./core/_admin_slack_response"

function ManageFRMOauthToken() {

    const navigate = useNavigate()

    const {
        token_unique_id: tokenUniqueId
    } = useParams()

    const [loading, setLoading] = useState<boolean>(true)
    const [loadingError, setLoadingError] = useState<string | null>(null)

    const [currentToken, setCurrentToken] = useState<FRMAccessToken | null>(null)

    const [saving, setSaving] = useState<boolean>(false)
    const [savingError, setSavingError] = useState<string | null>(null)

    const companyNameInputRef = useRef<HTMLInputElement>(null)

    const tryToLoadOauthCode = useCallback(async () => {
        if (!tokenUniqueId) {
            navigate('/admin/frm')
            return
        }
        try {
            const result = await adminGetFRMOauthCodeByUniqueID(tokenUniqueId)
            setCurrentToken(result)
            return
        } catch (error: any) {
            console.warn('error', error)
            const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
            setLoadingError(errorMessage)
        } finally {
            setLoading(false)
        }
    }, [navigate, tokenUniqueId])

    const onFormSave = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (saving || !tokenUniqueId) {
            return
        }
        setSaving(true)
        setSavingError(null)
        try {
            const companyName = companyNameInputRef.current?.value || ''
            await saveCompanyNameForTokenUniqueId(tokenUniqueId, companyName)
            // We update the user's info in the back-end but rather than spending
            // time to make sure we update the current user here we do a hard location navigation to
            // force a refresh of the user's info (still going to /admin/frm)
            // navigate('/admin/frm')
            window.location.href = '/admin/frm'
        } catch (error: any) {
            console.warn('error', error)
            const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
            setSavingError(errorMessage)
        } finally {
            setSaving(false)
        }
    }, [saving, tokenUniqueId])

    useEffect(() => {
        tryToLoadOauthCode()
    }, [tryToLoadOauthCode])

    if (loadingError) {
        return <div className='alert alert-danger'>{loadingError || 'Something went wrong'}</div>
    }

    if (loading || !currentToken) {
        return (
            <div className='ms-4 spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
            </div>
        )
    }

    return <form onSubmit={onFormSave}>
        <div>
            <h4>
                FRM Company Identifier:
            </h4>
        </div>
        <div>
            {currentToken.company_id}
        </div>
        <div className='mt-3'>
            <h4>
                FRM Company Name:
            </h4>
        </div>
        <div>
            <input
                ref={companyNameInputRef}
                type='text'
                className='form-control'
                placeholder='Company Name'
                defaultValue={currentToken.company_name || ''}
                required
            />
        </div>
        <div className='mt-3'>
            <div className='text-muted'>
                Note ... the FRM doesn't return the company name with the token right now. We need to name these
                tokens so that we can switch between them in the future.
            </div>
            <div className='mt-3'>
                <button className='btn btn-primary'>
                    Save
                </button>
            </div>
        </div>
        {savingError && <div className='mt-3 alert alert-danger'>
            {savingError}
        </div>}
    </form>
}

export default ManageFRMOauthToken