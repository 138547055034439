import { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { ApiError } from '../../auth'
import { getObituaryDebugInfo, makeObituaryDebugRequest } from '../core/_requests'
import { ObituaryDebug } from '../core/_models'
import ProgressBarInSeconds from '../ProgressBarInSeconds'
import { useObituaryDetail } from './ObituaryDetail'
import { replaceNewLinesWithParagraphs } from './ObituaryWriteSection'
import ToneSelector, { ToneSelectionOption } from './ToneSelector'

const MessagesContainer = styled.div`
  overflow-x: hidden;
  padding-bottom: 50px;
`

const MessagesContainerWithOverflow = styled(MessagesContainer)`
  max-height: 500px;
  overflow-y: auto;
`

const MessagesBlur = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
`

const ItemRole = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`

const ItemContainer = styled.div`
  p.user-paragraph {
    margin: 0 !important;
    padding: 0;
  }
`

const UpdatedResultContainer = styled.div`
  font-size: 1.2rem;
`

function DebugItem({
  apiNamespace,
  showToneSelector = undefined,
}: {
  apiNamespace: 'biography' | 'condolences'
  showToneSelector?: boolean
}) {
  const { obituary } = useObituaryDetail()

  const [showEverything, setShowEverything] = useState(false)

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [obituaryDebug, setObituaryDebug] = useState<ObituaryDebug | null>(null)

  const [makingRequest, setMakingRequest] = useState(false)
  const [requestError, setRequestError] = useState<string | null>(null)
  const [latestResult, setLatestResult] = useState<string | null>(null)

  const [biographyTone, setBiographyTone] = useState<ToneSelectionOption | null>(null)

  const onLoadDebugInfo = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const updatedDebugInfo = await getObituaryDebugInfo(
        obituary.unique_identifier,
        apiNamespace,
        biographyTone?.value || ''
      )
      setObituaryDebug(updatedDebugInfo)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [apiNamespace, biographyTone?.value, obituary.unique_identifier])

  const onMakeObituaryDebugRequest = useCallback(async () => {
    setMakingRequest(true)
    setRequestError(null)
    try {
      const result = await makeObituaryDebugRequest(obituary.unique_identifier, {
        apiNamespace,
        updated_messages: obituaryDebug?.updated_messages,
        biography_tone: biographyTone?.value || '',
      })
      setLatestResult(result)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setRequestError(errorMessage)
    } finally {
      setMakingRequest(false)
    }
  }, [apiNamespace, biographyTone?.value, obituary.unique_identifier, obituaryDebug?.updated_messages])

  useEffect(() => {
    onLoadDebugInfo()
  }, [onLoadDebugInfo])

  if (loading) {
    return (
      <div>
        <div className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='alert alert-danger' role='alert'>
        <div className='alert-text'>{error}</div>
      </div>
    )
  }

  let useMessages = [] as ObituaryDebug['messages']

  if (obituaryDebug?.messages && obituaryDebug?.messages.length > 0) {
    // Show everything means use every message.
    // Otherwise, use the first and the last messages.
    const messageLength = obituaryDebug.messages.length
    useMessages = showEverything
      ? obituaryDebug.messages
      : messageLength > 1
        ? [obituaryDebug.messages[0], obituaryDebug.messages[messageLength - 1]]
        : obituaryDebug.messages
  }

  return (
    <div>
      <div className='row'>
        <div className='col-12 col-lg-4'>
          <textarea
            className='form-control'
            rows={15}
            defaultValue={obituaryDebug?.system_prompt}
            onChange={(event) => {
              obituaryDebug?.updatedMessage(0, event.target.value)
            }}
          />
          {showToneSelector && (
            <div className='mt-4'>
              <ToneSelector
                isShareableObituary={false}
                biographyTone={biographyTone}
                setBiographyTone={setBiographyTone}
                fullWidth
              />
            </div>
          )}
        </div>
        <div className='col-12 col-lg-8 position-relative'>
          <MessagesContainer as={showEverything ? MessagesContainerWithOverflow : undefined}>
            {useMessages.map((message, index) => {
              return (
                <div key={`message-${index}-${message.content}`}>
                  {index > 0 && (
                    <>
                      <div className='separator separator-dashed my-5'></div>
                      {!showEverything ? (
                        <>
                          <button
                            type='button'
                            className='btn btn-secondary btn-sm'
                            onClick={() => {
                              setShowEverything(true)
                            }}
                          >
                            Show all messages
                          </button>
                          <div className='separator separator-dashed my-5'></div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  <ItemRole>{message.role}</ItemRole>
                  <div className='row'>
                    <div className='col-10'>
                      <ItemContainer>
                        <textarea
                          className='form-control'
                          rows={index === 0 || index === useMessages.length - 1 ? 10 : 5}
                          defaultValue={message.content}
                          onChange={(event) => {
                            obituaryDebug?.updatedMessage(index + 1, event.target.value)
                          }}
                        />
                      </ItemContainer>
                    </div>
                  </div>
                </div>
              )
            })}
          </MessagesContainer>
          <MessagesBlur />
        </div>
      </div>
      <div className='separator separator-dashed my-5'></div>
      <div className='row'>
        <div className='col-12'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={onMakeObituaryDebugRequest}
            disabled={makingRequest}
          >
            {makingRequest ? (
              <div className='spinner-border text-light' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            ) : (
              'Make Request'
            )}
          </button>
          {requestError && (
            <div className='alert alert-danger mt-3' role='alert'>
              <div className='alert-text'>{requestError}</div>
            </div>
          )}
          {makingRequest && <ProgressBarInSeconds estimatedTimeInSeconds={30} />}
          {latestResult && (
            <div>
              <div className='separator separator-dashed my-5'></div>
              <UpdatedResultContainer
                dangerouslySetInnerHTML={{ __html: replaceNewLinesWithParagraphs(latestResult) }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DebugItem
