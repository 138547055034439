/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useObituaryDetail} from './ObituaryDetail'
import usePageTitle from '../../../hooks/usePageTitle'
import clsx from 'clsx'
import DebugItem from './DebugItem'

interface ObituaryDebugComponentProps {}

function ObituaryDebugComponent({}: ObituaryDebugComponentProps) {
  const {obituary, obituaryWebsocket} = useObituaryDetail()

  const [debugOptions] = useState([
    {
      key: 'biography',
      label: 'Biography',
      component: () => {
        return <DebugItem apiNamespace='biography' showToneSelector />
      },
    },
    {
      key: 'condolences',
      label: 'Condolences',
      component: () => {
        return <DebugItem apiNamespace='condolences' />
      },
    },
  ])
  const [activeTab, setActiveTab] = useState(debugOptions[0].key)

  useEffect(() => {
    if (obituaryWebsocket) {
      obituaryWebsocket.sendPageView('debug')
    }
  }, [obituaryWebsocket])

  usePageTitle({
    title: `${obituary.getSimpleName()}'s Obituary | Debug`,
  })

  return (
    <div>
      <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 ps-4'>
        {debugOptions.map((option) => {
          return (
            <li className='nav-item' key={option.key}>
              <a
                className={clsx('nav-link', activeTab === option.key && 'active')}
                data-bs-toggle='tab'
                href={`#kt_tab_pane_${option.key}`}
              >
                {option.label}
              </a>
            </li>
          )
        })}
      </ul>
      <div className='tab-content' id='myTabContent'>
        {debugOptions.map((option) => {
          return (
            <div
              className={clsx('tab-pane', activeTab === option.key && 'active show')}
              id={`kt_tab_pane_${option.key}`}
              role='tabpanel'
              key={option.key}
            >
              <div className='row'>
                <div className='col-lg-12'>{option.component()}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ObituaryDebugComponent
