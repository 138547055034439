import React from 'react'
import {Obituary} from '../core/_models'
import {useObituaryDetail} from './ObituaryDetail'

interface ObituaryPhotosAndVideosProps {}

function ObituaryPhotosAndVideos({}: ObituaryPhotosAndVideosProps) {
  const {obituary, setObituary} = useObituaryDetail()

  return <div>ObituaryPhotosAndVideos</div>
}

export default ObituaryPhotosAndVideos
