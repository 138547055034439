/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl, useDebounce } from '../../_metronic/helpers'
import { ApiError } from '../modules/auth'
import { DateTime } from 'luxon'
import FuneralHomeRowOptions from './FuneralHomeRowOptions'
import { FuneralHome } from '../modules/obituaries/core/_models'
import { getAllAvailableFuneralHomes, getClientTeamMembers } from '../funeral_homes/dashboard/core/_requests'
import WidgetConversionsByDayWidget from '../funeral_homes/dashboard/WidgetConversionsByDayWidget'
import SessionsByDayWidget from '../funeral_homes/dashboard/SessionsByDayWidget'

const AdminDashboard: React.FC = () => {
  const [loading, setLoading] = React.useState(true)
  const [loadingError, setLoadingError] = React.useState<string | null>(null)
  const [allFuneralHomes, setAllFuneralHomes] = React.useState<FuneralHome[]>([])

  const [searchText, setSearchText] = React.useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  const loadResults = useCallback(async () => {
    setLoading(true)
    try {
      const updatedData = await getAllAvailableFuneralHomes(debouncedSearchText)
      setAllFuneralHomes(updatedData)
      setLoadingError(null)
    } catch (error: any) {
      console.warn('error', error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setLoadingError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [debouncedSearchText])

  useEffect(() => {
    loadResults()
  }, [loadResults])

  return <div> <>
    <div className='row'>
      <div className='col-lg-6'>
        <SessionsByDayWidget isForAllClients />
      </div>
      <div className='col-lg-6'>
        <WidgetConversionsByDayWidget isForAllClients />
      </div>
    </div>
  </>
  </div>

}

export default AdminDashboard
