import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useShareableObituary } from './ShareableView'
import { Obituary } from '../modules/obituaries/core/_models'
import { Menu as MenuIcon } from 'react-feather'

import PersonalInformationForm from '../modules/obituaries/components/forms/PersonalInformationForm'
import RelativesForm from '../modules/obituaries/components/forms/RelativesForm'
import CeremonyDetailsForm from '../modules/obituaries/components/forms/CeremonyDetailsForm'
import EducationForm from '../modules/obituaries/components/forms/EducationForm'
import CareerForm from '../modules/obituaries/components/forms/CareerForm'
import MilitaryServiceForm from '../modules/obituaries/components/forms/MilitaryServiceForm'
import LifeEventsForm from '../modules/obituaries/components/forms/LifeEventsForm'
import PersonalityAndInterestsForm from '../modules/obituaries/components/forms/PersonalityAndInterestsForm'
import usePageTitle from '../hooks/usePageTitle'
import UserRelationshipForm from './UserRelationshipForm'
import AffiliationsForm from '../modules/obituaries/components/forms/AffiliationsForm'
import FinalQuestionsForm from '../modules/obituaries/components/forms/FinalQuestionsForm'

const NavigationContainer = styled.div``

const NavigationItem = styled.div`
  padding: 0 10px;
`

const NavigationLink = styled(Link)`
  font-size: 1.75rem;

  span {
    font-size: 1.25rem;
  }
`

export const SelectableHeader = styled.div`
  padding: 20px 10px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h1 {
    padding: 0;
    margin: 0;
    font-size: 1.25rem;
  }
`

function buildTraditionalObituaryNavigation(obituary: Obituary) {
  const basePath = `/o/${obituary.shareable_code}/details`
  return [
    {
      name: 'Personal Information',
      path: `${basePath}`,
      count: obituary.personal_information_count,
    },
    {
      name: 'Relatives',
      path: `${basePath}/relatives`,
      count: obituary.relatives_count,
    },
    {
      name: 'Education',
      path: `${basePath}/education`,
      count: obituary.education_count,
    },
    {
      name: 'Career',
      path: `${basePath}/career`,
      count: obituary.career_count,
    },
    {
      name: 'Military Service',
      path: `${basePath}/military`,
      count: obituary.military_service_count,
    },
    {
      name: 'Affiliations',
      path: `${basePath}/affiliations`,
      count: obituary.affiliations_count,
    },
    {
      name: 'Life Events',
      path: `${basePath}/life-events`,
      count: obituary.life_events_count,
    },
    {
      name: 'Personality and Interests',
      path: `${basePath}/personality`,
      count: obituary.personality_and_interests_count,
    },
    /*
    false && 'disablingServicesForNow' && {
      name: 'Services & Condolences',
      path: `${basePath}/ceremony`,
      count: obituary.ceremony_details_count,
    },
    {
      name: 'Final Questions',
      path: `${basePath}/final-questions`,
      count: 0, // TODO
    },
    */
  ]
}

export function Navigation({ onSelect }: { onSelect: () => void }) {
  const location = useLocation()

  const { obituary } = useShareableObituary()

  const options = buildTraditionalObituaryNavigation(obituary)

  return (
    <NavigationContainer>
      {options.map((option) => {
        const { name, path, count } = option
        const isActive = location.pathname === path
        return (
          <NavigationItem
            key={name}
            className='nav-item'
            style={
              isActive
                ? {
                  boxShadow: 'rgb(255 255 255 / 9%) 0px 0px 4px 4px',
                  borderRadius: '4px',
                }
                : {}
            }
          >
            <NavigationLink
              to={path}
              className='py-3 nav-link w-100 h-100 flex-column justify-content-center align-items-start'
              style={
                isActive
                  ? {
                    fontWeight: '500',
                  }
                  : {
                    fontWeight: '100',
                  }
              }
              onClick={onSelect}
            >
              {name}
              {count && count > 0 ? (
                <span
                  style={{
                    lineHeight: '10px',
                    marginTop: 10,
                    marginBottom: 10,
                    paddingTop: 0,
                    paddingLeft: 10,
                  }}
                  className='text-muted d-block'
                >
                  {`${count} completed ${count === 1 ? 'item' : 'items'}`}
                </span>
              ) : (
                <></>
              )}
            </NavigationLink>
          </NavigationItem>
        )
      })}
    </NavigationContainer>
  )
}

interface ObituariesDetailItemProps {
  selectedSection:
  | 'personal'
  | 'relatives'
  | 'ceremony'
  | 'education'
  | 'career'
  | 'military'
  | 'affiliations'
  | 'life-events'
  | 'personality'
  | 'final-questions'
}

function ObituariesDetailItem({ selectedSection }: ObituariesDetailItemProps) {
  const navigate = useNavigate()

  // Get the current path
  const { pathname } = useLocation()

  const { obituary, setObituary, obituaryWebsocket, userRelationshipToObituary } =
    useShareableObituary()

  // check for something like /o/MZ3-DII/details/final-questions

  const isOnFinalQuestions = pathname === `/o/${obituary.shareable_code}/details/final-questions`

  const [viewingCategories, setViewingCategories] = useState(false)

  const basePath = `/o/${obituary.shareable_code}/details`

  const sectionMapping = {
    personal: {
      pageTitle: 'Personal Information',
      component: () => {
        return (
          <PersonalInformationForm
            obituary={obituary}
            setObituary={setObituary}
            onSuccessfulSave={() => {
              navigate(`${basePath}/relatives`)
            }}
            hideImageUploader
            obituaryWebsocket={obituaryWebsocket}
            userRelationshipToObituary={userRelationshipToObituary}
          />
        )
      },
    },
    relatives: {
      pageTitle: 'Relatives',
      component: () => {
        return (
          <RelativesForm
            obituary={obituary}
            setObituary={setObituary}
            onSuccessfulSave={() => {
              navigate(`${basePath}/education`)
            }}
            goBackUrl={`${basePath}`}
            obituaryWebsocket={obituaryWebsocket}
            userRelationshipToObituary={userRelationshipToObituary}
          />
        )
      },
    },
    education: {
      pageTitle: 'Education',
      component: () => {
        return (
          <EducationForm
            obituary={obituary}
            setObituary={setObituary}
            onSuccessfulSave={() => {
              navigate(`${basePath}/career`)
            }}
            goBackUrl={`${basePath}/relatives`}
            obituaryWebsocket={obituaryWebsocket}
            userRelationshipToObituary={userRelationshipToObituary}
          />
        )
      },
    },
    career: {
      pageTitle: 'Career',
      component: () => {
        return (
          <CareerForm
            obituary={obituary}
            setObituary={setObituary}
            onSuccessfulSave={() => {
              navigate(`${basePath}/military`)
            }}
            goBackUrl={`${basePath}/education`}
            obituaryWebsocket={obituaryWebsocket}
            userRelationshipToObituary={userRelationshipToObituary}
          />
        )
      },
    },
    military: {
      pageTitle: 'Military Service',
      component: () => {
        return (
          <MilitaryServiceForm
            obituary={obituary}
            setObituary={setObituary}
            onSuccessfulSave={() => {
              navigate(`${basePath}/affiliations`)
            }}
            goBackUrl={`${basePath}/career`}
            obituaryWebsocket={obituaryWebsocket}
            userRelationshipToObituary={userRelationshipToObituary}
          />
        )
      },
    },
    affiliations: {
      pageTitle: 'Affiliations',
      component: () => {
        return (
          <AffiliationsForm
            obituary={obituary}
            setObituary={setObituary}
            onSuccessfulSave={() => {
              navigate(`${basePath}/life-events`)
            }}
            goBackUrl={`${basePath}/military`}
            obituaryWebsocket={obituaryWebsocket}
            userRelationshipToObituary={userRelationshipToObituary}
          />
        )
      },
    },
    'life-events': {
      pageTitle: 'Life Events',
      component: () => {
        return (
          <LifeEventsForm
            obituary={obituary}
            setObituary={setObituary}
            onSuccessfulSave={() => {
              navigate(`${basePath}/personality`)
            }}
            goBackUrl={`${basePath}/affiliations`}
            obituaryWebsocket={obituaryWebsocket}
            userRelationshipToObituary={userRelationshipToObituary}
          />
        )
      },
    },
    personality: {
      pageTitle: 'Personality and Interests',
      component: () => {
        return (
          <PersonalityAndInterestsForm
            obituary={obituary}
            setObituary={setObituary}
            onSuccessfulSave={() => {
              // Ceremony is disabled for now
              // navigate(`${basePath}/ceremony`)
              navigate(`/o/${obituary.shareable_code}/write`)
            }}
            goBackUrl={`${basePath}/life-events`}
            obituaryWebsocket={obituaryWebsocket}
            userRelationshipToObituary={userRelationshipToObituary}
          />
        )
      },
    },
    ceremony: {
      pageTitle: 'Services & Condolences',
      component: () => {
        return (
          <CeremonyDetailsForm
            obituary={obituary}
            setObituary={setObituary}
            onSuccessfulSave={() => {
              navigate(`/o/${obituary.shareable_code}/write`)
            }}
            goBackUrl={`${basePath}/personality`}
            obituaryWebsocket={obituaryWebsocket}
            userRelationshipToObituary={userRelationshipToObituary}
          />
        )
      },
    },
    'final-questions': {
      pageTitle: 'Final Questions',
      component: () => {
        return (
          <FinalQuestionsForm
            obituary={obituary}
            setObituary={setObituary}
            onSuccessfulSave={() => {
              navigate(`/o/${obituary.shareable_code}/write`)
            }}
            goBackUrl={`${basePath}/personality`}
            obituaryWebsocket={obituaryWebsocket}
            userRelationshipToObituary={userRelationshipToObituary}
            viewingShareableObituary
          />
        )
      },
    },
  }

  usePageTitle({
    title: `${obituary.getSimpleName()}'s ${sectionMapping[selectedSection].pageTitle}`,
  })

  if (!userRelationshipToObituary) {
    return <UserRelationshipForm />
  }

  return (
    <div className='h-100'>
      {!viewingCategories ? (
        <SelectableHeader
          className='w-100 cursor-pointer'
          onClick={() => setViewingCategories(true)}
        >
          <MenuIcon size={18} />
          <h1 className='ms-4'>Categories</h1>
        </SelectableHeader>
      ) : (
        <SelectableHeader className='w-100'>
          <h1>Choose a category</h1>
        </SelectableHeader>
      )}
      {viewingCategories ? (
        <div className='mt-4'>
          <Navigation
            onSelect={() => {
              setViewingCategories(false)
            }}
          />
        </div>
      ) : (
        <div
          className='pb-9'
          style={
            isOnFinalQuestions
              ? {
                height: 'calc(100vh - 175px)',
              }
              : {}
          }
        >
          {sectionMapping[selectedSection].component()}
        </div>
      )}
    </div>
  )
}

export default ObituariesDetailItem
