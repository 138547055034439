/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import SelectUserTypeCard from './SelectUserTypeCard'
import { updateUserType } from '../../modules/auth/core/_requests'
import { useAuth } from '../../modules/auth/core/Auth'

function SelectUserType() {
  const { currentPhoenixUserPhone, setCurrentPhoenixUserPhone } = useAuth()

  const [selectedUserType, setSelectedUserType] = React.useState('')
  const [error, setError] = React.useState<string | null>(null)

  const [name, setName] = React.useState(currentPhoenixUserPhone?.user_profile?.name || '')
  const [email, setEmail] = React.useState(
    currentPhoenixUserPhone?.email || ''
  )

  const onUpdateUserType = useCallback(async () => {
    alert('onUpdateUserType needs setting')
    /*
    setError(null)
    try {
      const updatedUser = await updateUserType({
        name,
        email,
        user_type: selectedUserType,
      })
      setCurrentUser(updatedUser)
    } catch (error: any) {
      console.warn(error)
      setError(error?.response?.data?.error || 'Something went wrong, please try again')
    }
    */
  }, [])

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault()
      onUpdateUserType()
    },
    [onUpdateUserType]
  )

  return (
    <form onSubmit={onSubmit} className='pb-5'>
      <div className='d-flex flex-wrap flex-stack mb-4'>
        <h3 className='fw-bolder'>
          Your Information:
          {false && 'subtitle' && (
            <span className='fs-6 text-gray-400 fw-bold ms-4'>Select User Type</span>
          )}
        </h3>
      </div>

      <div>
        <div className='row mb-10'>
          <div className='col-md-8'>
            <div className='form-group'>
              <label className='form-label'>Name (First and Last Name)</label>
              <input
                type='text'
                className='form-control'
                placeholder='Enter your first and last name...'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div className='col-md-4'>
            <div className='form-group'>
              <label className='form-label'>Email</label>
              <input
                type='email'
                className='form-control'
                placeholder='Enter your email address...'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Select User Type:</h3>
      </div>

      <div className='row g-6 g-xl-9'>
        {/* Offset 2 if xl */}
        <div className='col-md-6 col-xl-4 offset-xl-2'>
          <SelectUserTypeCard
            title="I'm with a funeral home"
            selected={selectedUserType === 'funeral_home_owner_or_operator'}
            onClick={() => {
              setSelectedUserType(
                selectedUserType === 'funeral_home_owner_or_operator'
                  ? ''
                  : 'funeral_home_owner_or_operator'
              )
            }}
          />
        </div>
        {false && (
          <div className='col-md-6 col-xl-4'>
            <SelectUserTypeCard
              title='I am a funeral director'
              selected={selectedUserType === 'funeral_director'}
              onClick={() => {
                setSelectedUserType(
                  selectedUserType === 'funeral_director' ? '' : 'funeral_director'
                )
              }}
            />
          </div>
        )}
        <div className='col-md-6 col-xl-4'>
          <SelectUserTypeCard
            title="I'm a family member or friend"
            selected={selectedUserType === 'other'}
            onClick={() => {
              setSelectedUserType(selectedUserType === 'other' ? '' : 'other')
            }}
          />
        </div>
      </div>
      <div className='separator separator-dashed my-10'></div>
      <div className='d-flex justify-content-center mt-5'>
        <button type='submit' className='btn btn-primary btn-lg' disabled={!selectedUserType}>
          Continue
        </button>
      </div>
      {error && (
        <div className='row'>
          <div className='col-md-6 col-xl-4 offset-md-3 offset-xl-4'>
            <div className='alert alert-danger mt-4' role='alert'>
              <div className='alert-text text-center'>{error}</div>
            </div>
          </div>
        </div>
      )}
    </form>
  )
}

const OnboardingWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Onboarding</PageTitle>
      <SelectUserType />
    </>
  )
}

export default OnboardingWrapper
