import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useAuth } from "../../../auth/core/Auth"

import { getJourneyFromAdmin } from "../../../../funeral_homes/dashboard/core/_requests"
import { Journey } from "../../../../funeral_homes/dashboard/core/journey_classes"
import usePageTitle from "../../../../hooks/usePageTitle"

interface ObituaryDetailContextParams {
  journey: Journey | null,
  setJourney: (journey: Journey) => void
}

const JourneyDetailContext = React.createContext<ObituaryDetailContextParams>({
  journey: null,
  setJourney: () => { },
})

function LoadingOverlay() {
  return (
    <div>
      <div className='spinner-border text-primary' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
  )
}

export const useJourneyDetail = () => React.useContext(JourneyDetailContext)

function JourneyProvider({ children }: { children: React.ReactNode }) {
  const params = useParams()
  const navigate = useNavigate()
  const { auth } = useAuth()

  const [loadingJourney, setLoadingJourney] = useState(!!params.unique_id)
  const [journey, setJourney] = useState<Journey | null>(null)

  usePageTitle(
    !journey?.unique_identifier || !journey
      ? {
        title: `Create Obituary`,
        includeDefaultTitle: true,
      }
      : {
        title: journey.for_full_name ? `${journey.for_full_name}'s Obituary` : 'Obituary',
      }
  )

  useEffect(() => {
    if (!params.unique_id) {
      setLoadingJourney(false)
      return
    }
    async function getUpdatedObituary() {
      if (!params.unique_id) {
        return
      }
      try {
        const updatedJourney = await getJourneyFromAdmin(params.unique_id)
        setJourney(updatedJourney)
        setLoadingJourney(false)
      } catch (error) {
        console.error(error)
        navigate('/obituaries') // TODO: Toast error?
      }
    }
    if (params.unique_id && (!journey || journey.unique_identifier !== params.unique_id)) {
      setLoadingJourney(true)
      getUpdatedObituary()
    }
  }, [navigate, journey, params.unique_id])


  return <JourneyDetailContext.Provider
    value={{
      journey,
      setJourney,
    }}
  >
    {loadingJourney ? <LoadingOverlay /> : children}
  </JourneyDetailContext.Provider>
}

export default JourneyProvider