import {Link} from 'react-router-dom'

function FormTitle({
  title,
  hideContinue = false,
  continueGoesTo = undefined,
  additionalButtons = undefined,
}: {
  title: string
  hideContinue?: boolean
  continueGoesTo?: string
  additionalButtons?: React.ReactNode
}) {
  return (
    <div className='d-flex mb-5 align-items-center justify-content-between'>
      <h2 className='p-0 m-0'>{title}</h2>
      {!hideContinue && (
        <div>
          {additionalButtons && additionalButtons}
          {!continueGoesTo ? (
            <button type='submit' className='btn btn-sm btn-light-primary me-2'>
              Continue
            </button>
          ) : (
            <Link to={continueGoesTo} className='btn btn-sm btn-light-primary me-2'>
              Continue
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

export default FormTitle
