import styled from 'styled-components'
import { FormikProps } from 'formik'

import {
  ObituaryAdditionalQuestionChanged,
  ObituaryWebsocket,
} from '../../../../obituaries/useObituaryWebsocket'
import { IItemProps } from './FormItemsRenderer'
import { useCallback, useState } from 'react'
import { generateNewObituaryQuestion } from '../../core/_requests'
import { Obituary, ReceivedQuestion } from '../../core/_models'
import { ApiError } from '../../../auth'
import useMoreQuestions from '../../../../obituaries/useMoreQuestions'
import useAdditionalQuestionsUpdates from '../../../../obituaries/useAdditionalQuestionsUpdates'
import FieldBeingEditedItem, { IUsersWhoAreEditingFieldsType } from './FieldBeingEditedItem'
import React from 'react'
import { useAuth } from '../../../auth/core/Auth'

const AskAQuestionPill = styled.div`
  background-color: var(--kt-info);
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  padding: 8px 16px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  user-select: none;
`

const DisabledAskAQuestionPill = styled(AskAQuestionPill)`
  background-color: var(--kt-info-light);
  color: var(--kt-info);
  cursor: default;
`

interface AdditionalQuestionsProps {
  formik: FormikProps<any>
  obituaryWebsocket: ObituaryWebsocket
  setObituary: (obituary: Obituary) => void
  item: IItemProps
  receivedQuestions?: ReceivedQuestion[]
}

function AdditionalQuestions({
  formik,
  obituaryWebsocket,
  setObituary,
  item,
  receivedQuestions,
}: AdditionalQuestionsProps) {
  const { currentPhoenixUserPhone } = useAuth()

  const [loadingAnotherQuestion, setLoadingAnotherQuestion] = useState(false)
  const [saveError, setSaveError] = useState<string | undefined>(undefined)

  const { questionsBeingGenerated } = useMoreQuestions(
    obituaryWebsocket,
    (updatedObituary: Obituary) => {
      setObituary(updatedObituary)
    }
  )

  const [, setLastUpdated] = useState<number>(0)

  const generatedQuestions = receivedQuestions?.filter(
    (question) => question.generated_based_on_attribute === item.additionalQuestionId
  )

  // Create an array of refs for generatedQuestions

  const generatedQuestionRefs = generatedQuestions?.map((question) => {
    return React.createRef<HTMLTextAreaElement>()
  })

  const { editingFieldsByName } = useAdditionalQuestionsUpdates(
    obituaryWebsocket,
    (changedMessage?: ObituaryAdditionalQuestionChanged) => {
      if (generatedQuestionRefs) {
        if (changedMessage) {
          // Find the index of the changedMessage.questionUniqueId in generatedQuestions
          const index = generatedQuestions?.findIndex(
            (question) => question.unique_id === changedMessage.questionUniqueId
          )
          if (index !== undefined && index !== -1) {
            const ref = generatedQuestionRefs[index]
            if (ref && ref.current) {
              // Update the value:
              ref.current.value = changedMessage.value
            } else {
              console.warn('ref or ref.current is undefined')
            }
          }
        }
      }
      // Only way to force a re-render
      setLastUpdated(Date.now())
    }
  )

  // See if item.additionalQuestionId is a key of questionsBeingGenerated

  const itemBeingGenerated = item.additionalQuestionId
    ? questionsBeingGenerated[item.additionalQuestionId]
    : undefined

  const onAskAnotherQuestion = useCallback(async () => {
    if (!item.additionalQuestionId) {
      return
    }
    if (itemBeingGenerated || loadingAnotherQuestion) {
      return
    }
    setLoadingAnotherQuestion(true)
    setSaveError(undefined)
    try {
      const { obituary: updatedObituary } = await generateNewObituaryQuestion(
        obituaryWebsocket.obituary.unique_identifier,
        {
          tiedToQuestionId: item.additionalQuestionId,
        }
      )
      setObituary(updatedObituary)
      setSaveError(undefined)
    } catch (error: any) {
      console.warn(error)
      const errorMessage =
        error instanceof ApiError ? error.message : 'Unable to generate new questions'
      setSaveError(errorMessage)
    } finally {
      setLoadingAnotherQuestion(false)
    }
  }, [
    item.additionalQuestionId,
    itemBeingGenerated,
    loadingAnotherQuestion,
    obituaryWebsocket.obituary.unique_identifier,
    setObituary,
  ])

  if (!obituaryWebsocket.obituary) {
    // Should never happen
    console.warn('obituaryWebsocket.obituary is undefined')
    return <></>
  }

  if (!item.additionalQuestionId) {
    // Should never happen
    console.warn('item.additionalQuestionId is undefined')
    return <></>
  }

  if (generatedQuestions && generatedQuestions.length > 0) {
    return (
      <>
        {generatedQuestions.map((generatedQuestion, index) => {
          return (
            <div key={`question-${generatedQuestion.unique_id}`} className='row mt-8'>
              <div className='col-12 position-relative'>
                <label className='form-label'>{generatedQuestion.question}</label>
                <textarea
                  ref={generatedQuestionRefs ? generatedQuestionRefs[index] : undefined}
                  className='form-control'
                  name={item.name}
                  onChange={(e) => {
                    // TODO: change a local state variable.
                    if (obituaryWebsocket && item.additionalQuestionId) {
                      obituaryWebsocket?.formActions.onAdditionalQuestionChanged({
                        questionUniqueId: generatedQuestion.unique_id,
                        name: item.additionalQuestionId,
                        value: e.target.value,
                      })
                    }
                  }}
                  onFocus={() => {
                    if (obituaryWebsocket && item.additionalQuestionId) {
                      obituaryWebsocket?.formActions.onAdditionalQuestionFocused({
                        questionUniqueId: generatedQuestion.unique_id,
                        name: item.additionalQuestionId,
                      })
                    }
                  }}
                  onBlur={() => {
                    if (obituaryWebsocket && item.additionalQuestionId) {
                      obituaryWebsocket?.formActions.onAdditionalQuestionBlurred({
                        questionUniqueId: generatedQuestion.unique_id,
                        name: item.additionalQuestionId,
                      })
                    }
                  }}
                  defaultValue={generatedQuestion.answer || ''}
                  autoFocus={false}
                  required={false}
                  placeholder={''}
                  rows={5}
                  readOnly={false}
                />
                {editingFieldsByName && editingFieldsByName[generatedQuestion.unique_id] && (
                  <div className='position-absolute top-0 end-0'>
                    <FieldBeingEditedItem
                      editingFieldByName={editingFieldsByName[generatedQuestion.unique_id]}
                    />
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </>
    )
  }

  if (!item.value || !item.value.trim()) {
    return <></>
  }

  return (
    <div className='mt-4'>
      {saveError && (
        <div className='alert alert-danger'>
          <div className='alert-text'>{saveError}</div>
        </div>
      )}
      <AskAQuestionPill
        as={
          itemBeingGenerated || loadingAnotherQuestion ? DisabledAskAQuestionPill : AskAQuestionPill
        }
        onClick={onAskAnotherQuestion}
      >
        {itemBeingGenerated && itemBeingGenerated.user.unique_id !== currentPhoenixUserPhone?.unique_identifier ? (
          <>{`${itemBeingGenerated.message}`}</>
        ) : (
          <>{!loadingAnotherQuestion ? 'Ask more questions' : 'Preparing more questions...'}</>
        )}
      </AskAQuestionPill>
    </div>
  )
}

export default AdditionalQuestions
