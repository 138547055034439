import { useCallback, useEffect, useState } from 'react'

import Select from 'react-select'
import { ApiError } from '../../auth'
import { useAuth } from '../../auth/core/Auth'
import { getCurrentFuneralHomeDetails } from '../../../funeral_homes/dashboard/core/_requests'

interface FuneralHomeDirectorSelectorProps {
  onChange: (funeralDirectorUserUniqueId: 'new' | string) => void
}

const LOCAL_STORAGE_KEY_FOR_LAST_SELECTED_USER_UNIQUE_ID = 'lastSelectedFuneralDirector'

function FuneralHomeDirectorSelector({ onChange }: FuneralHomeDirectorSelectorProps) {
  const { currentPhoenixUser } = useAuth()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const [selectedValue, setSelectedValue] = useState<{ value: string; label: string }>({
    label: '(Select a director)',
    value: '',
  })

  const [selectableMemberships, setSelectableMemberships] = useState<
    { value: string; label: string }[]
  >([])

  const loadMemberships = useCallback(async () => {
    try {
      const { funeralHome } = await getCurrentFuneralHomeDetails()
      if (funeralHome.active_memberships.length === 0) {
        setSelectableMemberships([
          {
            value: 'new',
            label: `(Create a new member of ${funeralHome.short_name || funeralHome.name || 'the site'})`,
          },
        ])
        setSelectedValue({
          value: 'new',
          label: `(Create a new member of ${funeralHome.short_name || funeralHome.name || 'the site'})`,
        })
        onChange('new')
      } else {
        let defaultSelectedMembership = funeralHome.active_memberships[0]
        // Check local storage for last selected funeral director
        const lastSelectedMembershipUniqueId = localStorage.getItem(
          LOCAL_STORAGE_KEY_FOR_LAST_SELECTED_USER_UNIQUE_ID
        )
        if (lastSelectedMembershipUniqueId) {
          // See if this membership is in the list of active memberships
          const lastSelectedMembership = funeralHome.active_memberships.find(
            (membership) => `${membership.user.id}` === lastSelectedMembershipUniqueId
          )
          if (lastSelectedMembership) {
            defaultSelectedMembership = lastSelectedMembership
          }
        }
        setSelectedValue({
          value: `${defaultSelectedMembership.user.id}`,
          label: `${defaultSelectedMembership.user.name}${currentPhoenixUser?.id === defaultSelectedMembership.user.id ? ' (You)' : ''
            }`,
        })
        onChange(`${defaultSelectedMembership.user.id}`)
        setSelectableMemberships(
          funeralHome.active_memberships
            .map((membership) => ({
              value: `${membership.user.id}`,
              label: `${membership.user.name}${currentPhoenixUser?.id === membership.user.id ? ' (You)' : ''
                }`,
            }))
            .concat({
              value: 'new',
              label: `(Create a new member of ${funeralHome.short_name || funeralHome.name || 'the site'})`,
            })
        )
      }
      setError(null)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPhoenixUser?.id])

  useEffect(() => {
    loadMemberships()
  }, [loadMemberships])

  if (loading) {
    return (
      <div>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div>
        <div className='alert alert-danger' role='alert'>
          {error}
        </div>
      </div>
    )
  }

  return (
    <Select
      required
      options={selectableMemberships}
      value={selectedValue}
      onChange={(item: { value: string; label: any }) => {
        if (!item) {
          return
        }
        setSelectedValue({
          value: item.value,
          label: item.label,
        })
        onChange(item.value)
        localStorage.setItem(LOCAL_STORAGE_KEY_FOR_LAST_SELECTED_USER_UNIQUE_ID, item.value)
      }}
    />
  )
}

export default FuneralHomeDirectorSelector
