import SelectFuneralHomeWrapper from '../pages/obituaries/SelectFuneralHomeWrapper'

function AddFuneralHome() {
  return (
    <div>
      <SelectFuneralHomeWrapper
        title='Create Funeral Home'
        isAdminRoute
      />
    </div>
  )
}

export default AddFuneralHome
