import { Navigate, useParams } from 'react-router-dom'

import { useCallback, useEffect, useState } from 'react'
import { ApiError } from '../../modules/auth'
import { Obituary } from '../../modules/obituaries/core/_models'
import { getObituaryFromAdmin } from '../../funeral_homes/dashboard/core/_requests'

function UserObituaryDetail() {
  const { unique_id: obituaryUniqueId } = useParams()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [obituary, setObituary] = useState<Obituary | null>(null)

  const tryToLoadObituary = useCallback(async () => {
    if (!obituaryUniqueId) {
      return
    }
    setLoading(true)
    try {
      const obituary = await getObituaryFromAdmin(obituaryUniqueId)
      // We simple redirect after this retrieve as family members
      // need to use the shareable view.
      setError(null)
      setObituary(obituary)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [obituaryUniqueId])

  useEffect(() => {
    tryToLoadObituary()
  }, [tryToLoadObituary])

  if (loading || !obituary) {
    return (
      <div>
        <div className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='alert alert-danger'>
        <div className='alert-text'>{error}</div>
      </div>
    )
  }

  return <Navigate to={`/o/${obituary.shareable_code}`} replace />
}

export default UserObituaryDetail
