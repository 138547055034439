import ReconnectingWebSocket from 'reconnecting-websocket'

import {Obituary, Section, VenueAndDateTimeItem} from '../modules/obituaries/core/_models'
import {useCallback, useEffect, useState} from 'react'
import {SimpleUserModel} from '../modules/auth/core/_models'
import {useAuth} from '../modules/auth/core/Auth'
import {ObituaryUserJoined, ObituaryUserLeft} from './useObituaryWebsocket'

export class ObituaryFinalQuestionMessage {
  unique_id: string
  inserted_at: string
  updated_at: string
  archived_at: string | null

  is_loading: boolean

  created_by_user: SimpleUserModel | null
  generated_by_ai: boolean

  message: string | null

  constructor(data: any) {
    this.unique_id = data.unique_id
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.archived_at = data.archived_at
    this.is_loading = data.is_loading
    this.created_by_user = data.created_by_user ? new SimpleUserModel(data.created_by_user) : null
    this.generated_by_ai = data.generated_by_ai
    this.message = data.message
  }
}

export class ObituaryFinalQuestionMessageStatusUpdate {
  status: 'created' | 'finished'
  message: ObituaryFinalQuestionMessage

  constructor(status: 'created' | 'finished', message: ObituaryFinalQuestionMessage) {
    this.status = status
    this.message = new ObituaryFinalQuestionMessage(message)
  }
}

export function handleIncomingWebsocketMessage(
  e: any
):
  | ObituaryUserJoined
  | ObituaryUserLeft
  | ObituaryFinalQuestionMessage
  | ObituaryFinalQuestionMessageStatusUpdate
  | undefined {
  if (!e.data) {
    console.warn('No data in websocket message')
    return
  }
  let data
  try {
    data = JSON.parse(e.data)
  } catch (error) {
    console.warn('Could not parse data: ', e.data)
    return
  }
  if (!data.type) {
    console.warn('No type in data: ', data)
    return
  }
  if (data.type === 'error') {
    console.warn('Error from websocket: ', data)
    return
  }
  if (data.type === 'simple_update') {
    // We have data.type, data.user, and data.payload
    // data.payload is the actual data we want to update
    // data.user is the user who made the change
    if (!data.payload) {
      console.warn('No payload in data: ', data)
      return
    }
    if (!data.user) {
      console.warn('No user in data: ', data)
      return
    }
    if (!data.payload.subtype) {
      console.warn('No subtype in data: ', data)
      return
    }
    if (data.payload.subtype === 'user_joined') {
      return new ObituaryUserJoined(data.user)
    }
    if (data.payload.subtype === 'user_left') {
      return new ObituaryUserLeft(data.user)
    }
    if (data.payload.subtype === 'new_final_question_message') {
      if (!data.payload.message) {
        console.warn('No message in data: ', data)
        return
      }
      return new ObituaryFinalQuestionMessage(data.payload.message)
    }
    // final_question_message_requested_update:
    if (data.payload.subtype === 'final_question_message_requested_update') {
      if (!data.payload.message) {
        console.warn('No message in data: ', data)
        return
      }
      // data.payload.status must be "created" or "finished"
      if (!data.payload.status) {
        console.warn('No status in data: ', data)
        return
      }
      if (data.payload.status !== 'created' && data.payload.status !== 'finished') {
        console.warn('Invalid status in data: ', data)
        return
      }
      return new ObituaryFinalQuestionMessageStatusUpdate(data.payload.status, data.payload.message)
    }
  }
  console.warn(
    `Unknown subtype for simple_update (${data.payload.subtype || '(no subtype)'}): `,
    data
  )
  return undefined
}

function useObituaryQuestionsWebsocket(
  obituary: Obituary,
  onReceivedNewMessageCallback: (message: ObituaryFinalQuestionMessage) => void,
  onReceivedUpdateCallback: (update: ObituaryFinalQuestionMessageStatusUpdate) => void
) {
  /*
  We have a second websocket in case this scales differently than just the normal one.
  */

  const {auth, currentPhoenixUserPhone} = useAuth()
  const authToken = auth ? auth.api_token : null

  const [connectedWebsocket, setConnectedWebsocket] = useState<ReconnectingWebSocket | null>(null)

  const onSendWebsocketMessage = useCallback(
    (type: string, payload: any) => {
      if (!connectedWebsocket) {
        return
      }
      connectedWebsocket.send(JSON.stringify({type, payload}))
    },
    [connectedWebsocket]
  )

  const onSendNewResponse = useCallback(
    ({message}: {message: string}) => {
      onSendWebsocketMessage('from_user', {
        subtype: 'new_final_question_message',
        message,
      })
    },
    [onSendWebsocketMessage]
  )

  useEffect(() => {
    if (!authToken) {
      return
    }
    if (obituary.is_loading || !obituary.unique_identifier) {
      return
    }
    // Sets up the websocket connection for this card
    const webSocket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_API_WS_URL}/obituaries/${obituary.unique_identifier}/final-questions/?token=${authToken}`
    )
    setConnectedWebsocket(webSocket)
    return () => {
      webSocket.close()
    }
  }, [authToken, obituary.is_loading, obituary.unique_identifier])

  useEffect(() => {
    if (connectedWebsocket) {
      const handleMessage = (message: any) => {
        // First we parse the message. We use handleIncomingWebsocketMessage:
        const parsedMessage = handleIncomingWebsocketMessage(message)
        if (!parsedMessage || !currentPhoenixUserPhone) {
          // Warnings are logged up-stream of this function, so we can just
          // return here.
          return
        } else if (parsedMessage instanceof ObituaryFinalQuestionMessage) {
          onReceivedNewMessageCallback(parsedMessage)
        } else if (parsedMessage instanceof ObituaryFinalQuestionMessageStatusUpdate) {
          onReceivedUpdateCallback(parsedMessage)
        } else {
          console.warn('Unhandled question message: ', parsedMessage)
        }
      }
      connectedWebsocket.addEventListener('message', handleMessage)
      return () => {
        if (connectedWebsocket) {
          connectedWebsocket.removeEventListener('message', handleMessage)
        }
      }
    }
  }, [
    connectedWebsocket,
    currentPhoenixUserPhone,
    onReceivedNewMessageCallback,
    onReceivedUpdateCallback,
  ])

  return {
    onSendNewResponse,
  }
}

export default useObituaryQuestionsWebsocket
