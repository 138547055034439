function WaitingForApproval() {
  return (
    <div className={`card shadow`}>
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* Add an "Info" alert here */}
        <div className='p-5'>
          <div className='alert alert-info mt-5'>
            <div className='alert-text text-center'>
              <div className='fs-3 fw-bold'>Your account is pending approval.</div>
              <div className='fs-5 fw-bold my-4'>
                You will be notified when your account is approved.
              </div>
              <div className='fs-5 fw-bold'>Thank you for your patience!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WaitingForApproval
