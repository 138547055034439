import { Handle, Position } from 'reactflow';

import { NodeCopy } from '../InteractiveEmployeeManage';
import { NodeContainer, CopyTitle, CopyBody } from './NodeVideoWithQuestions';
import { FormContainer } from './NodeArbitraryDataForm';

function NodeCustomAIObituaryWriter({ id: nodeId, data }: { id: string, data: any }) {

    const copy = data.copy as NodeCopy;

    const targetNodeId = data?.targetNodeId;

    return (
        <NodeContainer $highlighted={data?.highlightedNodeId === nodeId}>
            {targetNodeId && <Handle type="target" position={Position.Left} id={targetNodeId} />}
            <FormContainer>
                <CopyTitle>
                    {copy.title}
                </CopyTitle>
                <CopyBody>
                    {copy.body}
                </CopyBody>
            </FormContainer>
        </NodeContainer>
    );
}

export default NodeCustomAIObituaryWriter;