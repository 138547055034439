/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useState } from 'react'

import Dropzone from 'react-dropzone-uploader'
import styled from 'styled-components'
import { KTSVG } from '../../../../_metronic/helpers'
import { useObituaryDetail } from '../components/ObituaryDetail'
import { Obituary, ObituaryImageUpload } from '../core/_models'
import { removeObituaryImage, saveObituaryImages } from '../core/_requests'
import { useAuth } from '../../auth/core/Auth'

const DropzoneContainer = styled.div`
  height: 300px;
  overflow-y: auto;

  .dropzone-document-upload {
    min-height: 100%;
    height: 100%;
    padding-bottom: 15px;
  }
`

interface UploadImagesModalProps {
  onClose: (updatedObituary?: Obituary, newUploads?: ObituaryImageUpload[]) => void
}

interface PendingObituaryImageUpload {
  id: string
  obituaryImageUpload: ObituaryImageUpload
}

const UploadImagesModal: FC<UploadImagesModalProps> = ({ onClose }) => {
  const { auth } = useAuth()
  const apiToken = auth?.api_token
  const { obituary, setObituary } = useObituaryDetail()

  const [savingDocumentsToProject, setSavingDocumentsToProject] = useState(false)

  const [uploadingIds, setUploadingIds] = useState<string[]>([])
  const [fileMetaIdsUploaded, setMetaIdsUploaded] = useState<string[]>([])
  const [serversideFileUploads, setServersideFileUploads] = useState<PendingObituaryImageUpload[]>(
    []
  )

  const [uploadError, setUploadError] = useState('')

  const canSaveDocuments = serversideFileUploads.length > 0 && uploadingIds.length === 0

  function ExistingImageRow({
    imageUpload,
    index,
    onRemovedImageUpload,
  }: {
    imageUpload: ObituaryImageUpload
    index: number
    onRemovedImageUpload: (imageUpload: ObituaryImageUpload) => void
  }) {
    const [confirmingRemoval, setConfirmingRemoval] = useState(false)

    return (
      <div className='mb-5'>
        {index > 0 && <div className='separator separator-dashed my-5' />}
        <div className='d-flex align-items-center justify-content-between'>
          <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
            <img
              src={imageUpload.image_url}
              alt={imageUpload.original_file_name}
              style={{ width: 'auto' }}
            />
          </div>
          <div>
            {!confirmingRemoval ? (
              <button
                type='button'
                className='btn btn-danger'
                onClick={() => {
                  setConfirmingRemoval(true)
                }}
              >
                Remove
              </button>
            ) : (
              <div>
                <span>
                  Are you sure you want to remove this image? This action cannot be undone.
                </span>
                <div className='d-flex mt-3 align-items-center justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={() => {
                      setConfirmingRemoval(false)
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type='button'
                    className='btn btn-danger ms-3'
                    onClick={() => {
                      onRemovedImageUpload(imageUpload)
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  // specify upload params and url for your files
  const getUploadParams = ({ meta }: { meta: any }) => {
    return {
      url: `${process.env.REACT_APP_API_URL}/obituaries/${obituary.unique_identifier}/upload-image/`,
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    }
  }

  // called every time a file's `status` changes
  const handleChangeStatus = (
    { meta, file, xhr }: { meta: any; file: any; xhr?: any },
    status: any
  ): void => {
    if (status === 'done') {
      setUploadError('')
      setMetaIdsUploaded([...fileMetaIdsUploaded, meta.id])
      setUploadingIds(uploadingIds.filter((id) => id !== meta.id))
      // Get the JSON response
      const response = JSON.parse(xhr.response)
      const fileUpload = new ObituaryImageUpload(response.data)
      // PendingObituaryImageUpload is necessary so we can remove items from the list
      // when the user cancels an upload or removes a file.
      setServersideFileUploads([
        ...serversideFileUploads,
        {
          id: meta.id,
          obituaryImageUpload: fileUpload,
        },
      ])
    } else if (status === 'uploading') {
      setUploadError('')
      setUploadingIds([...uploadingIds, meta.id])
    } else if (status === 'removed' || status === 'aborted') {
      setMetaIdsUploaded(fileMetaIdsUploaded.filter((id: any) => id !== meta.id))
      setUploadingIds(uploadingIds.filter((id: any) => id !== meta.id))
      setServersideFileUploads(serversideFileUploads.filter((upload) => upload.id !== meta.id))
    } else if (status === 'exception_upload' || status === 'error_upload') {
      setUploadError('Error uploading file')
      setUploadingIds(uploadingIds.filter((id: any) => id !== meta.id))
    }
  }

  const saveDocuments = async () => {
    if (serversideFileUploads.length === 0) {
      return
    }
    setSavingDocumentsToProject(true)
    const fileUploadUniqueIds = serversideFileUploads.map(
      (fileUpload: PendingObituaryImageUpload) => fileUpload.obituaryImageUpload.unique_id
    )
    try {
      const updatedObituary = await saveObituaryImages(obituary.unique_identifier, fileUploadUniqueIds)
      onClose(
        updatedObituary,
        serversideFileUploads.map((upload) => upload.obituaryImageUpload)
      )
      setObituary(updatedObituary)
    } catch (error: any) {
      console.warn(error)
      setUploadError(error?.response?.data?.error || 'Unable to save images to obituary')
    } finally {
      // onClose will unmount this component, so not sure
      // if setting this state is necessary.
      setSavingDocumentsToProject(false)
    }
  }

  const onRemovedImageUpload = useCallback(
    async (imageUpload: ObituaryImageUpload) => {
      try {
        const updatedObituary = await removeObituaryImage(obituary.unique_identifier, imageUpload.unique_id)
        setObituary(updatedObituary)
      } catch (error: any) {
        console.warn(error)
      }
    },
    [obituary.unique_identifier, setObituary]
  )

  return (
    <div className='modal-dialog modal-dialog-centered mw-900px'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>{`Upload Images to ${obituary.getSimpleName()}'s obituary`}</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => onClose()}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body py-lg-5 px-lg-5'>
          <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
            <li className='nav-item'>
              <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                Upload New Images
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                Manage Existing Images
              </a>
            </li>
          </ul>
          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
              <DropzoneContainer>
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  accept='image/*'
                  classNames={{
                    ...Dropzone.defaultProps.classNames,
                    dropzone: 'dzu-dropzone dropzone-document-upload',
                  }}
                  inputContent='Drag and drop images here or click to upload'
                  inputWithFilesContent='Add more images'
                />
              </DropzoneContainer>
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
              <div style={{ minHeight: 300, backgroundColor: '#f1f1f1' }} className='p-5 rounded'>
                {obituary.active_image_uploads.length > 0 ? (
                  <>
                    {obituary.active_image_uploads.map((imageUpload, index) => {
                      return (
                        <ExistingImageRow
                          key={`image-upload-${imageUpload.unique_id}`}
                          imageUpload={imageUpload}
                          index={index}
                          onRemovedImageUpload={onRemovedImageUpload}
                        />
                      )
                    })}
                  </>
                ) : (
                  <p>No images have been uploaded</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='modal-footer'>
          <div className='d-flex flex-column flex-grow-1 px-5'>
            <div className='row'>
              <div className='col-xl-12'>
                {uploadError && (
                  <div className='mb-0 alert alert-danger'>
                    <div className='alert-text font-weight-bold text-center'>{uploadError}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='d-flex flex-stack'>
            <div>
              <button
                type='button'
                className='btn btn-lg btn-primary me-3'
                disabled={!canSaveDocuments}
                onClick={saveDocuments}
              >
                {savingDocumentsToProject ? (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <>
                    <span className='indicator-label'>
                      {uploadingIds.length > 0
                        ? `Waiting for Upload${uploadingIds.length !== 1 ? 's' : ''} to Complete...`
                        : 'Save Images to Obituary'}
                    </span>
                    <span className='indicator-label'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadImagesModal
