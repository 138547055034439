import { useState, useCallback, useEffect } from 'react'
import { ApiError } from '../../auth'
import { useObituaryDetail } from './ObituaryDetail'
import { getObituaryContributors, reinviteContributor } from '../core/_requests'
import { KnownFamilyMember } from '../core/_models'
import styled from 'styled-components'
import { useAuth } from '../../auth/core/Auth'

const FamilyMemberRow = styled.div`
  p {
    font-size: 1.5rem;
  }
`

function ObituaryContributors() {
  const { currentPhoenixUserPhone } = useAuth()

  const { obituary, setAddingFamilyMember } = useObituaryDetail()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const [reinvitingUniqueId, setReinvitingUniqueId] = useState<string | null>(null)
  const [reinviteError, setReinviteError] = useState<string | null>(null)

  // I don't love the KnownFamilyMember naming but here we are

  const [knownFamilyMembers, setKnownFamilyMembers] = useState<KnownFamilyMember[]>([])

  const onLoadContributors = useCallback(async () => {
    try {
      const { contributors: updatedKnownFamilyMembers } = await getObituaryContributors(
        obituary.unique_identifier
      )
      setKnownFamilyMembers(updatedKnownFamilyMembers)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [obituary.unique_identifier])

  const onReinvite = useCallback(
    async (contributor: KnownFamilyMember) => {
      try {
        setReinvitingUniqueId(contributor.unique_id)
        setReinviteError(null)
        await reinviteContributor(obituary.unique_identifier, contributor.unique_id)
      } catch (error: any) {
        console.warn(error)
        const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
        setReinviteError(errorMessage)
      } finally {
        setReinvitingUniqueId(null)
      }
    },
    [obituary.unique_identifier]
  )

  useEffect(() => {
    onLoadContributors()
  }, [onLoadContributors])

  // If the current user is an admin, show all contributors
  // If the current user is not an admin, show all contributors except for admins

  const useKnownFamilyMembers = currentPhoenixUserPhone?.is_admin
    ? knownFamilyMembers
    : knownFamilyMembers.filter((knownFamilyMember) => !knownFamilyMember.tied_to_user?.is_admin)

  if (loading) {
    return (
      <div>
        <div className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='alert alert-danger' role='alert'>
        <div className='alert-text'>{error}</div>
      </div>
    )
  }

  return (
    <div className='mt-5'>
      {reinviteError && (
        <div className='alert alert-danger' role='alert'>
          <div className='alert-text'>{reinviteError}</div>
        </div>
      )}
      {useKnownFamilyMembers.length === 0 ? (
        <>
          <h3 className='mb-5'>No contributors yet</h3>
        </>
      ) : (
        <>
          {useKnownFamilyMembers.map((knownFamilyMember, index) => {
            return (
              <FamilyMemberRow key={knownFamilyMember.unique_id}>
                {index > 0 && <div className='separator separator-dashed my-7'></div>}
                <h3 className='mb-5'>{`${knownFamilyMember.name ||
                  knownFamilyMember.tied_to_user?.user_profile?.getSimpleName() ||
                  '(No name given)'
                  } (${knownFamilyMember.is_self ? 'self' : knownFamilyMember.relationship || 'unknown'
                  })`}</h3>
                <p>{knownFamilyMember.phone_number}</p>
                {!knownFamilyMember.tied_to_user?.email_is_unknown &&
                  knownFamilyMember.email_address !== knownFamilyMember.tied_to_user?.email && (
                    <p>{knownFamilyMember.tied_to_user?.email}</p>
                  )}
                <p>{knownFamilyMember.email_address}</p>
                <p>
                  <button
                    className='btn btn-sm btn-secondary'
                    onClick={() => onReinvite(knownFamilyMember)}
                    disabled={!!reinvitingUniqueId}
                  >
                    {reinvitingUniqueId === knownFamilyMember.unique_id
                      ? 'Re-Inviting...'
                      : 'Re-Invite'}
                  </button>
                </p>
              </FamilyMemberRow>
            )
          })}
        </>
      )}
      <div className='separator separator-dashed my-7'></div>
      <button
        type='button'
        className='btn btn-primary'
        onClick={() => {
          setAddingFamilyMember(true)
        }}
      >
        Add Family Member
      </button>
    </div>
  )
}

export default ObituaryContributors
