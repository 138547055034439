import { useState } from 'react'
import styled from 'styled-components'
import { DateTime } from 'luxon'

import { useObituaryDetail } from './components/ObituaryDetail'
import ObituaryAvatarPicker from './ObituaryAvatarPicker'

const Container = styled.div`
  background: #1a1a1a;
  color: white;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
`

const PhotoContainer = styled.div`
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  text-align: center;
`

const HeaderWords = styled.div`
  font-size: 2rem;
`

const HeaderName = styled.div`
  font-size: 3rem;
`

const HeaderDatesAndSymbol = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 2rem;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 100%;
`

const HeaderBirthDate = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 10px;
`

const HeaderSymbol = styled.div`
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props: { url: string }) => props.url});
  margin: 0 10px;
  border-radius: 10px;
`

const HeaderDeathDate = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding-left: 10px;
`

const HeaderQuote = styled.div`
  font-size: 2rem;
  margin-bottom: 20px;
`
const MilitaryFlag = styled.div`
  background-image: url('https://imgur.com/hWzKnhI.png');
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

const MilitaryFlagOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
`

interface GravemarkerDesignerProps {
  type: 'text' | 'photo' | 'military'
}

function GravemarkerDesigner({ type }: GravemarkerDesignerProps) {
  const { obituary } = useObituaryDetail()

  const [editingName, setEditingName] = useState(false)
  const [mediaUploadModalOpen, setMediaUploadModalOpen] = useState(false)

  console.log('obituary: ', obituary.dates_lived_string)
  console.log('obituary: ', obituary.data_personal_information?.birthDate)
  console.log('obituary: ', obituary.data_personal_information?.dateOfDeath)

  function formatDate(rawDateString: string) {
    const date = DateTime.fromISO(rawDateString)
    return date.toLocaleString(DateTime.DATE_MED)
  }

  return (
    <Container className='shadow position-relative'>
      {type === 'photo' && (
        <PhotoContainer>
          <ObituaryAvatarPicker
            obituary={obituary}
            onClick={() => setMediaUploadModalOpen(true)}
            condensed={false}
          />
        </PhotoContainer>
      )}
      <Content>
        <HeaderWords>In Memory Of</HeaderWords>
        <HeaderName>
          {editingName ? (
            <input
              type='text'
              defaultValue={obituary.getSimpleName() || ''}
              className='form-control'
            />
          ) : (
            <span>{obituary.getSimpleName() || 'Name'}</span>
          )}
        </HeaderName>
        <HeaderDatesAndSymbol>
          <HeaderBirthDate>
            {obituary.data_personal_information?.birthDate
              ? formatDate(obituary.data_personal_information?.birthDate)
              : 'Unknown'}
          </HeaderBirthDate>
          <HeaderSymbol
            url='https://media.discordapp.net/attachments/996261824280399923/1089946555236220990/cohoat_dark_simplistic_minimalistic_delicate_intertwining_quill_0d1f8ed8-18a1-4e9c-bbc6-f722cfadb4af.png'
            className='shadow'
          />
          <HeaderDeathDate>
            {obituary.data_personal_information?.dateOfDeath
              ? formatDate(obituary.data_personal_information?.dateOfDeath)
              : 'Present'}
          </HeaderDeathDate>
        </HeaderDatesAndSymbol>
        <HeaderQuote>{obituary.getTributeText() || 'Forever in our hearts'}</HeaderQuote>
      </Content>
      {type === 'military' && (
        <MilitaryFlag>
          <MilitaryFlagOverlay />
        </MilitaryFlag>
      )}
    </Container>
  )
}

export default GravemarkerDesigner
