/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import CopyToClipboard from 'react-copy-to-clipboard'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { Obituary } from './core/_models'
import { archiveObituary, getObituaryDownloadLink, resetObituaryQuestions } from './core/_requests'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { useAuth } from '../auth/core/Auth'
import { useObituaryDetail } from './components/ObituaryDetail'

interface ObituaryDropdownProps {
  obituary: Obituary
}

function ObituaryDropdown({ obituary }: ObituaryDropdownProps) {
  const navigate = useNavigate()

  const { currentPhoenixUserPhone } = useAuth()
  const { setAddingFamilyMember } = useObituaryDetail()

  const [confirmingReset, setConfirmingReset] = React.useState(false)
  const [confirmingArchive, setConfirmingArchive] = React.useState(false)

  const [resetting, setResetting] = React.useState(false)
  const [archiving, setArchiving] = React.useState(false)
  const [generatingDownloadLink, setGeneratingDownloadLink] = React.useState(false)

  const handleReset = useCallback(async () => {
    setConfirmingReset(true)
  }, [])

  const handleConfirm = useCallback(async () => {
    //resetObituaryQuestions
    setResetting(true)
    try {
      await resetObituaryQuestions(obituary.unique_identifier)
      setConfirmingReset(false)
      // Close the dropdown
      // by clicking the button
      // that opened it. Little hacky but it works
      document.getElementById('obituary_options_button')?.click()
    } catch (error) {
      console.error(error)
      // TODO: Toast
    } finally {
      setResetting(false)
    }
  }, [obituary.unique_identifier])

  const onArchiveObituary = useCallback(async () => {
    setArchiving(true)
    try {
      await archiveObituary(obituary.unique_identifier)
      navigate('/')
    } catch (error) {
      console.error(error)
      setArchiving(false)
    }
  }, [obituary.unique_identifier, navigate])

  const onObituaryDownload = useCallback(async () => {
    // Call getObituaryDownloadLink
    setGeneratingDownloadLink(true)
    try {
      const downloadLink = await getObituaryDownloadLink(obituary.unique_identifier)
      window.open(downloadLink, '_blank')
    } catch (error) {
      console.error(error)
    } finally {
      setGeneratingDownloadLink(false)
    }
  }, [obituary.unique_identifier])

  const [copied, setCopied] = useState(false)
  useEffect(() => {
    if (!copied) {
      return
    }

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }, [copied])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <div className='ms-4'>
      <button
        type='button'
        id='obituary_options_button'
        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <i className='bi bi-three-dots fs-3'></i>
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Obituary Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='p-5'>
          <div className='form-text'>Share this link with family and friends:</div>
          <div className='input-group input-group-sm'>
            <input
              type='text'
              className='form-control form-control-sm'
              placeholder='Public link to obituary...'
              value={obituary.public_link}
              readOnly
            />
            <OverlayTrigger
              key='copy-to-clipboard'
              placement='top'
              overlay={
                true && 'disabledForNow' ? (
                  <></>
                ) : (
                  <Tooltip id='tooltip-copy-to-clipboard'>Copy Link to Clipboard</Tooltip>
                )
              }
            >
              <CopyToClipboard text={obituary.public_link} onCopy={() => setCopied(true)}>
                <a className='btn btn-sm btn-secondary'>{copied ? 'Copied!' : 'Copy'}</a>
              </CopyToClipboard>
            </OverlayTrigger>
          </div>
        </div>
        {false && 'disablingAddingForNow' && <div className='px-7 py-0'>
          <div className='mb-4'>
            <div className='mt-5'>
              <label className='form-label fw-bold'>Add Family Member</label>
              <div>
                <button
                  type='button'
                  className='btn btn-primary btn-sm'
                  onClick={() => {
                    setAddingFamilyMember(true)
                  }}
                >
                  Add Family Member
                </button>
              </div>
            </div>
          </div>
        </div>}
        {false && 'downloading' && currentPhoenixUserPhone?.is_superuser && (
          <div className='px-7 py-0'>
            <div className='mb-4'>
              <div className='mt-5'>
                <label className='form-label fw-bold'>Download</label>
                <div>
                  <button
                    type='button'
                    className='btn btn-primary btn-sm'
                    onClick={onObituaryDownload}
                    disabled={generatingDownloadLink}
                  >
                    {!generatingDownloadLink ? 'Download' : 'Generating...'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {false && 'archiving' && <div className='px-7 py-5'>
          <div className='mb-4'>
            <div className='mt-0'>
              {false && 'showResetOption' && (
                <>
                  {confirmingReset ? (
                    <>
                      <label className='form-label fw-bold'>
                        Are you sure you want to reset the obituary questions?
                      </label>
                      <div>
                        <button
                          type='button'
                          className='btn btn-danger btn-sm'
                          onClick={handleConfirm}
                          disabled={resetting}
                        >
                          {!resetting ? 'Yes, reset' : 'Resetting...'}
                        </button>
                        <button
                          type='button'
                          className='btn btn-light btn-sm ms-2'
                          onClick={(e: any) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setConfirmingReset(false)
                          }}
                          disabled={resetting}
                        >
                          No, Cancel
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <label className='form-label fw-bold'>Reset</label>
                      <div>
                        <button
                          type='button'
                          className='btn btn-danger btn-sm'
                          onClick={handleReset}
                        >
                          Reset Questions
                        </button>
                      </div>
                    </>
                  )}
                  <div className='separator my-4'></div>
                </>
              )}

              {confirmingArchive ? (
                <>
                  <label className='form-label fw-bold'>
                    Are you sure you want to archive this obituary?
                  </label>
                  <div>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm'
                      onClick={onArchiveObituary}
                      disabled={archiving}
                    >
                      {!resetting ? 'Yes, archive' : 'Archiving...'}
                    </button>
                    <button
                      type='button'
                      className='btn btn-light btn-sm ms-2'
                      onClick={(e: any) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setConfirmingArchive(false)
                      }}
                      disabled={archiving}
                    >
                      No, Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <label className='form-label fw-bold'>Archive Obituary</label>
                  <div>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm'
                      onClick={() => {
                        setConfirmingArchive(true)
                      }}
                    >
                      Archive Obituary
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default ObituaryDropdown
