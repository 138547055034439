import { useState, useRef, useEffect, useCallback } from "react";
import ReactSlider from "react-slider";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

import { useJourneyPersonalizationDetail } from "./PersonalizeWelcomeVideoCustomizeUpload";
import LeftChevronIcon from "./LeftChevronIcon";
import RightChevronIcon from "./RightChevronIcon";
import { updateJourneyPersonalizationVideoCrop } from "../../../funeral_homes/dashboard/core/_requests";
import PlayButtonIcon from "./PlayButtonIcon";
import PauseButtonIcon from "./PauseButtonIcon";
import { ApiError } from "../../auth";
import { useAuth } from "../../auth/core/Auth";

const PlayAndSliderContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const PlayContainer = styled.div`
    height: 60px;
    width: 80px;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 6px;
    color: #fff;
`;

const CurrentProgressVerticalLine = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: red;
    z-index: 2;
    user-select: none;
`;

const SliderContainer = styled.div`
    display: flex;
    flex-grow: 1;
    position: relative;

    .horizontal-slider {
        width: 100%;
        height: 60px;
    }

    .horizontal-slider .scrub-track {
        background-color: #e9ecef;
        height: 100%;
        border-radius: 10px;
    }

    .horizontal-slider .scrub-thumb {
        width: 30px;
        height: 100%;
        background-color: #fdcc00;
        border-radius: 0;
        cursor: grab;
    }

    .horizontal-slider .scrub-thumb-0 {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
    }

    .horizontal-slider .scrub-thumb-1 {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .horizontal-slider .scrub-thumb:active {
        cursor: grabbing;
    }

    .scrub-track-1 {
        border-top: 6px solid #fdcc00;
        border-bottom: 6px solid #fdcc00;
        position: relative;
    }

    .scrub-track-0, .scrub-track-2 {
        overflow: hidden;
        position: relative;

        ::before {
            content: "";
            background-color: rgba(0, 0, 0, 0.8);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

`;

const ActiveTrack = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const BottomPreviewActionsContainer = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
`;

export const formatMsToNiceSecondsString = (currentDurationMilliseconds: number) => {
    const durationInMilliseconds = currentDurationMilliseconds;
    const secondsRoundedToTwoDecimalPlaces = (durationInMilliseconds / 1000).toFixed(2);
    return secondsRoundedToTwoDecimalPlaces;
}

function BackgroundImagePicker({ selectedExternalImageUrl, setSelectedExternalImageUrl }: { selectedExternalImageUrl: string | null, setSelectedExternalImageUrl: (url: string | null) => void }) {

    const [previewCategoryUrl, setPreviewCategoryUrl] = useState<string | null>(null);

    const [availableBackgroundCategories, _setAvailableBackgroundCategories] = useState([
        {
            name: 'Interior',
            images: [
                {
                    url: 'https://uploads.obituaries.ai/video-backgrounds/interior-background-1.png',
                },
                {
                    url: 'https://uploads.obituaries.ai/video-backgrounds/interior-background-2.png',
                },
                {
                    url: 'https://uploads.obituaries.ai/video-backgrounds/interior-background-3.png',
                },
                {
                    url: 'https://uploads.obituaries.ai/video-backgrounds/interior-background-4.png',
                },
            ]
        },
        {
            name: 'Chapel',
            images: [
                {
                    url: 'https://uploads.obituaries.ai/video-backgrounds/chapel-background-1.png',
                },
                {
                    url: 'https://uploads.obituaries.ai/video-backgrounds/chapel-background-2.png',
                },
                {
                    url: 'https://uploads.obituaries.ai/video-backgrounds/chapel-background-3.png',
                },
                {
                    url: 'https://uploads.obituaries.ai/video-backgrounds/chapel-background-4.png',
                },
            ]
        },
        {
            name: 'Garden',
            images: [
                {
                    url: 'https://uploads.obituaries.ai/video-backgrounds/garden-background-1.png',
                },
                {
                    url: 'https://uploads.obituaries.ai/video-backgrounds/garden-background-2.png',
                },
                {
                    url: 'https://uploads.obituaries.ai/video-backgrounds/garden-background-3.png',
                },
                {
                    url: 'https://uploads.obituaries.ai/video-backgrounds/garden-background-4.png',
                },
            ]
        }
    ]);

    const toggleBodyScroll = useCallback((shouldEnable: boolean) => {
        if (shouldEnable) {
            document.body.style.overflow = 'unset';
        } else {
            document.body.style.overflow = 'hidden';
        }
    }, []);

    return <div>
        {availableBackgroundCategories.map((category) => {
            return <div key={category.name} className='mb-5'>
                <div className='fw-bold'>{category.name}</div>
                <div className='row'>
                    {category.images.map((image) => {
                        const imageSelected = selectedExternalImageUrl === image.url;
                        return <div className='col-lg-3' key={image.url}>
                            <div
                                className='mt-5'
                                style={{
                                    backgroundImage: `url(${image.url})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    height: '100px',
                                    width: '100%',
                                    borderRadius: '10px',
                                    cursor: 'pointer',
                                    ...(imageSelected ? {
                                        border: '5px solid #fdcc00',
                                    } : {})
                                }}
                                onClick={() => {
                                    setPreviewCategoryUrl(image.url);
                                    toggleBodyScroll(false);
                                }}
                            />
                        </div>
                    })}
                </div>
            </div>
        })}
        {previewCategoryUrl && <div style={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', zIndex: 999999999 }}>
            <div style={{ position: 'relative', height: '100%', width: '100%', padding: '30px 0', }}>
                <div
                    style={{ zIndex: 1, backgroundColor: 'rgba(0,0,0,0.8)', position: 'absolute', top: '0', left: '0', right: '0', bottom: '0' }}
                    onClick={() => {
                        setPreviewCategoryUrl(null);
                        toggleBodyScroll(true);
                    }} />
                <div
                    style={{
                        zIndex: 2,
                        height: '80%',
                        width: '80%',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        position: 'relative',
                        margin: '0 auto',
                    }}
                >
                    <img src={previewCategoryUrl} style={{ height: '100%', width: '100%', objectFit: 'contain' }} alt='Preview' />
                </div>
                <BottomPreviewActionsContainer>
                    <button className='btn btn-secondary me-3' onClick={() => {
                        setPreviewCategoryUrl(null);
                        toggleBodyScroll(true);
                    }}>Cancel</button>
                    <button className='btn btn-primary ms-3' onClick={() => {
                        setPreviewCategoryUrl(null);
                        toggleBodyScroll(true);
                        setSelectedExternalImageUrl(previewCategoryUrl);
                    }}>Use This Background</button>
                </BottomPreviewActionsContainer>
            </div>
        </div>}
    </div>
}

function JourneyPersonalizationEditForm() {
    const {
        basePath,
        journeyPersonalization,
        previewIsActive,
        setPreviewIsActive,
    } = useJourneyPersonalizationDetail();

    const playerRef = useRef<ReactPlayer>(null);

    const enableProgrammaticTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const [currentDurationMilliseconds, setCurrentDurationMilliseconds] = useState(0);
    const [allowedDurationMilliseconds, setAllowedDurationMilliseconds] = useState(0);

    const [startValue, setStartValue] = useState(0);
    const [endValue, setEndValue] = useState(0);

    const [videoHasLoaded, setVideoHasLoaded] = useState(false);

    const [isPlaying, setIsPlaying] = useState(false);

    const [currentProgressTimeMs, setCurrentProgressTimeMs] = useState(0);
    const [canBePausedProgrammatically, setCanBePausedProgrammatically] = useState(false);

    const [backgroundChoice, setBackgroundChoice] = useState(journeyPersonalization?.data.background_choice || '');
    const [backgroundImageUrl, setBackgroundImageUrl] = useState(journeyPersonalization?.data.background_image_url || '');
    const [backgroundImageColor, setBackgroundImageColor] = useState(journeyPersonalization?.data.background_image_color || '');
    const [selectedExternalImageUrl, setSelectedExternalImageUrl] = useState(journeyPersonalization?.data.selected_external_image_url || null);

    const [savingValues, setSavingValues] = useState(false);
    const [errorSavingValues, setErrorSavingValues] = useState<string | null>(null);
    const [successfullySavedMessage, setSuccessfullySavedMessage] = useState<string | null>(null);


    useEffect(() => {
        if (!journeyPersonalization) {
            return
        }
        // Sets up the initial values
        setBackgroundChoice(journeyPersonalization?.data.background_choice || '');
        setBackgroundImageUrl(journeyPersonalization?.data.background_image_url || '');
        setBackgroundImageColor(journeyPersonalization?.data.background_image_color || '');
        setSelectedExternalImageUrl(journeyPersonalization?.data.selected_external_image_url || null);
    }, [journeyPersonalization]);

    const updateVideoBackgroundValues = useCallback(async () => {
        if (!journeyPersonalization) {
            return;
        }
        setErrorSavingValues(null);
        setSavingValues(true);
        setSuccessfullySavedMessage(null);
        try {
            await updateJourneyPersonalizationVideoCrop(journeyPersonalization.unique_identifier, {
                start_time_ms: startValue,
                end_time_ms: endValue,
                background_choice: (backgroundChoice || '').trim(),
                background_image_url: (backgroundImageUrl || '').trim(),
                background_image_color: (backgroundImageColor || '').trim(),
                selected_external_image_url: (selectedExternalImageUrl || '').trim(),
            });
            setSuccessfullySavedMessage('Successfully saved changes');
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof ApiError ? exception.message : 'Something went wrong'
            setErrorSavingValues(errorMessage);
        } finally {
            setSavingValues(false);
        }
    }, [journeyPersonalization, startValue, endValue, backgroundChoice, backgroundImageUrl, backgroundImageColor, selectedExternalImageUrl]);

    if (!journeyPersonalization) {
        return null;
    }

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <Link
                    className="btn btn-light"
                    to={`${basePath}/upload`}
                >
                    Go Back
                </Link>
                <h4>
                    Preview and Crop Video
                </h4>
                <a
                    className="btn btn-warning"
                    href={`${process.env.NODE_ENV === 'production' ? 'https://www.solemnoaks.com' : 'http://localhost:3000'}?obit_ai_jf_uid=need-to-update-this-${journeyPersonalization.unique_identifier}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Preview
                </a>
            </div>
            {false && 'thisIsHandyForDebugging' && <div>
                {journeyPersonalization?.journey_upload?.uploaded_file_url || 'No video uploaded'}
            </div>}
            <div className="mt-6" style={{ position: 'relative', borderRadius: '10px', overflow: 'hidden' }}>
                <ReactPlayer
                    ref={playerRef}
                    url={journeyPersonalization.journey_upload?.uploaded_file_url}
                    controls
                    width="100%"
                    height="auto"
                    onReady={(player) => {
                        if (!videoHasLoaded) {
                            setVideoHasLoaded(true);
                            const videoDurationInSeconds = player.getDuration();
                            const videoDurationMilliseconds = videoDurationInSeconds * 1000;  // Convert to milliseconds
                            setAllowedDurationMilliseconds(videoDurationMilliseconds);
                            if (journeyPersonalization.data.video_crop) {
                                setStartValue(journeyPersonalization.data.video_crop.start_time_ms || 0);
                                setEndValue(journeyPersonalization.data.video_crop.end_time_ms || videoDurationMilliseconds);
                                const computedDurationMilliseconds = (journeyPersonalization.data.video_crop.end_time_ms || videoDurationMilliseconds) - (journeyPersonalization.data.video_crop.start_time_ms || 0);
                                setCurrentDurationMilliseconds(computedDurationMilliseconds);
                            } else {
                                setEndValue(videoDurationMilliseconds);
                                setStartValue(0);
                                setCurrentDurationMilliseconds(videoDurationMilliseconds);
                            }
                        }
                    }}
                    progressInterval={100}
                    onProgress={({ playedSeconds }) => {
                        const playedMilliseconds = playedSeconds * 1000;
                        setCurrentProgressTimeMs(playedMilliseconds);
                        // This seems to work? Unless live-reload breaks it?
                        if (canBePausedProgrammatically && endValue > 0 && playedMilliseconds >= endValue) {
                            // Pause the video
                            playerRef.current?.getInternalPlayer().pause();
                        }
                    }}
                    onError={(error) => {
                        console.warn('onError', error);
                    }}
                    onPlay={() => {
                        setCanBePausedProgrammatically(false);
                        setIsPlaying(true);
                        if (enableProgrammaticTimeoutRef.current) {
                            clearTimeout(enableProgrammaticTimeoutRef.current);
                        }
                        enableProgrammaticTimeoutRef.current = setTimeout(() => {
                            // This avoids some weird race conditions
                            setCanBePausedProgrammatically(true);
                        }, 1000);
                    }}
                    onPause={() => {
                        setIsPlaying(false);
                        setCanBePausedProgrammatically(false);
                        if (enableProgrammaticTimeoutRef.current) {
                            clearTimeout(enableProgrammaticTimeoutRef.current);
                        }
                    }}
                />
            </div>
            {videoHasLoaded && <>
                <PlayAndSliderContainer>
                    <PlayContainer onClick={() => {
                        if (isPlaying) {
                            playerRef.current?.getInternalPlayer().pause();
                        } else {
                            const seekToTimeInSeconds = startValue / 1000;
                            playerRef.current?.seekTo(seekToTimeInSeconds);
                            playerRef.current?.getInternalPlayer().play();
                        }
                    }}>
                        {!isPlaying ? <PlayButtonIcon /> : <PauseButtonIcon />}
                    </PlayContainer>
                    <SliderContainer>
                        <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="scrub-thumb"
                            trackClassName="scrub-track"
                            ariaLabel={['Lower thumb', 'Upper thumb']}
                            ariaValuetext={state => `Thumb value ${state.valueNow}`}
                            pearling
                            markClassName="mark"
                            thumbActiveClassName="scrub-thumb--active"
                            minDistance={5}
                            value={[startValue, endValue]}
                            onSliderClick={(_value) => { }}
                            onAfterChange={([updatedStartTime, updatedEndTime], selectedIndex) => {
                                setStartValue(updatedStartTime);
                                setEndValue(updatedEndTime);
                                setCurrentDurationMilliseconds(updatedEndTime - updatedStartTime);
                                if (selectedIndex === 0) {
                                    playerRef.current?.seekTo(updatedStartTime / 1000);
                                    playerRef.current?.getInternalPlayer().play();
                                } else {
                                    playerRef.current?.seekTo(updatedEndTime / 1000);
                                }
                            }}
                            renderThumb={(props, state) => {
                                const showLeftChevron = state.index === 0;
                                return <div {...props}>
                                    {showLeftChevron ? <LeftChevronIcon /> : <RightChevronIcon />}
                                </div>
                            }}
                            renderTrack={(props, state) => {
                                const isMainTrack = state.index === 1;
                                if (!isMainTrack) {
                                    return <div {...props} />;
                                }
                                return <div {...props}>
                                    <ActiveTrack>
                                        <div>
                                            Duration: {formatMsToNiceSecondsString(currentDurationMilliseconds)} seconds
                                        </div>
                                        <div style={{ fontSize: '0.8rem' }}>
                                            {`(${formatMsToNiceSecondsString(startValue)} to ${formatMsToNiceSecondsString(endValue)})`}
                                        </div>
                                    </ActiveTrack>
                                </div>
                            }}
                            min={0}
                            max={allowedDurationMilliseconds}
                        />
                        {currentProgressTimeMs > 0 && <CurrentProgressVerticalLine style={{ left: `${(currentProgressTimeMs / allowedDurationMilliseconds) * 100}%` }} />}
                    </SliderContainer>
                </PlayAndSliderContainer>
                <div className='row mt-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Video Background</label>
                    <div className='col-lg-8 fv-row'>
                        <div>
                            <select className='form-select form-select-solid form-select-lg fw-bold' name='background_choice' value={backgroundChoice} onChange={(e) => {
                                const updatedValue = e.target.value;
                                setBackgroundChoice(updatedValue);
                            }}>
                                <option value=''>(Keep background)</option>
                                <option value='image'>Remove Background and Replace with Image</option>
                            </select>
                        </div>
                    </div>
                </div>
                {backgroundChoice === 'color' && <div className='row mt-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Color to be used</label>
                    <div className='col-lg-8 fv-row'>
                        <div>
                            <input
                                className='form-control form-control-lg form-control-solid'
                                name='background_image_color'
                                value={backgroundImageColor}
                                onChange={(event) => {
                                    setBackgroundImageColor(event.target.value);
                                }} />
                        </div>
                        {true && <div className='form-text'>{`Must be a valid CSS color (e.g. #fffff)`}</div>}
                    </div>
                </div>}
                {backgroundChoice === 'image' && <div className='row mt-7'>
                    {true ? <>
                        <label className='col-lg-4 fw-bold text-muted'>Background image to be used</label>
                        <div className='col-lg-8 fv-row'>
                            <BackgroundImagePicker selectedExternalImageUrl={selectedExternalImageUrl} setSelectedExternalImageUrl={setSelectedExternalImageUrl} />
                        </div>
                    </> : <>
                        <label className='col-lg-4 fw-bold text-muted'>URL to image to be used</label>
                        <div className='col-lg-8 fv-row'>
                            <div>
                                <input
                                    className='form-control form-control-lg form-control-solid'
                                    name='background_image_url'
                                    value={backgroundImageUrl}
                                    onChange={(event) => {
                                        setBackgroundImageUrl(event.target.value);
                                    }} />
                            </div>
                            {true && <div className='form-text'>{`Must be a valid image URL (e.g. https://www.example.com/image.jpg)`}</div>}
                        </div>
                    </>}
                </div>}
                <div className='row mt-7'>
                    <label className='col-lg-4 fw-bold text-muted'></label>
                    <div className='col-lg-8 fv-row'>
                        <div>
                            <button
                                className='btn btn-primary'
                                onClick={() => {
                                    updateVideoBackgroundValues();
                                }}
                            >
                                {savingValues ? "Saving..." : "Save Changes"}
                            </button>
                        </div>
                        {true && <div className='form-text'>{`Cropping and updating the video background may take some time to be applied`}</div>}
                    </div>
                </div>
                {errorSavingValues && <div className='row mt-7'>
                    <label className='col-lg-4 fw-bold text-muted'></label>
                    <div className='col-lg-8 fv-row'>
                        <div className='alert alert-danger' role='alert'>
                            {errorSavingValues}
                        </div>
                    </div>
                </div>}
                {successfullySavedMessage && <div className='row mt-7'>
                    <label className='col-lg-4 fw-bold text-muted'></label>
                    <div className='col-lg-8 fv-row'>
                        <div className='alert alert-success' role='alert'>
                            {successfullySavedMessage}
                        </div>
                    </div>
                </div>}
            </>}
        </div>
    );
}

export default JourneyPersonalizationEditForm;
