import styled from 'styled-components';
import {
    ChevronLeft
} from 'react-feather'
import { useCallback, useState } from 'react';
import { JourneyPersonalization } from '../../../../../funeral_homes/dashboard/core/journey_classes';
import { removeVideoFromJourneyPersonalization } from '../../../../../funeral_homes/dashboard/core/_requests';
import { ApiError } from '../../../../auth';

const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
`;

const GoBackContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    cursor: pointer;
`;

const ActionsContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

function NodeManageVideoItem({
    journeyPersonalization,
    setJourneyPersonalizationForNode,
    onGoBack,
    onRecordVideo,
    onUploadVideo,
}: {
    nodeId: string;
    journeyPersonalization: JourneyPersonalization | null;
    setJourneyPersonalizationForNode: (journeyPersonalization: JourneyPersonalization | null) => void;
    onGoBack: () => void;
    onRecordVideo: () => void;
    onUploadVideo: () => void;
}) {

    const [confirmingRemove, setConfirmingRemove] = useState(false);

    const [removing, setRemoving] = useState(false);
    const [errorRemoving, setErrorRemoving] = useState<string | null>(null);

    const onRemoveVideo = useCallback(async () => {
        if (!journeyPersonalization?.unique_identifier) {
            return;
        }
        if (removing) {
            return;
        }
        setRemoving(true);
        setErrorRemoving(null);
        try {
            await removeVideoFromJourneyPersonalization(journeyPersonalization.unique_identifier);
            setJourneyPersonalizationForNode(null);
            onGoBack();
        } catch (error: any) {
            console.warn('error', error)
            const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
            setErrorRemoving(errorMessage);
            setRemoving(false);
        }
    }, [journeyPersonalization?.unique_identifier, onGoBack, removing, setJourneyPersonalizationForNode]);

    return <Container>
        <ActionsContainer className='p-8'>
            {!confirmingRemove ? <>
                <button
                    className='btn btn-outline btn-outline-primary w-100' style={{ border: '1px solid #009ef7' }}
                    type='button'
                    onClick={() => {
                        onRecordVideo();
                    }}>
                    Record a New Video
                </button>
                <button
                    className='btn btn-outline btn-outline-primary w-100 mt-4' style={{ border: '1px solid #009ef7' }}
                    type='button'
                    onClick={() => {
                        onUploadVideo();
                    }}>
                    Upload a New Video
                </button>
                {journeyPersonalization?.journey_upload?.uploaded_file_url && <button
                    className='btn btn-outline btn-outline-danger w-100 mt-4' style={{ border: '1px solid #f44336' }}
                    type='button'
                    onClick={() => {
                        setConfirmingRemove(true);
                    }}>
                    Remove Video
                </button>}
            </> : <>
                <div className='text-center' style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                    Are you sure you want to remove this video?
                </div>
                <button
                    className='btn btn-outline btn-outline-danger w-100 mt-4' style={{ border: '1px solid #f44336' }}
                    type='button'
                    onClick={() => {
                        onRemoveVideo();
                    }}>
                    Yes, Remove Video
                </button>
                <button
                    className='btn btn-outline btn-outline-primary w-100 mt-4' style={{ border: '1px solid #009ef7' }}
                    type='button'
                    onClick={() => {
                        setConfirmingRemove(false);
                    }}>
                    Cancel
                </button>
                {errorRemoving && <div className='text-center text-danger mt-4'>
                    {errorRemoving}
                </div>}
            </>}
        </ActionsContainer>
        <GoBackContainer onClick={onGoBack}>
            <ChevronLeft size={24} />
        </GoBackContainer>
    </Container>
}

export default NodeManageVideoItem;