import React, { useContext, useState } from 'react'

import { Field, Form, Formik } from 'formik'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import Select, { createFilter } from 'react-select'

import { useNavigate } from 'react-router-dom'

import { localStorageKeys } from './PersistForm'
import familyRelationshipOptions from '../core/familyRelationshipOptions'
import { updateObituary } from '../core/_requests'
import { Obituary, TemporaryMessage } from '../core/_models'
import FuneralHomeDirectorSelector from './FuneralHomeDirectorSelector'
import { useAuth } from '../../auth/core/Auth'
import clsx from 'clsx'
import LanguagePicker from '../../../obituaries/LanguagePicker'
import { alertContext } from '../../../../contexts/alertContext'
import { createObituaryFromAdmin } from '../../../funeral_homes/dashboard/core/_requests'
import FuneralHomeSwitcher from '../../../../_metronic/layout/components/FuneralHomeSwitcher'

// TODO: Get away from Container, Row, Col

const Container = styled.div`
  .obituary-type-with-border {
    border-left: 1px dashed #ccc;
    @media (max-width: 768px) {
      border-left: 0;
    }
  }
`
const Row = styled.div``
const Col = styled.div``

const CreateObituaryButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  /* on mobile devices make this display block */
  @media (max-width: 768px) {
    flex-direction: column;

    button {
      margin-bottom: 2rem;
    }

    .advanced-options-link {
      border: 0;
      margin: 0;
      padding: 0;
    }
  }
`

const ShowAdvancedOptions = styled.div`
  margin-left: 2rem;
  padding-left: 2rem;
  border-left: 1px solid #ccc;
  cursor: pointer;
`

export interface IFamilyMember {
  name: string
  email: string
  phone: string
  relationship: string
  relationship_other_details: string // Specific details
  is_authorizer: boolean
}

export interface IObituaryCreationForm {
  for_person_full_name: string
  for_person_first_name: string
  for_person_middle_name: string
  for_person_last_name: string
  for_person_nickname: string
  nickname_is_preferred: boolean
  for_person_born_in_city_and_state: string
  for_person_recent_city_and_state: string
  birth_date_string: string
  death_date_string: string
  death_not_yet_passed: boolean
  birth_date_display_year_only: boolean
  gender_value: 'male' | 'female' | 'nonbinary'
  funeral_director_id: string
  funeral_director_name: string
  funeral_director_phone: string
  funeral_director_email: string
  family_members: IFamilyMember[]
  // New stuff
  for_person_phone_number?: string
  for_person_email?: string
}

// Gets overwritten by the user's information

const defaultInitialFormValues = {
  for_person_full_name: '',
  for_person_first_name: '',
  for_person_middle_name: '',
  for_person_last_name: '',
  for_person_nickname: '',
  nickname_is_preferred: false,
  for_person_born_in_city_and_state: '',
  for_person_recent_city_and_state: '',
  birth_date_string: '',
  death_date_string: '',
  death_not_yet_passed: false,
  birth_date_display_year_only: false,
  gender_value: 'male', // or 'female' or 'nonbinary'
  // Funeral director contact information.
  // This gets pre-filled in with the user's information.
  funeral_director_id: '',
  funeral_director_name: '',
  funeral_director_phone: '',
  funeral_director_email: '',
  family_members: [], // We no longer default to family members but instead we add 1 if selecting at-need / other
  // New stuff
  for_person_phone_number: '',
  for_person_email: '',
} as IObituaryCreationForm

interface ObituaryBiographicalFormProps {
  obituary: Obituary | null
  setObituary: (obituary: Obituary) => void
}

export interface IObituaryCreationForm {
  for_person_first_name: string
  for_person_middle_name: string
  for_person_last_name: string
  for_person_nickname: string
  nickname_is_preferred: boolean
  for_person_born_in_city_and_state: string
  for_person_recent_city_and_state: string
  birth_date_string: string
  death_date_string: string
  death_not_yet_passed: boolean
  birth_date_display_year_only: boolean
  gender_value: 'male' | 'female' | 'nonbinary'
  funeral_director_name: string
  funeral_director_phone: string
  funeral_director_email: string
  family_members: IFamilyMember[]
}

export const getDateAsAstring = (date: Date) => {
  // Returns a string in the format of 'YYYY-MM-DD'
  return DateTime.fromJSDate(date).toISODate()
}

function ObituaryBiographicalForm({ obituary, setObituary }: ObituaryBiographicalFormProps) {
  const navigate = useNavigate()
  const { currentPhoenixUser } = useAuth()

  const initialFormValues = {
    ...defaultInitialFormValues,
    funeral_director_id: currentPhoenixUser?.id || '',
    // Following only gets shown if "funeral_director_id" == "new"
    funeral_director_name: '',
    funeral_director_phone: '',
    funeral_director_email: '',
  }

  const inputNicknameRef = React.useRef<HTMLInputElement>(null)

  const [addingNickname, setAddingNickname] = React.useState(obituary ? true : false) // Always show the nickname input if we are editing an existing obituary

  const [submissionError, setSubmissionError] = useState<string | null>(null)

  const [selectedObituaryType, setSelectedObituaryType] = useState<
    'other' | 'pre_need' | 'at_need' | null
  >(null)

  const [selectedObituaryPreneedType, setSelectedObituaryPreneedType] = useState<
    'self' | 'someone_else' | null
  >(null)

  const areCreatingPreNeedForSelf =
    selectedObituaryType === 'pre_need' && selectedObituaryPreneedType === 'self'

  const [advancedOptionsAreVisible, setAdvancedOptionsAreVisible] = useState(true)

  // TODO: Implement advanced options (language selections)

  const [advancedOptionsAreAvailable] = useState(false) // maybe client dependent? last minute?

  const isViewingAdvancedOptions = advancedOptionsAreVisible && advancedOptionsAreAvailable

  const { setNoticeData } = useContext(alertContext)

  const onFormSubmit = async (values: any, actions: any) => {
    setSubmissionError(null)
    try {
      if (!obituary) {
        const {
          obituary: updatedObituary,
          return_successes,
          return_errors,
        } = await createObituaryFromAdmin({
          ...values,
          family_members: values.family_members.filter((x: any) => !!x.name),
          obituary_type: selectedObituaryType,
          preneed_type: selectedObituaryPreneedType,
        })
        localStorage.removeItem(localStorageKeys.OBITUARY_INPUT_FORM)
        let updatedTemporaryMessages: TemporaryMessage[] = []
        if (return_errors && Array.isArray(return_errors)) {
          updatedTemporaryMessages = updatedTemporaryMessages.concat(
            return_errors.map((e: any) => new TemporaryMessage(e, 'error'))
          )
        }
        if (return_successes && Array.isArray(return_successes)) {
          updatedTemporaryMessages = updatedTemporaryMessages.concat(
            return_successes.map((s: any) => new TemporaryMessage(s, 'success'))
          )
        }
        for (const message of updatedTemporaryMessages) {
          setNoticeData({ title: message.message, type: message.type })
        }
        setObituary(updatedObituary)
        navigate(`/old-obituaries/${updatedObituary.unique_identifier}`)
      } else {
        // Update
        const updatedObituary = await updateObituary(obituary.unique_identifier, values)
        setObituary(updatedObituary)
        // TODO: Toast
      }
    } catch (e: any) {
      console.warn(e)
      actions.setSubmitting(false)
      let errorMessage = 'There was an error submitting your obituary.'
      if (!obituary) {
        errorMessage =
          e.response?.data?.error ||
          'There was an error creating your obituary. Please try again or contact us if the problem persists.'
      } else {
        errorMessage =
          e.response?.data?.error ||
          'There was an error updating your obituary. Please try again or contact us if the problem persists.'
      }
      setSubmissionError(errorMessage)
    }
  }

  return (
    <Formik initialValues={initialFormValues} onSubmit={onFormSubmit}>
      {({ values: formValues, isSubmitting, setFieldValue, getFieldProps, touched, errors }) => (
        <Form>
          <Container className='p-5'>
            <div>
              <div>
                {/* This is helpful when switching between funeral homes */}
                {currentPhoenixUser?.is_admin && <Row className='mb-5 pe-15 pe-md-0'>
                  <h1>{`Create an obituary for ${currentPhoenixUser?.current_client?.name}`}</h1>
                </Row>}
                <Row className='mb-5 pe-15 pe-md-0'>
                  <h1>What type of obituary would you like to create?</h1>
                </Row>
                <Row>
                  <div className='row select-obituary-type-wrapper mb-8'>
                    <div className='col-lg-12'>
                      {[
                        {
                          value: 'pre_need',
                          label: 'Pre-Need',
                          subTitle: '(planned ahead of time)',
                        },
                        {
                          value: 'at_need',
                          label: 'At-Need',
                          subTitle: '(someone has passed away)',
                        },
                        {
                          value: 'other',
                          label: 'Other',
                          subTitle: '(everything else)',
                        },
                      ].map((obitType, index) => {
                        return (
                          <div
                            className={clsx(
                              'form-check form-check-inline position-relative',
                              index > 0 && 'ps-lg-12 mt-4 mt-lg-0 obituary-type-with-border'
                            )}
                            key={obitType.value}
                          >
                            <div className='d-flex align-items-center justify-content-center'>
                              <input
                                className='form-check-input mt-1'
                                type='radio'
                                name='obituary_type'
                                id={`obituary_type_${obitType.value}`}
                                value={obitType.value}
                                checked={selectedObituaryType === obitType.value}
                                onChange={(e) => {
                                  setSelectedObituaryType(e.target.value as any)
                                  if (
                                    obitType.value === 'at_need' &&
                                    formValues.family_members.length === 0
                                  ) {
                                    // If no family members have been added yet, add one:
                                    // We only do this for at-need as pre-need is typically done
                                    // for one's self and Other is unclear at this point
                                    setFieldValue('family_members', [
                                      {
                                        name: '',
                                        phone: '',
                                        email: '',
                                        relationship: '',
                                        relationship_other_details: '',
                                        is_authorizer: false,
                                      },
                                    ])
                                  }
                                }}
                                required
                              />
                              <label
                                className='form-check-label ms-4'
                                htmlFor={`obituary_type_${obitType.value}`}
                              >
                                <div className='fs-2'>{obitType.label}</div>
                                <div className='fs-4 text-muted'>{obitType.subTitle}</div>
                              </label>
                            </div>
                          </div>
                        )
                      })}
                      {false && 'toShowHelpText' && (
                        <Row className='mt-5'>
                          <div className='text-muted fs-4'>
                            {selectedObituaryType === 'pre_need' && (
                              <>
                                Pre-Need is for writing obituaries and planning services before
                                someone passes away.
                              </>
                            )}
                          </div>
                        </Row>
                      )}
                    </div>
                  </div>
                </Row>
              </div>
              {selectedObituaryType === 'pre_need' && (
                <div className='mb-10'>
                  <Row className='mb-5 pe-15 pe-md-0'>
                    <h1>What type of pre-need obituary is this?</h1>
                  </Row>
                  <Row>
                    <div className='row select-obituary-preneed-type-wrapper mb-2'>
                      <div className='col-lg-12'>
                        {[
                          {
                            value: 'self',
                            label: 'Planning an obituary in advance for themselves',
                          },
                          {
                            value: 'someone_else',
                            label: 'Planning an obituary in advance for someone else',
                          },
                        ].map((preneedType, index) => {
                          return (
                            <div
                              className={clsx(
                                'form-check form-check-inline',
                                index > 0 && 'ps-lg-12 mt-4 mt-lg-0'
                              )}
                              key={preneedType.value}
                            >
                              <div className='d-flex align-items-center justify-content-center'>
                                <div>
                                  <input
                                    className='form-check-input mt-1'
                                    type='radio'
                                    name='obituary_preneed_type'
                                    id={`obituary_preneed_type_${preneedType.value}`}
                                    value={preneedType.value}
                                    checked={selectedObituaryPreneedType === preneedType.value}
                                    onChange={(e) => {
                                      const updatedValue = e.target.value as any
                                      setSelectedObituaryPreneedType(updatedValue)
                                      if (
                                        updatedValue === 'someone_else' &&
                                        formValues.family_members.length === 0
                                      ) {
                                        // If no family members have been added yet, add one:
                                        setFieldValue('family_members', [
                                          {
                                            name: '',
                                            phone: '',
                                            email: '',
                                            relationship: '',
                                            relationship_other_details: '',
                                            is_authorizer: false,
                                          },
                                        ])
                                      }
                                    }}
                                    required
                                  />
                                </div>
                                <label
                                  className='form-check-label fs-2 ps-2'
                                  htmlFor={`obituary_preneed_type_${preneedType.value}`}
                                >
                                  {preneedType.label}
                                </label>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </Row>
                </div>
              )}
              <div>
                <Row className='mb-5'>
                  <h1>
                    {!areCreatingPreNeedForSelf
                      ? "Who is this obituary for? Enter the deceased's full name:"
                      : 'Who is this obituary for?'}
                  </h1>
                </Row>
                <Row className='mb-10'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='form-label'>Full Name *</label>
                          <Field name='for_person_full_name'>
                            {({ field }: { field: any }) => (
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Enter full name...'
                                required
                                {...field}
                                autoComplete='off'
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      <div>
                        {true && (
                          <Row
                            style={
                              addingNickname
                                ? {
                                  display: 'none',
                                }
                                : {
                                  display: 'block',
                                }
                            }
                          >
                            {/* Small button as link */}
                            <Col className='mt-2'>
                              <button
                                type='button'
                                className='btn btn-secondary btn-sm'
                                onClick={() => {
                                  setAddingNickname(true)
                                  setTimeout(() => {
                                    if (inputNicknameRef.current) {
                                      inputNicknameRef.current.focus()
                                    }
                                  }, 100)
                                }}
                                tabIndex={-1}
                              >
                                Add nickname
                              </button>
                            </Col>
                          </Row>
                        )}
                        <div
                          className='row'
                          style={
                            !addingNickname
                              ? {
                                display: 'none',
                              }
                              : {
                                display: 'block',
                              }
                          }
                        >
                          <div className='col-12 mt-4'>
                            <label className='form-label'>Nickname</label>
                            <Col>
                              <div>
                                <Field name='for_person_nickname'>
                                  {({ field }: { field: any }) => (
                                    <input
                                      ref={inputNicknameRef}
                                      type='text'
                                      className='form-control'
                                      placeholder='Nickname'
                                      {...field}
                                      autoComplete='off'
                                    />
                                  )}
                                </Field>
                                {/* Checkbox with label */}
                                <div className='mt-5'>
                                  <Field name='nickname_is_preferred' type='checkbox'>
                                    {({ field }: { field: any }) => (
                                      <input
                                        id='nickname_is_preferred'
                                        type='checkbox'
                                        className='form-check-input'
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                  <label
                                    className='form-check-label ms-2'
                                    htmlFor='nickname_is_preferred'
                                  >
                                    Treat this as their first name
                                  </label>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </div>
                    </div>
                    {areCreatingPreNeedForSelf && (
                      <>
                        <div className='col-lg-4 mt-4 mt-lg-0'>
                          <label className='form-label'>Phone *</label>
                          <Field name='for_person_phone_number'>
                            {({ field }: { field: any }) => (
                              <input
                                type='text'
                                className='form-control'
                                placeholder={"Enter the planning party's phone number"}
                                required
                                {...field}
                                autoComplete='off'
                              />
                            )}
                          </Field>
                        </div>
                        <div className='col-lg-4 mt-4 mt-lg-0'>
                          <label className='form-label'>Email</label>
                          <Field name='for_person_email'>
                            {({ field }: { field: any }) => (
                              <input
                                type='text'
                                className='form-control'
                                placeholder={"Enter the planning party's email"}
                                {...field}
                                autoComplete='off'
                              />
                            )}
                          </Field>
                        </div>
                      </>
                    )}
                  </div>
                  {isViewingAdvancedOptions && (
                    <>
                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='mt-5'>
                            <label className='form-label'>Language</label>
                            <div>
                              <LanguagePicker />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Row>

                {!obituary && (
                  <>
                    <Row className='mt-8 mb-5'>
                      <h1 className='mb-5'>{`Family or friends helping with obituary`}</h1>
                      {formValues.family_members.map((familyMember, index) => {
                        return (
                          <div key={`family-member-${index}`} className='position-relative'>
                            {index > 0 && <div className='separator separator-dashed my-8' />}
                            <div className='row mb-5'>
                              <div className='col-lg-4'>
                                <label className='form-label'>Name (First and Last Name) *</label>
                                <Field name={`family_members[${index}].name`}>
                                  {({ field }: { field: any }) => (
                                    <input
                                      type='text'
                                      className='form-control'
                                      placeholder='Enter family member name'
                                      required={false}
                                      {...field}
                                      autoComplete='off'
                                    />
                                  )}
                                </Field>
                              </div>
                              <div className='col-lg-4 mt-4 mt-lg-0'>
                                <label className='form-label'>Phone *</label>
                                <Field name={`family_members[${index}].phone`}>
                                  {({ field }: { field: any }) => (
                                    <input
                                      type='text'
                                      className='form-control'
                                      placeholder='Enter family member phone number'
                                      {...field}
                                      required={!!formValues.family_members[index].name}
                                      autoComplete='off'
                                    />
                                  )}
                                </Field>
                              </div>
                              <div className='col-lg-4 mt-4 mt-lg-0'>
                                <label className='form-label'>Email</label>
                                <Field name={`family_members[${index}].email`}>
                                  {({ field }: { field: any }) => (
                                    <input
                                      type='email'
                                      className='form-control'
                                      placeholder='Enter family member email'
                                      {...field}
                                      autoComplete='off'
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-lg-4'>
                                <label className='form-label'>Relationship *</label>
                                <Row>
                                  <Field name={`family_members[${index}].relationship`}>
                                    {({ field }: { field: any }) => {
                                      return (
                                        <Select
                                          name={`family_members[${index}].relationship`}
                                          options={familyRelationshipOptions}
                                          onChange={(value: any) => {
                                            if (!value) {
                                              setFieldValue(
                                                `family_members[${index}].relationship`,
                                                null
                                              )
                                              return
                                            }
                                            setFieldValue(
                                              `family_members[${index}].relationship`,
                                              value.value
                                            )
                                          }}
                                          required={!!formValues.family_members[index].name}
                                          value={
                                            field.value
                                              ? familyRelationshipOptions.find(
                                                (option) => option.value === field.value
                                              )
                                              : null
                                          }
                                          isClearable
                                          formatOptionLabel={(option: any) => {
                                            return option.label
                                          }}
                                          filterOption={createFilter({
                                            ignoreAccents: true,
                                            ignoreCase: true,
                                            matchFrom: 'any',
                                            stringify: (option: any) => {
                                              return option.data?.searchableLabel || option.label
                                            },
                                          })}
                                        />
                                      )
                                    }}
                                  </Field>
                                </Row>
                                {familyMember.relationship === 'other' && (
                                  <Row>
                                    <Col>
                                      <div className='mt-5'>
                                        <label className='form-label'>
                                          Describe the relationship
                                        </label>
                                      </div>
                                      <div className='mt-2'>
                                        <Field
                                          name={`family_members[${index}].relationship_other_details`}
                                        >
                                          {({ field }: { field: any }) => (
                                            <input
                                              type='text'
                                              className='form-control'
                                              placeholder='Ex. Neighbor'
                                              required={familyMember.relationship === 'other'}
                                              {...field}
                                            />
                                          )}
                                        </Field>
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                                {isViewingAdvancedOptions && (
                                  <>
                                    <Row>
                                      <Col>
                                        <div className='mt-5'>
                                          <label className='form-label'>Language</label>
                                          <div>
                                            <LanguagePicker />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                                <Row className='position-absolute bottom-0' style={{ right: 0 }}>
                                  <Col>
                                    <div className='mt-5'>
                                      <button
                                        type='button'
                                        className='btn btn-light btn-sm'
                                        onClick={() => {
                                          const familyMembers = formValues.family_members
                                          familyMembers.splice(index, 1)
                                          setFieldValue('family_members', familyMembers)
                                        }}
                                      >
                                        <i className='bi bi-trash'></i>
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className='col-lg-4 mt-4 mt-lg-0'>
                                <label className='form-label'>Authorizer / next-of-kin?</label>
                                <Field
                                  name={`family_members[${index}].is_authorizer`}
                                  type='checkbox'
                                >
                                  {({ field }: { field: any }) => (
                                    <div className='pt-4'>
                                      <label className='form-check-label'>
                                        <input
                                          type='checkbox'
                                          className='form-check-input me-2'
                                          {...field}
                                        />
                                        <span className='d-none d-md-inline'>
                                          Yes, this person is the authorizer or next-of-kin
                                        </span>
                                        <span className='d-md-none'>
                                          Yes, authorizer or next-of-kin
                                        </span>
                                      </label>
                                    </div>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                      <div className='row mt-4'>
                        <div className='col-lg-4'>
                          <button
                            type='button'
                            className='btn btn-secondary btn-sm'
                            onClick={() => {
                              setFieldValue('family_members', [
                                ...formValues.family_members,
                                {
                                  name: '',
                                  phone: '',
                                  email: '',
                                  relationship: '',
                                  relationship_other_details: '',
                                  is_authorizer: false,
                                },
                              ])
                            }}
                          >
                            {formValues.family_members.length > 0
                              ? 'Add another family member'
                              : 'Add family member'}
                          </button>
                        </div>
                      </div>
                    </Row>
                    {isViewingAdvancedOptions && (
                      <>
                        <Row className='mt-8 mb-5'>
                          <h1>{`Change obituary language`}</h1>
                          <div className='row mt-8'>
                            <div className='col-lg-4'>
                              <div>
                                <label className='form-label'>
                                  Choose the language for this obituary *
                                </label>
                              </div>
                              <div>
                                <LanguagePicker />
                              </div>
                            </div>
                          </div>
                          <div className='row mt-4 mb-5'>
                            <div className='col-lg-12'>
                              <div className='text-gray-600'>
                                <p>
                                  English is the default language for all obituaries. If you select
                                  another language, the obituary will be translated into that
                                  language.
                                </p>
                                <p>
                                  Family members will be texted an invite to this obituary in the
                                  language assigned to each of them above.
                                </p>
                                <p>
                                  These family members will be able to view the obituary in their
                                  preferred language.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </>
                    )}
                  </>
                )}
                {(currentPhoenixUser?.onboarding_flags?.selected_user_type !== 'other' ||
                  'alwaysShowingThisForNow') && (
                    <>
                      <Row className='mt-8 mb-5'>
                        <h1 className='mb-5'>{`Which funeral director or arranger is handling this obituary?`}</h1>
                        <div className='row'>
                          <div className='col-lg-4'>
                            <FuneralHomeDirectorSelector
                              onChange={(funeralDirectorUserUniqueId) => {
                                setFieldValue('funeral_director_id', funeralDirectorUserUniqueId)
                              }}
                            />
                          </div>
                        </div>
                        {formValues.funeral_director_id !== 'new' ? (
                          <></>
                        ) : (
                          <div className='row mt-4'>
                            <div className='col-lg-4'>
                              <label className='form-label'>Name *</label>
                              <Field name='funeral_director_name'>
                                {({ field }: { field: any }) => (
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Enter your name'
                                    required
                                    {...field}
                                  />
                                )}
                              </Field>
                            </div>
                            <div className='col-lg-4 mt-4 mt-lg-0'>
                              <label className='form-label'>Phone *</label>
                              <Field name='funeral_director_phone'>
                                {({ field }: { field: any }) => (
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Enter your phone number'
                                    required
                                    {...field}
                                  />
                                )}
                              </Field>
                            </div>
                            <div className='col-lg-4 mt-4 mt-lg-0'>
                              <label className='form-label'>Email</label>
                              <Field name='funeral_director_email'>
                                {({ field }: { field: any }) => (
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Enter your email'
                                    {...field}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        )}
                        {isViewingAdvancedOptions && (
                          <>
                            <div className='row'>
                              <div className='col-lg-4'>
                                <div className='mt-5'>
                                  <label className='form-label'>Language</label>
                                  <div>
                                    <LanguagePicker />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </Row>
                    </>
                  )}
              </div>
            </div>
            <Row className='mt-8 mb-8'>
              <div className='separator separator-dashed my-8' />
            </Row>
            <Row className={clsx('row', obituary ? 'mt-8' : 'mt-4')}>
              <Col className='col-12'>
                {obituary ? (
                  <button type='submit' className='btn btn-primary btn-lg' disabled={isSubmitting}>
                    {!isSubmitting ? `Save Obituary` : `Saving...`}
                  </button>
                ) : (
                  <CreateObituaryButtonContainer>
                    <button
                      type='submit'
                      className='btn btn-primary btn-lg'
                      disabled={isSubmitting}
                    >
                      {!isSubmitting ? `Create Obituary` : `Creating...`}
                    </button>
                    {advancedOptionsAreAvailable && (
                      <ShowAdvancedOptions
                        onClick={() => {
                          setAdvancedOptionsAreVisible(!isViewingAdvancedOptions)
                        }}
                        className='advanced-options-link'
                      >
                        {!isViewingAdvancedOptions
                          ? 'Show Advanced Options'
                          : 'Hide Advanced Options'}
                      </ShowAdvancedOptions>
                    )}
                  </CreateObituaryButtonContainer>
                )}
                <div className='alert alert-danger mt-4' role='alert' hidden={!submissionError}>
                  {submissionError}
                </div>
              </Col>
            </Row>
          </Container>
          {advancedOptionsAreAvailable && (
            <div className='position-absolute top-0 end-0 p-3'>
              <button
                onClick={() => {
                  setAdvancedOptionsAreVisible(!isViewingAdvancedOptions)
                }}
                className='btn btn-light btn-sm'
                type='button'
              >
                {/* show gear on mobile and text otherwise */}
                <i className='bi bi-gear d-lg-none'></i>
                <span className='d-none d-lg-inline'>
                  {!isViewingAdvancedOptions ? 'Advanced' : 'Hide Advanced Options'}
                </span>
              </button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default ObituaryBiographicalForm
