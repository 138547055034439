import { Suspense, useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { alertContext } from '../contexts/alertContext'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import AlertDropdown from './alerts/alertDropDown'
import NoticeAlert from './alerts/notice'
import { AuthInit } from './modules/auth/core/Auth'

export const GOOGLE_OAUTH_CLIENT_ID =
  '791299892825-ugbruhau0jesv0um08chjtc3rjaitfgv.apps.googleusercontent.com'

const App = () => {
  var _ = require('lodash')

  const {
    errorData,
    errorOpen,
    setErrorOpen,
    noticeData,
    noticeOpen,
    setNoticeOpen,
    successData,
    successOpen,
    setSuccessOpen,
  } = useContext(alertContext)

  // Initialize state variable for the list of alerts
  const [alertsList, setAlertsList] = useState<
    Array<{ type: string; data: { title: string; list?: Array<string>; link?: string }; id: string }>
  >([])

  // Use effect hook to update alertsList when a new alert is added
  useEffect(() => {
    // If there is an error alert open with data, add it to the alertsList
    if (errorOpen && errorData) {
      setErrorOpen(false)
      setAlertsList((old) => {
        let newAlertsList = [
          ...old,
          { type: 'error', data: _.cloneDeep(errorData), id: _.uniqueId() },
        ]
        return newAlertsList
      })
    }
    // If there is a notice alert open with data, add it to the alertsList
    else if (noticeOpen && noticeData) {
      setNoticeOpen(false)
      setAlertsList((old) => {
        let newAlertsList = [
          ...old,
          { type: 'notice', data: _.cloneDeep(noticeData), id: _.uniqueId() },
        ]
        return newAlertsList
      })
    }
    // If there is a success alert open with data, add it to the alertsList
    else if (successOpen && successData) {
      setSuccessOpen(false)
      setAlertsList((old) => {
        let newAlertsList = [
          ...old,
          { type: 'success', data: _.cloneDeep(successData), id: _.uniqueId() },
        ]
        return newAlertsList
      })
    }
  }, [
    _,
    errorData,
    errorOpen,
    noticeData,
    noticeOpen,
    setErrorOpen,
    setNoticeOpen,
    setSuccessOpen,
    successData,
    successOpen,
  ])

  const removeAlert = (id: string) => {
    setAlertsList((prevAlertsList) => prevAlertsList.filter((alert) => alert.id !== id))
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
      <AlertDropdown />
      {/*<div class='d-flex flex-column-reverse position-fixed' style='z-index: 40; bottom: 1.25rem; left: 1.25rem;'>*/}
      <div
        className='d-flex flex-column-reverse position-fixed'
        style={{ zIndex: 40, bottom: '1.25rem', left: '1.25rem' }}
      >
        {alertsList.map((alert) => (
          <div key={alert.id}>
            <NoticeAlert
              key={alert.id}
              title={alert.data.title}
              link={alert.data.link || ''}
              id={alert.id}
              removeAlert={removeAlert}
            />
          </div>
        ))}
      </div>
    </Suspense>
  )
}

export { App }
