import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Routes, Route, Outlet, Navigate, Link, useLocation } from 'react-router-dom'
import ReactSlider from "react-slider";

import { useAuth } from '../../../auth/core/Auth'
import { ClientRowItem, ClientRowItemRenderer } from '../../../../admin/clients/ClientDetailRoutes';
import { ApiError } from '../../../auth';
import { getFullClientPaymentSettings, updateClientDetails, updateClientPaymentSettings } from '../../../../funeral_homes/dashboard/core/_requests';
import PaymentsStripeConnectActions from './PaymentsStripeConnectActions';
import { FuneralHomePaymentSettingForAdmin } from '../../core/_models';

const CreateAvatarLayoutContainer = styled.div`
  max-width: 820px;
  margin: 0 auto;
`;

const SliderContainer = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;

  .horizontal-slider {
    width: 100%;
    height: 26px;
  }

  .horizontal-slider .scrub-track {
    background-color: #e2e2e2;
    height: 100%;
    border-radius: 10px;
    position: relative;
  }
  
  .horizontal-slider .scrub-track-0 {
    background-color: #009ef7;
  }

  .horizontal-slider .scrub-thumb {
    width: 26px;
    height: 26px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: grab;
    outline: #ccc auto 1px;
  }

  .horizontal-slider .scrub-thumb:active {
    cursor: grabbing;
    outline: -webkit-grabbing;
  }
`;


interface CloneVoiceContextParams {
}

const PaymentsProviderContext = React.createContext<CloneVoiceContextParams>({
})

export const usePersonalizeContext = () => React.useContext(PaymentsProviderContext)

type RouteItem = { path: string; title: string };

function PaymentsProviderLayout({ basePath }: { basePath: string }) {

  const location = useLocation()

  const routes = [
    {
      path: `${basePath}/received-payments`,
      title: 'Received Payments',
    },
    {
      path: `${basePath}/coupon-codes`,
      title: 'Coupon Codes',
    },
    {
      path: `${basePath}/settings`,
      title: 'Settings',
    },
  ].filter(Boolean) as RouteItem[];

  return <CreateAvatarLayoutContainer className='card card-custom card-stretch shadow mb-5 p-8'>
    <div className='mb-8'>
      <div className='d-flex overflow-auto h-55px'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          {routes.map((item, key) => {
            return <li className='nav-item' key={key}>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname.startsWith(item.path) && 'active')
                }
                to={item.path}
              >
                {item.title}
              </Link>
            </li>
          })}
        </ul>
      </div>
    </div>
    <Outlet />
  </CreateAvatarLayoutContainer>
}


function PaymentSettings() {
  const { currentPhoenixUser, setCurrentPhoenixUser } = useAuth()
  const client = currentPhoenixUser?.current_client

  const [loadingFullPaymentSettings, setLoadingFullPaymentSettings] = useState(true)
  const [loadingFullPaymentSettingsError, setLoadingFullPaymentSettingsError] = useState<string | null>(null)
  const [currentFullPaymentSettings, setCurrentFullPaymentSettings] = useState<FuneralHomePaymentSettingForAdmin | null>(null)

  const [isEditing, setIsEditing] = useState(!client?.payment_settings ? true : false)

  const [saving, setSaving] = useState(false)
  const [saveError, setSaveError] = useState<string | null>(null)

  const [shouldChargeConsumersPerObituary, setShouldChargeConsumersPerObituary] = useState(client?.payment_settings?.public_data.charge_consumers_per_obituary || false)
  const [amountToChargeConsumers, setAmountToChargeConsumers] = useState(client?.payment_settings?.public_data.amount_to_charge_consumers_in_cents || 10 * 100)

  const onFormSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!client) {
      return
    }
    setSaveError(null)
    setSaving(true)
    // Doing this manually to avoid formik for now.
    const formValues = new FormData(event.currentTarget)
    const serializedFormValues: any = {}
    formValues.forEach((value, key) => {
      serializedFormValues[key] = value
    })
    const finalValues = {
      charge_consumers_per_obituary: shouldChargeConsumersPerObituary,
      amount_to_charge_consumers_in_cents: amountToChargeConsumers,
    }
    try {
      const updatedUser = await updateClientPaymentSettings(client.unique_identifier, finalValues)
      setCurrentPhoenixUser(updatedUser)
      setIsEditing(false)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setSaveError(errorMessage)
    } finally {
      setSaving(false)
    }
  }, [amountToChargeConsumers, client, setCurrentPhoenixUser, shouldChargeConsumersPerObituary])

  const onRefreshFullPaymentSettings = useCallback(async () => {
    if (!client) {
      return
    }
    try {
      const updatedFullPaymentSettings = await getFullClientPaymentSettings(client.unique_identifier)
      setCurrentFullPaymentSettings(updatedFullPaymentSettings)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setLoadingFullPaymentSettingsError(errorMessage)
    } finally {
      setLoadingFullPaymentSettings(false)
    }
  }, [client])

  useEffect(() => {
    onRefreshFullPaymentSettings()
  }, [onRefreshFullPaymentSettings])

  if (loadingFullPaymentSettings) {
    return (
      <div>
        <div className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  }

  if (loadingFullPaymentSettingsError || !currentFullPaymentSettings) {
    return <div className='alert alert-danger' role='alert'>
      {loadingFullPaymentSettingsError || 'Something went wrong (no payment settings)'}
    </div>
  }

  const widgetOptionItems: ClientRowItem[] = [
    {
      label: 'Charge Consumers Per Obituary?',
      value: shouldChargeConsumersPerObituary ? 'Yes' : 'No',
      editingComponent: <div>
        <div className='form-check form-switch form-check-custom form-check-solid'>
          <input className='form-check-input' type='checkbox' id='charge_consumers_per_obituary' name='charge_consumers_per_obituary' checked={shouldChargeConsumersPerObituary} onChange={(event) => {
            setShouldChargeConsumersPerObituary(event.target.checked)
          }} />
          <label className='form-check-label' htmlFor='charge_consumers_per_obituary'>
            <span className='fw-bold text-muted'>Charge Consumers Per Obituary?</span>
          </label>
        </div>
      </div>
    },
    {
      label: 'Amount to Charge Consumers',
      value: !shouldChargeConsumersPerObituary ? 'Not applicable' : `$${amountToChargeConsumers / 100}`,
      editingComponent: !shouldChargeConsumersPerObituary ? <div>
        (Not applicable)
      </div> : <div className='mb-8'>
        <div>
          <SliderContainer>
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="scrub-thumb"
              trackClassName="scrub-track"
              ariaLabel={"Slider"}
              value={amountToChargeConsumers}
              onChange={(updatedValue) => {
                // Make sure the updatedValue is an integer and an even number
                const updatedValueAsInt = Math.round(updatedValue / 100) * 100
                setAmountToChargeConsumers(updatedValueAsInt)
              }}
              min={1 * 100}
              max={200 * 100}
              step={100}
            />
          </SliderContainer>
        </div>
        <div className='d-flex justify-content-between mt-3 position-relative'>
          <div>
            $1
          </div>
          <div>
            $200
          </div>
          <div style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
            <div className='d-flex align-items-center justify-content-center flex-column'>
              <div className='fw-bolder fs-2 text-dark'>
                ${amountToChargeConsumers / 100}
              </div>
              <div className='text-muted'>
                Amount to charge consumers
              </div>
            </div>
          </div>
        </div>
      </div>
    },
    {
      label: 'Linked Bank Account',
      value: currentFullPaymentSettings.private_data.stripe_connect_account?.payouts_enabled ? 'Yes, payouts are enabled' : 'No',
      editingComponent: !currentFullPaymentSettings.unique_identifier ? <div>
        (Must save settings first)
      </div> : <div className='mb-8'>
        <PaymentsStripeConnectActions paymentSettings={currentFullPaymentSettings} />
      </div>
    },
  ]

  return <form onSubmit={onFormSubmit}>
    {widgetOptionItems.map((item, index) => {
      return <ClientRowItemRenderer key={`widget-option-${index}`} item={item} isEditing={isEditing} />
    })}
    <div className='separator separator-dashed my-7'></div>
    <div className='d-flex justify-content-start'>
      {!isEditing ? <>
        <button type='button' className='btn btn-secondary' onClick={() => {
          setIsEditing(true)
          setSaveError(null)
        }}>
          Edit Settings
        </button>
      </> : <>
        {client?.payment_settings && <button type='button' className='btn btn-secondary' onClick={() => {
          setIsEditing(false)
          setSaveError(null)
        }} disabled={saving}>
          Cancel
        </button>}
        <button type='submit' className='btn btn-primary ms-2' disabled={saving}>
          {!saving ? 'Save' : 'Saving...'}
        </button>
      </>}
    </div>
    {/* Need a little bit of bottom padding unless there is an error */}
    {saveError ? <div className='alert alert-danger mt-5' role='alert'>
      <div className='alert-text'>{saveError}</div>
    </div> : <div className="mb-3" />}
  </form>
}

function PaymentsProvider() {

  const { currentPhoenixUser } = useAuth()

  const basePath = '/payments'

  return (
    <PaymentsProviderContext.Provider
      value={{}}
    >
      <Routes>
        <Route
          element={
            <>
              <PaymentsProviderLayout basePath={basePath} />
            </>
          }
        >
          {/* /payments/settings : */}
          <Route path='settings' element={<PaymentSettings />} />
          {/* The refresh and return routes may be implemented in the future */}
          {/* /payments/settings/stripe-connect/refresh : */}
          <Route path='settings' element={<Navigate to={`${basePath}/settings`} replace />} />
          {/* /payments/settings/stripe-connect/return : */}
          <Route path='settings' element={<Navigate to={`${basePath}/settings`} replace />} />
          <Route path='*' element={<Navigate to={!currentPhoenixUser?.current_client?.payment_settings || true ? `${basePath}/settings` : `/?todo`} replace />} />
        </Route>
      </Routes>
    </PaymentsProviderContext.Provider >
  )
}

export default PaymentsProvider
