import React, { useEffect, useState } from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'
import { Channel } from 'phoenix'

import ObituaryHeader from '../ObituaryHeader'

import { Obituary } from '../core/_models'
import ObituaryBiographicalFormCreate from './ObituaryBiographicalFormCreate'
import ObituaryQuestions from './ObituaryQuestions'
import ObituaryPhotosAndVideos from './ObituaryPhotosAndVideos'
import ObituaryFamilyTree from './ObituaryFamilyTree'
import ObituaryWriteDragAndDropWrapper from './ObituaryWriteDragAndDropWrapper'
import ObituaryFinalizeAndSend from './ObituaryFinalizeAndSend'
import ObituaryInsights from './ObituaryInsights'
import ObituaryInputForm from './ObituaryInputForm'
import { ObituaryWebsocket } from '../../../obituaries/useObituaryWebsocket'
import usePageTitle from '../../../hooks/usePageTitle'
import MemorialFolder from '../MemorialFolder'
import Scripture from '../Scripture'
import Emails from '../Emails'
import ObituaryDebug from './ObituaryDebug'
import Merchandise from '../Merchandise'
import AddFamilyMemberModal from '../add-family-member-modal/AddFamilyMemberModal'
import { getObituaryFromAdmin } from '../../../funeral_homes/dashboard/core/_requests'
import useNextObituaryWebsocket from '../../../obituaries/next/useNextObituaryWebsocket'
import { useAuth } from '../../auth/core/Auth'

interface ObituaryDetailContextParams {
  obituary: Obituary // Always defined, but we check below for is_loading to only render if it's loaded
  setObituary: (obituary: Obituary) => void
  obituaryWebsocket: ObituaryWebsocket | null
  addingFamilyMember: boolean
  setAddingFamilyMember: (addingFamilyMember: boolean) => void
  obituaryChannel: Channel | null
}

const ObituaryDetailContext = React.createContext<ObituaryDetailContextParams>({
  obituary: { is_loading: true } as Obituary,
  setObituary: () => { },
  obituaryWebsocket: null,
  addingFamilyMember: false,
  setAddingFamilyMember: () => { },
  obituaryChannel: null,
})

export const useObituaryDetail = () => React.useContext(ObituaryDetailContext)

function LoadingOverlay() {
  return (
    <div>
      <div className='spinner-border text-primary' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
  )
}

function ObituaryDetail() {
  const params = useParams()
  const navigate = useNavigate()
  const { auth } = useAuth()

  const [loadingObituary, setLoadingObituary] = useState(!!params.unique_id)
  const [obituary, setObituary] = useState<Obituary | null>(null)
  const [addingFamilyMember, setAddingFamilyMember] = useState(false)

  const useObituary = obituary || ({ is_loading: true } as Obituary)

  // const { obituaryWebsocket } = useObituaryWebsocket(useObituary)
  const obituaryWebsocket = null; // TODO

  const {
    obituaryChannel
  } = useNextObituaryWebsocket({
    apiAccessToken: auth?.api_token || '',
    obituaryUniqueId: useObituary.unique_identifier,
  })

  usePageTitle(
    !useObituary.unique_identifier || !obituary
      ? {
        title: `Create Obituary`,
        includeDefaultTitle: true,
      }
      : useObituary.is_loading
        ? {
          title: ``,
        }
        : {
          title: `${useObituary.getSimpleName()}'s Obituary`,
        }
  )

  useEffect(() => {
    if (!params.unique_id) {
      setLoadingObituary(false)
      return
    }
    async function getUpdatedObituary() {
      if (!params.unique_id) {
        return
      }
      try {
        const updatedObituary = await getObituaryFromAdmin(params.unique_id)
        setObituary(updatedObituary)
        setLoadingObituary(false)
      } catch (error) {
        console.error(error)
        navigate('/old-obituaries') // TODO: Toast error?
      }
    }
    if (params.unique_id && (!obituary || obituary.unique_identifier !== params.unique_id)) {
      setLoadingObituary(true)
      getUpdatedObituary()
    }
  }, [navigate, obituary, params.unique_id])

  return (
    <ObituaryDetailContext.Provider
      value={{
        obituary: useObituary,
        setObituary,
        obituaryWebsocket,
        addingFamilyMember,
        setAddingFamilyMember,
        obituaryChannel
      }}
    >
      <Routes>
        {loadingObituary ? (
          <Route path='/' element={<LoadingOverlay />} />
        ) : (
          <Route
            element={
              <>
                {!useObituary.is_loading && <ObituaryHeader />}
                <div className='row g-5 g-xxl-8'>
                  <div className='col-xl-12'>
                    <div
                      className={`card`}
                      style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                    >
                      {/* begin::Body */}
                      <div className='card-body pb-6 pt-0 ps-0 pe-0 pt-md-4 pb-md-10 ps-md-10 pe-md-10'>
                        <Outlet />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          >
            {!useObituary.is_loading && (
              <>
                {/* See the bottom of this for the catch-all route (Personal Information / etc.) */}
                {false && 'disabledForNow' && (
                  <Route
                    path='/media'
                    element={
                      <>
                        <ObituaryPhotosAndVideos />
                      </>
                    }
                  />
                )}
                {false && 'disabledForNow' && (
                  <Route
                    path='/questions'
                    element={
                      <>
                        <ObituaryQuestions />
                      </>
                    }
                  />
                )}
                {false && 'disabledForNow' && (
                  <Route
                    path='/family/*'
                    element={
                      <>
                        <ObituaryFamilyTree />
                      </>
                    }
                  />
                )}
                <Route
                  path='/write'
                  element={
                    <>
                      <ObituaryWriteDragAndDropWrapper />
                    </>
                  }
                />
                <Route
                  path='/finalize_and_send'
                  element={
                    <>
                      <ObituaryFinalizeAndSend />
                    </>
                  }
                />
                <Route
                  path='/insights'
                  element={
                    <>
                      <ObituaryInsights />
                    </>
                  }
                />
                <Route
                  path='/memorial-folder'
                  element={
                    <>
                      <MemorialFolder />
                    </>
                  }
                />
                <Route
                  path='/scripture'
                  element={
                    <>
                      <Scripture />
                    </>
                  }
                />
                <Route
                  path='/merchandise/*'
                  element={
                    <>
                      <Merchandise />
                    </>
                  }
                />
                <Route
                  path='/emails'
                  element={
                    <>
                      <Emails />
                    </>
                  }
                />
                <Route
                  path='/debug'
                  element={
                    <>
                      <ObituaryDebug />
                    </>
                  }
                />
              </>
            )}
            <Route
              path='*'
              element={
                <>
                  {!useObituary.unique_identifier || !obituary ? (
                    <ObituaryBiographicalFormCreate obituary={obituary} setObituary={setObituary} />
                  ) : (
                    <ObituaryInputForm />
                  )}
                </>
              }
            />
          </Route>
        )}
      </Routes>
      {obituary?.unique_identifier && addingFamilyMember && <AddFamilyMemberModal />}
    </ObituaryDetailContext.Provider>
  )
}

export default ObituaryDetail
