import { Link, useLocation } from 'react-router-dom'
import QRCode from 'react-qr-code'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { useObituaryDetail } from './components/ObituaryDetail'
import ObituaryDropdown from './ObituaryDropdown'
import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'
import { Obituary, ObituaryImageUpload } from './core/_models'
import UploadImagesModalWrapper from './upload-media/UploadImagesModalWrapper'
import useSimpleFormActionChanged from '../../obituaries/useSimpleFormActionChanged'
import { ApiError } from '../auth'
import { generateObituaryTribute } from './core/_requests'
import { useAuth } from '../auth/core/Auth'
import ObituaryAvatarPicker from './ObituaryAvatarPicker'

const IconUploadMedia = styled.div`
  overflow: hidden;

  .hover-upload-media {
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-weight: bold;
    font-size: 1.25rem;
    cursor: pointer;
    height: 100%;
  }

  &:hover {
    .hover-upload-media {
      opacity: 1;
    }
  }
`

const PreNeedContainer = styled.div`
  /* yellow background */
  background: #f2f1706e;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: var(--bs-card-border-radius) var(--bs-card-border-radius) 0 0;
  font-size: 12px;
  height: 16px;
`

function ObituaryHeader() {
  const location = useLocation()

  const { currentPhoenixUserPhone } = useAuth()

  const shouldShowCondensedHeader = useMediaQuery({ query: '(max-width: 700px)' })

  const { obituary, setObituary, obituaryWebsocket } = useObituaryDetail()

  // Websocket will update the obituaryForName state
  const [obituaryForName, setObituaryForName] = useState(obituary?.getSimpleName() || '')

  const [mediaUploadModalOpen, setMediaUploadModalOpen] = useState(false)

  const [generatingTribute, setGeneratingTribute] = useState(false)
  const [tributeGenerationError, setTributeGenerationError] = useState<string | null>(null)

  const [copied, setCopied] = useState(false)
  useEffect(() => {
    if (!copied) {
      return
    }

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }, [copied])

  const onFinishedUploadingImages = useCallback(
    (updatedObituary?: Obituary, newUploads?: ObituaryImageUpload[]) => {
      setMediaUploadModalOpen(false)
    },
    []
  )

  useSimpleFormActionChanged(obituaryWebsocket, (action) => {
    if (action.form === 'personal_information' && action.name === 'lovedOnesFullName') {
      setObituaryForName(action.value)
    }
  })

  const onGenerateTribute = useCallback(async () => {
    setGeneratingTribute(true)
    setTributeGenerationError(null)
    try {
      const updatedObituary = await generateObituaryTribute(obituary.unique_identifier)
      setObituary(updatedObituary)
    } catch (error: any) {
      console.warn('error', error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setTributeGenerationError(errorMessage)
    } finally {
      setGeneratingTribute(false)
    }
  }, [obituary.unique_identifier, setObituary])

  return (
    <>
      <div
        className='card mb-0'
        style={{
          borderBottomWidth: 1,
          borderBottomColor: '#ebedf2',
          borderBottomStyle: 'solid',
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        {obituary.isPreNeedObituary() && <PreNeedContainer>{`- Pre-Need -`}</PreNeedContainer>}
        <div
          className={clsx(
            'card-body pb-0',
            shouldShowCondensedHeader ? 'px-4' : 'px-10',
            obituary.isPreNeedObituary() ? 'pt-6' : 'pt-4'
          )}
          style={{
            zIndex: 2,
          }}
        >
          <div className={clsx('row', shouldShowCondensedHeader ? 'mb-3' : '')}>
            <div className={clsx(shouldShowCondensedHeader ? 'col-12' : 'col-8')}>
              <div className='row h-100'>
                <div className='d-flex align-items-center justify-content-center col-xl-2 col-3'>
                  <ObituaryAvatarPicker
                    obituary={obituary}
                    onClick={() => setMediaUploadModalOpen(true)}
                    condensed={shouldShowCondensedHeader}
                  />
                </div>
                <div className='col-9 d-flex flex-column align-items-start justify-content-center'>
                  <div className='text-gray-800 fs-2 fw-bolder'>
                    {obituaryForName ? `${obituaryForName}` : <span>&nbsp;</span>}
                  </div>
                  {obituary.full_obituary_text &&
                    obituary.full_obituary_text.trim() &&
                    obituary.active_tribute_headlines.length > 0 ? (
                    <div>
                      {/* Sub headerd */}
                      <div className=''>
                        <div className='mb-1'>{obituary.active_tribute_headlines[0].text}</div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {obituary.hasWrittenBiography() && false && 'tributeGenerationComingBackSoon' && (
                        <>
                          <button
                            type='button'
                            className='btn btn-sm btn-light-info'
                            onClick={() => {
                              onGenerateTribute()
                            }}
                            disabled={generatingTribute}
                          >
                            {!generatingTribute ? 'Generate Tribute' : 'Generating...'}
                          </button>
                          {tributeGenerationError && (
                            <div className='mt-3'>
                              <div className='alert alert-danger'>
                                <div className='alert-text'>{tributeGenerationError}</div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <div>
                    <Link
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      to={`/old-obituaries/${obituary.unique_identifier}`}
                    >
                      {!shouldShowCondensedHeader
                        ? `${obituary.dates_lived_string}${obituary.total_age_string || ''}`
                        : `${obituary.dates_lived_short_string}`}
                    </Link>
                  </div>
                  {!shouldShowCondensedHeader && (
                    <div className='w-200px'>
                      <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                        <span className='fw-bold fs-6 text-gray-400'>Obituary Completion</span>
                        <span className='fw-bolder fs-6'>{`${obituary.progress_percentage}%`}</span>
                      </div>
                      <div className='h-5px mx-3 w-100 bg-light mb-3'>
                        <div
                          className='bg-success rounded h-5px'
                          role='progressbar'
                          style={{ width: `${obituary.progress_percentage}%` }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!shouldShowCondensedHeader && obituary.public_link && (
              <div className='col-4 d-flex align-items-center justify-content-end'>
                {/*<img src={'https://via.placeholder.com/150'} alt='QR Code' />*/}
                <QRCode value={obituary.public_link} size={100} />
                <ObituaryDropdown obituary={obituary} />
              </div>
            )}
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <div
                className={clsx(
                  'd-flex overflow-auto',
                  !shouldShowCondensedHeader ? 'h-55px' : 'h-40px'
                )}
              >
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  {!obituary ? (
                    <>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname === '/create-obituary' && 'active')
                          }
                          to='/create-obituary'
                        >
                          Create Obituary
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            ([
                              `/old-obituaries/${obituary.unique_identifier}`,
                              `/old-obituaries/${obituary.unique_identifier}/relatives`,
                              `/old-obituaries/${obituary.unique_identifier}/ceremony`,
                              `/old-obituaries/${obituary.unique_identifier}/education`,
                              `/old-obituaries/${obituary.unique_identifier}/career`,
                              `/old-obituaries/${obituary.unique_identifier}/military`,
                              `/old-obituaries/${obituary.unique_identifier}/affiliations`,
                              `/old-obituaries/${obituary.unique_identifier}/life-events`,
                              `/old-obituaries/${obituary.unique_identifier}/personality`,
                              `/old-obituaries/${obituary.unique_identifier}/final-questions`,
                            ].indexOf(location.pathname) > -1
                              ? 'active'
                              : '')
                          }
                          to={`/old-obituaries/${obituary.unique_identifier}`}
                        >
                          Details
                        </Link>
                      </li>
                      {false && obituary && (
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link text-active-primary me-6 ` +
                              (location.pathname === `/old-obituaries/${obituary.unique_identifier}/media` &&
                                'active')
                            }
                            to={`/old-obituaries/${obituary.unique_identifier}/media`}
                          >
                            {`Add Photos & Videos`}
                          </Link>
                        </li>
                      )}
                      {false && 'oldFormat' && (
                        <>
                          <li className='nav-item'>
                            <Link
                              className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname ===
                                  `/old-obituaries/${obituary.unique_identifier}/questions` && 'active')
                              }
                              to={`/old-obituaries/${obituary.unique_identifier}/questions`}
                            >
                              Answer Questions
                            </Link>
                          </li>
                          <li className='nav-item'>
                            <Link
                              className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname.startsWith(
                                  `/old-obituaries/${obituary.unique_identifier}/family`
                                ) && 'active')
                              }
                              to={`/old-obituaries/${obituary.unique_identifier}/family`}
                            >
                              Describe Family
                            </Link>
                          </li>
                        </>
                      )}
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname === `/old-obituaries/${obituary.unique_identifier}/write` &&
                              'active')
                          }
                          to={`/old-obituaries/${obituary.unique_identifier}/write`}
                        >
                          {!shouldShowCondensedHeader ? 'Write Obituary' : 'Obituary'}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname ===
                              `/old-obituaries/${obituary.unique_identifier}/finalize_and_send` && 'active')
                          }
                          to={`/old-obituaries/${obituary.unique_identifier}/finalize_and_send`}
                        >
                          {!shouldShowCondensedHeader ? `Finalize & Download` : 'Finalize'}
                        </Link>
                      </li>
                      {/* Insights tab: */}
                      {false && <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname === `/old-obituaries/${obituary.unique_identifier}/insights` &&
                              'active')
                          }
                          to={`/old-obituaries/${obituary.unique_identifier}/insights`}
                        >
                          {!shouldShowCondensedHeader ? `Insights` : 'Insights'}
                        </Link>
                      </li>}
                      {/* Memorial folder tab: */}
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname ===
                              `/old-obituaries/${obituary.unique_identifier}/memorial-folder` && 'active')
                          }
                          to={`/old-obituaries/${obituary.unique_identifier}/memorial-folder`}
                        >
                          {!shouldShowCondensedHeader ? `Personalized Poetry` : 'Poetry'}
                        </Link>
                      </li>
                      {/* Scripture tab: */}
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname === `/old-obituaries/${obituary.unique_identifier}/scripture` &&
                              'active')
                          }
                          to={`/old-obituaries/${obituary.unique_identifier}/scripture`}
                        >
                          {!shouldShowCondensedHeader ? `Scripture` : 'Scripture'}
                        </Link>
                      </li>
                      {/* Merchandise tab: */}
                      {false && 'merchandiseComingSoon' && (
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link text-active-primary me-6 ` +
                              (location.pathname.startsWith(
                                `/old-obituaries/${obituary.unique_identifier}/merchandise`
                              ) && 'active')
                            }
                            to={`/old-obituaries/${obituary.unique_identifier}/merchandise`}
                          >
                            {!shouldShowCondensedHeader ? `Merchandise` : 'Merchandise'}
                          </Link>
                        </li>
                      )}
                      {/* Emails tab: */}
                      {false && <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname === `/old-obituaries/${obituary.unique_identifier}/emails` &&
                              'active')
                          }
                          to={`/old-obituaries/${obituary.unique_identifier}/emails`}
                        >
                          {!shouldShowCondensedHeader ? `Emails` : 'Emails'}
                        </Link>
                      </li>}
                      {/* Debug tab: */}
                      {false && 'debugComingSoon' && (
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link text-active-primary me-6 ` +
                              (location.pathname === `/old-obituaries/${obituary.unique_identifier}/debug` &&
                                'active')
                            }
                            to={`/old-obituaries/${obituary.unique_identifier}/debug`}
                          >
                            {!shouldShowCondensedHeader ? `Debug` : 'Debug'}
                          </Link>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {mediaUploadModalOpen && <UploadImagesModalWrapper onClose={onFinishedUploadingImages} />}
    </>
  )
}

export default ObituaryHeader
