/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { DateTime } from 'luxon'
import { RefreshCw } from 'react-feather'

import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../_metronic/partials'
import { getDashboardDataForWidgetOpensAndConversions } from './core/_requests'
import { ApiError } from '../../modules/auth'
import styled from 'styled-components'
import { DashboardDataResponseForWidgetOpensAndConversions } from './core/_models'
import { Link } from 'react-router-dom'
import { useAuth } from '../../modules/auth/core/Auth'

const ReactFeatherButton = styled.button`
  color: #a2a5b7;
  /* add a hover color: */
  &:hover {
    color: #5e6278;
  }
`


const formatDateForViewing = (unixTimestamp: number) => {
  // Given a unix timestamp, return a string in the format of Oct 8, 2021
  // We use luxon for compatibility / ease of use
  const parsedDate = DateTime.fromSeconds(unixTimestamp)
  return parsedDate.toFormat('LLL dd, yyyy')
}


const CountContainer = function ({ children, backgroundColor }: { children: React.ReactNode, backgroundColor: string }) {
  return <span style={{ background: backgroundColor, padding: '3px 5px', color: 'white', borderRadius: '3px' }}>
    {children}
  </span>
}

const WidgetConversionsByDayWidget = ({ isForAllClients = false }: { isForAllClients?: boolean }) => {

  const { currentPhoenixUser } = useAuth()

  const chartColor = 'primary'
  // const chartHeight = '350px'

  const chartHeight = window.innerHeight - 320 + 'px'

  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()

  // By default the selected dates are today - 6 months ago
  // They are represented as a string in the format of YYYY-MM-DD
  // We use luxon for compatibility / ease of use

  const [loadingObituariesByMonth, setLoadingObituariesByMonth] = React.useState(true)
  const [loadingError, setLoadingError] = React.useState<string | null>(null)
  const [dashboardResponse, setDashboardResponse] = React.useState<DashboardDataResponseForWidgetOpensAndConversions | null>(
    null
  )

  const [hideDashboard, setHideDashboard] = React.useState(false)

  const [startDate, setStartDate] = React.useState(
    Math.floor(DateTime.local().setZone('America/Chicago').minus({ days: 7 }).startOf('day').toSeconds())
  )

  const [endDate, setEndDate] = React.useState(
    Math.floor(DateTime.local().setZone('America/Chicago').toSeconds())
  )

  const loadDashboardData = useCallback(async () => {
    setLoadingObituariesByMonth(true)
    try {
      // We may want to use the funeral home's timezone instead of the user's timezone
      // at some point. For now, we'll use the user's timezone.
      // For end of day math and things check ObituariesByMonthWidget.tsx
      // Instead we just use the unix timestamp of now, so that everything works as expected
      const updatedData = await getDashboardDataForWidgetOpensAndConversions(
        startDate,
        endDate,
        isForAllClients
      )
      setLoadingError(null)
      setDashboardResponse(updatedData)
      if (updatedData.total_widget_opens > 0 || updatedData.total_widget_questions_answered > 0) {
        const chart = new ApexCharts(
          chartRef.current,
          chartOptions(chartColor, chartHeight, updatedData)
        )
        if (chart) {
          chart.render()
        }
      } else {
        setHideDashboard(true)
      }
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      setLoadingError(errorMessage)
    } finally {
      setLoadingObituariesByMonth(false)
    }
  }, [chartHeight, endDate, isForAllClients, startDate])

  useEffect(() => {
    loadDashboardData()
  }, [loadDashboardData])

  if (!currentPhoenixUser) {
    // Should never happen
    return <div className='p-5'>
      <div className='alert alert-danger'>
        <div className='alert-text'>You must be logged in to view this dashboard.</div>
      </div>
    </div>
  }

  if (!currentPhoenixUser.current_client) {
    // Also should never happen
    return <div className='p-5'>
      <div className='alert alert-danger'>
        <div className='alert-text'>You must select a funeral home to view this dashboard.</div>
      </div>
    </div>
  }

  return (
    <div className={`card shadow`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            {/* <span className='fw-bold text-gray-800 d-block fs-3'>{`${currentPhoenixUser.current_client.name}`}</span> */}
            <span className='fw-bold text-gray-800 d-block fs-3'>{isForAllClients ? `All Widget Opens & Conversions` : `Widget Opens & Conversions`}</span>
            <span className='text-gray-400 fw-semibold'>
              {`${formatDateForViewing(startDate)} - ${formatDateForViewing(endDate)}`}
            </span>
          </div>

          <div className={`fw-bold fs-3 text-${chartColor}`}>
            {loadingObituariesByMonth ? (
              <div>
                <div className='spinner-border spinner-border-sm align-middle ms-2'></div>
              </div>
            ) : (
              <div>
                {loadingError ? (
                  <>
                    <ReactFeatherButton
                      type='button'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      onClick={loadDashboardData}
                    >
                      <RefreshCw size={14} />
                    </ReactFeatherButton>
                  </>
                ) : (
                  <>
                    {!dashboardResponse ? <></> : <>
                      <CountContainer backgroundColor="#43acf8">{dashboardResponse.getFormattedWidgetOpensLabel()}</CountContainer>
                      <span> </span>
                      <CountContainer backgroundColor="#70d49a">{dashboardResponse.getFormattedObituariesCreatedLabel()}</CountContainer>
                      <span>&nbsp;
                      </span>
                      <CountContainer backgroundColor="#ffd045">{dashboardResponse.getFormattedWidgetConversionsLabel()}</CountContainer>
                    </>}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        {/* end::Hidden */}
        {/* begin::Chart */}
        {loadingError ? (
          <div className='p-5'>
            <div className='alert alert-danger'>
              <div className='alert-text'>{loadingError}</div>
            </div>
          </div>
        ) : (
          <>
            {loadingObituariesByMonth ? (
              <div className='mb-8'></div>
            ) : (
              <div>
                {dashboardResponse?.widget_opens_by_day?.length === 0 || dashboardResponse?.widget_questions_answered_by_day?.length === 0 ? (
                  <div className='p-5'>
                    <div className='alert alert-info'>
                      <div className='alert-text text-center'>
                        {'datePickerIsNotReadyYet' ? (
                          <>
                            No opens/conversions found for the selected date range. Please{' '}
                            <Link to='/create-obituary'>create an obituary</Link>.
                          </>
                        ) : (
                          <>
                            No opens/conversions found for the selected date range. Please select a
                            different date range or{' '}
                            <Link to='/create-obituary'>create an obituary</Link>.
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>{/* Chart gets shown */}</>
                )}
              </div>
            )}
          </>
        )}
        {/* end::Chart */}
        <div
          ref={chartRef}
          className='chart'
          style={{ height: chartHeight, display: hideDashboard ? 'none' : 'block' }}
        ></div>
        {hideDashboard && (
          <div>
            <div className='p-5'></div>
          </div>
        )}
      </div>
    </div>
  )
}

const chartOptions = (
  chartColor: string,
  chartHeight: string,
  responseData: DashboardDataResponseForWidgetOpensAndConversions
): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')

  const fivePleasingHexColors = [
    getCSSVariableValue('--bs-primary'),
    getCSSVariableValue('--bs-success'),
    getCSSVariableValue('--bs-warning'),
    getCSSVariableValue('--bs-danger'),
    getCSSVariableValue('--bs-info'),
  ]

  const resultingOpensByDay = responseData.widget_opens_by_day.map((widgetOpenByDay) => {
    return {
      x: widgetOpenByDay.full_date,
      y: widgetOpenByDay.activity_count
    }
  })

  const resultingQuestionsAnsweredByDay = responseData.widget_questions_answered_by_day.map((widgetConversionByDay) => {
    return {
      x: widgetConversionByDay.full_date,
      y: widgetConversionByDay.conversion_count
    }
  })

  const obituariesByDay = responseData.obituaries_created_by_day.map((obituaryByDay) => {
    return {
      x: obituaryByDay.full_date,
      y: obituaryByDay.count
    }
  })

  const datesAndYears = responseData.widget_opens_by_day.map((openByDay) => {
    const fullDate = openByDay.full_date // "2021-02-01"
    const parsedString = DateTime.fromISO(fullDate)
    // Format this with a day of the week and then the LL dd, yyyy format
    const formattedString = parsedString.toFormat('EEE LLL dd, yyyy')
    return formattedString
  })

  // TODO: Add new "series" item

  const availableSeries = [
    {
      name: 'Opens',
      data: resultingOpensByDay,
      tooltip: {
        singularLabel: 'widget open',
        pluralLabel: 'widget opens',
      }
    },
    {
      name: 'Obituaries',
      data: obituariesByDay,
      tooltip: {
        singularLabel: 'obituary created',
        pluralLabel: 'obituaries created',
      }
    },
    {
      name: 'Conversions',
      data: resultingQuestionsAnsweredByDay,
      tooltip: {
        singularLabel: 'question answered',
        pluralLabel: 'questions answered',
      }
    },
  ]

  return {
    series: availableSeries,
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: true,
          delay: 50,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: datesAndYears,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: function (val) {
          // Integers only
          return val.toFixed(0)
        },
      },
    },
    fill: {
      type: 'solid',
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val, opts) {
          const desiredSeries = availableSeries[opts.seriesIndex]
          return `${val} ${val === 1 ? desiredSeries.tooltip.singularLabel : desiredSeries.tooltip.pluralLabel}`
        },
      },
    },
    colors: fivePleasingHexColors,
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export default WidgetConversionsByDayWidget
