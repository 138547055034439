import React, { useCallback, useEffect, useState } from "react"

import { useDebounce, KTSVG } from "../../../_metronic/helpers"
import { getClientTeamMembers, reinviteTeamMemberToClient } from "../../funeral_homes/dashboard/core/_requests"
import { ApiError } from "../../modules/auth"
import { FuneralHomePhoenixMembership } from "../../modules/obituaries/core/_models"
import { useClientDetail } from "./ClientDetailRoutes"

function ReinviteButton({ membershipUniqueId }: { membershipUniqueId: string }) {

    const {
        client,
    } = useClientDetail()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const [hasSuccessfullyReinvited, setHasSuccessfullyReinvited] = useState<boolean>(false)

    const onReinvite = useCallback(async () => {
        setError(null)
        setLoading(true)
        try {
            await reinviteTeamMemberToClient(client.unique_identifier, {
                membershipUniqueId
            })
            setHasSuccessfullyReinvited(true)
            setTimeout(() => {
                setHasSuccessfullyReinvited(false)
            }, 3000)
        } catch (error: any) {
            console.warn(error)
            const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
            setError(errorMessage)
        } finally {
            setLoading(false)
        }
    }, [client.unique_identifier, membershipUniqueId])

    return <>
        {error && <div className='alert alert-danger' role='alert'>
            <div className='alert-text'>{error}</div>
        </div>}
        <button className='btn btn-sm btn-light-primary' disabled={loading || hasSuccessfullyReinvited} onClick={onReinvite}>
            {hasSuccessfullyReinvited ? <>
                Re-invited!
            </> : <>
                {!loading ? 'Re-Invite' : <div className='spinner spinner-primary spinner-sm' />}
            </>}
        </button>
    </>
}

function TeamMemberRow({ teamMember }: { teamMember: FuneralHomePhoenixMembership }) {

    return <tr>
        <td>
            {teamMember.user.name}
        </td>
        <td>
            {teamMember.user.email}
        </td>
        <td>
            {teamMember.user.data?.inputted_phone_number || '(No phone number)'}
        </td>
        <td>
            {teamMember.date_confirmed_by_user ? 'Yes' : 'No'}
        </td>
        <td className='text-end'>
            {!teamMember.date_confirmed_by_user && <ReinviteButton membershipUniqueId={teamMember.unique_identifier} />}
        </td>
    </tr>
}

function ClientTeamList() {

    const {
        client,
        setAddingTeamMember,
    } = useClientDetail()

    const searchIsEnabled = false // One day

    const [loading, setLoading] = React.useState(true)
    const [loadingError, setLoadingError] = React.useState<string | null>(null)
    const [teamMembers, setTeamMembers] = React.useState<FuneralHomePhoenixMembership[]>([])

    const [searchText, setSearchText] = React.useState<string>('')
    const debouncedSearchText = useDebounce(searchText, 500)

    const loadResults = useCallback(async () => {
        setLoading(true)
        try {
            const updatedTeamMembers = await getClientTeamMembers(client.unique_identifier, debouncedSearchText)
            setTeamMembers(updatedTeamMembers)
            setLoadingError(null)
        } catch (error: any) {
            console.warn('error', error)
            const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
            setLoadingError(errorMessage)
        } finally {
            setLoading(false)
        }
    }, [client.unique_identifier, debouncedSearchText])

    useEffect(() => {
        loadResults()
    }, [loadResults])

    return <div>
        {/* begin::Header */}
        <div className='card-header border-0 p-0 m-0'>
            <h3 className='card-title align-items-start flex-column'>
                {/* begin::Search Bar */}
                {searchIsEnabled && <div className='d-flex align-items-center position-relative my-1'>
                    <input
                        type='text'
                        className='form-control form-control-solid w-300px'
                        placeholder='Search for a funeral home...'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    {loading ? (
                        <>
                            <div className='ms-4 spinner-border' role='status'>
                                <span className='visually-hidden'>Loading...</span>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>}
                {/* end::Search Bar */}
            </h3>
            <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to add a user'
            >
                <button className='btn btn-sm btn-light-primary' onClick={() => setAddingTeamMember(true)}>
                    <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                    Add User
                </button>
            </div>
        </div>
        {/* end::Header */}
        <>
            {loadingError ? (
                <>
                    <div className='ms-4'>
                        <div className='alert alert-danger' role='alert'>
                            <div className='alert-text'>{loadingError}</div>
                        </div>
                    </div>
                </>
            ) : (
                <>

                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {false && (
                                        <th className='w-25px'>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    value='1'
                                                    data-kt-check='true'
                                                    data-kt-check-target='.widget-9-check'
                                                />
                                            </div>
                                        </th>
                                    )}
                                    {false && <th></th>}
                                    <th className='min-w-150px'>Team Member Name</th>
                                    <th className='min-w-150px'>Email Address</th>
                                    <th className='min-w-150px'>Phone Number</th>
                                    <th className='min-w-150px'>Accepted?</th>
                                    <th className='min-w-100px text-end'>Actions</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {teamMembers?.length === 0 ? (
                                    <>
                                        <tr>
                                            <td colSpan={5}>
                                                <div className='text-center pt-5'>No team members found</div>
                                            </td>
                                        </tr>
                                    </>
                                ) : (
                                    <>
                                        {teamMembers.map((teamMember) => {
                                            return (
                                                <TeamMemberRow
                                                    key={`team-member-${teamMember.unique_identifier}`}
                                                    teamMember={teamMember}
                                                />
                                            )
                                        })}
                                    </>
                                )}
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                </>
            )}
        </>
    </div>
}

export default ClientTeamList