import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { ApiError } from '../../../auth'
import { saveObituaryComponent } from '../../core/_requests'
import { personalityAndInterestsSchema } from '../../form_schema'
import { KnownFamilyMember, Obituary, PersonalityAndInterests } from '../../core/_models'
import FormTitle from './FormTitle'
import { IAvailableFormType, ObituaryWebsocket } from '../../../../obituaries/useObituaryWebsocket'
import useComplicatedFormBehavior from '../../../../obituaries/useComplicatedFormBehavior'
import FormItemsRenderer, { IItemProps } from './FormItemsRenderer'
import { useAuth } from '../../../auth/core/Auth'
import useObituaryPartialUpdater from '../../../../obituaries/useObituaryPartialUpdater'
import useObituaryFormUpdater from '../../../../obituaries/next/useObituaryFormUpdater'
import useObituaryChannel from '../../../../obituaries/next/useObituaryChannel'

const _personalityToFormikValues = (
  personality: PersonalityAndInterests
): PersonalityAndInterests => {
  return {
    descriptionOfLovedOne: personality.descriptionOfLovedOne || '',
    favoriteMemories: personality.favoriteMemories || '',
    proudestAccomplishments: personality.proudestAccomplishments || '',
    hobbiesAndFavoriteThings: personality.hobbiesAndFavoriteThings || '',
  }
}

function PersonalityAndInterestsForm({
  obituary,
  setObituary,
  onSuccessfulSave,
  goBackUrl,
  obituaryWebsocket,
  userRelationshipToObituary,
}: {
  obituary: Obituary
  setObituary: (obituary: Obituary) => void
  onSuccessfulSave: (updatedObituary: Obituary) => void
  goBackUrl: string
  obituaryWebsocket: ObituaryWebsocket | null
  userRelationshipToObituary?: KnownFamilyMember | null
}) {

  const {
    obituaryChannel
  } = useObituaryChannel();

  const { currentPhoenixUserPhone } = useAuth()

  const title = 'Personality and Interests'

  const formik = useFormik<PersonalityAndInterests>({
    initialValues: _personalityToFormikValues(
      obituary.data_personality_and_interests || ({} as PersonalityAndInterests)
    ),
    validationSchema: personalityAndInterestsSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true)
      setStatus(undefined)
      try {
        const updatedObituary = await saveObituaryComponent(
          obituary.unique_identifier,
          'personality',
          values
        )
        setObituary(updatedObituary)
        onSuccessfulSave(updatedObituary)
      } catch (error: any) {
        console.warn(error)
        const errorMessage =
          error instanceof ApiError ? error.message : 'Unable to save personality and interests'
        setStatus(errorMessage)
      }
    },
  })

  const formError = formik.status

  const items: IItemProps[] = [
    {
      label: !userRelationshipToObituary?.is_self
        ? currentPhoenixUserPhone?.isFuneralHomeUser()
          ? `How would you describe ${obituary.getSimpleName() || 'your client'
          }? What did people often say about them?`
          : `How would you describe ${obituary.getSimpleName() || 'your loved one'
          }? What did people often say about them?`
        : 'How would you describe yourself? What do people often say about you?',
      name: 'descriptionOfLovedOne',
      type: 'text',
      value: formik.values.descriptionOfLovedOne,
      autoFocus: false,
      required: false,
      error: formik.errors.descriptionOfLovedOne,
      touched: formik.touched.descriptionOfLovedOne,
      isTextArea: true,
      placeholder: 'e.g. kind and selfless / the best sense of humor / tough but loving...',
      additionalQuestionId: 'personality.descriptionOfLovedOne',
    } as IItemProps,
    {
      label: !userRelationshipToObituary?.is_self
        ? currentPhoenixUserPhone?.isFuneralHomeUser()
          ? `What are some of ${obituary.getSimpleName() || 'your client'
          }'s favorite memories? What did they love most about themselves?`
          : `What are some of ${obituary.getSimpleName() || 'your loved one'
          }'s favorite memories? What did they love most about themselves?`
        : 'What are your favorite memories? What do you love most about yourself?',
      name: 'favoriteMemories',
      type: 'text',
      value: formik.values.favoriteMemories,
      autoFocus: false,
      required: false,
      error: formik.errors.favoriteMemories,
      touched: formik.touched.favoriteMemories,
      isTextArea: true,
      placeholder:
        'e.g. the smell of their baking / summers at the beach / built a treehouse for the kids...',
      additionalQuestionId: 'personality.favoriteMemories',
    } as IItemProps,
    {
      label: !userRelationshipToObituary?.is_self
        ? currentPhoenixUserPhone?.isFuneralHomeUser()
          ? `What are some of ${obituary.getSimpleName() || 'your client'
          }'s proudest accomplishments?`
          : `What are some of ${obituary.getSimpleName() || 'your loved one'
          }'s proudest accomplishments?`
        : 'What are some of your proudest accomplishments?',
      name: 'proudestAccomplishments',
      type: 'text',
      value: formik.values.proudestAccomplishments,
      autoFocus: false,
      required: false,
      error: formik.errors.proudestAccomplishments,
      touched: formik.touched.proudestAccomplishments,
      isTextArea: true,
      placeholder: 'e.g. graduated from college / started a business / raised a family...',
      additionalQuestionId: 'personality.proudestAccomplishments',
    } as IItemProps,
    {
      label: !userRelationshipToObituary?.is_self
        ? currentPhoenixUserPhone?.isFuneralHomeUser()
          ? `What are some of ${obituary.getSimpleName() || 'your client'
          }'s favorite things to do? What are their hobbies?`
          : `What are some of ${obituary.getSimpleName() || 'your loved one'
          }'s favorite things to do? What are their hobbies?`
        : 'What are some of your favorite things to do? What are your hobbies?',
      name: 'hobbiesAndFavoriteThings',
      type: 'text',
      value: formik.values.hobbiesAndFavoriteThings,
      autoFocus: false,
      required: false,
      error: formik.errors.hobbiesAndFavoriteThings,
      touched: formik.touched.hobbiesAndFavoriteThings,
      isTextArea: true,
      placeholder: 'e.g. hiking / reading / playing the piano...',
      additionalQuestionId: 'personality.hobbiesAndFavoriteThings',
    } as IItemProps,
  ]

  const formType: IAvailableFormType = 'personality'
  const { editingFieldsByName } = useComplicatedFormBehavior(formType, obituaryWebsocket, formik)

  useObituaryPartialUpdater(obituary.unique_identifier, 'personality', (updatedData: any) => {
    formik.setValues(_personalityToFormikValues(updatedData))
    obituary.data_personality_and_interests = updatedData // Should we call setObituary here? Hm
  })

  useObituaryFormUpdater({
    obituaryChannel,
    onChange: (event) => {
      if (event.formType === 'personality') {
        formik.setFieldValue(event.formName, event.formValue)
      }
    }
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormTitle title={title} />
        {formError && (
          <div className='alert alert-danger'>
            <div className='alert-text'>{formError}</div>
          </div>
        )}
        <FormItemsRenderer
          formType={formType}
          formik={formik}
          obituaryWebsocket={obituaryWebsocket}
          setObituary={setObituary}
          items={items}
          editingFieldsByName={editingFieldsByName}
          receivedQuestions={obituary.data_personality_and_interests?.receivedQuestions || undefined}
          obituaryChannel={obituaryChannel}
        />
        <div className='row mt-10'>
          <div className='col-12 d-flex justify-content-between align-items-center'>
            <Link to={goBackUrl} className='btn btn-secondary me-3'>
              Back
            </Link>
            <button type='submit' className='btn btn-primary'>
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default PersonalityAndInterestsForm
