import ReconnectingWebSocket from 'reconnecting-websocket'

import {
  Obituary,
  ObituaryEmail,
  Section,
  VenueAndDateTimeItem,
} from '../modules/obituaries/core/_models'
import {useCallback, useEffect, useState} from 'react'
import {SimpleUserModel} from '../modules/auth/core/_models'
import {useAuth} from '../modules/auth/core/Auth'

export class ObituaryUserJoined {
  user: SimpleUserModel

  constructor(user: any) {
    this.user = new SimpleUserModel(user)
  }
}

export class ObituaryUserLeft {
  user: SimpleUserModel

  constructor(user: any) {
    this.user = new SimpleUserModel(user)
  }
}

// Keep a generic type of "form" which is 'personal_information' | 'relatives' | 'services' | 'education' | 'career' | 'military' | 'affiliations' | 'life-events' | 'personality':

export type IAvailableFormType =
  | 'personal_information'
  | 'relatives'
  | 'services'
  | 'education'
  | 'career'
  | 'military'
  | 'affiliations'
  | 'life-events'
  | 'personality'
  | 'final-questions'
  | 'write_obituary'
  | 'finalize_obituary'
  | 'insights'
  | 'top-interests'
  | 'memorial-folder'
  | 'scripture'
  | 'merchandise'
  | 'emails'
  | 'debug'

export class ObituaryPageViewMessage {
  user: SimpleUserModel
  page_name: IAvailableFormType

  constructor(user: any, page_name: string) {
    this.user = new SimpleUserModel(user)
    this.page_name = page_name as IAvailableFormType
  }
}

export class ObituaryFormActionsFocused {
  user: SimpleUserModel
  name: string
  form: IAvailableFormType

  constructor(user: any, data: any) {
    this.user = new SimpleUserModel(user)
    this.name = data.name
    this.form = data.form
  }
}

export class ObituaryFormActionsBlurred {
  user: SimpleUserModel
  name: string
  form: IAvailableFormType

  constructor(user: any, data: any) {
    this.user = new SimpleUserModel(user)
    this.name = data.name
    this.form = data.form
  }
}

export class ObituaryFormActionsChanged {
  user: SimpleUserModel
  name: string
  value: string
  form: IAvailableFormType

  constructor(user: any, data: any) {
    this.user = new SimpleUserModel(user)
    this.name = data.name
    this.value = data.value
    this.form = data.form
  }
}

export class ObituaryFormActionsCheckboxChanged {
  user: SimpleUserModel
  name: string
  value: boolean
  form: IAvailableFormType

  constructor(user: any, data: any) {
    this.user = new SimpleUserModel(user)
    this.name = data.name
    this.value = data.value
    this.form = data.form
  }
}

export class ObituarySectionWriteUpdate {
  user: SimpleUserModel
  section_unique_id: string
  status: string
  message: string

  // Optional also are "obituary" and "updated_section"
  obituary?: Obituary
  updated_section?: Section

  constructor(user: any, data: any) {
    this.user = new SimpleUserModel(user)
    this.section_unique_id = data.section_unique_id
    this.status = data.status
    this.message = data.message
    if (data.metadata?.obituary) {
      this.obituary = new Obituary(data.metadata.obituary)
    }
    if (data.metadata?.updated_section) {
      this.updated_section = new Section(data.metadata.updated_section)
    }
  }
}

export class ObituaryMoreQuestionsUpdate {
  user: SimpleUserModel
  tied_to_question_id: string
  status: string
  message: string

  // Obituary is only sent upon a successful update (status === 'success', new questions have been generated)
  obituary?: Obituary

  constructor(user: any, data: any) {
    this.user = new SimpleUserModel(user)
    this.tied_to_question_id = data.tied_to_question_id
    this.status = data.status
    this.message = data.message
    if (data.metadata?.obituary) {
      this.obituary = new Obituary(data.metadata.obituary)
    }
  }
}

export class ObituaryAdditionalQuestionFocused {
  user: SimpleUserModel
  basedOnName: string
  questionUniqueId: string

  constructor(user: any, data: any) {
    this.user = new SimpleUserModel(user)
    this.basedOnName = data.name
    this.questionUniqueId = data.questionUniqueId
  }
}

export class ObituaryAdditionalQuestionBlurred {
  user: SimpleUserModel
  basedOnName: string
  questionUniqueId: string

  constructor(user: any, data: any) {
    this.user = new SimpleUserModel(user)
    this.basedOnName = data.name
    this.questionUniqueId = data.questionUniqueId
  }
}

export class ObituaryAdditionalQuestionChanged {
  user: SimpleUserModel
  basedOnName: string
  questionUniqueId: string
  value: string

  constructor(user: any, data: any) {
    this.user = new SimpleUserModel(user)
    this.basedOnName = data.name
    this.questionUniqueId = data.questionUniqueId
    this.value = data.value || ''
  }
}

export class ObituaryNewServiceItem {
  user: SimpleUserModel
  venueAndDateTimeItem: VenueAndDateTimeItem

  constructor(user: any, serviceItemData: any) {
    this.user = new SimpleUserModel(user)
    this.venueAndDateTimeItem = new VenueAndDateTimeItem(serviceItemData)
  }
}

export class ObituaryRemoveServiceItem {
  user: SimpleUserModel
  venueAndDateTimeItemUniqueId: string

  constructor(user: any, venueAndDateTimeItemUniqueId: string) {
    this.user = new SimpleUserModel(user)
    this.venueAndDateTimeItemUniqueId = venueAndDateTimeItemUniqueId
  }
}

export class ObituaryServiceItemFocused {
  user: SimpleUserModel
  serviceItemUniqueId: string
  name: string

  constructor(user: any, data: any) {
    this.user = new SimpleUserModel(user)
    this.serviceItemUniqueId = data.uniqueId
    this.name = data.name
  }
}

export class ObituaryServiceItemBlurred {
  user: SimpleUserModel
  serviceItemUniqueId: string
  name: string

  constructor(user: any, data: any) {
    this.user = new SimpleUserModel(user)
    this.serviceItemUniqueId = data.uniqueId
    this.name = data.name
  }
}

export class ObituaryServiceItemChanged {
  user: SimpleUserModel
  uniqueId: string
  name: string
  value: string

  constructor(user: any, data: any) {
    this.user = new SimpleUserModel(user)
    this.uniqueId = data.uniqueId
    this.name = data.name
    this.value = data.value
  }
}

export function handleIncomingWebsocketMessage(
  e: any
):
  | ObituaryUserJoined
  | ObituaryUserLeft
  | ObituaryPageViewMessage
  | ObituaryFormActionsFocused
  | ObituaryFormActionsBlurred
  | ObituaryFormActionsChanged
  | ObituaryFormActionsCheckboxChanged
  | ObituarySectionWriteUpdate
  | ObituaryMoreQuestionsUpdate
  | ObituaryAdditionalQuestionFocused
  | ObituaryAdditionalQuestionBlurred
  | ObituaryAdditionalQuestionChanged
  | ObituaryNewServiceItem
  | ObituaryRemoveServiceItem
  | ObituaryServiceItemFocused
  | ObituaryServiceItemBlurred
  | ObituaryServiceItemChanged
  | ObituaryEmail
  | undefined {
  if (!e.data) {
    console.warn('No data in websocket message')
    return
  }
  let data
  try {
    data = JSON.parse(e.data)
  } catch (error) {
    console.warn('Could not parse data: ', e.data)
    return
  }
  if (!data.type) {
    console.warn('No type in data: ', data)
    return
  }
  if (data.type === 'error') {
    console.warn('Error from websocket: ', data)
    return
  }
  if (data.type === 'obituary_simple_update') {
    // We have data.type, data.user, and data.payload
    // data.payload is the actual data we want to update
    // data.user is the user who made the change
    if (!data.payload) {
      console.warn('No payload in data: ', data)
      return
    }
    if (!data.user) {
      console.warn('No user in data: ', data)
      return
    }
    if (!data.payload.subtype) {
      console.warn('No subtype in data: ', data)
      return
    }
    if (data.payload.subtype === 'user_joined') {
      return new ObituaryUserJoined(data.user)
    }
    if (data.payload.subtype === 'user_left') {
      return new ObituaryUserLeft(data.user)
    }
    if (data.payload.subtype === 'page_view') {
      if (!data.payload.page_name) {
        console.warn('No page_name in data: ', data)
        return
      }
      return new ObituaryPageViewMessage(data.user, data.payload.page_name)
    }
    if (data.payload.subtype === 'form_actions.input_focused') {
      if (!data.payload.name) {
        console.warn('No name in data: ', data)
        return
      }
      return new ObituaryFormActionsFocused(data.user, data.payload)
    }
    if (data.payload.subtype === 'form_actions.input_blurred') {
      if (!data.payload.name) {
        console.warn('No name in data: ', data)
        return
      }
      return new ObituaryFormActionsBlurred(data.user, data.payload)
    }
    if (data.payload.subtype === 'form_actions.input_changed') {
      if (!data.payload.name) {
        console.warn('No name in data: ', data)
        return
      }
      // data.payload.value can be an empty string, so we just make sure it's not undefined / null
      // We have a separate event for checkboxes.
      if (typeof data.payload.value === 'undefined' || data.payload.value === null) {
        console.warn('No value in data: ', data)
        return
      }
      return new ObituaryFormActionsChanged(data.user, data.payload)
    }
    if (data.payload.subtype === 'form_actions.checkbox_changed') {
      if (!data.payload.name) {
        console.warn('No name in data: ', data)
        return
      }
      // data.payload.value can be an empty string, so we just make sure it's not undefined / null
      // We have a separate event for checkboxes.
      if (typeof data.payload.value === 'undefined' || data.payload.value === null) {
        console.warn('No value in data: ', data)
        return
      }
      return new ObituaryFormActionsCheckboxChanged(data.user, data.payload)
    }
    if (data.payload.subtype === 'section_write_update') {
      // payload is checked up-stream so we just check the required fields
      // payload.metadata is optional but contains the obituary and the updated section
      // after the final update
      if (!data.payload.status) {
        console.warn('Received section_write_update without status: ', data)
        return
      }
      if (!data.payload.message) {
        console.warn('Received section_write_update without message: ', data)
        return
      }
      if (!data.payload.section_unique_id) {
        console.warn('Received section_write_update without section_unique_id: ', data)
        return
      }
      return new ObituarySectionWriteUpdate(data.user, data.payload)
    }
    // generating_new_questions : ObituaryMoreQuestionsUpdate
    if (data.payload.subtype === 'generating_new_questions') {
      if (!data.payload.status) {
        console.warn('Received generating_new_questions without status: ', data)
        return
      }
      if (!data.payload.message) {
        console.warn('Received generating_new_questions without message: ', data)
        return
      }
      if (!data.payload.tied_to_question_id) {
        console.warn('Received generating_new_questions without tied_to_question_id: ', data)
        return
      }
      return new ObituaryMoreQuestionsUpdate(data.user, data.payload)
    }
    // additional_question_focused : ObituaryAdditionalQuestionFocused
    if (data.payload.subtype === 'form_actions.additional_question_focused') {
      if (!data.payload.questionUniqueId) {
        console.warn('Received additional_question_focused without questionUniqueId: ', data)
        return
      }
      if (!data.payload.name) {
        console.warn('Received additional_question_focused without name: ', data)
        return
      }
      return new ObituaryAdditionalQuestionFocused(data.user, data.payload)
    }
    // additional_question_blurred : ObituaryAdditionalQuestionBlurred
    if (data.payload.subtype === 'form_actions.additional_question_blurred') {
      if (!data.payload.questionUniqueId) {
        console.warn('Received additional_question_blurred without questionUniqueId: ', data)
        return
      }
      if (!data.payload.name) {
        console.warn('Received additional_question_blurred without name: ', data)
        return
      }
      return new ObituaryAdditionalQuestionBlurred(data.user, data.payload)
    }
    // form_actions.additional_question_changed: ObituaryAdditionalQuestionChanged
    if (data.payload.subtype === 'form_actions.additional_question_changed') {
      if (!data.payload.questionUniqueId) {
        console.warn('Received additional_question_changed without questionUniqueId: ', data)
        return
      }
      if (!data.payload.name) {
        console.warn('Received additional_question_changed without name: ', data)
        return
      }
      if (typeof data.payload.value === 'undefined' || data.payload.value === null) {
        console.warn('Received additional_question_changed without value: ', data)
        return
      }
      return new ObituaryAdditionalQuestionChanged(data.user, data.payload)
    }
    // new_service_item: ObituaryNewServiceItem
    if (data.payload.subtype === 'new_service_item') {
      // We expect metadata.venue_and_date_time_item to be set
      if (!data.payload.metadata) {
        console.warn('Received new_service_item without metadata: ', data)
        return
      }
      if (!data.payload.metadata.venue_and_date_time_item) {
        console.warn('Received new_service_item without metadata.venue_and_date_time_item: ', data)
        return
      }
      return new ObituaryNewServiceItem(data.user, data.payload.metadata.venue_and_date_time_item)
    }
    // remove_service_item: ObituaryRemoveServiceItem
    if (data.payload.subtype === 'remove_service_item') {
      // We only expect metadata.venue_and_date_time_item_unique_id to be set
      if (!data.payload.metadata) {
        console.warn('Received remove_service_item without metadata: ', data)
        return
      }
      if (!data.payload.metadata.venue_and_date_time_item_unique_id) {
        console.warn(
          'Received remove_service_item without metadata.venue_and_date_time_item_unique_id: ',
          data
        )
        return
      }
      return new ObituaryRemoveServiceItem(
        data.user,
        data.payload.metadata.venue_and_date_time_item_unique_id
      )
    }
    // form_actions.service_item_focused: ObituaryServiceItemFocused
    if (data.payload.subtype === 'form_actions.service_item_focused') {
      if (!data.payload.uniqueId) {
        console.warn('Received service_item_focused without uniqueId: ', data)
        return
      }
      if (!data.payload.name) {
        console.warn('Received service_item_focused without name: ', data)
        return
      }
      return new ObituaryServiceItemFocused(data.user, data.payload)
    }
    // form_actions.service_item_blurred: ObituaryServiceItemBlurred
    if (data.payload.subtype === 'form_actions.service_item_blurred') {
      if (!data.payload.uniqueId) {
        console.warn('Received service_item_blurred without uniqueId: ', data)
        return
      }
      if (!data.payload.name) {
        console.warn('Received service_item_blurred without name: ', data)
        return
      }
      return new ObituaryServiceItemBlurred(data.user, data.payload)
    }
    // form_actions.service_item_changed: ObituaryServiceItemChanged
    if (data.payload.subtype === 'form_actions.service_item_changed') {
      if (!data.payload.uniqueId) {
        console.warn('Received service_item_changed without uniqueId: ', data)
        return
      }
      if (!data.payload.name) {
        console.warn('Received service_item_changed without name: ', data)
        return
      }
      if (typeof data.payload.value === 'undefined' || data.payload.value === null) {
        console.warn('Received service_item_changed without value: ', data)
        return
      }
      return new ObituaryServiceItemChanged(data.user, data.payload)
    }
    console.warn(
      `Unknown subtype for obituary_simple_update (${data.payload.subtype || '(no subtype)'}): `,
      data
    )
  } else if (data.type === 'obituary_received_email') {
    if (!data.payload) {
      console.warn('Received obituary_received_email without payload: ', data)
      return
    }
    if (!data.payload.email) {
      console.warn('Received obituary_received_email without email: ', data)
      return
    }
    // For now we have a single subtype (created)
    if (data.payload.subtype !== 'created') {
      console.warn('Unknown subtype for obituary_received_email: ', data)
      return
    }
    return new ObituaryEmail(data.payload.email)
  } else {
    console.warn('Unknown type for obituary updates: ', data)
  }

  return undefined
}

export interface FormActions {
  onInputFocused: ({name, form}: {name: string; form: IAvailableFormType}) => void
  onInputBlurred: ({name, form}: {name: string; form: IAvailableFormType}) => void
  onCheckboxChanged: ({
    form,
    name,
    value,
  }: {
    form: IAvailableFormType
    name: string
    value: boolean
  }) => void
  onInputChanged: ({
    form,
    name,
    value,
  }: {
    form: IAvailableFormType
    name: string
    value: string
  }) => void
  onAdditionalQuestionFocused: ({
    questionUniqueId,
    name,
  }: {
    questionUniqueId: string
    name: string
  }) => void
  onAdditionalQuestionBlurred: ({
    questionUniqueId,
    name,
  }: {
    questionUniqueId: string
    name: string
  }) => void
  onAdditionalQuestionChanged: ({
    questionUniqueId,
    name,
    value,
  }: {
    questionUniqueId: string
    name: string
    value: string
  }) => void
  // Service items (focused, blurred, changed):
  onServiceItemFocused: ({uniqueId, name}: {uniqueId: string; name: string}) => void
  onServiceItemBlurred: ({uniqueId, name}: {uniqueId: string; name: string}) => void
  onServiceItemChanged: ({
    uniqueId,
    name,
    value,
  }: {
    uniqueId: string
    name: string
    value: string
  }) => void
}

export interface ObituaryWebsocket {
  obituary: Obituary
  websocket: ReconnectingWebSocket | null
  sendMessage: (type: string, payload: any) => void
  sendPageView: (page: IAvailableFormType) => void
  formActions: FormActions
  addEventListener: (type: string, listener: (event: MessageEvent) => void) => void
  removeEventListener: (type: string, listener: (event: MessageEvent) => void) => void
}

function useObituaryWebsocket(obituary: Obituary) {
  const {auth} = useAuth()
  const authToken = auth ? auth.api_token : null

  const [connectedWebsocket, setConnectedWebsocket] = useState<ReconnectingWebSocket | null>(null)

  const onSendMessage = useCallback(
    (type: string, payload: any) => {
      if (!connectedWebsocket) {
        return
      }
      connectedWebsocket.send(JSON.stringify({type, payload}))
    },
    [connectedWebsocket]
  )

  const onSendPageView = useCallback(
    (page: IAvailableFormType) => {
      onSendMessage('page_view', {page})
    },
    [onSendMessage]
  )

  const onInputFocused = useCallback(
    ({name, form}: {name: string; form: IAvailableFormType}) => {
      onSendMessage('form_actions.input_focused', {name, form})
    },
    [onSendMessage]
  )

  const onInputBlurred = useCallback(
    ({name, form}: {name: string; form: IAvailableFormType}) => {
      onSendMessage('form_actions.input_blurred', {name, form})
    },
    [onSendMessage]
  )

  const onCheckboxChanged = useCallback(
    ({name, value, form}: {form: IAvailableFormType; name: string; value: boolean}) => {
      onSendMessage('form_actions.checkbox_changed', {form, name, value})
    },
    [onSendMessage]
  )

  const onInputChanged = useCallback(
    ({form, name, value}: {form: IAvailableFormType; name: string; value: string}) => {
      onSendMessage('form_actions.input_changed', {form, name, value})
    },
    [onSendMessage]
  )

  const onAdditionalQuestionFocused = useCallback(
    ({questionUniqueId, name}: {questionUniqueId: string; name: string}) => {
      onSendMessage('form_actions.additional_question_focused', {questionUniqueId, name})
    },
    [onSendMessage]
  )

  const onAdditionalQuestionBlurred = useCallback(
    ({questionUniqueId, name}: {questionUniqueId: string; name: string}) => {
      onSendMessage('form_actions.additional_question_blurred', {questionUniqueId, name})
    },
    [onSendMessage]
  )

  const onAdditionalQuestionChanged = useCallback(
    ({questionUniqueId, name, value}: {questionUniqueId: string; name: string; value: string}) => {
      onSendMessage('form_actions.additional_question_changed', {questionUniqueId, name, value})
    },
    [onSendMessage]
  )

  const onServiceItemFocused = useCallback(
    ({uniqueId, name}: {uniqueId: string; name: string}) => {
      onSendMessage('form_actions.service_item_focused', {uniqueId, name})
    },
    [onSendMessage]
  )

  const onServiceItemBlurred = useCallback(
    ({uniqueId, name}: {uniqueId: string; name: string}) => {
      onSendMessage('form_actions.service_item_blurred', {uniqueId, name})
    },
    [onSendMessage]
  )

  const onServiceItemChanged = useCallback(
    ({uniqueId, name, value}: {uniqueId: string; name: string; value: string}) => {
      onSendMessage('form_actions.service_item_changed', {uniqueId, name, value})
    },
    [onSendMessage]
  )

  useEffect(() => {
    if (!authToken) {
      return
    }
    if (obituary.is_loading || !obituary.unique_identifier) {
      return
    }
    // Sets up the websocket connection for this card
    const webSocket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_API_WS_URL}/obituaries/${obituary.unique_identifier}/?token=${authToken}`
    )
    setConnectedWebsocket(webSocket)
    return () => {
      webSocket.close()
    }
  }, [authToken, obituary.is_loading, obituary.unique_identifier])

  return {
    obituaryWebsocket: {
      obituary,
      websocket: connectedWebsocket,
      sendMessage: onSendMessage,
      sendPageView: onSendPageView,
      formActions: {
        onInputFocused,
        onInputBlurred,
        onCheckboxChanged,
        onInputChanged,
        onAdditionalQuestionFocused,
        onAdditionalQuestionBlurred,
        onAdditionalQuestionChanged,
        onServiceItemFocused,
        onServiceItemBlurred,
        onServiceItemChanged,
      } as FormActions,
    } as ObituaryWebsocket,
  }
}

export default useObituaryWebsocket
