import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import styled from 'styled-components'
import { Channel } from 'phoenix'

import { LogOut } from 'react-feather'

import { getObituaryByShareableCode } from '../modules/obituaries/core/_requests'
import { ApiError } from '../modules/auth'
import { Obituary, KnownFamilyMember } from '../modules/obituaries/core/_models'
import ObituariesDetailItem from './ObituariesDetailItem'
import TabNavigation from './TabNavigation'
import WriteObituary from './WriteObituary'
import FinalizeAndSend from './FinalizeAndSend'
import Welcome from './Welcome'
import ConnectedUsers from './ConnectedUsers'
import UserRelationshipForm from './UserRelationshipForm'
import { useAuth } from '../modules/auth/core/Auth'
import { ThemeModeComponent } from '../../_metronic/assets/ts/layout'
import LanguagePicker from './LanguagePicker'
import useNextObituaryWebsocket from './next/useNextObituaryWebsocket'
import { ObituaryWebsocket } from './useObituaryWebsocket'
import ShareableLogin from './ShareableLogin'
import ComingSoon from './ComingSoon'

/*
pathname.startsWith('/o matters below for dark-mode search in the future
*/
/* Dark mode was #1a1a1a, now it's #f6f8fa */
/* color was #ececf1 now it's #1a1a1a */

const PageHeader = styled.div`
  position: relative;
  background-color: #f6f8fa;
  color: #1a1a1a;
  text-align: left;
  font-size: 1.5rem;
  height: 55px;

  display: flex;

  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  margin-right: 100px; /* to account for the language/logout buttons */
`

const PageHeaderLink = styled(Link)`
  color: #1a1a1a;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const PageHeaderTribute = styled.div`
  display: flex;
  width: 100%;
`
/*
pathname.startsWith('/o matters below for dark-mode search in the future
*/
/* Dark mode was #1a1a1a, now it's #f6f8fa */

const Container = styled.div`
  position: relative;

  background-color: #f6f8fa;
  color: #1a1a1a;

  h1 {
    color: #1a1a1a;
  }
`

const Content = styled.div`
  position: relative;
`

const ContentColumn = styled.div``

interface ShareableObituaryContextParams {
  obituary: Obituary // Always defined, but we check below for is_loading to only render if it's loaded
  setObituary: (obituary: Obituary) => void
  obituaryWebsocket: ObituaryWebsocket | null // Temporary, obituaryWebsocket is going away
  obituaryChannel: Channel | null
  userRelationshipToObituary: KnownFamilyMember | null
  setUserRelationshipToObituary: (userRelationshipToObituary: KnownFamilyMember | null) => void
}

const ShareableObituaryContext = React.createContext<ShareableObituaryContextParams>({
  obituary: { is_loading: true } as Obituary,
  setObituary: () => { },
  obituaryWebsocket: null, // Temporary
  obituaryChannel: null,
  userRelationshipToObituary: null,
  setUserRelationshipToObituary: () => { },
})

export const useShareableObituary = () => React.useContext(ShareableObituaryContext)

function ObituaryTributeHeader() {
  const { obituary } = useShareableObituary()

  if (!obituary.hasWrittenBiography()) {
    return <></>
  }

  if (obituary.active_tribute_headlines.length === 0) {
    return <></>
  }

  return (
    <PageHeaderTribute className='text-muted fs-8 fs-md-4'>
      {obituary.getTributeText()}
    </PageHeaderTribute>
  )
}

function ShareableViewLoader() {
  const navigate = useNavigate()

  const { auth, saveAuth, logout, currentPhoenixUser, currentPhoenixUserPhone, setCurrentPhoenixUser, setCurrentPhoenixUserPhone } = useAuth()
  const { obituaryWebsocket, userRelationshipToObituary, setUserRelationshipToObituary } =
    useShareableObituary()

  const location = useLocation()

  const params = useParams()
  const shareableCode = params.shareable_code as string

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const { obituary, setObituary } = useShareableObituary()

  const haveLoadedObituary = useRef(false)

  const tryToLoadObituary = useCallback(async () => {

    const apiToken = auth?.api_token

    // This is maybe hacky, but if we have set currentPhoenixUser
    // then we have already set the obituary, so we return.
    // Likely just need to re-write the front-end, smh.

    if (currentPhoenixUser) {

      try {
        const { obituary: updatedObituary, myRelationship: updatedUserRelationship } =
          await getObituaryByShareableCode(params.shareable_code as string as string, apiToken || '')
        setObituary(updatedObituary)
        setUserRelationshipToObituary(updatedUserRelationship)
        setError(null)
      } catch (error) {
        console.warn(error)
        const errorMessage = error instanceof ApiError ? error.message : 'Unable to load obituary'
        setError(errorMessage)
        setObituary({ is_loading: false } as Obituary)
      } finally {
        setLoading(false)
      }

      return
    }

    // hax to keep from infinitely re-loading the obituary
    // MVPing this out the door...

    if (haveLoadedObituary.current) {
      return
    }

    haveLoadedObituary.current = true

    // One of the most important things we do is check the URL for a parameter
    // of `t`. If it exists, we will use that as the token. If that request succeeds,
    // then we basically auto-login the user.
    //
    // If it doesn't succeed we still end up loading the obituary by the code.
    // The token may have expired, be invalid, etc and we still want to show the original
    // obituary workflow.

    // First, try to load the obituary by the token:

    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('t')

    if (token) {

      try {
        const {
          obituary: updatedObituary,
          myRelationship: updatedUserRelationship,
          user: updatedUser,
          user_phone: updatedUserPhone,
          api_token: updatedApiToken
        } =
          await getObituaryByShareableCode(params.shareable_code as string as string, apiToken || token)
        setCurrentPhoenixUser(updatedUser || undefined)
        setCurrentPhoenixUserPhone(updatedUserPhone || undefined)
        setObituary(updatedObituary)
        setUserRelationshipToObituary(updatedUserRelationship)
        setError(null)
        setLoading(false)
        if (updatedApiToken) {
          saveAuth({
            api_token: updatedApiToken,
          })
        }
        // It's important that we return here. We end up loading the obituary after this
        // which will require a login, and this way we quickly auto-login to this obit.
        return;
      } catch (error) {
        console.warn(error)
      }
    }

    try {
      const { obituary: updatedObituary, myRelationship: updatedUserRelationship } =
        await getObituaryByShareableCode(params.shareable_code as string as string, apiToken || '')
      setObituary(updatedObituary)
      setUserRelationshipToObituary(updatedUserRelationship)
      setError(null)
    } catch (error) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Unable to load obituary'
      setError(errorMessage)
      setObituary({ is_loading: false } as Obituary)
    } finally {
      setLoading(false)
    }
  }, [currentPhoenixUser, auth?.api_token, params.shareable_code, setCurrentPhoenixUser, setCurrentPhoenixUserPhone, setObituary, setUserRelationshipToObituary, saveAuth])

  const onLogout = useCallback(async () => {
    try {
      await logout()
      navigate(`/o/${obituary.shareable_code}`)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof ApiError ? error.message : 'Unable to logout'
      alert(errorMessage) // This should never happen so quickly making this an alert() is fine
    }
  }, [logout, navigate, obituary.shareable_code])

  useEffect(() => {
    tryToLoadObituary()
  }, [tryToLoadObituary])

  if (loading || obituary.is_loading) {
    return (
      <div className='w-100 h-100'>
        <div className='d-flex justify-content-center align-items-center h-100'>
          <div className='spinner-border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='w-100 h-100'>
        <div className='d-flex justify-content-center align-items-center h-100'>
          <div className='alert alert-danger' role='alert'>
            <div className='alert-text'>{error}</div>
          </div>
        </div>
      </div>
    )
  }

  if (currentPhoenixUserPhone && currentPhoenixUserPhone.isFuneralHomeUser()) {
    // should we redirect? maybe not.
    // return <Navigate to={`/obituaries/${obituary.unique_identifier}`} />
  }

  if (!obituary.funeral_home) {
    // This should never happen so we raise an error (but keep TypeScript happy)
    throw new Error('Obituary has no funeral home')
  }

  return (
    <>
      <Container className='w-100 h-100'>
        <Content className='container'>
          {location.pathname !== `/o/${shareableCode}/start` ? (
            <>
              <PageHeader>
                <PageHeaderLink
                  to={`/o/${shareableCode}/details`}
                >{`${obituary.getSimpleName()}'s Obituary`}</PageHeaderLink>
                <ObituaryTributeHeader />
              </PageHeader>
            </>
          ) : (
            <div style={{ height: 55 }} />
          )}
          <div className='row'>
            <ContentColumn className='col-12 col-md-6 offset-md-3'>
              {(currentPhoenixUserPhone || currentPhoenixUser) &&
                (!userRelationshipToObituary ||
                  !userRelationshipToObituary.date_confirmed_by_user) ? (
                <>
                  <UserRelationshipForm />
                </>
              ) : (
                <>
                  {!location.pathname.startsWith(`/o/${shareableCode}/start`) &&
                    !location.pathname.startsWith(`/o/${shareableCode}/login`) && (
                      <>
                        <TabNavigation obituary={obituary} />
                      </>
                    )}
                  <Outlet />
                </>
              )}
            </ContentColumn>
          </div>
          <div
            className='position-absolute top-0 end-0 p-3 d-flex flex-direction-row'
            style={{ zIndex: 100 }}
          >
            {false && 'languagesComingOneDay' && (
              <LanguagePicker startCollapsed hideEnglishDescriptions />
            )}
            {/*
              pathname.startsWith('/o matters below for dark-mode search in the future
              dark mode used to have a light button for logout. now it's a secondary button.
            */}
            {(currentPhoenixUserPhone || currentPhoenixUser) && (
              <button onClick={() => onLogout()} className='ms-4 btn btn-sm btn-secondary'>
                <LogOut color='white' size={16} />
              </button>
            )}
          </div>
        </Content>
      </Container>
      {false &&
        'disabledForNow' &&
        currentPhoenixUserPhone &&
        obituaryWebsocket &&
        !location.pathname.startsWith(`/o/${shareableCode}/start`) &&
        !location.pathname.startsWith(`/o/${shareableCode}/login`) && <ConnectedUsers />}
    </>
  )
}

function FixRedirect() {
  const { currentPhoenixUserPhone, currentPhoenixUser } = useAuth()

  // const [searchParams] = useSearchParams()

  //
  // e.g https://localhost:3000/o/YEH-NH8?c=uaoFubRM
  // includes a one-time redeemable code
  // that quickly authenticates a user when invited to an obituary
  //
  // This is risky because users may share links.
  // Do we need to worry about what happens if a user shares a link with a code?
  // If so, we should probably remove the code from the URL after it's used.
  //

  // const searchParamCode = searchParams.get('c')

  // Deciding for now to deal with the initial login
  // to avoid worrying about this

  const [searchParams] = useSearchParams()
  const incomingToken = searchParams.get('t')

  const { obituary } = useShareableObituary()

  const addIncomingTokenUrlParam = incomingToken ? `?t=${incomingToken}` : ``

  if (currentPhoenixUserPhone || currentPhoenixUser) {
    return <Navigate to={`/o/${obituary.shareable_code}/details${addIncomingTokenUrlParam}`} replace />
  }

  return <Navigate to={`/o/${obituary.shareable_code}/start${addIncomingTokenUrlParam}`} replace />
}

function ShareableView() {
  const { currentPhoenixUserPhone, currentPhoenixUser, auth } = useAuth()

  const [obituary, setObituary] = useState<Obituary>({ is_loading: true } as Obituary)
  const [userRelationshipToObituary, setUserRelationshipToObituary] =
    useState<KnownFamilyMember | null>(null)

  const {
    obituaryChannel
  } = useNextObituaryWebsocket({
    apiAccessToken: auth?.api_token || '',
    obituaryUniqueId: obituary.unique_identifier,
  })

  useEffect(() => {
    // Force dark mode
    ThemeModeComponent.init()
  }, [])

  console.log("ShareableView: ', ",)

  return (
    <ShareableObituaryContext.Provider
      value={{
        obituary,
        setObituary,
        obituaryWebsocket: null,
        obituaryChannel,
        userRelationshipToObituary,
        setUserRelationshipToObituary,
      }}
    >
      <Routes>
        <Route path='/:shareable_code/*' element={<ShareableViewLoader />}>
          <Route path='start' element={<Welcome />} />
          {!currentPhoenixUserPhone && !currentPhoenixUser ? (
            <>
              <Route path='login' element={<ShareableLogin />} />
              <Route path='login/code-confirm' element={<ShareableLogin confirming />} />
            </>
          ) : (
            <>
              <Route path='details' element={<ObituariesDetailItem selectedSection='personal' />} />
              <Route
                path='details/relatives'
                element={<ObituariesDetailItem selectedSection='relatives' />}
              />
              <Route
                path='details/ceremony'
                element={<ObituariesDetailItem selectedSection='ceremony' />}
              />
              <Route
                path='details/education'
                element={<ObituariesDetailItem selectedSection='education' />}
              />
              <Route
                path='details/career'
                element={<ObituariesDetailItem selectedSection='career' />}
              />
              <Route
                path='details/military'
                element={<ObituariesDetailItem selectedSection='military' />}
              />
              <Route
                path='details/affiliations'
                element={<ObituariesDetailItem selectedSection='affiliations' />}
              />
              <Route
                path='details/life-events'
                element={<ObituariesDetailItem selectedSection='life-events' />}
              />
              <Route
                path='details/personality'
                element={<ObituariesDetailItem selectedSection='personality' />}
              />
              <Route
                path='details/final-questions'
                element={<ObituariesDetailItem selectedSection='final-questions' />}
              />
              <Route path='write' element={<WriteObituary />} />
              <Route path='finalize_and_send' element={<FinalizeAndSend />} />
            </>
          )}
          {/* Everything else goes to the start */}
          <Route path='*' element={<FixRedirect />} />
        </Route>
        <Route path='*' element={<ComingSoon />} />
      </Routes>
    </ShareableObituaryContext.Provider>
  )
}

export default ShareableView
