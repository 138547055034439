import { createRoot } from 'react-dom/client'
// Axios
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { GoogleOAuthProvider } from '@react-oauth/google';

// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropzone-uploader/dist/styles.css'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import 'react-quill/dist/quill.snow.css'

import 'react-image-crop/dist/ReactCrop.css'
import { AuthProvider } from './app/modules/auth/core/Auth'
import { setupAxios } from './app/modules/auth/core/AuthHelpers'
import ContextWrapper from './contexts'
import { GOOGLE_OAUTH_CLIENT_ID } from './app/App'

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')

if (container) {
  createRoot(container).render(
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <ContextWrapper>
        <DndProvider backend={HTML5Backend}>
          <QueryClientProvider client={queryClient}>
            <MetronicI18nProvider>
              <AuthProvider>
                <AppRoutes />
              </AuthProvider>
            </MetronicI18nProvider>
            {false && 'enableForQueryTools' && <ReactQueryDevtools initialIsOpen={false} />}
          </QueryClientProvider>
        </DndProvider>
      </ContextWrapper>
    </GoogleOAuthProvider>
  )
}
