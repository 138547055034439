import TitleAndDescriptionItem from "./TitleAndDescriptionItem"
import { useShareableObituary } from "./ShareableView"
import { EmailLoginForm, EmailVerifyForm, SocialLoginForm } from "../modules/auth/components/Login"
import { Navigate, useNavigate } from "react-router-dom"
import useEmailFromUrl from "../hooks/useEmailFromUrl"

function ShareableLogin({ confirming }: { confirming?: boolean }) {

    const navigate = useNavigate()

    const { emailAddress } = useEmailFromUrl()

    const { obituary } = useShareableObituary()

    if (confirming) {
        if (!emailAddress) {
            return <Navigate to={`/o/${obituary.shareable_code}/login`} />
        }
        return <div className='mt-12'>
            <div>
                <TitleAndDescriptionItem
                    title={`Access ${obituary.getSimpleName()}'s Obituary`}
                    description={`We have sent you an email with a link to confirm your email address. Please check your inbox and click the link to confirm your email address.`}
                />
                <div>
                    <div className='mt-6'>
                        <EmailVerifyForm emailAddressBeingVerified={emailAddress} onCancel={() => {
                            navigate(`/o/${obituary.shareable_code}/login`)
                        }} />
                    </div>
                </div>
            </div>
        </div>
    }

    return <div className='mt-12'>
        <div>
            <TitleAndDescriptionItem
                title={`Access ${obituary.getSimpleName()}'s Obituary`}
                description={`To honor the life and legacy of ${obituary.getSimpleFirstName()}, please login below.`}
            />
            <div>
                <div className='mt-6'>
                    <SocialLoginForm />
                </div>
                {/* begin::Separator */}
                <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div>
                {/* end::Separator */}
                <EmailLoginForm
                    confirmEmailCodeRoute={`/o/${obituary.shareable_code}/login/code-confirm`}
                    forObituaryUniqueId={obituary.unique_identifier}
                />
            </div>
        </div>
    </div>
}

export default ShareableLogin