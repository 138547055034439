import axios from 'axios'
import {
  DashboardDataResponse,
  DashboardDataResponseForUniqueSessions,
  DashboardDataResponseForWidgetOpensAndConversions,
  DashboardJourneysResponse,
  DashboardObituariesResponse,
} from './_models'
import {
  FuneralHomeWithMemberships,
  FuneralHomeMembership,
  Obituary,
  FuneralHome,
  FuneralHomePhoenixMembership,
  SimplifiedPhoenixUser,
  FuneralHomePaymentSetting,
  FuneralHomePaymentSettingForAdmin,
} from '../../../modules/obituaries/core/_models'
import {SelectableOptionGroup} from '../../../modules/obituaries/InspirationalPassages'
import {GratitudeAlphabet} from '../../../modules/obituaries/gratitude_alphabet/types'
import {InspirationalPassage} from '../../../modules/obituaries/inspiration_passage/types'
import {PersonalizedPoem} from '../../../modules/obituaries/poetry/types'
import {
  Journey,
  JourneyAnswer,
  JourneyFlow,
  JourneyFlowPreviewResponse,
  JourneyPersonalization,
} from './journey_classes'

// Tenancy

export function adminUpdateCurrentUserClient(updatedClientUniqueId: string) {
  return axios
    .post(`${process.env.REACT_APP_NEXT_API_URL}/admin/update-current-user-client`, {
      updated_client_unique_id: updatedClientUniqueId,
    })
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return responseData.data
    })
}

//

export function getDashboardDataOldByMonth(
  datetimeStartUnixTimestamp: number,
  datetimeEndUnixTimestamp: number
) {
  const newRoute = `${process.env.REACT_APP_NEXT_API_URL}/admin/dashboard/data?start_unix_ts=${datetimeStartUnixTimestamp}&end_unix_ts=${datetimeEndUnixTimestamp}`
  return axios
    .get(newRoute)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return new DashboardDataResponse(responseData.data)
    })
}

export function getDashboardDataForUniqueSessions(
  datetimeStartUnixTimestamp: number,
  datetimeEndUnixTimestamp: number,
  isForAllClients: boolean = false
) {
  const newRoute = `${process.env.REACT_APP_NEXT_API_URL}/admin/dashboard/data/unique-sessions?start_unix_ts=${datetimeStartUnixTimestamp}&end_unix_ts=${datetimeEndUnixTimestamp}&is_for_all_clients=${isForAllClients}`
  return axios
    .get(newRoute)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return new DashboardDataResponseForUniqueSessions(responseData.data)
    })
}

export function getDashboardDataForWidgetOpensAndConversions(
  datetimeStartUnixTimestamp: number,
  datetimeEndUnixTimestamp: number,
  isForAllClients: boolean = false
) {
  const newRoute = `${process.env.REACT_APP_NEXT_API_URL}/admin/dashboard/data/widget-opens-and-conversions?start_unix_ts=${datetimeStartUnixTimestamp}&end_unix_ts=${datetimeEndUnixTimestamp}&is_for_all_clients=${isForAllClients}`
  return axios
    .get(newRoute)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return new DashboardDataResponseForWidgetOpensAndConversions(responseData.data)
    })
}

export function getDashboardObituaries(query: string) {
  return axios
    .get(`${process.env.REACT_APP_NEXT_API_URL}/admin/dashboard/obituaries?q=${query || ''}`)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return new DashboardObituariesResponse(responseData.data)
    })
}

export function getDashboardJourneys(query: string) {
  return axios
    .get(`${process.env.REACT_APP_NEXT_API_URL}/admin/dashboard/journeys?q=${query || ''}`)
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return new DashboardJourneysResponse(responseData.data)
    })
}

export function getDashboardJourneyQuestions(journeyUniqueId: string) {
  return axios
    .get(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/dashboard/journeys/${journeyUniqueId}/questions`
    )
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return {
        journey: new Journey(responseData.data.journey),
        journeyAnswers: [...(responseData.data.latest_journey_answers || [])].map(
          (x: any) => new JourneyAnswer(x)
        ),
      }
    })
}

export function getCurrentFuneralHomeDetails() {
  return axios
    .get(`${process.env.REACT_APP_NEXT_API_URL}/admin/current-funeral-home`)
    .then((res) => res.data)
    .then((response: any) => {
      return {
        funeralHome: new FuneralHomeWithMemberships(response.data.funeral_home),
        myMembership: response.data.my_membership
          ? new FuneralHomeMembership(response.data.my_membership)
          : null,
      }
    })
}

export function createObituaryFromAdmin(data: any) {
  return axios
    .post<Obituary>(`${process.env.REACT_APP_NEXT_API_URL}/admin/create-obituary`, data)
    .then((res) => res.data)
    .then((response: any) => {
      return {
        obituary: new Obituary(response.data.obituary),
        return_successes: response.data.return_successes,
        return_errors: response.data.return_errors,
      }
    })
}

export function getObituaryFromAdmin(uniqueId: string) {
  return axios
    .get<Obituary>(`${process.env.REACT_APP_NEXT_API_URL}/admin/obituaries/${uniqueId}`)
    .then((res) => res.data)
    .then((response: any) => {
      return new Obituary(response.data)
    })
}

export function getJourneyFromAdmin(uniqueId: string) {
  return axios
    .get<Journey>(`${process.env.REACT_APP_NEXT_API_URL}/admin/journeys/${uniqueId}`)
    .then((res) => res.data)
    .then((response: any) => {
      return new Journey(response.data)
    })
}

export function createJourneyPersonalization(recordedForNodeId: string, activeVideoBlob: Blob) {
  const formData = new FormData()
  formData.append('recording', activeVideoBlob, 'recording.webm')
  formData.append('recorded_for_node_id', recordedForNodeId)
  return axios
    .post<JourneyPersonalization>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/create-journey-personalization`,
      formData
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new JourneyPersonalization(response.data.journey_personalization)
    })
}

export function getActiveJourneyPersonalizationForNode(nodeId: string) {
  return axios
    .get<JourneyPersonalization | null>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/active-journey-personalization-for-node/${nodeId}`
    )
    .then((res) => res.data)
    .then((response: any) => {
      if (response.data.journey_personalization) {
        return new JourneyPersonalization(response.data.journey_personalization)
      } else {
        return null
      }
    })
}

export function saveFormValuesForNode(nodeId: string, data: any) {
  return axios
    .post<JourneyPersonalization>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/save-journey-personalization-form-values-for-node/${nodeId}`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new JourneyPersonalization(response.data.journey_personalization)
    })
}

export function updateButtonLabelForNode(nodeId: string, data: any) {
  return axios
    .post<JourneyPersonalization>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/update-journey-personalization-button-label-for-node/${nodeId}`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new JourneyPersonalization(response.data.journey_personalization)
    })
}

export function generateJourneyFlowPreview() {
  return axios
    .post<JourneyFlowPreviewResponse>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/generate-journey-flow-preview`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new JourneyFlowPreviewResponse(response.data)
    })
}

export function publishJourneyFlow() {
  return axios
    .post<JourneyFlowPreviewResponse>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/publish-journey-flow`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new JourneyFlowPreviewResponse(response.data)
    })
}

export function getJourneyPersonalization(uniqueId: string) {
  return axios
    .get<JourneyPersonalization>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/journey-personalizations/${uniqueId}`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new JourneyPersonalization(response.data.journey_personalization)
    })
}

export function updateJourneyPersonalizationVideoCrop(uniqueId: string, data: any) {
  return axios
    .post<JourneyPersonalization>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/journey-personalizations/${uniqueId}/update-video-crop`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new JourneyPersonalization(response.data.journey_personalization)
    })
}

export function removeVideoFromJourneyPersonalization(uniqueId: string) {
  return axios
    .post<JourneyPersonalization>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/journey-personalizations/${uniqueId}/remove-video`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new JourneyPersonalization(response.data.journey_personalization)
    })
}

export function getAllJourneyPersonalizations() {
  return axios
    .get<JourneyPersonalization[]>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/list-all-journey-personalizations`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return response.data.journey_personalizations.map(
        (journeyPersonalization: any) => new JourneyPersonalization(journeyPersonalization)
      )
    })
}

// Personalized poem

export function getPersonalizedPoems(obituaryUniqueId: string) {
  return axios
    .get(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/obituaries/${obituaryUniqueId}/personalized-poem`
    )
    .then((res) => res.data)
    .then((response: any) => {
      // We used to return an array, now we return the latest.
      if (!response.data.results) {
        console.warn('No results found for personalized poem.')
        return []
      }
      return response.data.results.map((x: any) => new PersonalizedPoem(x))
    })
}

export function generatePersonalizedPoem(obituaryUniqueId: string, data: any) {
  return axios
    .post(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/obituaries/${obituaryUniqueId}/generate-personalized-poem`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        personalized_poem: new PersonalizedPoem(response.data.derivative_work),
      }
    })
}

// Gratitude alphabet

export function getGratitudeAlphabets(obituaryUniqueId: string) {
  return axios
    .get(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/obituaries/${obituaryUniqueId}/gratitude-alphabet`
    )
    .then((res) => res.data)
    .then((response: any) => {
      // We used to return an array, now we return the latest.
      if (response.data.derivative_work) {
        return [new GratitudeAlphabet(response.data.derivative_work)]
      } else {
        return []
      }
      return response.data.results.map((x: any) => new GratitudeAlphabet(x))
    })
}

export function generateGratitudeAlphabet(obituaryUniqueId: string) {
  return axios
    .post(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/obituaries/${obituaryUniqueId}/generate-gratitude-alphabet`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        gratitude_alphabet: new GratitudeAlphabet(response.data),
      }
    })
}

// Inspirational passages (will POST data)

export function getInspirationalPassages(obituaryUniqueId: string) {
  return axios
    .get(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/obituaries/${obituaryUniqueId}/inspirational-passages`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        passages: response.data.results.map((x: any) => new InspirationalPassage(x)),
        scripture_options: response.data.scripture_options as SelectableOptionGroup[],
      }
    })
}

export function generateInspirationalPassages(obituaryUniqueId: string, data: any) {
  return axios
    .post(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/obituaries/${obituaryUniqueId}/generate-inspirational-passages`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return {
        inspirational_passage: new InspirationalPassage(response.data.derivative_work),
      }
    })
}

export function getAllAvailableFuneralHomes(queryString?: string): Promise<FuneralHome[]> {
  return axios
    .get(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/get-all-available-funeral-homes?q=${
        queryString || ''
      }`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return response.data.funeral_homes.map((funeralHome: any) => new FuneralHome(funeralHome))
    })
}

export function createFuneralHomeFromGooglePlaceId(googlePlaceId: string) {
  return axios
    .post<FuneralHome>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/funeral-homes/create-from-google-place-id`,
      {
        google_place_id: googlePlaceId,
      }
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new FuneralHome(response.data.client)
    })
}

export function createFuneralHomeFromAnyData(data: any) {
  return axios
    .post<FuneralHome>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/funeral-homes/create-from-data`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new FuneralHome(response.data.client)
    })
}

export function getDetailedClient(clientUniqueIdentifier: string) {
  return axios
    .get(`${process.env.REACT_APP_NEXT_API_URL}/admin/clients/${clientUniqueIdentifier}/detailed`)
    .then((res) => res.data)
    .then((response: any) => {
      return {
        client: new FuneralHome(response.data.client),
      }
    })
}

export function updateClientDetails(clientUniqueIdentifier: string, data: any) {
  return axios
    .post<FuneralHome>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/clients/${clientUniqueIdentifier}/update-details`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      return new FuneralHome(response.data.client)
    })
}

export function updateClientPaymentSettings(clientUniqueIdentifier: string, data: any) {
  return axios
    .post<FuneralHome>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/clients/${clientUniqueIdentifier}/update-payment-settings`,
      data
    )
    .then((res) => res.data)
    .then((response: any) => {
      // The user has the client with its updated payment settings.
      return new SimplifiedPhoenixUser(response.data.user)
    })
}

export function getFullClientPaymentSettings(clientUniqueIdentifier: string) {
  // This returns private data applicable to the admin, which we don't want
  // to include with every client serialization. Therefore the request is explicit.
  return axios
    .get<FuneralHomePaymentSetting>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/clients/${clientUniqueIdentifier}/full-payment-settings`
    )
    .then((res) => res.data)
    .then((response: any) => {
      // The user has the client with its updated payment settings.
      return new FuneralHomePaymentSettingForAdmin(response.data.payment_setting)
    })
}

export function getStripeConnectSetupLink(clientUniqueIdentifier: string) {
  return axios
    .get<string>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/clients/${clientUniqueIdentifier}/stripe-connect-setup-link`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return response.data.stripe_connect_setup_link
    })
}

export function getStripeConnectUpdateLink(clientUniqueIdentifier: string) {
  return axios
    .get<string>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/clients/${clientUniqueIdentifier}/stripe-connect-update-link`
    )
    .then((res) => res.data)
    .then((response: any) => {
      return response.data.stripe_connect_setup_link
    })
}

export function getClientWidgetCode(clientUniqueIdentifier: string) {
  return axios
    .get(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/clients/${clientUniqueIdentifier}/widget-code`
    )
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return responseData.data as {
        widget_script: string
      }
    })
}

export function getClientTeamMembers(clientUniqueIdentifier: string, query?: string) {
  return axios
    .get(
      `${
        process.env.REACT_APP_NEXT_API_URL
      }/admin/clients/${clientUniqueIdentifier}/team-members?q=${query || ''}`
    )
    .then((response) => {
      return response.data
    })
    .then((responseData) => {
      return responseData.data.results.map((x: any) => new FuneralHomePhoenixMembership(x))
    })
}

export function addTeamMemberToClient(clientUniqueIdentifier: string, data: any) {
  return axios
    .post<Obituary>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/clients/${clientUniqueIdentifier}/add-team-member`,
      data
    )
    .then((res) => res.data)
    .then((responseData: any) => {
      return responseData.data.results.map((x: any) => new FuneralHomePhoenixMembership(x))
    })
}

export function reinviteTeamMemberToClient(clientUniqueIdentifier: string, data: any) {
  return axios
    .post<Obituary>(
      `${process.env.REACT_APP_NEXT_API_URL}/admin/clients/${clientUniqueIdentifier}/reinvite-team-member`,
      data
    )
    .then((res) => res.data)
    .then((responseData: any) => {
      return responseData.data.results.map((x: any) => new FuneralHomePhoenixMembership(x))
    })
}
