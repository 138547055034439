import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useObituaryDetail } from './components/ObituaryDetail'
import usePageTitle from '../../hooks/usePageTitle'
import MerchandiseDetail from './MerchandiseDetail'

interface MerchandiseProps { }

function Merchandise({ }: MerchandiseProps) {
  const { obituary, obituaryWebsocket } = useObituaryDetail()

  useEffect(() => {
    if (obituaryWebsocket) {
      obituaryWebsocket.sendPageView('merchandise')
    }
  }, [obituaryWebsocket])

  usePageTitle({
    title: `${obituary.getSimpleName()}'s Obituary | Merchandise`,
  })

  if (!obituary.hasWrittenBiography()) {
    return (
      <div className='alert alert-warning'>
        <div className='alert-text font-weight-bold text-dark'>
          Before creating merchandise, you need to{' '}
          <Link
            to={`/obituaries/${obituary.unique_identifier}/write`}
            className='ms-1 me-1 text-dark text-decoration-underline'
          >
            write the obituary here
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className='row mt-2 mt-md-2'>
      <div className='col-lg-12'>
        <div className='row'>
          <div className='col-12'>
            <MerchandiseDetail />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Merchandise
