import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import fixWebmDuration from "fix-webm-duration";

const ActuallyRecordingActionsContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    height: 100px;
    bottom: 30px;
`

const ActuallyRecordingActionsContent = styled.div`
    width: 80vw;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const HintContainer = styled.div<{ $right?: boolean }>`
    position: absolute;
    left: ${({ $right }) => $right ? `unset` : `0`};
    right: ${({ $right }) => $right ? `0` : `unset`};

    color: white;
    text-align: center;
    opacity: 0.5;
`;

const ErrorUploadingContainer = styled.div`
    background: #090A0B;
    border-radius: 16px;
    padding: 8px 12px;
    margin: 16px;

    position: absolute;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white !important;
`;

const ElapsedTimeContainer = styled.div`
    background: #090A0B40;
    border-radius: 16px;
    padding: 8px 12px;
    margin: 16px;

    position: absolute;
    right: 0;

    width: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TimeElapsedContent = styled.span`
    font-size: 24px;
    color: white;
`;

const TimeSeparatorContent = styled.span`
    font-size: 24px;
    color: white;
    margin: 0 8px;
    opacity: 0.5;
`;

const MaxTimeContent = styled.span`
    font-size: 24px;
    color: white;
    opacity: 0.5;
`;

const RecordActionContainer = styled.div`
    height: 100%;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RecordingActionContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    text-align: center;
`;

const RecordContainer = styled.div<{ $disabled?: boolean }>`
    position: relative;

  height: 100%;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ $disabled }) => !$disabled ? `pointer` : `default`};
  opacity: ${({ $disabled }) => !$disabled ? `1` : `0.5`};

  transition: transform 0.2s ease-in-out;

  /* Make this a little bigger scale and animated on hover */

  &:hover {
    transform: ${({ $disabled }) => !$disabled ? `scale(1.1)` : `scale(1)`};
  }
`;

const RecordContainerOuterCircle = styled.div`
    scale: 0.8;
    margin-bottom: 10px;

  position: absolute;

  height: 75px;
  width: 75px;

  /*
  opacity of 0.4
*/
  background-color: #a64a55;
  border-radius: 50%;

  z-index: 1;
`;

const RecordContainerInnerCircle = styled.div`
    position: absolute;
    left: 29px;
    top: 29px;
    height: 57px;
    width: 57px;
    background: #fa536e;
    border-radius: 50%;
    margin: -20px 0px 0px -20px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RecordContainerInnerCircleIsClicked = styled(RecordContainerInnerCircle)`
  height: 38px;
  width: 38px;
  margin: -19px 0px 0px -19px;
`;

const IsRecordingInnerStopSquare = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: white;
`;

function PersonalizeRecordingActions({
    userMediaStream,
    onCanceled,
    onReRecord,
    onReceivedVideoUrl,
    uploading,
    onClickedUpload,
    errorUploading,
    shouldSkipCountdown,
    setShouldSkipCountdown,
}: {
    userMediaStream: MediaStream,
    onCanceled: () => void,
    onReRecord: () => void,
    onReceivedVideoUrl: (video: { url: string, blob: Blob }) => void
    uploading: boolean,
    onClickedUpload: () => void
    errorUploading: string | null,
    shouldSkipCountdown: boolean,
    setShouldSkipCountdown: (shouldSkipCountdown: boolean) => void,
}) {

    const isClicked = false;

    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const recordedChunksRef = useRef<any[]>([]);

    const [timeElapsed, setTimeElapsed] = useState(0);

    const [isStopped, setIsStopped] = useState(false);

    const startedRecordingAtTimeRef = useRef<number | null>(null);
    const stoppedRecordingAtTimeRef = useRef<number | null>(null);

    // May want to conditionally show the "Skip Count-down" checkbox
    const [_reRecordedCount, setReRecordedCount] = useState(0);

    const formatSecondsToMinutesAndSeconds = (seconds: number) => {
        // This must have padded 0s for the minutes and seconds
        const minutes = Math.floor(seconds / 60);
        const secondsLeft = seconds % 60;
        const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const paddedSeconds = secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft;
        return `${paddedMinutes}:${paddedSeconds}`;
    }

    useEffect(() => {
        let recordOptions = {
            mimeType: "video/webm; codecs=vp9"
        }; // You can also use 'vp9'
        if (MediaRecorder.isTypeSupported(recordOptions.mimeType)) {
            // Down-grade to 'vp8' if 'vp9' isn't supported
            recordOptions = {
                mimeType: "video/webm; codecs=vp8"
            };
            if (!MediaRecorder.isTypeSupported(recordOptions.mimeType)) {
                // Should probably throw an error...
                // instead we'll just make the mimeType undefined
                recordOptions = {
                    mimeType: ""
                };
            }
        }
        mediaRecorderRef.current = new MediaRecorder(userMediaStream, recordOptions);
        mediaRecorderRef.current.ondataavailable = (event) => {
            if (event.data.size > 0) {
                recordedChunksRef.current.push(event.data);
            }
        };
        mediaRecorderRef.current.onstop = (event) => {
            stoppedRecordingAtTimeRef.current = Date.now();
        };
        mediaRecorderRef.current.start(1000);
        startedRecordingAtTimeRef.current = Date.now();
    }, [userMediaStream]);

    useEffect(() => {

        const interval = setInterval(() => {
            setTimeElapsed(prev => prev + 1);
        }, 1000);

        if (isStopped) {
            clearInterval(interval);
            return;
        }

        return () => {
            clearInterval(interval);
        }

    }, [isStopped]);

    const onClickedRecordButton = useCallback(() => {
        if (!isStopped) {
            setIsStopped(true);
            mediaRecorderRef.current?.stop();
            const duration = (stoppedRecordingAtTimeRef.current || Date.now()) - (startedRecordingAtTimeRef.current || Date.now());
            const recordedBlob = new Blob(recordedChunksRef.current, { type: "video/webm" });
            fixWebmDuration(recordedBlob, duration, function (fixedBlob) {
                onReceivedVideoUrl({
                    url: URL.createObjectURL(fixedBlob),
                    blob: fixedBlob
                });
            });
        } else {
            onReRecord();
            setReRecordedCount(prev => prev + 1);
        }
    }, [isStopped, onReRecord, onReceivedVideoUrl]);

    useEffect(() => {
        // Handle all keypresses. Disable all of them
        const handleKeyPress = (e: KeyboardEvent) => {
            e.preventDefault();
            if (e.key === ' ') {
                onClickedRecordButton();
            }
        }
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        }
    }, [onClickedRecordButton]);

    return <ActuallyRecordingActionsContainer>
        <ActuallyRecordingActionsContent>
            <div style={{ width: 160, ...(isStopped ? { display: 'block' } : { display: 'none' }) }} />
            <RecordActionContainer>
                <button type="button" className='btn btn-dark btn-sm' onClick={(e) => {
                    e.preventDefault();
                    mediaRecorderRef.current?.stop(); // Assume you add a stop method to the MediaRecorder
                    onCanceled();
                }} disabled={uploading}>
                    Cancel
                </button>
            </RecordActionContainer>
            <RecordContainer $disabled={!!uploading}>
                <RecordContainerOuterCircle
                    onClick={(e) => {
                        e.preventDefault();
                        if (uploading) {
                            return;
                        }
                        onClickedRecordButton();
                    }}
                >
                    <RecordContainerInnerCircle as={isClicked ? RecordContainerInnerCircleIsClicked : RecordContainerInnerCircle}>
                        {!isStopped ? <IsRecordingInnerStopSquare /> : <div />}
                    </RecordContainerInnerCircle>
                </RecordContainerOuterCircle>
                <RecordingActionContainer>
                    {!isStopped ? `Stop` : `Re-Record`}
                </RecordingActionContainer>
            </RecordContainer>
            <RecordActionContainer>
                <button type="button" className='btn btn-primary btn-sm' onClick={(e) => {
                    e.preventDefault();
                    onClickedUpload();
                }} disabled={uploading || !isStopped}>
                    {uploading ? 'Uploading...' : 'Upload'}
                </button>
            </RecordActionContainer>
            {false ? <div style={{ color: 'white', width: 160, fontSize: 14, textAlign: 'center', ...(isStopped ? { display: 'block' } : { display: 'none' }) }}>
                <label htmlFor="skip-countdown">
                    Skip Count-down
                    <input type="checkbox" id="skip-countdown" className='form-check-input' style={{ marginLeft: 8 }} checked={shouldSkipCountdown} onChange={(e) => {
                        setShouldSkipCountdown(e.target.checked);
                    }} />
                </label>
            </div> : <div style={{ width: 160, ...(isStopped ? { display: 'block' } : { display: 'none' }) }} />}
            {!uploading && <HintContainer>
                Hint: Press the spacebar to toggle recording
            </HintContainer>}
            {errorUploading ? <ErrorUploadingContainer>
                {errorUploading}
            </ErrorUploadingContainer> : <>
                {!isStopped ? <ElapsedTimeContainer>
                    <TimeElapsedContent>
                        {formatSecondsToMinutesAndSeconds(timeElapsed)}
                    </TimeElapsedContent>
                    <TimeSeparatorContent>
                        /
                    </TimeSeparatorContent>
                    <MaxTimeContent>
                        05:00
                    </MaxTimeContent>
                </ElapsedTimeContainer> : <HintContainer $right={true}>
                    You will be able to crop on the next screen
                </HintContainer>}
            </>}
        </ActuallyRecordingActionsContent>
    </ActuallyRecordingActionsContainer >
}

export function PersonalizeRecordingActionsAsDisabledItems({ onCanceled }: { onCanceled: () => void }) {

    return <ActuallyRecordingActionsContainer>
        <ActuallyRecordingActionsContent>
            <RecordActionContainer>
                <button type="button" className='btn btn-dark btn-sm' onClick={(e) => {
                    e.preventDefault();
                    onCanceled();
                }}>
                    Cancel
                </button>
            </RecordActionContainer>
            <RecordContainer $disabled={true}>
                <RecordContainerOuterCircle
                    onClick={(e) => {
                    }}
                >
                    <RecordContainerInnerCircle>
                    </RecordContainerInnerCircle>
                </RecordContainerOuterCircle>
                <RecordingActionContainer>
                    Stop
                </RecordingActionContainer>
            </RecordContainer>
            <RecordActionContainer>
                <button type="button" className='btn btn-primary btn-sm' disabled>
                    Upload
                </button>
            </RecordActionContainer>
            <HintContainer>
                Hint: Press the spacebar to toggle recording
            </HintContainer>
            <ElapsedTimeContainer>
                <TimeElapsedContent>
                    00:00
                </TimeElapsedContent>
                <TimeSeparatorContent>
                    /
                </TimeSeparatorContent>
                <MaxTimeContent>
                    05:00
                </MaxTimeContent>
            </ElapsedTimeContainer>
        </ActuallyRecordingActionsContent>
    </ActuallyRecordingActionsContainer>
}

export default PersonalizeRecordingActions;