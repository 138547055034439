/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import PrivateUserRoutes from './PrivateUserRoutes'
import PrivateFuneralHomeRoutes from './PrivateFuneralHomeRoutes'
import PrivateSelectUserTypeRoutes from './PrivateSelectUserTypeRoutes'
import PrivateSelectFuneralHomeRoutes from './PrivateSelectFuneralHomeRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { App } from '../App'
import PrivateFuneralHomeWaitingRoutes from './PrivateFuneralHomeWaitingRoutes'
import PrivateAdminRoutes from './PrivateAdminRoutes'
import ShareableView from '../obituaries/ShareableView'
import { AuthPage } from '../modules/auth/AuthPage'
import { Logout } from '../modules/auth/Logout'
import { useAuth } from '../modules/auth/core/Auth'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

function OldRoutes() {
  // Here for reference

  const { currentPhoenixUserPhone, currentPhoenixUser } = useAuth()

  return <>
    {(currentPhoenixUserPhone || currentPhoenixUser) ? (
      <>
        {currentPhoenixUserPhone?.is_admin && <Route path='admin/*' element={<PrivateAdminRoutes />} />}
        {!currentPhoenixUserPhone?.hasSelectedUserType() ? (
          <>
            <Route path='/*' element={<PrivateSelectUserTypeRoutes />} />
            <Route index element={<Navigate to='/onboarding' />} />
          </>
        ) : (
          <>
            {currentPhoenixUserPhone.isFuneralHomeUser() &&
              !currentPhoenixUserPhone.hasFuneralHomeMembership() &&
              !currentPhoenixUserPhone.is_admin ? (
              <>
                {/* Force the "non-other" user to select a funeral home. */}
                <Route path='/*' element={<PrivateSelectFuneralHomeRoutes />} />
                <Route index element={<Navigate to='/select-funeral-home' />} />
              </>
            ) : (
              <>
                {currentPhoenixUserPhone.isFuneralHomeUser() ? (
                  <>
                    {!currentPhoenixUserPhone.is_admin && currentPhoenixUserPhone.isWaitingForApproval() ? (
                      <>
                        <Route path='/*' element={<PrivateFuneralHomeWaitingRoutes />} />
                        <Route index element={<Navigate to='/waiting' />} />
                      </>
                    ) : (
                      <>
                        <Route path='/*' element={<PrivateFuneralHomeRoutes />} />
                        <Route index element={<Navigate to='/dashboard' replace />} />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Route path='/*' element={<PrivateUserRoutes />} />
                    <Route index element={<Navigate to='/obituaries' />} />
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    ) : (
      <>
        {(currentPhoenixUser || currentPhoenixUserPhone) ? <>
          <Route path='/*' element={<PrivateUserRoutes />} />
          <Route index element={<Navigate to='/obituaries' />} />
        </> : <>
          <Route path='auth/*' element={<AuthPage />} />
          <Route path='*' element={<Navigate to='/auth' />} />
        </>}
      </>
    )}
  </>
}

const AppRoutes: FC = () => {
  const { currentPhoenixUserPhone, currentPhoenixUser } = useAuth()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='o/*' element={<ShareableView />} />
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {(currentPhoenixUser || currentPhoenixUserPhone) ? <>
            {(currentPhoenixUser?.is_admin || currentPhoenixUserPhone?.is_admin) && <Route path='admin/*' element={<PrivateAdminRoutes />} />}
            {(currentPhoenixUser?.is_admin || currentPhoenixUser?.has_funeral_home_access) ? <>
              {/* These could be used to the consumer-facing front-end... */}
              {/*
              <Route path='/*' element={<PrivateUserRoutes />} />
              <Route index element={<Navigate to='/obituaries' />} />
            */}
              <Route path='/*' element={<PrivateFuneralHomeRoutes />} />
              <Route index element={<Navigate to='/dashboard' replace />} />
            </> : <>
              {/* Send to the generic /o/ page if they're not an admin or funeral home user */}
              <Route path='/*' element={<Navigate to='/o/' />} />
            </>}
          </> : <>
            <Route path='auth/*' element={<AuthPage />} />
            <Route path='*' element={<Navigate to='/auth' />} />
          </>}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
