/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { PageTitle } from '../../../_metronic/layout/core'
import { ApiError } from '../../modules/auth'
import { createFuneralHomeFromAnyData, createFuneralHomeFromGooglePlaceId } from '../../funeral_homes/dashboard/core/_requests'

export interface INewFuneralHomeFormProps {
  name: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zipCode: string
  website: string
  phoneNumber: string
  emailAddress: string
}

const newFuneralHomeSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  addressLine1: Yup.string().required('Address line 1 is required'),
  addressLine2: Yup.string(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipCode: Yup.string().required('Zip code is required'),
  website: Yup.string(),
  phoneNumber: Yup.string().required('Phone number is required'),
  emailAddress: Yup.string(),
})

const GOOGLE_PLACES_API_KEY = 'AIzaSyD0oAGfnQSBR5aHMMBRuFS5UEznMhxoIOY'

interface GooglePlaceSelectOption {
  label: string
  value: {
    place_id: string
    description: string
  }
}

function CreateFuneralHomeForm({ onCancel }: { onCancel: () => void }) {

  const [submitting, setSubmitting] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | null>(null)

  const formik = useFormik<INewFuneralHomeFormProps>({
    initialValues: {
      name: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      website: '',
      phoneNumber: '',
      emailAddress: '',
    },
    validationSchema: newFuneralHomeSchema,
    onSubmit: async (values) => {
      if (submitting) {
        return
      }
      setSubmitting(true)
      setError(null)
      try {
        const result = await createFuneralHomeFromAnyData(values)
        // Hard reload for now:
        window.location.href = `/admin/clients/${result.unique_identifier}/overview`
      } catch (error: any) {
        const errorMessage =
          error instanceof ApiError ? error.message : 'Something went wrong, please try again'
        console.warn(error)
        setError(errorMessage)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='card card-custom shadow-sm'>
      <div className='card-header'>
        <div className='card-title'>
          <h3 className='card-label'>Create Funeral Home</h3>
        </div>
      </div>
      <div className='card-body'>
        <form onSubmit={formik.handleSubmit}>
          <div className='row g-5'>
            <div className='col-12'>
              <label className='form-label'>Funeral Home Name: *</label>
              <input
                type='text'
                className='form-control'
                name='name'
                onChange={formik.handleChange}
                value={formik.values.name}
                autoFocus
                required
              />
              {formik.errors.name && formik.touched.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.name}</div>
                </div>
              )}
            </div>
            <div className='col-6'>
              <label className='form-label'>Address Line 1: *</label>
              <input
                type='text'
                className='form-control'
                name='addressLine1'
                onChange={formik.handleChange}
                value={formik.values.addressLine1}
                required
              />
              {formik.errors.addressLine1 && formik.touched.addressLine1 && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.addressLine1}</div>
                </div>
              )}
            </div>
            <div className='col-6'>
              <label className='form-label'>Address Line 2:</label>
              <input
                type='text'
                className='form-control'
                name='addressLine2'
                onChange={formik.handleChange}
                value={formik.values.addressLine2}
              />
              {formik.errors.addressLine2 && formik.touched.addressLine2 && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.addressLine2}</div>
                </div>
              )}
            </div>
            <div className='col-4'>
              <label className='form-label'>City: *</label>
              <input
                type='text'
                className='form-control'
                name='city'
                onChange={formik.handleChange}
                value={formik.values.city}
                required
              />
              {formik.errors.city && formik.touched.city && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.city}</div>
                </div>
              )}
            </div>
            <div className='col-4'>
              <label className='form-label'>State: *</label>
              <input
                type='text'
                className='form-control'
                name='state'
                onChange={formik.handleChange}
                value={formik.values.state}
                required
              />
              {formik.errors.state && formik.touched.state && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.state}</div>
                </div>
              )}
            </div>
            <div className='col-4'>
              <label className='form-label'>Zip Code: *</label>
              <input
                type='text'
                className='form-control'
                name='zipCode'
                onChange={formik.handleChange}
                value={formik.values.zipCode}
                required
              />
              {formik.errors.zipCode && formik.touched.zipCode && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.zipCode}</div>
                </div>
              )}
            </div>
            <div className='col-4'>
              <label className='form-label'>Phone Number: *</label>
              <input
                type='text'
                className='form-control'
                name='phoneNumber'
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
                required
              />
              {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phoneNumber}</div>
                </div>
              )}
            </div>
            <div className='col-4'>
              <label className='form-label'>Email Address:</label>
              <input
                type='email'
                className='form-control'
                name='emailAddress'
                onChange={formik.handleChange}
                value={formik.values.emailAddress}
              />
              {formik.errors.emailAddress && formik.touched.emailAddress && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.emailAddress}</div>
                </div>
              )}
            </div>
            <div className='col-4'>
              <label className='form-label'>Website:</label>
              <input
                type='text'
                className='form-control'
                name='website'
                onChange={formik.handleChange}
                value={formik.values.website}
              />
              {formik.errors.website && formik.touched.website && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.website}</div>
                </div>
              )}
            </div>
          </div>
          <div className='separator separator-dashed my-10'></div>
          {error && (
            <div>
              <div className='alert alert-danger' role='alert'>
                <div className='alert-text'>{error}</div>
              </div>
            </div>
          )}
          <div className='d-flex justify-content-center mt-5'>
            <button type='submit' className='btn btn-primary btn-lg' disabled={submitting}>
              {submitting ? 'Continuing...' : 'Continue'}
            </button>
          </div>
          <div className='d-flex justify-content-center mt-5'>
            <button
              className='btn btn-link'
              onClick={() => {
                onCancel()
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

function SelectFuneralHome({
  title,
  subtitle = '',
  placeholder = '',
  isAdminRoute = false,
}: {
  title: string
  subtitle?: string
  placeholder?: string,
  isAdminRoute?: boolean
}) {

  const [savingPlace, setSavingPlace] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | null>(null)

  const [searchingForAFuneralHome, setSearchingForAFuneralHome] = React.useState<boolean>(true)

  const [selectedFuneralHomePlace, setSelectedFuneralHomePlace] =
    React.useState<GooglePlaceSelectOption | null>(null)

  const onSaveFuneralHomePlace = useCallback(async () => {
    if (!selectedFuneralHomePlace) {
      return
    }
    setSavingPlace(true)
    setError(null)
    try {
      const createdFuneralHome = await createFuneralHomeFromGooglePlaceId(selectedFuneralHomePlace.value.place_id)
      if (isAdminRoute) {
        window.location.href = `/admin/clients/${createdFuneralHome.unique_identifier}/overview`
      } else {
        // We only setSavingPlace to false if the user is not redirected to the next page
        setSavingPlace(false)
      }
    } catch (error: any) {
      const errorMessage =
        error instanceof ApiError ? error.message : 'Something went wrong, please try again'
      console.warn(error)
      setError(errorMessage)
      setSavingPlace(false)
    }
  }, [isAdminRoute, selectedFuneralHomePlace])

  const onFuneralHomeNotListed = useCallback(async () => {
    setSearchingForAFuneralHome(false)
  }, [])

  if (!searchingForAFuneralHome) {
    return (
      <CreateFuneralHomeForm
        onCancel={() => {
          setSearchingForAFuneralHome(true)
        }}
      />
    )
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          {title}
          {subtitle && <span className='fs-6 text-gray-400 fw-bold ms-4'></span>}
        </h3>
      </div>

      <div className='row g-5'>
        <div className='col-12'>
          <label className='form-label'>Funeral Home:</label>
          <GooglePlacesAutocomplete
            apiKey={GOOGLE_PLACES_API_KEY}
            autocompletionRequest={{
              types: ['establishment'],
            }}
            selectProps={{
              placeholder: placeholder || 'Search for a funeral home....',
              onChange: (value: any) => {
                setSelectedFuneralHomePlace(value)
              },
              autoFocus: true,
              styles: {
                control: (provided: any) => ({
                  ...provided,
                  fontSize: 24,
                }),
                input: (provided: any) => ({
                  ...provided,
                  fontSize: 24,
                }),
                option: (provided: any) => ({
                  ...provided,
                  fontSize: 24,
                }),
              },
            }}
          />
        </div>
      </div>
      <div className='separator separator-dashed my-10'></div>
      <div className='d-flex justify-content-center mt-5'>
        <button
          type='button'
          className='btn btn-primary btn-lg'
          disabled={savingPlace || !selectedFuneralHomePlace}
          onClick={() => {
            onSaveFuneralHomePlace()
          }}
        >
          {!savingPlace ? 'Continue' : 'Saving...'}
        </button>
      </div>
      {true && (
        <div className='d-flex justify-content-center mt-5'>
          <button
            className='btn btn-link'
            onClick={() => {
              onFuneralHomeNotListed()
            }}
          >
            Funeral home not listed?
          </button>
        </div>
      )}
      {error && (
        <div className='row'>
          <div className='col-md-6 col-xl-4 offset-md-3 offset-xl-4'>
            <div className='alert alert-danger mt-4' role='alert'>
              <div className='alert-text text-center'>{error}</div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const SelectFuneralHomeWrapper = ({
  title,
  subtitle = '',
  placeholder = '',
  isAdminRoute = false,
}: {
  title: string
  subtitle?: string
  placeholder?: string
  isAdminRoute?: boolean
}) => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Onboarding</PageTitle>
      <SelectFuneralHome
        title={title}
        subtitle={subtitle}
        placeholder={placeholder}
        isAdminRoute={isAdminRoute}
      />
    </>
  )
}

export default SelectFuneralHomeWrapper
