import type { Identifier, XYCoord } from 'dnd-core'
import { DateTime } from 'luxon'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import ReactQuill from 'react-quill'
import styled from 'styled-components'

import { Obituary, Section, SectionRevision, SectionSummary } from '../core/_models'
import {
  summarizeObituarySection,
  updateObituarySection,
  writeObituarySection,
  writeObituaryFamilyMembers,
  writeServicesAndCondolences,
  writeObituarySectionForBiography,
} from '../core/_requests'
import { ApiError } from '../../auth'
import { Link } from 'react-router-dom'
import { ISectionsBeingGenerated } from '../../../obituaries/useSimpleSectionWriteUpdate'
import { SimpleUserModel } from '../../auth/core/_models'
import ProgressBarInSeconds from '../ProgressBarInSeconds'
import ToneSelector, { ToneSelectionOption } from './ToneSelector'
import { useAuth } from '../../auth/core/Auth'
import useObituaryChannel from '../../../obituaries/next/useObituaryChannel'
import handlePaste from '../../../hooks/handleQuillPaste'

export const ItemTypes = {
  CARD: 'card',
}

interface DragItem {
  index: number
  section: Section
}

const QuillContainer = styled.div`
  width: 100%;

  .ql-container {
    border-radius: 10px;
  }
  .ql-editor {
    font-size: 1.5rem;
  }

  p {
    margin-bottom: 20px;
  }

  p:last-child {
    margin-bottom: 0;
  }
`


function SectionSummaryElement({ summary }: { summary: SectionSummary }) {
  return (
    <>
      <strong>{`Summary in ${summary.summary_in_words} word${summary.summary_in_words !== 1 ? 's' : ''
        }:`}</strong>
      <div>{summary.summary_text}</div>
    </>
  )
}

interface SummarizeSectionProps {
  obituary: Obituary
  setObituary: (obituary: Obituary) => void
  sectionRevision: SectionRevision
  setConfirmGenerateSummary: (confirmGenerateSummary: boolean) => void
  onCompletedSummary: (summary: SectionSummary, section: Section) => void
  disableButtons?: boolean
}

function SummarizeSection({
  obituary,
  setObituary,
  sectionRevision,
  setConfirmGenerateSummary,
  onCompletedSummary,
  disableButtons = false,
}: SummarizeSectionProps) {
  const {
    auth
  } = useAuth();

  const [performingSummary, setPerformingSummary] = useState(false)

  const [summarizeInWords, setSummarizeInWords] = useState<string>('10')
  const [customWordCount, setCustomWordCount] = useState<string>('')
  const [errorSummarizing, setErrorSummarizing] = useState<string | null>(null)

  const [activeSummaryText, setActiveSummaryText] = useState<string>('')

  const onPerformSummary = useCallback(async () => {
    setActiveSummaryText('')
    let wordCount = parseInt(summarizeInWords, 10)
    if (summarizeInWords === 'custom') {
      if (customWordCount === '') {
        return
      }
      wordCount = parseInt(customWordCount, 10)
    }
    setPerformingSummary(true)
    setErrorSummarizing(null)
    try {
      // No time to keep track of state, just winging it.
      await summarizeObituarySection(
        auth?.api_token || '',
        obituary.unique_identifier,
        sectionRevision.unique_identifier,
        wordCount,
        (token) => {
          setActiveSummaryText((prev) => prev + token)
        }
      )
    } catch (e) {
      console.warn('e', e)
      const errorMessage = e instanceof ApiError ? e.message : 'Unable to summarize section (unknown error)'
      setErrorSummarizing(errorMessage)
    } finally {
      setPerformingSummary(false)
    }
  }, [summarizeInWords, customWordCount, auth?.api_token, obituary.unique_identifier, sectionRevision.unique_identifier])

  return (
    <>
      <div className='d-flex flex-row align-items-center'>
        <div className='ms-4 me-4'>How many words would you like to summarize to? </div>
        {summarizeInWords !== 'custom' ? (
          <select
            className='form-select form-select-sm w-100px me-4'
            value={summarizeInWords}
            onChange={(e) => setSummarizeInWords(e.target.value)}
            disabled={performingSummary}
          >
            <option value='100'>100</option>
            <option value='50'>50</option>
            <option value='25'>25</option>
            <option value='10'>10</option>
            <option value='5'>5</option>
            <option value='custom'>Custom</option>
          </select>
        ) : (
          <input
            className='form-control form-control-sm w-150px me-4'
            type='number'
            value={customWordCount}
            onChange={(e) => setCustomWordCount(e.target.value)}
            autoFocus
            placeholder='Enter a number...'
            disabled={performingSummary}
          />
        )}
        <button
          type='button'
          className='btn btn-secondary btn-sm me-2'
          onClick={() => {
            setConfirmGenerateSummary(false)
          }}
          disabled={performingSummary}
        >
          Cancel
        </button>
        <button
          type='button'
          className='btn btn-primary btn-sm'
          onClick={() => {
            onPerformSummary()
          }}
          disabled={disableButtons || performingSummary}
        >
          {!performingSummary ? 'Summarize' : 'Summarizing...'}
        </button>
      </div>
      {errorSummarizing && <div className='mt-4'>
        <div className='alert alert-danger'>{errorSummarizing}</div>
      </div>}
      {activeSummaryText && <>
        <div className='mt-4'>
          {activeSummaryText}
        </div>
      </>}
    </>
  )
}
export function replaceNewLinesWithParagraphs(text: string) {
  return `${text || ''}`
    .split('\n\n')
    .flatMap((doubleNewLine) =>
      doubleNewLine.split('\n').map((singleNewLine) => {
        return `<p class='user-paragraph'>${singleNewLine}</p>`
      })
    )
    .join('')
}

export interface ObituaryWriteSectionProps {
  obituary: Obituary
  setObituary: (obituary: Obituary) => void
  index: number
  section: Section
  moveCard: (dragIndex: number, hoverIndex: number) => void
  sectionBackgroundColor: string
  linkToUpdateBio: string
  linkToRelatives: string
  linkToServiceDetails: string
  sectionsBeingGenerated: ISectionsBeingGenerated
  isShareableObituary?: boolean
}

const ObituaryWriteSection: FC<ObituaryWriteSectionProps> = ({
  obituary,
  setObituary,
  index,
  section: defaultSection,
  moveCard,
  sectionBackgroundColor,
  linkToUpdateBio,
  linkToRelatives,
  linkToServiceDetails,
  sectionsBeingGenerated,
  isShareableObituary = false,
}) => {

  const {
    obituaryChannel
  } = useObituaryChannel()

  const [sectionIsBeingWritten, setSectionIsBeingWritten] = useState(false)
  const [textBeingWritten, setTextBeingWritten] = useState('')

  // We are probably close to being ready to completely refactor this..

  ///

  const sectionBeingGeneratedInfo: { status: string; message: string; user: SimpleUserModel } | null =
    sectionsBeingGenerated[defaultSection.unique_id] ?? null

  const disableButtons =
    sectionBeingGeneratedInfo !== null && sectionBeingGeneratedInfo.status !== 'finished'

  ///

  const [section, setSection] = useState<Section>(defaultSection)

  const sectionTextAsHtml = useMemo(() => {
    return replaceNewLinesWithParagraphs(section.latest_revision?.text_content || '')
  }, [section.latest_revision?.text_content])

  const [editorText, setEditorText] = useState<string>(sectionTextAsHtml)

  const [writing, setWriting] = useState(false)
  const [savingRevision, setSavingRevision] = useState(false)
  const [editing, setEditing] = useState(section.latest_revision?.text_content === '')

  const [confirmGenerateSummary, setConfirmGenerateSummary] = useState(false)

  const [selectedSectionSummary, setSelectedSectionSummary] = useState<SectionSummary | null>(null)

  const [generationError, setGenerationError] = useState<string | null>(null)
  const [updateError, setUpdateError] = useState<string | null>(null)

  const [viewingPreviousSummaries, setViewingPreviousSummaries] = useState(false)

  const [biographyTone, setBiographyTone] = useState<ToneSelectionOption | null>(null)

  const dragElementRef = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!dragElementRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dragElementRef.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id: section.unique_id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(dragElementRef))

  const onWriteSection = useCallback(async () => {
    setWriting(true)
    setGenerationError(null)
    try {
      /*
      const { section: updatedSection, obituary: updatedObituary } =
        section.key === 'biography_personal'
          ? await writeObituarySectionForBiography(obituary.unique_identifier, section.unique_id, {
            biography_tone: biographyTone?.value,
          })
          : section.key === 'family_members'
            ? await writeObituaryFamilyMembers(obituary.unique_identifier, section.unique_id)
            : section.key === 'detail_services'
              ? await writeServicesAndCondolences(obituary.unique_identifier, section.unique_id)
              : await writeObituarySection(obituary.unique_identifier, section.unique_id)
      setSection(updatedSection)
      if (!updatedSection.can_be_generated_with_ai) {
        setEditing(true)
      }
      setEditorText(
        replaceNewLinesWithParagraphs(updatedSection.latest_revision?.text_content || '')
      )
      setObituary(updatedObituary)
      */
      // Now we have websockets with streaming of the AI response, so
      // we don't do anything upon success.
      section.key === 'biography_personal'
        ? await writeObituarySectionForBiography(obituary.unique_identifier, section.unique_id, {
          biography_tone: biographyTone?.value,
        })
        : section.key === 'family_members'
          ? await writeObituaryFamilyMembers(obituary.unique_identifier, section.unique_id)
          : section.key === 'detail_services'
            ? await writeServicesAndCondolences(obituary.unique_identifier, section.unique_id)
            : await writeObituarySection(obituary.unique_identifier, section.unique_id)
      return;
    } catch (error: any) {
      console.warn(error)
      const errorMessage =
        error instanceof ApiError ? error.message : 'Unable to write section (unknown error)'
      setGenerationError(errorMessage)
      setWriting(false)
    }
  }, [obituary.unique_identifier, section.key, section.unique_id, biographyTone])

  const onSaveSection = useCallback(async (revisionUniqueId: string) => {
    // We make sure to pass in `revisionUniqueId` in case a new revision
    // has been created and a user's state is out of sync.
    setSavingRevision(true)
    setUpdateError(null)
    try {
      const { obituary: updatedObituary } = await updateObituarySection(
        obituary.unique_identifier,
        revisionUniqueId,
        {
          text_content: editorText,
        }
      )
      const matchingSection = updatedObituary.active_sections.find(
        (s) => s.unique_id === section.unique_id
      )
      if (matchingSection) {
        setSection(matchingSection)
        setEditorText(
          replaceNewLinesWithParagraphs(matchingSection.latest_revision?.text_content || '')
        )
      } else {
        console.warn('Unable to find matching section in updated obituary')
      }
      setEditing(false)
      setObituary(updatedObituary)
    } catch (e) {
      console.error(e)
      const errorMessage = e instanceof ApiError ? e.message : 'Unable to save section (unknown error)'
      setUpdateError(errorMessage)
    } finally {
      setSavingRevision(false)
    }
  }, [obituary.unique_identifier, editorText, setObituary, section.unique_id])

  useEffect(() => {
    if (!obituaryChannel) {
      return;
    }
    // This should be somewhere else, but for speed it's here
    if (section.key === 'biography_personal') {
      obituaryChannel.on('started_writing_biography', (msg) => {
        setSectionIsBeingWritten(true)
        setTextBeingWritten('...')
      })
      obituaryChannel.on('biography_writing_update', (msg) => {
        if (msg.payload?.text_content) {
          setTextBeingWritten(replaceNewLinesWithParagraphs(msg.payload.text_content))
        }
      })
      obituaryChannel.on('finished_writing', (msg) => {
        if (msg?.payload?.update_type === 'biography_writing_update') {
          setSectionIsBeingWritten(false)
          setWriting(false)
          setEditing(false)
          setSection((prev) => {
            return new Section({
              ...prev,
              latest_revision: msg.payload.section_revision
            })
          })
          setEditorText(
            replaceNewLinesWithParagraphs(msg.payload.section_revision.text_content || '')
          )
        }
      })
    }
    if (section.key === 'family_members') {
      obituaryChannel.on('started_writing_family', (msg) => {
        setSectionIsBeingWritten(true)
        setTextBeingWritten('...')
      })
      obituaryChannel.on('family_writing_update', (msg) => {
        if (msg.payload?.text_content) {
          setTextBeingWritten(replaceNewLinesWithParagraphs(msg.payload.text_content))
        }
      })
      obituaryChannel.on('finished_writing', (msg) => {
        if (msg?.payload?.update_type === 'family_writing_update') {
          setSectionIsBeingWritten(false)
          setWriting(false)
          setEditing(false)
          setSection((prev) => {
            return new Section({
              ...prev,
              latest_revision: msg.payload.section_revision
            })
          })
          setEditorText(
            replaceNewLinesWithParagraphs(msg.payload.section_revision.text_content || '')
          )
        }
      })
    }
  }, [obituaryChannel, section, section.key])

  return (
    <>
      {index > 0 && <div className='separator separator-dashed my-5'></div>}
      <div
        ref={preview}
        style={{ opacity, backgroundColor: sectionBackgroundColor }}
        data-handler-id={handlerId}
        className='ps-1 pe-3 px-md-4 py-4 mb-md-2'
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {false && <div ref={dragElementRef} style={{ cursor: 'move', padding: 10 }} title='Move section'>
            <i className='fas fa-bars fa-2x' />
          </div>}
          <div className='ps-5 w-100'>
            <div>
              <label className='form-label'>{section.title}</label>
            </div>
            {sectionIsBeingWritten ? <>
              <div
                style={{ fontSize: '1.5rem' }}
                dangerouslySetInnerHTML={{
                  __html: textBeingWritten,
                }} />
            </> : <>
              <div>
                {section.latest_revision ? (
                  <>
                    {!editing ? (
                      <>
                        <div
                          style={{ fontSize: '1.5rem' }}
                          dangerouslySetInnerHTML={{
                            __html: sectionTextAsHtml,
                          }}
                        />
                        {selectedSectionSummary && (
                          <div>
                            <div className='separator separator-dashed my-5'></div>
                            <SectionSummaryElement summary={selectedSectionSummary} />
                            <div className='separator separator-dashed my-5'></div>
                          </div>
                        )}
                        {confirmGenerateSummary ? (
                          <SummarizeSection
                            obituary={obituary}
                            setObituary={setObituary}
                            sectionRevision={section.latest_revision}
                            setConfirmGenerateSummary={setConfirmGenerateSummary}
                            onCompletedSummary={(summary, updatedSection) => {
                              setSelectedSectionSummary(summary)
                              setConfirmGenerateSummary(false)
                              setSection(updatedSection)
                            }}
                            disableButtons={disableButtons}
                          />
                        ) : <></>}
                        <div className='d-flex align-items-center justify-content-between'>
                          {section.can_be_summarized_with_ai_in_words && !confirmGenerateSummary && (
                            <div>
                              {section.active_summaries.length > 0 &&
                                false &&
                                'existingSummariesAreConfusingSoDisabling' ? (
                                <>
                                  <div className='me-2 ms-2'>View summary in</div>
                                  <select
                                    className='form-select form-select-sm w-300px me-4'
                                    onChange={(e) => {
                                      const currentValue = e.target.value
                                      if (currentValue === 'new') {
                                        setConfirmGenerateSummary(true)
                                        return
                                      }
                                      const summary = section.active_summaries.find(
                                        (s) => s.unique_id === currentValue
                                      )
                                      if (summary) {
                                        setSelectedSectionSummary(summary)
                                      } else {
                                        setSelectedSectionSummary(null)
                                      }
                                    }}
                                  >
                                    <option value=''>(Select Summary)</option>
                                    {section.active_summaries.map((summary) => (
                                      <option
                                        key={`summary-${summary.unique_id}`}
                                        value={summary.unique_id}
                                      >
                                        {`${summary.summary_in_words} word${summary.summary_in_words !== 1 ? 's' : ''
                                          } (created ${DateTime.fromISO(
                                            summary.inserted_at
                                          ).toRelative()})`}
                                      </option>
                                    ))}
                                    <option value='new'>Generate new summary</option>
                                  </select>
                                </>
                              ) : (
                                <div>
                                  <button
                                    type='button'
                                    className='btn btn-secondary btn-sm ms-2'
                                    onClick={() => {
                                      setConfirmGenerateSummary(true)
                                    }}
                                    disabled={disableButtons}
                                  >
                                    Summarize
                                  </button>
                                  {section.active_summaries.length > 0 && (
                                    <span
                                      className='ms-5 text-muted cursor-pointer'
                                      onClick={() => {
                                        setViewingPreviousSummaries(!viewingPreviousSummaries)
                                      }}
                                    >
                                      {viewingPreviousSummaries
                                        ? 'Hide previous summaries'
                                        : 'View previous summaries'}
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                          {!confirmGenerateSummary && (
                            <div>
                              <button
                                type='button'
                                className='btn btn-secondary btn-sm'
                                onClick={() => {
                                  setEditing(true)
                                }}
                                disabled={disableButtons}
                              >
                                Edit
                              </button>
                              {section.key === 'biography_personal' && !writing && !section.latest_revision?.text_content && (
                                <span className='ms-5'>
                                  <Link to={linkToUpdateBio}>Update Bio</Link>
                                </span>
                              )}
                              {section.key === 'family_members' && !writing && (
                                <span className='ms-5'>
                                  <Link to={linkToRelatives}>Update Relatives</Link>
                                </span>
                              )}
                              {section.key === 'detail_services' && (
                                <span className='ms-5'>
                                  <Link to={linkToServiceDetails}>Update Service Details</Link>
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <QuillContainer className='mb-5'>
                          <ReactQuill
                            style={{ fontSize: '1.5rem' }}
                            theme='snow'
                            value={editorText}
                            onChange={(updatedText) => {
                              setEditorText(updatedText)
                            }}
                            modules={{
                              toolbar: false, // Snow includes toolbar by default
                              clipboard: {
                                matchers: [[Node.ELEMENT_NODE, handlePaste]],
                              },
                            }}
                            readOnly={writing}
                            placeholder={`Write ${section.title.toLowerCase()} information here...`}
                          />
                        </QuillContainer>
                        <div>
                          {section.key === 'biography_personal' && false && (
                            <div className='mb-5'>
                              <ToneSelector
                                isShareableObituary={isShareableObituary}
                                biographyTone={biographyTone}
                                setBiographyTone={setBiographyTone}
                              />
                            </div>
                          )}
                          {section.can_be_generated_with_ai && (
                            <button
                              type='button'
                              className='btn btn-danger'
                              onClick={() => {
                                onWriteSection()
                              }}
                              disabled={disableButtons || savingRevision || writing}
                            >
                              {!writing ? 'Re-Write' : 'Re-Writing...'}
                            </button>
                          )}
                          <button
                            type='button'
                            className='btn btn-primary float-end'
                            onClick={() => {
                              onSaveSection(section.latest_revision?.unique_identifier || '')
                            }}
                            disabled={disableButtons || savingRevision || writing}
                          >
                            {!savingRevision ? 'Save' : 'Saving...'}
                          </button>
                          {section.latest_revision?.text_content !== '' && (
                            <button
                              type='button'
                              className='btn btn-secondary me-2 float-end'
                              onClick={() => {
                                setEditing(false)
                              }}
                              disabled={disableButtons || savingRevision || writing}
                            >
                              Cancel
                            </button>
                          )}
                        </div>
                        {updateError && <div className='mt-4'>
                          <div className='alert alert-danger'>{updateError}</div>
                        </div>}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {section.key === 'family_members' ? (
                      <>
                        {!obituary.data_relatives?.passedAway && !obituary.data_relatives?.survivors ? (
                          <>
                            <button
                              type='button'
                              className={'btn btn-light btn-sm'}
                              onClick={() => {
                                onWriteSection()
                              }}
                              disabled={writing}
                            >
                              {!writing ? 'Create Empty Section' : 'Creating...'}
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type='button'
                              className={'btn btn-primary btn-sm'}
                              onClick={() => {
                                onWriteSection()
                              }}
                              disabled={writing}
                            >
                              {!writing ? section.getButtonActionText() : 'Generating...'}
                            </button>
                            {section.key === 'family_members' && !writing && (
                              <span className='ms-5'>
                                <Link to={linkToRelatives}>Update Relatives</Link>
                              </span>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {section.key === 'biography_personal' && false && (
                          <>
                            <ToneSelector
                              isShareableObituary={isShareableObituary}
                              biographyTone={biographyTone}
                              setBiographyTone={setBiographyTone}
                            />
                          </>
                        )}
                        <button
                          type='button'
                          className={
                            ['biography_personal', 'family_members', 'detail_services'].includes(
                              section.key
                            )
                              ? 'btn btn-primary btn-sm'
                              : 'btn btn-light btn-sm'
                          }
                          onClick={() => {
                            onWriteSection()
                          }}
                          disabled={writing}
                        >
                          {/* e.g. Generate Obituary, Generate Services & Condolences, Generate Special Remarks, etc. */}
                          {!writing ? section.getButtonActionText() : 'Generating...'}
                        </button>
                        {section.key === 'biography_personal' && !writing && (
                          <span className='ms-5'>
                            <Link to={linkToUpdateBio}>Update Bio</Link>
                          </span>
                        )}
                        {section.key === 'detail_services' && (
                          <span className='ms-5'>
                            <Link to={linkToServiceDetails}>Update Service Details</Link>
                          </span>
                        )}
                      </>
                    )}
                  </>
                )}
                {viewingPreviousSummaries && (
                  <div>
                    <div className='mt-4'>
                      {section.active_summaries
                        .filter((x) => x && x.unique_id !== selectedSectionSummary?.unique_id)
                        .map((summary) => {
                          return (
                            <div key={`summary-${summary.unique_id}`}>
                              <div className='separator separator-dashed my-5'></div>
                              <SectionSummaryElement summary={summary} />
                            </div>
                          )
                        })}
                    </div>
                  </div>
                )}
              </div>
              {sectionBeingGeneratedInfo && (
                <div>
                  {sectionBeingGeneratedInfo.status !== 'finished' ? (
                    <>
                      <div className='mt-4'>
                        <div className='alert alert-info'>
                          <div className='alert-text'>
                            <i className='fas fa-info-circle me-2'></i>
                            {`${sectionBeingGeneratedInfo.message}...`}
                          </div>
                        </div>
                        <ProgressBarInSeconds
                          estimatedTimeInSeconds={section.key === 'biography_personal' ? 30 : 10}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              {generationError && (
                <div className='mt-4'>
                  <div className='alert alert-danger'>
                    <div className='alert-text'>{generationError}</div>
                  </div>
                </div>
              )}</>}
          </div>
        </div>
      </div>
    </>
  )
}

export default ObituaryWriteSection
