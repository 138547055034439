import { useJourneyDetail } from "./_JourneyProvider"

function JourneyDetail() {

    const {
        journey
    } = useJourneyDetail()

    if (!journey) {
        return null;
    }

    return <div>
        <div className='row g-5 g-xxl-8 mt-1'>
            <div className='col-xl-12'>
                <h2>
                    {`Is For Self: ${journey.data.is_for_self ? 'Yes' : 'No'}`}
                </h2>
            </div>
        </div>
        <div className='row g-5 g-xxl-8 mt-1'>
            <div className='col-xl-12'>
                <h2>
                    {`Name: ${journey.data.latest_onboarding_snapshot?.current_users_full_name || '(Not provided)'}`}
                </h2>
                <h2>
                    {`Email Address: ${journey.data.latest_onboarding_snapshot?.current_users_email || '(Not provided)'}`}
                </h2>
                <h2>
                    {`Phone Number: ${journey.data.latest_onboarding_snapshot?.current_users_phone || '(Not provided)'}`}
                </h2>
            </div>
        </div>
        {!journey.data.is_for_self && <div className='row g-5 g-xxl-8 mt-1'>
            <div className='col-xl-12'>
                <h2>
                    {`For Name: ${journey.data.latest_onboarding_snapshot?.other_persons_full_name || '(Not provided)'}`}
                </h2>
                <h2>
                    {`Has passed? ${journey.data.latest_onboarding_snapshot?.other_person_has_passed ? 'Yes' : 'No'}`}
                </h2>
                <h2>
                    {`Relationship: ${journey.data.latest_onboarding_snapshot?.current_user_relationship_to_other_person || '(Not provided)'}`}
                </h2>
            </div>
        </div>}
    </div>
}

export default JourneyDetail