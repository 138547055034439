import {Obituary} from '../../../modules/obituaries/core/_models'
import {Journey} from './journey_classes'

export class ObituaryMonthDataItem {
  formatted_month: string
  total_count: number
  obituaries_by_type: {other: number; pre_need: number; at_need: number; external: number}

  constructor(data: any) {
    this.formatted_month = data.formatted_month
    this.total_count = data.total_count
    this.obituaries_by_type = data.obituaries_by_type
  }
}

// TODO: THIS:

export class ObituaryMonthDataItemOneDay {
  date_string: string // "2019-01-01"
  total_obituaries: number
  preneed_obituaries: number
  atneed_obituaries: number

  constructor(data: any) {
    this.date_string = data.date_string
    this.total_obituaries = data.total_obituaries
    this.preneed_obituaries = data.preneed_obituaries
    this.atneed_obituaries = data.atneed_obituaries
  }
}

export class DashboardDataResponse {
  total_count: number
  obituaries_by_month: ObituaryMonthDataItem[]

  constructor(data: any) {
    this.total_count = data.total_count
    this.obituaries_by_month = data.obituaries_by_month.map(
      (item: any) => new ObituaryMonthDataItem(item)
    )
  }
}

export class SessionByDayItem {
  full_date: string // "2019-01-01"
  unique_sessions: number

  constructor(data: any) {
    this.full_date = data.full_date
    this.unique_sessions = data.unique_sessions
  }
}

export class UserByDayItem {
  full_date: string // "2019-01-01"
  unique_users: number

  constructor(data: any) {
    this.full_date = data.full_date
    this.unique_users = data.unique_users
  }
}

export class PageViewByDayItem {
  full_date: string // "2019-01-01"
  // page_views_for_date is computed now client-side, should be server-side in a few minutes....
  page_views_for_date: number
  visits_by_path: {path_name: string; visits: number}[]

  constructor(data: any) {
    this.full_date = data.full_date
    this.visits_by_path = data.visits_by_path
    this.page_views_for_date = data.visits_by_path.reduce(
      (accumulator: number, currentValue: any) => accumulator + currentValue.visits,
      0
    )
  }
}

export class DashboardDataResponseForUniqueSessions {
  total_unique_sessions: number
  unique_sessions_by_day: SessionByDayItem[]

  total_unique_users: number
  unique_users_by_day: UserByDayItem[]

  total_page_views: number
  page_views_by_day: PageViewByDayItem[]

  constructor(data: any) {
    this.total_unique_sessions = data.total_unique_sessions
    this.unique_sessions_by_day = data.unique_sessions_by_day.map(
      (item: any) => new SessionByDayItem(item)
    )
    this.total_unique_users = data.total_unique_users
    this.unique_users_by_day = data.unique_users_by_day.map((item: any) => new UserByDayItem(item))
    this.total_page_views = data.total_page_views
    this.page_views_by_day = data.page_views_by_day.map((item: any) => new PageViewByDayItem(item))
  }

  getFormattedPageViewsLabel(): string {
    // Returns something like:
    // 2,089 page views
    const pageViews = this.total_page_views || 0
    return `${pageViews.toLocaleString()} page view${pageViews === 1 ? '' : 's'}`
  }

  getFormattedUniqueSessionsLabel(): string {
    // Returns something like:
    // 1,234 sessions
    const totalUniqueSessions = this.total_unique_sessions || 0
    return `${totalUniqueSessions.toLocaleString()} session${totalUniqueSessions === 1 ? '' : 's'}`
  }

  getFormattedUniqueUsersLabel(): string {
    // Returns something like:
    // 1,234 users
    const totalUniqueUsers = this.total_unique_users || 0
    return `${totalUniqueUsers.toLocaleString()} user${totalUniqueUsers === 1 ? '' : 's'}`
  }
}

class WidgetOpenByDayItem {
  full_date: string // "2019-01-01"
  activity_count: number

  constructor(data: any) {
    this.full_date = data.full_date
    this.activity_count = data.activity_count
  }
}

class WidgetConversionByDayItem {
  full_date: string // "2019-01-01"
  conversion_count: number

  constructor(data: any) {
    this.full_date = data.full_date
    this.conversion_count = data.conversion_count
  }
}

class ObituaryByDayItem {
  full_date: string // "2019-01-01"
  count: number

  constructor(data: any) {
    this.full_date = data.full_date
    this.count = data.count
  }
}

export class DashboardDataResponseForWidgetOpensAndConversions {
  total_widget_opens: number
  widget_opens_by_day: WidgetOpenByDayItem[]

  total_widget_questions_answered: number
  widget_questions_answered_by_day: WidgetConversionByDayItem[]

  total_obituaries_created: number
  obituaries_created_by_day: ObituaryByDayItem[]

  constructor(data: any) {
    this.total_widget_opens = data.total_widget_opens
    this.widget_opens_by_day = data.widget_opens_by_day.map(
      (item: any) => new WidgetOpenByDayItem(item)
    )
    this.total_widget_questions_answered = data.total_widget_questions_answered
    this.widget_questions_answered_by_day = data.widget_questions_answered_by_day.map(
      (item: any) => new WidgetConversionByDayItem(item)
    )
    this.total_obituaries_created = data.total_obituaries_created
    this.obituaries_created_by_day = data.obituaries_created_by_day.map(
      (item: any) => new ObituaryByDayItem(item)
    )
  }

  getFormattedWidgetOpensLabel(): string {
    // Returns something like:
    // 1,234 widget opens
    const totalWidgetOpens = this.total_widget_opens || 0
    return `${totalWidgetOpens.toLocaleString()} widget open${totalWidgetOpens === 1 ? '' : 's'}`
  }

  getFormattedWidgetConversionsLabel(): string {
    // Returns something like:
    // 1,234 widget conversions
    const totalWidgetQuestionsAsnwered = this.total_widget_questions_answered || 0
    return `${totalWidgetQuestionsAsnwered.toLocaleString()} question${
      totalWidgetQuestionsAsnwered === 1 ? '' : 's'
    } answered`
  }

  getFormattedObituariesCreatedLabel(): string {
    // Returns something like:
    // 1,234 obituaries created
    const totalObituariesCreated = this.total_obituaries_created || 0
    return `${totalObituariesCreated.toLocaleString()} obit${
      totalObituariesCreated === 1 ? '' : 's'
    } created`
  }
}

export class DashboardObituariesResponse {
  total_count: number
  obituaries: Obituary[]

  constructor(data: any) {
    this.total_count = data.total_count
    this.obituaries = data.obituaries.map((item: any) => new Obituary(item))
  }
}

export class DashboardJourneysResponse {
  total_count: number
  journeys: Journey[]

  constructor(data: any) {
    this.total_count = data.total_count
    this.journeys = data.journeys.map((item: any) => new Journey(item))
  }
}
