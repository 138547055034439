import { MenuItem } from '../../_metronic/layout/components/header/MenuItem'
import { useAuth } from '../modules/auth/core/Auth'

const AdminHeader = () => {
  const { currentPhoenixUserPhone } = useAuth()

  return (
    <div className='header-menu align-items-stretch mb-5'>
      <div className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch'>
        <MenuItem to='/admin/home' title='Admin Home' />
        <MenuItem to='/admin/dashboard' title='Admin Dashboard' />
        <MenuItem to='/admin/frm' title='FRM Configuration' />
        {currentPhoenixUserPhone?.is_superuser && (
          <>
            <MenuItem to='/admin/slack' title='Slack Configuration' />
          </>
        )}
      </div>
    </div>
  )
}

export default AdminHeader
