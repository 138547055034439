import {useSearchParams} from 'react-router-dom'

function useEmailFromUrl() {
  const [searchParams] = useSearchParams()
  const emailAddress = searchParams.get('e')

  // Emails could have spaces ... in which case we need to replace them with +

  const emailAddressWithPlus = emailAddress?.replace(/ /g, '+')

  return {
    emailAddress: emailAddressWithPlus || '',
  }
}

export default useEmailFromUrl
