import {useEffect} from 'react'
import styled from 'styled-components'

import AddFamilyMemberModalHeader from './AddFamilyMemberModalHeader'
import AddFamilyMemberModalForm from './AddFamilyMemberModalForm'
import {useObituaryDetail} from '../components/ObituaryDetail'

const ClickableOutside = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: transparent;
`

const ModalContent = styled.div`
  z-index: 1050;
`

const AddFamilyMemberModal = () => {
  const {setAddingFamilyMember} = useObituaryDetail()

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div
          className='modal-dialog'
          style={{
            maxWidth: '1000px',
          }}
        >
          {/* begin::Modal content */}
          <ModalContent className='modal-content'>
            <AddFamilyMemberModalHeader />
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 my-7'>
              <AddFamilyMemberModalForm />
            </div>
            {/* end::Modal body */}
          </ModalContent>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
        <ClickableOutside
          onClick={(e) => {
            setAddingFamilyMember(false)
          }}
        />
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default AddFamilyMemberModal
