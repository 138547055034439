import clsx from 'clsx'
import {useMediaQuery} from 'react-responsive'
import {useLocation, Link} from 'react-router-dom'

import {Obituary} from '../modules/obituaries/core/_models'

function TabNavigation({obituary}: {obituary: Obituary}) {
  const location = useLocation()

  const shouldShowCondensedHeader = useMediaQuery({query: '(max-width: 700px)'})

  const basePath = `/o/${obituary.shareable_code}`

  return (
    <div className='row'>
      <div className='col-sm-12'>
        <div
          className={clsx('d-flex overflow-auto', !shouldShowCondensedHeader ? 'h-55px' : 'h-40px')}
        >
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={clsx(
                  'nav-link text-active-primary me-6',
                  location.pathname.startsWith(`${basePath}/details`) && 'active'
                )}
                to={`${basePath}/details`}
              >
                Details
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={clsx(
                  'nav-link text-active-primary me-6',
                  location.pathname.startsWith(`${basePath}/write`) && 'active'
                )}
                to={`${basePath}/write`}
              >
                {!shouldShowCondensedHeader ? 'Write Obituary' : 'Obituary'}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={clsx(
                  'nav-link text-active-primary me-6',
                  location.pathname.startsWith(`${basePath}/finalize_and_send`) && 'active'
                )}
                to={`${basePath}/finalize_and_send`}
              >
                {!shouldShowCondensedHeader ? `Finalize & Download` : 'Finalize'}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default TabNavigation
