import { useRef, useState } from 'react';
import styled from 'styled-components';
import {
    ChevronLeft
} from 'react-feather'
import Dropzone from 'react-dropzone-uploader'

import { useAuth } from '../../../../auth/core/Auth';
import { JourneyPersonalization } from '../../../../../funeral_homes/dashboard/core/journey_classes';

const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
`;

const GoBackContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    cursor: pointer;
`;

const InstructionsContainer = styled.div`
    width: 100%;
    height: 200px;
    position: relative;
    background-color: rgb(227, 227, 227);
`;

const InstructionsContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    button {
        background-color: white !important;

        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: #f8f9fa !important;
        }
    }
`;

const DropzoneContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    color: black;

    text-align: center;
    overflow-y: auto;

    .dropzone-document-upload {
        min-height: 100%;
        height: 100%;
        padding-bottom: 15px;
    }
`

interface PendingUpload {
    id: string
}

function NodeUploadVideoPreviewItem({
    nodeId,
    onGoBack,
    onSuccessfulUpload
}: {
    nodeId: string;
    onGoBack: () => void;
    onSuccessfulUpload: (journeyPersonalization: JourneyPersonalization) => void;
}) {
    const { auth } = useAuth()
    const apiToken = auth?.api_token

    const dropzoneRef = useRef<Dropzone>(null)

    const [uploadingIds, setUploadingIds] = useState<string[]>([])
    const [fileMetaIdsUploaded, setMetaIdsUploaded] = useState<string[]>([])
    const [serversideFileUploads, setServersideFileUploads] = useState<PendingUpload[]>(
        []
    )

    const [uploadError, setUploadError] = useState('')

    // specify upload params and url for your files
    const getUploadParams = () => {
        return {
            url: `${process.env.REACT_APP_NEXT_API_URL}/admin/upload-journey-personalization-video-for-node/${nodeId}`,
            headers: {
                Authorization: `Bearer ${apiToken}`,
            },
        }
    }

    // called every time a file's `status` changes
    const handleChangeStatus = (
        { meta, xhr }: { meta: any; file: any; xhr?: any },
        status: any
    ): void => {
        if (status === 'done') {
            setUploadError('')
            setMetaIdsUploaded([...fileMetaIdsUploaded, meta.id])
            setUploadingIds(uploadingIds.filter((id) => id !== meta.id))
            // Get the JSON response
            const response = JSON.parse(xhr.response)
            const resultingPersonalization = new JourneyPersonalization(response.data.journey_personalization)
            onSuccessfulUpload(resultingPersonalization)
        } else if (status === 'uploading') {
            setUploadError('')
            setUploadingIds([...uploadingIds, meta.id])
        } else if (status === 'removed' || status === 'aborted') {
            setUploadError('')
            setMetaIdsUploaded(fileMetaIdsUploaded.filter((id: any) => id !== meta.id))
            setUploadingIds(uploadingIds.filter((id: any) => id !== meta.id))
            setServersideFileUploads(serversideFileUploads.filter((upload) => upload.id !== meta.id))
        } else if (status === 'exception_upload' || status === 'error_upload') {
            setUploadError('Error uploading file')
            setUploadingIds(uploadingIds.filter((id: any) => id !== meta.id))
        }
    }

    return <Container>
        <InstructionsContainer>
            <InstructionsContent>
                <button
                    className='btn btn-outline btn-outline-primary' style={{ border: '1px solid #009ef7' }}
                    type='button'
                    onClick={() => {
                    }}>
                    Upload a Video
                </button>
                <div className='mt-4 text-center text-gray-600 px-8'>
                    Drag and drop a video file here or click to select a file to upload.
                </div>
            </InstructionsContent>
        </InstructionsContainer>
        <DropzoneContainer>
            <Dropzone
                ref={dropzoneRef}
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                accept='video/*'
                classNames={{
                    ...Dropzone.defaultProps.classNames,
                    dropzone: 'dzu-dropzone dropzone-document-upload',
                }}
                inputContent='Drag and drop your video here or click to upload'
                inputWithFilesContent='Add new video'
                multiple={false}
                maxFiles={1}
            />
            {uploadError && <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                color: 'red',
                textAlign: 'center',
                padding: 12
            }}>
                {uploadError}
            </div>}
        </DropzoneContainer>
        <GoBackContainer onClick={onGoBack}>
            <ChevronLeft size={24} />
        </GoBackContainer>
    </Container >
}

export default NodeUploadVideoPreviewItem;