import clsx from 'clsx'
import styled from 'styled-components'

import { Obituary } from './core/_models'

const IconUploadMedia = styled.div`
  overflow: hidden;

  .hover-upload-media {
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-weight: bold;
    font-size: 1.25rem;
    cursor: pointer;
    height: 100%;
  }

  &:hover {
    .hover-upload-media {
      opacity: 1;
    }
  }
`

interface ObituaryAvatarPickerProps {
  obituary: Obituary
  onClick?: () => void
  condensed?: boolean
  canUploadAvatars?: boolean
}

function ObituaryAvatarPicker({ obituary, onClick, condensed = false, canUploadAvatars = false }: ObituaryAvatarPickerProps) {
  if (canUploadAvatars) {
    return (
      <div>
        <div
          className={clsx(
            'symbol symbol-fixed position-relative',
            !condensed ? 'symbol-100px symbol-lg-80px' : 'symbol-50px symbol-lg-40px'
          )}
        >
          <div style={{ position: 'relative' }}>
            <IconUploadMedia className='symbol symbol-50px symbol-lg-100px symbol-fixed position-relative'>
              {obituary.data_personal_information?.primaryAvatarUrl ||
                obituary.active_image_uploads.length > 0 ? (
                <img
                  src={
                    obituary.data_personal_information?.primaryAvatarUrl ||
                    obituary.active_image_uploads[0].image_url
                  }
                  alt='Obituary'
                  style={{ width: 'auto' }}
                />
              ) : (
                <span
                  className='symbol-label bg-primary text-inverse-primary fw-bolder'
                  style={{ fontSize: condensed ? 18 : 26 }}
                >
                  {obituary.for_full_name_initials || '-'}
                </span>
              )}
              <div
                className='hover-upload-media'
                style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
                onClick={onClick}
              >
                <div className='d-flex flex-column align-items-center justify-content-center h-100 text-center'>
                  <div className=''>
                    <div>Upload Images</div>
                  </div>
                </div>
              </div>
            </IconUploadMedia>
          </div>
          {false && 'onlineIndicator' && (
            <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
          )}
        </div>
      </div>
    )
  }


  return (
    <div
      className={clsx(
        'symbol symbol-fixed position-relative',
        !condensed ? 'symbol-100px symbol-lg-80px' : 'symbol-50px symbol-lg-40px'
      )}
    >
      <div style={{ position: 'relative' }}>
        <div className='symbol symbol-50px symbol-lg-100px symbol-fixed position-relative'>
          <span
            className='symbol-label bg-primary text-inverse-primary fw-bolder'
            style={{ fontSize: condensed ? 18 : 26 }}
          >
            {obituary.for_full_name_initials || '-'}
          </span>
        </div>
      </div>
    </div>
  )

}

export default ObituaryAvatarPicker
