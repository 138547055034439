import {useState, useEffect} from 'react'
import {FormikProps} from 'formik'

import {IUsersWhoAreEditingFieldsType} from '../modules/obituaries/components/forms/FieldBeingEditedItem'
import {
  handleIncomingWebsocketMessage,
  ObituaryPageViewMessage,
  ObituaryUserLeft,
  ObituaryUserJoined,
  ObituaryFormActionsFocused,
  ObituaryFormActionsBlurred,
  ObituaryFormActionsCheckboxChanged,
  ObituaryFormActionsChanged,
  ObituaryWebsocket,
  IAvailableFormType,
  ObituaryMoreQuestionsUpdate,
  ObituarySectionWriteUpdate,
  ObituaryAdditionalQuestionFocused,
  ObituaryAdditionalQuestionBlurred,
  ObituaryAdditionalQuestionChanged,
  ObituaryNewServiceItem,
  ObituaryRemoveServiceItem,
  ObituaryServiceItemBlurred,
  ObituaryServiceItemChanged,
  ObituaryServiceItemFocused,
} from './useObituaryWebsocket'
import {useAuth} from '../modules/auth/core/Auth'
import {ObituaryEmail} from '../modules/obituaries/core/_models'

function useComplicatedFormBehavior(
  formType: IAvailableFormType,
  obituaryWebsocket: ObituaryWebsocket | null,
  formik: FormikProps<any>,
  anotherUserUpdatedServiceItemsCallback?: (
    parsedMessage:
      | ObituaryNewServiceItem
      | ObituaryRemoveServiceItem
      | ObituaryServiceItemFocused
      | ObituaryServiceItemBlurred
      | ObituaryServiceItemChanged
  ) => void
) {
  const {currentPhoenixUserPhone} = useAuth()

  /*
  editingFieldsByName looks like:
    {
      'lovedOnesFullName': {
        'users': {
          'unique_id1': {
            ...
          } as SimpleUserModel,
          'unique_id2': {
            ...
          } as SimpleUserModel,
        }
      },
    }
  */

  const [editingFieldsByName, setEditingFieldsByName] = useState<IUsersWhoAreEditingFieldsType>({})

  useEffect(() => {
    if (obituaryWebsocket) {
      obituaryWebsocket.sendPageView(formType)
    }
  }, [obituaryWebsocket, formType])

  useEffect(() => {
    let workingEditingFieldsByName = {} as IUsersWhoAreEditingFieldsType
    if (obituaryWebsocket) {
      if (obituaryWebsocket.websocket) {
        const handleMessage = (message: any) => {
          // First we parse the message. We use handleIncomingWebsocketMessage:
          const parsedMessage = handleIncomingWebsocketMessage(message)
          if (!parsedMessage || !currentPhoenixUserPhone) {
            // Warnings are logged up-stream of this function, so we can just
            // return here.
            return
          }
          if (parsedMessage instanceof ObituaryEmail) {
            // May want to update the tab notification?
            return
          }
          if (parsedMessage.user.unique_id === currentPhoenixUserPhone.unique_identifier) {
            // All messages right now are based on other users' actions, so we
            // can just return for now
            return
          }
          if (parsedMessage instanceof ObituaryPageViewMessage) {
            // Do nothing for now
            return
          } else if (parsedMessage instanceof ObituaryUserLeft) {
            // See if parsedMessage.user.unique_id is in workingEditingFieldsByName
            // If so, remove it
            for (const [name, value] of Object.entries(workingEditingFieldsByName)) {
              if (parsedMessage.user.unique_id in value.users) {
                delete value.users[parsedMessage.user.unique_id]
              }
              if (Object.keys(value.users).length === 0) {
                delete workingEditingFieldsByName[name]
              }
            }
            // Update state
            setEditingFieldsByName(workingEditingFieldsByName)
            return
          } else if (parsedMessage instanceof ObituaryUserJoined) {
            // Do nothing for now
            return
          } else if (parsedMessage instanceof ObituaryFormActionsFocused) {
            const {name, user} = parsedMessage
            // Update workingEditingFieldsByName
            if (name in workingEditingFieldsByName) {
              // If name is already in workingEditingFieldsByName, add user to
              // users
              workingEditingFieldsByName[name].users[user.unique_id] = user
            } else {
              // If name is not in workingEditingFieldsByName, add it
              workingEditingFieldsByName[name] = {
                users: {
                  [user.unique_id]: user,
                },
              }
            }
            // Update state
            setEditingFieldsByName(workingEditingFieldsByName)
            // See if parsedMessage.name is in formik.values
            return
          } else if (parsedMessage instanceof ObituaryFormActionsBlurred) {
            const {name, user} = parsedMessage
            // Update workingEditingFieldsByName
            if (name in workingEditingFieldsByName) {
              // If name is already in workingEditingFieldsByName, remove user
              // from users
              delete workingEditingFieldsByName[name].users[user.unique_id]
            }
            // Update state
            setEditingFieldsByName(workingEditingFieldsByName)
            // See if parsedMessage.name is in formik.values
            return
            /*

            Note ... the following if statements likely need to be
            used even if the user doesn't matched. This is because
            a user can be logged in on multiple devices.

            Need to consider this.
            
          */
          } else if (parsedMessage instanceof ObituaryFormActionsCheckboxChanged) {
            // If instance of ObituaryFormActionsChanged, update formik
            // See if parsedMessage.name is in formik.values
            if (parsedMessage.name in formik.values) {
              // If so, update formik.values
              formik.setFieldValue(parsedMessage.name, parsedMessage.value)
            }
            return
          } else if (parsedMessage instanceof ObituaryFormActionsChanged) {
            // (functionally identical to ObituaryFormActionsCheckboxChanged)
            // If instance of ObituaryFormActionsChanged, update formik
            // See if parsedMessage.name is in formik.values
            if (parsedMessage.name in formik.values) {
              // If so, update formik.values
              formik.setFieldValue(parsedMessage.name, parsedMessage.value)
            }
            return
          } else if (
            parsedMessage instanceof ObituarySectionWriteUpdate ||
            parsedMessage instanceof ObituaryMoreQuestionsUpdate
          ) {
            // Do nothing right now (this is handled in the specific form)
          } else if (
            parsedMessage instanceof ObituaryAdditionalQuestionFocused ||
            parsedMessage instanceof ObituaryAdditionalQuestionBlurred ||
            parsedMessage instanceof ObituaryAdditionalQuestionChanged
          ) {
            // Do nothing right now (this is handled in the specific form)
          } else if (
            parsedMessage instanceof ObituaryNewServiceItem ||
            parsedMessage instanceof ObituaryRemoveServiceItem ||
            parsedMessage instanceof ObituaryServiceItemFocused ||
            parsedMessage instanceof ObituaryServiceItemBlurred ||
            parsedMessage instanceof ObituaryServiceItemChanged
          ) {
            // Do nothing right now (this is handled in the specific form)
            if (
              anotherUserUpdatedServiceItemsCallback &&
              parsedMessage.user.unique_id !== currentPhoenixUserPhone.unique_identifier
            ) {
              anotherUserUpdatedServiceItemsCallback(parsedMessage)
            }
          } else {
            console.warn('Unhandled message: ', parsedMessage)
          }
        }
        obituaryWebsocket.websocket.addEventListener('message', handleMessage)
        return () => {
          if (obituaryWebsocket.websocket) {
            obituaryWebsocket.websocket.removeEventListener('message', handleMessage)
          }
        }
      }
    }
  }, [anotherUserUpdatedServiceItemsCallback, currentPhoenixUserPhone, formik, obituaryWebsocket])

  return {
    editingFieldsByName,
  }
}

export default useComplicatedFormBehavior
