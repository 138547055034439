import { FamilyAudioResponse, Obituary } from '../core/_models'
import { Routes, Route, Navigate } from 'react-router-dom'
import ObituaryFamilyTreeRecordDescription from './ObituaryFamilyTreeRecordDescription'
import { useCallback, useEffect, useState } from 'react'
import { retryFamilyAudioTranscription } from '../core/_requests'
import { useObituaryDetail } from './ObituaryDetail'
import ReconnectingWebSocket from 'reconnecting-websocket'
import { useAuth } from '../../auth/core/Auth'

interface AudioResponseItemProps {
  title: string
  item: FamilyAudioResponse
}

export function AudioResponseItem({
  title,
  item: defaultFamilyAudioResponse,
}: AudioResponseItemProps) {
  const { auth } = useAuth()
  const authToken = auth ? auth.api_token : null

  const { obituary, setObituary } = useObituaryDetail()

  const [audioResponse, setAudioResponse] = useState<FamilyAudioResponse>(
    defaultFamilyAudioResponse
  )
  const [viewingTranscript, setViewingTranscript] = useState(false)

  const onRetryTranscription = useCallback(
    async (familyAudioResponse: FamilyAudioResponse) => {
      try {
        await retryFamilyAudioTranscription(obituary.unique_identifier, familyAudioResponse.unique_id)
        setAudioResponse(
          new FamilyAudioResponse({
            ...familyAudioResponse,
            audio_response_transcription_status: 'In-Progress',
          })
        )
      } catch (e) {
        console.warn(e)
      }
    },
    [obituary.unique_identifier]
  )

  function replaceNewLinesWithBreaks(text: string) {
    return text.split('\n').map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      )
    })
  }

  useEffect(() => {
    if (!authToken) {
      return
    }
    // Sets up the websocket connection for this card
    const webSocket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_API_WS_URL}/obituaries/family-audio-response/${defaultFamilyAudioResponse.unique_id}/?token=${authToken}`
    )
    webSocket.onmessage = function (e) {
      const data = JSON.parse(e.data)
      if (data.type === 'transcription_update') {
        const updatedAudioResponse = data.data.family_audio_response
        setAudioResponse(new FamilyAudioResponse(updatedAudioResponse))
        const updatedObitary = new Obituary(data.data.obituary)
        setObituary(updatedObitary)
      }
    }
    return () => {
      webSocket.close()
    }
  }, [authToken, defaultFamilyAudioResponse.unique_id, setObituary])

  return (
    <div key={`audio-response-${audioResponse.unique_id}`} className='mb-5'>
      <div className='row'>
        <div className='col-12'>
          <h4>{title}</h4>
          <audio src={audioResponse.audio_response_url} controls />
          {audioResponse.audio_response_transcription_status === 'Failed' ? (
            <div>
              <p>{`Transcription failed`}</p>
              <button
                className='btn btn-secondary btn-sm'
                onClick={() => {
                  onRetryTranscription(audioResponse)
                }}
              >
                Retry
              </button>
            </div>
          ) : (
            <div>
              {audioResponse.audio_response_transcription_status === 'Completed' ? (
                <div>
                  {audioResponse.created_relationships.length > 0 ? (
                    <>
                      <p className='mt-5'>{`Found ${audioResponse.created_relationships.length
                        } relationship${audioResponse.created_relationships.length !== 1 ? 's' : ''
                        }`}</p>
                    </>
                  ) : (
                    <p>{`No relationships found`}</p>
                  )}
                  {viewingTranscript && (
                    <div>
                      <div>{audioResponse.audio_response_transcript}</div>
                      {audioResponse.audio_response_family_summary_text ? (
                        <>
                          <div className='my-5'>Resulted in:</div>
                          <p>
                            {replaceNewLinesWithBreaks(
                              audioResponse.audio_response_family_summary_text
                            )}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  <button
                    type='button'
                    className='btn btn-secondary btn-sm'
                    onClick={() => {
                      setViewingTranscript(!viewingTranscript)
                    }}
                  >
                    {viewingTranscript ? 'Hide Transcript' : 'View Transcript'}
                  </button>
                </div>
              ) : (
                <div>
                  <p>{`Transcription status: ${audioResponse.audio_response_transcription_status}`}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function ObituaryFamilyTree({ }: {}) {
  const { obituary } = useObituaryDetail()

  return (
    <Routes>
      <Route path='/' element={<ObituaryFamilyTreeRecordDescription />} />
      <Route path='*' element={<Navigate to={`/obituaries/${obituary.unique_identifier}/family`} />} />
    </Routes>
  )
}

export default ObituaryFamilyTree
