import { KTSVG } from '../../../../_metronic/helpers'
import { useClientDetail } from '../ClientDetailRoutes'

const AddTeamMemberModalHeader = () => {
  const {
    client,
    setAddingTeamMember
  } = useClientDetail()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{`Add Team Member to ${client.name}`}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setAddingTeamMember(false)}
        style={{ cursor: 'pointer' }}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export default AddTeamMemberModalHeader
