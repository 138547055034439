/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect } from 'react'
import { useObituaryDetail } from './components/ObituaryDetail'
import usePageTitle from '../../hooks/usePageTitle'
import { Link } from 'react-router-dom'
import InspirationalPassages from './InspirationalPassages'
import { getObituaryByShareableCode } from './core/_requests'

interface ScriptureProps { }

function Scripture({ }: ScriptureProps) {
  const { obituary, setObituary, obituaryWebsocket } = useObituaryDetail()

  useEffect(() => {
    if (obituaryWebsocket) {
      obituaryWebsocket.sendPageView('scripture')
    }
  }, [obituaryWebsocket])

  usePageTitle({
    title: `${obituary.getSimpleName()}'s Obituary | Scripture`,
  })

  const onReloadObituary = useCallback(async () => {
    if (!obituary) {
      return
    }
    try {
      // This is called in the `Write Obituary` tab
      // We use `getObituaryByShareableCode` cause it's new and better
      const { obituary: updatedObituary } = await getObituaryByShareableCode(obituary.unique_identifier)
      setObituary(updatedObituary)
    } catch (error) {
      console.warn(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obituary.unique_identifier, setObituary])

  useEffect(() => {
    onReloadObituary()
  }, [onReloadObituary])

  if (!obituary.hasWrittenBiography()) {
    return (
      <div className='alert alert-warning'>
        <div className='alert-text font-weight-bold text-dark'>
          Before working on scripture you must
          <Link
            to={`/obituaries/${obituary.unique_identifier}/write`}
            className='ms-1 me-1 text-dark text-decoration-underline'
          >
            write the obituary here
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
          <div className='row mt-2 mt-md-2'>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='col-12'>
                  <InspirationalPassages />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
          <div className='row'>
            <div className='col-lg-12'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Scripture
