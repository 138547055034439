import { useCallback, useEffect, useState } from 'react'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ApiError } from '../modules/auth'
import { useAuth } from '../modules/auth/core/Auth'
import { adminGetAvailableFRMCompanies, adminUpdateCurrentUserFRMCompanyID } from './core/_requests'

const StyledSelectContainer = styled.div`
  padding-right: 5px;
`

function FRMClientSwitcher({
    hideCreation = false,
    onChanged = undefined
}: {
    hideCreation?: boolean,
    onChanged?: (value: string) => void
}) {
    const navigate = useNavigate()

    const { currentPhoenixUser } = useAuth()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)

    const [selectedValue, setSelectedValue] = useState<{ value: string; label: string }>({
        label: '(Select a director)',
        value: '',
    })

    const [selectableFRMCompanies, setSelectableFRMCompanies] = useState<
        { value: string; label: string }[]
    >([])

    const onChange = async (value: string) => {
        if (value === 'new') {
            navigate('/admin/funeral-homes/new')
            // reset the selected value to the current funeral home:
            setSelectedValue({
                value: selectableFRMCompanies[0].value,
                label: selectableFRMCompanies[0].label,
            })
        } else if (onChanged) {
            onChanged(value)
        } else {
            setLoading(true)
            try {
                await adminUpdateCurrentUserFRMCompanyID(
                    value
                )
                // reload the page, but only after a successful update:
                window.location.reload()
            } catch (exception) {
                console.error(exception)
                const errorMessage = exception instanceof ApiError ? exception.message : 'Something went wrong'
                setError(errorMessage)
                setLoading(false)
            }
        }
    }

    const loadAvailableCompanies = useCallback(async () => {
        try {
            const {
                company_ids_and_names: frmCompanies,
            } = await adminGetAvailableFRMCompanies()
            let currentFrmCompany = frmCompanies.find(
                (funeralHome) => funeralHome.company_id === currentPhoenixUser?.current_frm_company_id
            )
            if (!currentFrmCompany) {
                currentFrmCompany = frmCompanies[0]
            }
            setSelectedValue({
                value: currentFrmCompany.company_id,
                label: `${currentFrmCompany.company_name}`,
            })
            if (onChanged) {
                onChanged(currentFrmCompany.company_id)
            }
            const updatedFrmCompanies = frmCompanies.map((funeralHome) => ({
                value: funeralHome.company_id,
                label: `${funeralHome.company_name}`,
            }))
            setSelectableFRMCompanies(updatedFrmCompanies)
            setError(null)
        } catch (error: any) {
            console.warn(error)
            const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
            setError(errorMessage)
        } finally {
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPhoenixUser])

    useEffect(() => {
        loadAvailableCompanies()
    }, [loadAvailableCompanies])

    if (loading) {
        return <></>
    }

    if (error) {
        return (
            <div className='me-5'>
                <div className='alert alert-danger' role='alert'>
                    {error}
                </div>
            </div>
        )
    }

    return (
        <StyledSelectContainer className='menu-item'>
            <Select
                required
                options={selectableFRMCompanies}
                value={selectedValue}
                onChange={(item: { value: string; label: any }) => {
                    if (!item) {
                        return
                    }
                    setSelectedValue({
                        value: item.value,
                        label: item.label,
                    })
                    onChange(item.value)
                }}
                menuPlacement='auto'
                styles={{
                    control: (baseStyles: any) => ({
                        ...baseStyles,
                    }),
                    // selected option:
                    singleValue: (baseStyles: any) => ({
                        ...baseStyles,
                        fontWeight: 'normal',
                        color: 'var(--kt-gray-600)',
                    }),
                    // selectable options:
                    option: (baseStyles: any) => ({
                        ...baseStyles,
                        fontWeight: 'normal',
                    }),
                }}
            />
        </StyledSelectContainer>
    )
}

export default FRMClientSwitcher
