/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect } from 'react'
import { KTSVG, toAbsoluteUrl, useDebounce } from '../../../_metronic/helpers'
import { ApiError } from '../../modules/auth'
import { getDashboardJourneys } from './core/_requests'
import { DashboardJourneysResponse } from './core/_models'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'

type Props = {}

const ProgressBarItem = ({ value }: { value: number }) => {
  let color = 'bg-primary'
  if (value < 50) {
    color = 'bg-danger'
  } else if (value > 50 && value < 75) {
    color = 'bg-warning'
  } else if (value > 75) {
    color = 'bg-success'
  }
  return (
    <div className='d-flex flex-column w-100 me-2'>
      <div className='d-flex flex-stack mb-2'>
        <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
      </div>
      <div className='progress h-6px w-100'>
        <div
          className={`progress-bar ${color}`}
          role='progressbar'
          style={{ width: `${value}%` }}
        ></div>
      </div>
    </div>
  )
}

const JourneyTable: React.FC<Props> = ({ }) => {
  const [loadingObituaries, setLoading] = React.useState(true)
  const [loadingError, setLoadingError] = React.useState<string | null>(null)

  // We keep separate loading states for future use (and to not break the table while searching)
  const [, setSearchingForObituaries] = React.useState<boolean>(false)
  const [, setErrorSearchingForObituaries] = React.useState<string | null>(null)

  const [journeysTableResponse, setJourneysTableResponse] =
    React.useState<DashboardJourneysResponse | null>(null)

  const [obituarySearchText, setObituarySearchText] = React.useState<string>('')
  const debouncedObituarySearchText = useDebounce(obituarySearchText, 500)

  const loadDashboardData = useCallback(async () => {
    const queryText = debouncedObituarySearchText || ''
    if (!queryText) {
      setLoading(true)
    } else {
      setSearchingForObituaries(true)
    }
    try {
      const updatedData = await getDashboardJourneys(queryText)
      setJourneysTableResponse(updatedData)
      setErrorSearchingForObituaries(null)
      setLoadingError(null)
    } catch (error: any) {
      const errorMessage = error instanceof ApiError ? error.message : 'Something went wrong'
      if (!queryText) {
        setLoadingError(errorMessage)
      } else {
        setErrorSearchingForObituaries(errorMessage)
      }
    } finally {
      setLoading(false)
      setSearchingForObituaries(false)
    }
  }, [debouncedObituarySearchText])

  useEffect(() => {
    loadDashboardData()
  }, [loadDashboardData])

  return (
    <div className={`card card-custom card-stretch shadow mb-5`}>
      {loadingObituaries ? (
        <>
          <div className='card-body d-flex justify-content-center align-items-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <>
          {loadingError ? (
            <>
              <div className='card-body d-flex justify-content-center align-items-center'>
                <div className='alert alert-danger' role='alert'>
                  <div className='alert-text'>{loadingError}</div>
                </div>
              </div>
            </>
          ) : (
            <>
              {journeysTableResponse?.total_count === 0 && !obituarySearchText ? (
                <>
                  <div className='card-body d-flex justify-content-center align-items-center'>
                    <div>
                      No journeys have been created
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {' '}
                  {/* begin::Header */}
                  <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      {/* begin::Search Bar */}
                      <div className='d-flex align-items-center position-relative my-1'>
                        <input
                          type='text'
                          className='form-control form-control-solid w-300px'
                          placeholder='Search for an obituary...'
                          value={obituarySearchText}
                          onChange={(e) => setObituarySearchText(e.target.value)}
                        />
                      </div>
                      {/* end::Search Bar */}
                    </h3>
                    {false && <div
                      className='card-toolbar'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      data-bs-trigger='hover'
                      title='Click to add an obituary'
                    >
                      <Link to='/create-obituary' className='btn btn-sm btn-light-primary'>
                        <KTSVG
                          path='media/icons/duotune/arrows/arr075.svg'
                          className='svg-icon-3'
                        />
                        New Obituary
                      </Link>
                    </div>}
                  </div>
                  {/* end::Header */}
                  {/* begin::Body */}
                  <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bold text-muted'>
                            {false && (
                              <th className='w-25px'>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value='1'
                                    data-kt-check='true'
                                    data-kt-check-target='.widget-9-check'
                                  />
                                </div>
                              </th>
                            )}
                            <th className='w-25px'>Image</th>
                            <th className='min-w-150px'>Name of Deceased</th>
                            <th className='min-w-150px'>Headline</th>
                            <th className='min-w-150px'>Contact Info</th>
                            <th className='w-150px text-center'>Type</th>
                            <th className='min-w-60px'>Progress</th>
                            <th className='min-w-30px text-end'>Actions</th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {journeysTableResponse?.journeys.map((journey) => {
                            return (
                              <tr key={`journey-${journey.unique_identifier}`}>
                                {false && (
                                  <td>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                      <input
                                        className='form-check-input widget-9-check'
                                        type='checkbox'
                                        value='1'
                                      />
                                    </div>
                                  </td>
                                )}
                                <td>
                                  <Link
                                    to={`/journey/${journey.unique_identifier}`}
                                    style={{
                                      height: 50,
                                      textAlign: 'center',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <span
                                      className='symbol-label bg-primary text-inverse-primary fw-bolder'
                                      style={{ fontSize: 14, padding: 10, borderRadius: 5, minWidth: 40 }}
                                    >
                                      {journey.for_full_name_initials || '-'}
                                    </span>
                                  </Link>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    {false && 'avatarDisabled' && (
                                      <div className='symbol symbol-45px me-5'>
                                        <img
                                          src={toAbsoluteUrl('/media/avatars/300-14.jpg')}
                                          alt=''
                                        />
                                      </div>
                                    )}
                                    <div className='d-flex justify-content-start flex-column'>
                                      <Link
                                        to={`/journeys/${journey.unique_identifier}`}
                                        className='text-dark fw-bold text-hover-primary fs-6'
                                      >
                                        {`${journey.for_full_name || '-'}`}
                                      </Link>
                                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        {/* (dates lived) */}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className='text-muted d-block fs-6'>
                                    {journey.tribute_headline ? (
                                      <>
                                        <span>{journey.tribute_headline}</span>
                                      </>
                                    ) : (
                                      <>
                                        <span>-</span>
                                      </>
                                    )}
                                  </span>
                                </td>
                                <td className='text-left'>
                                  {journey.data.latest_onboarding_snapshot?.current_users_full_name || "(No name provided)"}
                                  <br />
                                  {journey.data.latest_onboarding_snapshot?.current_users_email || "(No email provided)"}
                                  <br />
                                  {journey.data.latest_onboarding_snapshot?.current_users_phone || "(No phone number provided)"}
                                </td>
                                <td>
                                  {/* Pre-Need, At-Need, Other */}
                                  <div className='text-dark fw-bold d-block fs-6 text-center'>
                                    {`${journey.getObituaryTypeString()}`}
                                  </div>
                                  <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                                    {`(${DateTime.fromISO(journey.updated_at).toRelative()})`}
                                  </span>
                                </td>
                                <td className='text-end'>
                                  <ProgressBarItem value={journey.full_journey_progress_percentage_value} />
                                </td>
                                <td>
                                  <div className='d-flex justify-content-end flex-shrink-0'>
                                    {false && (
                                      <a
                                        href='#'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/general/gen019.svg'
                                          className='svg-icon-3'
                                        />
                                      </a>
                                    )}
                                    <Link
                                      to={`/journeys/${journey.unique_identifier}`}
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/art/art005.svg'
                                        className='svg-icon-3'
                                      />
                                    </Link>
                                    {false && (
                                      <a
                                        href='#'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/general/gen027.svg'
                                          className='svg-icon-3'
                                        />
                                      </a>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                        {/* end::Table body */}
                      </table>
                      {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                  </div>
                  {/* begin::Body */}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default JourneyTable
