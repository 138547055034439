/*
An "item" looks like:
{
    label: 'Your full name *',
    name: 'fullName',
    type: 'text',
    value: formik.values.fullName,
    autoFocus: false,
    required: true,
    error: formik.errors.fullName,
    touched: formik.touched.fullName,
    isTextArea: false,
    isCheckbox: false,
    checked: undefined,
    isDatePicker: false,
    placeholder: 'Enter your full name...',
}
*/

import clsx from 'clsx'
import { DateTime } from 'luxon'
import { FormikProps } from 'formik'
import DatePicker from 'react-datepicker'
import { Channel } from 'phoenix'

import { getDateAsAstring } from '../ObituaryBiographicalFormCreate'
import FieldBeingEditedItem, { IUsersWhoAreEditingFieldsType } from './FieldBeingEditedItem'
import { IAvailableFormType, ObituaryWebsocket } from '../../../../obituaries/useObituaryWebsocket'
import AdditionalQuestions from './AdditionalQuestions'
import { Obituary, ReceivedQuestion } from '../../core/_models'

export interface IItemProps {
  label: string
  name: string
  type: string
  value: string | undefined
  autoFocus: boolean
  required: boolean
  error: string | undefined
  touched: boolean | undefined
  isTextArea: boolean
  isCheckbox: boolean | undefined
  checked: boolean | undefined
  isDatePicker?: boolean | undefined
  placeholder?: string | undefined
  itemIsHidden?: boolean | undefined
  additionalComponent?: () => JSX.Element | undefined
  additionalComponentIsAbove?: boolean | undefined
  readOnly?: boolean | undefined
  autoComplete?: string | undefined
  additionalQuestionId?: string | undefined
  isSkipped?: boolean | undefined
  isSelect?: boolean | undefined
  selectOptions?: Array<{ value: string; label: string }> | undefined
}

// IItemHeaderProps is used for the "header" item type.
// it has a "header" and "name" property.

export interface IItemHeaderProps {
  header: string
  name: string
  isSkipped?: boolean | undefined
}

interface FormItemsRendererProps {
  formType?: IAvailableFormType
  formik: FormikProps<any>
  obituaryWebsocket: ObituaryWebsocket | null
  setObituary?: (obituary: Obituary) => void
  items: Array<IItemProps | IItemHeaderProps>
  editingFieldsByName?: IUsersWhoAreEditingFieldsType
  textAreaRows?: number
  receivedQuestions?: ReceivedQuestion[]
  obituaryChannel: Channel | null
}

function FormItemsRenderer({
  formType,
  formik,
  obituaryWebsocket,
  setObituary,
  items,
  editingFieldsByName,
  textAreaRows = 5,
  receivedQuestions,
  obituaryChannel
}: FormItemsRendererProps) {
  return (
    <>
      {items.map((item, index) => {
        if (!item.name) {
          console.warn('Item is missing a name', item)
        }
        if (item.isSkipped) {
          return <div key={`skipped-index-${index}`} />
        }
        // If the item is IItemHeaderProps, then we render a header.
        if ('header' in item) {
          return (
            <div key={`${item.name || 'unknown-name'}`} className='fs-4'>
              {item.header}
            </div>
          )
        }
        return (
          <div
            className={clsx(
              'row',
              item.isCheckbox ? 'mt-4' : 'mt-8',
              item.itemIsHidden ? 'd-none' : ''
            )}
            key={`${item.name || 'missing-name'}`}
          >
            {item.additionalComponentIsAbove &&
              item.additionalComponent &&
              item.additionalComponent()}
            <div className='col-12 position-relative'>
              {item.isCheckbox ? (
                <>
                  <div>
                    <input
                      id={item.name}
                      type='checkbox'
                      className='form-check-input'
                      name={item.name}
                      checked={item.checked}
                      onChange={(e) => {
                        formik.setFieldValue(item.name, e.target.checked)
                        if (obituaryWebsocket && formType) {
                          obituaryWebsocket.formActions.onCheckboxChanged({
                            form: formType,
                            name: item.name,
                            value: e.target.checked,
                          })
                        }
                        if (obituaryChannel && formType) {
                          obituaryChannel.push('obituary_form_checkbox_changed', {
                            form: formType,
                            name: item.name,
                            value: e.target.checked,
                          })
                        }
                      }}
                      onFocus={() => {
                        if (obituaryWebsocket && formType) {
                          obituaryWebsocket?.formActions.onInputFocused({
                            form: formType,
                            name: item.name,
                          })
                        }
                        if (obituaryChannel && formType) {
                          obituaryChannel.push('obituary_form_field_focused', {
                            form: formType,
                            name: item.name,
                          })
                        }
                      }}
                      onBlur={() => {
                        if (obituaryWebsocket && formType) {
                          obituaryWebsocket?.formActions.onInputBlurred({
                            form: formType,
                            name: item.name,
                          })
                        }
                        if (obituaryChannel && formType) {
                          obituaryChannel.push('obituary_form_field_blurred', {
                            form: formType,
                            name: item.name,
                          })
                        }
                      }}
                    />
                    <label className='form-check-label ms-2' htmlFor={item.name}>
                      {item.label}
                    </label>
                  </div>
                </>
              ) : (
                <>
                  <label className='form-label'>{item.label}</label>
                  {item.isSelect ? (
                    <>
                      <select
                        className='form-select'
                        name={item.name}
                        value={item.value}
                        onChange={(e) => {
                          formik.setFieldValue(item.name, e.target.value)
                          if (obituaryWebsocket && formType) {
                            obituaryWebsocket?.formActions.onInputChanged({
                              form: formType,
                              name: item.name,
                              value: e.target.value,
                            })
                          }
                          if (obituaryChannel && formType) {
                            obituaryChannel.push('obituary_form_select_changed', {
                              form: formType,
                              name: item.name,
                              value: e.target.value,
                            })
                          }
                        }}
                        onFocus={() => {
                          if (obituaryWebsocket && formType) {
                            obituaryWebsocket?.formActions.onInputFocused({
                              form: formType,
                              name: item.name,
                            })
                          }
                          if (obituaryChannel && formType) {
                            obituaryChannel.push('obituary_form_field_focused', {
                              form: formType,
                              name: item.name,
                            })
                          }
                        }}
                        onBlur={() => {
                          if (obituaryWebsocket && formType) {
                            obituaryWebsocket?.formActions.onInputBlurred({
                              form: formType,
                              name: item.name,
                            })
                          }
                          if (obituaryChannel && formType) {
                            obituaryChannel.push('obituary_form_field_blurred', {
                              form: formType,
                              name: item.name,
                            })
                          }
                        }}
                      >
                        {item.selectOptions?.map((option) => (
                          <option key={`item-${option.value || 'empty'}`} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : (
                    <>
                      {item.isDatePicker ? (
                        <>
                          <div>
                            {/* https://github.com/Hacker0x01/react-datepicker/issues/3921 */}
                            {/* May not need all of these formats, but this seems to make it more useable on user input */}
                            <DatePicker
                              className='form-control'
                              selected={item.value ? DateTime.fromISO(item.value).toJSDate() : null}
                              onChange={(date) => {
                                // If it's a single Date, then do the following:
                                if (date instanceof Date) {
                                  const dateAsString = getDateAsAstring(date)
                                  formik.setFieldValue(item.name, dateAsString)
                                  if (obituaryWebsocket && formType) {
                                    obituaryWebsocket?.formActions.onInputChanged({
                                      form: formType,
                                      name: item.name,
                                      value: dateAsString,
                                    })
                                  }
                                  if (obituaryChannel && formType) {
                                    obituaryChannel.push('obituary_form_date_changed', {
                                      form: formType,
                                      name: item.name,
                                      value: dateAsString,
                                    })
                                  }
                                } else {
                                  // Must be null, so clear the value. I think an empty string is ok for now.
                                  formik.setFieldValue(item.name, '')
                                  if (obituaryWebsocket && formType) {
                                    obituaryWebsocket?.formActions.onInputChanged({
                                      form: formType,
                                      name: item.name,
                                      value: '',
                                    })
                                  }
                                  if (obituaryChannel && formType) {
                                    obituaryChannel.push('obituary_form_date_changed', {
                                      form: formType,
                                      name: item.name,
                                      value: '',
                                    })
                                  }
                                }
                              }}
                              onFocus={() => {
                                if (obituaryWebsocket && formType) {
                                  obituaryWebsocket?.formActions.onInputFocused({
                                    form: formType,
                                    name: item.name,
                                  })
                                }
                                if (obituaryChannel && formType) {
                                  obituaryChannel.push('obituary_form_field_focused', {
                                    form: formType,
                                    name: item.name,
                                  })
                                }
                              }}
                              onBlur={() => {
                                if (obituaryWebsocket && formType) {
                                  obituaryWebsocket?.formActions.onInputBlurred({
                                    form: formType,
                                    name: item.name,
                                  })
                                }
                                if (obituaryChannel && formType) {
                                  obituaryChannel.push('obituary_form_field_blurred', {
                                    form: formType,
                                    name: item.name,
                                  })
                                }
                              }}
                              dateFormat={[
                                'MMMM do, yyyy', // May 1, 2021
                                'MMMM do yyyy', // May 1 2021
                                'MMMM d, yyyy', // May 1, 2021
                                'P', // 2021-05-01
                                'PP', // 05/01/2021
                                'PPP', // May 1, 2021
                                'MMM dd yyyy', // May 01 2021
                                'MMMM dd yyyy', // May 01 2021
                                'MMMM d yyyy', // May 1 2021
                                // Also allow 01-01-2021 and 01/01/2021 and 01.01.2021 and 01 01 2021 and 01-01-2021
                                // and 01/01/21 and 01/01/21 and 01.01.21 and 01 01 21 and 01-01-21
                                'MM-dd-yyyy',
                                'MM/dd/yyyy',
                                'MM.dd.yyyy',
                                'MM dd yyyy',
                                'MM-dd-yy',
                                'MM/dd/yy',
                                'MM.dd.yy',
                                'MM dd yy',
                              ]}
                              showYearDropdown
                              showMonthDropdown
                              dropdownMode='select'
                              maxDate={new Date()}
                              placeholderText={item.placeholder || ''}
                              required={item.required}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          {!item.isTextArea ? (
                            <input
                              type={item.type}
                              className='form-control'
                              name={item.name}
                              onChange={(e) => {
                                formik.setFieldValue(item.name, e.target.value)
                                if (obituaryWebsocket && formType) {
                                  obituaryWebsocket?.formActions.onInputChanged({
                                    form: formType,
                                    name: item.name,
                                    value: e.target.value,
                                  })
                                }
                                if (obituaryChannel && formType) {
                                  obituaryChannel.push('obituary_form_input_changed', {
                                    form: formType,
                                    name: item.name,
                                    value: e.target.value,
                                  })
                                }
                              }}
                              value={item.value || ''}
                              autoFocus={item.autoFocus}
                              required={item.required}
                              onFocus={() => {
                                if (obituaryWebsocket && formType) {
                                  obituaryWebsocket?.formActions.onInputFocused({
                                    form: formType,
                                    name: item.name,
                                  })
                                }
                                if (obituaryChannel && formType) {
                                  obituaryChannel.push('obituary_form_field_focused', {
                                    form: formType,
                                    name: item.name,
                                  })
                                }
                              }}
                              onBlur={() => {
                                if (obituaryWebsocket && formType) {
                                  obituaryWebsocket?.formActions.onInputBlurred({
                                    form: formType,
                                    name: item.name,
                                  })
                                }
                                if (obituaryChannel && formType) {
                                  obituaryChannel.push('obituary_form_field_blurred', {
                                    form: formType,
                                    name: item.name,
                                  })
                                }
                              }}
                              placeholder={item.placeholder || ''}
                              readOnly={item.readOnly}
                              autoComplete={item.autoComplete}
                            />
                          ) : (
                            <textarea
                              className='form-control'
                              name={item.name}
                              onChange={(e) => {
                                formik.setFieldValue(item.name, e.target.value)
                                if (obituaryWebsocket && formType) {
                                  obituaryWebsocket?.formActions.onInputChanged({
                                    form: formType,
                                    name: item.name,
                                    value: e.target.value,
                                  })
                                }
                                if (obituaryChannel && formType) {
                                  obituaryChannel.push('obituary_form_input_changed', {
                                    form: formType,
                                    name: item.name,
                                    value: e.target.value,
                                  })
                                }
                              }}
                              onFocus={() => {
                                if (obituaryWebsocket && formType) {
                                  obituaryWebsocket?.formActions.onInputFocused({
                                    form: formType,
                                    name: item.name,
                                  })
                                }
                                if (obituaryChannel && formType) {
                                  obituaryChannel.push('obituary_form_field_focused', {
                                    form: formType,
                                    name: item.name,
                                  })
                                }
                              }}
                              onBlur={() => {
                                if (obituaryWebsocket && formType) {
                                  obituaryWebsocket?.formActions.onInputBlurred({
                                    form: formType,
                                    name: item.name,
                                  })
                                }
                                if (obituaryChannel && formType) {
                                  obituaryChannel.push('obituary_form_field_blurred', {
                                    form: formType,
                                    name: item.name,
                                  })
                                }
                              }}
                              value={item.value || ''}
                              autoFocus={item.autoFocus}
                              required={item.required}
                              placeholder={item.placeholder || ''}
                              rows={textAreaRows || 5}
                              readOnly={item.readOnly}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {item.error && item.touched && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{item.error}</div>
                </div>
              )}
              {!item.additionalComponentIsAbove &&
                item.additionalComponent &&
                item.additionalComponent()}
              {editingFieldsByName && editingFieldsByName[item.name] && (
                <div className='position-absolute top-0 end-0'>
                  <FieldBeingEditedItem editingFieldByName={editingFieldsByName[item.name]} />
                </div>
              )}
              {obituaryWebsocket && setObituary && item.additionalQuestionId && (
                <AdditionalQuestions
                  formik={formik}
                  obituaryWebsocket={obituaryWebsocket}
                  setObituary={setObituary}
                  item={item}
                  receivedQuestions={receivedQuestions}
                />
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}

export default FormItemsRenderer
