/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useState } from 'react'
import { useLayout } from '../core'
import { resetUserType } from '../../../app/modules/auth/core/_requests'
import { useNavigate } from 'react-router-dom'
import FuneralHomeSwitcher from './FuneralHomeSwitcher'
import { useAuth } from '../../../app/modules/auth/core/Auth'

const Footer: FC = () => {
  const { classes } = useLayout()
  const navigate = useNavigate()

  const { currentPhoenixUserPhone, currentPhoenixUser, setCurrentPhoenixUser } = useAuth()

  const [switchingUserType, setSwitchingUserType] = useState(false)

  const onSwitchUserType = useCallback(async () => {
    console.warn('onSwitchUserType')
    /*
    setSwitchingUserType(true)
    try {
      const updatedUser = await resetUserType()
      setCurrentUser(updatedUser)
      // Goto /onboarding 
      navigate('/onboarding')
    } catch (error: any) {
      console.error(error)
    }
    */
  }, [])

  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href='/' className='text-gray-800 text-hover-primary'>
            obituaries.ai
          </a>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          {(currentPhoenixUser?.is_admin ||
            (currentPhoenixUserPhone?.hasSelectedUserType() &&
              currentPhoenixUserPhone?.is_admin &&
              currentPhoenixUserPhone.isFuneralHomeUser())) ? <FuneralHomeSwitcher /> : <></>}
          {false && 'disabledUntilSupported' && (currentPhoenixUser?.is_admin || currentPhoenixUserPhone?.hasSelectedUserType()) && (
            <li className='menu-item'>
              <button
                type='button'
                className='btn menu-link ps-0 pe-2'
                disabled={switchingUserType}
                onClick={onSwitchUserType}
              >
                Switch User Type
              </button>
            </li>
          )}
          {false && (
            <>
              <li className='menu-item'>
                <a href='#' className='menu-link ps-0 pe-2'>
                  About
                </a>
              </li>
              <li className='menu-item'>
                <a href='#' className='menu-link pe-0 pe-2'>
                  Contact
                </a>
              </li>
              <li className='menu-item'>
                <a href='#' className='menu-link pe-0'>
                  Purchase
                </a>
              </li>
            </>
          )}
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export { Footer }
